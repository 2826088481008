import React, { Fragment, useEffect, useRef } from 'react';
import { Tooltip } from '@material-ui/core';
import c from 'classnames';
import PropTypes from 'prop-types';

import { manipulateHeatmapTitle } from '../../../utils/functions';

const PracticeRankBoard = ({ items, itemLength, scroll, headerToUse }) => {
    const itemsToIterate = items;
    const scrollIndex = useRef(null);
    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        scrollIndex.current?.scrollIntoView({ behavior: 'instant' });
    }, [scroll]);
    return (
        <Fragment>
            <div className="rankDiv">
                {itemsToIterate.map(({ title: rowTitle = '', items: subItems = [] }, itemIndex) => {
                    const { score = '' } = subItems[0] || {};
                    const title = manipulateHeatmapTitle(rowTitle);
                    const color = score > itemLength - 12 ? 'red' : '#000000';
                    const colorToUse = score < 13 ? '#0679C3' : color;
                    const bg = colorToUse === '#0679C3' ? '#F2F2F2' : '#FEEBEB';
                    const isLevel = itemIndex === 12 || itemIndex === itemLength - 12;
                    const borderStyle = isLevel ? { borderTop: '1px solid #CCCCCC' } : {};
                    const id = itemIndex === 12 ? 'mid' : '';
                    const idToUse = itemIndex === itemLength - 12 ? 'bottom' : id;
                    return (
                        <Fragment>
                            {!itemIndex && (
                                <div
                                    className="sectionDiv"
                                    key={`${title}${itemIndex}1`}
                                    style={borderStyle}
                                    id={idToUse}
                                >
                                    <div className="headerWrapper" key="header">
                                        <ul>
                                            <li className="sectionContent headerList contentList">Rank</li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                            <div className="sectionDiv" style={borderStyle} id={idToUse}>
                                <ul ref={scroll === itemIndex ? scrollIndex : null}>
                                    <Tooltip placement="top" arrow title={title} aria-label={title}>
                                        <li className="sectionContent contentList">
                                            <span
                                                style={{
                                                    color: colorToUse,
                                                    fontWeight: colorToUse === '#0679C3' ? 500 : 400,
                                                    background: colorToUse === '#000000' ? '#ffffff' : bg,
                                                }}
                                                className="rankBg"
                                            >
                                                {itemIndex + 1}
                                            </span>
                                        </li>
                                    </Tooltip>
                                    {/* {subItems.map(({ score: innerScore }, index) => {
                                    const searchIndex = index + minSliceValue;
                                    const i =
                                        itemsToIterate.findIndex(
                                            ({ items: innerItem }) => innerItem[searchIndex].score === itemIndex + 1
                                        ) || 0;
                                    const { title: prName = '' } = itemsToIterate[i] || {};
                                    const innerColor = i > itemLength - 12 ? 'red' : '#000000';
                                    const innerColorToUse = i < 12 ? '#0679C3' : innerColor;
                                    return (
                                        <li
                                            className="contentList"
                                            key={`${innerScore}${index}${Math.random()}`}
                                            style={{
                                                color: innerColorToUse,
                                                fontWeight: innerColorToUse === '#0679C3' ? 500 : 400,
                                            }}
                                        >
                                            {prName}
                                        </li>
                                    );
                                })} */}
                                </ul>
                            </div>
                        </Fragment>
                    );
                })}
            </div>
            <div className="scrollSection">
                {itemsToIterate.map(({ title: rowTitle = '', items: subItems = [] }, itemIndex) => {
                    // const { score = '' } = subItems[0] || {};
                    const title = manipulateHeatmapTitle(rowTitle);
                    // const color = score > itemLength - 12 ? 'red' : '#000000';
                    // const colorToUse = score < 13 ? '#0679C3' : color;
                    // const bg = colorToUse === '#0679C3' ? '#F2F2F2' : '#FEEBEB';
                    const isLevel = itemIndex === 12 || itemIndex === itemLength - 12;
                    const borderStyle = isLevel ? { borderTop: '1px solid #CCCCCC' } : {};
                    const id = itemIndex === 12 ? 'mid' : '';
                    const idToUse = itemIndex === itemLength - 12 ? 'bottom' : id;
                    return (
                        <Fragment>
                            {!itemIndex && (
                                <div className="sectionDiv headerWrapper" key="header1">
                                    <ul>
                                        {subItems.map((item, index) => {
                                            return (
                                                <li
                                                    className={c(
                                                        // { bold: prSortIndex === index },
                                                        'contentList headerList'
                                                    )}
                                                    key={`header_${index}`}
                                                    // onClick={() => sortPRData(index)}
                                                >
                                                    {manipulateHeatmapTitle(headerToUse[index])}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            )}
                            <div className="sectionDiv" key={`${title}${itemIndex}1`} style={borderStyle} id={idToUse}>
                                <ul ref={scroll === itemIndex ? scrollIndex : null}>
                                    {subItems.map(({ score: innerScore }, index) => {
                                        const searchIndex = index;
                                        const i =
                                            itemsToIterate.findIndex(({ items: innerItem }) => {
                                                const { score: sc = 0 } = innerItem[searchIndex] || {};
                                                return sc === itemIndex + 1;
                                            }) || 0;
                                        const { title: prName = '' } = itemsToIterate[i] || {};
                                        const innerColor = i > itemLength - 12 ? 'red' : '#000000';
                                        const innerColorToUse = i < 12 ? '#0679C3' : innerColor;
                                        return (
                                            <li
                                                className="contentList"
                                                key={`${innerScore}${index}11`}
                                                style={{
                                                    color: innerColorToUse,
                                                    fontWeight: innerColorToUse === '#0679C3' ? 500 : 400,
                                                }}
                                            >
                                                {prName}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </Fragment>
                    );
                })}
            </div>
        </Fragment>
    );
};

PracticeRankBoard.propTypes = {
    items: PropTypes.func.isRequired,
    itemLength: PropTypes.number.isRequired,
    // maxSliceValue: PropTypes.number.isRequired,
    // minSliceValue: PropTypes.number.isRequired,
    scroll: PropTypes.number.isRequired,
    headerToUse: PropTypes.array.isRequired,
};

export default PracticeRankBoard;
