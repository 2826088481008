import {
    GET_DAILY_RES,
    GET_DEMO_RES,
    GET_CROSS_TAB,
    SET_DEMO_VALUE,
    SET_CROSS_TABS_DEMO,
    RELOAD_DATA,
    RESET_TAB_DATA,
} from './constants';

export const getDailyResponses = payload => ({
    type: `${GET_DAILY_RES}_PENDING`,
    payload,
});

export const getDailyResponsesSuccess = (payload = {}) => ({
    type: `${GET_DAILY_RES}_SUCCESS`,
    payload,
});

export const getDailyResponsesFailed = (payload = {}) => ({
    type: `${GET_DAILY_RES}_FAILED`,
    payload,
});

export const getDemoResponses = payload => ({
    type: `${GET_DEMO_RES}_PENDING`,
    payload,
});

export const getDemoResponsesSuccess = (payload = {}) => ({
    type: `${GET_DEMO_RES}_SUCCESS`,
    payload,
});

export const getDemoResponsesFailed = (payload = {}) => ({
    type: `${GET_DEMO_RES}_FAILED`,
    payload,
});

export const getCrossTab = payload => ({
    type: `${GET_CROSS_TAB}_PENDING`,
    payload,
});

export const getCrossTabSuccess = (payload = {}) => ({
    type: `${GET_CROSS_TAB}_SUCCESS`,
    payload,
});

export const getCrossTabFailed = (payload = {}) => ({
    type: `${GET_CROSS_TAB}_FAILED`,
    payload,
});

export const setDropDownValue = payload => ({
    type: SET_DEMO_VALUE,
    payload,
});

export const setCrossTabsDropDown = payload => ({
    type: SET_CROSS_TABS_DEMO,
    payload,
});

export const reloadSiteData = () => ({
    type: RELOAD_DATA,
});

export const reloadTabData = payload => ({
    type: RESET_TAB_DATA,
    payload,
});
