import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dialog, IconButton, Button, TextField, Tooltip } from '@material-ui/core';
import { Close, Info } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import c from 'classnames';

import Loader from '../../../components/Loader';
import { getStaticText } from '../../../utils/constants';
import { removeUnderscore } from '../../../utils/functions';
import './index.scss';

const AddActionModal = ({
    optionListInfo,
    optionData,
    actionError,
    closeModal,
    addCompAction,
    actionCreated,
    resetActionRedux,
    actionLoading,
}) => {
    const staticText = getStaticText('1033');
    const { SITE_TEXT } = staticText;

    const { ADD_ACTION, CREATE_ACTION, ACTION_SUBMITTED, ACTION_SUBMITTED_TEXT, TEXT_PLACE, OK_TEXT } = SITE_TEXT;
    const [optionList, updateActionList] = useState({});
    const [intervention, setData] = useState('');

    const resetAndClose = () => {
        if (actionCreated) {
            resetActionRedux();
        }
        closeModal(false);
    };

    const addNewAction = () => {
        if (!actionCreated) {
            const dataToSend = {
                ...optionList,
                intervention,
            };
            addCompAction(dataToSend);
        } else {
            resetAndClose();
        }
    };

    const updateDataToSend = (innerKey, { id, value, outcome }) => {
        const objToAdd = innerKey === 'practice' ? { outcome, [innerKey]: id } : { [innerKey]: value };
        updateActionList({
            ...optionList,
            ...objToAdd,
        });
    };

    const active = Object.keys(optionList).length === Object.keys(optionData).length && intervention;

    return (
        <Dialog open disableEscapeKeyDown disableBackdropClick classes={{ root: 'addActionRoot' }}>
            {actionLoading ? <Loader position="absolute" showBackground background="transparent" /> : null}
            <section className="headContent">
                <IconButton aria-label="close" className="closeButton" onClick={resetAndClose}>
                    <Close />
                </IconButton>
                <h2>{actionCreated ? ACTION_SUBMITTED : CREATE_ACTION}</h2>
            </section>
            <section className="dataContent">
                {actionError && <div className="error">{actionError} </div>}
                {actionCreated ? (
                    <div className="actionCreated">{ACTION_SUBMITTED_TEXT}</div>
                ) : (
                    <Fragment>
                        <div className="autoCompleteContent clearfix">
                            {Object.keys(optionListInfo).map(innerKey => (
                                <div key={innerKey} className="optionContent">
                                    <Autocomplete
                                        key={innerKey}
                                        options={optionData[innerKey]}
                                        getOptionLabel={({ value }) => value}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label={removeUnderscore(innerKey)}
                                            />
                                        )}
                                        onChange={(e, newValues) => updateDataToSend(innerKey, newValues)}
                                    />
                                    <Tooltip
                                        classes={{
                                            tooltip: 'actionOptionTip',
                                        }}
                                        arrow
                                        title={optionListInfo[innerKey]}
                                    >
                                        <Info />
                                    </Tooltip>
                                </div>
                            ))}
                        </div>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            inputProps={{ maxLength: 200, 'data-testid': 'intervention' }}
                            value={intervention}
                            onChange={({ target: { value } }) => setData(value)}
                            placeholder={TEXT_PLACE}
                            label="Intervention"
                            variant="outlined"
                        />
                    </Fragment>
                )}
            </section>
            <section className="buttonContent clearfix">
                <Button
                    data-testid="addNewAction"
                    classes={{ root: c({ active: active || actionCreated }) }}
                    onClick={addNewAction}
                >
                    {actionCreated ? OK_TEXT : ADD_ACTION}
                </Button>
            </section>
        </Dialog>
    );
};

AddActionModal.propTypes = {
    optionListInfo: PropTypes.object.isRequired,
    optionData: PropTypes.object.isRequired,
    actionError: PropTypes.string.isRequired,
    actionCreated: PropTypes.bool.isRequired,
    actionLoading: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    addCompAction: PropTypes.func.isRequired,
    resetActionRedux: PropTypes.func.isRequired,
};

export default AddActionModal;
