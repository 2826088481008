import React, { useState } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';
import CloseIcon from '../assets/CloseYellow.svg';
import Bulb from '../assets/Bulb.svg';
import QuickHelpIcon from '../assets/QuickHelp.svg';
import QuestionIcon from '../assets/QuestionIconBlack.svg';
import { getStaticTextAnalytics } from '../constants';

const QuickHelp = ({ helpView, helpMode, activePage }) => {
    const staticTextAnalytics = getStaticTextAnalytics();
    const { PAGE_WISE_HELP, SITE_TEXT } = staticTextAnalytics;
    const help = PAGE_WISE_HELP[activePage];
    const { QUICK_HELPT } = SITE_TEXT;
    const [showQuickHelp, setHover] = useState(false);

    const showImage = () => {
        if (helpView) {
            return <img src={CloseIcon} alt="" />;
        }
        if (showQuickHelp) {
            return <img src={QuickHelpIcon} alt="" />;
        }
        return <img src={Bulb} alt="" />;
    };

    const formatText = text => {
        const phrasesToBold = ['top and bottom quartile of the selected sample', 'healthy from unhealthy'];
        const regex = new RegExp(`(${phrasesToBold.join('|')})`, 'g');
        const parts = text.split(regex);

        return (
            <>
                {parts.map((part, index) => {
                    if (phrasesToBold.includes(part)) {
                        return <b key={index}>{part}</b>;
                    }
                    return part;
                })}
            </>
        );
    };

    return (
        <div className={c({ helpView }, 'quickHelpContent')}>
            <div className={c({ backdropRoot: helpView })} />
            <div
                className="helpBtn"
                data-testid="helpBtn"
                onClick={() => helpMode(!helpView)}
                onPointerEnter={() => setHover(true)}
                onPointerLeave={() => setHover(false)}
            >
                {showImage()}
            </div>
            {helpView && (
                <div className="helpDialog">
                    <h3>
                        <span>
                            <img src={QuestionIcon} alt="" />
                        </span>
                        {QUICK_HELPT}
                    </h3>
                    <ul className="clearfix helpQuestions">
                        {help.map(({ helpQues, helpText, helpTexts = [] }) => {
                            return (
                                <li>
                                    <div>
                                        <span />
                                        {activePage !== '5' && (
                                            <span>
                                                <strong>{helpQues}</strong>
                                                <br />
                                                <div className="helpGraph">{helpText}</div>
                                            </span>
                                        )}
                                    </div>

                                    <div>
                                        <span />
                                        {activePage === '5' && (
                                            <span>
                                                <strong>{helpQues}</strong>
                                                <br />
                                                <div className="helpGraph">{formatText(helpText)}</div>
                                            </span>
                                        )}
                                    </div>
                                    {helpTexts.length ? (
                                        <ul>
                                            {helpTexts.map(
                                                ({ helpQues: quickHelpQues = '', helpText: quickHelpText = '' }) => {
                                                    return (
                                                        <>
                                                            <div className="listing" />
                                                            <div className="helpTextContent">
                                                                <div className="subHelpQues">
                                                                    <ul>
                                                                        <li>{quickHelpQues}</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="subHelpText">
                                                                    <ul>
                                                                        <li>{quickHelpText}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    ) : null}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

QuickHelp.propTypes = {
    helpView: PropTypes.bool.isRequired,
    helpMode: PropTypes.func.isRequired,
    activePage: PropTypes.string.isRequired,
};

export default QuickHelp;
