import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Button, Dialog, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import AuthStore from '../../common/AuthStore';
import DwnldImg from '../../assets/DownloadImg.svg';
import Loader from '../../assets/DwnldLoader.svg';
import Completed from '../../assets/DwnldComplete.svg';
import { getStaticText } from '../../utils/constants';
import './index.scss';

let timeoutAutoComplete;
export default function DwnldModal({
    getRawPptData,
    reportId,
    open,
    setOpen,
    singlePastReport,
    getSinglePastReport,
    LatamHide,
    defaultSettings,
}) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { REPORT_TEXT, DOWNLOADING_TEXT, REPORT_VIEW } = SITE_TEXT;
    const [dwnldModal, setDwnldModal] = useState(false);
    const { id, export_name, status: pptStatus = '', is_json_created: status = false } = singlePastReport || {};

    useEffect(() => {
        timeoutAutoComplete = setInterval(() => {
            const { status: pptStatus2 = '', is_json_created } = singlePastReport || {};
            if (pptStatus2 === 'failed') {
                setOpen(false);
                return () => clearInterval(timeoutAutoComplete);
            }
            if (is_json_created) {
                setDwnldModal(true);
                return clearInterval(timeoutAutoComplete);
            }
            getSinglePastReport(reportId);
            return null;
        }, 2000);
        return () => clearInterval(timeoutAutoComplete);
        // eslint-disable-next-line
    }, [status, pptStatus, singlePastReport]);

    const getExport = () => {
        const { pptAccessToken = '' } = AuthStore;
        const taskIdToUse = Math.ceil(Math.random() * 1000000);
        getRawPptData({ id, pptAccessToken, taskIdToUse, export_name, fileToDownload: false });
        setOpen(false);
    };

    return open ? (
        <Fragment>
            <div className="infoToastContainer">
                <Dialog
                    open
                    disableBackdropClick
                    maxWidth="sm"
                    classes={{
                        root: 'DownloadDialogRoot',
                    }}
                >
                    <div className="top">
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpen(false)}>
                            <Close fontSize="small" />
                        </IconButton>
                    </div>
                    <div>
                        <h2>
                            {REPORT_TEXT} {dwnldModal ? DOWNLOADING_TEXT['1'] : DOWNLOADING_TEXT['2']}
                        </h2>
                        <div>{DOWNLOADING_TEXT['3']}</div>
                        <div>{DOWNLOADING_TEXT['4']}</div>
                        <div className="imageWrapper">
                            <img src={DwnldImg} alt="" />
                            {!dwnldModal ? (
                                <span>
                                    <img className="spinLoader" src={Loader} alt="" />
                                </span>
                            ) : (
                                <span>
                                    <img src={Completed} alt="" />
                                </span>
                            )}
                        </div>
                        <div className="buttonContent clearfix">
                            {!LatamHide && (
                                <Button classes={{ root: c({ active: true }) }}>
                                    {' '}
                                    <Link to="/reports">{REPORT_VIEW}</Link>
                                </Button>
                            )}
                            <Button classes={{ root: c({ active: dwnldModal }) }} onClick={() => getExport()}>
                                {DOWNLOADING_TEXT['5']}
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </div>
        </Fragment>
    ) : null;
}

DwnldModal.defaultProps = {
    LatamHide: false,
};

DwnldModal.propTypes = {
    getRawPptData: PropTypes.func.isRequired,
    reportId: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    singlePastReport: PropTypes.object.isRequired,
    LatamHide: PropTypes.bool,
    defaultSettings: PropTypes.object.isRequired,
};
