import { GET_SETTINGS, UPDATE_SETTINGS, RESET_ERROR, CHANGE_USER_VIEW } from './constants';

export const getSettings = payload => ({
    type: `${GET_SETTINGS}_PENDING`,
    payload,
});

export const getSettingsSuccess = payload => ({
    type: `${GET_SETTINGS}_SUCCESS`,
    payload,
});

export const getSettingsFailed = (payload = {}) => ({
    type: `${GET_SETTINGS}_FAILED`,
    payload,
});

export const updateSettings = payload => ({
    type: `${UPDATE_SETTINGS}_PENDING`,
    payload,
});

export const updateSettingsSuccess = () => ({
    type: `${UPDATE_SETTINGS}_SUCCESS`,
});

export const updateSettingsFailed = (payload = {}) => ({
    type: `${UPDATE_SETTINGS}_FAILED`,
    payload,
});

export const resetError = () => ({
    type: RESET_ERROR,
});

export const changeUser = payload => ({
    type: `${CHANGE_USER_VIEW}_PENDING`,
    payload,
});

export const changeUserSuccess = payload => ({
    type: `${CHANGE_USER_VIEW}_SUCCESS`,
    payload,
});

export const changeUserFailed = (payload = {}) => ({
    type: `${CHANGE_USER_VIEW}_FAILED`,
    payload,
});
