import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import AddOns from './AddOns';
import { getDefaultSettings } from '../../Login/selector';

const stateToProps = createStructuredSelector({
    defaultSettings: getDefaultSettings(),
});

export default connect(stateToProps)(AddOns);
