import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import LoginContent from './Login';
import login from './reducer';
import loginSagas from './sagas';
import { getAccessDetails, removeToken } from './actions';
import { selectUserData, selectResetToken } from './selector';

const dispatchToProps = {
    getAccessDetails,
    removeToken,
};

const stateToProps = createStructuredSelector({
    userData: selectUserData(),
    resetToken: selectResetToken(),
});

export { loginSagas, login };
export default connect(stateToProps, dispatchToProps)(LoginContent);
