import React from 'react';
import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { getStaticText } from '../../../utils/constants';
import colors from '../../../sass/colors';

export const UpperLegends = ({ defaultSettings }) => {
    const { lang, is_employee_experience: isOhi4 = false } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { COHESION_LEGENDS_TEXT } = SITE_TEXT || {};
    return (
        <Box className="rightside-legend">
            <Grid container spacing={3}>
                {COHESION_LEGENDS_TEXT.map((legend, index) => (
                    <Grid item xs={6} className="legend-ctrl" key={index}>
                        <Box className={`cohesion-legends ${legend.type} ${isOhi4 ? 'ohi4' : ''}`}>
                            <Box component="h3">{legend.title}</Box>
                            <Box component="p">{legend.description}</Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

UpperLegends.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
};

export const GraphLegend = ({ defaultSettings }) => {
    const { lang, is_employee_experience: isOhi4 = false } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { COHESION_GRAPH_LEGEND_TEXT, COHESION_GRAPH_LEGEND_TEXT_OHI4 } = SITE_TEXT || {};
    const legendtextToUse = isOhi4 ? COHESION_GRAPH_LEGEND_TEXT_OHI4 : COHESION_GRAPH_LEGEND_TEXT;
    return (
        <div className="practice-legend">
            {legendtextToUse.map((text, index) =>
                index === 0 ? (
                    <div key="legend_first">{text}: </div>
                ) : (
                    <div className="rectangle" key={`legend_${index}`}>
                        <div
                            className="color-box"
                            style={{
                                backgroundColor: isOhi4
                                    ? colors.$cohesionGraphOhi4[index][0]
                                    : colors.$cohesionGraph[index === 6 ? 7 : index][0],
                            }}
                        />
                        {text}
                    </div>
                )
            )}
        </div>
    );
};

GraphLegend.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
};
