import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Checkbox, FormControlLabel } from '@material-ui/core';
import c from 'classnames';

import { getStaticText } from '../../../utils/constants';

function AdditionalSettings({ reportIndex, handleCreateReport, goBack, defaultSettings, scoreData, isNonProfit }) {
    const { lang, threshold = 0, ohi_text } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        ADDITIONAL_SETTINGS,
        CUSTOMISE_EXPORT_REPORT,
        BACK,
        CREATE_REPORT,
        DYNAMIC_TEXT_INFO,
        LOW_THRESHOLD,
        ADDITIONAL_SETTING_OPTIONS,
    } = SITE_TEXT || {};
    const { REPORT_TYPE } = DYNAMIC_TEXT_INFO({ ohi_text });
    const additionalOptions = isNonProfit
        ? [...ADDITIONAL_SETTING_OPTIONS.slice(0, 2), ...ADDITIONAL_SETTING_OPTIONS.slice(4)]
        : ADDITIONAL_SETTING_OPTIONS;
    const { qbyq = {} } = scoreData;
    const { size = 0 } = qbyq;
    const active = reportIndex > 3 || size > threshold;
    return (
        <section>
            <h2>{REPORT_TYPE[reportIndex]}</h2>
            <span className="subHeading">{ADDITIONAL_SETTINGS}</span>
            <div className="enterpriseWrapper">
                <div className="title">{CUSTOMISE_EXPORT_REPORT}</div>
                <FormGroup classes={{ root: c('enterpriseCheckBox') }} row>
                    {additionalOptions.map(({ label, key }, i) => (
                        <FormControlLabel
                            key={`${key}${i}`}
                            control={<Checkbox disableRipple name="checkedA" />}
                            label={label}
                        />
                    ))}
                </FormGroup>
            </div>
            {!active && <div className="errorInfo">{LOW_THRESHOLD}</div>}
            <div className="buttonWrapper">
                <div className="outlineBtn" onClick={goBack}>
                    {BACK}
                </div>
                <div className={c('greenBtn', { active })} onClick={handleCreateReport}>
                    {CREATE_REPORT}
                </div>
            </div>
        </section>
    );
}

AdditionalSettings.propTypes = {
    handleCreateReport: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    reportIndex: PropTypes.number.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    scoreData: PropTypes.object.isRequired,
    isNonProfit: PropTypes.bool.isRequired,
};

export default AdditionalSettings;
