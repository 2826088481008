import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import EngagementBoard from './EngagementBoard';

import {
    getEngagementData,
    getEngagementDemographicData,
    setEngagementDropdown,
    getScoreData,
    getPptData,
} from '../../actions';

import {
    selectApiParams,
    selectApiCount,
    getErrorInfo,
    selectFiltersApplied,
    selectFilters,
    selectEngagementData,
    selectEngagementDataFetched,
    selectEngagementDemographicData,
    selectEngagementDemographicDataFetched,
    selectEngagementDropdown,
    selectScoreData,
    selectScoreDataFetched,
} from '../../selector';

import { getDefaultSettings } from '../../../Login/selector';

const stateToProps = createStructuredSelector({
    engagementData: selectEngagementData(),
    engagementDataFetched: selectEngagementDataFetched(),
    engagementDemographicData: selectEngagementDemographicData(),
    engagementDemographicDataFetched: selectEngagementDemographicDataFetched(),
    engagementDropdown: selectEngagementDropdown(),
    apiParams: selectApiParams(),
    apiLoadingCount: selectApiCount(),
    errorInfo: getErrorInfo(),
    activeFilter: selectFiltersApplied(),
    defaultSettings: getDefaultSettings(),
    filters: selectFilters(),
    scoreData: selectScoreData(),
    scoreDataFetched: selectScoreDataFetched(),
});

const dispatchToProps = {
    getEngagementData,
    getEngagementDemographicData,
    setEngagementDropdown,
    getScoreData,
    getPptData,
};

export default connect(stateToProps, dispatchToProps)(EngagementBoard);
