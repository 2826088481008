import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import c from 'classnames';
import { Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './index.scss';
import AlignmentImg from '../assets/AlignmentImage.png';
import ExecutionImg from '../assets/ExecutionImage.png';
import RenewalImg from '../assets/RenewalImage.png';
import colors from '../../../sass/colors';
import { getBenchmarkName, getPptValues, replacePssKeys, getPptValue } from '../../../utils/functions';
import Down from '../assets/Down.svg';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import getStaticTextDiagnose from '../constants';
import AuthStore from '../../../common/AuthStore';
import DwnldModal from '../../../components/InformationToast/DwnldModal';
import { getStaticText } from '../../../utils/constants';

function ProfileModal({
    defaultSettings,
    showDialog,
    setShowProfileDialog,
    totalProfileSize,
    profileData,
    errorInfo,
    apiParams,
    getReport,
    scoreData,
    singlePastReport,
    getPptData,
    reportError,
    getRawPptData,
    getSinglePastReport,
    filters,
}) {
    const {
        lang,
        quartileColors,
        survey_version = '',
        demographic = '',
        report_name_or_client_name: rName = '',
        report_id = '',
        checkme_mapping = {},
        is_ohi_pss_module,
        template_name: template_file_name,
        report_name = '',
        engagement = '',
    } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { PERCENTILE } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, SINGLE_PAGE_EXPORT_PARAMS, PROFILE_REPLACEMENTS, NON_PROFIT_SURVEY } = staticText;
    const updatedCheckmeMapping = replacePssKeys(checkme_mapping, is_ohi_pss_module, PROFILE_REPLACEMENTS, 'profile');
    const {
        BENCHMARK_HEAD,
        BENCHMARK_NA,
        PPT_VALUES,
        SOURCE_TEXT,
        NUMBER_TEXT,
        NUMBER_SURVEY_TEXT,
        PPT_RES_TEXT,
        PPT_RES_TEXT2,
        PPT_RES_TEXT3,
        ALL_PPT_TEXT,
        OUT_PRT,
        PROFILE_VIEW,
        SCORECARD_GROUP_HEAD,
        DOWNLOAD_TEXT,
        NUMBER_OF_RESPONDENTS_N,
    } = SITE_TEXT || {};
    const isOhi4 = survey_version === '4' || survey_version === '3_2';
    const [dwnld, setdwnld] = useState(false);
    const { qbyq = {} } = scoreData;
    const { records = {}, size, n_respondents, n_survey } = qbyq;
    const { report_type } = apiParams;
    const isPercentile = report_type === PERCENTILE;
    const [hoveredCard, setHoveredCard] = useState(null);
    // new
    const isNonProfit = engagement === NON_PROFIT_SURVEY;

    const getPpt = (outcomeArray = [], prtArray = []) => {
        const { pptAccessToken = '' } = AuthStore;
        const output = {
            output_file_name: 'data.pptx',
            template_file_name,
            slides: [
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'outcome_profile_checkme',
                    data: outcomeArray,
                },
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'practice_profile_checkme',
                    data: prtArray,
                },
            ],
        };
        getPptData(output, pptAccessToken, `${report_name} ${OUT_PRT} Profile`);
    };

    const PROFILE_GROUP = ['Text1', 'Text2', 'Text3', 'Text4', 'Text5'];

    const getDataForPpt = () => {
        const { GLOBAL_VALS, profile } = PPT_VALUES({ rName });
        const { benchmarks = [] } = filters[0];
        const label = getBenchmarkName(benchmarks);
        const pptArray = [
            {
                name: 'source',
                value: `${SOURCE_TEXT}: ${rName} (${NUMBER_TEXT}=${size}); ${BENCHMARK_HEAD}: ${label} (${NUMBER_TEXT}=${n_respondents}, ${NUMBER_SURVEY_TEXT}=${n_survey})`,
            },
            {
                name: 'Checkme',
                is_delete: 'true',
            },
            ...getPptValues(GLOBAL_VALS),
        ];

        const outcomeArray = [
            ...pptArray,
            {
                name: 'Title_text',
                value: profile.outcomeTitle,
            },
            {
                name: '3. Subtitle',
                value: isPercentile ? PPT_RES_TEXT3 : PPT_RES_TEXT,
            },
            {
                name: 'Rectangle 3',
                value: ALL_PPT_TEXT[23],
            },
        ];
        const prtArray = [
            ...pptArray,
            {
                name: 'Title_text',
                value: profile.practiceTitle,
            },
            {
                name: '3. Subtitle',
                value: isPercentile ? PPT_RES_TEXT3 : PPT_RES_TEXT2,
            },
        ];

        Object.keys(profileData).forEach(innerContent => {
            const valueInRecord = records[innerContent];
            const { children = {}, display_name = '' } = valueInRecord;
            const parentArray = getPptValue(quartileColors, valueInRecord, innerContent, true, false);
            outcomeArray.push(...parentArray);
            prtArray.push({
                name: `${innerContent}_text`,
                value: display_name,
            });
            Object.keys(children).forEach(childVal => {
                const valueInChild = children[childVal];
                const childArray = getPptValue(quartileColors, valueInChild, childVal, false, true);
                prtArray.push(...childArray);
            });
        });
        getPpt(outcomeArray, prtArray);
    };

    const v4PptDownload = () => {
        const addOns = {
            fill_threshold_with: demographic === '1' ? '-' : '',
            others: demographic === '3' ? 1 : 0,
        };
        const { benchmarks = [] } = filters[0];
        const label = getBenchmarkName(benchmarks);
        const valueToSend = {
            ...SINGLE_PAGE_EXPORT_PARAMS({ BENCHMARK_NAME: label }),
            ...addOns,
            report_name: rName,
            ...apiParams,
            report_id,
            single_page_meta: {
                checkme: updatedCheckmeMapping.profile,
                name: 'profile',
            },
        };
        setdwnld(true);
        getReport(valueToSend);
    };

    const newData = index => {
        if (index === 0) {
            return Object.keys(profileData).slice(0, 1);
        }
        if (index === 1) {
            return Object.keys(profileData).slice(3, 5);
        }
        if (index === 2) {
            return Object.keys(profileData)
                .slice(8, 9)
                .concat(Object.keys(profileData).slice(1, 2))
                .concat(Object.keys(profileData).slice(7, 8));
        }
        if (index === 3) {
            return Object.keys(profileData).slice(5, 7);
        }

        return [];
    };

    const profileCardText = [
        {
            heading: "Are people aligned around the organization's visions, strategy, culture, and values?",
            name: SCORECARD_GROUP_HEAD[0],
            profileImg: AlignmentImg,
        },

        {
            heading:
                'Can employees fulfill their roles with the current capabilities, processes, and motivation level?',
            name: SCORECARD_GROUP_HEAD[1],
            profileImg: ExecutionImg,
        },

        {
            heading:
                'How does the organization understand, interact, respond, and adapt to its situation and external environment?',
            name: SCORECARD_GROUP_HEAD[2],
            profileImg: RenewalImg,
        },
    ];

    const getProfileOutcome = dataSet => {
        const { display_name, score, score_percentile = '', quartile } = profileData[dataSet];
        const { background, color, border: borderColor = colors.$grey200 } = quartileColors[quartile] || {};
        const newItem1 = [];
        if (hoveredCard === 0) {
            newItem1.push(Object.keys(profileData).slice(0, 3));
        } else if (hoveredCard === 1) {
            newItem1.push(Object.keys(profileData).slice(3, 7));
        } else if (hoveredCard === 2) {
            newItem1.push(Object.keys(profileData).slice(7, 9));
        } else {
            newItem1.push([]);
        }

        const newObj = newItem1[0] || [];
        return (
            <div className="profileOutcome">
                <div
                    style={{
                        background: isOhi4
                            ? `linear-gradient(135deg, ${borderColor} 25%, ${background} 25%)`
                            : background,
                        color,
                        borderColor: isOhi4 ? 'transparent' : borderColor,
                        borderWidth: quartile === '' ? 1 : 2,
                        borderStyle: isOhi4 ? 'none' : 'solid',
                        position: 'relative',
                    }}
                    className={`profileTabOutcome ${newObj.includes(dataSet) ? 'highlighted' : ''}
                    ${!newObj.includes(dataSet) && newObj.length > 0 ? 'noneHighlight' : ''}
                    ${newObj.includes(dataSet) && hoveredCard === 1 ? 'exeHighlight' : ''}
                    `}
                >
                    <div className="outcomeTab">{display_name}</div>
                    <div className="outcomeTab">{score_percentile || score}</div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (hoveredCard) {
            setHoveredCard(hoveredCard);
        }
    }, [hoveredCard]);

    const handleCardHover = index => {
        setHoveredCard(index);
    };

    return (
        <div className="scoreBoard profileContent clearfix">
            <Helmet>
                <title>Health Profile Page</title>
            </Helmet>

            <div className="rightScorecardHeader profileSection">
                <Dialog open={showDialog} maxWidth="md" fullWidth className="profileDialog">
                    <div>
                        <div className="profileContent">
                            <div className="iconBtn">
                                <IconButton
                                    aria-label="close"
                                    className="closeButton"
                                    onClick={() => setShowProfileDialog(false)}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>

                            <div className="profileTab">
                                <div className="profileTabs">
                                    <div className="profileHeading">{PROFILE_VIEW}</div>
                                    <div>
                                        <div className="nRes">
                                            {NUMBER_OF_RESPONDENTS_N} : <span>{totalProfileSize}</span>
                                        </div>
                                    </div>
                                </div>

                                {!isNonProfit && (
                                    <ul className="profileList">
                                        {!errorInfo && (
                                            <li
                                                data-testid="getPptButton"
                                                onClick={isOhi4 ? v4PptDownload : getDataForPpt}
                                                className="resurveyImg"
                                            >
                                                <img src={Down} alt="" />
                                                <span className="lineHead upperHead">{DOWNLOAD_TEXT}</span>
                                            </li>
                                        )}
                                    </ul>
                                )}
                            </div>
                        </div>
                        {errorInfo && <ErrorToast message={errorInfo} />}
                        {dwnld && !reportError && (
                            <DwnldModal
                                getRawPptData={getRawPptData}
                                reportId={report_id}
                                open={dwnld}
                                setOpen={setdwnld}
                                getSinglePastReport={getSinglePastReport}
                                singlePastReport={singlePastReport}
                                defaultSettings={defaultSettings}
                            />
                        )}
                        <div>
                            {/* <div className="profileAlignContent">
                                {profileCardText.map((item, index) => (
                                    <div
                                        key={index}
                                        onMouseEnter={() => handleCardHover(index)}
                                        onMouseLeave={() => handleCardHover(null)}
                                        className="profileAlign"
                                    >
                                        <img src={item.profileImg} alt="" />
                                        <div>{item.name}</div>
                                    </div>
                                ))}
                            </div>
                            <div className="mainProfileContent">
                                {hoveredCard !== null ? (
                                    <div className="profileHeading">{profileCardText[hoveredCard].heading}</div>
                                ) : (
                                    <div className="profileHeader" />
                                )}

                                <div className="profileOutcomeSection">
                                    {PROFILE_GROUP.map((text, index) => {
                                        const finalObj =
                                            index === 4 ? Object.keys(profileData).slice(2, 3) : newData(index);
                                        return (
                                            <div style={{ display: 'flex', gap: '0.2vh' }}>
                                                {finalObj.map((dataSet, dataSetIndex) => {
                                                    return <div>{getProfileOutcome(dataSet, dataSetIndex)}</div>;
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div> */}

                            {/* new  */}
                            <div className="profileModal">
                                <div className="profileAlignContent">
                                    {profileCardText.map((item, index) => (
                                        <div
                                            key={index}
                                            onMouseEnter={() => handleCardHover(index)}
                                            onMouseLeave={() => handleCardHover(null)}
                                            className="profileAlign"
                                        >
                                            <img src={item.profileImg} alt="" />
                                            <div>{item.name}</div>
                                        </div>
                                    ))}
                                </div>
                                <div className="mainProfileContent">
                                    {hoveredCard !== null ? (
                                        <div className="profileHeading">{profileCardText[hoveredCard].heading}</div>
                                    ) : (
                                        <div className="profileHeader" />
                                    )}

                                    <div className="profileOutcomeSection">
                                        {PROFILE_GROUP.map((text, index) => {
                                            const finalObj =
                                                index === 4 ? Object.keys(profileData).slice(2, 3) : newData(index);
                                            return (
                                                <div style={{ display: 'flex', gap: '0.2vh' }}>
                                                    {finalObj.map((dataSet, dataSetIndex) => {
                                                        return <div>{getProfileOutcome(dataSet, dataSetIndex)}</div>;
                                                    })}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div>
                                <ul className="benchmark clearfix">
                                    <li>{BENCHMARK_HEAD} : </li>
                                    {quartileColors
                                        .slice()
                                        .reverse()
                                        .map(({ title, background, border: borderColor }) => {
                                            const bottomDecileVar = title === 'Bottom decile';
                                            const BenchmarkTitle = title === 'No benchmarks';
                                            return (
                                                <li
                                                    key={title}
                                                    style={{ color: background }}
                                                    className={c({
                                                        hide: bottomDecileVar || BenchmarkTitle,
                                                    })}
                                                >
                                                    <span
                                                        className="benchmarkTriangle"
                                                        style={{
                                                            background,
                                                            borderWidth: '13px 13px 0 0',
                                                            borderColor: bottomDecileVar
                                                                ? `transparent ${borderColor} transparent transparent`
                                                                : `${borderColor} transparent transparent transparent`,
                                                            borderStyle: 'solid',
                                                        }}
                                                    />
                                                    <div style={{ color: 'black' }}>{title}</div>
                                                </li>
                                            );
                                        })}
                                    <li>
                                        <span style={{ background: colors.$white, border: '1.5px solid #e6e6e6' }} />
                                        {BENCHMARK_NA}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
}

ProfileModal.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    showDialog: PropTypes.bool.isRequired,
    setShowProfileDialog: PropTypes.func.isRequired,
    totalProfileSize: PropTypes.number.isRequired,
    profileData: PropTypes.object.isRequired,
    errorInfo: PropTypes.string.isRequired,
    apiParams: PropTypes.object.isRequired,
    getReport: PropTypes.func.isRequired,
    scoreData: PropTypes.object.isRequired,
    singlePastReport: PropTypes.object.isRequired,
    getPptData: PropTypes.func.isRequired,
    reportError: PropTypes.string.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
};

export default ProfileModal;
