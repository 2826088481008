import { SET_BM_VERSION_IN_USE } from '../Reports/constants';
import {
    SET_CURRENT_OPTION,
    SET_GAP_DATA_FETCHED,
    SET_DROPDOWN_VALUE,
    SET_SCORE_COLOR,
    UPDATE_INCLUSION_FILTERS,
    GET_ALL_GAP_INCLUSION_DATA,
    SET_PAGE_DATA_FETCHED,
    GET_MAPPED_PROJECTS,
    UPDATE_INCLUSION_RES_FILTERS,
    SET_PREVIOUS_SURVEY,
    SET_DEMO_COMPARISON_SURVEY,
    SET_RESURVEY_DROPDOWN_VALUE,
    GET_RES_ALL_GAP_INCLUSION_DATA,
} from './constants';

export const setCurrentOption = payload => ({
    type: SET_CURRENT_OPTION,
    payload,
});

export const setGapDataFetched = payload => ({
    type: SET_GAP_DATA_FETCHED,
    payload,
});

export const setDropDownValue = payload => ({
    type: SET_DROPDOWN_VALUE,
    payload,
});

export const setResurveyDropDownValue = payload => ({
    type: SET_RESURVEY_DROPDOWN_VALUE,
    payload,
});

export const updateInclusionFilters = payload => ({
    type: UPDATE_INCLUSION_FILTERS,
    payload,
});

export const updateResurveyFilters = payload => ({
    type: UPDATE_INCLUSION_RES_FILTERS,
    payload,
});

export const setScoreColor = (payload = {}) => ({
    type: SET_SCORE_COLOR,
    payload,
});

export const getAllGapInclusionData = payload => ({
    type: `${GET_ALL_GAP_INCLUSION_DATA}_PENDING`,
    payload,
});

export const getGapAllInclusionDataSuccess = payload => ({
    type: `${GET_ALL_GAP_INCLUSION_DATA}_SUCCESS`,
    payload,
});

export const getGapAllInclusionDataFailed = (payload = {}) => ({
    type: `${GET_ALL_GAP_INCLUSION_DATA}_FAILED`,
    payload,
});

export const getResAllGapInclusionData = payload => ({
    type: `${GET_RES_ALL_GAP_INCLUSION_DATA}_PENDING`,
    payload,
});

export const getResAllGapInclusionDataSuccess = payload => ({
    type: `${GET_RES_ALL_GAP_INCLUSION_DATA}_SUCCESS`,
    payload,
});

export const getResAllGapInclusionDataFailed = (payload = {}) => ({
    type: `${GET_RES_ALL_GAP_INCLUSION_DATA}_FAILED`,
    payload,
});

export const setBmVersionInUse = payload => ({
    type: SET_BM_VERSION_IN_USE,
    payload,
});

export const setpageDataFetched = () => ({
    type: SET_PAGE_DATA_FETCHED,
});

export const getIaProjects = payload => ({
    type: `${GET_MAPPED_PROJECTS}_PENDING`,
    payload,
});

export const setPrevSurvey = payload => ({
    type: SET_PREVIOUS_SURVEY,
    payload,
});

export const setDemoComparisonSurvey = payload => ({
    type: SET_DEMO_COMPARISON_SURVEY,
    payload,
});

export const getProjectsSuccess = payload => ({
    type: `${GET_MAPPED_PROJECTS}_SUCCESS`,
    payload,
});

export const getProjectsFailed = payload => ({
    type: `${GET_MAPPED_PROJECTS}_FAILED`,
    payload,
});
