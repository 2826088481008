import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import PracticeDemographics from '../../../components/FilterBar/PracticeDemographics';
import { getStaticText } from '../../../utils/constants';
import { DEMO_IMAGES } from '../constants';
import ImageModal from '../../../components/ImageModal';
import viewIcon from '../assets/viewIcon.svg';

function PracticeOptions({ goBack, submitInclusionReport, reportIndex, inclusionData, defaultSettings, setLastMenu }) {
    const { lang, ohi_text } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { PRACTICE_OPTIONS, VIEW_SAMPLE_SLIDE, BACK, CREATE_REPORT, DYNAMIC_TEXT_INFO, PRACTICE_OPTION_SUBHEADING } =
        SITE_TEXT || {};
    const { REPORT_TYPE } = DYNAMIC_TEXT_INFO({ ohi_text });
    const [modal, setModal] = useState(0);
    const [practiceDemo, setPracticeDemo] = useState({});
    const isActive = true;

    const compare = (a, b) => {
        if (a.level_sort_order < b.level_sort_order) {
            return -1;
        }
        if (a.level_sort_order > b.level_sort_order) {
            return 1;
        }
        return 0;
    };

    useEffect(() => {
        const inclusionObj = {};
        // eslint-disable-next-line no-unused-vars
        const formattedInclusionData =
            Object.keys(inclusionData).map(key => {
                const { children = {} } = inclusionData[key] || {};
                Object.keys(children).forEach(subkey => {
                    const { level = '' } = children[subkey];
                    children[subkey].key = subkey;
                    if (inclusionObj[level]) {
                        inclusionObj[level].push(children[subkey]);
                    } else {
                        inclusionObj[level] = [];
                        inclusionObj[level].push(children[subkey]);
                    }
                });
                return inclusionData;
            })[0] || {};
        Object.keys(inclusionObj).forEach(key => inclusionObj[key].sort(compare));
        setPracticeDemo(inclusionObj);
        if (isActive) {
            setLastMenu(true);
        } else {
            setLastMenu(false);
        }
        // eslint-disable-next-line
    }, []);

    const submitReport = () => {
        const arr = [];
        Object.keys(practiceDemo).forEach(value => {
            const index = arr.indexOf(practiceDemo[value]);
            if (index > -1) {
                practiceDemo[value].forEach(({ key }) => {
                    if (practiceDemo[value][index].options.indexOf(key) === -1) {
                        practiceDemo[value][index].options.push(key);
                    }
                });
            } else {
                const obj = {};
                obj.level = value;
                obj.options = [];
                practiceDemo[value].forEach(({ key = '', isSelected = false }) => {
                    if (obj.options.indexOf(key) === -1 && isSelected) {
                        obj.options.push(key);
                    }
                });
                arr.push(obj);
            }
        });
        let str = '';
        arr.forEach(({ level, options }) => {
            if (options.length) {
                str += `level:${level},options:${options.join(',')}$`;
            }
        });
        submitInclusionReport({ practice_options: str.slice(0, -1) });
    };

    return (
        <section className="outcomeWrapper">
            <div>
                <h2>{REPORT_TYPE[reportIndex]}</h2>
                <div className="subHeading">
                    <div className="pracViewHeading">
                        <div>
                            {PRACTICE_OPTIONS}
                            {/* <span>(Can select upto 3 practices from each group)</span> */}
                        </div>
                        <span className="sampleText" onClick={() => setModal(5)}>
                            <img src={viewIcon} alt="" />
                            {VIEW_SAMPLE_SLIDE}
                        </span>
                    </div>
                    <div className="subInfo">{PRACTICE_OPTION_SUBHEADING}</div>
                </div>
            </div>
            <div className="midWrapper rightSideFilters heatMapWrapper">
                <PracticeDemographics
                    defaultSettings={defaultSettings}
                    demographics={practiceDemo}
                    updateDemographics={e => setPracticeDemo(e)}
                />
            </div>
            <div className="buttonWrapperReports">
                <div className="outlineBtn" onClick={goBack}>
                    {BACK}
                </div>
                <div
                    data-test-id="greenBtn"
                    className={c('blueButton', { active: isActive })}
                    onClick={() => submitReport()}
                >
                    {CREATE_REPORT}
                </div>
            </div>
            {modal > 0 ? (
                <ImageModal
                    defaultSettings={defaultSettings}
                    imageArr={DEMO_IMAGES.filter(({ modalNo }) => modalNo === modal)}
                    closeModal={() => setModal(0)}
                />
            ) : null}
        </section>
    );
}

PracticeOptions.propTypes = {
    goBack: PropTypes.func.isRequired,
    submitInclusionReport: PropTypes.func.isRequired,
    reportIndex: PropTypes.number.isRequired,
    inclusionData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    setLastMenu: PropTypes.func.isRequired,
};

export default PracticeOptions;
