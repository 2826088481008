import * as lang_1033 from './Language/lang_1033';
import * as lang_10 from './Language/lang_10';
import * as lang_21 from './Language/lang_21';

const getStaticTextDiagnose = lang => {
    switch (lang) {
        case '1033':
            return lang_1033;
        case '10':
            return lang_10;
        case '21':
            return lang_21;
        default:
            return lang_1033;
    }
};
export default getStaticTextDiagnose;
