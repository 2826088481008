import React, { useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import AuthStore from '../../../../common/AuthStore';
import FilterSvg from '../../assets/Filter.svg';
import getStaticTextDiagnose from '../../constants';
import { getStaticText, SINGLE_PAGE_EXPORT_PARAMS } from '../../../../utils/constants';
import { getBenchmarkName, getPptValues } from '../../../../utils/functions';
import ErrorToast from '../../../../components/InformationToast/ErrorToast';
import DwnldModal from '../../../../components/InformationToast/DwnldModal';
import Down from '../../assets/Down.svg';
import ActiveFilters1 from '../../assets/ActiveFilters1.svg';

function ValuesBoard({
    errorInfo,
    apiParams,
    apiLoadingCount,
    valuesDataFetched,
    scoreDataFetched,
    getValuesData,
    scoreData,
    getScoreData,
    valuesData,
    activeFilter,
    toggleFilterView,
    defaultSettings,
    filters,
    getPptData,
    getReport,
    getRawPptData,
    getSinglePastReport,
    singlePastReport,
    reportError,
}) {
    const {
        report_name_or_client_name: rName = '',
        report_name = '',
        template_name: template_file_name,
        lang,
        survey_version = '',
        demographic = '',
        report_id = '',
        checkme_mapping = {},
    } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { VALUES_PPT_COLOR } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { qbyq = {} } = scoreData;
    const { size, n_respondents, n_survey } = qbyq;
    const {
        VALUES_TEXT,
        VALUES_MAIN_TEXT,
        VALUES_LEGEND_TEXT,
        BENCHMARK_HEAD,
        FILTERS,
        DOWNLOAD,
        SOURCE_TEXT,
        NUMBER_TEXT,
        NUMBER_SURVEY_TEXT,
        NUMBER_OF_RESPONDENTS_N,
        PPT_VALUES,
    } = SITE_TEXT || {};
    const isOhi4 = survey_version === '4' || survey_version === '3_2';
    const [dwnld, setdwnld] = useState(false);
    useEffect(() => {
        if (!valuesDataFetched && !apiLoadingCount) {
            const { ohid, filters: apiFilters } = apiParams;
            const language = Number(lang);
            getValuesData({ ohid, filters: apiFilters, lang: language });
        }
        if (!scoreDataFetched && !apiLoadingCount) {
            getScoreData(apiParams);
        }
        // eslint-disable-next-line
    }, [valuesDataFetched, apiLoadingCount, scoreDataFetched]);

    const getDataForPpt = () => {
        const { pptAccessToken = '' } = AuthStore;
        const { benchmarks = [] } = filters[0];
        const label = getBenchmarkName(benchmarks);
        const { cdvalues_checkme } = PPT_VALUES({ rName });
        const data = [
            {
                name: 'source',
                value: `${SOURCE_TEXT}: ${rName} (${NUMBER_TEXT}=${size}); ${BENCHMARK_HEAD}: ${label} (${NUMBER_TEXT}=${n_respondents}, ${NUMBER_SURVEY_TEXT}=${n_survey})`,
            },
            {
                name: 'Checkme',
                is_delete: 'true',
            },
            ...getPptValues(cdvalues_checkme),
        ];

        Object.keys(valuesData)
            .filter(x => x !== 'nSize')
            .forEach(name => {
                const table_data = [];
                valuesData[name].forEach(({ title: value, type = '' }, row_index) => {
                    const text_color = VALUES_PPT_COLOR[type] || '#000000';
                    table_data.push({
                        row_index,
                        column_index: 1,
                        is_bold: true,
                        value,
                        text_color,
                    });
                });
                data.push({
                    name,
                    is_delete: false,
                    table_data,
                });
            });
        const output = {
            output_file_name: 'data.pptx',
            template_file_name,
            slides: [
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'cdvalues_checkme',
                    data,
                },
            ],
        };

        getPptData(output, pptAccessToken, `${report_name} Values Report`);
    };

    const v4PptDownload = () => {
        const addOns = {
            fill_threshold_with: demographic === '1' ? '-' : '',
            others: demographic === '3' ? 1 : 0,
        };
        const { benchmarks = [] } = filters[0];
        const label = getBenchmarkName(benchmarks);
        const valueToSend = {
            ...SINGLE_PAGE_EXPORT_PARAMS({ BENCHMARK_NAME: label }),
            ...addOns,
            report_name: rName,
            ...apiParams,
            report_id,
            single_page_meta: {
                checkme: checkme_mapping.values,
                name: 'values',
            },
        };
        setdwnld(true);
        getReport(valueToSend);
    };

    return (
        <div className="valuesBoard clearfix" data-testid="valuesBoard">
            <div className="rightScorecardHeader">
                <div className="scorecardHeading">
                    <div className="mainHeading">{VALUES_TEXT}</div>
                    {isOhi4 && (
                        <div className="nRes">
                            {NUMBER_OF_RESPONDENTS_N} : <span>{size.toLocaleString('en-US')}</span>
                        </div>
                    )}
                </div>
                <ul className="navScoreList">
                    {!errorInfo && (
                        <li
                            data-testid="getPptBtn"
                            onClick={isOhi4 ? v4PptDownload : getDataForPpt}
                            className="resurveyImg"
                        >
                            <img src={Down} alt="" />
                            <span className="lineHead upperHead">{DOWNLOAD}</span>
                        </li>
                    )}
                    <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                        <img src={activeFilter && filters.length > 0 ? ActiveFilters1 : FilterSvg} alt="" />
                        <span className="filter">{FILTERS}</span>
                    </li>
                </ul>
            </div>
            {errorInfo && <ErrorToast message={errorInfo} />}
            {dwnld && !reportError && (
                <DwnldModal
                    getRawPptData={getRawPptData}
                    reportId={report_id}
                    open={dwnld}
                    setOpen={setdwnld}
                    getSinglePastReport={getSinglePastReport}
                    singlePastReport={singlePastReport}
                    defaultSettings={defaultSettings}
                />
            )}
            {Object.keys(valuesData).length ? (
                <Fragment>
                    <section className="dataSection">
                        {Object.keys(valuesData)
                            .filter(x => x !== 'nSize' && !x.includes('_count'))
                            .map(dataSet => {
                                const { heading = '', para = '' } = VALUES_MAIN_TEXT[dataSet] || {};
                                return (
                                    <div key={dataSet}>
                                        <h3>{heading}</h3>
                                        <p>{para}</p>
                                        <ul>
                                            {valuesData[dataSet].map(({ title, type = '' }) => (
                                                <li className={c({ ohi4: isOhi4 }, `ellipsisPro ${type}`)} key={title}>
                                                    {title}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                );
                            })}
                    </section>
                    <ul className={c({ ohi4Benchmark: isOhi4 }, 'benchmark clearfix')}>
                        <li>{BENCHMARK_HEAD} : </li>
                        {VALUES_LEGEND_TEXT.map(title => (
                            <li key={title}>
                                <span />
                                {title}
                            </li>
                        ))}
                    </ul>
                </Fragment>
            ) : null}
        </div>
    );
}

ValuesBoard.propTypes = {
    errorInfo: PropTypes.string.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    valuesDataFetched: PropTypes.bool.isRequired,
    scoreDataFetched: PropTypes.bool.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    apiParams: PropTypes.object.isRequired,
    valuesData: PropTypes.object.isRequired,
    scoreData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    getValuesData: PropTypes.func.isRequired,
    getScoreData: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    getPptData: PropTypes.func.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    reportError: PropTypes.string.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    singlePastReport: PropTypes.object.isRequired,
};

export default ValuesBoard;
