import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Inclusion from './Inclusion';
import inclusion from './reducer';

import {
    getDemographics,
    getBenchmarks,
    getIaBenchmarks,
    updateFilters,
    getBenchmarkVersion,
    getProjectsData,
} from '../Diagnose/actions';
import {
    getInclusionData,
    getAllGapData,
    updateReportApiParams,
    getInclusionDataResurvey,
    getResurveyAllGapData,
    deleteResurveyMapping,
    getMappedDemographic,
    setAvgGapToggle,
    deleteScoreComparison,
    getQbyqNsize,
} from '../Reports/actions';
import {
    setDropDownValue,
    setScoreColor,
    updateInclusionFilters,
    getIaProjects,
    updateResurveyFilters,
    setBmVersionInUse,
    setPrevSurvey,
    setDemoComparisonSurvey,
} from './actions';
import { getDefaultSettings } from '../Login/selector';
import {
    selectApiCount,
    selectApiParams,
    selectScoreDataFetched,
    selectScoreData,
    selectProjects,
    selectProjectsFetched,
} from '../Diagnose/selector';
import {
    selectIaApiParams,
    selectInclusionApiCount,
    selectInclusionQbyq,
    selectDemographicList,
    selectInclusionDataFetched,
    selectDropDownValue,
    selectAllGapData,
    selectScoreColor,
    selectFilters,
    selectFiltersApplied,
    selectResurveyDataFetched,
    selectResurveyProjectsFetched,
    selectResurveyData,
    selectResurveyAllGapData,
    selectResurveyAllGapDataFetched,
    selectBenchmarkVersions,
    selectBmVersionInUse,
    selectAllGapDataFetched,
    selectPrevSurvey,
    selectDemoComparisonSurvey,
    selectDemoComparisonYear,
    selectResurveyGapDataFetched,
    selectResurveyDemographicfetched,
    selectDemoNSize,
    selectDemographicMappedData,
    selectError,
} from './selector';
import { selectReportApiCount, selectReportApiParams, selectShowAvgGap } from '../Reports/selector';

const stateToProps = createStructuredSelector({
    demoApiCount: selectApiCount(),
    inclusionApiCount: selectInclusionApiCount(),
    apiParams: selectApiParams(),
    inclusionqbyq: selectInclusionQbyq(),
    reportApiCount: selectReportApiCount(),
    demographicList: selectDemographicList(),
    filters: selectFilters(),
    activeResetFilter: selectFiltersApplied(),
    defaultSettings: getDefaultSettings(),
    inclusionDataFetched: selectInclusionDataFetched(),
    scoreDataFetched: selectScoreDataFetched(),
    scoreData: selectScoreData(),
    dropDownValue: selectDropDownValue(),
    allGapData: selectAllGapData(),
    scoreColor: selectScoreColor(),
    iaApiParams: selectIaApiParams(),
    reportApiParams: selectReportApiParams(),
    resurveyData: selectResurveyData(),
    resurveyDataFetched: selectResurveyDataFetched(),
    resurveyAllGapData: selectResurveyAllGapData(),
    resurveyAllGapDataFetched: selectResurveyAllGapDataFetched(),
    projects: selectResurveyProjectsFetched(),
    benchmarkVersions: selectBenchmarkVersions(),
    bmVersionInUse: selectBmVersionInUse(),
    allGapDataFetched: selectAllGapDataFetched(),
    prevSurvey: selectPrevSurvey(),
    showAvgGap: selectShowAvgGap(),
    demoComparisonSurvey: selectDemoComparisonSurvey(),
    demoComparisonyear: selectDemoComparisonYear(),
    allProjects: selectProjects(),
    resurveyGapDataFetched: selectResurveyGapDataFetched(),
    projectsFetched: selectProjectsFetched(),
    resurveyDemoFetched: selectResurveyDemographicfetched(),
    demoNSize: selectDemoNSize(),
    demographicMappedData: selectDemographicMappedData(),
    error: selectError(),
});

const dispatchToProps = {
    getInclusionData,
    getDemographics,
    getBenchmarks,
    getIaBenchmarks,
    updateInclusionFilters,
    updateFilters,
    setDropDownValue,
    getAllGapData,
    setScoreColor,
    updateReportApiParams,
    getInclusionDataResurvey,
    getResurveyAllGapData,
    getIaProjects,
    deleteResurveyMapping,
    updateResurveyFilters,
    getMappedDemographic,
    getBenchmarkVersion,
    setBmVersionInUse,
    setPrevSurvey,
    setAvgGapToggle,
    setDemoComparisonSurvey,
    getProjectsData,
    deleteScoreComparison,
    getQbyqNsize,
};

export { inclusion };

export default connect(stateToProps, dispatchToProps)(Inclusion);
