import React from 'react';
import { Box } from '@material-ui/core';
import { Helmet } from 'react-helmet';

import Layout from '../../components/Layout';
import { getStaticText } from '../../utils/constants';

const staticText = getStaticText();
const { SITE_TEXT } = staticText;

function Page404() {
    const { ERROR_400_MSG } = SITE_TEXT;
    return (
        <Layout showHeader>
            <div className="settings clearfix">
                <Box>
                    <Helmet>
                        <title>404 Error</title>
                    </Helmet>
                    <h4>{ERROR_400_MSG}</h4>
                </Box>
            </div>
        </Layout>
    );
}

export default Page404;
