import { fromJS } from 'immutable';
import { getStaticText } from '../../utils/constants';
import {
    GET_ACCESS_DETAILS,
    UPDATE_PASSWORD,
    GET_DEFAULT_SETTINGS,
    UPDATE_DEFAULT_SETTINGS,
    REMOVE_SAVED_TOKEN,
    LANGUAGES_IN_USE,
} from './constants';
import { UPDATE_SETTINGS } from '../Settings/constants';

const staticText = getStaticText();
const { USER_ROLES } = staticText;
export const INITIAL_STATE = fromJS({
    count: 0,
    error: '',
    expiryTime: 0,
    passwordUpdated: false,
    resetToken: false,
    userData: fromJS({}),
    defaultSettings: fromJS({ lang: '1033' }),
    defaultSettingsFetched: false,
});

const reducer = (state = INITIAL_STATE, { type = '', payload = {} }) => {
    switch (type) {
        case `${GET_ACCESS_DETAILS}_PENDING`: {
            return state
                .update('count', count => count + 1)
                .set('error', '')
                .set('passwordUpdated', false)
                .set('userData', fromJS({}));
        }
        case `${GET_DEFAULT_SETTINGS}_PENDING`: {
            return state
                .update('count', count => count + 1)
                .set('error', '')
                .set('defaultSettings', fromJS({}));
        }
        case `${UPDATE_PASSWORD}_PENDING`: {
            return state.update('count', count => count + 1).set('error', '');
        }
        case `${GET_ACCESS_DETAILS}_SUCCESS`: {
            return state.update('count', count => count - 1).set('userData', payload);
        }
        case `${UPDATE_PASSWORD}_SUCCESS`: {
            return state.set('passwordUpdated', true).update('count', count => count - 1);
        }
        case `${GET_DEFAULT_SETTINGS}_SUCCESS`: {
            const { role, full_inclusion_access, lang } = payload;
            const langToUse = LANGUAGES_IN_USE.includes(lang) ? lang : '1033';
            const valueToUse = role === USER_ROLES.CLIENT ? false : full_inclusion_access;
            return state
                .update('count', count => count - 1)
                .set('defaultSettings', fromJS({ ...payload, full_inclusion_access: valueToUse, lang: langToUse }))
                .set('defaultSettingsFetched', true);
        }
        case UPDATE_DEFAULT_SETTINGS: {
            const { lang } = payload;
            const langToUse = LANGUAGES_IN_USE.includes(lang) ? lang : '1033';
            const payloadInUse = { ...payload, lang: langToUse };
            return state.updateIn(['defaultSettings'], oldSettings => oldSettings.merge(payloadInUse));
        }
        case `${UPDATE_SETTINGS}_SUCCESS`: {
            return state.set('defaultSettingsFetched', false);
        }
        case `${GET_ACCESS_DETAILS}_FAILED`:
        case `${UPDATE_PASSWORD}_FAILED`: {
            return state.update('count', count => count - 1).set('error', payload.error);
        }
        case REMOVE_SAVED_TOKEN: {
            return state.set('userData', fromJS({})).set('resetToken', payload);
        }
        default:
            return state;
    }
};

export default reducer;
