import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, FormControl, InputLabel, Button, Tooltip } from '@material-ui/core';
import c from 'classnames';
import moment from 'moment';
import XLSX from 'xlsx-color';

import { getStaticText } from '../../utils/constants';
import { getCrossTabBg, sortChildren } from '../../utils/functions';
import { SLICE_VALUE, RESET_TAB_REDUX } from './constants';
import DownloadIcon from '../Diagnose/assets/Download.svg';
import FilterSvg from '../Diagnose/assets/Filter.svg';
import ActiveFilter from '../Diagnose/assets/ActiveFilter.svg';
import ArrowLeft from '../Diagnose/assets/ArrowLeft.svg';

function CrossTabs({
    errorInfo,
    activeFilter,
    toggleFilterView,
    apiParams,
    surveyLoadCount,
    demographics,
    crossTabData,
    crossTabFetched,
    getCrossTab,
    crossTabDrop,
    setCrossTabsDropDown,
    renderDemographicFilter,
    originalInd,
    setIndGlobal,
    addCustomInput,
    reloadTabData,
    showUpdateTarget,
    defaultSettings,
}) {
    const { lang, rr_threshold } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        SURVEY_LINKS,
        DOWNLOAD,
        FILTERS,
        PEOPLE_RES_TEXT,
        DEMO_RES_TEXT,
        DEMOGRAPHICS,
        APPLY_SETTINGS,
        RES_HEAD,
        COMPLETE_RES_TEXT,
        TOTAL_RES,
        DEMOGRAPHIC_FILTERS,
        INSUFF_RES,
        TOTAL_RES_TEXT,
        SURVEY_TYPE,
        X_AXIS,
        FILTERS_MAIN,
        Y_AXIS,
        UPDATE_TARGET,
        NOT_ALLOWED,
        CROSS_TABS,
        RR_SURVEY_OPTIONS,
    } = SITE_TEXT || {};
    const { result = {}, n_size = 0 } = crossTabData;
    const [page, updatePage] = useState(1);
    const [leftDemo, updateLeftDemo] = useState(crossTabDrop[0]);
    const [rightDemo, updateRightDemo] = useState(crossTabDrop[1]);
    const [isSticky, setSticky] = useState(false);
    const { ind_global } = apiParams;
    const isGeneric = ind_global === 99;

    const getExport = () => {
        const manipulateReports = sheets => {
            Object.keys(sheets).forEach(val => {
                if (typeof sheets[val] === 'object') {
                    const split = sheets[val].v.split('%') || [];
                    const filterSplit = sheets[val].v.split('#') || [];
                    let align = 'left';
                    let styles = {};
                    if (
                        filterSplit.length > 1 ||
                        split.length > 1 ||
                        split[0] === NOT_ALLOWED ||
                        split[0] === INSUFF_RES
                    ) {
                        align = 'center';
                        const { background } = CROSS_TABS[getCrossTabBg(split[0])];
                        styles = {
                            fill: {
                                patternType: 'solid',
                                fgColor: { rgb: background.replace('#', '') },
                            },
                            font: {
                                sz: 12,
                            },
                        };
                    } else {
                        styles = {
                            font: {
                                bold: true,
                                sz: 14,
                            },
                        };
                    }
                    const value = sheets[val].v;
                    if (typeof value === 'string' && value.includes('%')) {
                        // Convert percentage string to number and set cell type to percentage
                        const percentageValue = parseFloat(value.replace('%', '')) / 100;
                        sheets[val].t = 'n'; // Set cell type to number
                        sheets[val].v = percentageValue;
                        sheets[val].z = '0%'; // Set cell format to percentage
                    } else if (!isNaN(Number(value, 10))) {
                        sheets[val].t = 'n'; // Set cell type to number
                        sheets[val].v = Number(value);
                    }

                    sheets[val].s = {
                        alignment: {
                            vertical: align,
                            horizontal: align,
                        },
                        ...styles,
                    };
                }
            });
        };
        const table = document.getElementById('tableData');
        const table2 = document.getElementById('tableData2');

        if (table) {
            const wb = XLSX.utils.table_to_book(table, { sheet: 'Report', raw: true });
            manipulateReports(wb.Sheets.Report);
            if (!isGeneric) {
                const ws2 = XLSX.utils.table_to_sheet(table2, { sheet: 'Report_Count', raw: true });
                XLSX.utils.book_append_sheet(wb, ws2, 'Report_Count');
                manipulateReports(wb.Sheets.Report_Count);
            }
            XLSX.writeFile(wb, `${SURVEY_LINKS[2]} ${moment().format('MM_DD_YYYY')}.xlsx`);
        }
    };

    const callDemographicsData = (x_axis_demographic, y_axis_demographic) => {
        setCrossTabsDropDown([x_axis_demographic, y_axis_demographic]);
        getCrossTab({ ...apiParams, x_axis_demographic, y_axis_demographic, threshold: rr_threshold });
        updatePage(1);
    };

    const mainKeys = sortChildren(result, 'order');

    const listenToScroll = () => {
        const headerId = document.getElementById('fixedHeader');
        const sticky = headerId ? headerId.offsetTop : 0;
        if (window.pageYOffset > sticky && window.pageYOffset > 400) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };

    useEffect(() => {
        return () => reloadTabData(RESET_TAB_REDUX[2]);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!crossTabFetched && demographics.length && !surveyLoadCount) {
            callDemographicsData(crossTabDrop[0], crossTabDrop[1]);
            updateLeftDemo(crossTabDrop[0]);
            updateRightDemo(crossTabDrop[1]);
        }
        window.addEventListener('scroll', listenToScroll);
        return () => {
            window.removeEventListener('scroll', listenToScroll);
        };
        // eslint-disable-next-line
    }, [crossTabFetched, apiParams, demographics]);

    const demographicHeader = demographics.filter(item => item.code === leftDemo).map(({ label }) => label);

    return (
        <div className="tabContent clearfix" data-testid="crossTabContent">
            <h2 className="clearfix">
                {SURVEY_LINKS[2]}
                <ul className="rightSideNav clearfix">
                    <li>
                        {TOTAL_RES_TEXT} {n_size}
                    </li>
                    {originalInd === RR_SURVEY_OPTIONS[1].code && (
                        <li>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">{SURVEY_TYPE}</InputLabel>
                                <Select
                                    value={ind_global}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                        classes: { paper: 'demoDropdown' },
                                    }}
                                    onChange={({ target: { value } }) => setIndGlobal(value)}
                                >
                                    {RR_SURVEY_OPTIONS.map(({ code = '', label = '' }) => (
                                        <MenuItem key={code} value={code}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </li>
                    )}
                    {showUpdateTarget && (
                        <li onClick={() => addCustomInput(true)}>
                            <span className="updateTargetBtn">{UPDATE_TARGET}</span>
                        </li>
                    )}
                    {!errorInfo[2] && (
                        <li data-testid="getExportBtn" onClick={getExport}>
                            <img src={DownloadIcon} alt="" /> {DOWNLOAD}
                        </li>
                    )}
                    <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                        <img src={activeFilter ? ActiveFilter : FilterSvg} alt="" />
                        {FILTERS}
                    </li>
                </ul>
            </h2>
            {errorInfo[2] && <div className="error">{errorInfo[2]} </div>}
            <section className="mainContent clearfix">
                <section className="dataContent clearfix">
                    <div className="upperHeader clearfix">
                        <div className="leftHeader">
                            <h3>
                                {DEMO_RES_TEXT} {DEMOGRAPHICS}
                            </h3>
                            <p>{PEOPLE_RES_TEXT}</p>
                        </div>
                    </div>

                    <div className="selectWrapper clearfix">
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">{X_AXIS}</InputLabel>
                            <Select
                                value={leftDemo}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                    classes: { paper: 'demoDropdown' },
                                }}
                                onChange={({ target: { value: code } }) => updateLeftDemo(code)}
                            >
                                {demographics.map(({ code = '', label = '' }) => (
                                    <MenuItem key={code} value={code}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">{Y_AXIS}</InputLabel>
                            <Select
                                value={rightDemo}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                    classes: { paper: 'demoDropdown' },
                                }}
                                onChange={({ target: { value: code } }) => updateRightDemo(code)}
                            >
                                {demographics.map(({ code = '', label = '' }) => (
                                    <MenuItem key={code} value={code}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            data-testid="getCrossTabsButton"
                            classes={{ root: 'getCrossTabsButton' }}
                            onClick={() => callDemographicsData(leftDemo, rightDemo)}
                        >
                            {APPLY_SETTINGS}
                        </Button>
                    </div>
                    {mainKeys.length ? (
                        <section className="crossTabsContent">
                            {/* <p>{leftDemo}</p> */}
                            <p>{demographicHeader}</p>

                            <div id="fixedHeader" className={c('headerWrapper clearfix', { isSticky })}>
                                <section className="clearfix">
                                    {page > 1 && (
                                        <div
                                            data-testid="arrowLeft"
                                            className="arrow"
                                            onClick={() => updatePage(page - 1)}
                                        >
                                            <img src={ArrowLeft} alt="" />
                                        </div>
                                    )}
                                    <ul>
                                        {mainKeys[0].data
                                            .slice((page - 1) * SLICE_VALUE, page * SLICE_VALUE)
                                            .map(({ display_name }) => (
                                                <Tooltip
                                                    arrow
                                                    key={display_name}
                                                    placement="top"
                                                    title={display_name}
                                                    aria-label={display_name}
                                                >
                                                    <li>{display_name}</li>
                                                </Tooltip>
                                            ))}
                                    </ul>
                                    {mainKeys[0].data.length > page * SLICE_VALUE && (
                                        <div
                                            data-testid="arrowRight"
                                            className="arrow arrowRight"
                                            onClick={() => updatePage(page + 1)}
                                        >
                                            <img src={ArrowLeft} alt="" />
                                        </div>
                                    )}
                                </section>
                            </div>
                            {mainKeys.map(({ data, display_name }) => (
                                <section key={display_name} className="clearfix">
                                    <span>{display_name}</span>
                                    <ul>
                                        {data
                                            .slice((page - 1) * SLICE_VALUE, page * SLICE_VALUE)
                                            .map(({ response_rate = 0, total = 0, completed = 0, name }) => {
                                                const { background } = CROSS_TABS[getCrossTabBg(response_rate)];
                                                if (response_rate === -1)
                                                    return (
                                                        <li key={name} style={{ background }}>
                                                            {NOT_ALLOWED}
                                                        </li>
                                                    );
                                                if (response_rate === -2)
                                                    return (
                                                        <li key={name} style={{ background }}>
                                                            {INSUFF_RES}
                                                        </li>
                                                    );
                                                if (isGeneric)
                                                    return (
                                                        <li key={name} style={{ background: '#f2f4f7' }}>
                                                            {completed}
                                                        </li>
                                                    );
                                                return (
                                                    <Tooltip
                                                        arrow
                                                        key={name}
                                                        title={
                                                            <span>
                                                                {COMPLETE_RES_TEXT} = <b>{completed}</b> <br />
                                                                {TOTAL_RES} = <b>{total}</b>
                                                            </span>
                                                        }
                                                        aria-label={name}
                                                        placement="right-start"
                                                        classes={{ popper: 'crossTapTip' }}
                                                    >
                                                        <li style={{ background }}>{response_rate}%</li>
                                                    </Tooltip>
                                                );
                                            })}
                                    </ul>
                                </section>
                            ))}

                            <table id="tableData" style={{ display: 'none' }}>
                                <tbody>
                                    <tr>
                                        <th>{FILTERS_MAIN}</th>
                                    </tr>
                                    <tr>
                                        <td>{DEMOGRAPHIC_FILTERS}</td>
                                        {renderDemographicFilter()}
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr key="blank">
                                        <th />
                                        {mainKeys[0].data.map(({ display_name }) => (
                                            <th key={display_name}>{display_name}</th>
                                        ))}
                                    </tr>

                                    {mainKeys.map(({ data, display_name }) => (
                                        <tr key={display_name} className="clearfix">
                                            <td>{display_name}</td>
                                            {data.map(({ response_rate = 0, name, completed }) => {
                                                const valueInUse = isGeneric ? completed : `${response_rate}%`;
                                                if (response_rate === -2) return <td key={name}>{INSUFF_RES}</td>;
                                                return (
                                                    <td key={name}>
                                                        {response_rate === -1 ? NOT_ALLOWED : valueInUse}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {!isGeneric ? (
                                <table id="tableData2" style={{ display: 'none' }}>
                                    <tbody>
                                        <tr>
                                            <th>{FILTERS_MAIN}</th>
                                        </tr>
                                        <tr>
                                            <td>{DEMOGRAPHIC_FILTERS}</td>
                                            {renderDemographicFilter()}
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr key="blank">
                                            <th />
                                            {mainKeys[0].data.map(({ display_name }) => (
                                                <th key={display_name}>{display_name}</th>
                                            ))}
                                        </tr>

                                        {mainKeys.map(({ data, display_name }) => (
                                            <tr key={display_name} className="clearfix">
                                                <td>{display_name}</td>
                                                {data.map(({ response_rate = 0, name, completed }) => {
                                                    if (response_rate === -2) return <td key={name}>{INSUFF_RES}</td>;
                                                    return (
                                                        <td key={name}>
                                                            {response_rate === -1 ? NOT_ALLOWED : completed}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : null}
                            <ul className="classification clearfix">
                                <li>{RES_HEAD} </li>
                                {CROSS_TABS.slice(0).map(({ background, text }) => (
                                    <li key={background}>
                                        <span style={{ background }} />
                                        {text}
                                    </li>
                                ))}
                            </ul>
                        </section>
                    ) : null}
                </section>
            </section>
        </div>
    );
}

CrossTabs.propTypes = {
    demographics: PropTypes.array.isRequired,
    errorInfo: PropTypes.array.isRequired,
    crossTabDrop: PropTypes.array.isRequired,
    showUpdateTarget: PropTypes.bool.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    crossTabFetched: PropTypes.bool.isRequired,
    apiParams: PropTypes.object.isRequired,
    crossTabData: PropTypes.object.isRequired,
    surveyLoadCount: PropTypes.number.isRequired,
    originalInd: PropTypes.number.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    getCrossTab: PropTypes.func.isRequired,
    setCrossTabsDropDown: PropTypes.func.isRequired,
    renderDemographicFilter: PropTypes.func.isRequired,
    setIndGlobal: PropTypes.func.isRequired,
    reloadTabData: PropTypes.func.isRequired,
    addCustomInput: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
};

export default CrossTabs;
