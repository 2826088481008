import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import SettingsContent from './Settings';
import settings from './reducer';
import settingsSagas from './sagas';

import { getSettings, updateSettings, resetError } from './actions';
import { selectApiCount, selectSettingsData, selectSettingsFetched, getErrorInfo } from './selector';

import { selectApiParams } from '../Diagnose/selector';
import { getDefaultSettings } from '../Login/selector';

const stateToProps = createStructuredSelector({
    apiLoadingCount: selectApiCount(),
    settingsData: selectSettingsData(),
    settingsDataFetched: selectSettingsFetched(),
    apiParams: selectApiParams(),
    errorInfo: getErrorInfo(),
    defaultSettings: getDefaultSettings(),
});

const dispatchToProps = { getSettings, updateSettings, resetError };

export { settingsSagas, settings };

export default connect(stateToProps, dispatchToProps)(SettingsContent);
