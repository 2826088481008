import { postWebApi } from '../../webapis/dashboard';
import endpoints from '../../Endpoints';

export const getUserReportsUrl = () => {
    const { apiBasePath, userReportsPath } = endpoints;
    const url = `${apiBasePath}${userReportsPath}`;
    return postWebApi(url).request;
};

export const getUserListUrl = options => {
    const { apiBasePath, userListPath } = endpoints;
    const url = `${apiBasePath}${userListPath}`;
    return postWebApi(url, options).request;
};
