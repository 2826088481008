import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import './index.scss';
import { Accordion, AccordionDetails, Button, MenuItem, Select, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { KeyboardArrowDownSharp } from '@material-ui/icons';
import InfoIcon from '../assets/info-feedback.svg';
import ArrowBack from '../assets/Small-left.svg';
import ArrowForward from '../assets/Small-right.svg';
import ArrowDoubleBack from '../assets/Double-arrow-left.svg';
import ArrowDoubleForward from '../assets/Double-arrow-right.svg';
import CloudImage from '../../Analytics/assets/InsufficientData1.svg';

function FeedbackList({
    getFeedbackList,
    feedbackList,
    defaultSettings,
    setTicketId,
    setView,
    tab,
    refreshFeedbackList,
    feedbackListFetched,
}) {
    const { user_id } = defaultSettings || {};
    const [page, setPage] = useState(1);
    const [entryCount, setEntryCount] = useState(10);

    const FEEDBACK_LIST_TABLE_HEADING = [
        {
            id: 'created_on',
            label: 'Created on',
        },
        {
            id: 'ticket_no',
            label: 'Ticket no',
        },
        {
            id: 'summary',
            label: 'Summary',
        },
        {
            id: 'issue_type',
            label: 'Type',
        },
        {
            id: 'status',
            label: 'Status',
        },
        {
            id: 'action',
            label: 'Action',
        },
    ];

    useEffect(() => {
        getFeedbackList({ user_id });
        // eslint-disable-next-line
    }, []);

    const changeView = id => {
        setView(2);
        setTicketId(id);
    };

    const lastPage = Math.ceil(feedbackList.length / entryCount);

    const refreshList = () => {
        refreshFeedbackList({ feedback_id: 0, user_id });
    };

    return (
        <div className="userReportsContent">
            <div className="optionWrapper">
                <div>
                    <ul className="leftNav clearfix">
                        {['Report feedback', 'My feedback list'].map((tabName, tabIndex) => {
                            return (
                                <li
                                    key={tabName + tabIndex}
                                    className={c({ activeLink: tabIndex === tab })}
                                    onClick={() => setView(tabIndex)}
                                >
                                    {tabName}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            <div>
                <Button
                    onClick={refreshList}
                    className="action-btn"
                    startIcon={
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path
                                d="M9.7508 2.69891C8.23407 1.14961 5.87274 0.666337 3.83016 1.59384C1.45172 2.67385 0.229074 5.33818 0.961253 7.84564C1.69343 10.3531 4.15768 11.9409 6.74358 11.5713C9.32947 11.2017 11.2503 8.98724 11.2508 6.37507C11.2508 6.16796 11.083 6.00004 10.8759 6C10.6688 5.99996 10.5008 6.16782 10.5008 6.37493C10.5004 8.61393 8.85395 10.512 6.63747 10.8288C4.42098 11.1456 2.30877 9.78467 1.68119 7.63542C1.05361 5.48617 2.10159 3.20246 4.14025 2.27673C5.95578 1.45233 8.0651 1.92825 9.3563 3.375H7.1258C6.91869 3.375 6.7508 3.54289 6.7508 3.75C6.7508 3.95711 6.91869 4.125 7.1258 4.125H10.1037C10.1077 4.12506 10.1116 4.12506 10.1156 4.125H10.1258C10.3329 4.125 10.5008 3.95711 10.5008 3.75V0.75C10.5008 0.542893 10.3329 0.375 10.1258 0.375C9.91869 0.375 9.7508 0.542893 9.7508 0.75V2.69891Z"
                                fill="currentcolor"
                            />
                        </svg>
                    }
                >
                    Refresh
                </Button>
            </div>
            <Accordion classes={{ root: 'accordionRoot' }} key="table" expanded>
                <AccordionDetails classes={{ root: 'clearfix' }}>
                    <table className="userSection">
                        <tbody className="userContent">
                            <thead className="headerSection">
                                {FEEDBACK_LIST_TABLE_HEADING.map(({ label, id }) => (
                                    <th className="headerHeading" key={id}>
                                        {label}
                                        {id === 'status' ? (
                                            <span className="infoIcon">
                                                <Tooltip
                                                    // title="Ticket Journey: <br/> 1. To Do"
                                                    classes={{ popper: 'tooltip' }}
                                                    title={
                                                        <span>
                                                            Ticket Journey: <br /> 1. To Do <br /> 2. In Progress <br />{' '}
                                                            3. QA Ready <br /> 4. Code Review <br /> 5. Done <br />
                                                        </span>
                                                    }
                                                >
                                                    <img src={InfoIcon} alt="" />
                                                </Tooltip>
                                            </span>
                                        ) : null}
                                    </th>
                                ))}
                            </thead>

                            {feedbackList
                                .slice((page - 1) * entryCount, page * entryCount)
                                .map(
                                    (
                                        {
                                            created_on = '',
                                            id: ticket_no = '',
                                            jira_number = '',
                                            type_of_bug = '',
                                            summary = '',
                                            status = '',
                                        },
                                        indexRep
                                    ) => {
                                        return (
                                            <tr key={ticket_no} className="userDataSection">
                                                <td className="userTableContent">
                                                    {moment(created_on).format('DD-MMM-YYYY')}
                                                </td>
                                                <td className="userTableContent">{jira_number}</td>
                                                <td className="userTableContent">{summary}</td>
                                                <td className="userTableContent">{type_of_bug}</td>
                                                <td className="userTableContent status">
                                                    <span
                                                        className={c({
                                                            todo: status === 'To Do',
                                                            done: status === 'Done',
                                                        })}
                                                    >
                                                        {status}
                                                    </span>
                                                </td>
                                                <td data-testid={`reportTab${indexRep}`} className="arrowContent">
                                                    <div className="reportView" onClick={() => changeView(ticket_no)}>
                                                        VIEW DETAILS
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            {!feedbackList.length && feedbackListFetched ? (
                                <div className="emptyList">
                                    <div>
                                        <h3>No Completed Tickets</h3>
                                        <img src={CloudImage} alt="" />
                                    </div>
                                </div>
                            ) : null}
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>
            {feedbackList.length > entryCount ? (
                <div className="pagination">
                    <span onClick={() => setPage(1)} className={c({ inActive: page === 1 }, 'arrowBtn')}>
                        <img src={ArrowDoubleBack} alt="" />
                    </span>
                    <span onClick={() => setPage(page - 1)} className={c({ inActive: page === 1 }, 'arrowBtn')}>
                        <img src={ArrowBack} alt="" />
                    </span>
                    {Array(lastPage)
                        .fill(0)
                        .map((_, index) => {
                            return (
                                <span key={index + 1} className={c({ activePage: page === index + 1 })}>
                                    {index + 1}
                                </span>
                            );
                        })}
                    <span onClick={() => setPage(page + 1)} className={c({ inActive: page === lastPage }, 'arrowBtn')}>
                        <img src={ArrowForward} alt="" />
                    </span>
                    <span onClick={() => setPage(lastPage)} className={c({ inActive: page === lastPage }, 'arrowBtn')}>
                        <img src={ArrowDoubleForward} alt="" />
                    </span>
                    <span className="resultsPerPage">
                        Results Per Page{' '}
                        <span>
                            <Select
                                IconComponent={KeyboardArrowDownSharp}
                                value={entryCount}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                onChange={({ target }) => {
                                    setEntryCount(target.value);
                                }}
                            >
                                {[10, 11, 12].map(pageItem => {
                                    return (
                                        <MenuItem
                                            classes={{ root: 'demographicOptions' }}
                                            key={pageItem}
                                            value={pageItem}
                                        >
                                            {pageItem}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </span>
                    </span>
                </div>
            ) : null}
        </div>
    );
}

FeedbackList.propTypes = {
    getFeedbackList: PropTypes.func.isRequired,
    feedbackList: PropTypes.array.isRequired,
    defaultSettings: PropTypes.func.isRequired,
    setTicketId: PropTypes.func.isRequired,
    setView: PropTypes.func.isRequired,
    tab: PropTypes.number.isRequired,
    refreshFeedbackList: PropTypes.func.isRequired,
    feedbackListFetched: PropTypes.bool.isRequired,
};

export default FeedbackList;
