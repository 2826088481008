import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import c from 'classnames';

import { getStaticText } from '../../utils/constants';

function Quartiles({ data, updateData, errorInfo, resetError, defaultSettings }) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { BG_COLOR, BD_COLOR, FONT_COLOR, APPLY_SETTINGS, SETTINGS_NAV_LINKS } = SITE_TEXT || {};
    const [colorPicker, togglePicker] = useState([]);
    const { customSettings = [], quartileColors: oldQuartileColors = [] } = data;
    const [quartileColors, updateColors] = useState(oldQuartileColors);
    const [active, enableSubmit] = useState(false);

    const setQuartileData = (index, selected_val) => {
        updateColors([...quartileColors.slice(0, index), selected_val, ...quartileColors.slice(index + 1)]);
        enableSubmit(true);
        if (errorInfo) {
            resetError();
        }
    };

    const saveData = () => {
        const customData = customSettings.reduce(
            (o, { api_param, selected_val }) => ({ ...o, [api_param]: selected_val }),
            {}
        );
        const dataToSend = { quartileColors: JSON.stringify(quartileColors), ...customData };
        updateData(dataToSend, { quartileColors });
        enableSubmit(false);
    };

    return (
        <div className="quartiles">
            <h3>{SETTINGS_NAV_LINKS[3]}</h3>
            {quartileColors.map((quartile, index) => {
                const { title, background, border, color } = quartile;
                return (
                    <section key={title} className="clearfix">
                        <h4>{title}</h4>
                        <div>
                            <span
                                data-testid={`bgTab${index}`}
                                style={{ background }}
                                onClick={() => togglePicker([index, 0])}
                            />
                            {colorPicker[0] === index && colorPicker[1] === 0 ? (
                                <div className="popoverReactColor">
                                    <div className="colorCover" onClick={() => togglePicker([])} />
                                    <ChromePicker
                                        disableAlpha
                                        color={background}
                                        onChange={({ hex }) =>
                                            setQuartileData(index, {
                                                ...quartile,
                                                background: hex,
                                            })
                                        }
                                    />
                                </div>
                            ) : null}
                            {BG_COLOR}
                        </div>
                        {!index && (
                            <div className="bdTile">
                                <span
                                    data-testid={`bdTab${index}`}
                                    style={{ background, borderColor: border }}
                                    onClick={() => togglePicker([index, 1])}
                                />
                                {colorPicker[0] === index && colorPicker[1] === 1 ? (
                                    <div className="popoverReactColor">
                                        <div className="colorCover" onClick={() => togglePicker([])} />
                                        <ChromePicker
                                            disableAlpha
                                            color={border}
                                            onChange={({ hex }) =>
                                                setQuartileData(index, {
                                                    ...quartile,
                                                    border: hex,
                                                })
                                            }
                                        />
                                    </div>
                                ) : null}
                                {BD_COLOR}
                            </div>
                        )}
                        <div>
                            <span
                                data-testid={`colTab${index}`}
                                style={{ background, color }}
                                onClick={() => togglePicker([index, 2])}
                            >
                                Aa
                            </span>
                            {colorPicker[0] === index && colorPicker[1] === 2 ? (
                                <div className="popoverReactColor">
                                    <div className="colorCover" onClick={() => togglePicker([])} />
                                    <ChromePicker
                                        disableAlpha
                                        color={color}
                                        onChange={({ hex }) =>
                                            setQuartileData(index, {
                                                ...quartile,
                                                color: hex,
                                            })
                                        }
                                    />
                                </div>
                            ) : null}
                            {FONT_COLOR}
                        </div>
                    </section>
                );
            })}
            <div className="buttonContent clearfix">
                <Button data-testid="qtlSaveBtn" classes={{ root: c({ active }) }} onClick={saveData}>
                    {APPLY_SETTINGS}
                </Button>
            </div>
        </div>
    );
}

Quartiles.propTypes = {
    data: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    errorInfo: PropTypes.string.isRequired,
    updateData: PropTypes.func.isRequired,
    resetError: PropTypes.func.isRequired,
};

export default Quartiles;
