import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import './index.scss';
import { Accordion, AccordionDetails, MenuItem, Select } from '@material-ui/core';
import moment from 'moment';
import { KeyboardArrowDownSharp } from '@material-ui/icons';
import { Helmet } from 'react-helmet';
import ArrowBack from '../../Diagnose/assets/Small-left.svg';
import ArrowForward from '../../Diagnose/assets/Small-right.svg';
import ArrowDoubleBack from '../../Diagnose/assets/Double-arrow-left.svg';
import ArrowDoubleForward from '../../Diagnose/assets/Double-arrow-right.svg';
import searchBar from '../../UserReports/assets/zoom.svg';
import CloudImage from '../assets/InsufficientData1.svg';
import { getStaticText } from '../../../utils/constants';
import AuthStore from '../../../common/AuthStore';
import SuccessToast from '../../../components/CustomToast/SuccessToast';
import { ACTION_BTNS_2 } from '../constants';
import { USNumFormat } from '../../../utils/functions';
import DuplicateModal from './DuplicateModal';

function CustomBmRequest({
    defaultSettings,
    tab,
    approvalRequestList,
    approvalRequestFetched,
    getApprovalRequests,
    filterParams,
    setTab,
    getCustomBmDetail,
    customBmMsg,
    setSuccessToast,
    setActionCode,
    actionCode,
    duplicateCustomBmRequest,
}) {
    const { lang = '1033' } = defaultSettings || {};
    // const staticTextAnalytics = getStaticTextAnalytics(lang);
    // const { ANALYTICS_QUARTILE_COLORS, FLAGPOLE_LINK } = staticTextAnalytics;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText || {};
    const { ANALYTIC_CUSTOM_BM_TEXT, CUSTOM_BM_ANALYSIS_TABS } = SITE_TEXT || {};
    const [min, setMinSlice] = useState(0);
    const [max, setMaxSlice] = useState(5);
    const [page, setPage] = useState(1);
    const [entryCount, setEntryCount] = useState(10);
    const [duplicateModal, setDuplicateModal] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [pageList, setFilteredData] = useState(approvalRequestList);
    const { gsodApprover } = AuthStore;
    const isApprover = gsodApprover === 'true';
    // const [customBmSelected, setCustomBmSelected] = useState({});
    // const { id: customBmId = '', name: customBmName = '' } = customBmSelected || {};
    const dateToShow = tab === 1 ? 'submitted_on' : 'approved_on';
    const submitLabel = tab === 1 ? 'Submitted On' : 'Approved On';
    const labelToShow = tab === 2 ? 'Rejected On' : submitLabel;
    const [customBmSelected, setCustomBmSelected] = useState({ id: '', name: '' });
    const { id: customBmId = '', name: customBmName = '' } = customBmSelected || {};

    const FEEDBACK_LIST_TABLE_HEADING = [
        {
            id: 'name',
            label: 'Custom Benchmark Name',
        },
        {
            id: 'no_of_surveys',
            label: 'No. of Surveys',
        },
        {
            id: 'no_of_responses',
            label: 'No. of Respondents',
        },
        {
            id: 'created_by',
            label: 'Created By',
        },
        {
            id: dateToShow,
            label: labelToShow,
        },
        {
            id: 'action',
            label: 'Action',
        },
    ];

    const getStatus = tabNo => {
        if (tabNo === 1) {
            return 'awaiting';
        }
        if (tabNo === 2) {
            return 'rejected';
        }
        if (tabNo === 6) {
            return 'approved';
        }
        return '';
    };

    const duplicateRequest = () => {
        duplicateCustomBmRequest(customBmId);
        setTab(0);
    };

    useEffect(() => {
        if (!approvalRequestFetched) {
            // debugger;
            getApprovalRequests({
                status: getStatus(tab),
                filters: filterParams,
                limit: 100,
                page: 1,
            });
        }
        // eslint-disable-next-line
    }, [approvalRequestFetched]);

    const normalizeString = str => {
        if (!str) return '';
        return str.toLowerCase().replace(/\s+/g, '');
    };

    const handleSearchChange = e => {
        const { value } = e.target;
        setSearchInput(value);
        const normalizedInput = normalizeString(value);

        const filtered = approvalRequestList.filter(({ name = '' }) => normalizeString(name).includes(normalizedInput));
        setFilteredData(filtered);
    };

    const lastPage = Math.ceil(pageList.length / entryCount);

    return (
        <div className="customBmList">
            <Helmet>
                <title>{ANALYTIC_CUSTOM_BM_TEXT['1']} </title>
            </Helmet>
            <div className="rightScorecardHeader">
                <div className="scorecardHeading">
                    <div className="mainHeading">{ANALYTIC_CUSTOM_BM_TEXT['2']}</div>
                    {/* <div className="nRes">{`${NUMBER_CAP_SURVEY_TEXT} (${NUMBER_TEXT}) : ${n_survey}`}</div> */}
                </div>
                <ul className="navScoreList">
                    {/* <li className="nRes" onClick={() => setTab(3)}>
                        Create Custom Benchmark
                    </li> */}
                    {/* <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                        <img src={!filtersApplied ? FilterSvg : ActiveFilter} alt="" />{' '}
                        <span className="filter">{FILTERS}</span>
                    </li> */}
                </ul>
            </div>
            {/* {renderfilterApplied} */}
            <div className="optionWrapper">
                <div className="differentiatorToggle">
                    <ul className="leftNav">
                        {CUSTOM_BM_ANALYSIS_TABS.map(({ tabName, index, restrictedAccess }) => {
                            if (!isApprover && restrictedAccess) return null;
                            return (
                                <li className={c({ activeLink: tab === index })} onClick={() => setTab(index)}>
                                    {tabName}
                                </li>
                            );
                        })}
                    </ul>
                    <div className="searchBar">
                        <input
                            type="text"
                            placeholder="Search"
                            value={searchInput}
                            onChange={handleSearchChange}
                            className="search"
                        />
                        <div className="searchIcon">
                            <img src={searchBar} alt="" />
                        </div>
                    </div>
                </div>
                <div className="rightOptions">
                    {/* <div>
                        <ul className="rightSideNav">
                            <li className="strong">{`Number of Surveys (n) = ${n_survey}`}</li>
                            <li
                                onClick={() => toggleFilterView(true)}
                                data-testid="filterButton"
                                className="filterButton"
                            >
                                <img src={filtersApplied ? ActiveFilter : FilterSvg} alt="" />
                                {FILTERS}
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>
            <div className="userReportsContent">
                <Accordion classes={{ root: 'accordionRoot' }} key="table" expanded>
                    <AccordionDetails classes={{ root: 'clearfix' }}>
                        <table className="userSection customBmRequestTable">
                            <tbody className="userContent">
                                <thead className="headerSection">
                                    {FEEDBACK_LIST_TABLE_HEADING.map(({ label, id }) => (
                                        <th className="headerHeading" key={id}>
                                            {label}
                                        </th>
                                    ))}
                                </thead>

                                {!!pageList.length &&
                                    pageList
                                        .slice((page - 1) * entryCount, page * entryCount)
                                        .map(
                                            (
                                                {
                                                    [dateToShow]: date = '',
                                                    name = '',
                                                    no_of_surveys = '',
                                                    no_of_responses = '',
                                                    created_by = '',
                                                    id = '',
                                                },
                                                indexRep
                                            ) => {
                                                return (
                                                    <tr key={id} className="userDataSection">
                                                        <td className="userTableContent">{name}</td>
                                                        <td className="userTableContent">
                                                            {USNumFormat(no_of_surveys)}
                                                        </td>
                                                        <td className="userTableContent">
                                                            {USNumFormat(no_of_responses)}
                                                        </td>
                                                        <td className="userTableContent ellipsisPro">{created_by}</td>
                                                        <td className="userTableContent">
                                                            {moment(date).format('DD-MMM-YYYY')}
                                                        </td>
                                                        <td
                                                            data-testid={`reportTab${indexRep}`}
                                                            className="arrowContent"
                                                        >
                                                            {tab === 6 ? (
                                                                <div className="reportView">
                                                                    {ACTION_BTNS_2.map(({ imgSrc, code }) => {
                                                                        return (
                                                                            <span
                                                                                onClick={() => {
                                                                                    if (code === 4) {
                                                                                        getCustomBmDetail(id);
                                                                                        setTab(4);
                                                                                        setActionCode(tab);
                                                                                    } else if (code === 9) {
                                                                                        setCustomBmSelected({
                                                                                            id,
                                                                                            name,
                                                                                        });
                                                                                        setDuplicateModal(true);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <img src={imgSrc} alt="" />
                                                                            </span>
                                                                        );
                                                                    })}
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className="reportView"
                                                                    onClick={() => {
                                                                        getCustomBmDetail(id);
                                                                        setTab(4);
                                                                        setActionCode(tab);
                                                                    }}
                                                                >
                                                                    View Details
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                {!pageList.length ? (
                                    <div className="emptyList">
                                        <div>
                                            <h3>No survey list available</h3>
                                            <img src={CloudImage} alt="" />
                                        </div>
                                    </div>
                                ) : null}
                            </tbody>
                        </table>
                    </AccordionDetails>
                </Accordion>
                {pageList.length > entryCount ? (
                    <div className="pagination">
                        <span
                            onClick={() => {
                                if (min - 4 <= 0) return null;
                                setPage(min - 4);
                                setMaxSlice(max - 5);
                                setMinSlice(min - 5);
                                return null;
                            }}
                            className={c({ inActive: page === 1 }, 'arrowBtn')}
                        >
                            <img src={ArrowDoubleBack} alt="" />
                        </span>
                        <span
                            onClick={() => {
                                setPage(page - 1);
                                if (page - 1 <= min) {
                                    setMaxSlice(max - 5);
                                    setMinSlice(min - 5);
                                }
                            }}
                            className={c({ inActive: page === 1 }, 'arrowBtn')}
                        >
                            <img src={ArrowBack} alt="" />
                        </span>
                        {Array(lastPage)
                            .fill(1)
                            .map((_, i) => i + 1)
                            .slice(min, max)
                            .map(index => {
                                return (
                                    <span
                                        key={index + 1}
                                        className={c({ activePage: page === index })}
                                        onClick={() => {
                                            setPage(index);
                                        }}
                                    >
                                        {index}
                                    </span>
                                );
                            })}
                        <span
                            onClick={() => {
                                setPage(page + 1);
                                if (page >= max) {
                                    setMaxSlice(max + 5);
                                    setMinSlice(min + 5);
                                }
                            }}
                            className={c({ inActive: page === lastPage }, 'arrowBtn')}
                        >
                            <img src={ArrowForward} alt="" />
                        </span>
                        <span
                            onClick={() => {
                                if (min + 5 > lastPage) return null;
                                setPage(min + 6);
                                setMinSlice(min + 5);
                                setMaxSlice(max + 5);
                                return null;
                            }}
                            className={c({ inActive: page === lastPage }, 'arrowBtn')}
                        >
                            <img src={ArrowDoubleForward} alt="" />
                        </span>
                        <span className="resultsPerPage">
                            Results Per Page{' '}
                            <span>
                                <Select
                                    IconComponent={KeyboardArrowDownSharp}
                                    value={entryCount}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    onChange={({ target }) => {
                                        setEntryCount(target.value);
                                    }}
                                >
                                    {[10, 11, 12].map(pageItem => {
                                        return (
                                            <MenuItem
                                                classes={{ root: 'demographicOptions' }}
                                                key={pageItem}
                                                value={pageItem}
                                            >
                                                {pageItem}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </span>
                        </span>
                    </div>
                ) : null}
                {customBmMsg ? (
                    <SuccessToast closeView={() => setSuccessToast('')} message={customBmMsg} actionCode={actionCode} />
                ) : null}
            </div>
            {duplicateModal ? (
                <DuplicateModal
                    setView={setDuplicateModal}
                    duplicateCustomBm={duplicateRequest}
                    customBmName={customBmName}
                />
            ) : null}
        </div>
    );
}

CustomBmRequest.propTypes = {
    getApprovalRequests: PropTypes.func.isRequired,
    approvalRequestList: PropTypes.array.isRequired,
    defaultSettings: PropTypes.func.isRequired,
    tab: PropTypes.number.isRequired,
    // refreshFeedbackList: PropTypes.func.isRequired,
    approvalRequestFetched: PropTypes.bool.isRequired,
    filterParams: PropTypes.string.isRequired,
    // renderfilterApplied: PropTypes.func.isRequired,
    setTab: PropTypes.func.isRequired,
    getCustomBmDetail: PropTypes.func.isRequired,
    // deleteCustomBm: PropTypes.func.isRequired,
    customBmMsg: PropTypes.string.isRequired,
    setSuccessToast: PropTypes.func.isRequired,
    // sendCustomBmRequest: PropTypes.func.isRequired,
    setActionCode: PropTypes.func.isRequired,
    actionCode: PropTypes.number.isRequired,
    duplicateCustomBmRequest: PropTypes.func.isRequired,
};

export default CustomBmRequest;
