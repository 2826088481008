import red from '@material-ui/core/colors/red';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';

const theme = {
    mui: createMuiTheme({
        typography: {
            fontFamily: ['"TheinhardtPan"'].join(','),
        },
        palette: {
            text: {
                primary: '#000',
            },
            primary: {
                main: '#110f5b',
            },
            secondary: {
                main: '#000',
            },
            error: {
                main: red.A400,
            },
            background: {
                default: '#fff',
            },
        },
        overrides: {
            MuiTabs: createStyles({
                root: {
                    height: '7vh !important',
                    minHeight: 0,
                },
                indicator: {
                    height: '0.5vh',
                },
            }),
        },
    }),
};

export default theme;
