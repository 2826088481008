import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import reports from './reducer';
import reportsSagas from './sagas';
import {
    selectApiCount,
    selectScoreData,
    selectApiParams,
    selectScoreDataFetched,
    selectProjectsFetched,
    selectProjects,
} from '../Diagnose/selector';
import { getScoreData, getProjectsData } from '../Diagnose/actions';
import { getDailyResponses } from '../ResponseRate/actions';
import { selectInclusionQbyq, selectInclusionDataFetched } from '../Inclusion/selector';
import {
    selectDailyResponses,
    selectDailyResFetched,
    selectLoadCount as selectResRateApiCount,
} from '../ResponseRate/selector';
import Reports from './Reports';
import { getDefaultSettings } from '../Login/selector';
import {
    selectReportApiCount,
    selectCurrentTab,
    selectRespondent,
    selectReportApiParams,
    selectPreviousRespondent,
    selectPastReports,
    selectPreviousCompRespondent,
} from './selector';
import {
    getReport,
    setCurrentTab,
    getPastReport,
    updateRespondents,
    getInclusionData,
    updatePreviousRespondent,
    getAllGapData,
    getMultiPptData,
    updatedPastRpts,
    updateCompPrevRespondent,
} from './actions';

const stateToProps = createStructuredSelector({
    currentTab: selectCurrentTab(),
    apiLoadingCount: selectApiCount(),
    reportApiCount: selectReportApiCount(),
    scoreData: selectScoreData(),
    inclusionData: selectInclusionQbyq(),
    apiParams: selectApiParams(),
    scoreDataFetched: selectScoreDataFetched(),
    projectsFetched: selectProjectsFetched(),
    projects: selectProjects(),
    respondent: selectRespondent(),
    previousRespondent: selectPreviousRespondent(),
    inclusionDataFetched: selectInclusionDataFetched(),
    defaultSettings: getDefaultSettings(),
    reportApiParams: selectReportApiParams(),
    dailyResponses: selectDailyResponses(),
    dailyResponsesFetched: selectDailyResFetched(),
    resRateApiCount: selectResRateApiCount(),
    pastReport: selectPastReports(),
    previousCompRespondent: selectPreviousCompRespondent(),
});

const dispatchToProps = {
    getReport,
    getScoreData,
    getProjectsData,
    setCurrentTab,
    getPastReport,
    updateRespondents,
    getInclusionData,
    updatePreviousRespondent,
    getAllGapData,
    getDailyResponses,
    getMultiPptData,
    updatedPastRpts,
    updateCompPrevRespondent,
};

export { reportsSagas, reports };

export default connect(stateToProps, dispatchToProps)(Reports);
