import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import Loader from '../Loader';
import AuthStore from '../../common/AuthStore';

const CallbackComponent = ({ mid, history, userData, getAccessDetails }) => {
    useEffect(() => {
        const access_token = mid.accessToken();
        if (access_token && isEmpty(userData)) {
            getAccessDetails({ access_token });
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const { accessToken = '' } = AuthStore;
        if (!isEmpty(userData) || accessToken) {
            history.push('/');
        }
        // eslint-disable-next-line
    }, [userData]);

    return <Loader position="absolute" showBackground />;
};

CallbackComponent.propTypes = {
    mid: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    getAccessDetails: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default CallbackComponent;
