import React, { useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import ReactHighcharts from 'react-highcharts';
import { getStaticText } from '../../../utils/constants';
import { chartConfig, chartConfigBenchmark } from '../constants';
import './index.scss';

function DemographicCharts({ categories, series, withBenchmark }) {
    const staticText = getStaticText();
    const { SITE_TEXT } = staticText;
    const {
        VIEW_BY,
        PRACTICES_SELECT,
        OUTCOME_SELECT,
        DEMOGRAPHICS_GRAPH_CLASSIFICATION,
        pcLegends,
        outLegends,
        CHART_TEXT,
        SCORE_CLASSIFICATION_TEXT,
    } = SITE_TEXT;
    const [graphType, setGraphType] = useState(1);
    const chartConfigToUse = withBenchmark ? chartConfigBenchmark : chartConfig;

    return (
        <div className="chartSection clearfix">
            <section className="tabBar clearfix">
                <div className="buttonWrapper">
                    <span>{`${VIEW_BY} :`}</span>
                    <div className="buttonContent" value={graphType}>
                        <button
                            type="button"
                            className={c('separateButton', { isActive: graphType === 1 })}
                            onClick={() => setGraphType(1)}
                        >
                            {OUTCOME_SELECT}
                        </button>
                        <button
                            type="button"
                            className={c('separateButton', { isActive: graphType === 2 })}
                            onClick={() => setGraphType(2)}
                        >
                            {PRACTICES_SELECT}
                        </button>
                    </div>
                </div>
            </section>
            {!withBenchmark && (
                <section className="legendContent clearfix">
                    <ul className="classification clearfix">
                        <li className="normalWeight">{SCORE_CLASSIFICATION_TEXT} </li>
                        {DEMOGRAPHICS_GRAPH_CLASSIFICATION.map(({ title, background }) => (
                            <li key={title}>
                                <div style={{ background }} />
                                <div>{title}</div>
                            </li>
                        ))}
                    </ul>
                </section>
            )}

            <section className="chartContent clearfix">
                <ReactHighcharts
                    config={{
                        ...chartConfigToUse,
                        xAxis: {
                            categories: categories[graphType],
                            gridLineWidth: 1,
                            gridZIndex: 4,
                            gridLineColor: withBenchmark ? '#dedede' : '#FFFFFF',
                            labels: {
                                autoRotation: [-90],
                            },
                        },
                        series: series[graphType],
                        yAxis: [
                            ...chartConfigToUse.yAxis,
                            {
                                opposite: true,
                                title: {
                                    text: CHART_TEXT,
                                    style: {
                                        fontSize: '9px',
                                    },
                                },
                            },
                        ],
                    }}
                />
            </section>
            <section className="subLegends clearfix">
                {graphType === 1 ? (
                    <ul>
                        {Object.keys(pcLegends).map(pcKey => (
                            <li key={pcKey}>
                                <span> {pcKey} :</span>
                                {pcLegends[pcKey]}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <ul>
                        {Object.keys(outLegends).map(outKey => (
                            <li key={outKey}>
                                <span>{outKey} :</span>
                                {outLegends[outKey]}
                            </li>
                        ))}
                    </ul>
                )}
            </section>
        </div>
    );
}

DemographicCharts.propTypes = {
    categories: PropTypes.array.isRequired,
    series: PropTypes.array.isRequired,
    withBenchmark: PropTypes.bool.isRequired,
};

export default DemographicCharts;
