import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Radio, FormGroup, RadioGroup, FormControlLabel } from '@material-ui/core';
import c from 'classnames';

import AuthStore from '../../../common/AuthStore';
import { getStaticText } from '../../../utils/constants';

function ChoseReport({
    submitReport,
    reportApiParams,
    setSideMenu,
    defaultSettings,
    isCfgClient,
    isIngClient,
    isAmmegaRpt,
    updateReportApiParams,
}) {
    const {
        inclusion_added = false,
        lang,
        ohi_text,
        export_access_list,
        ex_survey_added = false,
        ohi_influencer_access = false,
        engagement = '',
        year = '',
        report_type = '',
        survey_version = '',
        op_model_module = '',
    } = defaultSettings;
    const staticText = getStaticText(lang);
    const { NON_PROFIT_SURVEY, SITE_TEXT, USER_ROLES, CURRENT_YEAR } = staticText;
    const listAccess = export_access_list ? export_access_list.split(',') : [];
    const { REPORTS, SELECT_REPORT, NEXT, DYNAMIC_TEXT_INFO, CFG_CHOOSE_REPORT_OPTIONS } = SITE_TEXT || {};
    const { CHOOSE_REPORT_OPTIONS, ING_REPORT_OPTIONS, AMMEGA_REPORT_OPTIONS } = DYNAMIC_TEXT_INFO({
        ohi_text,
    });
    const [choseReport, setChoseReport] = useState({
        export_type: '',
        options: {
            ReportType: '',
        },
        compareReports: [{ comparisonName: '', from: '', to: '' }],
        additionalSettings: [{ reportName: '', surveyType: '' }],
    });
    const { options } = choseReport;
    const { ReportType } = options;
    const { userRole = '' } = AuthStore;
    const isAdmin = userRole && (userRole === USER_ROLES.SUPER_ADMIN || userRole === USER_ROLES.ADMIN);
    const isUserNotAdmin =
        userRole && (userRole === USER_ROLES.CLIENT || userRole === USER_ROLES.CST || userRole === USER_ROLES.USER);
    const isNonProfit = engagement === NON_PROFIT_SURVEY;
    let reportOptionsInUse = op_model_module ? CHOOSE_REPORT_OPTIONS : CHOOSE_REPORT_OPTIONS.slice(0, 8);
    if (isIngClient) {
        reportOptionsInUse = ING_REPORT_OPTIONS;
    } else if (isCfgClient) {
        reportOptionsInUse = CFG_CHOOSE_REPORT_OPTIONS;
    } else if (isAmmegaRpt) {
        reportOptionsInUse = AMMEGA_REPORT_OPTIONS;
    } else if (isNonProfit) {
        reportOptionsInUse = CHOOSE_REPORT_OPTIONS.slice(0, 1);
    }

    const handleReportData = ({ name, value }) => {
        let export_type = '';
        if (value === '0') {
            if (report_type !== 'percentile') {
                if (isNonProfit) {
                    setSideMenu(4);
                } else {
                    setSideMenu(1);
                }
            } else {
                setSideMenu(3);
            }
            export_type = 'enterprise';
        } else if (value === '1') {
            if (report_type !== 'percentile') {
                if (isNonProfit) {
                    setSideMenu(4);
                } else {
                    setSideMenu(1);
                }
            } else {
                setSideMenu(3);
            }
            export_type = 'bu';
        } else if (value === '3') {
            export_type = 'resurvey';
            setSideMenu(2);
        } else if (value === '4') {
            export_type = 'inclusion';
            setSideMenu(3);
        } else if (value === '6') {
            export_type = 'inclusion_resurvey';
            setSideMenu(3);
        } else if (value === '5') {
            export_type = 'dataCube';
            setSideMenu(4);
        } else if (value === '7') {
            export_type = 'employee_experience';
            setSideMenu(4);
        } else if (value === '8') {
            export_type = 'ohi_influencer';
            setSideMenu(4);
        } else if (value === '9') {
            export_type = 'op_model_excel';
            setSideMenu(4);
        }
        updateReportApiParams({
            ...reportApiParams,
            export_type,
            options: {
                ...choseReport.options,
                [name]: value,
            },
        });

        setChoseReport({
            ...choseReport,
            export_type,
            options: {
                ...choseReport.options,
                [name]: value,
            },
        });
    };

    useEffect(() => {
        const { options: opt } = reportApiParams;
        const { ReportType: rt } = opt;
        const filteredReports = reportOptionsInUse.filter(
            ({
                key,
                checkIncAccess = false,
                checkCubeAccess = false,
                checkIAResurveyAccess = false,
                checkExAccess = false,
                checkInfluencer = false,
                checkYear = false,
            }) => {
                if (
                    (checkIncAccess && !inclusion_added) ||
                    (checkIAResurveyAccess && !inclusion_added) ||
                    (checkCubeAccess && !isAdmin) ||
                    (isUserNotAdmin && listAccess.length && listAccess.indexOf(key) === -1) ||
                    (checkExAccess && !ex_survey_added) ||
                    (checkInfluencer && !ohi_influencer_access) ||
                    (checkYear && year !== CURRENT_YEAR)
                ) {
                    return false;
                }
                return true;
            }
        );
        const { key = '' } = filteredReports[0] || {};
        handleReportData({ name: 'ReportType', value: rt || key });
        // eslint-disable-next-line
    }, []);

    const handleNext = () => {
        submitReport(choseReport);
    };

    return (
        <section data-testid="chooseReportComponent">
            <div className="chooseReportWrapper">
                <h2>{REPORTS}</h2>
                <div className="heading">
                    <span>{SELECT_REPORT}</span>
                    <div className="reportWrapper">
                        <FormGroup data-test-id="reportType" classes={{ root: 'radioGroup colRadio' }}>
                            <RadioGroup
                                aria-label="ReportType"
                                name="ReportType"
                                value={ReportType}
                                onChange={({ target }) => handleReportData(target)}
                            >
                                {reportOptionsInUse.map(
                                    (
                                        {
                                            label,
                                            key,
                                            checkIncAccess = false,
                                            checkCubeAccess = false,
                                            checkIAResurveyAccess = false,
                                            checkExAccess = false,
                                            checkInfluencer = false,
                                            checkYear = false,
                                        },
                                        i
                                    ) => {
                                        if (checkIncAccess && !inclusion_added) {
                                            return null;
                                        }
                                        if (checkIAResurveyAccess && !inclusion_added) {
                                            return null;
                                        }
                                        if (checkCubeAccess && !isAdmin) {
                                            return null;
                                        }
                                        if (isUserNotAdmin && listAccess.length && listAccess.indexOf(key) === -1) {
                                            return null;
                                        }
                                        if (checkExAccess && !ex_survey_added) {
                                            return null;
                                        }
                                        if (checkInfluencer && !ohi_influencer_access) {
                                            return null;
                                        }
                                        if (checkYear && year !== CURRENT_YEAR) {
                                            return null;
                                        }
                                        return (
                                            <FormControlLabel
                                                key={i}
                                                value={key}
                                                control={<Radio disableRipple />}
                                                label={label}
                                                data-testid={`ChooseReport${key}`}
                                            />
                                        );
                                    }
                                )}
                            </RadioGroup>
                        </FormGroup>
                    </div>
                    <div className="buttonWrapperReports">
                        <div
                            className={c('blueButton', { active: ReportType })}
                            onClick={handleNext}
                            data-testid="nextBtnCRprt"
                        >
                            {NEXT}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

ChoseReport.propTypes = {
    submitReport: PropTypes.func.isRequired,
    reportApiParams: PropTypes.object.isRequired,
    setSideMenu: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    isCfgClient: PropTypes.bool.isRequired,
    isIngClient: PropTypes.bool.isRequired,
    isAmmegaRpt: PropTypes.bool.isRequired,
    updateReportApiParams: PropTypes.func.isRequired,
};

export default ChoseReport;
