const getFilterString = (filterArr = [], preString = '', endString = '') => {
    if (!filterArr.length) {
        return [];
    }
    const filters = [];
    filterArr.forEach(({ filter_items: options = [], filter_id: parentCode = '' }) => {
        const optionsCode = options
            .filter(({ isSelected }) => isSelected)
            .reduce((pre, { filter_item_id: code = '' }) => [...pre, code], []);
        if (optionsCode.length) {
            filters.push(`${preString}${parentCode}:${optionsCode.join(',')}${endString}`);
        }
    });
    if (!filters.length) {
        return '';
    }
    return filters.length > 1 ? filters.join(';') : filters[0];
};

export const getSelectedFilterIdsById = (filters, filterId) => {
    if (!filters.length) {
        return '';
    }
    const { filter_items: selectedFilterByIdChild = [] } = filters.find(({ filter_id }) => filter_id === filterId);

    return selectedFilterByIdChild.filter(({ isSelected }) => isSelected).map(({ filter_item_id }) => filter_item_id);
};

export const removeQuartileFromFilterParams = filterParam => {
    if (filterParam.includes('quartile')) {
        return filterParam.replace(/;quartile:[^;]*/g, '');
    }
    return filterParam;
};

export const removeYearFromFilterParams = filterParam => {
    return filterParam.replace(/year:[^;]*/g, '');
};

export const getTabName = name => {
    if (name.includes('McKinsey')) {
        return name.replace(/McKinsey /, '');
    }
    return name;
};

export const getFilterName = (filterArr = [], preString = '', endString = '') => {
    if (!filterArr.length) {
        return [];
    }
    const filters = [];
    filterArr.forEach(({ filter_items: options = [], filter_name: parentCode = '' }) => {
        const optionsCode = options
            .filter(({ isSelected }) => isSelected)
            .reduce((pre, { filter_item_name: code = '' }) => [...pre, code], []);
        if (optionsCode.length) {
            filters.push(`${optionsCode.join(',')}${endString} ${preString}${getTabName(parentCode)}`);
        }
    });
    if (!filters.length) {
        return '';
    }
    return filters.length > 1 ? filters.join(', ') : filters[0];
};

export const getMaxRange = filters => {
    const filterTotalComplete = filters.findIndex(({ filter_id }) => filter_id === 'totalcomplete');
    const { filter_items: filterItemsComp = [] } = filters[filterTotalComplete] || {};
    const { filter_item_id } = filterItemsComp[filterItemsComp.length - 1] || '';
    return filter_item_id;
};

export const getFilterArrFromFilterStr = (filterArr = [], str) => {
    if (!filterArr.length) {
        return [];
    }
    const filterStrArr = str.split(';');
    filterStrArr.forEach(filterStr => {
        const filterStrSplit = filterStr.split(':');
        const parentCode = filterStrSplit[0];
        const childCodeArr = filterStrSplit[1].split(',');
        const parentC = isNaN(parseInt(parentCode, 10)) ? parentCode : parseInt(parentCode, 10);
        const parentIndex = filterArr.findIndex(({ filter_id }) => filter_id === parentC);
        if (parentIndex !== -1) {
            filterArr[parentIndex].isSelected = true;
        }
        const { filter_items = [] } = filterArr[parentIndex] || {};
        childCodeArr.forEach(code => {
            const childCode = isNaN(parseInt(code, 10)) ? code : parseInt(code, 10);
            const childIndex = filter_items.findIndex(({ filter_item_id }) => filter_item_id === childCode);
            filterArr[parentIndex].filter_items[childIndex].isSelected = true;
        });
    });
    return filterArr;
};

export const debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

export default getFilterString;
