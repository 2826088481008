import { createSelector } from 'reselect';

const responseRate = state => state.get('responseRate');

const selectLoadCount = () => createSelector(responseRate, state => state.toJS().count);
const selectDailyResponses = () => createSelector(responseRate, state => state.toJS().dailyResponses);
const selectDailyResFetched = () => createSelector(responseRate, state => state.toJS().dailyResFetched);
const getErrorInfo = () => createSelector(responseRate, state => state.toJS().error);
const selectDropDownValue = () => createSelector(responseRate, state => state.toJS().dropDownValue);
const selectDemoResponses = () => createSelector(responseRate, state => state.toJS().demoResponses);
const selectDemoResFetched = () => createSelector(responseRate, state => state.toJS().demoResFetched);
const selectCrossTab = () => createSelector(responseRate, state => state.toJS().crossTabData);
const selectCrossTabFetched = () => createSelector(responseRate, state => state.toJS().crossTabFetched);
const selectCrossTabDrop = () => createSelector(responseRate, state => state.toJS().crossTabsDrop);

export {
    selectLoadCount,
    selectDailyResponses,
    selectDailyResFetched,
    getErrorInfo,
    selectDropDownValue,
    selectDemoResponses,
    selectDemoResFetched,
    selectCrossTab,
    selectCrossTabFetched,
    selectCrossTabDrop,
};
