import React, { useState, useMemo, Fragment, useEffect } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import { getStaticText } from '../../../../utils/constants';
import getStaticTextDiagnose from '../../constants';
import { getColor, getNegColor, manipulateHeatmapTitle } from '../../../../utils/functions';
import ArrowUp from '../../assets/ArrowUp.svg';
import ArrowDown from '../../assets/ArrowDown.svg';

function EnpsDemo({
    sortIeHeatmap,
    defaultSettings,
    empExpHeatmaps,
    crossTabDemo,
    empExpOhiHeatmaps,
    getEmpExpFactor,
    getOhiScoreForEmp,
    addOns,
    apiParams,
    enpsFetched,
}) {
    const { quartileColors = [], lang: langTxt = '1033', threshold = '' } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(langTxt);
    const {
        MAX_LENGTH_SUBSTRING,
        SORT_ASCENDING,
        SORT_DESCENDING,
        QUESTIONS_HEATMAP,
        IE_CROSSTAB_OPTIONS,
        WORKPLACE_DEMO,
        INT_TO_LEAVE_DEMO,
        ENPS_DEMO,
        OUTCOME_VAR,
        PRACTICE_VAR,
    } = staticTextDiagnose;
    const staticText = getStaticText(langTxt);
    const { SITE_TEXT, IND_EXP_SCORE_CLASSIFICATION } = staticText;
    const { N_SIZE, OHI_SCORE_TEXT, NEGATIVE_PRACTICES } = SITE_TEXT;
    const { filters: filterParams, ohid, lang, year, benchmarks = '' } = apiParams || {};
    const { [crossTabDemo]: data = {} } = empExpHeatmaps || {};
    const { header = [], items = [] } = data || {};
    const { [crossTabDemo]: ohiScoreData = {} } = empExpOhiHeatmaps || {};
    const { items: ohiItems = [] } = ohiScoreData || {};
    const [sortOrder, setSortOrder] = useState({});

    const headerIE = useMemo(() => {
        if (!items.length) return [];
        return [...items.map(({ title = '' }) => title)];
        // eslint-disable-next-line
    }, [empExpHeatmaps]);

    const dataToUse = useMemo(() => {
        if (!items.length) return [];
        const newArray = [];
        const innerArray = [];
        items.forEach(({ items: subItem = [] }) => {
            innerArray.push(subItem);
        });
        // eslint-disable-next-line no-unused-vars
        header.forEach(_elem => {
            newArray.push([]);
        });
        for (let i = 0; i < innerArray.length; i += 1) {
            // const { items: subItems } = items
            for (let j = 0; j < header.length; j += 1) {
                newArray[j].push(innerArray[i][j]);
            }
        }

        return newArray;
        // eslint-disable-next-line
    }, [empExpHeatmaps, crossTabDemo]);

    const ohiScoreToUse = useMemo(() => {
        if (!ohiItems.length) return [];
        const newArray = [];
        const innerArray = [];
        const innerArrayOhi = {};
        ohiItems.forEach(({ items: subItem = [], sub_demo = '' }) => {
            innerArrayOhi[sub_demo] = subItem;
        });
        const innerArrayEx = {};
        items.forEach(({ items: subItem = [], sub_demo = '' }) => {
            innerArrayEx[sub_demo] = subItem;
        });
        Object.keys(innerArrayEx).forEach(elem => {
            if (innerArrayOhi[elem]) {
                innerArray.push(innerArrayOhi[elem]);
            } else {
                innerArray.push(Array(header.length).fill({ score: '-', quartile: '' }));
            }
        });
        // eslint-disable-next-line no-unused-vars
        header.forEach(_elem => {
            newArray.push([]);
        });
        for (let i = 0; i < innerArray.length; i += 1) {
            for (let j = 0; j < header.length; j += 1) {
                newArray[j].push(innerArray[i][j]);
            }
        }

        return newArray;
        // eslint-disable-next-line
    }, [empExpOhiHeatmaps, crossTabDemo]);

    const headerToUse = headerIE;
    const { name = '' } = IE_CROSSTAB_OPTIONS.find(({ code }) => code === crossTabDemo);

    const getHeatMapValues = arrItems => {
        return QUESTIONS_HEATMAP.map(({ children: child = [], title: categoryN = '' }) => {
            const itemsToIterate = [];
            const titleArr = [];
            child.forEach(elem => {
                const index = header.indexOf(elem);
                if (header[index] !== N_SIZE) {
                    itemsToIterate.push(arrItems[index]);
                    titleArr.push(header[index]);
                }
            });
            return itemsToIterate.map((subItems, parentI) => {
                const title = manipulateHeatmapTitle(titleArr[parentI]);
                const isNegativeTrait = NEGATIVE_PRACTICES.includes(title);
                return (
                    <div className="sectionDiv" key={title}>
                        {!parentI ? (
                            <ul>
                                <li className="sectionContent headerContent">{categoryN}</li>
                            </ul>
                        ) : null}
                        <ul>
                            <Tooltip placement="top" arrow title={title} aria-label={title}>
                                <li className="sectionContent">{title}</li>
                            </Tooltip>
                            {subItems.map(({ score: innerScore }, index) => {
                                const i = getColor(innerScore, [], false, true);
                                const iNeg = getNegColor(innerScore, [], false, true);
                                const { positiveBg = '#ffffff', negativeBg = '#ffffff', color: textColor = '#000000' } =
                                    IND_EXP_SCORE_CLASSIFICATION[isNegativeTrait ? iNeg : i] || {};
                                const bg = isNegativeTrait ? negativeBg : positiveBg;
                                const background = bg;
                                const color = textColor;
                                const scoreToDisplay =
                                    !isNaN(parseInt(innerScore, 10)) &&
                                    !(crossTabDemo === INT_TO_LEAVE_DEMO && parseInt(innerScore, 10) === 0) &&
                                    !(crossTabDemo === ENPS_DEMO && parseInt(innerScore, 10) === 0)
                                        ? innerScore.toLocaleString('en-US')
                                        : '-';
                                return (
                                    <li
                                        className={c({ nSize: !i }, 'contentList')}
                                        key={`${innerScore}${index}${Math.random()}`}
                                        style={{
                                            border: `1px solid white`,
                                            background: scoreToDisplay === '-' ? '#ffffff' : background,
                                            color,
                                        }}
                                    >
                                        {scoreToDisplay}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            });
        });
    };

    const getOhiHeapMapValues = itemsToIterate => {
        return itemsToIterate.slice(0, 2).map((list, i) => {
            return (
                <div className="sectionDiv" key={'ohiScore' + i}>
                    {crossTabDemo !== WORKPLACE_DEMO ? (
                        <ul>
                            <li className="sectionContent">{!i ? 'n' : 'OHI Score'}</li>
                            {list.map(({ score: innerScore, quartile }, index) => {
                                const { background = '', color = '', border: borderColor = '' } =
                                    quartileColors[quartile] || {};
                                return (
                                    <li
                                        className={c({ nSize: !i }, 'contentList')}
                                        key={`${innerScore}${index}${Math.random()}`}
                                        style={{
                                            color,
                                            background: borderColor
                                                ? `linear-gradient(135deg, ${borderColor} 15%, ${background} 15%)`
                                                : background,
                                            border: '1px solid white',
                                        }}
                                    >
                                        {!isNaN(parseInt(innerScore, 10))
                                            ? parseInt(innerScore, 10).toLocaleString('en-US')
                                            : '-'}
                                    </li>
                                );
                            })}
                        </ul>
                    ) : null}
                </div>
            );
        });
    };

    const sortData = type => {
        if (type === N_SIZE || type === OHI_SCORE_TEXT) {
            setSortOrder({ [type]: sortOrder[type] === SORT_ASCENDING ? SORT_DESCENDING : SORT_ASCENDING });
            const arr = [];
            items.map(({ items: subItems = [] }, i) => {
                const { score = '' } = type === N_SIZE ? subItems[0] || {} : subItems[1] || {};
                arr.push(score + '#' + i);
                return items;
            });
            const sortArr =
                sortOrder[type] === 1
                    ? arr.slice(1, arr.length).sort((a, b) => a.split('#')[0] - b.split('#')[0])
                    : arr.slice(1, arr.length).sort((a, b) => b.split('#')[0] - a.split('#')[0]);
            sortArr.unshift(arr[0]);
            const indexArr = [];
            sortArr.map(val => {
                return indexArr.push(arr.indexOf(val));
            });
            const output = indexArr.map(i => items[i]);
            sortIeHeatmap(output);
        }
    };

    const callData = code => {
        getEmpExpFactor({
            demographic: code,
            threshold,
            lang,
            page: 1,
            ohid,
            benchmarks,
            type: PRACTICE_VAR,
            filters: filterParams,
            year,
            ...addOns,
        });
        getOhiScoreForEmp({
            demographic: code,
            threshold,
            lang,
            page: 1,
            ohid,
            benchmarks,
            type: OUTCOME_VAR,
            filters: filterParams,
            year,
            ...addOns,
        });
    };

    useEffect(() => {
        if (!(crossTabDemo in empExpHeatmaps) || !enpsFetched) {
            callData(crossTabDemo);
        }
        // eslint-disable-next-line
    }, [crossTabDemo, enpsFetched]);

    return (
        <Fragment>
            <div className="demographicBoard clearfix clearPadding enpsTable">
                <section className="sectionWrapper">
                    <div id="myHeader" className={c('headerWrapper clearfix')}>
                        <div>
                            {headerToUse.length ? (
                                <ul className={c({ tabLeft: true })}>
                                    <li className="headerList leftHeader">{name}</li>
                                    {headerToUse &&
                                        headerToUse.map((val, i) => {
                                            const cellVal = manipulateHeatmapTitle(val);
                                            return (
                                                <Tooltip
                                                    key={`${val}${i}`}
                                                    placement="top"
                                                    arrow
                                                    title={val}
                                                    aria-label={val}
                                                >
                                                    <li className="headerList" onClick={() => sortData(val)}>
                                                        {cellVal.length > MAX_LENGTH_SUBSTRING
                                                            ? `${cellVal.substring(0, MAX_LENGTH_SUBSTRING) + '...'}`
                                                            : cellVal}
                                                        {cellVal === OHI_SCORE_TEXT && (
                                                            <img
                                                                className="arrowSort"
                                                                src={sortOrder[val] === 1 ? ArrowUp : ArrowDown}
                                                                alt=""
                                                            />
                                                        )}
                                                    </li>
                                                </Tooltip>
                                            );
                                        })}
                                </ul>
                            ) : null}
                        </div>
                    </div>
                    {/* {status === 400 || (heatMapInclusionError && isInclusion) ? (
                    <ErrorToast message={message || heatMapInclusionError} />
                ) : null} */}
                    <div className="contentWrapper clearfix">
                        <div className="mainContent">
                            {ohiScoreToUse.length ? getOhiHeapMapValues(ohiScoreToUse) : null}
                        </div>
                    </div>
                    <div className="contentWrapper clearfix">
                        <div className="mainContent">{dataToUse.length ? getHeatMapValues(dataToUse) : null}</div>
                    </div>
                </section>
            </div>
        </Fragment>
    );
}

EnpsDemo.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    empExpHeatmaps: PropTypes.object.isRequired,
    crossTabDemo: PropTypes.string.isRequired,
    empExpOhiHeatmaps: PropTypes.object.isRequired,
    sortIeHeatmap: PropTypes.func.isRequired,
    getEmpExpFactor: PropTypes.func.isRequired,
    getOhiScoreForEmp: PropTypes.func.isRequired,
    addOns: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
    enpsFetched: PropTypes.bool.isRequired,
};

export default EnpsDemo;
