import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ValuesBoard from './ValuesBoard';

import { getScoreData, getPptData, getValuesData, getSinglePastReport } from '../../actions';
import { getReport, getRawPptData } from '../../../Reports/actions';

import {
    selectValuesData,
    selectValuesDataFetched,
    selectApiParams,
    selectApiCount,
    getErrorInfo,
    selectFiltersApplied,
    selectScoreData,
    selectScoreDataFetched,
    selectFilters,
    selectPastReport,
} from '../../selector';

import { getDefaultSettings } from '../../../Login/selector';
import { selectReportError } from '../../../Reports/selector';

const stateToProps = createStructuredSelector({
    valuesData: selectValuesData(),
    valuesDataFetched: selectValuesDataFetched(),
    apiParams: selectApiParams(),
    apiLoadingCount: selectApiCount(),
    errorInfo: getErrorInfo(),
    activeFilter: selectFiltersApplied(),
    defaultSettings: getDefaultSettings(),
    scoreData: selectScoreData(),
    scoreDataFetched: selectScoreDataFetched(),
    filters: selectFilters(),
    reportError: selectReportError(),
    singlePastReport: selectPastReport(),
});

const dispatchToProps = { getScoreData, getPptData, getValuesData, getReport, getRawPptData, getSinglePastReport };

export default connect(stateToProps, dispatchToProps)(ValuesBoard);
