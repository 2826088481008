class AuthStore {
    constructor() {
        if (!AuthStore.instance) {
            this._data = localStorage;
            AuthStore.instance = this;
        }

        return AuthStore.instance;
    }

    set userName(value) {
        this._data.setItem('userName', value);
    }

    get userName() {
        return this._data.getItem('userName');
    }

    deleteUserName() {
        this._data.removeItem('userName');
    }

    set userEmail(value) {
        this._data.setItem('userEmail', value);
    }

    get userEmail() {
        return this._data.getItem('userEmail');
    }

    deleteUserEmail() {
        this._data.removeItem('userEmail');
    }

    set userRole(value) {
        this._data.setItem('userRole', value);
    }

    get userRole() {
        return this._data.getItem('userRole');
    }

    deleteUserRole() {
        this._data.removeItem('userRole');
    }

    set gsodRole(value) {
        this._data.setItem('gsodRole', value);
    }

    get gsodRole() {
        return this._data.getItem('gsodRole');
    }

    deletegsodRole() {
        this._data.removeItem('gsodRole');
    }

    set gsodApprover(value) {
        this._data.setItem('gsodApprover', value);
    }

    get gsodApprover() {
        return this._data.getItem('gsodApprover');
    }

    deletegsodApprover() {
        this._data.removeItem('gsodApprover');
    }

    set accessToken(value) {
        this._data.setItem('accessToken', value);
    }

    get accessToken() {
        return this._data.getItem('accessToken');
    }

    deleteAccessToken() {
        this._data.removeItem('accessToken');
    }

    set mIdToken(value) {
        this._data.setItem('mIdToken', value);
    }

    get mIdToken() {
        return this._data.getItem('mIdToken');
    }

    deleteMidToken() {
        this._data.removeItem('mIdToken');
    }

    set pptAccessToken(value) {
        this._data.setItem('pptAccessToken', value);
    }

    get pptAccessToken() {
        return this._data.getItem('pptAccessToken');
    }

    deletePptAccessToken() {
        this._data.removeItem('pptAccessToken');
    }

    set pptTokenExp(value) {
        this._data.setItem('pptTokenExp', value);
    }

    get pptTokenExp() {
        return this._data.getItem('pptTokenExp');
    }

    deletePptTokenExp() {
        this._data.removeItem('pptTokenExp');
    }

    set ohid(value) {
        this._data.setItem('ohid', value);
    }

    get ohid() {
        return this._data.getItem('ohid');
    }

    deleteOhId() {
        this._data.removeItem('ohid');
    }

    set report_id(value) {
        this._data.setItem('report_id', value);
    }

    get report_id() {
        return this._data.getItem('report_id');
    }

    deleteReportId() {
        this._data.removeItem('report_id');
    }

    set buName(value) {
        this._data.setItem('buName', value);
    }

    get buName() {
        return this._data.getItem('buName');
    }

    deleteBuName() {
        this._data.removeItem('buName');
    }

    set surveyName(value) {
        this._data.setItem('surveyName', value);
    }

    get surveyName() {
        return this._data.getItem('surveyName');
    }

    deleteSurveyName() {
        this._data.removeItem('surveyName');
    }

    set practiceBU(value) {
        this._data.setItem('practiceBU', value);
    }

    get practiceBU() {
        return this._data.getItem('practiceBU');
    }

    deletePracticeBU() {
        this._data.removeItem('practiceBU');
    }

    set practiceReportBU(value) {
        this._data.setItem('practiceReportBU', value);
    }

    get practiceReportBU() {
        return this._data.getItem('practiceReportBU');
    }

    deletePracticeReportBU() {
        this._data.removeItem('practiceReportBU');
    }

    set resurveyFilter1(value) {
        this._data.setItem('resurveyFilter1', value);
    }

    get resurveyFilter1() {
        return this._data.getItem('resurveyFilter1');
    }

    deleteResurveyFilter1() {
        this._data.removeItem('resurveyFilter1');
    }

    set resurveyFilter2(value) {
        this._data.setItem('resurveyFilter2', value);
    }

    get resurveyFilter2() {
        return this._data.getItem('resurveyFilter2');
    }

    deleteResurveyFilter2() {
        this._data.removeItem('resurveyFilter2');
    }

    set resurveyName1(value) {
        this._data.setItem('resurveyName1', value);
    }

    get resurveyName1() {
        return this._data.getItem('resurveyName1');
    }

    deleteresurveyName1() {
        this._data.removeItem('resurveyName1');
    }

    set resurveyName2(value) {
        this._data.setItem('resurveyName2', value);
    }

    get resurveyName2() {
        return this._data.getItem('resurveyName2');
    }

    deleteResurveyName2() {
        this._data.removeItem('resurveyName2');
    }

    set gsodloginNumber(value) {
        this._data.setItem('gsodloginNumber', value);
    }

    get gsodloginNumber() {
        return this._data.getItem('gsodloginNumber');
    }

    deleteGsodLoginNumber() {
        this._data.removeItem('gsodloginNumber');
    }

    set ohiloginNumber(value) {
        this._data.setItem('ohiloginNumber', value);
    }

    get ohiloginNumber() {
        return this._data.getItem('ohiloginNumber');
    }

    deleteohiLoginNumber() {
        this._data.removeItem('ohiloginNumber');
    }

    set actionAccess(value) {
        this._data.setItem('actionAccess', value);
    }

    get actionAccess() {
        return this._data.getItem('actionAccess');
    }

    deleteActionAccess() {
        this._data.removeItem('actionAccess');
    }

    set showFeatureModal(value) {
        this._data.setItem('showFeatureModal', value);
    }

    get showFeatureModal() {
        return this._data.getItem('showFeatureModal');
    }

    deleteShowFeatureModal() {
        this._data.removeItem('showFeatureModal');
    }

    set gsodAccess(value) {
        this._data.setItem('gsodAccess', value);
    }

    get gsodAccess() {
        return this._data.getItem('gsodAccess');
    }

    deleteGsodAccess() {
        this._data.removeItem('gsodAccess');
    }

    set customBULanguage(value) {
        this._data.setItem('custombulang', value);
    }

    get customBULanguage() {
        return this._data.getItem('custombulang');
    }

    set isCBUblue(value) {
        this._data.setItem('iscbublue', value);
    }

    get isCBUblue() {
        return this._data.getItem('iscbublue');
    }

    deleteisCBUblue() {
        this._data.removeItem('iscbublue');
    }
}

const instance = new AuthStore();
Object.freeze(instance);

export default instance;
