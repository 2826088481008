import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { List, ListItem, Collapse, Checkbox, FormControlLabel } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { cloneDeep } from 'lodash';

import { toUpper } from '../../utils/functions';
import { getStaticText } from '../../utils/constants';
import './index.scss';

function InclusionDemographics({
    demographics,
    updateDemographics,
    limit,
    outcomeLimit,
    defaultSettings,
    inactiveOption,
    isActive,
}) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { PRC_QUESTIONS, OUTCOME_QUESTIONS, OUT_OF } = staticText || {};
    const selectParentCheckbox = ({ checked }, indexParent) => {
        const newDemographics = demographics.map((value, index) => {
            const newValue = { ...value };
            if (index === indexParent) {
                newValue.isOpen = checked ? true : value.isOpen;
                newValue.isSelected = checked;
                if (!checked) {
                    newValue.meta_scores.forEach(val => {
                        val.selectedChild = false;
                        return val;
                    });
                    Object.keys(newValue.levelChild).map(level => {
                        const levelArr = newValue.levelChild[level];
                        levelArr.map(val => {
                            val.selectedChild = false;
                            val.meta_scores.map(v => {
                                v.selectedChild = false;
                                return v;
                            });
                            return val;
                        });
                        return level;
                    });
                    newValue.isOpen = false;
                }
            } else {
                newValue.isOpen = false;
            }
            return newValue;
        });
        updateDemographics(newDemographics);
    };

    const handleParentClick = (val, i) => {
        const newDemographics = demographics.map((value, index) => {
            const newValue = { ...value };
            if (index === i) {
                newValue.isOpen = val.isOpen ? !val.isOpen : true;
            } else {
                newValue.isOpen = false;
            }
            return newValue;
        });
        updateDemographics(newDemographics);
    };

    const handleChildCheck = ({ checked }, indexChild, indexParent) => {
        const newDemographics = cloneDeep(demographics);
        newDemographics[indexParent].meta_scores[indexChild].selectedChild = checked;
        const filterSelectedMetaScore = (
            newDemographics[indexParent].meta_scores.filter(({ selectedChild }) => selectedChild) || []
        ).length;
        if (filterSelectedMetaScore > 0) {
            newDemographics[indexParent].isSelected = true;
        }
        updateDemographics(newDemographics);
    };

    const handleLevelCheck = ({ checked }, indexChild, indexParent, subChild, sub_meta_index) => {
        const newDemographics = cloneDeep(demographics);

        newDemographics[indexParent].levelChild[subChild][indexChild].meta_scores[
            sub_meta_index
        ].selectedChild = checked;
        const filterSelectedMetaScore = (
            newDemographics[indexParent].levelChild[subChild][indexChild].meta_scores.filter(
                ({ selectedChild }) => selectedChild
            ) || []
        ).length;
        if (filterSelectedMetaScore > 0) {
            newDemographics[indexParent].levelChild[subChild][indexChild].selectedChild = true;
            newDemographics[indexParent].isSelected = true;
        } else {
            newDemographics[indexParent].levelChild[subChild][indexChild].selectedChild = false;
        }
        updateDemographics(newDemographics);
    };

    const handleSubParent = ({ checked }, indexChild, subChild, indexParent) => {
        const newDemographics = cloneDeep(demographics);
        if (!checked) {
            newDemographics[indexParent].selectedChild = true;
            newDemographics[indexParent].levelChild[subChild][indexChild].selectedChild = checked;
            newDemographics[indexParent].levelChild[subChild][indexChild].meta_scores.forEach(val => {
                val.selectedChild = false;
                return val;
            });
        }
        updateDemographics(newDemographics);
    };

    const checkLevel = levelChild => {
        const arr = [];
        Object.keys(levelChild).forEach(val => {
            const len = levelChild[val].filter(({ selectedChild = false }) => selectedChild).length;
            arr.push(len);
        });
        const sum = arr.reduce((a, b) => a + b, 0);
        return sum;
    };

    const checkDisableParent = () => {
        let count = 0;
        demographics.forEach(val => {
            const { isSelected = false } = val;
            if (isSelected) {
                count += 1;
            }
        });
        return count;
    };

    return (
        <div className="filterContent clearfix">
            <div className="upperContent clearfix">
                {demographics.map((val, indexParent) => {
                    const {
                        levelChild = {},
                        meta_scores = [],
                        isOpen = false,
                        display_name = '',
                        isSelected = false,
                        key = '',
                    } = val;
                    const iconExpand = meta_scores.length ? (
                        <span className="expandIcon">{isOpen ? <ExpandLess /> : <ExpandMore />}</span>
                    ) : null;
                    const practiceLength = checkLevel(levelChild);
                    const outcomeLength = meta_scores.filter(({ selectedChild }) => selectedChild).length;
                    const checkParentLength = checkDisableParent();
                    return (
                        <div className="mainListWrap" key={indexParent}>
                            <ListItem
                                classes={{
                                    root: c('mainListContent', {
                                        borderBottom: !isOpen,
                                        disableClass:
                                            (checkParentLength === outcomeLimit && !isSelected) ||
                                            inactiveOption.includes(key),
                                    }),
                                }}
                                onClick={() =>
                                    checkParentLength === outcomeLimit && !isSelected
                                        ? {}
                                        : handleParentClick(val, indexParent)
                                }
                                data-testid={display_name}
                            >
                                <Checkbox
                                    checked={isActive && isSelected}
                                    onChange={({ target }) => selectParentCheckbox(target, indexParent)}
                                    name={display_name}
                                    disabled={checkParentLength === outcomeLimit && !isSelected}
                                    inputProps={{
                                        'data-testid': `ParentCheck${display_name}`,
                                    }}
                                />
                                {toUpper(display_name)}
                                {iconExpand}
                            </ListItem>
                            <Collapse in={isOpen} timeout="auto" unmountOnExit classes={{ container: 'childWrapper' }}>
                                <div className="questionHeading">
                                    <span>{OUTCOME_QUESTIONS}</span>
                                    <span>
                                        {outcomeLength}
                                        {OUT_OF}
                                        {limit}
                                    </span>
                                </div>
                                <div className="sublevel optionList">
                                    {meta_scores.map(
                                        (
                                            { selectedChild = false, qtext: childLabel = '', meta: childCode = '' },
                                            indexChild
                                        ) => {
                                            return (
                                                <List component="div" disablePadding key={childCode}>
                                                    <ListItem>
                                                        <FormControlLabel
                                                            classes={{ root: 'optionItem' }}
                                                            control={
                                                                <Checkbox
                                                                    checked={selectedChild}
                                                                    onChange={({ target }) =>
                                                                        handleChildCheck(
                                                                            target,
                                                                            indexChild,
                                                                            indexParent
                                                                        )
                                                                    }
                                                                    disabled={outcomeLength >= limit && !selectedChild}
                                                                    name={childCode}
                                                                    inputProps={{
                                                                        'data-testid': `ChildCheck${childLabel}`,
                                                                    }}
                                                                />
                                                            }
                                                            label={childLabel}
                                                        />
                                                    </ListItem>
                                                </List>
                                            );
                                        }
                                    )}
                                </div>
                                <div className="questionHeading">
                                    <span>{PRC_QUESTIONS}</span>
                                    <span>
                                        {practiceLength} {OUT_OF} {limit}
                                    </span>
                                </div>
                                {Object.keys(levelChild).map((subChild, subChildIndex) => {
                                    return (
                                        <div className="levelSubChild" key={subChildIndex}>
                                            <div className="levelHeading">{subChild}</div>
                                            {levelChild[subChild].map(
                                                (
                                                    {
                                                        display_name: sub_display_name = '',
                                                        meta_scores: sub_meta_scores = [],
                                                        selectedChild: selectedSubParent = false,
                                                    },
                                                    indexChild
                                                ) => {
                                                    const practiceSubLength = sub_meta_scores.filter(
                                                        ({ selectedChild }) => selectedChild
                                                    ).length;
                                                    return (
                                                        <div key={indexChild} className="childContainer">
                                                            <div className="optionList">
                                                                <List
                                                                    component="div"
                                                                    disablePadding
                                                                    key={sub_display_name + indexChild}
                                                                >
                                                                    <ListItem>
                                                                        <FormControlLabel
                                                                            classes={{ root: 'subOptionItem' }}
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={
                                                                                        isActive && selectedSubParent
                                                                                    }
                                                                                    onChange={({ target }) =>
                                                                                        handleSubParent(
                                                                                            target,
                                                                                            indexChild,
                                                                                            subChild,
                                                                                            indexParent
                                                                                        )
                                                                                    }
                                                                                    disabled={
                                                                                        practiceLength >= limit &&
                                                                                        !selectedSubParent
                                                                                    }
                                                                                    name={sub_display_name}
                                                                                    inputProps={{
                                                                                        'data-testid': `ChildCheck${sub_display_name}`,
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={toUpper(sub_display_name)}
                                                                        />
                                                                        <span className="countValue">
                                                                            {practiceSubLength} out of {limit}
                                                                        </span>
                                                                    </ListItem>
                                                                </List>
                                                            </div>
                                                            <div className="submeta optionList">
                                                                {sub_meta_scores.map(
                                                                    (
                                                                        {
                                                                            qtext: childLabel = '',
                                                                            meta: childCode = '',
                                                                            selectedChild = false,
                                                                        },
                                                                        sub_meta_index
                                                                    ) => {
                                                                        return (
                                                                            <List
                                                                                component="div"
                                                                                disablePadding
                                                                                key={childCode}
                                                                            >
                                                                                <ListItem>
                                                                                    <FormControlLabel
                                                                                        classes={{ root: 'optionItem' }}
                                                                                        control={
                                                                                            <Checkbox
                                                                                                checked={selectedChild}
                                                                                                onChange={e =>
                                                                                                    handleLevelCheck(
                                                                                                        e.target,
                                                                                                        indexChild,
                                                                                                        indexParent,
                                                                                                        subChild,
                                                                                                        sub_meta_index
                                                                                                    )
                                                                                                }
                                                                                                disabled={
                                                                                                    (practiceSubLength >=
                                                                                                        limit &&
                                                                                                        !selectedChild) ||
                                                                                                    (practiceLength >=
                                                                                                        limit &&
                                                                                                        !selectedSubParent)
                                                                                                }
                                                                                                name={childCode}
                                                                                                inputProps={{
                                                                                                    'data-testid': `ChildCheck${childLabel}`,
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                        label={childLabel}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    );
                                })}
                            </Collapse>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

InclusionDemographics.defaultProps = {
    limit: 2,
    outcomeLimit: 3,
    inactiveOption: [],
    isActive: false,
};

InclusionDemographics.propTypes = {
    demographics: PropTypes.array.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    updateDemographics: PropTypes.func.isRequired,
    limit: PropTypes.number,
    outcomeLimit: PropTypes.number,
    inactiveOption: PropTypes.array,
    isActive: PropTypes.bool,
};

export default InclusionDemographics;
