import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import UpdateViewModal from './UpdateViewModal';

import { resetError, changeUser } from '../../../containers/Settings/actions';
import { selectApiCount, getErrorInfo } from '../../../containers/Settings/selector';

const stateToProps = createStructuredSelector({
    error: getErrorInfo(),
    isLoading: selectApiCount(),
});

const dispatchToProps = { resetError, changeUser };

export default connect(stateToProps, dispatchToProps)(UpdateViewModal);
