import React, { useState } from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

import LeftArrow from '../../../assets/left.svg';
import RightArrow from '../../../assets/right.svg';

import '../../../components/ImageModal/index.scss';
import { getStaticText } from '../../../utils/constants';

function ImageModal({ imageArr, closeModal, defaultSettings }) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { NEXT, PREVIOUS, OUT_OF } = SITE_TEXT || {};
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(1);

    return (
        <Dialog
            open
            disableBackdropClick
            maxWidth="md"
            fullWidth
            classes={{
                root: 'imageDialogRoot',
            }}
        >
            <section className="headContent">
                <IconButton
                    aria-label="close"
                    classes={{
                        root: 'closeButton',
                    }}
                    onClick={() => closeModal(false)}
                >
                    <CloseIcon />
                </IconButton>
            </section>
            <section className="dataContent">
                {imageArr.slice(min, max).map((imgSrc = '') => (
                    <img key={imgSrc} src={imgSrc} alt="" className="screenImg" />
                ))}
            </section>
            {imageArr.length > 1 ? (
                <section className="footerNav">
                    <div>
                        {min === 0 ? (
                            <div />
                        ) : (
                            <div>
                                <div
                                    data-testid="leftArrow"
                                    onClick={() => {
                                        setMin(min - 1);
                                        setMax(max - 1);
                                    }}
                                >
                                    <img src={LeftArrow} alt="" />
                                </div>
                                <div className="previous"> {PREVIOUS} </div>
                            </div>
                        )}

                        <div>
                            {min + 1}
                            {OUT_OF}
                            {imageArr.length}
                        </div>
                        {imageArr.length !== max ? (
                            <div>
                                <div className="next"> {NEXT} </div>
                                <div
                                    data-testid="rightArrow"
                                    onClick={() => {
                                        setMin(min + 1);
                                        setMax(max + 1);
                                    }}
                                >
                                    <img src={RightArrow} alt="" />
                                </div>
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>
                </section>
            ) : null}
        </Dialog>
    );
}

ImageModal.propTypes = {
    imageArr: PropTypes.array.isRequired,
    closeModal: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
};

export default ImageModal;
