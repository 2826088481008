import { createSelector } from 'reselect';

const reports = state => state.get('reports');

const selectReportApiCount = () => createSelector(reports, state => state.toJS().count);
const selectReportError = () => createSelector(reports, state => state.toJS().error);
const selectRespondent = () => createSelector(reports, state => state.toJS().respondent);
const selectPreviousRespondent = () => createSelector(reports, state => state.toJS().previousRespondent);
const selectComments = () => createSelector(reports, state => state.toJS().comments);
const selectReportApiParams = () => createSelector(reports, state => state.toJS().reportApiParams);
const selectBuFilters = () => createSelector(reports, state => state.toJS().buDemographics);
const selectPracticeFilters = () => createSelector(reports, state => state.toJS().practiceDemographics);
const selectBenchDataFetched = () => createSelector(reports, state => state.toJS().benchDataFetched);
const selectDemograhicDataFetched = () => createSelector(reports, state => state.toJS().demograhicDataFetched);
const selectFilterIndex = () => createSelector(reports, state => state.toJS().filterIndex);
const selectPastReports = () => createSelector(reports, state => state.toJS().pastReport);
const selectCurrentTab = () => createSelector(reports, state => state.toJS().currentTab);
const selectInclusionData = () => createSelector(reports, state => state.toJS().inclusionData);
const selectGapData = () => createSelector(reports, state => state.toJS().gapData);
const selectGapDataFetched = () => createSelector(reports, state => state.toJS().gapDataFetched);
const selectGraphData = () => createSelector(reports, state => state.toJS().graphData);
const selectGraphDataFetched = () => createSelector(reports, state => state.toJS().graphDataFetched);
const selectPendingReports = () => createSelector(reports, state => state.toJS().pendingReports);
const selectBulkDemographics = () => createSelector(reports, state => state.toJS().bulkDemographics);
const selectShowBulk = () => createSelector(reports, state => state.toJS().showBulk);
const selectMappedDemos = () => createSelector(reports, state => state.toJS().mappedDemos);
const selectResurveyDemographics = () => createSelector(reports, state => state.toJS().resurveyDemographics);
const selectResurveyDemographicDataFetched = () =>
    createSelector(reports, state => state.toJS().resurveyDemographicDataFetched);
const selectShowAvgGap = () => createSelector(reports, state => state.toJS().showAvgGap);
const selectTaskId = () => createSelector(reports, state => state.toJS().taskId);
const selectShowMultiProgress = () => createSelector(reports, state => state.toJS().showMultiProgress);
const selectPreviousCompRespondent = () => createSelector(reports, state => state.toJS().previousCompRespondent);
const selectScoreBulkData = () => createSelector(reports, state => state.toJS().scoreBulkData);
const selectScoreBulkDataFetched = () => createSelector(reports, state => state.toJS().scoreBulkDataFetched);
const selectOrgLevelBu = () => createSelector(reports, state => state.toJS().orgLevelBu);

export {
    selectReportError,
    selectReportApiCount,
    selectReportApiParams,
    selectBuFilters,
    selectBenchDataFetched,
    selectDemograhicDataFetched,
    selectFilterIndex,
    selectPastReports,
    selectCurrentTab,
    selectInclusionData,
    selectGapData,
    selectGapDataFetched,
    selectPendingReports,
    selectRespondent,
    selectPreviousRespondent,
    selectGraphData,
    selectGraphDataFetched,
    selectComments,
    selectPracticeFilters,
    selectBulkDemographics,
    selectShowBulk,
    selectMappedDemos,
    selectResurveyDemographics,
    selectResurveyDemographicDataFetched,
    selectShowAvgGap,
    selectTaskId,
    selectShowMultiProgress,
    selectPreviousCompRespondent,
    selectScoreBulkData,
    selectScoreBulkDataFetched,
    selectOrgLevelBu,
};
