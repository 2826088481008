import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { MenuItem, Menu, Tooltip } from '@material-ui/core';
import c from 'classnames';

import AUTH0 from '../../auth_config';
import AuthStore from '../../common/AuthStore';
import UpdateViewModal from './UpdateViewModal';
import { getStaticText } from '../../utils/constants';
import { getFirstLetter } from '../../utils/functions';
// import logo from '../../assets/logo.png';
import './index.scss';

import MultipleUser from '../../assets/MultipleUser.svg';
import Reload from '../../assets/Reload.svg';
import GearSettings from '../../assets/GearSettings.svg';
import Logout from '../../assets/Logout.svg';
import smallRight from '../../assets/smallRight.svg';
import copyIcon from '../../assets/copyIcon.svg';
import Home from '../../assets/Home.svg';
import logoHead from '../../containers/Login/assets/logo2.svg';

const Layout = ({
    children,
    showHeader,
    removeToken,
    history,
    showNavigation,
    pendingReports,
    defaultSettings,
    showTabAction,
    showBenchTab,
    reportsData,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [showAddModal, updateModal] = useState(false);
    const {
        inclusion_added = false,
        year = '',
        lang = '1033',
        engagement = '',
        health_access,
        response_rate_access,
        ohid = '',
    } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, USER_ROLES, NON_PROFIT_SURVEY, TALX_REPORT_ID } = staticText;
    const { REPORTS, SETTINGS, LOG_OUT, USER_MNGT, REPORTS_TIP_TEXT, HEADER_NAV, HEADER_NAV_2, HEADER_NAV_3, HOME } =
        SITE_TEXT || {};
    const { CLIENT, CST, USER, SUPER_ADMIN, ADMIN } = USER_ROLES;
    const USERS_IN_CHECK = [CLIENT, CST, USER];

    const isNonProfit = engagement === NON_PROFIT_SURVEY;
    const open = Boolean(anchorEl);
    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const { path, isExact } = useRouteMatch();
    const {
        ohid: enableClick = '',
        userName = '',
        userRole = '',
        actionAccess = '',
        surveyName = '',
        gsodAccess = '',
        report_id,
    } = AuthStore;
    const userNameSplit = userName ? userName.split(' ') : '';
    const firstName = getFirstLetter(userNameSplit[0]);
    const lastName = getFirstLetter(userNameSplit[userNameSplit.length - 1]);
    const [selectedUserManagement, setSelectedUserManagement] = useState(false);
    const reportUserLength = Object.keys(reportsData).length || {};

    const UserRolesHere = [CLIENT];
    const LatamOhid = ohid === 'OHI13648';

    const checkUserRole = UserRolesHere.includes(userRole);

    const logOut = () => {
        AuthStore.deleteAccessToken();
        history.push('/');
        removeToken();
        AuthStore.deleteOhId();
        AuthStore.deleteReportId();
        AuthStore.deleteUserName();
        AuthStore.deleteUserEmail();
        AuthStore.deleteUserRole();
        AuthStore.deleteActionAccess();
        AuthStore.deleteMidToken();
        AuthStore.deleteShowFeatureModal();
        AuthStore.deleteGsodAccess();
        AuthStore.deletePptAccessToken();
        AuthStore.deletePptTokenExp();
        AuthStore.deletePracticeBU();
        AuthStore.deletePracticeReportBU();
        AuthStore.deleteResurveyFilter1();
        AuthStore.deleteResurveyFilter2();
        AuthStore.deleteGsodLoginNumber();
        AuthStore.deletegsodApprover();
        AuthStore.deleteohiLoginNumber();
    };

    const showUserViewModal = () => {
        updateModal(true);
        handleClose();
    };

    const benchTab = gsodAccess && !actionAccess && reportUserLength <= 0;

    const handleUser = () => {
        setSelectedUserManagement(!selectedUserManagement);
    };

    const isAdmin = userRole && (userRole === SUPER_ADMIN || userRole === ADMIN);
    const isClient = userRole && userRole === CLIENT;
    const role = Object.keys(USER_ROLES).filter(val => userRole === USER_ROLES[val])[0];
    const roleText = role?.includes('_') ? role.split('_').join(' ') : role;
    const isTalnxClient = report_id === TALX_REPORT_ID;
    const showRptBtn = health_access && !(isClient && isTalnxClient) && (path === '/diagnose' || path === '/inclusion');

    const hasSuccess = pendingReports.some(item => item.status === 'completed');
    const toolTitle =
        pendingReports.filter(({ pptGenerated }) => !pptGenerated).length && hasSuccess ? REPORTS_TIP_TEXT : '';

    const removeOldId = () => {
        AuthStore.deleteOhId();
        AuthStore.deleteReportId();
    };

    const handleCopyClick = () => {
        const textToCopy = `${enableClick} (${year})`;
        navigator.clipboard.writeText(textToCopy);
    };
    return (
        <div className="layout clearfix">
            <div className="header">
                <div className="headLogo">
                    <a href="/" rel="noopener noreferrer" onClick={removeOldId}>
                        <img src={logoHead} alt="" />
                    </a>
                </div>

                {showNavigation && showHeader && showBenchTab && showTabAction ? (
                    <div className="clearfix">
                        <div className="newAddedHeader">
                            <ul>
                                {HEADER_NAV.map(
                                    ({
                                        text,
                                        link,
                                        activeLink,
                                        checkAccess,
                                        checkRR,
                                        checkHealthAccess,
                                        checkAction,
                                        checkIncAccess,
                                        checkUserPermission,
                                        checkGsodAccess,
                                        checkLatamClient,
                                        checkNonProfit,
                                    }) => {
                                        if (
                                            (checkAccess && isClient) ||
                                            (checkAction && actionAccess !== 'true') ||
                                            (checkIncAccess && (!inclusion_added || isNonProfit)) ||
                                            (checkGsodAccess && gsodAccess !== 'true') ||
                                            (USERS_IN_CHECK.includes(userRole) && checkRR && !response_rate_access) ||
                                            (USERS_IN_CHECK.includes(userRole) &&
                                                checkHealthAccess &&
                                                !health_access) ||
                                            (USERS_IN_CHECK.includes(userRole) && checkUserPermission) ||
                                            (checkLatamClient && UserRolesHere.includes(userRole) && LatamOhid) ||
                                            (checkNonProfit && isNonProfit)
                                        ) {
                                            return null;
                                        }

                                        return (
                                            <li
                                                key={text}
                                                className={c({
                                                    enableClick: activeLink || enableClick,
                                                    hidden: path === '/' && isExact,
                                                })}
                                            >
                                                <NavLink activeClassName={c({ selected: path === link })} to={link}>
                                                    {text}
                                                </NavLink>
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                        </div>
                        <div className="newForHeader">
                            <span
                                data-testid="settingsBtn"
                                className={c('settingsButton', {
                                    selectedIcon: open,
                                })}
                                aria-controls="user-menu"
                                onClick={handleClick}
                            >
                                {firstName}
                                {lastName}
                            </span>
                            <Menu
                                id="user-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                PaperProps={{ style: { minWidth: 150 } }}
                            >
                                <MenuItem className="cardMenu">
                                    <div className="dropDownSection">
                                        <span className="cardName" onClick={handleClick}>
                                            {firstName}
                                            {lastName}
                                        </span>
                                        <span className="roleInformation">
                                            <Tooltip title={userName || ''}>
                                                <span className="roleName">
                                                    {userName && userName.length > 26
                                                        ? userName.slice(0, 12) + '...'
                                                        : userName}
                                                </span>
                                            </Tooltip>
                                            <span className="roleText">
                                                {roleText === 'CLIENT' ? 'USER' : roleText}
                                            </span>
                                        </span>
                                    </div>
                                </MenuItem>

                                {enableClick && (
                                    <MenuItem className="cardMenu">
                                        <div className="dropDownSection">
                                            <span
                                                data-testid="roleInfo"
                                                aria-controls="user-menu"
                                                className="roleInformation"
                                            >
                                                <Tooltip arrow title={surveyName}>
                                                    <span className="roleName">
                                                        {surveyName && surveyName.length > 36
                                                            ? surveyName.slice(0, 12) + '...'
                                                            : surveyName}
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title={`${enableClick} (${year})`}>
                                                    <span className="roleText">
                                                        {enableClick} {` (${year})`}
                                                        <img
                                                            src={copyIcon}
                                                            className="copyIcon"
                                                            alt="Copy Icon"
                                                            onClick={handleCopyClick}
                                                        />
                                                    </span>
                                                </Tooltip>
                                            </span>
                                        </div>
                                    </MenuItem>
                                )}

                                {isAdmin && (
                                    <MenuItem
                                        classes={{ root: 'enableClick' }}
                                        className={c('userMenu', {
                                            selectedUser: selectedUserManagement,
                                        })}
                                        onClick={handleUser}
                                    >
                                        <a
                                            target="_blank"
                                            href={`${AUTH0.redirectBaseUrl}/user-management/`}
                                            rel="noopener noreferrer"
                                        >
                                            <div className="userSection">
                                                <img src={MultipleUser} alt="" />
                                                {USER_MNGT}
                                            </div>
                                        </a>
                                    </MenuItem>
                                )}
                                {isAdmin && (
                                    <MenuItem
                                        classes={{ root: 'enableClick' }}
                                        onClick={showUserViewModal}
                                        className="userMenu"
                                    >
                                        <div style={{ width: '100%' }}>
                                            <div className="userSection">
                                                <img src={Reload} alt="" />
                                                Switch account
                                            </div>
                                        </div>
                                        <div>
                                            <img src={smallRight} alt="" className="smallRight" />
                                        </div>
                                    </MenuItem>
                                )}
                                <MenuItem
                                    classes={{ root: c({ enableClick, hide: !enableClick }) }}
                                    className="userMenu"
                                >
                                    <a href="/" rel="noopener noreferrer" onClick={removeOldId}>
                                        <div className="userSection">
                                            <img src={Home} alt="" />
                                            {HOME}
                                        </div>
                                    </a>
                                </MenuItem>
                                <MenuItem
                                    classes={{ root: c({ enableClick, hide: !enableClick }) }}
                                    className="userMenu"
                                >
                                    <NavLink to="/settings">
                                        <div className="userSection">
                                            <img src={GearSettings} alt="" />
                                            {SETTINGS}
                                        </div>
                                    </NavLink>
                                </MenuItem>
                                <MenuItem classes={{ root: 'enableClick' }} onClick={logOut} className="userMenu">
                                    <div style={{ width: '100%' }}>
                                        <div className="userSection">
                                            <img src={Logout} alt="" />
                                            {LOG_OUT}
                                        </div>
                                    </div>
                                </MenuItem>
                            </Menu>
                            {showRptBtn && (!checkUserRole || !LatamOhid) && (
                                <Tooltip arrow title={toolTitle}>
                                    <NavLink
                                        className={c('reportButton newReportBtn', {
                                            enableClick,
                                            toolTitle,
                                            hidden: path === '/',
                                        })}
                                        to="/reports"
                                    >
                                        {REPORTS}
                                    </NavLink>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                ) : null}

                {!showTabAction ? (
                    <div className="clearfix">
                        <div className="newAddedHeader">
                            <ul>
                                {HEADER_NAV_2.map(
                                    ({
                                        text,
                                        link,
                                        activeLink,
                                        checkAccess,
                                        checkRR,
                                        checkHealthAccess,
                                        checkAction,
                                        checkIncAccess,
                                        checkUserPermission,
                                        checkGsodAccess,
                                        checkLatamClient,
                                    }) => {
                                        if (
                                            (checkAccess && isClient) ||
                                            (checkAction && actionAccess !== 'true') ||
                                            (checkIncAccess && (!inclusion_added || isNonProfit)) ||
                                            (checkGsodAccess && gsodAccess !== 'true') ||
                                            (USERS_IN_CHECK.includes(userRole) && checkRR && !response_rate_access) ||
                                            (USERS_IN_CHECK.includes(userRole) &&
                                                checkHealthAccess &&
                                                !health_access) ||
                                            (USERS_IN_CHECK.includes(userRole) && checkUserPermission) ||
                                            (checkLatamClient && UserRolesHere.includes(userRole) && LatamOhid)
                                        ) {
                                            return null;
                                        }

                                        return (
                                            <li
                                                key={text}
                                                className={c({
                                                    enableClick: activeLink || enableClick,
                                                    // hidden: path === '/' && isExact,
                                                })}
                                            >
                                                <NavLink activeClassName={c({ selected: path === link })} to={link}>
                                                    {text}
                                                </NavLink>
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                        </div>
                        <div className="newForHeader">
                            <span
                                data-testid="settingsBtn"
                                className={c('settingsButton', {
                                    selectedIcon: open,
                                })}
                                aria-controls="user-menu"
                                onClick={handleClick}
                            >
                                {firstName}
                                {lastName}
                            </span>
                            <Menu
                                id="user-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                PaperProps={{ style: { minWidth: 150 } }}
                            >
                                <MenuItem className="cardMenu">
                                    <div className="dropDownSection">
                                        <span className="cardName" onClick={handleClick}>
                                            {firstName}
                                            {lastName}
                                        </span>
                                        <span className="roleInformation">
                                            <Tooltip title={userName || ''}>
                                                <span className="roleName">
                                                    {userName && userName.length > 26
                                                        ? userName.slice(0, 12) + '...'
                                                        : userName}
                                                </span>
                                            </Tooltip>
                                            <span className="roleText">
                                                {roleText === 'CLIENT' ? 'USER' : roleText}
                                            </span>
                                        </span>
                                    </div>
                                </MenuItem>

                                {enableClick && (
                                    <MenuItem className="cardMenu">
                                        <div className="dropDownSection">
                                            <span
                                                data-testid="roleInfo"
                                                aria-controls="user-menu"
                                                className="roleInformation"
                                            >
                                                <Tooltip arrow title={surveyName}>
                                                    <span className="roleName">
                                                        {surveyName && surveyName.length > 36
                                                            ? surveyName.slice(0, 12) + '...'
                                                            : surveyName}
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title={`${enableClick} (${year})`}>
                                                    <span className="roleText">
                                                        {enableClick} {` (${year})`}
                                                        <img
                                                            src={copyIcon}
                                                            className="copyIcon"
                                                            alt="Copy Icon"
                                                            onClick={handleCopyClick}
                                                        />
                                                    </span>
                                                </Tooltip>
                                            </span>
                                        </div>
                                    </MenuItem>
                                )}
                                <MenuItem classes={{ root: 'enableClick' }} className="userMenu">
                                    <a href="/" rel="noopener noreferrer" onClick={removeOldId}>
                                        <div className="userSection">
                                            <img src={Home} alt="" />
                                            {HOME}
                                        </div>
                                    </a>
                                </MenuItem>

                                <MenuItem classes={{ root: 'enableClick' }} onClick={logOut} className="userMenu">
                                    <div style={{ width: '100%' }}>
                                        <div className="userSection">
                                            <img src={Logout} alt="" />
                                            {LOG_OUT}
                                        </div>
                                    </div>
                                </MenuItem>
                            </Menu>

                            {showRptBtn && (!checkUserRole || !LatamOhid) && (
                                <Tooltip arrow title={toolTitle}>
                                    <NavLink
                                        className={c('reportButton newReportBtn', {
                                            enableClick,
                                            toolTitle,
                                            hidden: path === '/',
                                        })}
                                        to="/reports"
                                    >
                                        {REPORTS}
                                    </NavLink>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                ) : null}

                {!showBenchTab ? (
                    <div className="clearfix">
                        <div className="newAddedHeader">
                            <ul>
                                {HEADER_NAV_3.map(
                                    ({
                                        text,
                                        link,
                                        activeLink,
                                        checkAccess,
                                        checkRR,
                                        checkHealthAccess,
                                        checkAction,
                                        checkIncAccess,
                                        checkUserPermission,
                                        checkGsodAccess,
                                        checkLatamClient,
                                        showBench,
                                    }) => {
                                        if (
                                            (checkAccess && isClient) ||
                                            (checkAction && actionAccess !== 'true') ||
                                            (checkIncAccess && (!inclusion_added || isNonProfit)) ||
                                            (checkGsodAccess && gsodAccess !== 'true') ||
                                            (USERS_IN_CHECK.includes(userRole) && checkRR && !response_rate_access) ||
                                            (USERS_IN_CHECK.includes(userRole) &&
                                                checkHealthAccess &&
                                                !health_access) ||
                                            (USERS_IN_CHECK.includes(userRole) && checkUserPermission) ||
                                            (checkLatamClient && UserRolesHere.includes(userRole) && LatamOhid) ||
                                            // (showBench && !showSingleBench)
                                            (showBench && benchTab)
                                        ) {
                                            return null;
                                        }

                                        return (
                                            <li
                                                key={text}
                                                className={c({
                                                    enableClick: activeLink || enableClick,
                                                })}
                                            >
                                                <NavLink activeClassName={c({ selected: path === link })} to={link}>
                                                    {text}
                                                </NavLink>
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                        </div>
                        <div className="newForHeader">
                            <span
                                data-testid="settingsBtn"
                                className={c('settingsButton', {
                                    selectedIcon: open,
                                })}
                                aria-controls="user-menu"
                                onClick={handleClick}
                            >
                                {firstName}
                                {lastName}
                            </span>
                            <Menu
                                id="user-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                PaperProps={{ style: { minWidth: 150 } }}
                            >
                                <MenuItem className="cardMenu">
                                    <div className="dropDownSection">
                                        <span className="cardName" onClick={handleClick}>
                                            {firstName}
                                            {lastName}
                                        </span>
                                        <span className="roleInformation">
                                            <Tooltip title={userName || ''}>
                                                <span className="roleName">
                                                    {userName && userName.length > 26
                                                        ? userName.slice(0, 12) + '...'
                                                        : userName}
                                                </span>
                                            </Tooltip>
                                            <span className="roleText">
                                                {roleText === 'CLIENT' ? 'USER' : roleText}
                                            </span>
                                        </span>
                                    </div>
                                </MenuItem>

                                {enableClick && (
                                    <MenuItem className="cardMenu">
                                        <div className="dropDownSection">
                                            <span
                                                data-testid="roleInfo"
                                                aria-controls="user-menu"
                                                className="roleInformation"
                                            >
                                                <Tooltip arrow title={surveyName}>
                                                    <span className="roleName">
                                                        {surveyName && surveyName.length > 36
                                                            ? surveyName.slice(0, 12) + '...'
                                                            : surveyName}
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title={`${enableClick} (${year})`}>
                                                    <span className="roleText">
                                                        {enableClick} {` (${year})`}
                                                        <img
                                                            src={copyIcon}
                                                            className="copyIcon"
                                                            alt="Copy Icon"
                                                            onClick={handleCopyClick}
                                                        />
                                                    </span>
                                                </Tooltip>
                                            </span>
                                        </div>
                                    </MenuItem>
                                )}
                                <MenuItem classes={{ root: 'enableClick' }} className="userMenu">
                                    <a href="/" rel="noopener noreferrer" onClick={removeOldId}>
                                        <div className="userSection">
                                            <img src={Home} alt="" />
                                            {HOME}
                                        </div>
                                    </a>
                                </MenuItem>

                                <MenuItem classes={{ root: 'enableClick' }} onClick={logOut} className="userMenu">
                                    <div style={{ width: '100%' }}>
                                        <div className="userSection">
                                            <img src={Logout} alt="" />
                                            {LOG_OUT}
                                        </div>
                                    </div>
                                </MenuItem>
                            </Menu>

                            {showRptBtn && (!checkUserRole || !LatamOhid) && (
                                <Tooltip arrow title={toolTitle}>
                                    <NavLink
                                        className={c('reportButton newReportBtn', {
                                            enableClick,
                                            toolTitle,
                                            hidden: path === '/',
                                        })}
                                        to="/reports"
                                    >
                                        {REPORTS}
                                    </NavLink>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="mainContent">{children}</div>
            {showAddModal && <UpdateViewModal defaultSettings={defaultSettings} closeModal={updateModal} />}
        </div>
    );
};

Layout.defaultProps = {
    showHeader: false,
    showNavigation: true,
    showTabAction: true,
    showBenchTab: true,
};

Layout.propTypes = {
    showHeader: PropTypes.bool,
    showNavigation: PropTypes.bool,
    children: PropTypes.node.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    removeToken: PropTypes.func.isRequired,
    pendingReports: PropTypes.array.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    showTabAction: PropTypes.bool,
    showBenchTab: PropTypes.bool,
    reportsData: PropTypes.object.isRequired,
};

export default Layout;
