import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import QuestionBoard from './MainBoard';

import {
    getScoreData,
    getPptData,
    getSinglePastReport,
    setAddOnTabs,
    getScoreDataForHybrid,
    getReSurveyData,
    getProjectsData,
    resetReSurveyDataFetched,
    updateFilters,
    getDemographics,
    getBenchmarks,
} from '../actions';

import {
    selectScoreData,
    selectScoreDataFetched,
    selectApiParams,
    selectApiCount,
    selectFiltersApplied,
    getErrorInfo,
    selectFilters,
    selectPastReport,
    selectScoreDataHybrid,
    selectScoreDataHybridFetched,
    selectProjects,
    selectProjectsFetched,
    selectReSurveyData,
    selectReSurveyDataFetched,
    selectLocalPractice,
} from '../selector';

import { getDefaultSettings } from '../../Login/selector';
import { selectPastReports, selectReportError } from '../../Reports/selector';
import { getReport, getRawPptData } from '../../Reports/actions';

const stateToProps = createStructuredSelector({
    scoreData: selectScoreData(),
    scoreDataFetched: selectScoreDataFetched(),
    apiParams: selectApiParams(),
    apiLoadingCount: selectApiCount(),
    activeFilter: selectFiltersApplied(),
    errorInfo: getErrorInfo(),
    filters: selectFilters(),
    defaultSettings: getDefaultSettings(),
    pastReports: selectPastReports(),
    reportError: selectReportError(),
    singlePastReport: selectPastReport(),
    scoreDataHybrid: selectScoreDataHybrid(),
    scoreDataHybridFetched: selectScoreDataHybridFetched(),
    projects: selectProjects(),
    projectsFetched: selectProjectsFetched(),
    reSurveyData: selectReSurveyData(),
    reSurveyDataFetched: selectReSurveyDataFetched(),
    localPractice: selectLocalPractice(),
});

const dispatchToProps = {
    getScoreData,
    getPptData,
    getReport,
    getRawPptData,
    getSinglePastReport,
    setAddOnTabs,
    getScoreDataForHybrid,
    getReSurveyData,
    getProjectsData,
    resetReSurveyDataFetched,
    updateFilters,
    getDemographics,
    getBenchmarks,
};

export default connect(stateToProps, dispatchToProps)(QuestionBoard);
