import React from 'react';
import { Dialog } from '@material-ui/core';
import './index.scss';

const ConfirmationModal = props => {
    const { children } = props;

    return (
        <Dialog
            open
            disableEscapeKeyDown
            disableBackdropClick
            maxWidth="md"
            classes={{
                root: 'confirmDialog',
            }}
        >
            {children}
        </Dialog>
    );
};

export default ConfirmationModal;
