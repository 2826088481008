import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Overview from './Overview';
import { selectAllGapDataFetched, selectFiltersApplied } from '../selector';
import { selectRespondent, selectComments } from '../../Reports/selector';
import { updateRespondents, updateComments } from '../../Reports/actions';
import { getDefaultSettings } from '../../Login/selector';

const stateToProps = createStructuredSelector({
    respondent: selectRespondent(),
    comments: selectComments(),
    activeFilter: selectFiltersApplied(),
    allGapDataFetched: selectAllGapDataFetched(),
    defaultSettings: getDefaultSettings(),
});

const dispatchToProps = { updateRespondents, updateComments };

export default connect(stateToProps, dispatchToProps)(Overview);
