import { createSelector } from 'reselect';

const userReports = state => state.get('userReports');

const selectApiCount = () => createSelector(userReports, state => state.toJS().count);
const selectReportsData = () => createSelector(userReports, state => state.toJS().userReports);
const selectReportsFetched = () => createSelector(userReports, state => state.toJS().userReportsFetched);
const getErrorInfo = () => createSelector(userReports, state => state.toJS().error);
const selectUsersList = () => createSelector(userReports, state => state.toJS().usersList);
const selectUsersFetched = () => createSelector(userReports, state => state.toJS().usersListFetched);
const getListErrorInfo = () => createSelector(userReports, state => state.toJS().listError);

export {
    selectApiCount,
    selectReportsData,
    selectReportsFetched,
    getErrorInfo,
    selectUsersList,
    selectUsersFetched,
    getListErrorInfo,
};
