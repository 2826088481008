import React, { useState, Fragment } from 'react';
import { Menu } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import c from 'classnames';
import { cloneDeep } from 'lodash';

import Benchmarks from '../../../components/FilterBar/Benchmarks';

function SelectMenu({ control, benchmarks, updateBenchmarks, getSelectedChild, menuClass, defaultSettings }) {
    const [anchor, setAnchor] = useState(null);
    const leftSelectOpen = Boolean(anchor);
    const openMenu = event => setAnchor(event.currentTarget);

    const handleClose = () => {
        const updatedBenchmarks = cloneDeep(benchmarks);
        const openedBenchmark = updatedBenchmarks.findIndex(({ isOpen: oldOpen }) => oldOpen);
        if (openedBenchmark !== -1) {
            updatedBenchmarks[openedBenchmark].isOpen = false;
            updatedBenchmarks[openedBenchmark].viewAll = false;
            updatedBenchmarks[openedBenchmark].search = '';
        }
        updateBenchmarks(updatedBenchmarks);
        setAnchor(null);
    };

    const returnSelected = item => {
        setAnchor(null);
        getSelectedChild(item, control);
    };

    let label = '';
    const selectIndex = benchmarks.findIndex(({ selectedOption }) => selectedOption);
    if (selectIndex !== -1) {
        const { label: leftLabelItem = '' } =
            benchmarks[selectIndex].options.filter(({ code }) => code === benchmarks[selectIndex].selectedOption)[0] ||
            {};
        label = leftLabelItem;
    }

    return (
        <Fragment>
            <div aria-controls={control} onClick={openMenu}>
                <span title={label} className="ellipsisPro">
                    {label}
                </span>
                <span className={c({ open: leftSelectOpen })}>
                    <ExpandMoreIcon />
                </span>
            </div>
            <Menu
                id={control}
                anchorEl={anchor}
                keepMounted
                open={leftSelectOpen}
                onClose={handleClose}
                classes={{
                    paper: `selectMenu ${menuClass}`,
                }}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                PaperProps={{ style: { minWidth: 250 } }}
            >
                <div className="rightSideFilters">
                    <Benchmarks
                        resetOnSelect
                        showHeader={false}
                        defaultSettings={defaultSettings}
                        benchmarks={benchmarks}
                        updateBenchmarks={updateBenchmarks}
                        getSelectedChild={returnSelected}
                    />
                </div>
            </Menu>
        </Fragment>
    );
}

SelectMenu.defaultProps = {
    menuClass: '',
};

SelectMenu.propTypes = {
    control: PropTypes.string.isRequired,
    menuClass: PropTypes.string,
    benchmarks: PropTypes.array.isRequired,
    updateBenchmarks: PropTypes.func.isRequired,
    getSelectedChild: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
};

export default SelectMenu;
