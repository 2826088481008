import { GET_REPORTS, GET_REPORT_USERS } from './constants';

export const getReports = () => ({
    type: `${GET_REPORTS}_PENDING`,
});

export const getReportsSuccess = payload => ({
    type: `${GET_REPORTS}_SUCCESS`,
    payload,
});

export const getReportsFailed = (payload = {}) => ({
    type: `${GET_REPORTS}_FAILED`,
    payload,
});
export const getReportUsers = payload => ({
    type: `${GET_REPORT_USERS}_PENDING`,
    payload,
});

export const getReportUsersSuccess = payload => ({
    type: `${GET_REPORT_USERS}_SUCCESS`,
    payload,
});

export const getReportUsersFailed = (payload = {}) => ({
    type: `${GET_REPORT_USERS}_FAILED`,
    payload,
});
