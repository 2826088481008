import { fetchWebApi, postWebApi, putWebApi } from '../../webapis/dashboard';
import endpoints from '../../Endpoints';

export const getDailyRespUrl = options => {
    const { apiBasePath, dailyResPath } = endpoints;
    const url = `${apiBasePath}${dailyResPath}`;
    return postWebApi(url, options).request;
};

export const getDemoResUrl = options => {
    const { apiBasePath, demoResPath } = endpoints;
    const url = `${apiBasePath}${demoResPath}`;
    return postWebApi(url, options).request;
};

export const crossTapPathsUrl = options => {
    const { apiBasePath, crossTapPath } = endpoints;
    const url = `${apiBasePath}${crossTapPath}`;
    return postWebApi(url, options).request;
};

export const getResRateConfig = options => {
    const { apiBasePath, resRateConfigPath } = endpoints;
    const { ohid, lang, demographics } = options;
    const url = `${apiBasePath}${resRateConfigPath}?ohid=${ohid}&lang=${lang}&demographic=${demographics}`;
    return fetchWebApi(url).request;
};

export const postResRateConfig = options => {
    const { apiBasePath, resRateConfigPath } = endpoints;
    const url = `${apiBasePath}${resRateConfigPath}`;
    return putWebApi(url, options).request;
};
