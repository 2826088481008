import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ListItem, Checkbox, Select, MenuItem } from '@material-ui/core';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import c from 'classnames';

import { getStaticText } from '../../../utils/constants';

function DemographicFilter({
    showSelect,
    heatMapOption,
    sort_by,
    handleDemographicsData,
    // selectAll,
    handleSelectAllHeatMap,
    demoGraphicState,
    heatmap_demographic,
    handleSelect,
    hiddenOptions,
    defaultSettings,
    // showSelectAll,
    isResurveyCompHeatMap,
    resurveyMapping,
    showResurveyReportOnly,
    unmapped_demos = new Map(),
    sort_name,
}) {
    const { lang, engagement } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, CUSTOM, NON_PROFIT_SURVEY } = staticText;
    const { SORT_BY, SORTARR, NO_MAPPED_DEMO, MAPPED, SORTARR_EE } = SITE_TEXT || {};
    const [mappedDemoList, setMappedDemoList] = useState([]);
    const [demoMapped, setDemoMapped] = useState([]);
    const isNonProfit = engagement === NON_PROFIT_SURVEY;

    useEffect(() => {
        if (heatMapOption === CUSTOM) {
            const mappedList = showResurveyReportOnly ? Object.values(resurveyMapping) : [];
            setDemoMapped(mappedList);
            const comparedList = [];
            const unComparedList = [];
            mappedList.forEach(obj1 => {
                const match = demoGraphicState.find(obj2 => obj2.code === obj1.code);
                if (match) {
                    comparedList.push(obj1);
                }
            });
            demoGraphicState.forEach(obj2 => {
                const match = mappedList.find(obj1 => obj1.code === obj2.code);
                if (!match) {
                    unComparedList.push(obj2);
                }
            });
            const combinedLists = [...comparedList, ...unComparedList];
            setMappedDemoList(combinedLists);
        }
        // eslint-disable-next-line
    }, [heatMapOption, demoGraphicState, resurveyMapping, showResurveyReportOnly]);

    if (heatMapOption !== CUSTOM) return null;
    if (!isResurveyCompHeatMap && !demoGraphicState.length) return <div className="noMapDemo">{NO_MAPPED_DEMO}</div>;
    let limit = demoGraphicState.length;
    const map = Object.fromEntries(heatmap_demographic);
    const sortArrToUse = sort_name === 'sort_by' ? SORTARR : SORTARR_EE;

    if (!handleSelectAllHeatMap) {
        limit -= Object.keys(map).length;
    }
    return (
        <div className="optionWrappered">
            {showSelect && (
                <div className="settingContent">
                    <div className="title">{SORT_BY}</div>
                    {!isNonProfit && (
                        <Select
                            IconComponent={KeyboardArrowDownSharpIcon}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name={sort_name}
                            value={sort_by}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                            }}
                            onChange={({ target }) => handleDemographicsData(target)}
                        >
                            {sortArrToUse.map(({ key, label }) => (
                                <MenuItem classes={{ root: 'demographicOptions' }} key={key} value={key}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    )}

                    <div className="sortSelection">
                        <div>
                            Select demographic*<span>(1 minimum selection required)</span>
                        </div>
                    </div>
                </div>
            )}
            <div className="mainListWrap">
                {/* {showSelectAll && (
                    <ListItem
                        classes={{ root: c('mainListContent') }}
                        onClick={() => handleSelectAllHeatMap({ name: 'selectAll' })}
                    >
                        <Checkbox
                            checked={selectAll}
                            name="selectAll"
                            value={selectAll}
                            onChange={({ target }) => handleSelectAllHeatMap(target)}
                        />
                        {SELECT_ALL}
                    </ListItem>
                )} */}
                <div>
                    {mappedDemoList.map(({ code, label }) => {
                        const { label: allLabels = '' } = demoGraphicState.find(item => item.code === code) || [];
                        const mappedDemo = demoMapped.map(({ code: demoCode = '' }) => demoCode);
                        const mappedListing = mappedDemo.includes(code);
                        return (
                            <Fragment key={code}>
                                {hiddenOptions || !showResurveyReportOnly ? (
                                    <div className="mappedLists">
                                        <ListItem
                                            key={code}
                                            classes={{
                                                root: c('mainListContent', {
                                                    disabledContent:
                                                        (limit <= 3 && !map[code] && false) ||
                                                        hiddenOptions.indexOf(code) > -1,
                                                }),
                                            }}
                                            data-testid={label}
                                            onClick={() => handleSelect({ name: code, value: allLabels }, 0)}
                                        >
                                            <Checkbox
                                                key={`checkbox${code}`}
                                                checked={
                                                    heatmap_demographic.has(code) ||
                                                    hiddenOptions.indexOf(code) > -1 ||
                                                    unmapped_demos.has(code)
                                                }
                                                onChange={({ target }) => {
                                                    handleSelect(target, 0);
                                                }}
                                                name={code}
                                                value={allLabels}
                                            />
                                            {allLabels}
                                        </ListItem>
                                        {mappedListing && (
                                            <div className="mappedContent">
                                                <div className="mapped">{MAPPED}</div>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <ListItem
                                        key={code}
                                        classes={{ root: 'mainListContent' }}
                                        data-testid={label}
                                        onClick={() => handleSelect({ name: code, value: label }, 0)}
                                    >
                                        <Checkbox
                                            checked={unmapped_demos.has(code)}
                                            onChange={({ target }) => {
                                                handleSelect(target, 0);
                                            }}
                                            key={`checkbox${code}`}
                                            name={code}
                                            value={allLabels}
                                        />
                                        {allLabels}
                                    </ListItem>
                                )}
                            </Fragment>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

DemographicFilter.defaultProps = {
    showSelect: true,
    hiddenOptions: [],
    sort_by: '',
    handleSelectAllHeatMap: () => {},
    handleDemographicsData: () => {},
    // selectAll: false,
    isResurveyCompHeatMap: false,
    // showSelectAll: true,
    showResurveyReportOnly: false,
    sort_name: 'sort_by',
};

DemographicFilter.propTypes = {
    showSelect: PropTypes.bool,
    // selectAll: PropTypes.bool,
    handleDemographicsData: PropTypes.func,
    sort_by: PropTypes.string,
    hiddenOptions: PropTypes.array,
    // showSelectAll: PropTypes.bool,
    isResurveyCompHeatMap: PropTypes.bool,
    handleSelectAllHeatMap: PropTypes.func,
    heatMapOption: PropTypes.string.isRequired,
    demoGraphicState: PropTypes.array.isRequired,
    heatmap_demographic: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    handleSelect: PropTypes.func.isRequired,
    resurveyMapping: PropTypes.object.isRequired,
    showResurveyReportOnly: PropTypes.bool,
    unmapped_demos: PropTypes.object.isRequired,
    sort_name: PropTypes.string,
};

export default DemographicFilter;
