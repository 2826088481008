import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import TextComments from './TextComments';

import { getTextComments } from '../../actions';

import {
    selectApiCount,
    selectCommentsData,
    selectCommentsDataFetched,
    selectApiParams,
    getErrorInfo,
} from '../../selector';

import { getDefaultSettings } from '../../../Login/selector';

const stateToProps = createStructuredSelector({
    apiLoadingCount: selectApiCount(),
    defaultSettings: getDefaultSettings(),
    commentsData: selectCommentsData(),
    commentsDataFetched: selectCommentsDataFetched(),
    apiParams: selectApiParams(),
    errorInfo: getErrorInfo(),
});

const dispatchToProps = { getTextComments };

export default connect(stateToProps, dispatchToProps)(TextComments);
