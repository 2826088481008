import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Check from '../../containers/Diagnose/assets/C-check.svg';
import Close from '../../assets/Close.svg';
import './index.scss';

let timeout = '';

const SuccessToast = ({ message, closeView, actionCode }) => {
    const ACTION_HEADER = {
        0: 'Draft Created',
        1: 'Benchmark published successfully',
        2: 'Benchmark rejected successfully',
        6: 'Publish request sent',
        8: 'Deleted Custom Benchmark',
        9: 'Duplicated Custom Benchmark',
    };
    useEffect(() => {
        timeout = setTimeout(() => {
            closeView();
        }, 10000);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <div className="toastRoot">
            <div className="toastContent successToast">
                <div className="iconContainer">
                    <img src={Check} alt="loading" />
                </div>
                <div className="textContainer">
                    <div className="head">{ACTION_HEADER[actionCode]}</div>
                    <div className="desc">
                        {/* Survey list according to the selected filter is getting ready for further selection. */}
                        {message}
                    </div>
                </div>
                <div className="closeIcon">
                    <img src={Close} alt="" onClick={closeView} />
                </div>
            </div>
        </div>
    );
};

SuccessToast.propTypes = {
    message: PropTypes.string.isRequired,
    closeView: PropTypes.func.isRequired,
    actionCode: PropTypes.number.isRequired,
};

export default SuccessToast;
