import React, { Fragment, useEffect, useState } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import { Select, MenuItem, Tooltip } from '@material-ui/core';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { Helmet } from 'react-helmet';
import { cloneDeep } from 'lodash';
import Loader from 'react-spinners/FadeLoader';
import DemographicCharts from './DemographicCharts';
import ExcelIcon from '../assets/microsoftExcel.svg';
import Excel from './Excel';

import AuthStore from '../../../common/AuthStore';
import { scoreHeatMapChunk as heatMapChunk, graphChunk, PPT_PARAMS } from '../constants';
import { getStaticText } from '../../../utils/constants';
import {
    getColor,
    getGapColor,
    getRowData,
    sortChildren,
    getDemographicName,
    excludeFromGapText,
    getPptParamsForLegend,
    getBenchmarkColor,
    getDemographicPageCount,
    getExcludeGapString,
    scoreToDisplay,
    gapToDisplay,
    getFiltersAppliedText,
    getRowDataText,
    getFiltersText,
    checkHighLowOverall,
} from '../../../utils/functions';
import FilterSvg from '../../Diagnose/assets/Filter.svg';
import ActiveFilter from '../../Diagnose/assets/ActiveFilter.svg';
import DownloadIcon from '../../Diagnose/assets/Download.svg';
import CompareView from '../../Diagnose/assets/CompareView.svg';
import Close from '../../../assets/Close.svg';
import './index.scss';
import DemographicInclusionData from './DemographicInclusionData';
import GapInclusionData from './GapData';
import ResurveyDemographicTable from './ResurveyDemographicTable';
import ResurveyGapTable from './ResurveyGapTable';
import ErrorToast from '../../../components/InformationToast/ErrorToast';

function DemographicInclusion({
    toggleFilterView,
    demographics,
    activeFilter,
    currentOhid,
    gapDataFetched,
    getGapData,
    gapData,
    setDropDownValue,
    dropDownValue,
    error,
    inclusionqbyq,
    defaultSettings,
    getPptData,
    graphData,
    graphDataFetched,
    updateGraphGapData,
    lang,
    reportApiCount,
    inclusion_threshold,
    scoreColor,
    iaApiParams,
    getAllGapInclusionData,
    allGapInclusionData,
    allGapInclusionDataFetched,
    selectedTab,
    setSelectedTab,
    reportApiParams,
    pagesLoaded,
    setpageDataFetched,
    resurveyGapData,
    resurveyGapDataFetched,
    previousOhid,
    getResurveyGapData,
    resurveyDemographic,
    benchmarkName,
    isPulse,
    getMappingExcel,
    demographicMappedData,
    benchmarkVersions,
    bmVersionInUse,
    prevYear,
    allIaGapData,
    allGapDataFetched,
    allGapInclusionDataLoading,
    showAvgGap,
    setAvgGap,
    getDemographics,
    getMappedDemographic,
    showResurvey,
    setResurvey,
    setModalType,
    deleteScoreComparison,
    resurveyDemoFetched,
    getQbyqNsize,
    resurveyDataFetched,
    demoNSize,
    resurveyDropdownGroup,
    setResurveyDropDownValue,
    getResurveyAllGapData,
    resurveyAllGapData,
    resurveyAllGapDataFetched,
    resurveyInactive,
    getResAllGapInclusionData,
    resAllGapInclusionDataFetched,
    resAllGapInclusionData,
    resAllGapInclusionDataLoading,
    isDiffSurveyV,
    isDiffSurveyV2,
}) {
    const excludeOption = ['demo_StraightLiners', 'demo_StraightLinersIA'];
    const staticText = getStaticText(lang);
    const { SITE_TEXT, INCLUSION_DEFAULT_BENCHMARK_STR } = staticText;
    const { qbyq = {}, gap = {} } = gapData;
    const { qbyq: incQbyq = {} } = inclusionqbyq;
    const { size: mainSize = 0 } = incQbyq;
    const { qbyq: resHeatmapQbyq = {} } = demoNSize || {};
    const { size: demoPrevNsize } = resHeatmapQbyq || {};
    const [sortGroup, setSortGroup] = useState([]);
    const [sortParent, setSortParent] = useState([]);
    const [parentG, setParent] = useState([]);
    const [overallView, setOverallView] = useState(true);
    const checkLength = Object.keys(incQbyq).length;
    const checkMainLength = !!Object.keys(qbyq).length;
    const { styles = [], pptBenchmarkStyles = [] } = scoreColor;
    const {
        with_benchmark = true,
        benchmarks = '',
        filters = [],
        resurveyDemoFilters: resurveyFilters = [],
    } = iaApiParams;
    const resurveyDropdown =
        demographicMappedData.map(({ current_demographic_parent }) => current_demographic_parent) || [];
    const { pages = [] } = pagesLoaded;
    const {
        FILTERS,
        DEMOGRAPHIC,
        // DISCLAIMER_GAP_TEXT,
        DISCLAIMER,
        DOWNLOAD,
        // DISCLAIMER_TEXT_PART1,
        // DISCLAIMER_TEXT_PART2,
        FILTES_CHECK_NOTE,
        NUMBER_TEXT,
        N_SIZE_TEXT,
        PERSONAL_EXP,
        ENT_PERCEPTION,
        N_SIZE_SMALL,
        practicesByOutcome,
        DEMO_INCLUSION_TABS,
        FILTER_HOVER_TXT,
        FILTERS_APPLIED,
        BENCHMARKS_APPLIED,
        NUMBER_CAP_SURVEY_TEXT,
        NUMBER_CAP_TEXT,
        RESURVEY_TEXT,
        RESURVEY_INACTIVE_TEXT,
        SELECT_DEMO_GROUP,
        NONE,
        INCLUSION,
        SCORECARD_DEMOGRAPHIC_DISCLAIMER,
        RESURVEY_DEMO_VALUE_BASED,
        QUARTILE_MOVE,
        RESURVEY_DEMO_QUARTILE_BASED,
        RESURVEY_DEMO_VALUE_BASED_V2,
        RESURVEY_DEMO_QUARTILE_BASED_V2,
    } = SITE_TEXT;
    const [scoreData, setScoreData] = useState({});
    const { categories = [], series = [] } = scoreData;
    const {
        report_name_or_client_name: rName = '',
        exclude_inclusion_gap: defaultExGap = '',
        year = '',
        ohid = '',
        inclusion_resurvey_settings = '',
    } = defaultSettings;
    const { exclude_inclusion_gap: exGap = '' } = reportApiParams;
    const [page, updatePage] = useState(1);
    const [child, setChild] = useState([]);
    const [sort, setSort] = useState('');
    const { pptAccessToken = '' } = AuthStore;
    const [gapScoreData, setGapScoreData] = useState({});
    const [showTag, setShowTag] = useState(false);
    const [resDemo, setResDemo] = useState({});
    const [selectedSubTab, setSelectedSubTab] = useState(0);
    const [lowestScores, setLowestScores] = useState([]);
    const [largestScores, setLargestScores] = useState([]);
    const [selectedDownload, setSelectedDownload] = useState('');
    const [dwnldDrop, setDwnldDrop] = useState(false);
    const DEMO_TABS = resurveyGapDataFetched ? DEMO_INCLUSION_TABS : DEMO_INCLUSION_TABS.slice(0, 3);
    const pptParams = PPT_PARAMS;
    const pptStyle = with_benchmark ? pptBenchmarkStyles : styles;
    let excludeArr = defaultExGap || ' ';
    if (exGap) {
        excludeArr = exGap + ',' + defaultExGap;
    }
    const selectedBm = benchmarkVersions.filter(
        ({ BenchmarkPreCalculationSubVersionId }) => BenchmarkPreCalculationSubVersionId === bmVersionInUse
    )[0];
    const { BenchmarkPreCalculationSubVersionName: bmName = '', SurveyCount = '', RespondentCount = '' } =
        selectedBm || {};
    const benchmarkText = `${NUMBER_CAP_TEXT} = ${RespondentCount} | ${NUMBER_CAP_SURVEY_TEXT} : ${SurveyCount} | ${bmName}`;
    const gapDataProps = {
        ohid: currentOhid,
        group_on: '',
        filters,
        lang,
        inclusion_threshold,
    };
    const resurveyNote = ohid === 'OHI12922';
    const isValueBased = inclusion_resurvey_settings === 'Value based';
    const isQuartileMovement = inclusion_resurvey_settings === QUARTILE_MOVE;

    const callGapData = (gap_on, group_on = '') => {
        getGapData({
            ohid: currentOhid,
            gap_on,
            group_on,
            filters,
            lang,
            inclusion_threshold,
            with_benchmark,
            benchmark_sub_version_id: bmVersionInUse,
        });
    };

    const callResurveyGapData = (gap_on, group_on = '') => {
        getResurveyGapData({
            ohid: currentOhid,
            gap_on,
            group_on,
            filters,
            compare_with_ohid: previousOhid,
            compare_with_filters: resurveyFilters,
            lang,
            inclusion_threshold,
            with_benchmark,
            benchmark_sub_version_id: bmVersionInUse,
        });
    };

    const loadGapDataHandler = () => {
        const filteredDemo = demographics.filter(
            ({ code }) => !excludeArr.includes(code) && !excludeOption.includes(code)
        );
        return getAllGapInclusionData({
            ohid: currentOhid,
            group_on: '',
            filters,
            lang,
            inclusion_threshold,
            demographics: filteredDemo,
            page,
            benchmark_sub_version_id: bmVersionInUse,
        });
    };

    useEffect(() => {
        if (!gapDataFetched && demographics.length) {
            const { code = '' } = demographics[0] || {};
            const valueToSet = dropDownValue || code;
            setDropDownValue(valueToSet);
            callGapData(valueToSet);
        }
        if (selectedTab === 1 && !allGapInclusionDataFetched && !allGapInclusionDataLoading) {
            loadGapDataHandler();
        }
        // eslint-disable-next-line
    }, [gapDataFetched, demographics, selectedTab, allGapInclusionDataFetched]);

    useEffect(() => {
        if (!resurveyGapDataFetched) {
            if (previousOhid) {
                if (!resurveyDemoFetched) {
                    getDemographics({ ohid: previousOhid, lang }, 1);
                    getMappedDemographic({ ohid: currentOhid, prevOhid: previousOhid });
                }
                callResurveyGapData(resurveyDropdownGroup);
                getQbyqNsize({
                    ohid: previousOhid,
                    filters: resurveyFilters,
                    inclusion_threshold,
                    with_benchmark: benchmarks !== '-1:-1',
                    benchmarks: INCLUSION_DEFAULT_BENCHMARK_STR,
                    pid: '',
                    benchmark_sub_version_id: bmVersionInUse,
                });
                getResurveyAllGapData({
                    ohid: previousOhid,
                    filters: resurveyFilters,
                    lang,
                    inclusion_threshold,
                    exclude_from_gap: '',
                });
                setSelectedTab(3);
                deleteScoreComparison({});
                setResurvey(false);
                setModalType(2);
            } else {
                setModalType(1);
                setSelectedTab(0);
            }
        }
        // eslint-disable-next-line
    }, [previousOhid, resurveyGapDataFetched]);

    useEffect(() => {
        if (resurveyDataFetched) {
            deleteScoreComparison({});
        }
        // eslint-disable-next-line
    }, [resurveyDataFetched]);

    const getScoreOffSet = arr => {
        if (!arr.length) return;
        const hArr = [];
        const lArr = [];
        for (let i = 2; i < arr[0].length; i += 1) {
            let { displayValue: max = 100 } = arr[0][i];
            let { displayValue: min = 100 } = arr[0][i];
            const maxArr = [];
            const minArr = [];
            for (let j = 0; j < arr.length; j += 1) {
                const { displayValue: checkMax = 100, isDemo_999 = false } = arr[j][i] || { displayValue: 100 };
                const { displayValue: checkMin = -1 } = arr[j][i] || { displayValue: -1 };
                if (checkMax > max && !isDemo_999) {
                    max = checkMax;
                }
                if (checkMin < min && !isDemo_999) {
                    min = checkMin;
                }
            }
            for (let j = 0; j < arr.length; j += 1) {
                const { displayValue: checkMax = 100, isDemo_999 = false } = arr[j][i] || { displayValue: 100 };
                const { displayValue: checkMin = 0 } = arr[j][i] || { displayValue: 0 };
                if (checkMax === max && !isDemo_999) {
                    maxArr.push({ [`${i - 2}_${j}`]: '#228C22' });
                }
                if (checkMin === min && !isDemo_999) {
                    minArr.push({ [`${i - 2}_${j}`]: '#FCAE1E' });
                }
            }

            hArr.push(maxArr);
            lArr.push(minArr);
        }
        setLowestScores(lArr);
        setLargestScores(hArr);
    };

    const getDataForHeatmap = (qbyqData, gapObj, overallGapData) => {
        const obj = {
            finalArr: [],
            headerArr: [],
            gapArr: [],
            series: [[], [], []],
            scoreForGap: [],
            overallArr: [],
            allGapArr: [],
        };

        const { gap: allGap = {} } = overallGapData || {};
        const { records: qbyqRecord = {}, level_scores: mainLevel = {} } = incQbyq;
        obj.overallArr.push({ ...mainLevel.overall, score: mainLevel.overall.display_score });
        Object.keys(qbyqRecord).forEach(k => {
            const { display_name, sort_order, level, children = {} } = qbyqRecord[k];
            allGap[k] = { display_name, sort_order, level, ...allGap[k] };
            const gapchildrenObj = children;
            Object.keys(children).forEach(childrenK => {
                const { display_name: gapDisplayName, sort_order: gapSortOrder } = gapchildrenObj[childrenK];
                const { children: allGapChildren = {} } = allGap[k];
                allGapChildren[childrenK] = {
                    display_name: gapDisplayName,
                    sort_order: gapSortOrder,
                    ...allGapChildren[childrenK],
                };
            });
        });

        const filteredGaps = sortChildren(
            Object.keys(allGap)
                .filter(k => allGap[k].level !== '-')
                .reduce((o, k) => {
                    o[k] = allGap[k];
                    return o;
                }, {})
        );

        Object.keys(filteredGaps).forEach(val => {
            const { gap: pGap = '', key: pkey, avg_gap: pAvgGap = '', demographic: pDemographics = '' } = filteredGaps[
                val
            ];
            obj.allGapArr.push({ gap: showAvgGap ? pAvgGap : pGap, key: pkey, demographic: pDemographics });
        });

        Object.keys(filteredGaps).forEach(val => {
            const { children = {} } = filteredGaps[val];
            const sortGapChildrenObj = sortChildren(children);
            Object.keys(sortGapChildrenObj).forEach(childrenVal => {
                const {
                    gap: cGap = '',
                    key: ckey,
                    avg_gap: cAvgGap = '',
                    demographic: cDemographics,
                } = sortGapChildrenObj[childrenVal];
                obj.allGapArr.push({ gap: showAvgGap ? cAvgGap : cGap, key: ckey, demographic: cDemographics });
            });
        });

        const { level_scores: levelScore, records: qbyqRecords = {} } = incQbyq;
        const { levels_gap: allLevelGap = {} } = allIaGapData || {};
        const { overall: gapOverall = {}, individual: ind = {}, enterprise: ent = {} } = allLevelGap || {};
        obj.scoreForGap.push({ ...levelScore.individual, score: levelScore.individual.display_score });
        obj.scoreForGap.push({ ...levelScore.enterprise, score: levelScore.enterprise.display_score });
        obj.allGapArr.push({ ...ind, gap: showAvgGap ? ind.avg_gap : ind.gap });
        obj.allGapArr.push({ ...ent, gap: showAvgGap ? ent.avg_gap : ent.gap });
        obj.overallArr.push({
            score: showAvgGap ? gapOverall.avg_gap : gapOverall.gap,
            overallDemographics: gapOverall.demographic,
        });
        const filteredRecords = sortChildren(
            Object.keys(qbyqRecords)
                .filter(k => qbyqRecords[k].level !== '-')
                .reduce((o, k) => {
                    o[k] = qbyqRecords[k];
                    return o;
                }, {})
        );
        Object.keys(filteredRecords).forEach(val => {
            const { display_score: score = '', key: pKey, quartile = -1, inclusion_quartile = -1 } = filteredRecords[
                val
            ];
            obj.scoreForGap.push({ score, quartile, inclusion_quartile, key: pKey });
        });
        Object.keys(filteredRecords).forEach(val => {
            const { children = {} } = filteredRecords[val];
            const sortChildrenObj = sortChildren(children);
            Object.keys(sortChildrenObj).forEach(childVal => {
                const {
                    display_score: childScore = '',
                    key: cKey,
                    quartile = -1,
                    inclusion_quartile = -1,
                } = sortChildrenObj[childVal];
                obj.scoreForGap.push({ score: childScore, quartile, inclusion_quartile, key: cKey });
            });
        });

        const selectedGapData = child.length ? child : Object.keys(qbyqData);
        const qbyqKeys = sortGroup.length ? sortGroup.filter(item => selectedGapData.includes(item)) : selectedGapData;
        qbyqKeys.forEach((key, kIndex) => {
            const { display_name = '', size = '', level_scores: levels_score = {}, records = {} } = qbyqData[key] || {};
            const { enterprise = {}, individual = {}, overall = {} } = levels_score || {};
            const isDemo_999 = key.includes('999') || key.includes(999);
            const { display_score: indScore = '' } = individual;
            const { display_score: entScore = '' } = enterprise;
            const newObj = [];
            const outcomesHead = [];
            const outcomesSeries = [];
            const prtByPrtType = [];
            const prtByPrtTypeSeries = [];
            const prtsByOutcomeSeries = [];
            const headerArray = [N_SIZE_TEXT, `_${PERSONAL_EXP}`, `_${ENT_PERCEPTION}`];
            const sizePercent = Math.round((100 * size) / mainSize);
            newObj.push({ displayValue: display_name });
            newObj.push({ displayValue: `${size} (${sizePercent}%)` });
            newObj.push({ ...individual, displayValue: indScore, isDemo_999 });
            newObj.push({ ...enterprise, displayValue: entScore, isDemo_999 });

            if (!kIndex) {
                const { levels_gap = {} } = gapObj;
                const { individual: indGap = {}, enterprise: entGap = {}, overall: demoOverallGap = {} } = levels_gap;
                obj.gapArr.push('Gaps');
                obj.gapArr.push('size');
                obj.gapArr.push(indGap.gap);
                obj.gapArr.push(entGap.gap);
                obj.overallArr.push({ score: demoOverallGap.gap });
            }

            const filterWithoutDash = sortChildren(
                Object.keys(records)
                    .filter(k => records[k].level !== '-')
                    .reduce((o, k) => {
                        o[k] = records[k];
                        return o;
                    }, {})
            );
            Object.keys(filterWithoutDash).forEach(val => {
                const {
                    display_name: record_display_name = '',
                    // score = '',
                    display_score = '',
                    level = '',
                    key: pKey,
                    quartile = -1,
                    inclusion_quartile = -1,
                } = filterWithoutDash[val];
                if (!kIndex) {
                    const { gap: pGap = '' } = gapObj.records[pKey] || {};
                    obj.gapArr.push(pGap);
                }
                newObj.push({ displayValue: display_score, quartile, inclusion_quartile, isDemo_999, key: pKey });
                headerArray.push(`${level}_${record_display_name}`);
                outcomesSeries.push({ displayValue: display_score, quartile, inclusion_quartile });
                outcomesHead.push(record_display_name);
            });
            Object.keys(filterWithoutDash).forEach(val => {
                const { children = {}, key: pKey, display_name: record_display_name = '' } = filterWithoutDash[val];
                const sortChildrenObj = sortChildren(children);
                Object.keys(sortChildrenObj).forEach(childVal => {
                    const {
                        score: childScore = '',
                        display_score: child_dis_score = '',
                        display_name: sub_display_name = '',
                        key: cKey,
                        quartile = -1,
                        inclusion_quartile = -1,
                    } = sortChildrenObj[childVal];
                    if (!kIndex) {
                        const { gap: cGap = '' } = gapObj.records[pKey].children[cKey] || {};
                        obj.gapArr.push(cGap);
                    }
                    newObj.push({ displayValue: child_dis_score, quartile, inclusion_quartile, isDemo_999, key: cKey });
                    headerArray.push(`${record_display_name}_${sub_display_name}`);
                    prtByPrtTypeSeries.push(childScore);
                    prtByPrtType.push(sub_display_name);
                    const selectIndex = practicesByOutcome.findIndex(item => item === sub_display_name);
                    if (selectIndex !== -1) {
                        prtsByOutcomeSeries[selectIndex] = child_dis_score;
                    }
                });
            });

            obj.headerArr = headerArray;
            obj.series[0].push({ name: `${display_name} (${N_SIZE_SMALL}: ${size})`, data: outcomesSeries });
            obj.series[1].push({ name: `${display_name} (${N_SIZE_SMALL}: ${size})`, data: prtByPrtTypeSeries });
            obj.series[2].push({ name: `${display_name} (${N_SIZE_SMALL}: ${size})`, data: prtsByOutcomeSeries });
            obj.finalArr.push(newObj);
            obj.overallArr.push({ ...overall, score: overall.display_score, isDemo_999 });
            obj.categories = [outcomesHead, prtByPrtType, practicesByOutcome];
        });
        return obj;
    };

    useEffect(() => {
        if (gapDataFetched && !error) {
            const { qbyq: currentQbyq = {}, gap: currentGap = {} } = resurveyGapData[currentOhid] || {};
            const dataObj =
                resurveyGapDataFetched && selectedTab === 3
                    ? getDataForHeatmap(currentQbyq, currentGap, allIaGapData)
                    : getDataForHeatmap(qbyq, gap, allIaGapData);
            getScoreOffSet(dataObj.finalArr, dataObj.overallArr);
            setScoreData(dataObj);
        }
        // eslint-disable-next-line
    }, [qbyq, allIaGapData, error, showAvgGap, selectedTab, child, sortGroup]);

    useEffect(() => {
        const clonedQbyq = cloneDeep(qbyq);
        Object.keys(clonedQbyq).forEach(key => {
            if (!child.includes(key)) {
                delete clonedQbyq[key];
            }
        });
        if (child.length) {
            const data = getDataForHeatmap(clonedQbyq, gap, allIaGapData);
            setScoreData(data);
        }
        // eslint-disable-next-line
    }, [child]);

    useEffect(() => {
        if (sort >= 0 && sort < 4 && typeof sort === 'number') {
            const qbyqKeys = Object.keys(qbyq);
            const nSizeObj = {};
            const arr = [];
            const sortedQbyq = {};
            let sortedObjArr = [];
            if (sort < 2) {
                qbyqKeys.forEach(key => {
                    const { size = '' } = qbyq[key];
                    nSizeObj[key] = size;
                });
                sortedObjArr = Object.entries(nSizeObj).sort(([, a], [, b]) => (sort === 0 ? b - a : a - b));
            }
            if (sort >= 2 && sort < 4) {
                qbyqKeys.forEach(key => {
                    const { level_scores = {} } = qbyq[key] || {};
                    const { overall = {} } = level_scores || {};
                    const { score = '' } = overall || {};
                    nSizeObj[key] = score;
                });
                sortedObjArr = Object.entries(nSizeObj).sort(([, a], [, b]) => (sort === 2 ? b - a : a - b));
            }
            sortedObjArr.forEach(item => {
                sortedQbyq[item[0]] = qbyq[item[0]];
                arr.push(item[0]);
            });
            setSortGroup(arr);
            getDataForHeatmap(sortedQbyq, gap, allIaGapData);
        }
        if (sort >= 4 && typeof sort === 'number') {
            const qbyqKeys = Object.keys(allGapInclusionData);
            const overallObj = {};
            const arr = [];
            const sortedQbyq = {};
            let sortedObjArr = [];
            if (sort >= 4) {
                qbyqKeys.forEach(key => {
                    const { levels_gap = {} } = allGapInclusionData[key] || {};
                    const { overall = {} } = levels_gap || {};
                    overallObj[key] = overall.gap;
                });
                sortedObjArr = Object.entries(overallObj).sort(([, a], [, b]) => (sort === 4 ? b - a : a - b));
            }
            sortedObjArr.forEach(item => {
                sortedQbyq[item[0]] = qbyq[item[0]];
                arr.push(item[0]);
            });
            setSortParent(arr);
        }
        if (sort === '') {
            setSortGroup([]);
            setSortParent([]);
        }
        // eslint-disable-next-line
    }, [sort]);

    const getFilterSlideObj = (filterText, Nsize) => {
        return {
            identify_shape_name: 'checkme',
            identify_shape_value: 'filters_applied',
            is_copy: true,
            is_delete: true,
            data: [
                {
                    name: 'filtertext',
                    value: filterText,
                },
                {
                    name: 'exclude_inclusion_gap_text',
                    value: `${excludeFromGapText(getExcludeGapString(defaultExGap, exGap), demographics)}`,
                },
                {
                    name: 'Source',
                    value: `${currentOhid} (${NUMBER_TEXT} = ${Nsize})`,
                },
                {
                    name: 'checkme',
                    is_delete: true,
                },
            ],
        };
    };

    const createGraphPpt = mainData => {
        const { qbyq: graphQbyq = {} } = mainData;
        const pptParamsForLegend = getPptParamsForLegend(benchmarks);
        const graphKeys = Object.keys(graphQbyq);
        const totalLength = graphKeys.length;
        const { qbyq: mainQbyq = {} } = inclusionqbyq || {};
        const { records: mainRecords = {} } = mainQbyq;
        const chart_data = [];
        const chart_data2 = [];
        const commonEle = [
            ...pptParamsForLegend,
            {
                name: 'Source',
                value: `${rName} (${NUMBER_TEXT} = ${mainSize})`,
            },
            {
                name: 'checkme',
                is_delete: true,
            },
            {
                name: 'demographic_parent_name',
                value: `Demographic: ${getDemographicName(demographics, dropDownValue)}`,
            },
        ];
        graphKeys.forEach((qKey, kIndex) => {
            const { size, records, display_name } = graphQbyq[qKey];
            let index = 0;
            commonEle.push({
                name: `demo_child${kIndex + 1}_name`,
                value: `${display_name} (${size})`,
            });
            const filterWithoutDash = sortChildren(
                Object.keys(records)
                    .filter(k => records[k].level !== '-')
                    .reduce((o, k) => {
                        o[k] = records[k];
                        return o;
                    }, {})
            );
            Object.keys(filterWithoutDash).forEach(val => {
                const { children = {} } = filterWithoutDash[val];
                const sortChildrenObj = sortChildren(children);
                Object.keys(sortChildrenObj).forEach(childVal => {
                    const { score: value = '', display_name: sub_display_name = '' } = sortChildrenObj[childVal];
                    chart_data.push({
                        series: kIndex,
                        column_index: kIndex + 1,
                        index,
                        row_index: index + 1,
                        value,
                    });
                    index += 1;
                    const selectIndex = practicesByOutcome.findIndex(item => item === sub_display_name);
                    if (selectIndex !== -1) {
                        chart_data2.push({
                            series: kIndex,
                            column_index: kIndex + 1,
                            index: selectIndex,
                            row_index: selectIndex + 1,
                            value,
                        });
                    }
                });
            });
        });

        for (let i = totalLength; i < graphChunk; i += 1) {
            let index = 0;
            commonEle.push({
                name: `demo_child${i + 1}`,
                is_delete: true,
            });
            Array(17)
                .fill(0)
                .forEach(value => {
                    chart_data.push({
                        series: i,
                        column_index: i + 1,
                        index,
                        row_index: index + 1,
                        value,
                    });

                    chart_data2.push({
                        series: i,
                        column_index: i + 1,
                        index,
                        row_index: index + 1,
                        value,
                    });
                    index += 1;
                });
        }

        Object.keys(mainRecords)
            .filter(k => mainRecords[k].level !== '-')
            .forEach(val => {
                const { score = '', children = {}, meta_scores = [] } = mainRecords[val];
                const { gap: pGap = '' } = gap.records[val] || {};
                const scoreVal = scoreToDisplay(score, meta_scores, isPulse);
                const gapVal = gapToDisplay(pGap, scoreVal, isPulse);
                commonEle.push({
                    name: `${val}_gap`,
                    value: gapVal,
                    style: getGapColor(gapVal, pptStyle, true) + 12,
                });
                Object.keys(children).forEach(childKey => {
                    const { score: childScore = '', meta_scores: child_meta_score = [] } = children[childKey];
                    const { gap: cGap = '' } = gap.records[val].children[childKey] || {};
                    const scoreValChild = scoreToDisplay(childScore, child_meta_score, isPulse);
                    const gapValChild = gapToDisplay(cGap, scoreValChild, isPulse);
                    commonEle.push({
                        name: `${childKey}_gap`,
                        value: gapValChild,
                        style: getGapColor(gapValChild, pptStyle, true) + 12,
                    });
                });
            });

        const filterText = getFiltersText(filters, demographics);
        const filterSlide = getFilterSlideObj(filterText, mainSize);

        const output = {
            ...PPT_PARAMS,
            slides: [
                {
                    identify_shape_name: 'checkme',
                    identify_shape_value: 'practice_score_by_demographic1',
                    is_copy: false,
                    is_delete: false,
                    data: [
                        ...commonEle,
                        {
                            name: 'EKG1_Chart',
                            type: 'chart',
                            sub_type: 'line',
                            chart_data,
                        },
                    ],
                    styles: with_benchmark ? pptBenchmarkStyles : styles,
                },
                {
                    identify_shape_name: 'checkme',
                    identify_shape_value: 'practice_score_by_demographic_level1',
                    is_copy: false,
                    is_delete: false,
                    data: [
                        ...commonEle,
                        {
                            name: 'EKG2_Chart',
                            type: 'chart',
                            sub_type: 'line',
                            chart_data: chart_data2,
                        },
                    ],
                    styles: with_benchmark ? pptBenchmarkStyles : styles,
                },
                filterSlide,
            ],
        };
        getPptData(output, pptAccessToken, 'Inclusion_Demographics_Charts');
    };

    useEffect(() => {
        if (graphDataFetched) {
            createGraphPpt(graphData);
            updateGraphGapData(false);
        }
        // eslint-disable-next-line
    }, [graphDataFetched]);

    const getDataForPptGraph = () => {
        const sortedQbyq = sortChildren(qbyq, 'size', -1);
        const totalLength = sortedQbyq.length;
        if (totalLength <= graphChunk) {
            createGraphPpt(gapData);
        } else {
            const group_on = sortedQbyq.slice(0, graphChunk - 1).map(({ keyName }) => keyName);
            callGapData(dropDownValue, group_on.join(','));
        }
    };

    const checkHighLow = (index, j, slice_start, slice_end = '', i = 0) => {
        const largest = slice_end ? largestScores.slice(slice_start, slice_end) : largestScores.slice(slice_start);
        const lowest = slice_end ? lowestScores.slice(slice_start, slice_end) : lowestScores.slice(slice_start);
        const largestArr = overallView ? largest : [...largestScores.slice(0, 2), ...largest];
        const lowestArr = overallView ? lowest : [...lowestScores.slice(0, 2), ...lowest];
        const levelOffset = index > 1 ? 2 : slice_start;
        const lv = overallView ? 0 : levelOffset;
        const key = `${index + slice_start - lv}_${j + i}`;
        if (!largestArr[index]) return '';
        const largestObj = largestArr[index].filter(item => item[key])[0] || '';
        const lowestObj = lowestArr[index].filter(item => item[key])[0] || '';
        if (!largestObj && !lowestObj) return '';
        if (!lowestObj) {
            return largestObj[key];
        }
        if (!largestObj) {
            return lowestObj[key];
        }
        return '';
    };

    const getDataForPpt = () => {
        const { qbyq: mainQbyq = {} } = inclusionqbyq || {};
        const { level_scores: mainLevel = {} } = mainQbyq;
        const benchmarkDetail = {
            name: 'benchmark_detail',
            value: `${benchmarkName} (n surveys = ${SurveyCount}, no. of respondents = ${RespondentCount})`,
        };
        const pptParamsForLegend = getPptParamsForLegend(benchmarks);
        const { overall = {} } = mainLevel;
        const { score: ovScore, quartile: ovQuartile = -1 } = overall;

        const {
            finalArr = [],
            gapArr: allGapArr = [],
            scoreForGap = [],
            overallArr = [],
            allGapArr: overallGapArr = [],
        } = scoreData;
        const outComeSlides = [];
        const enterpriseSlides = [];
        const individualSlides = [];
        const commonEle = [
            {
                name: 'Source',
                value: `${currentOhid} (${NUMBER_TEXT} = ${mainSize})`,
            },
            {
                name: 'checkme',
                is_delete: true,
            },
            ...pptParamsForLegend,
            benchmarkDetail,
        ];
        const { levels_gap = {} } = gap;
        const { levels_gap: allGapLevels = {} } = allIaGapData || {};
        const { overall: overallGap = {} } = levels_gap;
        const { overall: allGapOverallObj = '' } = allGapLevels || {};
        const allGapOverall = showAvgGap ? allGapOverallObj.avg_gap : allGapOverallObj.gap;
        const allGapOverallStyle = getGapColor(allGapOverall, pptStyle, true) + 12;
        commonEle.push({
            name: 'demo_gap',
            value: allGapOverall,
            style: allGapOverallStyle,
        });
        if (showAvgGap) {
            commonEle.push({
                name: 'largest_gap',
                is_delete: true,
            });
        } else {
            commonEle.push({
                name: 'average_gap',
                is_delete: true,
            });
        }

        const selectedQbyqDemos = child.length ? child : Object.keys(qbyq);
        const heatmapKeys = sortGroup.length
            ? sortGroup.filter(item => selectedQbyqDemos.includes(item))
            : selectedQbyqDemos;
        const lvRow = overallView ? 0 : -2;
        for (let i = 0; i < heatmapKeys.length; i += heatMapChunk) {
            const chunkQbyq = heatmapKeys.slice(i, i + heatMapChunk);
            const dataChunk = finalArr.slice(i, i + heatMapChunk);
            const outcomeTable = [];
            const enterpriseTable = [];
            const individualTable = [];

            chunkQbyq.forEach((key, kIndex) => {
                const { display_name = '', size = '' } = qbyq[key];
                const percent = `${Math.round((size * 100) / mainSize)}%`;
                const { score = '', quartile: quart0 = -1 } = overallArr.length ? overallArr.slice(3)[kIndex] : {};
                const overallIncVal = scoreToDisplay(score, [], isPulse);
                const overallIncStyle = with_benchmark
                    ? getBenchmarkColor(quart0, overallIncVal)
                    : getColor(overallIncVal, pptStyle, false, true);
                const overallGapVal = gapToDisplay(overallGap.gap, overallIncVal, isPulse);
                const overallGapStyle = getGapColor(overallGapVal, pptStyle, true) + 12;
                outcomeTable.push(getRowDataText(display_name, 7, 0, kIndex * 2 + 8));
                enterpriseTable.push(getRowDataText(display_name, 7, 0, kIndex * 2 + 7));
                individualTable.push(getRowDataText(display_name, 7, 0, kIndex * 2 + 7));
                const highLowColor = !child.length ? checkHighLowOverall(overallIncVal, overallArr) : '';
                outcomeTable.push(
                    getRowData(overallIncVal, overallIncStyle, 4, kIndex * 2 + 8, highLowColor),
                    getRowData(size, 7, 1, kIndex * 2 + 8),
                    getRowData(mainSize, 7, 1, 2),
                    getRowDataText(percent, 7, 2, kIndex * 2 + 8),
                    getRowData(overallGapVal, overallGapStyle, 4, 6),
                    getRowData(allGapOverall, allGapOverallStyle, 4, 4)
                );
                enterpriseTable.push(
                    getRowData(overallIncVal, overallIncStyle, 4, kIndex * 2 + 7, highLowColor),
                    getRowData(size, 7, 1, kIndex * 2 + 7),
                    getRowData(mainSize, 7, 1, 1),
                    getRowDataText(percent, 7, 2, kIndex * 2 + 7),
                    getRowData(overallGapVal, overallGapStyle, 4, 5),
                    getRowData(allGapOverall, allGapOverallStyle, 4, 3)
                );
                individualTable.push(
                    getRowData(overallIncVal, overallIncStyle, 4, kIndex * 2 + 7, highLowColor),
                    getRowData(size, 7, 1, kIndex * 2 + 7),
                    getRowData(mainSize, 7, 1, 1),
                    getRowDataText(percent, 7, 2, kIndex * 2 + 7),
                    getRowData(overallGapVal, overallGapStyle, 4, 5),
                    getRowData(allGapOverall, allGapOverallStyle, 4, 3)
                );
            });
            for (let k = chunkQbyq.length; k <= heatMapChunk; k += 1) {
                outcomeTable.push(
                    getRowDataText(' ', 7, 0, k * 2 + 8),
                    getRowDataText(' ', 6, 4, k * 2 + 8),
                    getRowDataText(' ', 7, 1, k * 2 + 8),
                    getRowDataText(' ', 7, 2, k * 2 + 8)
                );
                enterpriseTable.push(
                    getRowDataText(' ', 7, 0, k * 2 + 7),
                    getRowDataText(' ', 6, 4, k * 2 + 7),
                    getRowDataText(' ', 7, 1, k * 2 + 7),
                    getRowDataText(' ', 7, 2, k * 2 + 7)
                );
                individualTable.push(
                    getRowDataText(' ', 7, 0, k * 2 + 7),
                    getRowDataText(' ', 6, 4, k * 2 + 7),
                    getRowDataText(' ', 7, 1, k * 2 + 7),
                    getRowDataText(' ', 7, 2, k * 2 + 7)
                );
            }

            for (let j = 0; j < heatMapChunk; j += 1) {
                const scoreArray = dataChunk[j] || Array(27).fill('a');
                const mainArray = overallView ? scoreArray.slice(4, 10) : scoreArray.slice(2, 10);
                const practiceArr1 = overallView
                    ? scoreArray.slice(10, 17)
                    : [...scoreArray.slice(2, 4), ...scoreArray.slice(10, 17)];
                const practiceArr2 = overallView
                    ? scoreArray.slice(17)
                    : [...scoreArray.slice(2, 4), ...scoreArray.slice(17)];
                let row_index = 1;
                const column_index = j * 2 + 8;
                const ovIncDisVal = scoreToDisplay(ovScore, [], isPulse);
                const ovIncIndex = with_benchmark
                    ? getBenchmarkColor(ovQuartile, ovIncDisVal)
                    : getColor(ovIncDisVal, pptStyle, false, true);
                mainArray.forEach((innerScore, index) => {
                    const { displayValue = '', quartile = -1 } = innerScore;
                    const scoreDisVal = scoreToDisplay(displayValue, [], isPulse);
                    const gapArr = overallView ? allGapArr.slice(4, 10) : allGapArr.slice(2, 10);
                    const gapDisVal = gapToDisplay(gapArr[index], scoreDisVal, isPulse);
                    const overallScoreArr = overallView ? scoreForGap.slice(2, 8) : scoreForGap.slice(0, 8);
                    const { score = '', quartile: quart = -1 } = overallScoreArr[index];
                    const overallDisVal = scoreToDisplay(score, [], isPulse);
                    const overallIndex = with_benchmark
                        ? getBenchmarkColor(quart, overallDisVal)
                        : getColor(overallDisVal, pptStyle, false, true);
                    const overallGapArrOutcome = overallView
                        ? overallGapArr.slice(0, 6)
                        : [...overallGapArr.slice(23, 25), ...overallGapArr.slice(0, 6)];
                    const ovGapVal = overallGapArrOutcome[index].gap;
                    const overallGapDisVal = gapToDisplay(ovGapVal, overallDisVal, isPulse);
                    const styleIndex = with_benchmark
                        ? getBenchmarkColor(quartile, scoreDisVal)
                        : getColor(scoreDisVal, pptStyle, false, true);
                    const highLowValue = overallView
                        ? checkHighLow(index, j, 2, 8, i)
                        : checkHighLow(index, j, 2, 8, i);
                    outcomeTable.push(
                        getRowData(scoreDisVal, styleIndex, row_index + 5 + lvRow, column_index, highLowValue)
                    );
                    if (column_index === 8) {
                        if (row_index === 1) {
                            if (overallView) {
                                outcomeTable.push(getRowData(ovIncDisVal, ovIncIndex, row_index + 3, column_index - 6));
                            }
                            commonEle.push({ name: 'inclusion_score', value: ovIncDisVal, style: ovIncIndex });
                        }
                        outcomeTable.push(
                            getRowData(overallDisVal, overallIndex, row_index + 5 + lvRow, column_index - 6)
                        );
                        outcomeTable.push(
                            getRowData(
                                gapDisVal,
                                getGapColor(gapDisVal, pptStyle, true) + 12,
                                row_index + 5 + lvRow,
                                column_index - 2
                            )
                        );
                        outcomeTable.push(
                            getRowData(
                                overallGapDisVal,
                                getGapColor(overallGapDisVal, pptStyle, true) + 12,
                                row_index + 5 + lvRow,
                                column_index - 4
                            )
                        );
                    }

                    row_index += 2;
                });

                practiceArr2.forEach((innerScore, ent_row_index) => {
                    const { displayValue = '', quartile = -1 } = innerScore;
                    const scoreDisVal = scoreToDisplay(displayValue, [], isPulse);
                    const gapArr = overallView
                        ? allGapArr.slice(17)
                        : [...allGapArr.slice(2, 4), ...allGapArr.slice(17)];
                    const overallScoreArr = overallView
                        ? scoreForGap.slice(15)
                        : [...scoreForGap.slice(0, 2), ...scoreForGap.slice(15)];
                    const gapDisVal = gapToDisplay(gapArr[ent_row_index], scoreDisVal, isPulse);
                    const { score = '', quartile: quart = -1 } = overallScoreArr[ent_row_index];
                    const overallDisVal = scoreToDisplay(score, [], isPulse);
                    const overallIndex = with_benchmark
                        ? getBenchmarkColor(quart, overallDisVal)
                        : getColor(overallDisVal, pptStyle, false, true);
                    const overallGapArrPractice = overallView
                        ? overallGapArr.slice(13)
                        : [...overallGapArr.slice(23, 25), ...overallGapArr.slice(13)];
                    const ovGapVal = overallGapArrPractice[ent_row_index].gap
                        ? overallGapArrPractice[ent_row_index].gap
                        : overallGapArrPractice[ent_row_index];
                    const overallGapDisVal = gapToDisplay(ovGapVal, overallDisVal, isPulse);
                    const styleIndex = with_benchmark
                        ? getBenchmarkColor(quartile, scoreDisVal)
                        : getColor(scoreDisVal, pptStyle, false, true);
                    const highLowValue = overallView
                        ? checkHighLow(ent_row_index, j, 15, '', i)
                        : checkHighLow(ent_row_index, j, 15, '', i);
                    enterpriseTable.push(
                        getRowData(
                            scoreDisVal,
                            styleIndex,
                            ent_row_index * 2 + 6 + lvRow,
                            column_index - 1,
                            highLowValue
                        )
                    );
                    if (column_index === 8) {
                        if (!ent_row_index && overallView) {
                            enterpriseTable.push(
                                getRowData(ovIncDisVal, ovIncIndex, ent_row_index * 2 + 4 + lvRow, column_index - 7)
                            );
                        }
                        enterpriseTable.push(
                            getRowData(overallDisVal, overallIndex, ent_row_index * 2 + 6 + lvRow, column_index - 7)
                        );
                        enterpriseTable.push(
                            getRowData(
                                gapDisVal,
                                getGapColor(gapDisVal, pptStyle, true) + 12,
                                ent_row_index * 2 + 6 + lvRow,
                                column_index - 3
                            )
                        );
                        enterpriseTable.push(
                            getRowData(
                                overallGapDisVal,
                                getGapColor(overallGapDisVal, pptStyle, true) + 12,
                                ent_row_index * 2 + 6 + lvRow,
                                column_index - 5
                            )
                        );
                    }
                });
                practiceArr1.forEach((innerScore, ind_row_index) => {
                    const { displayValue = '', quartile = -1 } = innerScore;
                    const scoreDisVal = scoreToDisplay(displayValue, [], isPulse);
                    const gapArr = overallView
                        ? allGapArr.slice(10, 17)
                        : [...allGapArr.slice(2, 4), ...allGapArr.slice(10, 17)];
                    const gapDisVal = gapToDisplay(gapArr[ind_row_index], scoreDisVal, isPulse);
                    const overallScoreArr = overallView
                        ? scoreForGap.slice(8, 15)
                        : [...scoreForGap.slice(0, 2), ...scoreForGap.slice(8, 15)];
                    const { score = '', quartile: quart = -1 } = overallScoreArr[ind_row_index];
                    const overallDisVal = scoreToDisplay(score, [], isPulse);
                    const overallIndex = with_benchmark
                        ? getBenchmarkColor(quart, overallDisVal)
                        : getColor(overallDisVal, pptStyle, false, true);
                    const overallGapArrPractice = overallView
                        ? overallGapArr.slice(6, 13)
                        : [...overallGapArr.slice(23, 25), ...overallGapArr.slice(6, 13)];
                    const ovGapVal = overallGapArrPractice[ind_row_index].gap
                        ? overallGapArrPractice[ind_row_index].gap
                        : overallGapArrPractice[ind_row_index];
                    const overallGapDisVal = gapToDisplay(ovGapVal, overallDisVal, isPulse);
                    const styleIndex = with_benchmark
                        ? getBenchmarkColor(quartile)
                        : getColor(scoreDisVal, pptStyle, false, true);
                    const highLowValue = overallView
                        ? checkHighLow(ind_row_index, j, 8, 15, i)
                        : checkHighLow(ind_row_index, j, 8, 15, i);
                    individualTable.push(
                        getRowData(
                            scoreDisVal,
                            styleIndex,
                            ind_row_index * 2 + 6 + lvRow,
                            column_index - 1,
                            highLowValue
                        )
                    );
                    if (column_index === 8) {
                        if (!ind_row_index && overallView) {
                            individualTable.push(
                                getRowData(ovIncDisVal, ovIncIndex, ind_row_index * 2 + 4 + lvRow, column_index - 7)
                            );
                        }
                        individualTable.push(
                            getRowData(overallDisVal, overallIndex, ind_row_index * 2 + 6 + lvRow, column_index - 7)
                        );
                        individualTable.push(
                            getRowData(
                                gapDisVal,
                                getGapColor(gapDisVal, pptStyle, true) + 12,
                                ind_row_index * 2 + 6 + lvRow,
                                column_index - 3
                            )
                        );
                        individualTable.push(
                            getRowData(
                                overallGapDisVal,
                                getGapColor(overallGapDisVal, pptStyle, true) + 12,
                                ind_row_index * 2 + 6 + lvRow,
                                column_index - 5
                            )
                        );
                    }
                });
            }

            const outcomeSlideArr = [
                ...commonEle,
                {
                    name: 'Title_demo_parent_name',
                    value: `${getDemographicName(demographics, dropDownValue)} ${getDemographicPageCount(
                        demographics,
                        dropDownValue,
                        i,
                        heatMapChunk,
                        heatmapKeys.length
                    )}`,
                },
                {
                    name: 'heatmap_outcome_scores_DataTable',
                    table_data: outcomeTable,
                },
            ];

            const enterpriseSlideArr = [
                ...commonEle,
                {
                    name: 'Title_demo_parent_name',
                    value: `${getDemographicName(demographics, dropDownValue)} ${getDemographicPageCount(
                        demographics,
                        dropDownValue,
                        i,
                        heatMapChunk,
                        heatmapKeys.length
                    )}`,
                },
                {
                    name: 'heatmap_practice_enterprise_scores_DataTable',
                    table_data: enterpriseTable,
                },
            ];

            const individualSlideArr = [
                ...commonEle,
                {
                    name: 'Title_demo_parent_name',
                    value: `${getDemographicName(demographics, dropDownValue)} ${getDemographicPageCount(
                        demographics,
                        dropDownValue,
                        i,
                        heatMapChunk,
                        heatmapKeys.length
                    )}`,
                },
                {
                    name: 'heatmap_practice_personal_scores_DataTable',
                    table_data: individualTable,
                },
            ];

            outComeSlides.push({
                identify_shape_name: 'checkme',
                identify_shape_value: overallView ? 'heatmap_outcome_scores' : 'heatmap_outcome_scores_old',
                is_copy: true,
                is_delete: true,
                styles: with_benchmark ? pptBenchmarkStyles : styles,
                data: outcomeSlideArr,
            });

            enterpriseSlides.push({
                identify_shape_name: 'checkme',
                identify_shape_value: overallView
                    ? 'heatmap_practice_enterprise_scores'
                    : 'heatmap_practice_enterprise_scores_old',
                is_copy: true,
                is_delete: true,
                styles: with_benchmark ? pptBenchmarkStyles : styles,
                data: enterpriseSlideArr,
            });

            individualSlides.push({
                identify_shape_name: 'checkme',
                identify_shape_value: overallView
                    ? 'heatmap_practice_personal_scores'
                    : 'heatmap_practice_personal_scores_old',
                is_copy: true,
                is_delete: true,
                styles: with_benchmark ? pptBenchmarkStyles : styles,
                data: individualSlideArr,
            });
        }

        const filterText = getFiltersText(filters, demographics);
        const filterSlide = getFilterSlideObj(filterText, mainSize);

        const output = {
            ...pptParams,
            slides: [...outComeSlides, ...enterpriseSlides, ...individualSlides, filterSlide],
        };
        getPptData(output, pptAccessToken, 'Inclusion_Demographics');
    };

    const renderFooter = () => {
        if (resurveyGapDataFetched && selectedTab === 3) {
            return (
                <div className="filterInfoSection newVersion">
                    <div>
                        <b>{FILTERS_APPLIED + ' (current survey) : '}</b>
                        {getFiltersAppliedText(filters, demographics)
                            ? getFiltersAppliedText(filters, demographics)
                            : NONE}{' '}
                        | n = {mainSize}
                    </div>
                    <div>
                        <b>{FILTERS_APPLIED + ' (comparison survey) : '}</b>
                        {getFiltersAppliedText(resurveyFilters, demographics)
                            ? getFiltersAppliedText(resurveyFilters, resurveyDemographic)
                            : NONE}{' '}
                        | n = {demoPrevNsize}
                    </div>
                    <div>
                        <b>{BENCHMARKS_APPLIED + ' : '}</b> {`${benchmarkName} | ${benchmarkText}`}
                    </div>
                </div>
            );
        }
        if (selectedTab === 1 && !allGapInclusionDataFetched) {
            return null;
        }
        return (
            <div className="filterInfoSection">
                <div>
                    <b>{FILTERS_APPLIED + ' : '}</b>
                    {getFiltersAppliedText(filters, demographics)
                        ? getFiltersAppliedText(filters, demographics)
                        : NONE}{' '}
                    | n = {mainSize}
                </div>
                <div>
                    <b>{BENCHMARKS_APPLIED + ' : '}</b> {`${benchmarkName} | ${benchmarkText}`}
                </div>

                {selectedTab === 1 && (
                    <div className="disclaimer">
                        <div>{DISCLAIMER} : </div>
                        <ul className="clearfix">
                            {SCORECARD_DEMOGRAPHIC_DISCLAIMER.map((text, i) => (
                                <div key={i} style={{ display: 'flex' }} className="demoDisclaimerNote">
                                    <div>{i + 1}.</div>
                                    <div>{text}</div>
                                </div>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="demographicInclusion clearfix">
            <Helmet>
                <title>
                    {INCLUSION} {DEMO_INCLUSION_TABS[selectedTab]}
                </title>
            </Helmet>
            <div className="underline">
                <div className="optionWrapper">
                    <div>
                        <h2>{DEMOGRAPHIC}s</h2>
                    </div>
                    <div>
                        <ul className="rightNav">
                            {resurveyInactive ? (
                                <Tooltip title={RESURVEY_INACTIVE_TEXT}>
                                    <li className="resurvey inActive">
                                        <img src={CompareView} alt="" />
                                        {RESURVEY_TEXT}
                                    </li>
                                </Tooltip>
                            ) : (
                                <li
                                    className={c({ selected: resurveyGapDataFetched }, 'resurvey')}
                                    onClick={() => setResurvey(!showResurvey)}
                                >
                                    <img src={CompareView} alt="" />
                                    {resurveyGapDataFetched && (
                                        <div
                                            onClick={e => {
                                                e.stopPropagation();
                                                setResurvey(true);
                                                setModalType(2);
                                            }}
                                        >
                                            <img src={Close} alt="" />
                                        </div>
                                    )}
                                    {RESURVEY_TEXT}
                                </li>
                            )}
                            {resurveyGapDataFetched && checkLength && selectedTab === 3 ? (
                                <>
                                    <li className="respondents">
                                        {`Current survey (n) = ${mainSize} (${currentOhid} - ${year})`}
                                    </li>
                                    <li className="respondents">
                                        {`Comparison survey (n) = ${demoPrevNsize} (${previousOhid} - ${prevYear})`}
                                    </li>
                                </>
                            ) : null}
                            {checkLength && selectedTab !== 3 ? (
                                <li className="respondents">{`Total Respondents (n) = ${mainSize}`}</li>
                            ) : null}
                            {resurveyGapDataFetched && selectedTab === 3 && (
                                <Tooltip title="click to download Demographic Mapping sheet">
                                    <li
                                        className="separator"
                                        onClick={() => {
                                            getMappingExcel({ ohid: currentOhid, previous_ohid: previousOhid });
                                        }}
                                    >
                                        <img src={ExcelIcon} alt="" className="excelDownload" />
                                        <span />
                                    </li>
                                </Tooltip>
                            )}
                            <Tooltip arrow title={FILTER_HOVER_TXT}>
                                <li
                                    className="separator noBorder"
                                    data-testid="filterButton"
                                    onClick={() => toggleFilterView(true)}
                                >
                                    <img src={activeFilter ? ActiveFilter : FilterSvg} alt="" /> <span>{FILTERS}</span>
                                </li>
                            </Tooltip>
                        </ul>
                    </div>
                </div>
            </div>
            <ul className="leftNav">
                {DEMO_TABS.map((tabVal, tabIndex) => {
                    if (isPulse && tabIndex === 2) {
                        return null;
                    }
                    return (
                        <li
                            key={tabVal}
                            data-testid={tabVal}
                            className={c({ activeLink: tabIndex === selectedTab })}
                            onClick={() => setSelectedTab(tabIndex)}
                        >
                            {tabVal}
                        </li>
                    );
                })}
            </ul>
            {selectedTab !== 2 && (
                <div className={c({ gapHeatmap: selectedTab === 1 || selectedTab === 4 }, 'buttonWrapper')}>
                    <div className="buttonContent">
                        {['Outcomes', 'Practices'].map((tabVal, tabIndex) => {
                            if (
                                (selectedTab === 1 && !allGapInclusionDataFetched) ||
                                (selectedTab === 4 && !resAllGapInclusionDataFetched)
                            )
                                return null;
                            return (
                                <button
                                    key={tabVal}
                                    type="button"
                                    className={c('separateButton', { isActive: tabIndex === selectedSubTab })}
                                    onClick={() => setSelectedSubTab(tabIndex)}
                                >
                                    {tabVal}
                                </button>
                            );
                        })}
                    </div>
                </div>
            )}
            {selectedDownload === 0 || selectedDownload === 1 ? (
                <Excel
                    benchmarks={benchmarks}
                    apiParams={iaApiParams}
                    demographicCurrentTab={selectedTab}
                    demographics={demographics}
                    items={selectedDownload === 0 ? scoreData.finalArr : gapScoreData.finalArr}
                    header={selectedDownload === 0 ? scoreData.headerArr : gapScoreData.headerArr}
                    defaultSettings={defaultSettings}
                    selectedDemographic={selectedDownload === 0 ? dropDownValue : gapScoreData.demoArr}
                    isGapHeatmap={selectedTab === 1}
                    inclusionStyle={scoreColor}
                    setSelectedDownload={setSelectedDownload}
                    overallArr={selectedDownload === 0 ? scoreData.overallArr : gapScoreData.overallGapArr}
                    benchmarkName={benchmarkName}
                    threshold={inclusion_threshold}
                    respondents={mainSize}
                />
            ) : null}
            <div className="tabsWrapper">
                {selectedTab === 0 && (
                    <Fragment>
                        {gapDataFetched ? (
                            <DemographicInclusionData
                                scoreData={scoreData}
                                scoreColor={scoreColor}
                                withBenchmark={with_benchmark}
                                showTag={showTag}
                                resDemo={resDemo}
                                isPulse={isPulse}
                                selectedSubTab={selectedSubTab}
                                allGapDataFetched={allGapDataFetched}
                                dropDownValue={dropDownValue}
                                setDropDownValue={setDropDownValue}
                                callGapData={callGapData}
                                currentOhid={currentOhid}
                                demographics={demographics}
                                excludeOption={excludeOption}
                                qbyq={qbyq}
                                child={child}
                                setChild={setChild}
                                sort={sort}
                                setSort={setSort}
                                sortGroup={sortGroup}
                                dwnldDrop={dwnldDrop}
                                setDwnldDrop={setDwnldDrop}
                                setSelectedDownload={setSelectedDownload}
                                setShowTag={setShowTag}
                                setAvgGap={setAvgGap}
                                showAvgGap={showAvgGap}
                                getDataForPpt={getDataForPpt}
                                overallView={overallView}
                                setOverallView={setOverallView}
                                error={error}
                                defaultSettings={defaultSettings}
                            />
                        ) : null}
                        {!reportApiCount && !checkMainLength ? <ErrorToast message={FILTES_CHECK_NOTE} /> : null}
                    </Fragment>
                )}
                {selectedTab === 1 && (
                    <Fragment>
                        {allGapInclusionDataFetched ? (
                            <Fragment>
                                {!error && page && checkMainLength && (
                                    <GapInclusionData
                                        gapData={gapScoreData}
                                        scoreData={scoreData}
                                        scoreColor={scoreColor}
                                        withBenchmark={with_benchmark}
                                        demographics={demographics}
                                        gapDataProps={gapDataProps}
                                        getAllGapInclusionData={getAllGapInclusionData}
                                        allGapInclusionData={allGapInclusionData}
                                        reportApiParams={reportApiParams}
                                        page={page}
                                        updatePage={updatePage}
                                        pages={pages}
                                        setpageDataFetched={setpageDataFetched}
                                        isPulse={isPulse}
                                        selectedSubTab={selectedSubTab}
                                        setSelectedDownload={setSelectedDownload}
                                        sortParent={sortParent}
                                        setAvgGap={setAvgGap}
                                        showAvgGap={showAvgGap}
                                        sort={sort}
                                        setSort={setSort}
                                        parentG={parentG}
                                        setParent={setParent}
                                        inclusionqbyq={inclusionqbyq}
                                        benchmarkName={benchmarkName}
                                        SurveyCount={SurveyCount}
                                        RespondentCount={RespondentCount}
                                        allIaGapData={allIaGapData}
                                        getPptData={getPptData}
                                        iaApiParams={iaApiParams}
                                        exGap={exGap}
                                        defaultSettings={defaultSettings}
                                        setGapScoreData={setGapScoreData}
                                        gapDataFetched={gapDataFetched}
                                        overallView={overallView}
                                        setOverallView={setOverallView}
                                    />
                                )}
                                {renderFooter()}
                            </Fragment>
                        ) : (
                            <Loader
                                color="#000000"
                                cssOverride={{
                                    margin: '10vh auto',
                                }}
                            />
                        )}
                    </Fragment>
                )}
                {selectedTab === 2 && (
                    <Fragment>
                        {error ? <ErrorToast message={error} /> : null}
                        {gapDataFetched && !error ? (
                            <div className="content">
                                <div className="upperHeader heatmap">
                                    <div className="demographicDropdown">
                                        <Select
                                            data-testid="demographicOptions"
                                            IconComponent={KeyboardArrowDownSharpIcon}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={dropDownValue}
                                            displayEmpty
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                },
                                                getContentAnchorEl: null,
                                            }}
                                            onChange={({ target: { value: code } }) => {
                                                if (code) {
                                                    setDropDownValue(code);
                                                    callGapData(code);
                                                }
                                            }}
                                        >
                                            <MenuItem classes={{ root: 'demographicOptions' }} value="">
                                                <em>{SELECT_DEMO_GROUP}</em>
                                            </MenuItem>
                                            {demographics
                                                .filter(({ code }) => !excludeOption.includes(code))
                                                .map(({ code = '', label = '' }) => {
                                                    return (
                                                        <MenuItem
                                                            key={code}
                                                            classes={{ root: 'demographicOptions' }}
                                                            value={code}
                                                        >
                                                            {label}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </div>
                                    <div>
                                        <ul>
                                            {!error && checkMainLength && (
                                                <li
                                                    data-testid="getPptBtn2"
                                                    className="separator noBorder"
                                                    onClick={getDataForPptGraph}
                                                >
                                                    <div>
                                                        <img src={DownloadIcon} alt="" />
                                                    </div>
                                                    <span>{DOWNLOAD}</span>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                                {checkMainLength && (
                                    <DemographicCharts
                                        categories={categories}
                                        series={series}
                                        withBenchmark={with_benchmark}
                                    />
                                )}
                            </div>
                        ) : null}
                    </Fragment>
                )}
                {selectedTab === 3 && resurveyGapDataFetched && (
                    <Fragment>
                        {error ? <ErrorToast message={error} /> : null}
                        {!!resurveyGapData && !error ? (
                            <ResurveyDemographicTable
                                scoreData={scoreData}
                                scoreColor={scoreColor}
                                withBenchmark={with_benchmark}
                                demographics={demographics}
                                dropDownValue={dropDownValue}
                                showTag={showTag}
                                resDemo={resDemo}
                                isPulse={isPulse}
                                prevYear={prevYear}
                                year={year}
                                selectedSubTab={selectedSubTab}
                                resurveyDropdown={resurveyDropdown}
                                resurveyDropdownGroup={resurveyDropdownGroup}
                                setResurveyDropDownValue={setResurveyDropDownValue}
                                callResurveyGapData={callResurveyGapData}
                                excludeOption={excludeOption}
                                setAvgGap={setAvgGap}
                                showAvgGap={showAvgGap}
                                getDataForPpt={getDataForPpt}
                                resurveyGapDataFetched={resurveyGapDataFetched}
                                resurveyAllGapDataFetched={resurveyAllGapDataFetched}
                                resurveyGapData={resurveyGapData}
                                resurveyAllGapData={resurveyAllGapData}
                                previousOhid={previousOhid}
                                currentOhid={currentOhid}
                                setResDemo={setResDemo}
                                iaApiParams={iaApiParams}
                                defaultSettings={defaultSettings}
                                checkHighLow={checkHighLow}
                                getPptData={getPptData}
                                benchmarkName={benchmarkName}
                                SurveyCount={SurveyCount}
                                inclusionqbyq={inclusionqbyq}
                                allIaGapData={allIaGapData}
                                resurveyDemographic={resurveyDemographic}
                                exGap={exGap}
                                RespondentCount={RespondentCount}
                                demoNSize={demoNSize}
                                overallView={overallView}
                                setOverallView={setOverallView}
                                allGapDataFetched={allGapDataFetched}
                                isDiffSurveyV={isDiffSurveyV}
                            />
                        ) : null}
                    </Fragment>
                )}
                {selectedTab === 4 && (
                    <ResurveyGapTable
                        scoreData={scoreData}
                        scoreColor={scoreColor}
                        withBenchmark={with_benchmark}
                        demographics={demographics}
                        dropDownValue={dropDownValue}
                        showTag={showTag}
                        resDemo={resDemo}
                        isPulse={isPulse}
                        prevYear={prevYear}
                        year={year}
                        selectedSubTab={selectedSubTab}
                        getResAllGapInclusionData={getResAllGapInclusionData}
                        getAllGapInclusionData={getAllGapInclusionData}
                        allGapInclusionDataFetched={allGapInclusionDataFetched}
                        allGapInclusionData={allGapInclusionData}
                        allGapInclusionDataLoading={allGapInclusionDataLoading}
                        lang={lang}
                        currentOhid={currentOhid}
                        previousOhid={previousOhid}
                        benchmarkVersions={benchmarkVersions}
                        inclusion_threshold={inclusion_threshold}
                        demographicMappedData={demographicMappedData}
                        resAllGapInclusionDataFetched={resAllGapInclusionDataFetched}
                        resAllGapInclusionData={resAllGapInclusionData}
                        resAllGapInclusionDataLoading={resAllGapInclusionDataLoading}
                        incQbyq={incQbyq}
                        resIncQbyq={resHeatmapQbyq}
                        allGapData={allIaGapData}
                        excludeArr={excludeArr}
                        excludeOption={excludeOption}
                        setAvgGap={setAvgGap}
                        showAvgGap={showAvgGap}
                        filters={filters}
                        resurveyFilters={resurveyFilters}
                        overallView={overallView}
                        setOverallView={setOverallView}
                        defaultSettings={defaultSettings}
                        isDiffSurveyV={isDiffSurveyV}
                    />
                )}
                {resAllGapInclusionDataFetched && allGapInclusionDataFetched ? renderFooter() : null}
            </div>
            {gapDataFetched && !error && ![1, 4].includes(selectedTab) && (
                <div className="disclaimer">
                    <div>{DISCLAIMER} : </div>
                    <ul className="clearfix">
                        {isValueBased &&
                            isDiffSurveyV &&
                            selectedTab !== 0 &&
                            selectedTab !== 1 &&
                            selectedTab !== 2 &&
                            RESURVEY_DEMO_VALUE_BASED.map((text, i) => (
                                <div key={`res${i}`} className="demoDisclaimerNote">
                                    <div>{i + 1}.</div>
                                    <div>{text}</div>
                                </div>
                            ))}

                        {isQuartileMovement &&
                            isDiffSurveyV &&
                            selectedTab !== 0 &&
                            selectedTab !== 1 &&
                            selectedTab !== 2 &&
                            RESURVEY_DEMO_QUARTILE_BASED.map((text, i) => (
                                <div key={`res${i}`} className="demoDisclaimerNote">
                                    <div>{i + 1}.</div>
                                    <div>{text}</div>
                                </div>
                            ))}

                        {isValueBased &&
                            isDiffSurveyV2 &&
                            selectedTab !== 0 &&
                            selectedTab !== 1 &&
                            selectedTab !== 2 &&
                            RESURVEY_DEMO_VALUE_BASED_V2.map((text, i) => (
                                <div key={`res${i}`} className="demoDisclaimerNote">
                                    <div>{i + 1}.</div>
                                    <div>{text}</div>
                                </div>
                            ))}

                        {isQuartileMovement &&
                            isDiffSurveyV2 &&
                            selectedTab !== 0 &&
                            selectedTab !== 1 &&
                            selectedTab !== 2 &&
                            RESURVEY_DEMO_QUARTILE_BASED_V2.map((text, i) => (
                                <div key={`res${i}`} className="demoDisclaimerNote">
                                    <div>{i + 1}.</div>
                                    <div>{text}</div>
                                </div>
                            ))}

                        {(selectedTab === 0 || selectedTab === 1 || selectedTab === 2) &&
                            SCORECARD_DEMOGRAPHIC_DISCLAIMER.map((text, i) => (
                                <div key={i} style={{ display: 'flex' }} className="demoDisclaimerNote">
                                    <div>{i + 1}.</div>
                                    <div>{text}</div>
                                </div>
                            ))}
                        {selectedTab === 3 && selectedSubTab === 1 && resurveyNote && (
                            <div>
                                Note : To compare past scores with current scores, adjustments should be applied to past
                                practice scores for Connection opportunities (-3 points) and Resource Accessibility (+1
                                points)
                            </div>
                        )}
                    </ul>
                </div>
            )}

            {selectedTab === 4 && gapDataFetched && (
                <div className="disclaimer">
                    <div>{DISCLAIMER} : </div>
                    <ul className="clearfix">
                        {isValueBased &&
                            isDiffSurveyV &&
                            selectedTab !== 0 &&
                            selectedTab !== 1 &&
                            selectedTab !== 2 &&
                            RESURVEY_DEMO_VALUE_BASED.map((text, i) => (
                                <div key={`res${i}`} className="demoDisclaimerNote">
                                    <div>{i + 1}.</div>
                                    <div>{text}</div>
                                </div>
                            ))}

                        {isQuartileMovement &&
                            isDiffSurveyV &&
                            selectedTab !== 0 &&
                            selectedTab !== 1 &&
                            selectedTab !== 2 &&
                            RESURVEY_DEMO_QUARTILE_BASED.map((text, i) => (
                                <div key={`res${i}`} className="demoDisclaimerNote">
                                    <div>{i + 1}.</div>
                                    <div>{text}</div>
                                </div>
                            ))}

                        {isValueBased &&
                            isDiffSurveyV2 &&
                            selectedTab !== 0 &&
                            selectedTab !== 1 &&
                            selectedTab !== 2 &&
                            RESURVEY_DEMO_VALUE_BASED_V2.map((text, i) => (
                                <div key={`res${i}`} className="demoDisclaimerNote">
                                    <div>{i + 1}.</div>
                                    <div>{text}</div>
                                </div>
                            ))}

                        {isQuartileMovement &&
                            isDiffSurveyV2 &&
                            selectedTab !== 0 &&
                            selectedTab !== 1 &&
                            selectedTab !== 2 &&
                            RESURVEY_DEMO_QUARTILE_BASED_V2.map((text, i) => (
                                <div key={`res${i}`} className="demoDisclaimerNote">
                                    <div>{i + 1}.</div>
                                    <div>{text}</div>
                                </div>
                            ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

DemographicInclusion.defaultProps = {
    isPulse: false,
    benchmarkName: '',
    resurveyGapDataFetched: false,
    previousOhid: '',
    getResurveyGapData: () => {},
    demoNSize: {},
    resurveyDemographic: [],
    resurveyGapData: {},
    getMappingExcel: () => {},
    demographicMappedData: [],
    prevYear: '',
    allGapInclusionDataLoading: false,
    getResurveyAllGapData: () => {},
    resurveyAllGapData: {},
    resurveyAllGapDataFetched: false,
};

DemographicInclusion.propTypes = {
    reportApiCount: PropTypes.number.isRequired,
    inclusion_threshold: PropTypes.number.isRequired,
    graphDataFetched: PropTypes.bool.isRequired,
    gapDataFetched: PropTypes.bool.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    lang: PropTypes.number.isRequired,
    demographics: PropTypes.array.isRequired,
    currentOhid: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    dropDownValue: PropTypes.string.isRequired,
    gapData: PropTypes.object.isRequired,
    graphData: PropTypes.object.isRequired,
    inclusionqbyq: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    getPptData: PropTypes.func.isRequired,
    getGapData: PropTypes.func.isRequired,
    setDropDownValue: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    updateGraphGapData: PropTypes.func.isRequired,
    scoreColor: PropTypes.object.isRequired,
    iaApiParams: PropTypes.object.isRequired,
    getAllGapInclusionData: PropTypes.func.isRequired,
    allGapInclusionData: PropTypes.object.isRequired,
    allGapInclusionDataFetched: PropTypes.bool.isRequired,
    selectedTab: PropTypes.number.isRequired,
    setSelectedTab: PropTypes.func.isRequired,
    reportApiParams: PropTypes.object.isRequired,
    pagesLoaded: PropTypes.object.isRequired,
    setpageDataFetched: PropTypes.func.isRequired,
    resurveyGapData: PropTypes.object,
    resurveyGapDataFetched: PropTypes.bool,
    previousOhid: PropTypes.string,
    getResurveyGapData: PropTypes.func,
    isPulse: PropTypes.bool,
    resurveyDemographic: PropTypes.array,
    benchmarkName: PropTypes.string,
    getMappingExcel: PropTypes.func,
    demographicMappedData: PropTypes.array,
    benchmarkVersions: PropTypes.array.isRequired,
    bmVersionInUse: PropTypes.number.isRequired,
    prevYear: PropTypes.string,
    allGapInclusionDataLoading: PropTypes.bool,
    allIaGapData: PropTypes.object.isRequired,
    allGapDataFetched: PropTypes.bool.isRequired,
    showAvgGap: PropTypes.bool.isRequired,
    setAvgGap: PropTypes.func.isRequired,
    getDemographics: PropTypes.func.isRequired,
    getMappedDemographic: PropTypes.func.isRequired,
    showResurvey: PropTypes.bool.isRequired,
    setResurvey: PropTypes.func.isRequired,
    setModalType: PropTypes.func.isRequired,
    deleteScoreComparison: PropTypes.func.isRequired,
    resurveyDemoFetched: PropTypes.bool.isRequired,
    getQbyqNsize: PropTypes.func.isRequired,
    resurveyDataFetched: PropTypes.bool.isRequired,
    demoNSize: PropTypes.object,
    resurveyDropdownGroup: PropTypes.string.isRequired,
    setResurveyDropDownValue: PropTypes.func.isRequired,
    getResurveyAllGapData: PropTypes.func,
    resurveyAllGapData: PropTypes.object,
    resurveyAllGapDataFetched: PropTypes.bool,
    resurveyInactive: PropTypes.bool.isRequired,
    resAllGapInclusionDataFetched: PropTypes.bool.isRequired,
    resAllGapInclusionData: PropTypes.object.isRequired,
    resAllGapInclusionDataLoading: PropTypes.bool.isRequired,
    getResAllGapInclusionData: PropTypes.func.isRequired,
    isDiffSurveyV: PropTypes.bool.isRequired,
    isDiffSurveyV2: PropTypes.bool.isRequired,
};

export default DemographicInclusion;
