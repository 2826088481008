import React, { Fragment, useEffect, useState } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import { ListSubheader, MenuItem, Select, Tooltip } from '@material-ui/core';
import { Sort } from '@material-ui/icons';
import { cloneDeep } from 'lodash';
import { getStaticText } from '../../../utils/constants';
import {
    excludeFromGapText,
    gapToDisplay,
    getBenchmarkColor,
    getColor,
    // getDemographicPageCount,
    getExcludeGapString,
    getFiltersText,
    getGapColor,
    getIncBenchmarkStyles,
    getInclusionStyle,
    getPptParamsForLegend,
    getRowData,
    getRowDataText,
    scoreToDisplay,
    sortChildren,
} from '../../../utils/functions';
import AuthStore from '../../../common/AuthStore';
import { gapHeatMapChunk, MAX_LENGTH_SUBSTRING, SLICE_VALUE_GAP } from '../constants';
import ArrowRight from '../../Diagnose/assets/ArrowRight.svg';
import ArrowLeft from '../../Diagnose/assets/ArrowLeft.svg';
import Dropdown from '../../../components/DropdownWithCheckbox/Dropdown';
import DownloadIcon from '../../Diagnose/assets/Download.svg';

const GapInclusionData = ({
    scoreData,
    gapData,
    demographics,
    scoreColor,
    withBenchmark,
    page,
    updatePage,
    isPulse,
    selectedSubTab,
    setSelectedDownload,
    sortParent,
    allGapInclusionData,
    setAvgGap,
    showAvgGap,
    // getDataForPptAllGap,
    parentG,
    setParent,
    sort,
    setSort,
    inclusionqbyq,
    benchmarkName,
    SurveyCount,
    RespondentCount,
    allIaGapData,
    getPptData,
    iaApiParams,
    exGap,
    defaultSettings,
    setGapScoreData,
    gapDataFetched,
    overallView,
    setOverallView,
}) => {
    const staticText = getStaticText();
    const { SITE_TEXT } = staticText;
    const {
        SCORE_CLASSIFICATION_TEXT,
        GAP_CLASSIFICATION_TEXT,
        OVERALL_INCLUSION,
        AVG_GAP,
        LARGEST_GAP,
        OVERALL_SORT,
        OVERALL_SCORE,
        VIEW_BY,
        SORT,
        DOWNLOAD,
        NUMBER_TEXT,
        PERSONAL_EXP,
        ENT_PERCEPTION,
        practicesByOutcome,
        SHOW_OVERALL,
    } = SITE_TEXT;
    const { pptAccessToken = '' } = AuthStore;
    const { with_benchmark = true, benchmarks = '', filters = [] } = iaApiParams;
    const {
        report_name_or_client_name: rName = '',
        exclude_inclusion_gap: defaultExGap = '',
        quartileColors = [],
        // year = '',
    } = defaultSettings;
    const { headerArr = [], finalArr = [], demoArr = [], overallGapArr = [] } = gapData;
    const [isSticky, setSticky] = useState(false);
    const [rearrangedArr, setRearrangedArr] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [dwnldDrop, setDwnldDrop] = useState(false);
    const { score: scoreStyles = [], gap: gapStyle = [] } = scoreColor;
    const benchmarkStyles = getIncBenchmarkStyles(quartileColors);
    const styleToUse = !withBenchmark ? scoreStyles : benchmarkStyles;
    const { scoreForGap = [], allGapArr = [] } = scoreData || {};
    const { gap = {}, qbyq: incQbyq = {} } = inclusionqbyq || {};
    const { pptBenchmarkStyles, styles } = scoreColor;
    const pptStyle = with_benchmark ? pptBenchmarkStyles : styles;
    const showScoreGapData = scoreDataArr => {
        const showGapArr = [];
        const demogMapping = {};
        demographics.forEach(item => {
            demogMapping[item.code] = item.title;
        });
        const header = demoArr.map(item => (demogMapping[item] ? demogMapping[item] : ''));
        setHeaders([...header]);
        for (let i = 0; i < headerArr.length; i += 1) {
            const showGapDataArr = [];
            showGapDataArr.push(headerArr[i]);
            for (let j = 0; j < scoreDataArr.length; j += 1) {
                showGapDataArr.push(scoreDataArr[j][i]);
            }
            showGapArr.push(showGapDataArr);
        }
        setRearrangedArr(showGapArr);
    };

    const listenToScroll = () => {
        const headerId = document.getElementById('myHeader');
        const sticky = headerId.offsetTop;
        if (window.pageYOffset > sticky && window.pageYOffset > 220) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };

    useEffect(() => {
        const allGapIaData = Object.keys(allGapInclusionData);
        if (gapDataFetched && allGapIaData.length) {
            const obj = {
                finalArr: [],
                headerArr: [],
                scoreArr: [],
                demoArr: [''],
                overallGapArr: [],
            };
            const { level_scores: mainLevel = {} } = incQbyq;
            obj.overallGapArr.push({ ...mainLevel.overall, score: mainLevel.overall.display_score });
            const { levels_gap: allLevelGap = {} } = allIaGapData || {};
            const { overall: gapOverall = {} } = allLevelGap || {};
            obj.overallGapArr.push({ gap: showAvgGap ? gapOverall.avg_gap : gapOverall.gap });
            const selectedGapData = parentG.length ? parentG : allGapIaData;
            const selectedSortGapData = sortParent.length
                ? sortParent.filter(item => selectedGapData.includes(item))
                : selectedGapData;
            selectedSortGapData.forEach(key => {
                const { levels_gap = {}, records = {} } = allGapInclusionData[key];
                const allGapData = cloneDeep(records);
                const { records: qbyqRecords = {} } = incQbyq;
                const { enterprise = {}, individual = {}, overall: overallDemoGap = {} } = levels_gap;
                const { gap: indGap = '' } = individual;
                const { gap: entGap = '' } = enterprise;
                const newObj = [];
                const outcomesHead = [];
                const prtByPrtType = [];
                const headerArray = [`_${PERSONAL_EXP}`, `_${ENT_PERCEPTION}`];
                newObj.push(indGap);
                newObj.push(entGap);
                Object.keys(qbyqRecords).forEach(k => {
                    const { display_name, sort_order, level, children = {} } = qbyqRecords[k];
                    allGapData[k] = { display_name, sort_order, level, ...allGapData[k] };
                    const childObj = children;
                    Object.keys(children).forEach(childK => {
                        const { display_name: displayName, sort_order: sortOrder } = childObj[childK];
                        const { children: allGapDataChild = {} } = allGapData[k];
                        const childrenData = allGapDataChild[childK] || [];
                        allGapData[k].children[childK] = {
                            display_name: displayName,
                            sort_order: sortOrder,
                            ...childrenData,
                        };
                    });
                });

                const filterWithoutDash = sortChildren(
                    Object.keys(allGapData)
                        .filter(k => allGapData[k].level !== '-')
                        .reduce((o, k) => {
                            o[k] = allGapData[k];
                            return o;
                        }, {})
                );
                Object.keys(filterWithoutDash).forEach(val => {
                    const {
                        gap: oGap = '',
                        key: oKey = '',
                        display_name: oDisplayName = '',
                        level = '',
                    } = filterWithoutDash[val];
                    newObj.push(oGap);
                    headerArray.push(`${level}_${oDisplayName}`);
                    outcomesHead.push(oKey);
                });
                Object.keys(filterWithoutDash).forEach(val => {
                    const { children = {}, display_name = '' } = filterWithoutDash[val];
                    const sortChildrenObj = sortChildren(children);
                    Object.keys(sortChildrenObj).forEach(childVal => {
                        const { gap: childGap = '', key: cKey, display_name: pDisplayName = '' } = sortChildrenObj[
                            childVal
                        ];
                        newObj.push(childGap);
                        headerArray.push(`${display_name}_${pDisplayName}`);
                        prtByPrtType.push(cKey);
                    });
                });

                obj.headerArr = headerArray;
                obj.demoArr.push(key);
                obj.finalArr.push(newObj);
                obj.overallGapArr.push(overallDemoGap);
                obj.categories = [outcomesHead, prtByPrtType, practicesByOutcome];
            });
            setGapScoreData(obj);
        }
        // eslint-disable-next-line
    }, [allGapInclusionData, page, showAvgGap, parentG, sortParent]);

    useEffect(() => {
        showScoreGapData(finalArr);

        window.addEventListener('scroll', listenToScroll);
        return () => {
            window.removeEventListener('scroll', listenToScroll);
        };
        // eslint-disable-next-line
    }, [gapData, parentG, sort]);

    const scoresHeaders = arr => {
        const newArr = arr.slice((page - 1) * SLICE_VALUE_GAP + 1, page * SLICE_VALUE_GAP + 1);
        return (
            <Fragment>
                <li className="headerList headerLeft" data-testid="arrowup">
                    <div>{selectedSubTab === 0 ? 'Dimension' : 'Outcome'}</div>
                    <div>{selectedSubTab === 0 ? 'Outcome' : 'Practice'}</div>
                </li>
                {newArr.map((val, i) => {
                    const displayValue =
                        val.length > MAX_LENGTH_SUBSTRING ? `${val.substring(0, MAX_LENGTH_SUBSTRING) + '...'}` : val;
                    return (
                        <Tooltip key={`${val}${i}`} placement="top" arrow title={val} aria-label={val}>
                            <li className="headerList" data-testid="arrowup">
                                {displayValue}
                            </li>
                        </Tooltip>
                    );
                })}
            </Fragment>
        );
    };

    const scoresGapTable = (arr, gapArr, qbyqScore) => {
        if (!arr.length) return null;
        return arr.map((item, i) => {
            const { score = '', quartile = -1, inclusion_quartile = -1 } = qbyqScore[i];
            const scoreVal = scoreToDisplay(score, [], isPulse);
            const { bgcolor = '', text_color = '', border_color = '' } =
                scoreVal !== '-'
                    ? getInclusionStyle(inclusion_quartile, quartile, benchmarkStyles, scoreStyles)
                    : { bgcolor: 'white', text_color: 'black', border_color: 'black' };
            const gapValMax = gapArr[i].gap;
            const maxGapVal = gapToDisplay(gapValMax, score, isPulse);
            const maxGapStyles = getGapColor(maxGapVal, gapStyle);
            const {
                score: overallQbyqScore = '',
                quartile: overallQuart = -1,
                inclusion_quartile: overallIncQuart = -1,
            } = overallGapArr[0];
            const {
                bgcolor: ovBgColor = '',
                text_color: ovTxtColor = '',
                border_color: ovBorderColor = '',
            } = getInclusionStyle(overallIncQuart, overallQuart, benchmarkStyles, scoreStyles);
            const overallLargestGapStyle = getGapColor(overallGapArr[1].gap, gapStyle);

            return (
                <>
                    {i === 0 && overallView && (
                        <div className="sectionDiv" key={`${item}${i}_overall`}>
                            <ul className="tabLeft">
                                <li
                                    className={c('sectionContent labelScore', {
                                        outcomeScorePractice: i < 8 && i > 1,
                                        practiceScorePractice: i >= 8,
                                        levelScorePractice: i <= 1,
                                    })}
                                >
                                    {' '}
                                </li>
                                <li
                                    className={c('sectionContent1 labelScore', {
                                        outcomeScorePractice: i < 8 && i > 1,
                                        practiceScorePractice: i >= 8,
                                        levelScorePractice: i <= 1,
                                    })}
                                >
                                    {OVERALL_INCLUSION}
                                    <div
                                        className="gapScores"
                                        style={{
                                            background: ovBgColor,
                                            color: ovTxtColor,
                                            borderColor: ovBorderColor,
                                            borderRadius: '50%',
                                        }}
                                    >
                                        <div>{overallQbyqScore}</div>
                                    </div>
                                    <div
                                        className="allGap"
                                        style={{
                                            borderRadius: '20%',
                                            background: overallLargestGapStyle.bgcolor,
                                            color: overallLargestGapStyle.text_color,
                                        }}
                                    >
                                        <div>{overallGapArr[1].gap}</div>
                                    </div>
                                </li>

                                {overallGapArr
                                    .slice((page - 1) * SLICE_VALUE_GAP + 2, page * SLICE_VALUE_GAP + 2)
                                    .map((elem, index) => {
                                        const { gap: overallGapScore = '' } = elem;
                                        const overallGapStyles = getGapColor(overallGapScore, gapStyle);
                                        return (
                                            <li
                                                className={c({ smallText: overallGapScore === '-' }, 'contentList')}
                                                style={{
                                                    background: overallGapStyles.bgcolor,
                                                    color: overallGapStyles.text_color,
                                                }}
                                                key={`${elem}${index}_0`}
                                            >
                                                {overallGapScore}
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    )}
                    <div className="sectionDiv" key={`${item}${i}`}>
                        <ul className="tabLeft">
                            <li
                                className={c('sectionContent labelScore', {
                                    outcomeScorePractice: i < 8 && i > 1,
                                    practiceScorePractice: i >= 8,
                                    levelScorePractice: i <= 1,
                                    smallText: scoreVal === '-',
                                })}
                            >
                                {item[0].split('_')[0]}
                            </li>
                            <Tooltip key={`${item[0]}${i}`} placement="top" arrow title={item[0]} aria-label={item[0]}>
                                <li
                                    className={c('sectionContent1 labelScore', {
                                        outcomeScorePractice: i < 8 && i > 1,
                                        practiceScorePractice: i >= 8,
                                        levelScorePractice: i <= 1,
                                        smallText: scoreVal === '-',
                                    })}
                                >
                                    {item[0].split('_')[1]}
                                    <div
                                        className="gapScores"
                                        style={{
                                            background: bgcolor,
                                            color: text_color,
                                            borderColor: border_color,
                                            borderRadius: '50%',
                                        }}
                                    >
                                        <div>{scoreVal}</div>
                                    </div>
                                    <div
                                        className="allGap"
                                        style={{
                                            borderRadius: '20%',
                                            background: maxGapStyles.bgcolor,
                                            color: maxGapStyles.text_color,
                                        }}
                                    >
                                        <div>{gapToDisplay(maxGapVal, [scoreVal])}</div>
                                    </div>
                                </li>
                            </Tooltip>
                            {item
                                .slice((page - 1) * SLICE_VALUE_GAP + 1, page * SLICE_VALUE_GAP + 1)
                                .map((elem, index) => {
                                    const gapVal = gapToDisplay(elem, score, isPulse);
                                    const gapStyles = getGapColor(gapVal, gapStyle);
                                    return (
                                        <li
                                            className={c({ smallText: gapVal === '-' }, 'contentList')}
                                            style={{
                                                background: gapStyles.bgcolor,
                                                color: gapStyles.text_color,
                                            }}
                                            key={`${elem}${index}`}
                                        >
                                            {gapVal}
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </>
            );
        });
    };
    const getFilterSlideObj = (filterText, reportName, Nsize) => {
        return {
            identify_shape_name: 'checkme',
            identify_shape_value: 'filters_applied',
            is_copy: true,
            is_delete: true,
            data: [
                {
                    name: 'filtertext',
                    value: filterText,
                },
                {
                    name: 'exclude_inclusion_gap_text',
                    value: `${excludeFromGapText(getExcludeGapString(defaultExGap, exGap), demographics)}`,
                },
                {
                    name: 'Source',
                    value: `${reportName} (${NUMBER_TEXT} = ${Nsize})`,
                },
                {
                    name: 'checkme',
                    is_delete: true,
                },
            ],
        };
    };

    const getDataForPptAllGap = () => {
        const { qbyq: mainQbyq = {} } = inclusionqbyq || {};
        const { size: mainSize = {} } = mainQbyq || {};
        const { level_scores: mainLevel = {} } = mainQbyq;
        const benchmarkDetail = {
            name: 'benchmark_detail',
            value: `${benchmarkName} (n surveys = ${SurveyCount}, no. of respondents = ${RespondentCount})`,
        };
        const pptParamsForLegend = getPptParamsForLegend(benchmarks);
        const { enterprise = {}, individual = {}, overall = {} } = mainLevel;
        const { score: indScore, quartile: indQuartile = -1, inclusion_quartile: indIncQuartile = -1 } = individual;
        const { score: entScore, quartile: entQuartile = -1, inclusion_quartile: entIncQuartile = -1 } = enterprise;
        const { score: ovScore, quartile: ovQuartile = -1 } = overall;

        // const { finalArr = [] } = gapScoreData;
        const { allGapArr: qbyqOverallGap = [] } = scoreData;
        const enterpriseScores = [];
        const enterpriseChildScores = [];
        const individualScores = [];
        const individualChildScores = [];
        const outComeSlides = [];
        const enterpriseSlides = [];
        const individualSlides = [];
        const commonEle = [
            {
                name: 'Source',
                value: `${rName} (${NUMBER_TEXT} = ${mainSize})`,
            },
            {
                name: 'checkme',
                is_delete: true,
            },
            ...pptParamsForLegend,
            ...pptParamsForLegend,
            benchmarkDetail,
        ];
        const levelGapArr = [];
        const { levels_gap = {} } = gap;
        const { individual: indGap = {}, enterprise: entGap = {}, overall: overallGap = {} } = levels_gap;
        const { levels_gap: allGapLevels = {} } = allIaGapData || {};
        const { overall: allGapOverallObj = '' } = allGapLevels || {};
        const allGapOverall = showAvgGap ? allGapOverallObj.avg_gap : allGapOverallObj.gap;
        const allGapOverallStyle = getGapColor(allGapOverall, pptStyle, true) + 12;
        const iaScoreStyle = with_benchmark
            ? getBenchmarkColor(ovQuartile, ovScore)
            : getColor(ovScore, pptStyle, false, true);
        levelGapArr.push(
            {
                name: 'individual_gap',
                value: indGap.gap,
                style: getGapColor(indGap.gap, pptStyle, true) + 12,
            },
            {
                name: 'enterprise_gap',
                value: entGap.gap,
                style: getGapColor(entGap.gap, pptStyle, true) + 12,
            }
        );

        commonEle.push({
            name: 'demo_gap',
            value: allGapOverall,
            style: allGapOverallStyle,
        });
        if (showAvgGap) {
            commonEle.push({
                name: 'largest_gap',
                is_delete: true,
            });
        } else {
            commonEle.push({
                name: 'average_gap',
                is_delete: true,
            });
        }

        const selectedGapDemos = parentG.length ? parentG : Object.keys(allGapInclusionData);
        const heatmapKeys = sortParent.length
            ? sortParent.filter(item => selectedGapDemos.includes(item))
            : selectedGapDemos;
        for (let i = 0; i < heatmapKeys.length; i += gapHeatMapChunk) {
            const chunkQbyq = heatmapKeys.slice(i, i + gapHeatMapChunk);
            const dataChunk = finalArr.slice(i, i + gapHeatMapChunk);
            const outcomeTable = [];
            const enterpriseTable = [];
            const individualTable = [];
            const hArrOutcome = [];
            const lArrOutcome = [];
            const hArrIndividual = [];
            const lArrIndividual = [];
            const hArrEnterprise = [];
            const lArrEnterprise = [];
            const nSizeKeys = [
                {
                    name: 'n-size_overall',
                    value: mainSize,
                },
            ];

            chunkQbyq.forEach((key, kIndex) => {
                const demo = key;
                const { levels_gap: gapLevels } = allGapInclusionData[key] || {};
                const { overall: overallDemoGap = {} } = gapLevels;
                const overallIncVal = gapToDisplay(overallDemoGap.gap, [], isPulse);
                const overallIncStyle = getGapColor(overallIncVal, pptStyle, true) + 12;
                const overallGapVal = gapToDisplay(overallGap.gap, overallIncVal, isPulse);
                const overallGapStyle = getGapColor(overallGapVal, pptStyle, true) + 12;
                const demoGroupName = demographics.filter(({ code }) => code === demo)[0];
                const { title = '', label = '' } = demoGroupName;
                const demoGroupHeader = title || label;
                outcomeTable.push(getRowDataText(demoGroupHeader, 7, 0, kIndex * 2 + 6));
                enterpriseTable.push(getRowDataText(demoGroupHeader, 7, 0, kIndex * 2 + 5));
                individualTable.push(getRowDataText(demoGroupHeader, 7, 0, kIndex * 2 + 5));
                outcomeTable.push(
                    getRowData(overallIncVal, overallIncStyle, 4, kIndex * 2 + 6),
                    getRowData(allGapOverall, allGapOverallStyle, 4, 4),
                    getRowData(ovScore, iaScoreStyle, 4, 2)
                );
                enterpriseTable.push(
                    getRowData(overallIncVal, overallIncStyle, 4, kIndex * 2 + 5),
                    getRowData(allGapOverall, allGapOverallStyle, 4, 3),
                    getRowData(ovScore, iaScoreStyle, 4, 1)
                );
                individualTable.push(
                    getRowData(overallIncVal, overallIncStyle, 4, kIndex * 2 + 5),
                    getRowData(overallGapVal, overallGapStyle, 4, 5),
                    getRowData(allGapOverall, allGapOverallStyle, 4, 3),
                    getRowData(ovScore, iaScoreStyle, 4, 1)
                );
            });

            for (let k = chunkQbyq.length; k <= gapHeatMapChunk; k += 1) {
                outcomeTable.push(getRowDataText(' ', 7, 0, k * 2 + 6));
                enterpriseTable.push(getRowDataText(' ', 7, 0, k * 2 + 5));
                individualTable.push(getRowDataText(' ', 7, 0, k * 2 + 5));
                outcomeTable.push(getRowDataText(' ', 6, 4, k * 2 + 6));
                enterpriseTable.push(getRowDataText(' ', 6, 4, k * 2 + 5));
                individualTable.push(getRowDataText(' ', 6, 4, k * 2 + 5));
            }

            for (let j = 0; j < gapHeatMapChunk; j += 1) {
                const scoreArray = dataChunk[j] || Array(25).fill('a');
                const mainArray = scoreArray;
                let row_index = 1;
                const column_index = j * 2;

                mainArray.slice(2, 8).forEach((innerScore, index) => {
                    const gapDisVal = gapToDisplay(innerScore, 1, isPulse);
                    const styleIndex = getGapColor(gapDisVal, pptStyle, true) + 12;
                    const overallScoreArr = scoreForGap.slice(2, 8);
                    const { score = '', quartile: quart = -1 } = overallScoreArr[index];
                    const overallDisVal = scoreToDisplay(score, [], isPulse);
                    const overallIndex = with_benchmark
                        ? getBenchmarkColor(quart, overallDisVal)
                        : getColor(overallDisVal, pptStyle, false, true);
                    const overallGapArrOutcome = qbyqOverallGap.slice(0, 6);
                    const overallGapDisVal = gapToDisplay(overallGapArrOutcome[index].gap, overallDisVal, isPulse);
                    const allGapIndex = getGapColor(overallGapDisVal, pptStyle, true) + 12;
                    outcomeTable.push(getRowData(gapDisVal, styleIndex, row_index + 5, column_index + 6));
                    if (column_index === 8) {
                        if (row_index === 3) {
                            commonEle.push({ name: 'inclusion_score', value: ovScore, style: iaScoreStyle });
                        }
                        outcomeTable.push(getRowData(overallDisVal, overallIndex, row_index + 5, column_index - 6));
                        outcomeTable.push(getRowData(overallGapDisVal, allGapIndex, row_index + 5, column_index - 4));
                    }
                    row_index += 2;
                });
                row_index += 2;
                scoreArray.slice(15).forEach((innerScore, ent_row_index) => {
                    const gapDisVal = gapToDisplay(innerScore, 1, isPulse);
                    const styleIndex = getGapColor(gapDisVal, pptStyle, true) + 12;
                    const overallScoreArr = scoreForGap.slice(15);
                    const { score = '', quartile: quart = -1 } = overallScoreArr[ent_row_index];
                    const overallDisVal = scoreToDisplay(score, [], isPulse);
                    const overallIndex = with_benchmark
                        ? getBenchmarkColor(quart, overallDisVal)
                        : getColor(overallDisVal, pptStyle, false, true);
                    const overallGapArrPractice = qbyqOverallGap.slice(13);
                    const overallGapDisVal = gapToDisplay(
                        overallGapArrPractice[ent_row_index].gap,
                        overallDisVal,
                        isPulse
                    );
                    const allGapIndex = getGapColor(overallGapDisVal, pptStyle, true) + 12;
                    enterpriseTable.push(getRowData(gapDisVal, styleIndex, ent_row_index * 2 + 6, column_index + 5));
                    if (column_index === 8) {
                        if (row_index === 3) {
                            commonEle.push({ name: 'inclusion_score', value: ovScore, style: iaScoreStyle });
                        }
                        enterpriseTable.push(
                            getRowData(overallDisVal, overallIndex, ent_row_index * 2 + 6, column_index - 7)
                        );
                        enterpriseTable.push(
                            getRowData(overallGapDisVal, allGapIndex, ent_row_index * 2 + 6, column_index - 5)
                        );
                    }
                });
                scoreArray.slice(8, 15).forEach((innerScore, ind_row_index) => {
                    const gapDisVal = gapToDisplay(innerScore, 1, isPulse);
                    const styleIndex = getGapColor(gapDisVal, pptStyle, true) + 12;
                    const overallScoreArr = scoreForGap.slice(8, 15);
                    const { score = '', quartile: quart = -1 } = overallScoreArr[ind_row_index];
                    const overallDisVal = scoreToDisplay(score, [], isPulse);
                    const overallIndex = with_benchmark
                        ? getBenchmarkColor(quart, overallDisVal)
                        : getColor(overallDisVal, pptStyle, false, true);
                    const overallGapArrPractice = qbyqOverallGap.slice(6, 13);
                    const overallGapDisVal = gapToDisplay(
                        overallGapArrPractice[ind_row_index].gap,
                        overallDisVal,
                        isPulse
                    );
                    const allGapIndex = getGapColor(overallGapDisVal, pptStyle, true) + 12;
                    individualTable.push(getRowData(gapDisVal, styleIndex, ind_row_index * 2 + 6, column_index + 5));
                    if (column_index === 8) {
                        if (row_index === 3) {
                            commonEle.push({ name: 'inclusion_score', value: ovScore, style: iaScoreStyle });
                        }
                        individualTable.push(
                            getRowData(overallDisVal, overallIndex, ind_row_index * 2 + 6, column_index - 7)
                        );
                        individualTable.push(
                            getRowData(overallGapDisVal, allGapIndex, ind_row_index * 2 + 6, column_index - 5)
                        );
                    }
                });
            }

            const outcomeSlideArr = [
                ...commonEle,
                {
                    name: 'individual_score',
                    value: indScore,
                    style: with_benchmark ? indQuartile : indIncQuartile,
                },
                {
                    name: 'enterprise_score',
                    value: entScore,
                    style: with_benchmark ? entQuartile : entIncQuartile,
                },
                ...levelGapArr,
                {
                    name: 'heatmap_outcome_gaps_DataTable',
                    table_data: outcomeTable,
                },
                ...enterpriseScores,
                ...individualScores,
                ...nSizeKeys,
                ...hArrEnterprise,
                ...lArrEnterprise,
            ];

            const enterpriseSlideArr = [
                ...commonEle,
                ...enterpriseScores,
                ...enterpriseChildScores,
                {
                    name: 'heatmap_practices_enterprise_gaps_DataTable',
                    table_data: enterpriseTable,
                },
                ...nSizeKeys,
                ...lArrOutcome,
                ...hArrOutcome,
            ];

            const individualSlideArr = [
                ...commonEle,
                ...individualScores,
                ...individualChildScores,
                {
                    name: 'heatmap_practices_individual_gaps_DataTable',
                    table_data: individualTable,
                },
                ...nSizeKeys,
                ...lArrIndividual,
                ...hArrIndividual,
            ];

            outComeSlides.push({
                identify_shape_name: 'checkme',
                identify_shape_value: 'heatmap_outcome_gaps',
                is_copy: true,
                is_delete: true,
                styles: with_benchmark ? pptBenchmarkStyles : styles,
                data: outcomeSlideArr,
            });

            enterpriseSlides.push({
                identify_shape_name: 'checkme',
                identify_shape_value: 'heatmap_practices_enterprise_gaps',
                is_copy: true,
                is_delete: true,
                styles: with_benchmark ? pptBenchmarkStyles : styles,
                data: enterpriseSlideArr,
            });

            individualSlides.push({
                identify_shape_name: 'checkme',
                identify_shape_value: 'heatmap_practices_individual_gaps',
                is_copy: true,
                is_delete: true,
                styles: with_benchmark ? pptBenchmarkStyles : styles,
                data: individualSlideArr,
            });
        }

        const filterText = getFiltersText(filters, demographics);
        const filterSlide = getFilterSlideObj(filterText, rName, mainSize);

        const output = {
            template_file_name: 'Inclusion_Template_R8_coach.pptx',
            output_file_name: 'inclusion_gap.pptx',
            slides: [...outComeSlides, ...enterpriseSlides, ...individualSlides, filterSlide],
        };
        getPptData(output, pptAccessToken, 'Inclusion_Demographics');
    };

    return (
        <div className="content">
            <div className="upperHeader heatmap gapHeatmap">
                <div>
                    <div className="demographicDropdown">
                        <Dropdown
                            selectedDemographic={Object.keys(allGapInclusionData)}
                            selected={parentG}
                            setSelected={setParent}
                            demographics={demographics}
                        />
                    </div>
                    <div className="demographicDropdown noBorder">
                        <Select
                            data-testid="demographicOptions"
                            IconComponent={Sort}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={sort}
                            displayEmpty
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                            }}
                            onChange={({ target: { value: code } }) => {
                                setSort(code);
                            }}
                        >
                            <MenuItem classes={{ root: 'sortOptions' }} value="">
                                <span>{SORT}</span>
                            </MenuItem>
                            <ListSubheader>{OVERALL_SCORE}</ListSubheader>
                            {OVERALL_SORT.map((item, i) => {
                                return (
                                    <MenuItem key={item} classes={{ root: 'sortOptions' }} value={i + 4}>
                                        {sortParent.length ? <span className="activeSort" /> : null}
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </div>
                </div>
                <div>
                    <ul>
                        <li className="toggleBtn">
                            <div className="tagName">{SHOW_OVERALL}</div>
                            <div className={c({ overallView })} onClick={() => setOverallView(!overallView)}>
                                <div data-testid="hideGapBtn" className={c({ isActive: !overallView })} />
                                <div data-testid="showGapBtn" className={c({ isActive: overallView })} />
                            </div>
                        </li>
                        <li data-testid="getPptBtn" className="separator" onClick={() => setDwnldDrop(!dwnldDrop)}>
                            <div>
                                <img src={DownloadIcon} alt="" />
                            </div>
                            <span>{DOWNLOAD}</span>
                            {dwnldDrop && (
                                <div className="dropdown">
                                    <div onClick={getDataForPptAllGap}>ppt</div>
                                    <div onClick={() => setSelectedDownload(1)}>excel</div>
                                </div>
                            )}
                        </li>
                        <li className="leftPadding">
                            <div className="tagName">{VIEW_BY}</div>
                            <div>
                                <div
                                    data-testid="hideGapBtn"
                                    className={c({ isActive: !showAvgGap })}
                                    onClick={() => setAvgGap(false)}
                                >
                                    {LARGEST_GAP}
                                </div>
                                <div
                                    data-testid="showGapBtn"
                                    className={c({ isActive: showAvgGap })}
                                    onClick={() => setAvgGap(true)}
                                >
                                    {AVG_GAP}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="legendContent tile">
                <div>
                    <div>
                        <ul className="classification">
                            <li>{SCORE_CLASSIFICATION_TEXT} </li>
                            {styleToUse
                                .filter(
                                    ({ quartile, style_for }) =>
                                        quartile !== '0' && quartile !== '999' && style_for !== 'blank_data'
                                )
                                .map(({ title, bgcolor }) => (
                                    <li key={title}>
                                        <span className="circle" style={{ background: bgcolor }} />
                                        {title}
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div>
                        <ul className="classification">
                            <li>{GAP_CLASSIFICATION_TEXT} </li>
                            {gapStyle.map(({ title, bgcolor }) => (
                                <li key={title}>
                                    <span style={{ background: bgcolor }} />
                                    {title}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <section className="sectionWrapper">
                <div
                    id="myHeader"
                    className={c('headerWrapper clearfix', {
                        fixedHeader: isSticky,
                        adjustHeader: isSticky,
                    })}
                >
                    {page > 1 ? (
                        <div className="arrow" onClick={() => updatePage(() => page - 1)}>
                            <img className="imgClass" src={ArrowLeft} alt="" />
                        </div>
                    ) : null}
                    <div>
                        {headers && headers.length ? <ul className="mainHeader">{scoresHeaders(headers)}</ul> : null}
                    </div>
                    {headers.length > page * SLICE_VALUE_GAP + 1 ? (
                        <div
                            className="arrow arrowRight"
                            onClick={() => {
                                updatePage(page + 1);
                            }}
                        >
                            <img className="imgClass" src={ArrowRight} alt="" />
                        </div>
                    ) : null}
                </div>
                <div className="contentWrapper clearfix">
                    <div className="mainContent">
                        {!overallView
                            ? scoresGapTable(rearrangedArr.slice(0, 2), allGapArr.slice(23), scoreForGap.slice(0, 2))
                            : null}
                        {selectedSubTab === 0 && scoreForGap.length
                            ? scoresGapTable(rearrangedArr.slice(2, 8), allGapArr.slice(0, 6), scoreForGap.slice(2, 8))
                            : scoresGapTable(rearrangedArr.slice(8), allGapArr.slice(6), scoreForGap.slice(8))}
                    </div>
                </div>
            </section>
        </div>
    );
};

GapInclusionData.defaultProps = {
    isPulse: false,
};

GapInclusionData.propTypes = {
    gapData: PropTypes.object.isRequired,
    demographics: PropTypes.array.isRequired,
    scoreColor: PropTypes.object.isRequired,
    scoreData: PropTypes.object.isRequired,
    withBenchmark: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    updatePage: PropTypes.func.isRequired,
    isPulse: PropTypes.bool,
    selectedSubTab: PropTypes.bool.isRequired,
    setSelectedDownload: PropTypes.func.isRequired,
    sortParent: PropTypes.array.isRequired,
    allGapInclusionData: PropTypes.object.isRequired,
    setAvgGap: PropTypes.func.isRequired,
    showAvgGap: PropTypes.bool.isRequired,
    parentG: PropTypes.array.isRequired,
    setParent: PropTypes.func.isRequired,
    sort: PropTypes.string.isRequired,
    setSort: PropTypes.func.isRequired,
    inclusionqbyq: PropTypes.object.isRequired,
    benchmarkName: PropTypes.string.isRequired,
    SurveyCount: PropTypes.string.isRequired,
    RespondentCount: PropTypes.number.isRequired,
    allIaGapData: PropTypes.object.isRequired,
    getPptData: PropTypes.func.isRequired,
    iaApiParams: PropTypes.object.isRequired,
    exGap: PropTypes.string.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    setGapScoreData: PropTypes.func.isRequired,
    gapDataFetched: PropTypes.bool.isRequired,
    overallView: PropTypes.bool.isRequired,
    setOverallView: PropTypes.func.isRequired,
};
export default GapInclusionData;
