import { postWebApi } from '../../webapis/dashboard';
import endpoints from '../../Endpoints';

export const getAccessApiUrl = options => {
    const { apiBasePath, accessDetails } = endpoints;
    const url = `${apiBasePath}${accessDetails}`;
    return postWebApi(url, options).request;
};

export const getPptAccessApiUrl = () => {
    const { apiBasePath, pptAccessDetails } = endpoints;
    const url = `${apiBasePath}${pptAccessDetails}`;
    return postWebApi(url).request;
};

export const changePasswordApiUrl = (options, token) => {
    const { apiBasePath, changePassword } = endpoints;
    const url = `${apiBasePath}${changePassword}`;
    return postWebApi(url, options, token).request;
};

export const getDefaultSettingsUrl = options => {
    const { apiBasePath, defaultSettingsPath } = endpoints;
    const url = `${apiBasePath}${defaultSettingsPath}`;
    return postWebApi(url, options).request;
};
