import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import RecipeBoard from './RecipeBoard';

import {
    getPracticeData,
    getPracticeBuData,
    // getScoreData,
    getPptData,
    setPracticeDisplay,
    updateFilters,
    resetPracticeBuDataFetched,
    getSinglePastReport,
    updateTargetSettingBm,
    getTargetScoreData,
} from '../actions';
import { updatePracticeBuFilters, getReport, getRawPptData } from '../../Reports/actions';

import {
    // selectScoreData,
    // selectScoreDataFetched,
    selectPracticeData,
    practiceDataFetched,
    selectApiParams,
    selectApiCount,
    selectFiltersApplied,
    getErrorInfo,
    selectFilters,
    selectPracticeToDisplay,
    selectPracticeBuData,
    selectPracticeBuDataFetched,
    selectPastReport,
    selectTargetBm,
    selectTargetBenchmarks,
    selectTargetScoreData,
    selectTargetScoreDataFetched,
} from '../selector';

import { getDefaultSettings } from '../../Login/selector';
import { selectPastReports, selectPracticeFilters, selectReportError } from '../../Reports/selector';

const stateToProps = createStructuredSelector({
    scoreData: selectTargetScoreData(),
    scoreDataFetched: selectTargetScoreDataFetched(),
    practiceData: selectPracticeData(),
    practiceDataFetched: practiceDataFetched(),
    apiParams: selectApiParams(),
    apiLoadingCount: selectApiCount(),
    activeFilter: selectFiltersApplied(),
    errorInfo: getErrorInfo(),
    filters: selectFilters(),
    practiceToDisplay: selectPracticeToDisplay(),
    defaultSettings: getDefaultSettings(),
    practiceFilters: selectPracticeFilters(),
    practiceBuData: selectPracticeBuData(),
    practiceBuDataFetched: selectPracticeBuDataFetched(),
    pastReports: selectPastReports(),
    reportError: selectReportError(),
    singlePastReport: selectPastReport(),
    targetBenchmarks: selectTargetBenchmarks(),
    targetBm: selectTargetBm(),
});

const dispatchToProps = {
    getPracticeData,
    getPracticeBuData,
    updatePracticeBuFilters,
    getPptData,
    setPracticeDisplay,
    getTargetScoreData,
    updateFilters,
    resetPracticeBuDataFetched,
    getReport,
    getRawPptData,
    getSinglePastReport,
    updateTargetSettingBm,
};

export default connect(stateToProps, dispatchToProps)(RecipeBoard);
