import React, { Fragment } from 'react';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import InfoLarge from '../assets/InfoLarge.svg';
import SelectBenchmark from '../../../components/SelectBenchmark/SelectBenchmark';
import { getStaticText } from '../../../utils/constants';

const WarningDialog = ({
    defaultSettings,
    setView,
    benchmarks,
    optionsToUse,
    setOptionsToUse,
    targetBm,
    updateTargetSettingBm,
}) => {
    // useEffect(() => {
    //     if (id) {
    //         setTicketId(id);
    //     }
    //     // eslint-disable-next-line
    // }, [feedback]);

    // new
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { TARGET_HEADING, TARGET_PARAGRAPH, TARGET_OUTCOME, TARGET_LIST_1, TARGET_SUB_LIST, CONTINUE } =
        SITE_TEXT || {};

    return (
        <Dialog
            open
            disableEscapeKeyDown
            disableBackdropClick
            maxWidth="md"
            classes={{
                root: 'feedbackSuccessDialogRoot warningDialogRoot',
            }}
        >
            <section className="dataContent dataHead">
                <div className="infoLarge">
                    <img src={InfoLarge} alt="" />
                </div>
                <h2>{TARGET_HEADING}</h2>
                {/* <div className="nRes">
                    {'Jira ticket ID : '} <b>{jira_number}</b>
                </div> */}
                <div className="para">
                    <div>{TARGET_PARAGRAPH}</div>
                    <div>
                        {TARGET_OUTCOME}
                        <ul>
                            <li>{TARGET_LIST_1}</li>
                            <li>{TARGET_SUB_LIST}</li>
                        </ul>
                    </div>
                    <div>
                        <SelectBenchmark
                            benchmarks={benchmarks}
                            updateTargetSettingBm={updateTargetSettingBm}
                            targetBm={targetBm}
                            optionsToUse={optionsToUse}
                            setOptionsToUse={setOptionsToUse}
                            showLabel
                        />
                    </div>
                </div>
            </section>

            <section className="buttonContent clearfix activeButtons">
                <Fragment>
                    <div
                        data-testid="compareButton"
                        className="compareBtn activeCompareBtn"
                        onClick={() => {
                            setView(false);
                            updateTargetSettingBm(optionsToUse);
                        }}
                    >
                        {CONTINUE}
                    </div>
                </Fragment>
            </section>
        </Dialog>
    );
};

WarningDialog.propTypes = {
    setView: PropTypes.func.isRequired,
    benchmarks: PropTypes.array.isRequired,
    optionsToUse: PropTypes.array.isRequired,
    setOptionsToUse: PropTypes.func.isRequired,
    targetBm: PropTypes.string.isRequired,
    updateTargetSettingBm: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
};

export default WarningDialog;
