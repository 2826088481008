import React, { useState } from 'react';
import { Button, Dialog } from '@material-ui/core';
import c from 'classnames';
import PropTypes from 'prop-types';

import { getStaticText } from '../../utils/constants';
import LeftArrow from '../../assets/left.svg';
import RightArrow from '../../assets/right.svg';
import './index.scss';

function InclusionFeaturesModal({ imageArr, closeModal, defaultSettings }) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { FEATURE_MODAL_HEADING, FEATURE_MODAL_BTN } = SITE_TEXT || {};
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(1);

    // eslint-disable-next-line react/prop-types
    const Dot = ({ isActive, dotNumber }) => (
        <div
            className={c({ activeDot: isActive }, 'dot')}
            onClick={() => {
                setMin(dotNumber);
                setMax(dotNumber + 1);
            }}
        />
    );

    return (
        <Dialog
            open
            disableBackdropClick
            maxWidth="md"
            classes={{
                root: 'featureDialogRoot',
            }}
        >
            <section className="headContent">
                <div>{FEATURE_MODAL_HEADING}</div>
            </section>
            <section className="dataContent">
                {imageArr.slice(min, max).map(({ imgSrc = '' }) => (
                    <img key={imgSrc} src={imgSrc} alt="" className="screenImg" />
                ))}
                {min === 0 ? (
                    <div />
                ) : (
                    <div className="leftArrow">
                        <div
                            data-testid="leftArrow"
                            onClick={() => {
                                setMin(min - 1);
                                setMax(max - 1);
                            }}
                        >
                            <img src={LeftArrow} alt="" />
                        </div>
                    </div>
                )}
                {imageArr.length !== max ? (
                    <div className="rightArrow">
                        <div
                            data-testid="rightArrow"
                            onClick={() => {
                                setMin(min + 1);
                                setMax(max + 1);
                            }}
                        >
                            <img src={RightArrow} alt="" />
                        </div>
                    </div>
                ) : (
                    <div />
                )}
            </section>

            {imageArr.length > 1 ? (
                <section className="footerNav">
                    <div className="dots">
                        {imageArr.map((_item, i) => (
                            <Dot isActive={i === min} key={`dot${i}`} dotNumber={i} />
                        ))}
                    </div>
                </section>
            ) : null}
            <section className="buttonSection">
                <Button onClick={() => closeModal(false)}>{FEATURE_MODAL_BTN}</Button>
            </section>
        </Dialog>
    );
}

InclusionFeaturesModal.propTypes = {
    imageArr: PropTypes.array.isRequired,
    closeModal: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
};

export default InclusionFeaturesModal;
