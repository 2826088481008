import React, { useState } from 'react';
import { Toast } from '@mds/mds-reactjs-library';
import './index.scss';
import InfoModal from './InfoModal';

export default function InfoToast() {
    const [open, setOpen] = useState(true);
    const [infoModal, setInfoModal] = useState(false);

    const action = (
        <React.Fragment>
            <div className="button" onClick={() => setInfoModal(true)}>
                Click here to troubleshoot
            </div>
        </React.Fragment>
    );

    const message = 'The gap calculation may be taking time due to large number of demographic groups.';

    return open ? (
        <div className="infoToastContainer">
            <Toast type="info" title="Calculating Largest Gaps" onCloseClick={() => setOpen(false)} action={action}>
                {message}
            </Toast>
            {infoModal ? <InfoModal setInfoModal={setInfoModal} /> : null}
        </div>
    ) : null;
}
