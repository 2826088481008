import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import DemographicScores from './DemographicScores';
import { getGapData, getInclusionData } from '../actions';
import { getDemographics } from '../../Diagnose/actions';
import { selectFilters, selectApiCount } from '../../Diagnose/selector';
import { getDefaultSettings } from '../../Login/selector';
import { selectGapData, selectReportApiParams, selectReportApiCount, selectGapDataFetched } from '../selector';
import { selectScoreColor } from '../../Inclusion/selector';

const stateToProps = createStructuredSelector({
    gapData: selectGapData(),
    reportApiParams: selectReportApiParams(),
    reportApiCount: selectReportApiCount(),
    gapDataFetched: selectGapDataFetched(),
    filters: selectFilters(),
    apiLoadingCount: selectApiCount(),
    defaultSettings: getDefaultSettings(),
    scoreColor: selectScoreColor(),
});

const dispatchToProps = { getGapData, getDemographics, getInclusionData };

export default connect(stateToProps, dispatchToProps)(DemographicScores);
