import React, { Fragment } from 'react';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import Trash from '../assets/Trash-can-large.svg';
import './index.scss';

const DeleteModal = ({ setView, deleteCustomBm, customBmId, customBmName }) => {
    const publishRequest = () => {
        deleteCustomBm(customBmId);
    };

    return (
        <Dialog
            open
            disableEscapeKeyDown
            disableBackdropClick
            maxWidth="md"
            classes={{
                root: 'customBmDialogRoot',
            }}
        >
            <section className="infoContent">
                <div className="infoLarge">
                    <img src={Trash} alt="" />
                </div>
                <h2>Delete Custom Benchmark</h2>
                <div className="para">
                    <div>Are you sure you want to delete “{customBmName}” benchmark?</div>
                </div>
            </section>
            <section className="btnContent clearfix activeButtons multiBtn">
                <Fragment>
                    <div
                        data-testid="detailButton"
                        className="detailedBtn aciveDetailBtn"
                        onClick={() => {
                            setView(false);
                        }}
                    >
                        No, Cancel
                    </div>
                    <div data-testid="compareButton" className="compareBtn activeCompareBtn" onClick={publishRequest}>
                        Yes, Delete
                    </div>
                </Fragment>
            </section>
        </Dialog>
    );
};

DeleteModal.propTypes = {
    setView: PropTypes.func.isRequired,
    deleteCustomBm: PropTypes.func.isRequired,
    customBmId: PropTypes.number.isRequired,
    customBmName: PropTypes.string.isRequired,
};

export default DeleteModal;
