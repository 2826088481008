import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, IconButton, Button, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import c from 'classnames';

import Loader from '../../Loader';
import { getStaticText } from '../../../utils/constants';
import './index.scss';
import ErrorToast from '../../InformationToast/ErrorToast';

const UpdateViewModal = ({ error, isLoading, changeUser, resetError, closeModal, defaultSettings }) => {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { UPDATE_VIEW, UPDATE_VIEW_BUTTON, TEXT_PLACE, CANCEL_TEXT } = SITE_TEXT || {};
    const [userId, setData] = useState('');

    const addNewAction = () => {
        changeUser({ user_id: parseInt(userId, 10) });
    };

    const resetErrorAndClose = () => {
        if (error) {
            resetError();
        }
        closeModal(false);
    };

    return (
        <Dialog onClose={resetErrorAndClose} open classes={{ root: 'changeUserView' }}>
            {isLoading ? <Loader position="absolute" showBackground background="transparent" /> : null}
            <section className="headContent">
                <IconButton aria-label="close" className="closeButton" onClick={resetErrorAndClose}>
                    <CloseIcon />
                </IconButton>
                <h2>{UPDATE_VIEW}</h2>
            </section>
            <section className="dataContent">
                {error && <ErrorToast message={error} />}
                <TextField
                    fullWidth
                    inputProps={{ maxLength: 50, 'data-testid': 'userId' }}
                    value={userId}
                    onChange={({ target: { value } }) => setData(value)}
                    placeholder={TEXT_PLACE}
                    label="User Id"
                    variant="outlined"
                />
            </section>
            <section className="buttonContent clearfix">
                <Button data-testid="addButton" classes={{ root: c({ active: userId }) }} onClick={addNewAction}>
                    {UPDATE_VIEW_BUTTON}
                </Button>
                <Button data-testid="cancelButton" classes={{ root: 'active' }} onClick={resetErrorAndClose}>
                    {CANCEL_TEXT}
                </Button>
            </section>
        </Dialog>
    );
};

UpdateViewModal.propTypes = {
    error: PropTypes.string.isRequired,
    isLoading: PropTypes.number.isRequired,
    changeUser: PropTypes.func.isRequired,
    resetError: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
};

export default UpdateViewModal;
