const colors = {
    $siteTextColor: '#000000',
    $white: '#ffffff',
    $black: '#000000',
    $yellow: '#ffd800',
    $yellow100: '#E0C446',
    $yellow200: '#FEC009',
    $yellow300: '#E6D7BC',
    $darkBlue100: '#002960',
    $pink: '#AD005B',
    $pink100: '#CD3030',
    $blue100: '#148FA7',
    $blue200: '#7D9AAA',
    $blue300: '#009AA6',
    $blue400: '#027AB1',
    $blue500: '#002960',
    $blue600: '#325dff',
    $blue700: '#00a9f3',
    $blue800: '#aae6f0',
    $darkBlue400: '#1e41e6',
    $green100: '#9fb9bd',
    $green200: '#08d39c',
    $green300: '#6d992c',
    $green400: '#669B29',
    $green500: '#B4E6CE',
    $green600: '#94DAB2',
    $green700: '#91CF50',
    $green800: '#4bca7a',
    $green900: '#a3e06f',
    $green1000: '#10CBBC',
    $grey200: '#d0d0d0',
    $grey300: '#cccccc',
    $grey400: '#e5e5e5',
    $grey500: '#7f7f7f',
    $grey600: '#979797',
    $grey700: '#c7d6db',
    $grey800: '#d6d6d6',
    $red300: '#9f0554',
    $red400: '#992c2c',
    $red500: '#E4546C',
    $black100: '#333333',
    $purple100: '#E3C4FF',
    $purple200: '#9D6CDA',
    $purple300: '#3B1955',
    $orange100: '#FBBBA8',
    $orange200: '#FFCCB0',
    $orange300: '#FFF1D2',
    $orange400: '#E1A900',
    $orange500: '#ffd24b',
    $orange600: '#ff8E4b',
    $significantlyPink: ' #CD3030',
    $significantlyGreen: '#10CBBC',
    $comparableColor: '#E6D7BC',
    $practiceGreen: '#007F26',
    $practiceRed: '#D51F31',
    $practiceYellow: '#F3C233',
    $scoreColor: {
        1: ['#048E42', '#ffffff'],
        2: ['#93D154', '#ffffff'],
        3: ['#FFC000', '#000000'],
        4: ['#FC6B08', '#ffffff'],
        5: ['#DF2D4A', '#ffffff'],
    },
    $demoScore: {
        1: ['#4bca7a'],
        2: ['#a3e06f'],
        3: ['#ffd24b'],
        4: ['#ff8E4b'],
        5: ['#f64b7c'],
    },
    $demoScoreColor: {
        1: ['rgba(0,180,66,0.83)', '#ffffff'],
        2: ['rgba(125,211,51,0.83)', '#000000'],
        3: ['rgba(255,191,0,0.83)', '#000000'],
        4: ['rgba(255,95,0,0.83)', '#ffffff'],
        5: ['rgba(243,0,70,0.83)', '#ffffff'],
    },
    $cohesionPractice: {
        1: ['#E5546C', '#ffffff'],
        2: ['#00B050', '#ffffff'],
        3: ['#5FCEFF'],
        4: ['#7030A0', '#000000'],
    },
    $cohesionGraph: {
        1: ['#012860', '#ffffff'],
        2: ['#2140E6', '#ffffff'],
        3: ['#00A9F4', '#ffffff'],
        4: ['#B2DDF6', '#000000'],
        5: ['#E0DFDF', '#000000'],
        6: ['#ffffff', '#000000'],
        7: ['#E5536C', '#000000'],
    },
    $cohesionPracticeOhi4: {
        1: ['#061F79', '#ffffff'],
        2: ['#2251FF', '#ffffff'],
        3: ['#00A9F4', '#ffffff'],
        4: ['#99C4FF', '#ffffff'],
    },
    $cohesionGraphOhi4: {
        1: ['#108980', '#ffffff'],
        2: ['#10CBBC', '#ffffff'],
        3: ['#75F0E7', '#ffffff'],
        4: ['#ffffff', '#000000'],
    },
    $indExpColor: {
        1: ['#108980', '#8E0B0B'],
        2: ['#12A195', '#B82525'],
        3: ['#10CBBC', '#E33B3B'],
        4: ['#20E9DA', '#F17E7E'],
        5: ['#A3F5EF', '#FAA8A8'],
    },
};

export default colors;
