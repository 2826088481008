import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Respondents from './Respondents';
import { getPptData } from '../../Diagnose/actions';
import { updateRespondents, updateComments, getRespondents } from '../../Reports/actions';
import { selectRespondent, selectComments } from '../../Reports/selector';
import { selectRespondentsData, selectRespondentsDataFetched, selectFiltersApplied } from '../selector';
import { getDefaultSettings } from '../../Login/selector';

const stateToProps = createStructuredSelector({
    respondentData: selectRespondentsData(),
    respondentFetched: selectRespondentsDataFetched(),
    respondent: selectRespondent(),
    comments: selectComments(),
    activeFilter: selectFiltersApplied(),
    defaultSettings: getDefaultSettings(),
});

const dispatchToProps = { getPptData, updateRespondents, updateComments, getRespondents };

export default connect(stateToProps, dispatchToProps)(Respondents);
