export const GET_ACTION_LIST = 'GET_ACTION_LIST';
export const UPDATE_ACTION_LIST = 'UPDATE_ACTION_LIST';
export const GET_COMP_VALUES = 'GET_COMP_VALUES';
export const UPDATE_VALUES = 'UPDATE_VALUES';
export const ACTION_USABILITY = 'ACTION_USABILITY';
export const ACTION_VOTE = 'ACTION_VOTE';
export const ADD_NEW_ACTION = 'ADD_NEW_ACTION';
export const RESET_ACTION_REDUX = 'RESET_ACTION_REDUX';

export const COMP_KEYS = ['recipe', 'outcome', 'intervention', 'level', 'influence_model', 'using_this_action', 'vote'];
