import React from 'react';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';

import { getStaticText } from '../../utils/constants';

import AuthStore from '../../common/AuthStore';
import './index.scss';

function WarningModal({ deleteToken, history, defaultSettings }) {
    const { lang = '1033' } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { SESSION_EXPIRED, LOGIN_BTN } = SITE_TEXT || {};

    const handleClickOnLogout = () => {
        AuthStore.deleteAccessToken();
        history.push('/');
        deleteToken();
        AuthStore.deleteOhId();
        AuthStore.deleteReportId();
        AuthStore.deleteUserName();
        AuthStore.deleteUserEmail();
        AuthStore.deleteUserRole();
        AuthStore.deleteActionAccess();
        AuthStore.deleteMidToken();
        AuthStore.deleteGsodAccess();
        AuthStore.deletePptAccessToken();
        AuthStore.deletePptTokenExp();
        AuthStore.deletePracticeBU();
        AuthStore.deletePracticeReportBU();
        AuthStore.deleteResurveyFilter1();
        AuthStore.deleteResurveyFilter2();
        AuthStore.deleteGsodLoginNumber();
        AuthStore.deletegsodApprover();
        AuthStore.deleteohiLoginNumber();
    };

    return (
        <Dialog
            open
            disableEscapeKeyDown
            disableBackdropClick
            maxWidth="md"
            classes={{
                root: 'warningDialog',
            }}
        >
            <h2>
                {SESSION_EXPIRED}
                <span data-testid="clickSpan" onClick={handleClickOnLogout}>
                    {LOGIN_BTN}
                </span>
            </h2>
        </Dialog>
    );
}

WarningModal.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    deleteToken: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default WarningModal;
