import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCohesionData, setCohesionDemographic, setCohesionSort } from '../actions';
import {
    selectCohesionScore,
    selectCohesionDemographic,
    selectIsCohesionData,
    selectApiParams,
    selectFilters,
    selectApiCount,
    selectFiltersApplied,
    selectCohesionDataFetched,
    selectCohesionError,
} from '../selector';
import { getDefaultSettings } from '../../Login/selector';
import Cohesion from './Cohesion';

const stateToProps = createStructuredSelector({
    cohesionScore: selectCohesionScore(),
    cohesionDemographic: selectCohesionDemographic(),
    cohesionData: selectIsCohesionData(),
    apiParams: selectApiParams(),
    filters: selectFilters(),
    error: selectCohesionError(),
    apiLoadingCount: selectApiCount(),
    cohesionDataFetched: selectCohesionDataFetched(),
    activeFilter: selectFiltersApplied(),
    defaultSettings: getDefaultSettings(),
});

const dispatchToProps = {
    getCohesionData,
    setCohesionSort,
    setCohesionDemographic,
};

export default connect(stateToProps, dispatchToProps)(Cohesion);
