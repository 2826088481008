import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Questions from './Questions';
import { getPptData } from '../../Diagnose/actions';
import { selectAllGapDataFetched, selectFiltersApplied } from '../selector';
import { getDefaultSettings } from '../../Login/selector';

const stateToProps = createStructuredSelector({
    activeFilter: selectFiltersApplied(),
    defaultSettings: getDefaultSettings(),
    allGapDataFetched: selectAllGapDataFetched(),
});

const dispatchToProps = { getPptData };

export default connect(stateToProps, dispatchToProps)(Questions);
