import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import c from 'classnames';
import { getStaticText } from '../../../utils/constants';
import getStaticTextDiagnose from '../constants';
import Info from '../assets/C-info.svg';
import './index.scss';
import { getEEBoxStyle } from '../../../utils/functions';
import MetaChartModal from '../MetaChartModal';

const showProgress = (value, isNegative) => {
    const { positiveBg = '#000000', negativeBg = '#ffffff' } = getEEBoxStyle(value, isNegative) || {};
    const progessStyle = {
        background: `
                    radial-gradient(closest-side, #ffffff 65%, transparent 0% 100%),
                    conic-gradient(${isNegative ? negativeBg : positiveBg} ${value}%, rgb(239, 238, 239) 0%)`,
    };
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <div className="progress-bar" style={progessStyle}>
                <progress value={value} min="0" max="100" style={{ height: 0, width: 0 }} />
                <div className="percentValue">{`${value}%`}</div>
            </div>
        </Box>
    );
};

function IndExpScorecard({ qbyqData, defaultSettings, apiLoadingCount, errorInfo }) {
    const { lang = '1033', report_type: surveyType = '' } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { QUESTIONS } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { NEG_IND_QUES, QUES_LEGEND_TYPE, SITE_TEXT } = staticText;
    const [showMetaChart, setShowMetaChart] = useState(false);
    const [metaData, setMetaData] = useState({});
    const [isPracticeMeta, setPraticeMeta] = useState(false);

    const toggleMetaDataPopup = (modalState = false, scores = {}, isPractice = false) => {
        setShowMetaChart(modalState);
        setMetaData(scores);
        setPraticeMeta(isPractice);
    };

    const { records: qbyq = {}, size = '' } = qbyqData || {};
    const checkLength = !!Object.keys(qbyq).length;
    const {
        SINGLE_QUESTIONS_LEGEND,
        QUESTION_SCORE,
        IND_EXP_SCORE_PAGE_TEXT_NOTES,
        EMPLOYEE_NET_PROMOTER_SCORE,
        EE_PERCENT_FAVORABLE,
        EE_PERCENTILE,
        NOTE,
        IND_EXP_QUESTION_NOTE,
    } = SITE_TEXT || {};

    return (
        <>
            <Fragment>
                <div className="indExpScorecard">
                    {checkLength &&
                        QUESTIONS.map(({ title, children, imgSrc }) => {
                            return (
                                <div className="cardWrapper">
                                    <div className="headerContent">
                                        <h2>
                                            <span>
                                                <img src={imgSrc} alt={title} />
                                            </span>
                                            {title}
                                        </h2>
                                    </div>
                                    {children.map(key => {
                                        const {
                                            quartile,
                                            range_start,
                                            range_end,
                                            display_name: childTitle = '',
                                            score,
                                            meta_scores = [],
                                        } = qbyq[key] || {};
                                        const negativeTrait = NEG_IND_QUES.includes(key);
                                        const sup = QUES_LEGEND_TYPE[key] || '';
                                        const { qtext = '', bottom2_score = 0, nuetral_score = 0 } =
                                            meta_scores[0] || {};
                                        const enps = score - bottom2_score;
                                        const positiveNps = enps > 0;
                                        const childSet = {
                                            meta_scores,
                                            display_name: childTitle,
                                            score,
                                            quartile,
                                            range_start,
                                            range_end,
                                        };
                                        return (
                                            <div className={c({ hoverElement: sup === 2 }, 'cardData')}>
                                                <div onClick={() => toggleMetaDataPopup(true, childSet, true)}>
                                                    {childTitle}
                                                    <sup>{sup}</sup>
                                                    {sup === 2 ? (
                                                        <span>
                                                            <img src={Info} alt="" />
                                                        </span>
                                                    ) : null}
                                                </div>

                                                <div className="percentProgress">
                                                    {showProgress(score, negativeTrait)}
                                                </div>
                                                {sup === 2 ? (
                                                    <div className="detailedInfo">
                                                        <div className="questionSection">
                                                            <div className="practiceSet clearfix">
                                                                <div className="metaSection">
                                                                    <div className="metaText">
                                                                        <b>{QUESTION_SCORE}</b>
                                                                    </div>
                                                                    <div className="metaScore chartLegends">
                                                                        <div>
                                                                            {SINGLE_QUESTIONS_LEGEND.map(
                                                                                (legendText, i) => {
                                                                                    const sectionWidth =
                                                                                        i === 1 ? nuetral_score : score;
                                                                                    const width = !i
                                                                                        ? bottom2_score
                                                                                        : sectionWidth;
                                                                                    return (
                                                                                        <div
                                                                                            style={{
                                                                                                width: `${width -
                                                                                                    0.5}%`,
                                                                                            }}
                                                                                        >
                                                                                            {legendText}
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="practiceSet clearfix">
                                                                <div className="metaSection">
                                                                    <div className="metaText">{qtext}</div>
                                                                    <div className="metaScore">
                                                                        <div className="chart">
                                                                            <span
                                                                                style={{
                                                                                    width: `${bottom2_score - 0.5}%`,
                                                                                }}
                                                                            >
                                                                                {bottom2_score}
                                                                            </span>
                                                                            <span
                                                                                style={{
                                                                                    width: `${nuetral_score - 0.5}%`,
                                                                                }}
                                                                            >
                                                                                {nuetral_score}
                                                                            </span>
                                                                            <span
                                                                                style={{
                                                                                    width: `${score - 0.5}%`,
                                                                                }}
                                                                            >
                                                                                {score}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="metaInfo">{IND_EXP_SCORE_PAGE_TEXT_NOTES}</div>
                                                        <div className="questionSection">
                                                            <div className="practiceSet bottom clearfix">
                                                                <div className="metaSection">
                                                                    <div className="metaText">
                                                                        <span>
                                                                            <strong>eNPS</strong>
                                                                            <br />
                                                                            {EMPLOYEE_NET_PROMOTER_SCORE}
                                                                        </span>
                                                                        <span>{`= ${score}% - ${bottom2_score}%`}</span>
                                                                    </div>
                                                                    <div className="metaScore">
                                                                        <div className="chart bottomChart">
                                                                            <div className="axis horizontal" />
                                                                            <div className="axis vertical" />
                                                                            <span />
                                                                            <span className="section2">
                                                                                <span
                                                                                    className={c(
                                                                                        { negative: !positiveNps },
                                                                                        'capsule'
                                                                                    )}
                                                                                    style={{
                                                                                        width: `${
                                                                                            positiveNps
                                                                                                ? enps / 2
                                                                                                : -enps / 2
                                                                                        }%`,
                                                                                    }}
                                                                                >
                                                                                    {enps + '%'}
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    {showMetaChart && (
                        <MetaChartModal
                            metaData={metaData}
                            closeModal={toggleMetaDataPopup}
                            isPracticeMeta={isPracticeMeta}
                            totalSize={size.toLocaleString('en-US')}
                            defaultSettings={defaultSettings}
                            isEE
                        />
                    )}
                </div>

                {!apiLoadingCount && !errorInfo && (
                    <div className="note">
                        <b>{NOTE}:</b>
                        <br />
                        {IND_EXP_QUESTION_NOTE[0]}
                        <br />
                        {IND_EXP_QUESTION_NOTE[1]}
                        <br />
                        {surveyType === 'percent favorable' ? <div>3. {EE_PERCENT_FAVORABLE}</div> : ''}
                        {surveyType === 'percentile' ? <div>3. {EE_PERCENTILE}</div> : ''}
                    </div>
                )}
            </Fragment>
        </>
    );
}

IndExpScorecard.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    qbyqData: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    errorInfo: PropTypes.number.isRequired,
};

export default IndExpScorecard;
