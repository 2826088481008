import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import c from 'classnames';

import InclusionDemographics from '../../../components/FilterBar/InclusionDemographics';
import { getStaticText } from '../../../utils/constants';
import { DEMO_IMAGES } from '../constants';
import ImageModal from '../../../components/ImageModal';
import { scoreToDisplay } from '../../../utils/functions';
import viewIcon from '../assets/viewIcon.svg';

function OutcomeOptions({
    reportApiParams,
    goBack,
    submitReport,
    reportIndex,
    inclusionData,
    defaultSettings,
    setLastMenu,
}) {
    const { lang, inclusion_survey_type = '', ohi_text } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const isPulse = inclusion_survey_type === 'Pulse';
    const { OUTCOME_OPTIONS, VIEW_SAMPLE_SLIDE, BACK, NEXT, DYNAMIC_TEXT_INFO, OUTCOMES_OPTION_SUBHEADING } =
        SITE_TEXT || {};
    const { REPORT_TYPE } = DYNAMIC_TEXT_INFO({ ohi_text });
    const { outcomeDemographic = [] } = reportApiParams;
    const [modal, setModal] = useState(0);
    const [outcomeDemo, setOutcomeDemo] = useState([]);
    const [inactiveOption, setInactiveOption] = useState([]);

    const getLevelLength = (demo, indexParent) => {
        let arr = [];
        Object.keys(demo[indexParent].levelChild).forEach(val => {
            const filterSelectedChild = demo[indexParent].levelChild[val].filter(
                ({ selectedChild = false, meta_scores }) =>
                    selectedChild &&
                    meta_scores.filter(({ selectedChild: metaChild = false }) => metaChild).length === 2
            );
            arr = [...arr, ...filterSelectedChild];
        });
        return arr.length === 2;
    };

    const validateData = () => {
        const filterSelected = outcomeDemo.filter(({ isSelected = false }) => isSelected) || [];
        const finalValidArr = [];
        if (filterSelected.length > 0) {
            filterSelected.forEach(({ meta_scores }, index) => {
                const filterMeta = (meta_scores.filter(({ selectedChild }) => selectedChild) || []).length;
                const getLevel = getLevelLength(filterSelected, index);
                finalValidArr.push(filterMeta === 2 && getLevel);
            });
        }
        return !finalValidArr.includes(false);
    };

    const isActive = validateData();

    useEffect(() => {
        const incData = cloneDeep(inclusionData);
        const inactiveData = [];
        const inclusionArr = Object.keys(incData).map(val => {
            const { score = '', meta_scores = [] } = incData[val];
            const isNA = scoreToDisplay(score, meta_scores, isPulse) === '-';
            if (isNA) {
                inactiveData.push(val);
            }
            incData[val].key = val;
            Object.keys(incData[val].children).forEach(v => {
                incData[val].children[v].key = v;
            });
            return incData[val];
        });
        const sortParent = Object.values(inclusionArr).sort((a, b) => a.sort_order - b.sort_order);
        const sortChildren = sortParent.map(val => {
            val.c = Object.values(val.children).sort((a, b) => a.sort_order - b.sort_order);
            return val;
        });
        const filtered = sortChildren.filter(key => key.level !== '-');

        const formattedInclusionData =
            filtered.map(key => {
                key.levelChild = {};
                const { c: child = [], levelChild = [] } = key || {};
                child.forEach(subkey => {
                    const { level = '' } = subkey;
                    if (levelChild[level]) {
                        levelChild[level].push(subkey);
                    } else {
                        levelChild[level] = [];
                        levelChild[level].push(subkey);
                    }
                });
                return filtered;
            })[0] || {};

        setOutcomeDemo(formattedInclusionData);
        setInactiveOption(inactiveData);
        if (isActive) {
            setLastMenu(true);
        } else {
            setLastMenu(false);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (outcomeDemographic.length) {
            setOutcomeDemo(outcomeDemographic);
        }
    }, [outcomeDemographic]);

    const handleNext = () => {
        const filteredDemo = outcomeDemo.filter(({ isSelected = false }) => isSelected);
        const x = {};
        filteredDemo.forEach(({ key: parentKey, meta_scores, levelChild }) => {
            const metaString = meta_scores
                .filter(({ selectedChild = false }) => selectedChild)
                .map(({ meta }) => meta)
                .join(',');
            let practise_str = '$';
            Object.keys(levelChild).forEach(level => {
                levelChild[level].forEach(({ key, selectedChild, meta_scores: practise_meta_scores }) => {
                    if (selectedChild) {
                        const striii = practise_meta_scores
                            .filter(({ selectedChild: selectedPractice }) => selectedPractice)
                            .map(({ meta }) => meta)
                            .join(',');
                        practise_str += `practice:${key}|${striii}$`;
                    }
                });
            });
            const str = `outcome:${parentKey}|${metaString}${practise_str}`;
            x[parentKey] = str.substring(0, str.length - 1);
        });

        submitReport({
            outcomeDemographic: outcomeDemo,
            outcomePerception: Object.values(x).join('$$'),
        });
    };

    return (
        <section className="outcomeWrapper">
            <div>
                <h2>{REPORT_TYPE[reportIndex]}</h2>
                <div className="subHeading">
                    <div className="viewHeading">
                        <div>
                            {OUTCOME_OPTIONS}
                            {/* <span>(Can select upto 3 outcomes)</span> */}
                        </div>
                        <span className="sampleText" onClick={() => setModal(4)}>
                            <img src={viewIcon} alt="" />
                            {VIEW_SAMPLE_SLIDE}
                        </span>
                    </div>
                    <div className="subInfo">(Can select upto 3 outcomes)</div>
                    <div className="subInfo">{OUTCOMES_OPTION_SUBHEADING}</div>
                </div>
            </div>
            <div className="midWrapper rightSideFilters heatMapWrapper">
                <InclusionDemographics
                    defaultSettings={defaultSettings}
                    demographics={outcomeDemo}
                    updateDemographics={e => setOutcomeDemo(e)}
                    inactiveOption={inactiveOption}
                    isActive={isActive}
                />
            </div>
            <div className="buttonWrapperReports">
                <div className="outlineBtn" onClick={goBack}>
                    {BACK}
                </div>
                <div className={c('blueButton', { active: isActive })} onClick={handleNext}>
                    {NEXT}
                </div>
            </div>
            {modal > 0 ? (
                <ImageModal
                    defaultSettings={defaultSettings}
                    imageArr={DEMO_IMAGES.filter(({ modalNo }) => modalNo === modal)}
                    closeModal={() => setModal(0)}
                />
            ) : null}
        </section>
    );
}

OutcomeOptions.propTypes = {
    goBack: PropTypes.func.isRequired,
    submitReport: PropTypes.func.isRequired,
    reportIndex: PropTypes.number.isRequired,
    inclusionData: PropTypes.object.isRequired,
    reportApiParams: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    setLastMenu: PropTypes.func.isRequired,
};

export default OutcomeOptions;
