import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { Select, TextField, MenuItem } from '@material-ui/core';

import { getStaticText } from '../../../utils/constants';
import CircleAdd from '../assets/CircleAdd.svg';
import CircleClose from '../assets/CircleClose.svg';

function CompareReports({
    goBack,
    submitReport,
    reportApiParams,
    currentYear,
    currentOhid,
    defaultSettings,
    additionalSettingsLength,
}) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        BACK,
        NEXT,
        RESURVEY,
        NAME_COMPARISON,
        REMOVE,
        GIVE_NAME_PLACEHOLDER,
        COMPARE,
        ADD_ANOTHER_SURVEY,
        SELECT_COMPARE_YEAR,
        TO,
    } = SITE_TEXT || {};
    const { additionalSettings, compareReports: cmpReports } = reportApiParams;
    const [compareId, setCompareId] = useState([]);
    const [rightMenu, setRightMenu] = useState([]);
    const [compareReports, setCompareReports] = useState([{ comparisonName: '', from: '', to: '' }]);

    useEffect(() => {
        const surveryType = additionalSettings.map(
            ({ surveyType }) => `${surveyType.split('+')[0]}-${surveyType.split('+')[2]}`
        );
        surveryType.unshift(`${currentOhid}-${currentYear}`);
        setCompareId(surveryType);
        setRightMenu(surveryType);
        setCompareReports(cmpReports || compareReports);
        // eslint-disable-next-line
    }, []);

    const isActive = !compareReports.filter(({ comparisonName, from, to }) => !comparisonName.trim() || !from || !to)
        .length;
    const handleAddClick = () => {
        setCompareReports([...compareReports, { comparisonName: '', from: '', to: '' }]);
    };

    const handleCompareReports = ({ name, value }, index) => {
        const reports = [...compareReports];
        reports[index][name] = value;
        if (name === 'from') {
            reports[index].to = '';
        }
        setCompareReports(reports);
    };

    const handleNext = () => {
        submitReport({ compareReports });
    };

    const removeSection = index =>
        setCompareReports([...compareReports.slice(0, index), ...compareReports.slice(index + 1)]);

    const renderLeftSideOptions = index => {
        const { from = '' } = compareReports[0] || [];
        const excludeFrom = from.split('-')[0] || '';
        if (index === 1) {
            return compareId
                .filter(val => val.split('-')[0] !== excludeFrom)
                .map((val, i) => (
                    <MenuItem
                        data-testid="from-option"
                        classes={{ root: 'demographicOptions' }}
                        key={`${val}${i}`}
                        value={val}
                    >
                        {val}
                    </MenuItem>
                ));
        }
        return compareId.map((val, i) => (
            <MenuItem data-testid="from-option" classes={{ root: 'demographicOptions' }} key={`${val}${i}`} value={val}>
                {val}
            </MenuItem>
        ));
    };
    return (
        <section className="resurveySection" data-testid="compareReportComponent">
            <div className="resurverWrapper">
                <h2>{RESURVEY}</h2>

                {compareReports.map(({ comparisonName, from, to }, index) => (
                    <div key={index}>
                        <div className={c('subHeading', { separate: index > 0 })}>
                            <span>
                                {compareReports.length > 1 ? `Compare Reports - 0${index + 1}` : 'Compare Reports'}
                            </span>
                            {index > 0 ? (
                                <div className="remove" onClick={() => removeSection(index)} data-testid="rmvSctBtn">
                                    <div>
                                        <img src={CircleClose} alt="" />
                                    </div>
                                    <div>{REMOVE}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className="compareReportWrapper">
                            <div className="settingContent">
                                <span className="title">{NAME_COMPARISON}</span>
                                <TextField
                                    autoComplete="false"
                                    name="comparisonName"
                                    value={comparisonName}
                                    onChange={({ target }) => handleCompareReports(target, index)}
                                    placeholder={GIVE_NAME_PLACEHOLDER}
                                    inputProps={{
                                        'data-testid': `comparisonName${index}`,
                                    }}
                                />
                            </div>
                            <div className="content">
                                <div className="leftContent">
                                    <div className="title">{COMPARE}</div>
                                    <Select
                                        IconComponent={KeyboardArrowDownSharpIcon}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="from"
                                        data-testid="from"
                                        value={from}
                                        displayEmpty
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        onChange={({ target }) => handleCompareReports(target, index)}
                                    >
                                        <MenuItem classes={{ root: 'demographicOptions' }} value="">
                                            {SELECT_COMPARE_YEAR}
                                        </MenuItem>
                                        {renderLeftSideOptions()}
                                    </Select>
                                </div>
                                <div className="midContent">{TO}</div>
                                <div className="rightContent">
                                    <div className="title">{COMPARE}</div>
                                    <Select
                                        IconComponent={KeyboardArrowDownSharpIcon}
                                        labelId="demo-simple-select-label"
                                        disabled={!from}
                                        id="demo-simple-select"
                                        name="to"
                                        data-testid="to"
                                        value={to}
                                        displayEmpty
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        onChange={({ target }) => handleCompareReports(target, index)}
                                    >
                                        <MenuItem classes={{ root: 'demographicOptions' }} value="">
                                            {SELECT_COMPARE_YEAR}
                                        </MenuItem>
                                        {rightMenu
                                            .filter(val => val !== from)
                                            .map(val => (
                                                <MenuItem
                                                    data-testid="to-option"
                                                    classes={{ root: 'demographicOptions' }}
                                                    key={val}
                                                    value={val}
                                                >
                                                    {val}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

                {compareReports.length < additionalSettingsLength ? (
                    <div className="addSection" onClick={handleAddClick} data-testid="addSectionBtn">
                        <img src={CircleAdd} alt="" />
                        <div>{ADD_ANOTHER_SURVEY}</div>
                    </div>
                ) : null}
                <div className="buttonWrapperReports">
                    <div className="outlineBtn" onClick={goBack}>
                        {BACK}
                    </div>
                    <div className={c('blueButton', { active: isActive })} onClick={handleNext}>
                        {NEXT}
                    </div>
                </div>
            </div>
        </section>
    );
}

CompareReports.propTypes = {
    goBack: PropTypes.func.isRequired,
    submitReport: PropTypes.func.isRequired,
    reportApiParams: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    currentOhid: PropTypes.string.isRequired,
    currentYear: PropTypes.number.isRequired,
    additionalSettingsLength: PropTypes.number.isRequired,
};
export default CompareReports;
