import React from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { getStaticText } from '../../utils/constants';

export default function InfoModal({ setInfoModal }) {
    const staticText = getStaticText();
    const { SITE_TEXT } = staticText;
    const { INFO_MODAL_TEXT } = SITE_TEXT;
    return (
        <Dialog
            open
            disableBackdropClick
            maxWidth="sm"
            classes={{
                root: 'inclInfoDialogRoot',
            }}
        >
            <div className="loadingInfoContainer">
                <div className="top">
                    <IconButton size="small" aria-label="close" color="inherit" onClick={() => setInfoModal(false)}>
                        <Close fontSize="small" />
                    </IconButton>
                </div>
                <div className="bottom">
                    <div>
                        <h3>{INFO_MODAL_TEXT['1']}</h3>
                        <ul>
                            <li>{INFO_MODAL_TEXT['2']}</li>
                            <li>{INFO_MODAL_TEXT['3']} </li>
                            <li>{INFO_MODAL_TEXT['4']}</li>
                            <li>{INFO_MODAL_TEXT['5']}</li>
                        </ul>
                    </div>
                    <div>
                        <div>
                            <h3>{INFO_MODAL_TEXT['6']}</h3>
                            <div>
                                <ul>
                                    <li>{INFO_MODAL_TEXT['7']}</li>
                                    <li>{INFO_MODAL_TEXT['8']}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

InfoModal.propTypes = {
    setInfoModal: PropTypes.func.isRequired,
};
