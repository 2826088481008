import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import DifferentiatorBoard from './DifferentiatorBoard';
import {
    getRanking,
    getDifferential,
    setDifferentialData,
    getMedian,
    setMedianData,
    getDifferentialQuartile,
    showQuatileTabBtn,
    showMedianTabBtn,
    getDifferentialMedian,
} from '../actions';
import { getPptData } from '../../Diagnose/actions';

import {
    selectRankingData,
    selectRankingFetched,
    selectFiltersApplied,
    selectFilterParam,
    selectDifferentialData,
    selectDifferentialDataFetched,
    selectRankingError,
    selectDifferentialError,
    selectDataAtGlance,
    selectFilters,
    selectDefaultYear,
    selectMedianData,
    selectMedianDataFetched,
    selectShowQuartileBtn,
    selectShowMedianBtn,
    selectMedianError,
} from '../selector';

import { getDefaultSettings } from '../../Login/selector';

const stateToProps = createStructuredSelector({
    rankingData: selectRankingData(),
    rankingFetched: selectRankingFetched(),
    activeFilter: selectFiltersApplied(),
    defaultSettings: getDefaultSettings(),
    filterParams: selectFilterParam(),
    differentialData: selectDifferentialData(),
    differentialDataFetched: selectDifferentialDataFetched(),
    rankingError: selectRankingError(),
    differentialError: selectDifferentialError(),
    dataAtGlance: selectDataAtGlance(),
    filters: selectFilters(),
    defaultYear: selectDefaultYear(),
    medianData: selectMedianData(),
    medianDataFetched: selectMedianDataFetched(),
    showQuartileBtn: selectShowQuartileBtn(),
    showMedianBtn: selectShowMedianBtn(),
    medianError: selectMedianError(),
});

const dispatchToProps = {
    getRanking,
    getDifferential,
    setDifferentialData,
    getPptData,
    getMedian,
    setMedianData,
    getDifferentialQuartile,
    showQuatileTabBtn,
    showMedianTabBtn,
    getDifferentialMedian,
};

export default connect(stateToProps, dispatchToProps)(DifferentiatorBoard);
