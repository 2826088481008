import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import CallbackComponent from './CallbackComponent';

import { getAccessDetails } from '../../containers/Login/actions';
import { selectUserData } from '../../containers/Login/selector';

const dispatchToProps = {
    getAccessDetails,
};

const stateToProps = createStructuredSelector({
    userData: selectUserData(),
});

export default withRouter(connect(stateToProps, dispatchToProps)(CallbackComponent));
