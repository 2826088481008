export const getScoreColor = diff => {
    let color = '#ffffff';
    if (diff < -10) {
        color = '#FF7575';
    }
    if (diff >= -10 && diff <= -1) {
        color = '#FFD1D1';
    }
    if (diff >= 0 && diff <= 10) {
        color = '#CCF0BA';
    }
    if (diff > 10) {
        color = '#60C93F';
    }
    return { colorCode: color, difference: isNaN(diff) ? '-' : diff };
};

export const CustomTopFilters = [
    { name: 'Select BU', key: 'select_BU' },
    { name: 'Select Level 2', key: 'select_lvl_2' },
    { name: 'Select Level 3', key: 'select_lvl_3' },
    { name: 'Select Level 4', key: 'select_lvl_4' },
    { name: 'Select Level 5', key: 'select_lvl_5' },
    { name: 'Select Level 6', key: 'select_lvl_6' },
];
