import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { TextField, Select, MenuItem } from '@material-ui/core';

import c from 'classnames';
import Benchmarks from '../../../components/FilterBar/Benchmarks';
import CircleClose from '../assets/CircleClose.svg';
import { getStaticText } from '../../../utils/constants';

function AdditionalSurvey({
    benchState,
    additionalSettings,
    handleAdditionalChange,
    handleBenchState,
    reportName,
    surveyType,
    index,
    projects,
    removeSection,
    currentOhid,
    reportApiParams,
    defaultSettings,
    showBulk,
}) {
    const { options: opt } = reportApiParams;
    const { ReportType } = opt;
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        BENCHMARK_QUARTILE_COLOR,
        SUB_INFO_GENERAL,
        ADDITIONAL_SURVEY,
        PREVIOUS_SURVEY,
        REMOVE,
        ENTER_REPORT_NAME,
        SELECT_SURVEY,
        ENTER_NAME,
    } = SITE_TEXT || {};
    const indexToUse = index + 1;
    const benchArr = benchState[indexToUse] || [];

    const filterProject = React.useMemo(() => {
        const allSurveys = [currentOhid];
        additionalSettings.forEach(({ surveyType: selectedSurveyType }, mainIndex) => {
            if (mainIndex !== index && selectedSurveyType) {
                const excluseOhid = selectedSurveyType.split('+')[0] || '';
                if (excluseOhid) {
                    allSurveys.push(excluseOhid);
                }
            }
        });
        return projects.filter(({ ohid }) => !allSurveys.includes(ohid));
        // eslint-disable-next-line
    }, [additionalSettings, index]);

    const getProjectList = projectArr => {
        return projectArr.map(({ benchmark_version_id, ohid, year }) => {
            return (
                <MenuItem
                    data-testid="lang-option"
                    classes={{ root: 'demographicOptions' }}
                    key={ohid}
                    value={`${ohid}+${benchmark_version_id}+${year}`}
                >
                    {ohid} - {year}
                </MenuItem>
            );
        });
    };
    return (
        <section className="sectionContent">
            <div className="surveyHeading subHeading">
                <span>{ReportType === '3' ? ADDITIONAL_SURVEY : PREVIOUS_SURVEY}</span>
                {additionalSettings.length > 1 && (
                    <div className="remove" onClick={e => removeSection(e, index)}>
                        <div>
                            <img src={CircleClose} alt="" />
                        </div>
                        <div>{REMOVE}</div>
                    </div>
                )}
            </div>

            <div className="generalSettingWrapper">
                {!showBulk && (
                    <div className="settingContent">
                        <span className="title">{ENTER_REPORT_NAME}</span>
                        <TextField
                            autoComplete="false"
                            name="reportName"
                            value={reportName}
                            onChange={e => handleAdditionalChange(e, index)}
                            placeholder={ENTER_NAME}
                            className={c('surveyTypeContent', { demoSurveyContent: reportName })}
                            data-testid="reportName"
                        />
                    </div>
                )}
                <div className="settingContent">
                    <span className="title">{SELECT_SURVEY}</span>
                    <Select
                        IconComponent={KeyboardArrowDownSharpIcon}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="surveyType"
                        data-testid="surveyType"
                        value={surveyType}
                        className={c('surveyTypeContent', { demoSurveyContent: surveyType })}
                        displayEmpty
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                        onChange={e => handleAdditionalChange(e, index)}
                    >
                        <MenuItem value="" classes={{ root: 'demographicOptions' }}>
                            {SELECT_SURVEY}
                        </MenuItem>
                        {getProjectList(filterProject)}
                    </Select>
                </div>
            </div>

            {benchArr.length && surveyType && ReportType < 4 ? (
                <div className="gnLowerWrapper">
                    <div className="title">{BENCHMARK_QUARTILE_COLOR}</div>
                    <div className="subInfo">{SUB_INFO_GENERAL}</div>
                    <div className="rightSideFilters additionalSurvey">
                        <Benchmarks
                            defaultSettings={defaultSettings}
                            showHeader={false}
                            benchmarks={benchArr}
                            updateBenchmarks={updatedBenchmarks => handleBenchState(updatedBenchmarks, indexToUse)}
                        />
                    </div>
                </div>
            ) : null}
        </section>
    );
}

AdditionalSurvey.propTypes = {
    additionalSettings: PropTypes.array.isRequired,
    handleAdditionalChange: PropTypes.func.isRequired,
    handleBenchState: PropTypes.func.isRequired,
    reportName: PropTypes.string.isRequired,
    surveyType: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    projects: PropTypes.array.isRequired,
    benchState: PropTypes.array.isRequired,
    removeSection: PropTypes.func.isRequired,
    currentOhid: PropTypes.string.isRequired,
    reportApiParams: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    showBulk: PropTypes.bool.isRequired,
};
export default AdditionalSurvey;
