import AUTH0 from './auth_config';

const Endpoints = {
    apiBasePath: AUTH0.apiBasePath,
    pptBasePath: AUTH0.pptBasePath,
    redirectBaseUrl: AUTH0.redirectBaseUrl,
    gsodBaseUrl: AUTH0.gsodBaseUrl,
    pptAccessDetails: '/users/user-ppt-token',
    pptSavePath: '/generate-ppt',
    pdfSavePath: '/generate-ppt?file_type=pdf',
    pptResponsePath: '/update-responsecode',
    pptStatusPath: '/task-log',
    accessDetails: '/users/verify-auth-user',
    changePassword: '/users/change-user-password',
    userReportsPath: '/users/user-reports',
    userListPath: '/company/report-assigned-users',
    scoreDataPath: '/company/score/qbyq',
    filtersPath: '/company/filters',
    benchmarksPath: '/benchmarks',
    heatMapPath: '/company/heatmap',
    resurveyHeatMapPath: '/company/heatmap-resurvey',
    heatMapInclusionPath: '/inclusion/score/client/specific',
    rankingPath: '/company/ranking',
    rankingBuPath: '/company/ranking-bu',
    projectsPath: '/users/user-projects',
    reSurveyPath: '/company/score/resurvey',
    reportsPath: '/export/request',
    excelReportPath: '/company/score/qbyq-score-excel',
    respondentsPath: '/inclusion/demographics/breakdown',
    influencerExcelDwnldPath: '/influencer/api/utils/export_excel',
    dataCubePath: '/inclusion/data-cube',
    buPath: '/company/score/bu',
    exportPath: '/export',
    getRawPptPath: '/export/export-json',
    getExportStatusPath: '/export/export-status',
    toolBox: '/toolbox/pattern',
    settingsPath: '/users/settings/structure',
    updateSettingsPath: '/users/settings/add/update',
    defaultSettingsPath: '/users/settings/list',
    inclusionDataPath: '/inclusion/score/qbyq',
    incluisionGapPath: '/inclusion/score/qbyq/gap',
    allGapPath: '/inclusion/score/qbyq/gap/all',
    dailyResPath: '/response-rate/day-by-day',
    demoResPath: '/response-rate/demographic-wise',
    crossTapPath: '/response-rate/cross-tap',
    cohesionPath: '/company/cohesion-score',
    cohesionUserSettingsPath: '/company/cohesion-user-settings',
    valuesPath: '/company/values',
    textCommentsPath: '/company/openend-questions',
    actionFiltersPath: '/actions/action-filters-list',
    actionUsability: '/actions/action-usability',
    actionVote: '/actions/action-vote',
    addCompendium: '/actions/add-compendium',
    changeUserViewUrl: '/users/exporter-auth-details',
    resRateConfigPath: '/response-rate/config',
    mappedDemographicsPath: '/company/resurvey-heatmap-mapping',
    inclusionResurveyMapping: '/inclusion-resurvey/mapping',
    mappedDemo: '/inclusion-resurvey/mapping/resurvey-mapping',
    getResurveyMappingDownload: '/inclusion-resurvey/mapping/export-import',
    inclusionResGapPath: '/inclusion-resurvey/gap',
    ingNpsPath: '/company/heatmap-nps',
    ingEngagementPath: '/company/engagement-question-score',
    defaultBmYrPath: '/survey-bm-year/default-bm-year/',
    analyticsDataPath: '/overview/analytics-data',
    analyticsRankingPath: '/ranks/practice_rank/',
    analyticsPerCategoryPath: '/overview/analytics-per-category',
    filterDataPath: '/filters/',
    filterMappingPath: '/filters/filters-mapping/',
    bmFilterDataPath: '/filters/benchmarking-year',
    analyticsScorecardPath: '/score/ohi-scorecard/',
    singleQuestionScore: '/individual_exp/score/qbyq',
    tlxengagementstatsPath: '/company/engagement-stats',
    ieHeatmapPath: '/individual_exp/heatmap',
    ieCrossTabPath: '/individual_exp/crosstab',
    orgHealthPath: '/individual_exp/orghealth',
    rankHeatmapPath: '/company/rank-heatmap',
    feedbackPath: '/company/feedback',
    customBmPath: '/custom-benchmark',
    customBmListPath: '/custom-benchmark/my-custom-benchmark/',
    customBmSurveyListPath: '/custom-benchmark/survey-list/',
    customBmDetail: '/custom-benchmark/get-custom-benchmark-detail/',
    deleteCustomBm: '/custom-benchmark/delete-custom-benchmark/',
    editCustomBm: '/custom-benchmark/update-custom-benchmark/',
    checkCustomBmNameExist: '/custom-benchmark/check-name-exist/',
    sendCustomBmReq: '/custom-benchmark/send-request/',
    searchOhiIds: '/useradmin/survey-list',
    requestedBenchmark: '/custom-benchmark/all-custom-benchmarks/',
    approveReq: '/custom-benchmark/approve-request/',
    rejectReq: '/custom-benchmark/rejected-request/',
    duplicateBm: '/custom-benchmark/duplicate-benchmark/',
};

export default Endpoints;
