import React, { Fragment } from 'react';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import Close from '../../../assets/Close.svg';
import './index.scss';

const PublishReqModal = ({
    setView,
    approveRequest,
    customBmId,
    customBmName,
    creatorName,
    rejectRequest,
    setTab,
    setActionCode,
}) => {
    const publishRequest = () => {
        approveRequest(customBmId);
        setTab(1);
        setActionCode(1);
    };

    const rejectBmRequest = () => {
        rejectRequest(customBmId);
        setTab(2);
        setActionCode(2);
    };

    return (
        <Dialog
            open
            disableEscapeKeyDown
            disableBackdropClick
            maxWidth="md"
            classes={{
                root: 'publishDialogRoot',
            }}
        >
            <section className="infoContent">
                <div style={{ float: 'right', cursor: 'pointer' }} onClick={() => setView(false)}>
                    <img src={Close} alt="" />
                </div>
                <h2>Publish Request</h2>
                <div className="para">
                    <div>
                        You have a publish request for <b>{customBmName}</b> created by <b>{creatorName}</b>
                    </div>
                    <div>
                        The creator will receive an update on their email and a notification on the portal once you
                        publish the request.
                    </div>
                </div>
            </section>
            <section className="btnContent clearfix activeButtons multiBtn">
                <Fragment>
                    <div data-testid="detailButton" className="detailedBtn aciveDetailBtn" onClick={rejectBmRequest}>
                        Reject
                    </div>
                    <div data-testid="compareButton" className="compareBtn activeCompareBtn" onClick={publishRequest}>
                        Publish
                    </div>
                </Fragment>
            </section>
        </Dialog>
    );
};

PublishReqModal.propTypes = {
    setView: PropTypes.func.isRequired,
    customBmId: PropTypes.number.isRequired,
    customBmName: PropTypes.string.isRequired,
    creatorName: PropTypes.string.isRequired,
    approveRequest: PropTypes.func.isRequired,
    rejectRequest: PropTypes.func.isRequired,
    setActionCode: PropTypes.func.isRequired,
    setTab: PropTypes.func.isRequired,
};

export default PublishReqModal;
