import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Select, MenuItem, FormControlLabel } from '@material-ui/core';
import { ExpandMore, ArrowBack } from '@material-ui/icons';
import c from 'classnames';
import { Helmet } from 'react-helmet';

import AuthStore from '../../../common/AuthStore';
import FilterBar from '../../../components/FilterBar';
import FilterSvg from '../assets/Filter.svg';
import ActiveFilters1 from '../assets/ActiveFilters1.svg';
import Down from '../assets/Down.svg';
import CompareView from '../assets/CompareView.svg';
import comparisonBu from '../assets/comparisonBu.svg';
import BulletList from '../assets/BulletList.svg';
import colors from '../../../sass/colors';
import { getStaticText } from '../../../utils/constants';
import {
    addZeroToNUmber,
    sortArray,
    getBenchmarkName,
    getBenchmarkString,
    updateFilterParams,
    getPptValues,
    getFilterArray,
} from '../../../utils/functions';
import './index.scss';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import DwnldModal from '../../../components/InformationToast/DwnldModal';
import ResurveyBoard from './ResurveyBoard';
import SelectModal from '../../../components/SelectModal';
import PracticeBuBoard from './PracticeBuBoard';
import getStaticTextDiagnose from '../constants';

function PracticeBoard({
    practiceData,
    practiceDataFetched,
    getPracticeData,
    toggleFilterView,
    apiParams,
    apiLoadingCount,
    activeFilter,
    errorInfo,
    filters,
    getPptData,
    practiceToDisplay,
    setPracticeDisplay,
    defaultSettings,
    scoreData,
    scoreDataFetched,
    getScoreData,
    practiceFilters,
    getPracticeBuData,
    practiceBuData,
    practiceBuDataFetched,
    updateFilters,
    updatePracticeBuFilters,
    resetPracticeBuDataFetched,
    appliedFiltersInfo,
    getReport,
    getRawPptData,
    reportError,
    getSinglePastReport,
    singlePastReport,
    practiceDataCompYr,
    getComparisonYrPracticeData,
    projects,
    projectsFetched,
    getBuData,
    buData,
    buDataFetched,
    getDemographics,
    getBenchmarks,
}) {
    const { $white } = colors;
    const {
        benchmark = [],
        report_name_or_client_name: rName = '',
        report_name = '',
        lang,
        quartileColors = [],
        survey_version = '',
        report_id = '',
        checkme_mapping = {},
        demographic = '',
        ohid: currentOhid = '',
        resurvey_settings = '',
        report_type: surveyType = '',
        resurvey_custom_delta = '',
        engagement,
    } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { QUARTILE_RESURVEY } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const {
        SITE_TEXT,
        BPP_LIST,
        SINGLE_PAGE_EXPORT_PARAMS,
        RESURVEY_EXPORT_PARAMS,
        ING_REPORT_ID,
        NON_PROFIT_SURVEY,
    } = staticText;
    const isOhi4 = survey_version === '4' || survey_version === '3_2';
    const {
        PRACTICE_HEAD,
        FILTERS,
        TORNADO_LABEL,
        VS_LABEL,
        LEGEND,
        COMPARE_TO_RECIPE,
        RECIPE_TOP,
        UNDER_EMPHASIZED,
        HIGH_PRACTICES,
        DOWNLOAD,
        BU_HEAD,
        COMPARE_BU,
        BU_PROJECTS,
        SOURCE_TEXT,
        BENCHMARK_HEAD,
        NUMBER_TEXT,
        NUMBER_SURVEY_TEXT,
        ALL_PPT_TEXT,
        PPT_VALUES,
        NOTE,
        PRACTICE_RANKING_NOTE,
        RESURVEY_TEXT,
        HEALTH_PRACTICE_RANKING,
        BU_COMPARISION,
        NUMBER_OF_RESPONDENTS_N,
        SHOW_SCORES,
        TOP_RANKING,
        MIDDLE_RANKING,
        BOTTOM_RANKING,
        RANK,
        PRACTICES,
        PERCENT_FAVORABLE,
        PERCENTILES,
        COMPARISON_BUBBLES,
        RANKING,
    } = SITE_TEXT || {};
    const [activeIndex, updateIndex] = useState(0);
    const [currentHover, setHover] = useState('');
    const [tornado, setTornado] = useState(false);
    const [showBu, setBuView] = useState(false);
    const [showReSurveyModal, setReSurveyModal] = useState(false);
    const [showResurveyView, setResurveyView] = useState(false);
    const [compOhid, setCompOhid] = useState({ ohid: '', year: '' });
    const [reloadData, updateReloadState] = useState(true);
    const [reSurveyFilter, updateFilterView] = useState(false);
    const [itemsToIterate, setItemsToIterate] = useState([]);

    const [buPracticeData, setBuPracticeData] = useState([]);
    const [dwnld, setdwnld] = useState(false);
    const { ranking = [], nSize, n_respondents, n_survey } = practiceData;
    const { title = '', items: mainPracticeData = [] } = ranking[activeIndex] || {};
    const mainData = showBu ? practiceBuData : mainPracticeData;
    const topMainData = mainData.slice(0, 10);
    const { qbyq = {} } = scoreData;
    const { records = {} } = qbyq;
    const { ohi_score, ...dataToIterate } = records;
    const { ohid: oldId, year: oldYear, threshold } = apiParams;
    const { year: newYr = '' } = compOhid;
    const { pptAccessToken = '', practiceBU = '' } = AuthStore;
    const { Recipe_checkme_1, Recipe_checkme_second, practiceBoard } = PPT_VALUES({ rName });
    const { RANK_LABEL, RANK_VS_LABEL } = practiceBoard;
    const isV4 = survey_version === '4';
    const isV3 = survey_version === '3_2';
    const [QbyqArr, setQbyqArr] = useState([]);
    const { filters: filterCheck = [], report_type = '' } = apiParams || {};
    let count = -1;
    const [scoreTabChecked, setscoreTabChecked] = useState(false);
    const [currentView, setReport] = useState(0);
    const [sizeToUse, updateSize] = useState([0, 0]);
    const [surveyRespondents, updateSurveyRes] = useState([0, 0]);
    const [surveys, updateSurveys] = useState([0, 0]);
    const [projectInFilter, setProjectinFilter] = useState([]);
    const [showPracticeBu, setShowPracticeBu] = useState(false);
    const [isToggled, setToggled] = useState(false);
    const isIngClient = report_id === parseInt(ING_REPORT_ID, 10);
    const isNonProfitSurvey = engagement === NON_PROFIT_SURVEY;

    const handleChange = event => {
        setscoreTabChecked(event.target.checked);
    };
    function extractSlug2Values(inputArray) {
        return inputArray.map(item => item.slug2);
    }
    const slug2Array = extractSlug2Values(mainPracticeData);

    const projectsInUse = useMemo(() => {
        return projects.filter(({ ohid }) => ohid !== currentOhid);
        // eslint-disable-next-line
    }, [currentOhid, projects]);

    useEffect(() => {
        const data = [];
        Object.keys(dataToIterate).forEach(innerContent => {
            const { children = {} } = records[innerContent];
            Object.keys(children).forEach(childVal => {
                const { display_name, range_start, range_end, score, quartile } = children[childVal];
                data.push({
                    key: childVal,
                    display_name,
                    range_start,
                    range_end,
                    score,
                    quartileFlag: !!(quartile === '0' || quartile === '1'),
                    quartile,
                });
            });
        });
        const keyIndexMap = {};
        slug2Array.forEach((key, index) => {
            keyIndexMap[key] = index;
        });
        const sortedArr = [...data].sort((a, b) => keyIndexMap[a.key] - keyIndexMap[b.key]);
        setQbyqArr(sortedArr);
        // eslint-disable-next-line
    }, [scoreData]);

    useEffect(() => {
        const { ranking: oldRanking = [] } = practiceData;
        if (!practiceDataFetched && !apiLoadingCount && !showPracticeBu) {
            getPracticeData(apiParams);
            updateReloadState(true);
        }
        if (!scoreDataFetched && !apiLoadingCount && !showPracticeBu) {
            getScoreData(apiParams);
        }
        if (oldRanking.length && reloadData && practiceDataFetched && !showBu) {
            let newActiveIndex = -1;
            if (practiceToDisplay) {
                newActiveIndex = oldRanking.findIndex(({ title: oldTitle }) => oldTitle.includes(practiceToDisplay));
            }
            const indexToUse = newActiveIndex !== -1 ? newActiveIndex : activeIndex;
            const { items = [] } = oldRanking[indexToUse];
            if (tornado) {
                setItemsToIterate(sortArray(items, 'value'));
            } else {
                setItemsToIterate(items);
            }
            updateIndex(indexToUse);
            updateReloadState(false);
        }
        if (showBu) {
            if (tornado) {
                setItemsToIterate(sortArray(practiceBuData, 'value'));
            } else {
                setItemsToIterate(practiceBuData);
            }
            setResurveyView(false);
        }
        if (showPracticeBu) {
            if (tornado) {
                setBuPracticeData(sortArray(practiceBuData, 'value'));
            } else {
                setBuPracticeData(practiceBuData);
            }
            setResurveyView(false);
        }
        // eslint-disable-next-line
    }, [practiceDataFetched, scoreDataFetched, apiParams, practiceData, showBu, practiceBuData, showPracticeBu]);

    const buPayload = newFilters => {
        const filtersArray = [];
        const { demographics = [], benchmarks: oldBench = [] } = newFilters[0];
        const { demographics: buDemo = [] } = newFilters[1] || {};
        demographics.forEach(({ options = [], code: parentCode = '' }, indexInDemo) => {
            const optionsCode = options
                .filter(({ isSelected }) => isSelected)
                .reduce((pre, { code }) => [...pre, code], []);
            const { options: buOptions = [] } = buDemo[indexInDemo] || {};
            const buOptionsCode = buOptions
                .filter(({ isSelected }) => isSelected)
                .reduce((pre, { code }) => [...pre, code], []);
            const preStr = optionsCode.length ? `${parentCode}:${optionsCode.join(',')}` : '';
            const endStr = buOptionsCode.length ? `${parentCode}:${buOptionsCode.join(',')}` : '';
            if (preStr || endStr) {
                filtersArray.push(`${preStr}||${endStr}`);
            }
        });
        return {
            ohid: `${oldId},${oldId}`,
            lang: parseInt(lang, 10),
            year: oldYear,
            threshold,
            filters: filtersArray,
            benchmark_str: getBenchmarkString(oldBench),
        };
    };

    const fetchBuData = newFilters => {
        const payload = buPayload(newFilters);
        getPracticeBuData(payload);
        if (showPracticeBu) {
            getBuData(payload);
        }
    };

    useEffect(() => {
        if (!practiceBuDataFetched && !apiLoadingCount && (showPracticeBu || showBu)) {
            resetPracticeBuDataFetched(true);
            fetchBuData([filters[0], { demographics: practiceFilters }]);
        }
        // eslint-disable-next-line
    }, [practiceBuDataFetched, practiceFilters, apiParams, filters, apiLoadingCount]);

    const setFilters = (newFilters, filtersApplied) => {
        updateFilterView(false);
        const { demographics = [], benchmarks: oldBench = [] } = newFilters[0];
        const { demographics: newBuFilters = [] } = newFilters[1];
        const updatedParams = updateFilterParams(demographics, oldBench);
        updateFilters({ filters: [newFilters[0]], filtersApplied, apiParams: { ...apiParams, ...updatedParams } });
        updatePracticeBuFilters(newBuFilters);
        setItemsToIterate([]);
        setBuPracticeData([]);
        resetPracticeBuDataFetched(false);
    };

    const setResurveyFilters = (newFilters, filtersApplied) => {
        updateFilterView(false);
        const { demographics = [], benchmarks: oldBench = [] } = newFilters[0];
        const updatedParams = updateFilterParams(demographics, oldBench);
        const filtersToSend = currentView < 3 ? newFilters : [newFilters[0]];
        updateFilters({ filters: filtersToSend, filtersApplied, apiParams: { ...apiParams, ...updatedParams } });
    };

    useEffect(() => {
        if (currentView < 3 && Object.keys(practiceDataCompYr).length) {
            const { ohid: prevOhid = '' } = compOhid || {};
            const { nSize: nSizes = '', n_respondents: nRespondents = '', n_survey: nSurvey = '' } =
                practiceDataCompYr[prevOhid] || {};
            updateSize([nSize, nSizes]);
            updateSurveyRes([n_survey, nSurvey]);
            updateSurveys([n_respondents, nRespondents]);
        }
        // eslint-disable-next-line
    }, [practiceDataCompYr]);

    useEffect(() => {
        return () => {
            setPracticeDisplay('');
        };
        // eslint-disable-next-line
    }, []);

    const setNewData = ({ target }) => {
        const { value } = target;
        const { items = [] } = ranking[value];
        updateIndex(value);
        if (tornado) {
            setItemsToIterate(sortArray(items, 'value'));
        } else {
            setItemsToIterate(items);
        }
    };
    const sortChart = ({ checked }) => {
        setTornado(checked);
        const { items = [] } = ranking[activeIndex];
        const itmesToUse = showPracticeBu ? practiceBuData : items;
        if (checked) {
            const sortedArr = sortArray(itmesToUse, 'value');
            setBuPracticeData(sortedArr);
            setItemsToIterate(sortedArr);
        } else {
            setItemsToIterate(itmesToUse);
            setBuPracticeData(itmesToUse);
        }
    };

    const renderRow = ({
        index,
        display_name,
        background,
        color,
        styleBlack,
        quartile,
        score,
        topRanking,
        bottomRanking,
    }) => (
        <div
            className="tableRow"
            key={index}
            style={{
                // eslint-disable-next-line no-nested-ternary
                color: topRanking ? '#0679C3' : bottomRanking ? '#D51F31' : 'initial',
            }}
        >
            <span
                className="recipe"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // eslint-disable-next-line no-nested-ternary
                    color: topRanking ? '#0679C3' : bottomRanking ? '#D51F31' : 'initial',
                }}
            >
                <div
                    style={{
                        borderRadius: topRanking ? '50%' : 'initial',
                        background: topRanking ? '#F2F2F2' : 'initial',
                        fontWeight: topRanking ? '500' : 'initial',
                        padding: '0px 12px',
                    }}
                >
                    {index + 1}
                </div>
            </span>
            <span
                className="score "
                style={{
                    fontWeight: topRanking ? '500' : 'initial',
                }}
            >
                {display_name}
            </span>
            <span className="scoreTab">
                {scoreTabChecked && (
                    <span
                        className="scoreCount"
                        style={{
                            background,
                            color,
                            ...styleBlack,
                        }}
                    >
                        <div className={`${quartile === '0' ? 'triangle' : ''}`} />
                        <span>{score}</span>
                    </span>
                )}
            </span>
        </div>
    );

    const getDataForPpt = () => {
        const mTable = [];
        const BPPTable = [];
        const gTable = mainData.filter(({ value }) => value < -10).map(({ text2 }) => text2);
        const mTableList = [];
        const gTableList = [];
        const BPPList = [];

        topMainData.forEach(({ text1 }) => {
            if (!topMainData.some(({ text2: mainText }) => mainText === text1)) {
                mTable.push(text1);
            }
        });
        Object.keys(dataToIterate).forEach(innerContent => {
            const { children = {} } = records[innerContent];
            Object.keys(children).forEach(childVal => {
                const { display_name, quartile } = children[childVal];
                if (BPP_LIST[childVal] && quartile === '4') {
                    BPPTable.push(display_name);
                }
            });
        });

        mTable.forEach((mTableEle, row_index) => {
            const is_bold = gTable.includes(mTableEle);
            mTableList.push(
                {
                    row_index,
                    column_index: 0,
                    is_bold,
                    value: '*',
                },
                {
                    row_index,
                    column_index: 1,
                    is_bold,
                    value: mTableEle,
                }
            );
        });
        gTable.forEach((gTableEle, row_index) => {
            const is_bold = mTable.includes(gTableEle);
            gTableList.push(
                {
                    row_index,
                    column_index: 0,
                    is_bold,
                    value: '*',
                },
                {
                    row_index,
                    column_index: 1,
                    is_bold,
                    value: gTableEle,
                }
            );
        });
        BPPTable.forEach((BPPEle, row_index) => {
            BPPList.push(
                {
                    row_index,
                    column_index: 0,
                    is_bold: true,
                    value: '*',
                },
                {
                    row_index,
                    column_index: 1,
                    is_bold: true,
                    value: BPPEle,
                }
            );
        });
        const mTableListLen = mTable.length;
        const gTableListLen = gTable.length;
        const BPPTListLen = BPPTable.length;
        const { benchmarks = [] } = filters[0];
        const label = getBenchmarkName(benchmarks);
        const commonEle = [
            {
                name: 'source',
                value: `${SOURCE_TEXT} ${rName} (${NUMBER_TEXT}=${nSize}); ${BENCHMARK_HEAD}: ${label} (${NUMBER_TEXT}=${n_respondents}, ${NUMBER_SURVEY_TEXT}=${n_survey})`,
            },
            {
                name: 'Checkme',
                is_delete: 'true',
            },
        ];
        const pptArray = [
            {
                name: 'Title_text',
                value: title,
            },
            {
                name: 'companyshape1',
                value: rName,
            },
            {
                name: 'txttopc',
                value: HIGH_PRACTICES,
            },
            {
                name: 'txttopa',
                value: RECIPE_TOP,
            },
            {
                name: 'txttopb',
                value: COMPARE_TO_RECIPE,
            },
            {
                name: 'txt3rd',
                value: UNDER_EMPHASIZED,
            },
            ...getPptValues(Recipe_checkme_1),
        ];
        const recipeTopPratice = [];
        const clientTopPratice = [];
        const chart_data = [];

        mainData.forEach(({ text1 }, row_index) => {
            const is_bold = row_index < 10 ? 'true' : 'false';
            recipeTopPratice.push(
                {
                    row_index,
                    column_index: 0,
                    is_bold,
                    value: row_index + 1,
                },
                {
                    row_index,
                    column_index: 1,
                    is_bold,
                    value: text1,
                }
            );
        });
        itemsToIterate.forEach(({ text2, index, value }, row_index) => {
            const is_bold = topMainData.some(({ text1: mainText }) => mainText === text2) ? 'true' : 'false';
            const bgcolor = value < -10 ? colors.$red300 : colors.$grey700;
            clientTopPratice.push(
                {
                    row_index,
                    column_index: 0,
                    is_bold,
                    value: index,
                },
                {
                    row_index,
                    column_index: 1,
                    is_bold,
                    value: text2,
                }
            );
            chart_data.push({
                index: row_index,
                value,
                bgcolor,
            });
        });
        const output = {
            template_file_name: 'Template_All.pptx',
            output_file_name: 'practice.pptx',
            slides: [
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'Recipe_checkme_1',
                    is_copy: true,
                    is_delete: false,
                    data: [
                        ...commonEle,
                        ...pptArray,
                        {
                            name: 'recipeTopPratice_table',
                            is_delete: 'false',
                            table_data: recipeTopPratice,
                        },
                        {
                            name: 'ClientTopPratice_table',
                            is_delete: 'false',
                            table_data: clientTopPratice,
                        },
                        {
                            name: 'DifferenceCompanyRecipe_chart',
                            is_delete: 'false',
                            chart_data,
                        },
                    ],
                },
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'Recipe_checkme_1',
                    is_delete: true,
                },
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'Recipe_checkme_second',
                    is_copy: true,
                    is_delete: false,
                    data: [
                        ...commonEle,
                        ...getPptValues(Recipe_checkme_second),
                        {
                            name: 'Title_text',
                            value: `${ALL_PPT_TEXT[0]} ${title.substring(
                                title.lastIndexOf('(') + 1,
                                title.lastIndexOf(')')
                            )}`,
                        },
                        {
                            name: 'M_List',
                            is_delete: !!mTableListLen,
                            value: mTableListLen ? '' : ALL_PPT_TEXT[1],
                        },
                        {
                            name: 'M_Table',
                            is_delete: !mTableListLen,
                            table_data: mTableList,
                        },
                        {
                            name: 'BPP_List',
                            is_delete: !!BPPTListLen,
                            value: BPPTListLen ? '' : ALL_PPT_TEXT[2],
                        },
                        {
                            name: 'BPP_Table',
                            is_delete: !BPPTListLen,
                            table_data: BPPList,
                        },
                        {
                            name: 'G_List',
                            is_delete: !!gTableListLen,
                            value: gTableListLen ? '' : ALL_PPT_TEXT[3],
                        },
                        {
                            name: 'G_Table',
                            is_delete: !gTableListLen,
                            table_data: gTableList,
                        },
                    ],
                },
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'Recipe_checkme_second',
                    is_delete: true,
                },
            ],
        };
        getPptData(output, pptAccessToken, `${report_name} Practice_Recipe_${activeIndex + 1}`);
    };

    const getSinglePageMeta = () => {
        const { practice_ranking = [] } = checkme_mapping || {};
        practice_ranking.splice(1, 1);
        const defaultPage = practice_ranking;
        if (scoreTabChecked) return ['Client_practice_rank_score', 'Client_practice_rank_score_2'];
        if (showPracticeBu) return ['Recipe_checkme_bu_4_0'];
        if (showResurveyView) return ['Top_10_practice_over_time'];
        return defaultPage;
    };

    const v4PptDownload = () => {
        const addOns = {
            fill_threshold_with: demographic === '1' ? '-' : '',
            others: demographic === '3' ? 1 : 0,
        };
        const { benchmarks = [] } = filters[0];
        const label = getBenchmarkName(benchmarks);
        const valueToSend = {
            ...SINGLE_PAGE_EXPORT_PARAMS({ BENCHMARK_NAME: label }),
            ...addOns,
            report_name: rName,
            ...apiParams,
            report_id,
            single_page_meta: {
                checkme: getSinglePageMeta(),
                name: 'practice_ranking',
            },
            practice_rank_score_flag: !!scoreTabChecked,
        };
        setdwnld(true);
        getReport(valueToSend);
    };

    const getResurveyParams = newFilters => {
        const { ohid: newId = '' } = compOhid || {};
        const selectedOhiId = [oldId, newId];
        const selectedIds = selectedOhiId;
        const ohid = `${selectedOhiId.join(',')}`;
        const compare_ids = selectedOhiId.map(selectId => `${oldId}-${selectId}`);

        const filtersArray = [];
        const benchmarksArray = [];
        newFilters.forEach(({ benchmarks: newB = [], demographics: newD = [] }, indexToUse) => {
            let benchMarkYear = oldYear;
            let preString = '';
            let endString = '';

            if (indexToUse) {
                const projectIndex = selectedIds[indexToUse - 1];
                if (isNaN(Number(projectIndex))) return;
                const { year } = projectsInUse[projectIndex];
                benchMarkYear = year;
                preString = indexToUse === 1 ? '||' : '||||';
                endString = indexToUse === 1 && selectedIds.length === 2 ? '||' : '';
            } else {
                endString = selectedIds.length === 2 ? '||||' : '||';
            }
            const filtersObtained = getFilterArray(newD, preString, endString);
            filtersObtained.forEach(result => {
                filtersArray.push(result);
            });
            if (newB.length) {
                benchmarksArray.push(`${getBenchmarkString(newB)}@${benchMarkYear}`);
            }
        });
        return {
            ohid,
            filters: filtersArray,
            benchmark_str: benchmarksArray.join(','),
            compare_ids,
            report_type,
            comparison_type: resurvey_settings === QUARTILE_RESURVEY ? 2 : 1,
            lang: parseInt(lang, 10),
        };
    };

    const v4PptDownloadResurvey = () => {
        const { benchmark_str, ohid } = getResurveyParams(filters);
        const { bm_filters } = apiParams;
        const addOns = {
            fill_threshold_with: demographic === '1' ? '-' : '',
            others: demographic === '3' ? 1 : 0,
        };
        const { key: Resurvey_SigVsQuartile = '' } =
            COMPARISON_BUBBLES.find(({ list_key }) => list_key.toLowerCase() === resurvey_settings.toLowerCase()) || {};
        const resurveyParams = {
            ...RESURVEY_EXPORT_PARAMS,
            ...addOns,
            report_name: rName,
            options: {
                ...RESURVEY_EXPORT_PARAMS.options,
                survey_names: `${ohid.split(',').join('$#$')}`,
                Resurvey_SigVsQuartile,
                resurvey_custom_delta,
            },
            bm_filters,
            benchmarks: benchmark_str,
            threshold,
            report_id,
        };
        const valueToSend = {
            ...resurveyParams,
            ...getResurveyParams(filters),
            single_page_meta: {
                checkme: getSinglePageMeta(),
                name: 'scorecard',
            },
        };
        setdwnld(true);
        getReport(valueToSend);
    };

    const buName = AuthStore.practiceBU || 'Bu';
    const practiceReportName = AuthStore.practiceReportBU || 'Organization';

    const v4PptDownloadBu = () => {
        const addOns = {
            fill_threshold_with: demographic === '1' ? '-' : '',
            others: demographic === '3' ? 1 : 0,
        };
        const { benchmarks = [] } = filters[0];
        const label = getBenchmarkName(benchmarks);
        const valueToSend = {
            ...SINGLE_PAGE_EXPORT_PARAMS({ BENCHMARK_NAME: label }),
            ...addOns,
            // report_name: rName,
            report_name: practiceReportName,
            bu_comparison_names: buName,
            ...apiParams,
            ...buPayload([filters[0], { demographics: practiceFilters }]),
            need_tornado_chart: tornado,
            report_id,
            single_page_meta: {
                checkme: getSinglePageMeta(),
                name: 'practice_ranking',
            },
        };
        setdwnld(true);
        getReport(valueToSend);
    };

    const leftHeading = showBu ? practiceBU : RANK_LABEL;
    const rightHeading = showBu ? `${rName} ${VS_LABEL} ${practiceBU}` : `${RANK_VS_LABEL} ${title}`;

    const callReSurveyApi = (currentIds, indexToUse = 0) => {
        const { ohid: newId, year: newYear } = projectsInUse[currentIds];
        setProjectinFilter([`${oldId} - ${oldYear}`, `${newId} - ${newYear}`]);
        getDemographics({ ohid: newId, lang: parseInt(lang, 10) }, indexToUse + 1);
        getBenchmarks({ ohid: newId, year: newYear, lang }, indexToUse + 1, benchmark);
        AuthStore[`resurveyName${indexToUse + 1}`] = `${newId} (${newYear})`;
        // resetReSurveyDataFetched(false);
    };

    const selectProject = (ohId, reportType) => {
        const { ohid, year } = projectsInUse[ohId] || {};
        setReSurveyModal(false);
        setCompOhid({ ohid, year: parseInt(year, 10) });
        setResurveyView(true);
        // updateFilterView(true);
        callReSurveyApi(ohId);
        setReport(reportType);
    };

    const showbreadCrumb = showPracticeBu ? 'Bu Comparison' : BU_HEAD;

    return (
        <>
            <div className="practiceBoard clearfix">
                <Helmet>
                    <title>{HEALTH_PRACTICE_RANKING}</title>
                </Helmet>

                {(showBu || showPracticeBu || showResurveyView) && (
                    <div className="breadCrumb">
                        <span
                            data-testid="setScoreFromBuBtn"
                            onClick={() => {
                                setBuView(false);
                                setShowPracticeBu(false);
                                setResurveyView(false);
                                updateReloadState(true);
                            }}
                            className="practiceText"
                        >
                            {PRACTICE_HEAD}
                        </span>
                        <span className="practiceText">{' > '}</span>
                        <span className="breadCrumbText">{showResurveyView ? RESURVEY_TEXT : showbreadCrumb}</span>
                    </div>
                )}
                <h2 className="clearfix">
                    {showBu || showPracticeBu || showResurveyView ? (
                        <span
                            data-testid="setBackFromBuBtn"
                            onClick={() => {
                                setBuView(false);
                                setShowPracticeBu(false);
                                setResurveyView(false);
                                updateReloadState(true);
                            }}
                        >
                            <ArrowBack />
                            <span>
                                {showBu && <span>{PRACTICE_HEAD}</span>}
                                {showPracticeBu && <span>{BU_COMPARISION}</span>}
                                {showResurveyView && <span>{RESURVEY_TEXT}</span>}
                            </span>
                        </span>
                    ) : (
                        <div className="practiceHeading">
                            <div className="mainHeading">{PRACTICE_HEAD}</div>
                            <div className="nRes">
                                {NUMBER_OF_RESPONDENTS_N} : <span>{nSize ? nSize.toLocaleString('en-US') : ''}</span>
                            </div>
                        </div>
                    )}
                    <ul className="rightSideNav">
                        {/* {showPracticeBu && (
                        <li>
                            <Checkbox checked={scoreTabChecked} onChange={handleChange} />
                            <span>show scores</span>
                        </li>
                    )} */}

                        {showPracticeBu && isV4 ? (
                            <li className="showPracticeToradoChart">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={tornado}
                                            onChange={({ target }) => sortChart(target)}
                                            disabled={isToggled}
                                            name="tornadoCheck"
                                            inputProps={{
                                                'data-testid': 'tornadoCheck',
                                            }}
                                        />
                                    }
                                    label={TORNADO_LABEL}
                                />
                            </li>
                        ) : null}

                        {!errorInfo && (isV4 || showPracticeBu) && !showResurveyView && !isNonProfitSurvey ? (
                            <li>
                                <FormControlLabel
                                    control={<Checkbox checked={scoreTabChecked} onChange={handleChange} />}
                                    label={SHOW_SCORES}
                                />
                                <span className="lineHead" />
                            </li>
                        ) : null}
                        {!errorInfo && !isV4 ? (
                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={tornado}
                                            onChange={({ target }) => sortChart(target)}
                                            name="tornadoCheck"
                                            inputProps={{
                                                'data-testid': 'tornadoCheck',
                                            }}
                                        />
                                    }
                                    label={TORNADO_LABEL}
                                />
                            </li>
                        ) : null}
                        {!errorInfo && !showPracticeBu && isV4 ? (
                            <li
                                data-testid="setReSurveyModalBtn"
                                onClick={() => setReSurveyModal(true)}
                                className="fImage resurveyImg"
                            >
                                <img src={BulletList} alt="" />
                                <span className="upperHead">{RESURVEY_TEXT}</span>
                            </li>
                        ) : null}
                        {!showBu &&
                            (!isOhi4 ? (
                                <li
                                    data-testid="setBuReportBtn"
                                    onClick={() => {
                                        setItemsToIterate([]);
                                        setBuView(true);
                                        resetPracticeBuDataFetched(false);
                                    }}
                                >
                                    <img src={CompareView} alt="" />
                                    <span className="upperHead">{COMPARE_BU}</span>
                                </li>
                            ) : null)}

                        {(isV4 || isV3) && !showPracticeBu && !showResurveyView ? (
                            <li
                                data-testid="setBuReportBtn"
                                onClick={() => {
                                    // setItemsToIterate([]);
                                    setShowPracticeBu(true);
                                    resetPracticeBuDataFetched(false);
                                }}
                            >
                                <img src={comparisonBu} alt="" />
                                <span className="upperHead">{COMPARE_BU}</span>
                            </li>
                        ) : null}

                        {!errorInfo && !isIngClient && !isNonProfitSurvey && (
                            <li
                                data-testid="getPptBtn"
                                onClick={() => {
                                    if (isV4 || isV3) {
                                        if (showResurveyView) {
                                            v4PptDownloadResurvey();
                                        } else if (showPracticeBu) {
                                            v4PptDownloadBu();
                                        } else {
                                            v4PptDownload();
                                        }
                                    } else {
                                        getDataForPpt();
                                    }
                                }}
                            >
                                <img src={Down} alt="" />
                                <span className="lineHead upperHead">{DOWNLOAD}</span>
                            </li>
                        )}
                        <li
                            onClick={() => {
                                if (showBu || showPracticeBu || showResurveyView) {
                                    updateFilterView(true);
                                } else {
                                    toggleFilterView(true);
                                }
                            }}
                            data-testid="filterButton"
                            className="filterBtn"
                        >
                            <img
                                className="imgView"
                                src={activeFilter && filterCheck.length > 0 ? ActiveFilters1 : FilterSvg}
                                alt=""
                            />
                            <span className="filter">{FILTERS}</span>
                        </li>
                    </ul>
                </h2>
                {/* {appliedFiltersInfo()} */}
                {!showPracticeBu && !showResurveyView && appliedFiltersInfo()}
                {/* {showResurveyView && appliedFiltersInfo([oldYear, newYear])} */}
                {errorInfo && <ErrorToast message={errorInfo} />}
                {dwnld && !reportError && (
                    <DwnldModal
                        getRawPptData={getRawPptData}
                        reportId={report_id}
                        open={dwnld}
                        setOpen={setdwnld}
                        getSinglePastReport={getSinglePastReport}
                        singlePastReport={singlePastReport}
                        defaultSettings={defaultSettings}
                    />
                )}

                {mainData.length && !isV3 && !isV4 ? (
                    <section>
                        <section className="topBoard clearfix">
                            <div className="leftBoard">
                                {showBu ? (
                                    <span>{rName}</span>
                                ) : (
                                    <Select
                                        labelId="practiceOptions"
                                        value={activeIndex}
                                        onChange={setNewData}
                                        IconComponent={ExpandMore}
                                        data-testid="practiceOptions"
                                    >
                                        {ranking.map(({ title: selectOption }, menuIndex) => (
                                            <MenuItem
                                                key={selectOption}
                                                value={menuIndex}
                                                classes={{ root: 'practiceOptions' }}
                                            >
                                                {selectOption}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            </div>

                            <div className="rightBoard">
                                <div>{leftHeading}</div>
                                <div className="ellipsisPro">{rightHeading}</div>
                            </div>
                        </section>

                        <section className="rankBoard clearfix">
                            <div className="leftHead">
                                {mainData.map(({ text1 }, leftIndex) => (
                                    <div
                                        onMouseOver={() => setHover(text1)}
                                        onMouseOut={() => setHover('')}
                                        key={text1}
                                        className={c('ellipsisPro', {
                                            topRank: leftIndex < 10,
                                            hovered: text1 === currentHover,
                                        })}
                                    >
                                        <span>{addZeroToNUmber(leftIndex + 1)}</span> {text1}
                                    </div>
                                ))}
                            </div>
                            <div className="rightItems">
                                {itemsToIterate.map(({ text2, value, index }) => (
                                    <section className="clearfix" key={text2}>
                                        <div
                                            onMouseOver={() => setHover(text2)}
                                            onMouseOut={() => setHover('')}
                                            className={c('ellipsisPro', {
                                                topRank: topMainData.some(({ text1 }) => text1 === text2),
                                                hovered: text2 === currentHover,
                                            })}
                                        >
                                            <span>{addZeroToNUmber(index)}</span> {text2}
                                        </div>
                                        <div className="clearfix">
                                            <div className={c('rank', { negative: value < 0 })}>
                                                <div
                                                    className={c('textBorder', { emphasised: value < -10 })}
                                                    style={{
                                                        width: `${2 * Math.abs(value)}%`,
                                                    }}
                                                >
                                                    <div className="textRank">{value}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                ))}
                                <div className="rightHighLight">
                                    <div />
                                </div>
                            </div>
                        </section>

                        <ul className="legends clearfix">
                            <li>
                                {LEGEND} : <span className="colored">{HIGH_PRACTICES}</span>
                                <span className="recipe">{RECIPE_TOP}</span>
                            </li>
                            <li>
                                <span className="bullet" />
                                {UNDER_EMPHASIZED}
                            </li>
                            <li>
                                <span className="bullet" />
                                {COMPARE_TO_RECIPE}
                            </li>
                        </ul>
                    </section>
                ) : null}

                {mainData.length && isV4 && !showPracticeBu && !showResurveyView ? (
                    <section className="isV4">
                        <div
                            style={{ display: 'flex', justifyContent: 'space-between' }}
                            className="leftHead leftSection"
                        >
                            <div style={{ width: '32%' }}>
                                <div className="rank-head">
                                    <div className="topRanking">{TOP_RANKING}</div>
                                </div>
                                <div className="PracticeRankTable">
                                    <div className="tableHeading">
                                        <span className="recipe "> {RANK}</span>
                                        <span className="score "> {PRACTICES}</span>
                                        {scoreTabChecked && <span className="range">Score</span>}
                                    </div>

                                    {QbyqArr.map(
                                        ({
                                            display_name,
                                            key,
                                            score,
                                            range_end,
                                            range_start,
                                            quartileFlag,
                                            quartile,
                                        }) => {
                                            const { color, background = $white } = quartileColors[quartile] || {};
                                            const styleBlack =
                                                quartile === ('' || 999) ? { border: '2px solid #000000' } : {};
                                            const arr = slug2Array.slice(0, 12);
                                            if (arr.includes(key)) {
                                                count += 1;
                                                return renderRow({
                                                    index: count,
                                                    display_name,
                                                    background,
                                                    color,
                                                    styleBlack,
                                                    quartile,
                                                    score,
                                                    range_start,
                                                    range_end,
                                                    quartileFlag,
                                                    topRanking: true,
                                                });
                                            }

                                            return null;
                                        }
                                    )}
                                </div>
                            </div>
                            <div style={{ width: '32%' }}>
                                <div className="rank-head">
                                    <div className="middleRanking">{MIDDLE_RANKING}</div>
                                </div>
                                <div className="PracticeRankTable">
                                    <div className="tableHeading">
                                        <span className="recipe ">{RANK}</span>
                                        <div className="score ">{PRACTICES}</div>
                                        {scoreTabChecked && <span className="range">Score</span>}
                                    </div>
                                    {QbyqArr.map(
                                        ({
                                            key,
                                            display_name,
                                            score,
                                            range_end,
                                            range_start,
                                            quartileFlag,
                                            quartile,
                                        }) => {
                                            const { color, background = $white } = quartileColors[quartile] || {};
                                            const styleBlack =
                                                quartile === ('' || 999) ? { border: '2px solid #000000' } : {};
                                            const arr = slug2Array.slice(12, slug2Array.length - 12);
                                            if (arr.includes(key)) {
                                                count += 1;
                                                return renderRow({
                                                    index: count,
                                                    display_name,
                                                    background,
                                                    color,
                                                    styleBlack,
                                                    quartile,
                                                    score,
                                                    range_start,
                                                    range_end,
                                                    quartileFlag,
                                                });
                                            }

                                            return null;
                                        }
                                    )}
                                </div>
                            </div>
                            <div style={{ width: '32%' }}>
                                <div className="rank-head">
                                    <div className="bottomRanking">{BOTTOM_RANKING}</div>
                                </div>
                                <div className="PracticeRankTable">
                                    <div className="tableHeading">
                                        <span className="recipe ">{RANK}</span>
                                        <div className="score ">{PRACTICES}</div>
                                        {scoreTabChecked && <span className="range">Score</span>}
                                    </div>
                                    {QbyqArr.map(
                                        ({
                                            key,
                                            display_name,
                                            score,
                                            range_end,
                                            range_start,
                                            quartileFlag,
                                            quartile,
                                        }) => {
                                            const { color, background = $white } = quartileColors[quartile] || {};
                                            const styleBlack =
                                                quartile === ('' || 999) ? { border: '2px solid #000000' } : {};
                                            const arr = slug2Array.slice(slug2Array.length - 12);
                                            if (arr.includes(key)) {
                                                count += 1;
                                                return renderRow({
                                                    index: count,
                                                    display_name,
                                                    background,
                                                    color,
                                                    styleBlack,
                                                    quartile,
                                                    score,
                                                    range_start,
                                                    range_end,
                                                    quartileFlag,
                                                    bottomRanking: true,
                                                });
                                            }

                                            return null;
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* <ul className="fixedScoreCardSurvey">
                        <div className="rankingInfo">
                            <div className="sub-head">Ranking:</div>
                            <div className="topRank" /> {TOP_RANKING}
                            <div className="bottomRank" /> {BOTTOM_RANKING}
                        </div>
                    </ul>
                    {scoreTabChecked ? (
                        <div>
                            <ul className="benchmark clearfix fixedScoreCard">
                                <li>{BENCHMARK_HEAD} : </li>
                                {quartileColors
                                    .slice()
                                    .reverse()
                                    .map(({ title: label = '', background, border: borderColor }) => {
                                        const bottomDecileVar = label === 'Bottom decile';
                                        const BenchmarkTitle = label === 'No benchmarks';
                                        return (
                                            <li
                                                key={label}
                                                style={{ color: background }}
                                                className={c({
                                                    hide: bottomDecileVar || BenchmarkTitle,
                                                })}
                                            >
                                                <span
                                                    className="benchmarkTriangle"
                                                    style={{
                                                        background,
                                                        borderWidth: '13px 13px 0 0',
                                                        borderColor: bottomDecileVar
                                                            ? `transparent ${borderColor} transparent transparent`
                                                            : `${borderColor} transparent transparent transparent`,
                                                        borderStyle: 'solid',
                                                    }}
                                                />
                                                <div style={{ color: 'black', fontWeight: '400' }}>{label}</div>
                                            </li>
                                        );
                                    })}
                            </ul>
                            <div className="noteInfo">
                                <b>{NOTE} : </b>
                                <br />
                                1. {PRACTICE_RANKING_NOTE}
                                <br />
                                {surveyType === 'percent favorable' && <div>2. {PERCENT_FAVORABLE}</div>}
                                {surveyType === 'percentile' && <div>2. {PERCENTILES}</div>}
                            </div>
                        </div>
                    ) : null} */}
                        {}
                    </section>
                ) : null}

                {mainData.length && isV3 && !showPracticeBu && !showResurveyView ? (
                    <section>
                        <section className="topBoard clearfix topHead">
                            <div className="leftBoard newLeftBoard">
                                <div className="rankBoard">{RANK}</div>
                                <div className="secondHead">
                                    {showBu ? (
                                        <span>{rName}</span>
                                    ) : (
                                        <Select
                                            labelId="practiceOptions"
                                            value={activeIndex}
                                            onChange={setNewData}
                                            IconComponent={ExpandMore}
                                            data-testid="practiceOptions"
                                        >
                                            {ranking.map(({ title: selectOption }, menuIndex) => (
                                                <MenuItem
                                                    key={selectOption}
                                                    value={menuIndex}
                                                    classes={{ root: 'practiceOptions' }}
                                                >
                                                    {selectOption}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                </div>
                            </div>

                            <div className="rightBoard rightHead">
                                <div className="heading">{leftHeading}</div>
                                <div className="ellipsisPro" style={{ paddingLeft: '2vh' }}>
                                    {rightHeading}
                                </div>
                            </div>
                        </section>

                        <section className="rankBoard clearfix rankSection">
                            <div className="leftHead leftSection">
                                {mainData.map(({ text1 }, leftIndex) => (
                                    <div
                                        onMouseOver={() => setHover(text1)}
                                        onMouseOut={() => setHover('')}
                                        key={text1}
                                        className={c('ellipsisPro leftPro', {
                                            topRank: leftIndex < 10,
                                            hovered: text1 === currentHover,
                                        })}
                                    >
                                        <div className="leftContent">
                                            <div className="leftIndex">
                                                <div>{addZeroToNUmber(leftIndex + 1)}</div>
                                            </div>
                                            <div className="rightText">{text1}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="rightItems rightSection">
                                {itemsToIterate.map(({ text2, value }) => (
                                    <section className="clearfix itemSection" key={text2}>
                                        <div
                                            onMouseOver={() => setHover(text2)}
                                            onMouseOut={() => setHover('')}
                                            className={c('ellipsisPro linePro', {
                                                topRank: topMainData.some(({ text1 }) => text1 === text2),
                                                hovered: text2 === currentHover,
                                            })}
                                        >
                                            <div className="rightText">
                                                <div>{text2}</div>
                                            </div>
                                        </div>
                                        <div className="clearfix">
                                            <div className={c('rank rankContent', { negative: value < 0 })}>
                                                <div
                                                    className={c('textBorder', { emphasised: value < -10 })}
                                                    style={{
                                                        width: `${2 * Math.abs(value)}%`,
                                                    }}
                                                >
                                                    <div className="textRank">{value}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                ))}
                                <div className="rightHighLight">
                                    <div className="rankHighLight" />
                                </div>
                            </div>
                        </section>
                        <div>
                            <ul className="legends clearfix">
                                <li>
                                    {LEGEND} : <span className="colored">{HIGH_PRACTICES}</span>
                                    <span className="recipe">{RECIPE_TOP}</span>
                                </li>
                                <li>
                                    <span className="bullet" />
                                    {UNDER_EMPHASIZED}
                                </li>
                                <li>
                                    <span className="bullet" />
                                    {COMPARE_TO_RECIPE}
                                </li>
                            </ul>
                        </div>
                    </section>
                ) : null}

                {reSurveyFilter && (showPracticeBu || showBu) && !showResurveyView && (
                    <FilterBar
                        activeResetFilter={activeFilter}
                        size={[nSize, 0]}
                        surveyRespondents={[n_respondents, 0]}
                        surveys={[n_survey, 0]}
                        filters={[filters[0], { demographics: practiceFilters }]}
                        projectInFilter={BU_PROJECTS}
                        currentView={6}
                        showBenchmarks={false}
                        closeFilters={updateFilterView}
                        setFilters={setFilters}
                        defaultBenchmark={benchmark}
                        defaultSettings={defaultSettings}
                        years={[oldYear, oldYear]}
                    />
                )}
                {reSurveyFilter && showResurveyView && !(showPracticeBu || showBu) && (
                    <FilterBar
                        activeResetFilter={activeFilter}
                        size={sizeToUse}
                        surveyRespondents={surveyRespondents}
                        surveys={surveys}
                        filters={filters}
                        projectInFilter={projectInFilter}
                        currentView={currentView}
                        showBenchmarks
                        closeFilters={updateFilterView}
                        setFilters={setResurveyFilters}
                        defaultBenchmark={benchmark}
                        defaultSettings={defaultSettings}
                        years={[oldYear, newYr]}
                    />
                )}
                {showReSurveyModal && (
                    <SelectModal
                        defaultSettings={defaultSettings}
                        oldSelectedId={compOhid.ohid}
                        projects={projectsInUse}
                        projectsFetched={projectsFetched}
                        closeModal={setReSurveyModal}
                        selectProject={selectProject}
                        practiceRankModal
                    />
                )}
                {showPracticeBu ? (
                    <PracticeBuBoard
                        apiParams={apiParams}
                        defaultSettings={defaultSettings}
                        // practiceBuData={itemsToIterate}
                        practiceBuData={buPracticeData}
                        practiceBuDataFetched={practiceBuDataFetched}
                        buData={buData}
                        buDataFetched={buDataFetched}
                        isChecked={scoreTabChecked}
                        tornado={tornado}
                        isToggled={isToggled}
                        setToggled={setToggled}
                        practiceDataHere={practiceBuData}
                        updateFilters={updateFilters}
                        toggleFilterView={toggleFilterView}
                        // updateBuFilters={updateBuFilters}
                        resetPracticeBuDataFetched={resetPracticeBuDataFetched}
                        filters={[filters[0], { demographics: practiceFilters }]}
                        setFilters={setFilters}
                    />
                ) : null}
                {showResurveyView ? (
                    <ResurveyBoard
                        practiceDataCompYr={practiceDataCompYr}
                        getComparisonYrPracticeData={getComparisonYrPracticeData}
                        apiParams={apiParams}
                        compOhid={compOhid}
                        apiLoadingCount={apiLoadingCount}
                        practiceData={practiceData}
                        scoreData={scoreData}
                        defaultSettings={defaultSettings}
                        isChecked={scoreTabChecked}
                        filters={filters}
                        setFilters={setResurveyFilters}
                    />
                ) : null}
            </div>
            {/* new  */}
            <ul className="fixedScoreCardSurvey">
                <div className="rankingInfo">
                    <div className="sub-head">{RANKING}:</div>
                    <div className="topRank" /> {TOP_RANKING}
                    <div className="bottomRank" /> {BOTTOM_RANKING}
                </div>
            </ul>
            {scoreTabChecked ? (
                <div>
                    <ul className="benchmark clearfix fixedScoreCard">
                        <li>{BENCHMARK_HEAD} : </li>
                        {quartileColors
                            .slice()
                            .reverse()
                            .map(({ title: label = '', background, border: borderColor }) => {
                                const bottomDecileVar = label === 'Bottom decile';
                                const BenchmarkTitle = label === 'No benchmarks';
                                return (
                                    <li
                                        key={label}
                                        style={{ color: background }}
                                        className={c({
                                            hide: bottomDecileVar || BenchmarkTitle,
                                        })}
                                    >
                                        <span
                                            className="benchmarkTriangle"
                                            style={{
                                                background,
                                                borderWidth: '13px 13px 0 0',
                                                borderColor: bottomDecileVar
                                                    ? `transparent ${borderColor} transparent transparent`
                                                    : `${borderColor} transparent transparent transparent`,
                                                borderStyle: 'solid',
                                            }}
                                        />
                                        <div style={{ color: 'black', fontWeight: '400' }}>{label}</div>
                                    </li>
                                );
                            })}
                    </ul>
                    <div className="noteInfo">
                        <b>{NOTE} : </b>
                        <br />
                        1. {PRACTICE_RANKING_NOTE}
                        <br />
                        {surveyType === 'percent favorable' && <div>2. {PERCENT_FAVORABLE}</div>}
                        {surveyType === 'percentile' && <div>2. {PERCENTILES}</div>}
                    </div>
                </div>
            ) : null}
        </>
    );
}

PracticeBoard.propTypes = {
    filters: PropTypes.array.isRequired,
    errorInfo: PropTypes.string.isRequired,
    practiceToDisplay: PropTypes.string.isRequired,
    apiParams: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    practiceData: PropTypes.object.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    practiceDataFetched: PropTypes.bool.isRequired,
    practiceBuDataFetched: PropTypes.bool.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    scoreDataFetched: PropTypes.bool.isRequired,
    practiceFilters: PropTypes.array.isRequired,
    practiceBuData: PropTypes.array.isRequired,
    scoreData: PropTypes.object.isRequired,
    getScoreData: PropTypes.func.isRequired,
    getPracticeData: PropTypes.func.isRequired,
    getPracticeBuData: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    getPptData: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    updatePracticeBuFilters: PropTypes.func.isRequired,
    setPracticeDisplay: PropTypes.func.isRequired,
    resetPracticeBuDataFetched: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    reportError: PropTypes.string.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    singlePastReport: PropTypes.object.isRequired,
    getBuData: PropTypes.func.isRequired,
    buData: PropTypes.object.isRequired,
    buDataFetched: PropTypes.bool.isRequired,
    practiceDataCompYr: PropTypes.object.isRequired,
    getComparisonYrPracticeData: PropTypes.func.isRequired,
    projects: PropTypes.object.isRequired,
    projectsFetched: PropTypes.bool.isRequired,
    getDemographics: PropTypes.func.isRequired,
    getBenchmarks: PropTypes.func.isRequired,
};

export default PracticeBoard;
