import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Mid } from '@mid/sdk';

import AUTH0 from './auth_config';
import theme from './theme';
import App from './App';
import store from './store';
import './base.css';
import * as serviceWorker from './serviceWorker';

const mid = new Mid({
    redirectUrl: `${AUTH0.redirectBaseUrl}/auth/callback`,
    logoutRedirectUrl: `${AUTH0.redirectBaseUrl}/`,
    loginInfo: {
        appId: AUTH0.clientId,
        oidcConfigUrl: AUTH0.oidcConfigUrl,
        authDriver: 'mid',
    },
    async landingFn(): Promise<void> {
        window.location.hash = '';
    },
});

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme.mui}>
            <CssBaseline>
                <App mid={mid} />
            </CssBaseline>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);

if (module.hot) {
    module.hot.accept();
}

serviceWorker.unregister();
