import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, Grid, FormControl } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { decode } from 'html-entities';
import { cloneDeep } from 'lodash';
import Filtertable from './tableView';
import AuthStore from '../../../../common/AuthStore';
import { getStaticText } from '../../../../utils/constants';
import getStaticTextDiagnose from '../../constants';
import { getScoreColor, CustomTopFilters } from './utils';
import ErrorToast from '../../../../components/InformationToast/ErrorToast';
import './index.scss';

const selectDropdown = (filterData, buData, handleFilterChange, lvlFilterSelected, clientFilter) => {
    const { code = [] } = clientFilter;

    return filterData.map((item, index) => (
        <Grid item xs={3} sm={3} md={3}>
            <div className="custom-bu-rpt-dropdown clearfix" title={decode(lvlFilterSelected[index])}>
                <FormControl title={decode(lvlFilterSelected[index])} disabled={index < code.length}>
                    <Select
                        id="label"
                        value={lvlFilterSelected[index] || ''}
                        displayEmpty
                        renderValue={value => (value === lvlFilterSelected[index] ? decode(value) : decode(item.name))}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                            classes: { paper: 'demoDropdown' },
                        }}
                        onChange={(e, { props }) => handleFilterChange(e.target.value, index + 1, props)}
                    >
                        {buData[index]?.[index].map(buItems => {
                            return (
                                <MenuItem
                                    value={buItems.value}
                                    precode={buItems.precode}
                                    data={buItems.data}
                                    style={{ display: buItems?.label === 'precode' ? 'none' : 'flex' }}
                                >
                                    {buItems.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
        </Grid>
    ));
};

function CBUReports({
    getPptData,
    defaultSettings,
    scoreData,
    filterColumns,
    setfilterColumns,
    getCustomBuScoreData,
    langData,
    setlangData,
    langDataFetched,
    scoreDataFetched,
    getTlnxEngagementData,
    engagementData,
    buFilters,
    setBUFilters,
    errorInfo,
}) {
    const {
        lang,
        threshold = 10,
        year = 2023,
        ohid,
        benchmark,
        custom_static_json,
        template_name = '',
    } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { CUSTOM_BU_QUARTILE_COLORS, CUSTOM_BU_DIFF_COLORS } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;

    const {
        CUSTOM_BU_HEADING,
        NOTE,
        BENCHMARK_HEAD,
        DISCLAIMER_DEMO_PART1,
        DISCLAIMER_DEMO_PART2,
        SELECT_DOWNLOAD,
        DOWNLOAD_PDF,
        DOWNLOAD_PPT,
        COLOR_TEXT,
        GREEN_TEXT,
        BLUE_TEXT,
        LANGUAGE,
        ENGLISH_CBR,
        GERMAN_CBR,
        DIFF_HEAD,
        TO,
    } = SITE_TEXT || {};
    const { customBULanguage, isCBUblue } = AuthStore;
    const [filters, setFilters] = useState([]);
    const [iscolrBlue, setiscolrBlue] = useState(isCBUblue || 'true');
    const [bulang, setbuLang] = useState(Number(customBULanguage) || 1033);
    const [currFilterName, setcurrFilterName] = useState('');
    const [defaultCall, setDefaultCall] = useState(false);
    const [currFilterIndex, setcurrFilterIndex] = useState(0);
    const [currpreCode, setcurrpreCode] = useState([]);
    const [currpreCodePrev, setcurrpreCodePrev] = useState([]);
    const [dataSet, setdataSet] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [columns, setColumns] = useState({});
    const [snackbarOpen, setsnackbarOpen] = useState(false);
    const [downloadType, setdownloadType] = useState('');
    const [clientFilter, setclientFilter] = useState({});

    const { comparison_id, filter_key, additional } = JSON.parse(custom_static_json);
    const preFilters = template_name.includes('{') ? JSON.parse(template_name) : {};

    const removeFilterKeys = () => {
        const { label = [] } = preFilters || {};
        if (!label.length) return filter_key;
        const filterCopy = cloneDeep(filter_key);
        Object.keys(filterCopy).forEach(key => {
            if (!label.includes(key)) {
                delete filterCopy[key];
            }
        });
        return filterCopy;
    };

    const filterKeyToUse = removeFilterKeys();

    const payload = {
        ohid: comparison_id.ohi,
        filters: [],
        compare_ids: [],
        lang: bulang,
        report_type: '',
        benchmark_str: `1001:1@${year},1001:1@${comparison_id.year}`,
    };

    const getnewData = data => {
        const dataSet = [];
        for (const key in data) {
            const firstDataSet = data[key];
            const nextDataSet = Object.keys(firstDataSet).filter(item => item !== 'precode');
            dataSet.push({ label: decode(key), value: key, precode: firstDataSet.precode, data: nextDataSet });
        }
        return dataSet;
    };

    const getFilterData = (newarray, index = 0, prevFilters = []) => {
        let filterValues = [];
        let nextFilterData = [];
        const oldFilters = prevFilters.length ? prevFilters : buFilters;
        switch (index) {
            case 0:
                nextFilterData = getnewData(filterKeyToUse);
                filterValues = [{ 0: nextFilterData }];
                break;
            case 1:
                nextFilterData = getnewData(filterKeyToUse[newarray[0]]);
                filterValues = [{ 0: oldFilters[0][0] }, { 1: nextFilterData }];
                break;
            case 2:
                nextFilterData = getnewData(filterKeyToUse[newarray[0]][newarray[1]]);
                filterValues = [{ 0: oldFilters[0][0] }, { 1: oldFilters[1][1] }, { 2: nextFilterData }];
                break;
            case 3:
                nextFilterData = getnewData(filterKeyToUse[newarray[0]][newarray[1]][newarray[2]]);
                filterValues = [
                    { 0: oldFilters[0][0] },
                    { 1: oldFilters[1][1] },
                    { 2: oldFilters[2][2] },
                    { 3: nextFilterData },
                ];
                break;
            case 4:
                nextFilterData = getnewData(filterKeyToUse[newarray[0]][newarray[1]][newarray[2]][newarray[3]]);
                filterValues = [
                    { 0: oldFilters[0][0] },
                    { 1: oldFilters[1][1] },
                    { 2: oldFilters[2][2] },
                    { 3: oldFilters[3][3] },
                    { 4: nextFilterData },
                ];
                break;
            case 5:
                nextFilterData = getnewData(
                    filterKeyToUse[newarray[0]][newarray[1]][newarray[2]][newarray[3]][newarray[4]]
                );
                filterValues = [
                    { 0: oldFilters[0][0] },
                    { 1: oldFilters[1][1] },
                    { 2: oldFilters[2][2] },
                    { 3: oldFilters[3][3] },
                    { 4: oldFilters[4][4] },
                    { 5: nextFilterData },
                ];
                break;
            case 6:
                nextFilterData = getnewData(
                    filterKeyToUse[newarray[0]][newarray[1]][newarray[2]][newarray[3]][newarray[4]][newarray[5]]
                );
                filterValues = [
                    { 0: oldFilters[0][0] },
                    { 1: oldFilters[1][1] },
                    { 2: oldFilters[2][2] },
                    { 3: oldFilters[3][3] },
                    { 4: oldFilters[4][4] },
                    { 5: oldFilters[5][5] },
                    { 6: nextFilterData },
                ];
                break;
            default:
                break;
        }
        if (!prevFilters || !prevFilters.length) {
            setBUFilters([...filterValues]);
        }
        return [...filterValues];
    };

    const callEngagement = payloadObj => {
        getTlnxEngagementData(payloadObj);
    };

    const handleDefaultFilterChange = () => {
        const { code = [], label = [] } = preFilters || {};
        const payloadArr = [];
        let engagementFilters = [''];
        code.forEach((codeToUse, i) => {
            const currYear = codeToUse.map(innerCodes => {
                const curr = innerCodes.split(',')[0];
                return `demo_${curr.split('_')[0]}:${curr}`;
            });
            const prevYear = codeToUse.map(innerCodes => {
                const curr = innerCodes.split(',')[1];
                return `demo_${curr.split('_')[0]}:${curr}`;
            });
            const payloads = [];
            [0, 1].forEach((_, index) => {
                const payloadItem = {
                    ohid: !index ? ohid : comparison_id.ohi,
                    filters: !index ? currYear : prevYear,
                    lang: Number(lang),
                    year: !index ? Number(year) : comparison_id.year,
                    threshold: 10,
                    bm_filters: [benchmark[0]],
                    benchmarks: benchmark[0],
                };
                payloads.push(payloadItem);
            });
            engagementFilters = currYear[i];
            payloadArr.push({ payloads, level: i + 1 });
            setDefaultCall(true);
            setcurrFilterName(label[i]);
        });
        if (payloadArr.length === 1) {
            getCustomBuScoreData(payloadArr[0]);
        }
        if (payloadArr.length > 1) {
            getCustomBuScoreData({ payloads: payloadArr, calls: payloadArr.length - 1 });
        }
        let filterData = getFilterData();
        code.forEach((elem, i) => {
            const labelCopy = cloneDeep(label);
            filterData = getFilterData(labelCopy.slice(0, i + 1), i + 1, filterData);
        });
        if (code.length) {
            setFilters(label);
            const engagementPayload = {
                ohid,
                filters: [engagementFilters],
                label_names: `${additional[0].manager},${additional[1].executive}`,
            };
            callEngagement(engagementPayload);
        }
        setBUFilters(filterData);
    };

    const handletableData = language => {
        payload.lang = language;
        setlangData(payload);
    };

    useEffect(() => {
        handleDefaultFilterChange();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (Object.keys(preFilters).length) {
            setclientFilter(preFilters);
        }
        // eslint-disable-next-line
    }, [buFilters]);

    useEffect(() => {
        if (!langDataFetched) {
            handletableData(bulang);
        }
        // eslint-disable-next-line
    }, [langDataFetched]);

    useEffect(() => {
        if (scoreDataFetched && langDataFetched) {
            setdataSet([scoreData, langData[0]]);
        }
        // eslint-disable-next-line
    }, [scoreDataFetched, langDataFetched]);

    const handleCutomBULanguage = value => {
        AuthStore.customBULanguage = value;
        setbuLang(value);
        handletableData(value);
    };

    const handleCutomBUColor = value => {
        AuthStore.isCBUblue = value;
        setiscolrBlue(value);
    };

    const handlecolumnsData = () => {
        const arr = [];
        const data1 = columns[0].qbyq.records;
        const data2 = columns[1].qbyq.records;
        Object.keys(data1).forEach((item, key) => {
            arr.push(
                {
                    name: `TextBox_L1_${key + 1}`,
                    value: data1[item].score,
                    style: data1[item].quartile,
                    text_color: '#ffffff',
                    bgcolor:
                        CUSTOM_BU_QUARTILE_COLORS[data1[item].quartile][
                            iscolrBlue === 'true' ? 'color_bl' : 'color_gr'
                        ],
                    border_color: data1[item]?.quartile === '0' ? '#FF9900' : '',
                },
                {
                    name: `Oval_L1_${key + 1}`,
                    value: data1[item].score - data2[item].score,
                    text_color: '#000',
                    bgcolor: getScoreColor(data1[item].score - data2[item].score).colorCode,
                }
            );
        });
        return arr;
    };

    const handlefiltercolumnsData = () => {
        const arr = [];
        for (let index = 0; index < 6; index += 1) {
            arr.push(
                {
                    name: `Header_L${index + 2}`,
                    is_delete: !filters[index],
                    value: !filters[index] ? '-' : decode(filters[index]),
                    existing_value: `[bu${index + 2}]`,
                },
                {
                    name: `Header_L${index + 2}`,
                    is_delete: !filters[index],
                    value: !filters[index] ? '-' : filterColumns[`level${index + 1}`][0]?.size,
                    existing_value: `[n${index + 2}]`,
                }
            );
            Object.keys(filterColumns[`level${index + 1}`]?.[0]?.records || {}).forEach((subItem, key) => {
                arr.push(
                    {
                        name: `TextBox_L${index + 2}_${key + 1}`,
                        value: filterColumns[`level${index + 1}`][0]?.records[subItem]?.score || '-',
                        style: filterColumns[`level${index + 1}`][0]?.records[subItem]?.quartile || '-',
                        text_color: '#ffffff',
                        border_color:
                            filterColumns[`level${index + 1}`][0]?.records[subItem]?.quartile === '0' ? '#FF9900' : '',
                        bgcolor:
                            CUSTOM_BU_QUARTILE_COLORS[
                                filterColumns[`level${index + 1}`][0]?.records[subItem]?.quartile
                            ][iscolrBlue === 'true' ? 'color_bl' : 'color_gr'],
                    },
                    {
                        name: `Oval_L${index + 2}_${key + 1}`,
                        value: Object.keys(filterColumns[`level${index + 1}`][1])?.length
                            ? filterColumns[`level${index + 1}`][0]?.records[subItem]?.score -
                              filterColumns[`level${index + 1}`][1]?.records[subItem]?.score
                            : '-',
                        style: Object.keys(filterColumns[`level${index + 1}`][1])?.length
                            ? filterColumns[`level${index + 1}`][0]?.records[subItem]?.score -
                              filterColumns[`level${index + 1}`][1]?.records[subItem]?.score
                            : 5,
                        text_color: '#000',
                        bgcolor: Object.keys(filterColumns[`level${index + 1}`][1])?.length
                            ? getScoreColor(
                                  filterColumns[`level${index + 1}`][0]?.records[subItem]?.score -
                                      filterColumns[`level${index + 1}`][1]?.records[subItem]?.score
                              ).colorCode
                            : '#efefef',
                    }
                );
            });
        }
        for (let i = Object.keys(filterColumns).length; i <= 6; i++) {
            arr.push({
                name:
                    i === 5
                        ? 'Group1g'
                        : i === 4
                        ? 'Group1f'
                        : i === 3
                        ? 'Group1e'
                        : i === 2
                        ? 'Group1d'
                        : i === 1
                        ? 'Group1c'
                        : i === 0
                        ? 'Group1b'
                        : 'Group1y',
                is_delete: true,
            });
        }
        return arr;
    };

    const getqbyqSlide = keys => {
        const objects = langData[0]?.qbyq?.records;
        const data = [];
        keys.forEach((item, count) => {
            objects[item].meta_scores.map((subitem, key) =>
                data.push({
                    name: `qbyq_${subitem.meta}`,
                    value: subitem.qtext,
                })
            );
            data.push(
                {
                    name: `year_${count + 1}a`,
                    value: comparison_id.year,
                },
                {
                    name: `year_${count + 1}b`,
                    value: year,
                }
            );
        });
        return data;
    };

    const getChartDataSlide = keys => {
        const datakey = filterColumns[Object.keys(filterColumns)[Object.keys(filterColumns).length - 1]];
        const chart_data_cur = [];
        const chart_data_prev = [];
        const chart_data_delete = [];
        const curr_filter_data = [];
        const diff_filter_data = [];
        const year_data = [];
        for (let i = 0; i < 2; i++) {
            keys.forEach((item, count) => {
                if (Object.keys(datakey[i])?.length > 0) {
                    datakey[i].records[item].meta_scores.forEach((subitem, scorekey) => {
                        const scores = [
                            {
                                series: 0,
                                index: 0,
                                value: subitem.bottom2_score ? subitem.bottom2_score : '',
                                label: subitem.bottom2_score ? subitem.bottom2_score : '',
                                row_index: 1,
                                column_index: 0,
                            },
                            {
                                series: 1,
                                index: 0,
                                value: subitem.nuetral_score ? subitem.nuetral_score : '',
                                label: subitem.nuetral_score ? subitem.nuetral_score : '',
                                row_index: 1,
                                column_index: 1,
                            },
                            {
                                series: 2,
                                index: 0,
                                value: subitem.score ? subitem.score : '',
                                label: subitem.score ? subitem.score : '',
                                row_index: 1,
                                column_index: 2,
                            },
                        ];

                        i === 0
                            ? chart_data_cur.push({
                                  name: `Chartb_${subitem.meta}`,
                                  chart_data: scores,
                              })
                            : chart_data_prev.push({
                                  name: `Charta_${subitem.meta}`,
                                  chart_data: scores,
                              });
                    });
                }
                if (Object.keys(datakey[1])?.length === 0) {
                    datakey[0].records[item].meta_scores.forEach((subitem, scorekey) => {
                        const scores = [
                            {
                                series: 0,
                                index: 0,
                                value: '-',
                                label: '-',
                                row_index: 1,
                                column_index: 0,
                            },
                            {
                                series: 1,
                                index: 0,
                                value: '-',
                                label: '-',
                                row_index: 1,
                                column_index: 1,
                            },
                            {
                                series: 2,
                                index: 0,
                                value: '-',
                                label: '-',
                                row_index: 1,
                                column_index: 2,
                            },
                        ];

                        chart_data_prev.push({
                            name: `Charta_${subitem.meta}`,
                            chart_data: scores,
                        });
                    });
                }
                if (i === 0) {
                    curr_filter_data.push({
                        name: `APP${count + 1}b`,
                        value: datakey[i].records[item].score,
                        text_color: '#ffffff',
                        bgcolor:
                            CUSTOM_BU_QUARTILE_COLORS[datakey[i].records[item]?.quartile][
                                iscolrBlue === 'true' ? 'color_bl' : 'color_gr'
                            ],
                        border_color: datakey[i].records[item]?.quartile === '0' ? '#FF9900' : '',
                    });
                    diff_filter_data.push({
                        name: `Oval${count + 1}`,
                        value: Object.keys(datakey[1])?.length
                            ? datakey[i].records[item].score - datakey[1].records[item].score
                            : '-',
                        text_color: '#000',
                        bgcolor: Object.keys(datakey[1])?.length
                            ? getScoreColor(datakey[i].records[item]?.score - datakey[1].records[item]?.score).colorCode
                            : '#efefef',
                    });
                } else {
                    const { records = {} } = datakey[i] || {};
                    const { score: prevScore = '', quartile: preQuart = '' } = records[item] || {};
                    curr_filter_data.push({
                        name: `APP${count + 1}a`,
                        value: Object.keys(datakey[1])?.length ? prevScore : '-',
                        text_color: '#ffffff',
                        bgcolor:
                            preQuart !== ''
                                ? CUSTOM_BU_QUARTILE_COLORS[preQuart][iscolrBlue === 'true' ? 'color_bl' : 'color_gr']
                                : '#ffffff',
                        border_color: Object.keys(datakey[1])?.length ? (preQuart === '0' ? '#FF9900' : '') : '',
                    });
                }

                year_data.push(
                    {
                        name: `previous_year_${count + 1}`,
                        value: comparison_id.year,
                    },
                    {
                        name: `current_year_${count + 1}`,
                        value: year,
                    }
                );
            });
        }
        return [chart_data_delete, chart_data_prev, chart_data_cur, curr_filter_data, diff_filter_data, year_data];
    };

    const handlepptFilterSummary = () => {
        const arr = [];
        for (let index = 0; index < 6; index++) {
            arr.push(
                {
                    name: `level${index + 1}_2021`,
                    value: !filters[index] ? '-' : filters[index],
                },
                {
                    name: `level${index + 1}_2020`,
                    value: !filters[index] ? '-' : filters[index],
                }
            );
        }
        return arr;
    };
    const getEnagementDataSlide = () => {
        const engData = [];
        const data1 = engagementData.slice(0, 3);
        const data2 = engagementData.slice(3, 6);
        const diff_data = [];

        data1.map((item, count) =>
            engData.push({
                name: `Chart${count + 1}a`,
                chart_data: [
                    {
                        index: 0,
                        value: item.top2_score ? item.top2_score : '',
                        label: item.top2_score ? item.top2_score : '',
                        row_index: 1,
                        column_index: 0,
                    },
                    {
                        series: 1,
                        index: 0,
                        value: item.nuetral_score ? Number(item.nuetral_score * -1) : '',
                        label: item.nuetral_score ? item.nuetral_score : '',
                        row_index: 1,
                        column_index: 1,
                    },
                    {
                        series: 3,
                        index: 0,
                        value: item.bottom2_score ? Number(item.bottom2_score * -1) : '',
                        label: item.bottom2_score ? item.bottom2_score : '',
                        row_index: 1,
                        column_index: 2,
                    },
                ],
                meta_data: {
                    max_bound: item ? item.top2_score : 0,
                    min_bound: -(item ? item.nuetral_score + item.bottom2_score : 0),
                },
            })
        );
        data2.map((item, count) =>
            engData.push({
                name: `Chart${count + 1}b`,
                chart_data: [
                    {
                        index: 0,
                        value: item.top2_score ? item.top2_score : '',
                        label: item.top2_score ? item.top2_score : '',
                        row_index: 1,
                        column_index: 0,
                    },
                    {
                        series: 1,
                        index: 0,
                        value: item.nuetral_score ? Number(item.nuetral_score * -1) : '',
                        label: item.nuetral_score ? item.nuetral_score : '',
                        row_index: 1,
                        column_index: 1,
                    },
                    {
                        series: 3,
                        index: 0,
                        value: item.bottom2_score ? Number(item.bottom2_score * -1) : '',
                        label: item.bottom2_score ? item.bottom2_score : '',
                        row_index: 1,
                        column_index: 2,
                    },
                ],
                meta_data: {
                    max_bound: item ? item.top2_score : 0,
                    min_bound: -(item ? item.nuetral_score + item.bottom2_score : 0),
                },
            })
        );

        for (let elem = 0; elem < 3; elem += 1) {
            diff_data.push({
                index: elem,
                value: data1[elem].top2_score - data2[elem].top2_score,
                row_index: elem + 1,
                column_index: 0,
                bgcolor: getScoreColor(data1[elem].top2_score - data2[elem].top2_score).colorCode,
                text_color: '#000000',
            });
        }
        engData.push({
            name: 'benchmark2_chart',
            chart_data: diff_data,
        });
        return engData;
    };

    const handleClose = () => {
        setsnackbarOpen(false);
    };

    const handlePptDownload = downloadtype => {
        const { pptAccessToken = '' } = AuthStore;
        setisLoading(true);
        const level1 = handlecolumnsData();
        const otherCols = handlefiltercolumnsData();
        const filterSummary = handlepptFilterSummary();
        const qbyq1 = getqbyqSlide(['direction', 'leadership', 'work_environment']);
        const qbyq2 = getqbyqSlide(['accountability', 'coordination_control', 'capabilities', 'motivation']);
        const qbyq3 = getqbyqSlide(['innovation_learning', 'external_orientation']);
        const chart1 = getChartDataSlide(['direction', 'leadership', 'work_environment']);
        const chart2 = getChartDataSlide(['accountability', 'coordination_control', 'capabilities', 'motivation']);
        const chart3 = getChartDataSlide(['innovation_learning', 'external_orientation']);
        const qbyq4 = getEnagementDataSlide();
        const qbyqSummary = `Source: Talanx ${year} OHC (N = ${columns[0]?.qbyq?.size}); Talanx ${comparison_id.year} OHC (N = ${columns[1]?.qbyq?.size});`;
        const slideSummary = `Source: Talanx ${year} OHC (N = ${columns[0]?.qbyq?.size}); Talanx ${comparison_id.year} OHC (N = ${columns[1]?.qbyq?.size}); Benchmark: Global ${year} (n = ${columns[0]?.qbyq?.n_respondents}, no.surveys = ${columns[0]?.qbyq?.n_survey}); Benchmark Global ${comparison_id.year} (n = ${columns[1]?.qbyq?.n_respondents}, no.surveys = ${columns[1]?.qbyq?.n_survey});`;
        const qbyq4SlideSummary = `Source: Talanx ${year} OHC (N = ${columns[0]?.qbyq?.size})`;
        const file_name = `2022_Talanx_Custom_Template_${iscolrBlue === 'true' ? 'BL' : 'GN'}_${
            bulang === 1033 ? 2057 : bulang
        }.pptx`;
        // TO DO: filters[0] === 'Hannover Re' ? '2023_Talanx_HR_Template_BL_2057.pptx' : file_name
        const output_file_name = `${decode(currFilterName).replaceAll('/', '')}.pptx`;
        const output = {
            template_file_name:
                filters[0] === 'Hannover Re'
                    ? `2023_Talanx_HR_Template_BL_${bulang === 1033 ? 2057 : bulang}.pptx`
                    : file_name,
            output_file_name,
            slides: [
                {
                    identify_shape_name: 'checkme',
                    identify_shape_value: 'landing_page',
                    data: [
                        {
                            name: 'checkme',
                            is_delete: true,
                        },
                        {
                            name: 'bu_title',
                            value: `${decode(currFilterName)} \n ${year} `,
                        },
                        {
                            name: 'Titel 1',
                            value: `${year}`,
                            existing_value: '2022',
                        },
                    ],
                },
                {
                    identify_shape_name: 'checkme',
                    identify_shape_value: 'Selected_unit',
                    data: [
                        {
                            name: 'checkme',
                            is_delete: true,
                        },
                        {
                            name: 'Title',
                            value: decode(currFilterName),
                            existing_value: 'bu_name',
                        },
                        {
                            name: 'Header_L1',
                            value: columns[0]?.qbyq?.size,
                            existing_value: '[n1]',
                        },
                        {
                            name: '5. Source',
                            value: slideSummary,
                        },

                        {
                            name: 'diff_year',
                            value: `${comparison_id.year}`,
                            existing_value: 'xx',
                        },
                        ...level1,
                        ...otherCols,
                    ],
                },
                {
                    identify_shape_name: 'checkme',
                    identify_shape_value: 'filter_summary',
                    data: [
                        {
                            name: 'checkme',
                            is_delete: true,
                        },
                        {
                            name: `previous_year_1`,
                            value: comparison_id.year,
                        },
                        {
                            name: `current_year_1`,
                            value: year,
                        },
                        ...filterSummary,
                    ],
                },
                {
                    identify_shape_name: 'checkme',
                    identify_shape_value: 'qbyq1',
                    data: [
                        {
                            name: '5. Source',
                            value: qbyqSummary,
                        },
                        {
                            name: 'checkme',
                            is_delete: true,
                        },
                        {
                            name: 'Title 1',
                            value: decode(currFilterName),
                            existing_value: 'bu_name',
                        },
                        {
                            name: 'previous_year',
                            value: comparison_id.year,
                        },
                        {
                            name: 'diff_year',
                            value: `${comparison_id.year}`,
                            existing_value: 'xx',
                        },
                        {
                            name: 'current_year',
                            value: year,
                        },
                        ...qbyq1,
                        ...chart1[0],
                        ...chart1[1],
                        ...chart1[2],
                        ...chart1[3],
                        ...chart1[4],
                        ...chart1[5],
                    ],
                },
                {
                    identify_shape_name: 'checkme',
                    identify_shape_value: 'qbyq2',
                    data: [
                        {
                            name: '5. Source',
                            value: qbyqSummary,
                        },
                        {
                            name: 'checkme',
                            is_delete: true,
                        },
                        {
                            name: 'Title 1',
                            value: decode(currFilterName),
                            existing_value: 'bu_name',
                        },
                        {
                            name: 'previous_year',
                            value: comparison_id.year,
                        },
                        {
                            name: 'diff_year',
                            value: `${comparison_id.year}`,
                            existing_value: 'xx',
                        },
                        {
                            name: 'current_year',
                            value: year,
                        },
                        ...qbyq2,
                        ...chart2[0],
                        ...chart2[1],
                        ...chart2[2],
                        ...chart2[3],
                        ...chart2[4],
                        ...chart2[5],
                    ],
                },
                {
                    identify_shape_name: 'checkme',
                    identify_shape_value: 'qbyq3',
                    data: [
                        {
                            name: '5. Source',
                            value: qbyqSummary,
                        },
                        {
                            name: 'checkme',
                            is_delete: true,
                        },
                        {
                            name: 'previous_year',
                            value: `${comparison_id.year}`,
                        },
                        {
                            name: 'current_year',
                            value: `${year}`,
                        },
                        {
                            name: 'Title 1',
                            value: decode(currFilterName),
                            existing_value: 'bu_name',
                        },
                        {
                            name: 'diff_year',
                            value: `${comparison_id.year}`,
                            existing_value: 'xx',
                        },
                        ...qbyq3,
                        ...chart3[0],
                        ...chart3[1],
                        ...chart3[2],
                        ...chart3[3],
                        ...chart3[4],
                        ...chart3[5],
                    ],
                },
                {
                    identify_shape_name: 'checkme',
                    identify_shape_value: 'qbyq4',
                    data: [
                        {
                            name: 'checkme',
                            is_delete: true,
                        },
                        {
                            name: '5. Source',
                            value: qbyq4SlideSummary,
                        },
                        ...qbyq4,
                    ],
                },
            ],
        };
        getPptData(
            output,
            pptAccessToken,
            decode(currFilterName)
                .split(' ')
                .join('_'),
            1,
            '',
            '',
            downloadtype
        );
        setisLoading(false);
    };

    const handledownloadType = value => {
        if (!errorInfo) {
            setdownloadType(value);
            handlePptDownload(value);
        } else {
            setsnackbarOpen(true);
        }
    };

    const removeOverflow = index => {
        const filterColumnData = cloneDeep(filterColumns);
        const filterColumnCount = Object.keys(filterColumnData).length;
        for (let i = index; i <= filterColumnCount; i += 1) {
            delete filterColumnData[`level${i}`];
        }
        setfilterColumns(filterColumnData);
    };

    const handleFilterChange = (filterName, index, { precode }) => {
        setDefaultCall(false);
        removeOverflow(index);
        const newFilters = filters.slice(0, index - 1);
        newFilters[index - 1] = filterName;
        const precodeOld = currpreCode.slice(0, index - 1);
        precodeOld[index - 1] = precode[0];
        const precodePrev = currpreCodePrev.slice(0, index - 1);
        precodePrev[index - 1] = precode[1];
        setcurrpreCode(precodeOld);
        setcurrpreCodePrev(precodePrev);
        setcurrFilterName(filterName);
        setcurrFilterIndex(index);
        setFilters(newFilters);
        getFilterData(newFilters, index);
        const filtersPrecode = [];
        precodeOld.map(item => filtersPrecode.push(`demo_${item.split('_')[0]}:${item}`));
        const engagementPayload = {
            ohid,
            filters: filtersPrecode,
            label_names: `${additional[0].manager},${additional[1].executive}`,
        };
        callEngagement(engagementPayload);
    };

    const getPage = () => {
        return (
            <div className="talanax-top-filters">
                <Grid
                    container
                    columns={16}
                    spacing={1}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className="tlx-grid-layout"
                >
                    <Grid
                        container
                        columns={16}
                        spacing={1}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        className="custom-bu-table-filters"
                    >
                        {selectDropdown(CustomTopFilters, buFilters, handleFilterChange, filters, clientFilter)}
                    </Grid>
                    <Filtertable
                        scoreData={scoreData}
                        iscellBlue={iscolrBlue}
                        precode={currpreCode}
                        currFilterName={currFilterName}
                        dataSet={dataSet}
                        ohid={ohid}
                        benchmark={benchmark}
                        lang={bulang}
                        year={year}
                        level={currFilterIndex}
                        isLoading={isLoading}
                        setisLoading={setisLoading}
                        filters={filters}
                        setColumns={setColumns}
                        columns={columns}
                        filterColumns={filterColumns}
                        comparison_id={comparison_id}
                        currpreCodePrev={currpreCodePrev}
                        getCustomBuScoreData={getCustomBuScoreData}
                        clientFilter={clientFilter}
                        error={errorInfo}
                        defaultCall={defaultCall}
                    />
                </Grid>
            </div>
        );
    };

    return (
        <div className="talanax-main-wrapper clearfix" data-testid="talanxBoard">
            <div className="optionWrapper">
                <div>
                    <h2>{CUSTOM_BU_HEADING}</h2>
                </div>
                <div>
                    <ul className="rightSideNav">
                        <li className="leftToggleList">
                            <div className="tagName">{LANGUAGE}</div>
                            <div>
                                <div
                                    className={bulang === 1033 && 'active blue'}
                                    onClick={() => handleCutomBULanguage(1033)}
                                >
                                    {ENGLISH_CBR}
                                </div>
                                <div className={bulang === 7 && 'active blue'} onClick={() => handleCutomBULanguage(7)}>
                                    {GERMAN_CBR}
                                </div>
                            </div>
                        </li>

                        <li className="leftToggleList separator">
                            <div className="tagName">{COLOR_TEXT}</div>
                            <div>
                                <div
                                    className={iscolrBlue === 'false' && 'active green'}
                                    onClick={() => handleCutomBUColor('false')}
                                >
                                    {GREEN_TEXT}
                                </div>
                                <div
                                    className={iscolrBlue === 'true' ? 'active blue' : ''}
                                    onClick={() => handleCutomBUColor('true')}
                                >
                                    {BLUE_TEXT}
                                </div>
                            </div>
                        </li>
                        <li className="cb-download-pdf-ppt">
                            <div className="talanax-top-filters">
                                <div className="custom-bu-rpt-dropdown clearfix">
                                    <FormControl style={{ minWidth: 160 }}>
                                        <Select
                                            id="label"
                                            value={downloadType}
                                            displayEmpty
                                            onOpen={() => setdownloadType('')}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                },
                                                getContentAnchorEl: null,
                                                classes: { paper: 'demoDropdown' },
                                            }}
                                            onChange={e => handledownloadType(e.target.value)}
                                        >
                                            <MenuItem value="">
                                                <em>{SELECT_DOWNLOAD}</em>
                                            </MenuItem>
                                            <MenuItem value="ppt">{DOWNLOAD_PPT}</MenuItem>
                                            <MenuItem value="pdf">{DOWNLOAD_PDF}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </li>
                        <Snackbar
                            open={snackbarOpen}
                            vertical="bottom"
                            horizontal="right"
                            autoHideDuration={6000}
                            onClose={handleClose}
                            message="Download Not Available"
                            action={
                                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            }
                        />
                    </ul>
                </div>
            </div>

            {getPage()}
            <Fragment>
                <ul className="benchmark custom_bu_benchmark_wrapper clearfix">
                    <div className="custom_bu_benchmark_div">
                        <span>
                            <b>{BENCHMARK_HEAD}:</b>
                        </span>
                        {CUSTOM_BU_QUARTILE_COLORS?.map(({ title, color_bl, color_gr, border: borderColor }) => (
                            <li key={title} style={{ color: iscolrBlue === 'true' ? color_bl : color_gr }}>
                                <span
                                    style={{ borderColor, background: iscolrBlue === 'true' ? color_bl : color_gr }}
                                />
                                {title}
                            </li>
                        ))}
                    </div>
                    <div className="custom_bu_benchmark_div">
                        <span>
                            <b>{`${DIFF_HEAD} ${TO} ${comparison_id.year} `}:</b>
                        </span>
                        {CUSTOM_BU_DIFF_COLORS?.map(({ title, color }) => (
                            <li key={title} style={{ color }}>
                                <span style={{ background: color }} />
                                {title}
                            </li>
                        ))}
                    </div>
                </ul>
                <div className="note">
                    <b>{NOTE}:</b>
                    {`${DISCLAIMER_DEMO_PART1} < ${threshold} ${DISCLAIMER_DEMO_PART2}`}
                </div>
                {errorInfo && <ErrorToast message={errorInfo} />}
            </Fragment>
        </div>
    );
}

CBUReports.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    scoreData: PropTypes.object.isRequired,
    getPptData: PropTypes.func.isRequired,
    filterColumns: PropTypes.object.isRequired,
    setfilterColumns: PropTypes.func.isRequired,
    getCustomBuScoreData: PropTypes.func.isRequired,
    langData: PropTypes.array.isRequired,
    setlangData: PropTypes.func.isRequired,
    langDataFetched: PropTypes.bool.isRequired,
    scoreDataFetched: PropTypes.bool.isRequired,
    getTlnxEngagementData: PropTypes.func.isRequired,
    engagementData: PropTypes.object.isRequired,
    buFilters: PropTypes.array.isRequired,
    setBUFilters: PropTypes.func.isRequired,
    errorInfo: PropTypes.string.isRequired,
};

export default CBUReports;
