import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { MenuItem, Select, Tooltip } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import getStaticTextDiagnose from '../../constants';
import { getStaticText } from '../../../../utils/constants';
import FilterSvg from '../../assets/Filter.svg';
import ActiveFilters1 from '../../assets/ActiveFilters1.svg';
import Down from '../../assets/Down.svg';
import Excel from './Excel';
import ErrorToast from '../../../../components/InformationToast/ErrorToast';

function EngagementDemographic({
    engagementData,
    selectedDemographic,
    callEngagementDemographic,
    engagementDemographicDataFetched,
    errorInfo,
    addOnTab,
    setAddOnTabs,
    demographics,
    defaultSettings,
    engagementDropdown,
    toggleFilterView,
    activeFilter,
}) {
    const { lang, ohid = '' } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { ENGAGEMENT_TABLE_HEADERS } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, HYBRID_QUES_KEY, EXCLUDE_QUES_KEY, ING_PREV_SURVEY_EXC_QUES } = staticText;
    const { ENGAGEMENT_NAV_LINKS, FILTERS, OVERALL, ADDONS_PAGE_TEXT, SIZE_MAIN_TEXT, DOWNLOAD } = SITE_TEXT || {};

    useEffect(() => {
        if (!engagementDemographicDataFetched) {
            callEngagementDemographic(selectedDemographic);
        }
        // eslint-disable-next-line
    }, [engagementDemographicDataFetched, selectedDemographic]);
    const isCurrentSurvey = ohid === 'OHI13240';
    const { job_engagement_score, org_engagement_score, overall, ...questionKeys } = engagementData;
    const { question_scores = {} } = overall || {};
    const [dwnld, setDwnld] = useState(false);

    const getOverallData = qScoreObj => {
        if (!Object.keys(qScoreObj).length) return '';
        const key =
            Object.keys(qScoreObj).filter(item => {
                const { q_code = '' } = qScoreObj[item];
                if (q_code === HYBRID_QUES_KEY) {
                    return item;
                }
                return null;
            })[0] || '';
        return qScoreObj[key];
    };

    const { [HYBRID_QUES_KEY]: hybridQues = {}, ...rest } = questionKeys;
    const hybridQuesExist = Object.keys(hybridQues).length;
    const { title: hLabel = '', demographics: hDemos = {} } = hybridQues || {};
    const checkLength = Object.keys(rest) && Object.keys(rest).length;
    const selectedGroup = demographics.filter(({ code = '' }) => code === engagementDropdown)[0];
    const { label: groupLabel = '' } = selectedGroup || {};

    const { size: overallSize = '', title: overallTitle, q_code: overallCode, ...headers } = hybridQuesExist
        ? getOverallData(question_scores)
        : {};

    const dibi = useMemo(() => {
        if (engagementDemographicDataFetched) {
            const { q2_6: obj = {} } = cloneDeep(rest) || {};
            const { demographics: demographicObj = {} } = obj || {};
            // eslint-disable-next-line no-unused-vars
            const { title = '', ...demo } = demographicObj || {};
            Object.keys(demo).forEach(demoName => {
                const { agree: currAgree = 0 } = demo[demoName] || {};
                let sum = currAgree;
                let { agree: overallAgreeDibi, size: overallSizeDibi } =
                    question_scores.filter(({ q_code = '' }) => q_code === 'q2_6')[0] || {};
                ING_PREV_SURVEY_EXC_QUES.forEach(key => {
                    const { demographics: demoX = {} } = rest[key] || {};
                    const { agree = 0 } = demoX[demoName] || {};
                    const { agree: ovAgree = '', size: ovSize = '' } =
                        question_scores.filter(({ q_code = '' }) => q_code === key)[0] || {};
                    sum += agree;
                    overallSizeDibi += ovSize;
                    overallAgreeDibi += ovAgree;
                });
                demo[demoName].agree = Math.round(sum / 5);
                demographicObj.overall = {
                    agree: Math.round(overallAgreeDibi / 5),
                    size: Math.round(overallSizeDibi / 5),
                };
            });
            return obj;
        }
        return {};
        // eslint-disable-next-line
    }, [engagementDemographicDataFetched, engagementDropdown, engagementData]);

    const { demographics: dibiDemo = {} } = dibi || {};
    const { overall: dibiOverall, ...dibiAllDemo } = dibiDemo || {};

    return (
        <Fragment>
            <div className="optionWrapper">
                <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <ul className="leftNav">
                            {ENGAGEMENT_NAV_LINKS.map((tabName, index) => {
                                return (
                                    <li
                                        data-testid={tabName}
                                        key={tabName}
                                        className={c({ activeLink: addOnTab === index })}
                                        onClick={() => setAddOnTabs(index)}
                                    >
                                        {tabName}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <ul className="navScoreList">
                        <div className="rightOptions">
                            <span>
                                <Select
                                    IconComponent={KeyboardArrowDownSharpIcon}
                                    value={engagementDropdown}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    onChange={({ target: { value: codeN } }) => {
                                        callEngagementDemographic(codeN);
                                    }}
                                >
                                    {demographics.map(({ label, code: codeN }) => {
                                        return (
                                            <MenuItem
                                                classes={{ root: 'demographicOptions' }}
                                                key={codeN}
                                                value={codeN}
                                            >
                                                {label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </span>
                        </div>
                        {!errorInfo && (
                            <Tooltip title="Download all tables in excel">
                                <li data-testid="getPptBtn" onClick={() => setDwnld(true)} className="resurveyImg">
                                    <img src={Down} alt="" />
                                    <span className="lineHead upperHead">{DOWNLOAD}</span>
                                </li>
                            </Tooltip>
                        )}
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                            <img src={activeFilter ? ActiveFilters1 : FilterSvg} alt="" />
                            <span className="filter">{FILTERS}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <section className="sectionWrapper">
                {!!checkLength &&
                    Object.keys(rest).map(engKey => {
                        const { title: label = '', demographics: demos = {} } = engagementData[engKey];
                        const {
                            disagree: ovDisagree = '',
                            agree: ovAgree = '',
                            neutral: ovNeutral = '',
                            size: ovSize = '',
                        } = question_scores.filter(({ q_code = '' }) => q_code === engKey)[0] || {};
                        const data = Object.keys(demos);
                        if (
                            (engKey.includes(EXCLUDE_QUES_KEY) && isCurrentSurvey) ||
                            (ING_PREV_SURVEY_EXC_QUES.includes(engKey) && !isCurrentSurvey)
                        )
                            return null;
                        return (
                            <div className="cardWrapper" key={engKey}>
                                <h4 className="question">{label || 'Question text unavailable'}</h4>
                                <div className="tableWrapper">
                                    <div className="col">
                                        {ENGAGEMENT_TABLE_HEADERS.map((header, i) => (
                                            <div className={c({ groupName: !i }, 'cell')} key={header}>
                                                {!i ? groupLabel : header}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col">
                                        <div className=" groupName cell">{OVERALL}</div>
                                        <div className=" cell">{ovSize.toLocaleString('en-US')}</div>
                                        <div className=" cell">{ovDisagree}</div>
                                        <div className=" cell">{ovAgree}</div>
                                        <div className=" cell">{ovNeutral}</div>
                                    </div>
                                    {data.length &&
                                        data.map((col, col_i) => {
                                            const { disagree, agree, neutral, size: nSize = '', title = '' } = demos[
                                                col
                                            ];
                                            return (
                                                <div className="col" key={'col' + col_i}>
                                                    <div className=" groupName cell">{title || col}</div>
                                                    <div className=" cell">{nSize.toLocaleString('en-US')}</div>
                                                    <div className=" cell">{disagree}</div>
                                                    <div className=" cell">{agree}</div>
                                                    <div className=" cell">{neutral}</div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        );
                    })}
                {isCurrentSurvey && Object.keys(dibiAllDemo).length ? (
                    <div className="cardWrapper">
                        <h4 className="question">{ADDONS_PAGE_TEXT[5]}</h4>
                        <div className="tableWrapper">
                            <div className="col">
                                {[' ', 'Size', 'Agree'].map((header, i) => (
                                    <div className={c({ groupName: !i }, 'cell')} key={header}>
                                        {!i ? groupLabel : header}
                                    </div>
                                ))}
                            </div>
                            <div className="col">
                                <div className=" groupName cell">{OVERALL}</div>
                                <div className=" cell">{dibiOverall.size.toLocaleString('en-US')}</div>
                                <div className=" cell">{dibiOverall.agree}</div>
                            </div>
                            {Object.keys(dibiAllDemo).map((col, col_i) => {
                                const { agree, size: nSize = '', title = '' } = dibiAllDemo[col];
                                return (
                                    <div className="col" key={'col' + col_i}>
                                        <div className=" groupName cell">{title || col}</div>
                                        <div className=" cell">{nSize.toLocaleString('en-US')}</div>
                                        <div className=" cell">{agree}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : null}
                {!!hybridQuesExist && (
                    <div className="cardWrapper">
                        <h4 className="question">{hLabel || 'Question text unavailable'}</h4>
                        <div className="tableWrapper">
                            <div className="col">
                                {Object.keys(headers).map((header, i) => {
                                    const dayCount = parseInt(header, 10) - 1;
                                    const days = dayCount === 1 ? ' day' : ' days';
                                    return (
                                        <Fragment>
                                            {!i && (
                                                <Fragment>
                                                    <div className={c({ groupName: !i }, 'cell')} key={header}>
                                                        {groupLabel}
                                                    </div>
                                                    <div className="cell">{SIZE_MAIN_TEXT}</div>
                                                </Fragment>
                                            )}
                                            <div className="cell" key={header}>
                                                {dayCount + days}
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </div>
                            <div className="col">
                                {Object.keys(headers).map((header, i) => {
                                    return (
                                        <Fragment>
                                            {!i && (
                                                <Fragment>
                                                    <div className={c({ groupName: !i }, 'cell')} key={header}>
                                                        {OVERALL}
                                                    </div>
                                                    <div className="cell">{overallSize.toLocaleString('en-US')}</div>
                                                </Fragment>
                                            )}
                                            <div className="cell" key={header}>
                                                {headers[header] || '-'}
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </div>
                            {Object.keys(hDemos).length &&
                                Object.keys(hDemos).map((col, col_i) => {
                                    const { title = '', size: nSize, ...otherKeys } = hDemos[col];
                                    return (
                                        <Fragment>
                                            <div className="col" key={'col' + col_i}>
                                                <div className=" groupName cell">{title || col}</div>
                                                <div className="cell">{nSize.toLocaleString('en-US')}</div>
                                                {Object.keys(headers).map((values, i) => (
                                                    <div className=" cell" key={values + i}>
                                                        {otherKeys[values] || '-'}
                                                    </div>
                                                ))}
                                            </div>
                                        </Fragment>
                                    );
                                })}
                        </div>
                    </div>
                )}
                {!checkLength && errorInfo && <ErrorToast message={errorInfo} />}
            </section>
            {dwnld && (
                <Excel
                    engagementDropdown={engagementDropdown}
                    engagementData={engagementData}
                    demographics={demographics}
                    setDwnld={setDwnld}
                    defaultSettings={defaultSettings}
                />
            )}
        </Fragment>
    );
}

EngagementDemographic.propTypes = {
    errorInfo: PropTypes.string.isRequired,
    selectedDemographic: PropTypes.string.isRequired,
    callEngagementDemographic: PropTypes.func.isRequired,
    engagementDemographicDataFetched: PropTypes.bool.isRequired,
    engagementData: PropTypes.object.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    demographics: PropTypes.array.isRequired,
    engagementDropdown: PropTypes.string.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
};

export default EngagementDemographic;
