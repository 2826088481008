import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import PracticeBoard from './PracticeBoard';

import {
    getPracticeData,
    getPracticeBuData,
    getScoreData,
    getPptData,
    setPracticeDisplay,
    updateFilters,
    resetPracticeBuDataFetched,
    getSinglePastReport,
    getComparisonYrPracticeData,
    getBuData,
    getDemographics,
    getBenchmarks,
} from '../actions';
import { updatePracticeBuFilters, getReport, getRawPptData } from '../../Reports/actions';

import {
    selectScoreData,
    selectScoreDataFetched,
    selectPracticeData,
    practiceDataFetched,
    selectApiParams,
    selectApiCount,
    selectFiltersApplied,
    getErrorInfo,
    selectFilters,
    selectPracticeToDisplay,
    selectPracticeBuData,
    selectPracticeBuDataFetched,
    selectPastReport,
    selectPracticeDataCompYr,
    selectProjects,
    selectProjectsFetched,
    selectBuData,
    selectBuDataFetched,
} from '../selector';

import { getDefaultSettings } from '../../Login/selector';
import { selectPracticeFilters, selectReportError } from '../../Reports/selector';

const stateToProps = createStructuredSelector({
    scoreData: selectScoreData(),
    scoreDataFetched: selectScoreDataFetched(),
    practiceData: selectPracticeData(),
    practiceDataFetched: practiceDataFetched(),
    apiParams: selectApiParams(),
    apiLoadingCount: selectApiCount(),
    activeFilter: selectFiltersApplied(),
    errorInfo: getErrorInfo(),
    filters: selectFilters(),
    practiceToDisplay: selectPracticeToDisplay(),
    defaultSettings: getDefaultSettings(),
    practiceFilters: selectPracticeFilters(),
    practiceBuData: selectPracticeBuData(),
    practiceBuDataFetched: selectPracticeBuDataFetched(),
    reportError: selectReportError(),
    singlePastReport: selectPastReport(),
    practiceDataCompYr: selectPracticeDataCompYr(),
    projects: selectProjects(),
    projectsFetched: selectProjectsFetched(),
    buData: selectBuData(),
    buDataFetched: selectBuDataFetched(),
});

const dispatchToProps = {
    getPracticeData,
    getPracticeBuData,
    updatePracticeBuFilters,
    getPptData,
    setPracticeDisplay,
    getScoreData,
    updateFilters,
    resetPracticeBuDataFetched,
    getReport,
    getRawPptData,
    getSinglePastReport,
    getComparisonYrPracticeData,
    getBuData,
    getBenchmarks,
    getDemographics,
};

export default connect(stateToProps, dispatchToProps)(PracticeBoard);
