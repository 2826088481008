import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ResponseRateContent from './ResponseRate';
import responseRate from './reducer';
import responseRateSagas from './sagas';

import {
    getDailyResponses,
    getDemoResponses,
    getCrossTab,
    setDropDownValue,
    setCrossTabsDropDown,
    reloadSiteData,
    reloadTabData,
} from './actions';
import {
    selectLoadCount,
    getErrorInfo,
    selectDailyResponses,
    selectDailyResFetched,
    selectDropDownValue,
    selectDemoResFetched,
    selectDemoResponses,
    selectCrossTab,
    selectCrossTabFetched,
    selectCrossTabDrop,
} from './selector';
import { getDemographics, updateFilters } from '../Diagnose/actions';
import { selectApiCount, selectApiParams, selectFiltersApplied, selectFilters } from '../Diagnose/selector';
import { getDefaultSettings } from '../Login/selector';

const stateToProps = createStructuredSelector({
    surveyLoadCount: selectLoadCount(),
    errorInfo: getErrorInfo(),
    demoApiCount: selectApiCount(),
    apiParams: selectApiParams(),
    activeFilter: selectFiltersApplied(),
    defaultSettings: getDefaultSettings(),
    filters: selectFilters(),
    dailyResponses: selectDailyResponses(),
    dailyResFetched: selectDailyResFetched(),
    dropDownValue: selectDropDownValue(),
    demoResponses: selectDemoResponses(),
    demoResFetched: selectDemoResFetched(),
    crossTabData: selectCrossTab(),
    crossTabFetched: selectCrossTabFetched(),
    crossTabDrop: selectCrossTabDrop(),
});

const dispatchToProps = {
    setDropDownValue,
    updateFilters,
    getDemographics,
    getDailyResponses,
    getDemoResponses,
    getCrossTab,
    reloadSiteData,
    reloadTabData,
    setCrossTabsDropDown,
};

export { responseRateSagas, responseRate };

export default connect(stateToProps, dispatchToProps)(ResponseRateContent);
