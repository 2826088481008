import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getDefaultSettings } from '../Login/selector';
import { getReportUsers } from '../UserReports/actions';
import { selectApiCount, selectUsersList, selectUsersFetched, getListErrorInfo } from '../UserReports/selector';
import { changeUser } from '../Settings/actions';
import { getErrorInfo } from '../Settings/selector';
import UserManagement from './UserManagement';

const stateToProps = createStructuredSelector({
    defaultSettings: getDefaultSettings(),
    usersList: selectUsersList(),
    usersListFetched: selectUsersFetched(),
    errorInfo: getListErrorInfo(),
    apiLoadingCount: selectApiCount(),
    settingsErrorInfo: getErrorInfo(),
});

const dispatchToProps = { getReportUsers, changeUser };

export default connect(stateToProps, dispatchToProps)(UserManagement);
