import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import Loader from './components/Loader';

// eslint-disable-next-line react/prop-types
export default function PrivateRoute({ component: Component, path, exact, token, settingsObtained }) {
    const primaryComp = settingsObtained ? (
        <Route
            exact={exact}
            path={path}
            render={props => (
                <Suspense fallback={<Loader position="absolute" showBackground />}>
                    <Component {...props} />
                </Suspense>
            )}
        />
    ) : (
        <Loader position="absolute" showBackground />
    );
    const route = token ? primaryComp : <Route render={() => <Redirect to="/" />} />;
    return route;
}

PrivateRoute.defaultProps = {
    exact: false,
    token: null,
};

PrivateRoute.propTypes = {
    exact: PropTypes.bool,
    token: PropTypes.string,
    settingsObtained: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
};
