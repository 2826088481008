import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Toolbox from './Toolbox';

import { getToolData, setTheme, deleteTheme, createThemeData } from '../actions';

import {
    selectScoreData,
    selectToolData,
    selectToolDataFetched,
    selectToolDataLoading,
    selectToolError,
} from '../selector';

import { getDefaultSettings } from '../../Login/selector';

const stateToProps = createStructuredSelector({
    scoreData: selectScoreData(),
    toolData: selectToolData(),
    toolDataFetched: selectToolDataFetched(),
    toolDataLoading: selectToolDataLoading(),
    error: selectToolError(),
    defaultSettings: getDefaultSettings(),
});

const dispatchToProps = {
    getToolData,
    setTheme,
    deleteTheme,
    createThemeData,
};

export default connect(stateToProps, dispatchToProps)(Toolbox);
