import React, { Fragment } from 'react';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import InfoLarge from '../assets/T-warning.svg';
import './index.scss';

const WarningDialog = ({ setView }) => {
    return (
        <Dialog
            open
            disableEscapeKeyDown
            disableBackdropClick
            maxWidth="md"
            classes={{
                root: 'customBmDialogRoot',
            }}
        >
            <section className="infoContent">
                <div className="infoLarge">
                    <img src={InfoLarge} alt="" />
                </div>
                <h2>Disclaimer!</h2>
                <div className="para">
                    <div>
                        Identifiable information about the organizations in the OHI database CANNOT be shared with
                        clients and CSTs. This includes client names, and any information that breaches client
                        confidentiality even without stating theclient name (e.g., Telecom organization in Canada).
                    </div>
                </div>
            </section>
            <section className="btnContent">
                <Fragment>
                    <div
                        data-testid="compareButton"
                        className="compareBtn activeCompareBtn"
                        onClick={() => {
                            setView(false);
                        }}
                    >
                        Continue
                    </div>
                </Fragment>
            </section>
        </Dialog>
    );
};

WarningDialog.propTypes = {
    setView: PropTypes.func.isRequired,
};

export default WarningDialog;
