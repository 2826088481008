import React, { useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { List, ListItem, Collapse, Checkbox, TextField, FormControlLabel, Button } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { cloneDeep } from 'lodash';

import { getStaticText } from '../../utils/constants';
// import { SITE_TEXT, FILTER_LIST_RANGE } from '../../utils/constants';
// import { addZeroToNUmber } from '../../utils/functions';
import CloseIcon from '../../assets/Close.svg';
import SearchIcon from '../../assets/Magnifying.svg';
import './index.scss';

function CustomBmFilter({
    filters,
    // setDemoActive,
    updateFilters,
    // noChild,
    // showOption,
    defaultSettings,
    // setIsDemoChecked,
}) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, FILTER_LIST_RANGE } = staticText;
    const [demographics, updateDemographics] = useState(filters);
    const {
        VIEW_ALL,
        // ALL_SELECTED,
        OPTION_TWO,
        OPTION_ONE,
        RESET_FILTERS,
        APPLY_FILTERS,
    } = SITE_TEXT || {};
    const selectAllChild = ({ checked }, indexParent) => {
        const newDemographics = cloneDeep(demographics);
        newDemographics[indexParent].filter_items.forEach(option => {
            if (!checked && option.optionNo) {
                delete option.optionNo;
            }
            option.isSelected = checked;
        });
        // setDemoActive(true);
        updateDemographics(newDemographics);
        // if (isOhiReport && showBulk) {
        //     setIsDemoChecked(true);
        //     if (checked) {
        //         setIsDemoChecked(true);
        //     } else {
        //         setIsDemoChecked(false);
        //     }
        // }
    };

    const handleChildCheck = ({ checked }, indexParent, childCode) => {
        const newDemographics = cloneDeep(demographics);
        const selectedItemIndex = newDemographics[indexParent].filter_items.findIndex(
            ({ filter_item_id }) => filter_item_id === childCode
        );
        const findOption = newDemographics[indexParent].filter_items.filter(({ optionNo }) => optionNo).length;
        newDemographics[indexParent].filter_items.map(val => {
            if (checked && val.filter_item_id === childCode) {
                val.optionNo = findOption === 0 ? OPTION_ONE : OPTION_TWO;
            } else if (!checked && (findOption === 1 || findOption === 2) && val.filter_item_id === childCode) {
                delete val.optionNo;
            } else if (val.isSelected) {
                val.optionNo = OPTION_ONE;
            }
            return val;
        });
        newDemographics[indexParent].filter_items[selectedItemIndex].isSelected = checked;
        // setDemoActive(true);
        updateDemographics(newDemographics);
        // if (isOhiReport && showBulk) {
        //     if (checked) {
        //         setIsDemoChecked(true);
        //     } else {
        //         setIsDemoChecked(false);
        //     }
        // }
    };

    const handleParentClick = index => {
        const newDemographics = cloneDeep(demographics);
        const openItem = newDemographics.findIndex(({ isOpen: oldOpen }) => oldOpen);
        if (openItem !== -1 && openItem !== index) {
            newDemographics[openItem].isOpen = false;
        }
        const { isOpen = false } = newDemographics[index];
        newDemographics[index].isOpen = !isOpen;
        newDemographics[index].viewAll = false;
        newDemographics[index].search = '';
        // console.log(newDemographics, index);
        updateDemographics(newDemographics);
    };

    const selectOptions = ({ value }, index) => {
        const newDemographics = cloneDeep(demographics);
        newDemographics[index].search = value;
        updateDemographics(newDemographics);
    };

    const resetSearch = index => {
        const newDemographics = cloneDeep(demographics);
        newDemographics[index].search = '';
        updateDemographics(newDemographics);
    };

    const toggleView = index => {
        const newDemographics = cloneDeep(demographics);
        newDemographics[index].viewAll = true;
        updateDemographics(newDemographics);
    };

    // const checkLength = () => {
    //     return demographics.filter(({ options = [] }) => options.filter(({ isSelected }) => isSelected).length).length;
    // };

    const noChild = false;

    const resetFilters = () => {
        const updatedDemographics = cloneDeep(demographics);
        updatedDemographics.forEach(({ filter_items = [] }, indexToUse) => {
            const openItem = updatedDemographics[indexToUse].isOpen;
            if (openItem) {
                updatedDemographics[indexToUse].isOpen = false;
                updatedDemographics[indexToUse].viewAll = false;
            }
            const selectedLength = filter_items.filter(({ isSelected }) => isSelected).length;
            if (selectedLength) {
                updatedDemographics[indexToUse].filter_items = filter_items.map(oldOption => ({
                    ...oldOption,
                    isSelected: false,
                }));
            }
        });
        updateFilters(updatedDemographics);
    };

    return (
        <div className="selectFilter">
            <div className={c('filterContent clearfix', {})}>
                <div className="upperContent clearfix">
                    {demographics.map(
                        (
                            {
                                filter_id: code = '',
                                filter_name: label = '',
                                filter_items: options = [],
                                isOpen = false,
                                viewAll = false,
                                search = '',
                                isDefault = false,
                                isSurveyDefault = false,
                            },
                            indexParent
                        ) => {
                            const selectedLength = options.filter(({ isSelected }) => isSelected).length;
                            const iconExpand = options.length ? (
                                <span className="expandIcon">{isOpen ? <ExpandLess /> : <ExpandMore />}</span>
                            ) : null;
                            const selectedOptions = search.trim()
                                ? options.filter(({ label: l = '' }) =>
                                      `${l}`.toLowerCase().includes(search.toLowerCase())
                                  )
                                : options;
                            const optionsToUse = viewAll
                                ? selectedOptions
                                : selectedOptions.slice(0, FILTER_LIST_RANGE);
                            return (
                                <div className="mainListWrap" key={code}>
                                    <ListItem
                                        classes={{
                                            root: c('mainListContent demoListContext', {
                                                borderBottom: !isOpen,
                                                // disableClass:
                                                //     checkLengthParent || isDisabled || hiddenOptions.indexOf(code) > -1,
                                            }),
                                        }}
                                        onClick={() => handleParentClick(indexParent)}
                                        data-testid={label}
                                    >
                                        <Checkbox
                                            checked={!!selectedLength}
                                            onChange={({ target }) => selectAllChild(target, indexParent)}
                                            name={code}
                                            // disabled={}
                                            inputProps={{
                                                'data-testid': `ParentCheck${label}`,
                                            }}
                                        />
                                        {label}
                                        {selectedLength ? <span className="showOption">{selectedLength}</span> : null}
                                        {/* {selectedLength ? <span className="textToShow">{textToShow}</span> : null} */}
                                        {iconExpand}
                                    </ListItem>
                                    {!noChild ? (
                                        <Collapse
                                            in={isOpen}
                                            timeout="auto"
                                            unmountOnExit
                                            classes={{ container: 'childWrapper' }}
                                        >
                                            <div className="searchHead">
                                                <img src={SearchIcon} alt="" className="searchIcon" />
                                                <TextField
                                                    fullWidth
                                                    value={search}
                                                    placeholder="Search"
                                                    onChange={({ target }) => selectOptions(target, indexParent)}
                                                    inputProps={{
                                                        'data-testid': `Text${label}`,
                                                    }}
                                                />
                                                <img
                                                    src={CloseIcon}
                                                    alt=""
                                                    className="closeIcon"
                                                    onClick={() => resetSearch(indexParent)}
                                                    data-testid={`Close${label}`}
                                                />
                                            </div>

                                            <div className="optionList">
                                                {optionsToUse.map(
                                                    ({
                                                        filter_item_name: childLabel = '',
                                                        filter_item_id: childCode = '',
                                                        isSelected = false,
                                                        optionNo = '',
                                                        allowSelect = false,
                                                    }) => {
                                                        return (
                                                            <List component="div" disablePadding key={childCode}>
                                                                <ListItem>
                                                                    <FormControlLabel
                                                                        classes={{
                                                                            root: c(
                                                                                'optionItem',
                                                                                'analyticsOptionItem',
                                                                                {
                                                                                    width80: optionNo !== '',
                                                                                    isDefault,
                                                                                    allowSelect,
                                                                                    isSurveyDefault,
                                                                                }
                                                                            ),
                                                                        }}
                                                                        control={
                                                                            <Checkbox
                                                                                checked={isSelected}
                                                                                onChange={({ target }) =>
                                                                                    handleChildCheck(
                                                                                        target,
                                                                                        indexParent,
                                                                                        childCode
                                                                                    )
                                                                                }
                                                                                // disabled={}
                                                                                name={childCode}
                                                                                inputProps={{
                                                                                    'data-testid': `ChildCheck${childLabel}`,
                                                                                }}
                                                                            />
                                                                        }
                                                                        label={childLabel}
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                        );
                                                    }
                                                )}
                                            </div>
                                            {!viewAll && selectedOptions.length > FILTER_LIST_RANGE ? (
                                                <div onClick={() => toggleView(indexParent)} className="viewAll">
                                                    {VIEW_ALL}
                                                </div>
                                            ) : null}
                                        </Collapse>
                                    ) : null}
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
            <div className="buttonContent clearfix">
                <Button classes={{ root: c({ active: true }) }} onClick={resetFilters}>
                    {RESET_FILTERS}
                </Button>
                <Button
                    classes={{ root: c({ active: true }) }}
                    onClick={() => {
                        updateFilters(demographics);
                    }}
                >
                    {APPLY_FILTERS}
                </Button>
            </div>
        </div>
    );
}

CustomBmFilter.propTypes = {
    filters: PropTypes.array.isRequired,
    updateFilters: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
};

export default CustomBmFilter;
