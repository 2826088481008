import moment from 'moment';
import Scorecard from '../Diagnose/assets/Overview.svg';
import Scorecard_active from '../Diagnose/assets/Overview_active.svg';
import Demographic from '../Diagnose/assets/Demographic.svg';
import Demographic_active from '../Diagnose/assets/demographic_active.svg';
import Benchmark from '../Diagnose/assets/Benchmark.svg';
import Benchmark_active from '../Diagnose/assets/benchmark_active.svg';

export const NAV_LINKS = [
    {
        imgSrc: Scorecard,
        imgSrcActive: Scorecard_active,
    },
    {
        imgSrc: Demographic,
        imgSrcActive: Demographic_active,
        index: 1,
    },
    {
        imgSrc: Benchmark,
        imgSrcActive: Benchmark_active,
    },
];

export const chartConfig = {
    title: { text: '' },
    chart: {
        height: 600,
    },
    credits: { enabled: false },
    tooltip: {
        formatter: function formatter() {
            const series = this.series.chart.series[0];
            const currentData = this.series.userOptions.metaData[this.point.index];
            const header = moment(this.x).format('dddd, MMM DD, YYYY');
            return `<span style="font-size: 10px">${header}</span><br/><span style="color: ${this.color}">●</span> ${series.name}: <b>${currentData.completed}/${currentData.total} (${this.y}%)</b><br/>`;
        },
    },
};

export const SLICE_VALUE = 10;
export const DEMO_MAX_VALUE = 20;
export const GET_DAILY_RES = 'GET_DAILY_RES';
export const GET_DEMO_RES = 'GET_DEMO_RES';
export const GET_CROSS_TAB = 'GET_CROSS_TAB';
export const SET_DEMO_VALUE = 'SET_DEMO_VALUE';
export const SET_CROSS_TABS_DEMO = 'SET_CROSS_TABS_DEMO';
export const RELOAD_DATA = 'RELOAD_DATA';
export const RESET_TAB_DATA = 'RESET_TAB_DATA';
export const RESET_TAB_REDUX = ['dailyResFetched', 'demoResFetched', 'crossTabFetched'];
