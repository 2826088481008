import { webApiGet, webApiPost, webApiDelete, webApiPut, webApiFormDataPost } from './core';

export function fetchWebApi(url, token = '') {
    return webApiGet(url, token);
}

export function postWebApi(url, bodyObject = {}, token = '') {
    return webApiPost(url, bodyObject, token);
}

export function deleteWebApi(url, bodyObject, token = '') {
    return webApiDelete(url, bodyObject, token);
}

export function putWebApi(url, bodyObject = {}, token = '') {
    return webApiPut(url, bodyObject, token);
}

export function postFormWebApi(url, bodyObject = {}, token = '') {
    // const { files, payload }  = bodyObject || {};
    return webApiFormDataPost(url, bodyObject, token);
}
