import React, { Fragment, useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import c from 'classnames';
import { Helmet } from 'react-helmet';

import AuthStore from '../../../common/AuthStore';
import FilterSvg from '../assets/Filter.svg';
import SelectMenu from './SelectMenu';
import { getStaticText } from '../../../utils/constants';
import getStaticTextDiagnose from '../constants';
import { getBenchmarkName, getPptValues } from '../../../utils/functions';
import colors from '../../../sass/colors';
import './index.scss';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import Down from '../assets/Down.svg';
import ActiveFilters1 from '../assets/ActiveFilters1.svg';
import DwnldModal from '../../../components/InformationToast/DwnldModal';

function BenchmarkBoard({
    apiParams,
    apiLoadingCount,
    scoreData,
    getScoreData,
    scoreDataFetched,
    leftBenchmarks,
    rightBenchmarks,
    updateApiParams,
    toggleFilterView,
    updateLeftBenchmarks,
    updateRightBenchmarks,
    activeFilter,
    errorInfo,
    filters,
    getPptData,
    defaultSettings,
    appliedFiltersInfo,
    getReport,
    getRawPptData,
    reportError,
    getSinglePastReport,
    singlePastReport,
    localPractice,
}) {
    useEffect(() => {
        if (!scoreDataFetched && !apiLoadingCount) {
            getScoreData(apiParams);
        }
        // eslint-disable-next-line
    }, [scoreDataFetched, apiParams]);
    const {
        quartileColors = [],
        report_name = '',
        report_name_or_client_name: rName = '',
        template_name: template_file_name,
        lang,
        engagement,
        survey_version = '',
        report_id = '',
        checkme_mapping = {},
        demographic = '',
    } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { PERCENTILE } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, NON_PROFIT_SURVEY, SINGLE_PAGE_EXPORT_PARAMS, ING_REPORT_ID } = staticText;

    useEffect(() => {
        const { bm_filters } = apiParams;
        const { code: right = '', selectedOption: rightOpt = '' } =
            rightBenchmarks.filter(({ selectedOption: op }) => op)[0] || {};
        const rightBm = `${rightOpt}:${right}`;
        if (engagement === NON_PROFIT_SURVEY && rightOpt && bm_filters[1] !== rightBm) {
            updateApiParams({ bm_filters: [bm_filters[0], rightBm] });
        }
        // eslint-disable-next-line
    }, []);

    const { report_type } = apiParams;
    const isPercentile = report_type === PERCENTILE;
    const { $white } = colors;
    const [expandedList, updateExpandedList] = useState([0]);
    const [dwnld, setdwnld] = useState(false);
    const {
        BENCHMARK_HEAD,
        DIFF_HEAD,
        FILTERS,
        BUSINESS_TEXT,
        DOWNLOAD,
        NOTE,
        BENCH_NOTE,
        PPT_RES_TEXT,
        PPT_RES_TEXT2,
        PPT_RES_TEXT3,
        SOURCE_TEXT,
        NUMBER_TEXT,
        NUMBER_SURVEY_TEXT,
        SIGNIFICANCE,
        SIGNIFICANCEv2,
        NUMBER_CAP_SURVEY_TEXT,
        ALL_PPT_TEXT,
        PPT_VALUES,
        BENCHMARK_NA,
        HEALTH_BENCHMARK_BOARD,
        NUMBER_OF_RESPONDENTS_N,
        NO_BENCHMARK,
        BOTTOM_DECILE,
        PERCENT_FAVORABLE,
    } = SITE_TEXT || {};

    const { qbyq = {}, bm_filters_result = [] } = scoreData;
    const benchOne = bm_filters_result[0] || {};
    const benchTwo = bm_filters_result[1] || benchOne;
    const { records: benchRecordOne = [], n_respondents: n_respondents1, n_survey: n_survey1 } = benchOne;
    const { records: benchRecordTwo = [], n_respondents: n_respondents2, n_survey: n_survey2 } = benchTwo;
    const { records = {}, size = '', n_respondents, n_survey } = qbyq;
    const { ohi_score = {}, ...dataToIterate } = records;
    const sigValToUse = survey_version === '4' || survey_version === '3_2' ? SIGNIFICANCEv2 : SIGNIFICANCE;
    const isOhi4 = survey_version === '4' || survey_version === '3_2';
    const filterCheck = apiParams.filters || [];

    const updateExpanded = index => {
        let newList = [...expandedList];
        const currentIndex = expandedList.indexOf(index);
        if (currentIndex !== -1) {
            newList = [...expandedList.slice(0, currentIndex), ...expandedList.slice(currentIndex + 1)];
        } else {
            newList.push(index);
        }
        updateExpandedList(newList);
    };

    const { display_name: ohiName = '', quartile: ohiQuartile = '', score: ohiScore = '' } = ohi_score;
    const ohiRecordOne = benchRecordOne.find(({ parent = '' }) => parent === ohiName) || {};
    const ohiRecordTwo = benchRecordTwo.find(({ parent: ohiParent = '' }) => ohiParent === ohiName) || {};
    const { difference: ohiOneDiff, significance: ohiOneSign } = ohiRecordOne.ohi_score || {};
    const { difference: ohiTwoDiff, significance: ohiTwoSign } = ohiRecordTwo.ohi_score || {};
    const { background: ohiBg, border: ohiBorder } = quartileColors[ohiQuartile] || {};
    const { bg: ohiOneBg } = sigValToUse[ohiOneSign] || {};
    const { bg: ohiTwoBg } = sigValToUse[ohiTwoSign] || {};
    const isIngClient = String(report_id) === ING_REPORT_ID;

    const getSelectedChild = (item, control) => {
        const { code = '', selectedOption = '' } = item;
        const bm_filters = [];
        if (control === 'left') {
            const { code: right = '', selectedOption: rightOpt = '' } =
                rightBenchmarks.filter(({ selectedOption: op }) => op)[0] || {};
            bm_filters.push(`${selectedOption}:${code}`);
            bm_filters.push(`${rightOpt}:${right}`);
        } else {
            const { code: left = '', selectedOption: leftOpt = '' } =
                leftBenchmarks.filter(({ selectedOption: op }) => op)[0] || {};
            bm_filters.push(`${leftOpt}:${left}`);
            bm_filters.push(`${selectedOption}:${code}`);
        }
        updateApiParams({ bm_filters });
    };

    const getPpt = (outcomeArray = [], prtArray = []) => {
        const { pptAccessToken = '' } = AuthStore;
        const output = {
            output_file_name: 'data.pptx',
            template_file_name,
            slides: [
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'outcome_key_peer_checkme',
                    data: outcomeArray,
                },
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'practice_key_peer_checkme',
                    data: prtArray,
                },
            ],
        };
        getPptData(output, pptAccessToken, `${report_name} Flagpole`);
    };

    const getDataForPpt = () => {
        const { benchmarks = [] } = filters[0];
        const label = getBenchmarkName(benchmarks);
        const label1 = getBenchmarkName(leftBenchmarks);
        const label2 = getBenchmarkName(rightBenchmarks);
        const { GLOBAL_VALS, benchmark } = PPT_VALUES({ rName });
        const footNote = isIngClient ? [{ name: 'footnote', value: localPractice }] : [];
        const pptArray = [
            {
                name: 'source',
                value: `${SOURCE_TEXT}: ${rName} (${NUMBER_TEXT}=${size}); ${BENCHMARK_HEAD}: ${label} (${NUMBER_TEXT}=${n_respondents}, ${NUMBER_SURVEY_TEXT}=${n_survey}); ${BENCHMARK_HEAD}: ${label1} (${NUMBER_TEXT}=${n_respondents1}, ${NUMBER_SURVEY_TEXT}=${n_survey1}); ${BENCHMARK_HEAD}: ${label2} (${NUMBER_TEXT}=${n_respondents2}, ${NUMBER_SURVEY_TEXT}=${n_survey2})`,
            },
            {
                name: 'Checkme',
                is_delete: 'true',
            },
            {
                name: 'ResultHeading',
                value: benchmark.title,
            },
            {
                name: 'benchmark1_text',
                value: label1,
            },
            {
                name: 'benchmark2_text',
                value: label2,
            },
            {
                name: '4. Footnote',
                value: BENCH_NOTE,
            },
            {
                name: 'benchmark1_subtext',
                value: ALL_PPT_TEXT[24],
            },
            {
                name: 'benchmark2_subtext',
                value: ALL_PPT_TEXT[24],
            },
            ...footNote,
            ...getPptValues(GLOBAL_VALS),
        ];
        const outcomeArray = [
            ...pptArray,
            {
                name: '3. Subtitle',
                value: isPercentile ? PPT_RES_TEXT3 : PPT_RES_TEXT,
            },
            {
                name: 'Title_text',
                value: ALL_PPT_TEXT[25],
            },
        ];
        const prtArray = [
            ...pptArray,
            {
                name: '3. Subtitle',
                value: isPercentile ? PPT_RES_TEXT3 : PPT_RES_TEXT2,
            },
            {
                name: 'Title 3',
                value: ALL_PPT_TEXT[25],
            },
        ];

        const parentChart = [
            {
                row_index: 1,
                column_index: 1,
                index: 0,
                value: ohiScore,
                bgcolor: ohiBg,
                border_color: ohiBorder,
            },
            {
                row_index: 1,
                column_index: 0,
                index: 0,
                value: ohiName,
                cat: ohiName,
            },
        ];
        const diffParentChart1 = [
            {
                row_index: 1,
                column_index: 0,
                index: 0,
                value: ohiOneDiff,
                bgcolor: ohiOneBg,
                border_color: ohiOneBg,
            },
        ];
        const diffParentChart2 = [
            {
                row_index: 1,
                column_index: 0,
                index: 0,
                value: ohiTwoDiff,
                bgcolor: ohiTwoBg,
                border_color: ohiTwoBg,
            },
        ];

        const table_data = [];
        const childChart = [];
        const diffChildChart1 = [];
        const diffChildChart2 = [];
        let indexInArr = 0;
        Object.keys(dataToIterate).forEach((innerContent, innerContentIndex) => {
            const { display_name, quartile = '', score: value = '', children = {} } = records[innerContent];
            const { background: bgcolor = $white, border: border_color = $white } = quartileColors[quartile] || {};
            const recordOne = benchRecordOne.find(({ parent: pOne = '' }) => pOne === display_name) || {};
            const recordTwo = benchRecordTwo.find(({ parent: pTwo = '' }) => pTwo === display_name) || {};
            const { difference: recordOneDiff, significance: recordOneSign } = recordOne[innerContent] || {};
            const { difference: recordTwoDiff, significance: recordTwoSign } = recordTwo[innerContent] || {};
            const { bg: recordOneBg } = sigValToUse[recordOneSign] || {};
            const { bg: recordTwoBg } = sigValToUse[recordTwoSign] || {};
            const index = innerContentIndex + 1;
            parentChart.push(
                {
                    row_index: index + 1,
                    column_index: 1,
                    index,
                    value,
                    bgcolor,
                    border_color,
                },
                {
                    row_index: index + 1,
                    column_index: 0,
                    index,
                    value: display_name,
                    cat: display_name,
                }
            );
            table_data.push({
                column_index: 0,
                row_index: innerContentIndex,
                value: display_name,
            });
            diffParentChart1.push({
                row_index: index + 1,
                column_index: 0,
                index,
                value: recordOneDiff,
                bgcolor: recordOneBg,
                border_color: recordOneBg,
            });
            diffParentChart2.push({
                row_index: index + 1,
                column_index: 0,
                index,
                value: recordTwoDiff,
                bgcolor: recordTwoBg,
                border_color: recordTwoBg,
            });
            const { children: recordOneChild = {} } = recordOne;
            const { children: recordTwoChild = {} } = recordTwo;
            Object.keys(children).forEach(childVal => {
                const { score: childScore, quartile: childQT, display_name: cDisplayName } = children[childVal];
                const { background = $white, border = $white } = quartileColors[childQT] || {};
                const { difference: recordOneChildDiff, significance: recordOneChildSign } = recordOneChild[childVal];
                const { difference: recordTwoChildDiff, significance: recordTwoChildSign } = recordTwoChild[childVal];
                const { bg: recordOneChildBg } = sigValToUse[recordOneChildSign] || {};
                const { bg: recordTwoChildBg } = sigValToUse[recordTwoChildSign] || {};
                childChart.push(
                    {
                        row_index: indexInArr + 1,
                        column_index: 1,
                        index: indexInArr,
                        value: childScore,
                        bgcolor: background,
                        border_color: border,
                    },
                    {
                        row_index: indexInArr + 1,
                        column_index: 0,
                        index: indexInArr,
                        value: cDisplayName,
                        cat: cDisplayName,
                    }
                );
                diffChildChart1.push({
                    row_index: indexInArr + 1,
                    column_index: 0,
                    index: indexInArr,
                    value: recordOneChildDiff,
                    bgcolor: recordOneChildBg,
                    border_color: recordOneChildBg,
                });
                diffChildChart2.push({
                    row_index: indexInArr + 1,
                    column_index: 0,
                    index: indexInArr,
                    value: recordTwoChildDiff,
                    bgcolor: recordTwoChildBg,
                    border_color: recordTwoChildBg,
                });
                indexInArr += 1;
            });
        });

        outcomeArray.push(
            { name: 'outcomes_chart', chart_data: parentChart },
            { name: 'benchmark1_chart', chart_data: diffParentChart1 },
            { name: 'benchmark2_chart', chart_data: diffParentChart2 }
        );
        prtArray.push(
            { name: 'outcomes_chart', chart_data: childChart },
            { name: 'benchmark1_chart', chart_data: diffChildChart1 },
            { name: 'benchmark2_chart', chart_data: diffChildChart2 },
            { name: 'TableHeader', is_delete: false, table_data }
        );
        getPpt(outcomeArray, prtArray);
    };

    const v4PptDownload = () => {
        const addOns = {
            fill_threshold_with: demographic === '1' ? '-' : '',
            others: demographic === '3' ? 1 : 0,
        };
        const { benchmarks = [] } = filters[0];
        const label = getBenchmarkName(benchmarks);
        const valueToSend = {
            ...SINGLE_PAGE_EXPORT_PARAMS({ BENCHMARK_NAME: label }),
            ...addOns,
            report_name: rName,
            ...apiParams,
            report_id,
            single_page_meta: {
                checkme: checkme_mapping.benchmark,
                name: 'benchmark',
            },
        };
        setdwnld(true);
        getReport(valueToSend);
    };

    return (
        <>
            <div className="benchmarkBoard clearfix">
                <Helmet>
                    <title>{HEALTH_BENCHMARK_BOARD}</title>
                </Helmet>

                <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <div className="mainHeading">{BENCHMARK_HEAD}</div>
                        <div className="nRes">
                            {NUMBER_OF_RESPONDENTS_N} : <span>{size.toLocaleString('en-US')}</span>
                        </div>
                    </div>
                    <ul className="navScoreList">
                        {!errorInfo && (
                            <li
                                data-testid="getPptButton"
                                onClick={isOhi4 ? v4PptDownload : getDataForPpt}
                                className="resurveyImg"
                            >
                                <img src={Down} alt="" />
                                <span className="lineHead upperHead">{DOWNLOAD}</span>
                            </li>
                        )}
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                            <img src={activeFilter && filterCheck.length > 0 ? ActiveFilters1 : FilterSvg} alt="" />
                            <span className="filter">{FILTERS}</span>
                        </li>
                    </ul>
                </div>

                {errorInfo && <ErrorToast message={errorInfo} />}
                {dwnld && !reportError && (
                    <DwnldModal
                        getRawPptData={getRawPptData}
                        reportId={report_id}
                        open={dwnld}
                        setOpen={setdwnld}
                        getSinglePastReport={getSinglePastReport}
                        singlePastReport={singlePastReport}
                        defaultSettings={defaultSettings}
                    />
                )}
                {Object.keys(scoreData).length ? (
                    <Fragment>
                        {appliedFiltersInfo()}
                        <section className="outcomeContent selectContent clearfix">
                            <div />
                            <div>{BUSINESS_TEXT}</div>
                            <SelectMenu
                                key="left"
                                control="left"
                                defaultSettings={defaultSettings}
                                benchmarks={leftBenchmarks}
                                getSelectedChild={getSelectedChild}
                                updateBenchmarks={updateLeftBenchmarks}
                            />
                            <SelectMenu
                                key="right"
                                control="right"
                                defaultSettings={defaultSettings}
                                benchmarks={rightBenchmarks}
                                getSelectedChild={getSelectedChild}
                                updateBenchmarks={updateRightBenchmarks}
                            />
                        </section>
                        <section className="outcomeContent surveyContent clearfix">
                            <div />
                            <div />
                            <div>
                                <span>
                                    {NUMBER_TEXT}: {n_respondents1.toLocaleString('en-US')}
                                </span>
                                <span>
                                    {NUMBER_CAP_SURVEY_TEXT}: {n_survey1}
                                </span>
                            </div>
                            <div>
                                <span>
                                    {NUMBER_TEXT}: {n_respondents2.toLocaleString('en-US')}
                                </span>
                                <span>
                                    {NUMBER_CAP_SURVEY_TEXT}: {n_survey2}
                                </span>
                            </div>
                        </section>
                        <section className="outcomeContent ohiContent clearfix">
                            <div className="ellipsisPro">{ohiName}</div>
                            <div>
                                <div className="rank">
                                    <div
                                        className="textBorder"
                                        style={{
                                            background: ohiBg,
                                            borderColor: ohiBorder,
                                            width: `${Math.abs(ohiScore)}%`,
                                        }}
                                    >
                                        <div className="textRank">{ohiScore}</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={c('rank', { negative: ohiOneDiff < 0 })}>
                                    <div
                                        className="textBorder"
                                        style={{
                                            background: ohiOneBg,
                                            width: `${2 * Math.abs(ohiOneDiff)}%`,
                                        }}
                                    >
                                        <div className="textRank">{ohiOneDiff}</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={c('rank', { negative: ohiTwoDiff < 0 })}>
                                    <div
                                        className="textBorder"
                                        style={{
                                            background: ohiTwoBg,
                                            width: `${2 * Math.abs(ohiTwoDiff)}%`,
                                        }}
                                    >
                                        <div className="textRank">{ohiTwoDiff}</div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {Object.keys(dataToIterate).map((dataSet, index) => {
                            const { display_name = '', quartile = '', score = '', children = {} } = dataToIterate[
                                dataSet
                            ];
                            const recordOne =
                                benchRecordOne.find(({ parent: pOne = '' }) => pOne === display_name) || {};
                            const recordTwo =
                                benchRecordTwo.find(({ parent: pTwo = '' }) => pTwo === display_name) || {};
                            const { difference: recordOneDiff, significance: recordOneSign } = recordOne[dataSet] || {};
                            const { difference: recordTwoDiff, significance: recordTwoSign } = recordTwo[dataSet] || {};
                            const { children: recordOneChild = {} } = recordOne;
                            const { children: recordTwoChild = {} } = recordTwo;
                            const { background = $white, border: borderColor = $white } =
                                quartileColors[quartile] || {};
                            const { bg: recordOneBg } = sigValToUse[recordOneSign] || {};
                            const { bg: recordTwoBg } = sigValToUse[recordTwoSign] || {};
                            return (
                                <Accordion
                                    classes={{ root: 'accordionRoot' }}
                                    key={index}
                                    expanded={expandedList.includes(index)}
                                >
                                    <AccordionSummary
                                        data-testid={dataSet}
                                        expandIcon={<ExpandMoreIcon />}
                                        onClick={() => updateExpanded(index)}
                                        classes={{ content: 'accordionHead' }}
                                    >
                                        <section className="outcomeContent clearfix">
                                            <div className="ellipsisPro">{display_name}</div>
                                            <div>
                                                <div className="rank">
                                                    <div
                                                        className="textBorder"
                                                        style={{
                                                            background,
                                                            borderColor,
                                                            width: `${Math.abs(score)}%`,
                                                        }}
                                                    >
                                                        <div className="textRank">{score}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className={c('rank', { negative: recordOneDiff < 0 })}>
                                                    <div
                                                        className="textBorder"
                                                        style={{
                                                            background: recordOneBg,
                                                            width: `${2 * Math.abs(recordOneDiff)}%`,
                                                        }}
                                                    >
                                                        <div className="textRank">{recordOneDiff}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className={c('rank', { negative: recordTwoDiff < 0 })}>
                                                    <div
                                                        className="textBorder"
                                                        style={{
                                                            background: recordTwoBg,
                                                            width: `${2 * Math.abs(recordTwoDiff)}%`,
                                                        }}
                                                    >
                                                        <div className="textRank">{recordTwoDiff}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </AccordionSummary>
                                    <AccordionDetails classes={{ root: 'clearfix' }}>
                                        {Object.keys(children).map(childSet => {
                                            const {
                                                display_name: childName = '',
                                                score: childScore = '',
                                                quartile: childQuartile,
                                            } = children[childSet];
                                            const { difference: recordOneChildDiff, significance: recordOneChildSign } =
                                                recordOneChild[childSet] || {};
                                            const { difference: recordTwoChildDiff, significance: recordTwoChildSign } =
                                                recordTwoChild[childSet] || {};
                                            const { background: childBg = $white, border: childBd = $white } =
                                                quartileColors[childQuartile] || {};
                                            const styleBlack =
                                                childQuartile === '' || childQuartile === '999'
                                                    ? { border: '2px solid ' + colors.$grey200 }
                                                    : {};
                                            const { bg: recordOneChildBg } = sigValToUse[recordOneChildSign] || {};
                                            const { bg: recordTwoChildBg } = sigValToUse[recordTwoChildSign] || {};
                                            return (
                                                <section className="outcomeContent clearfix" key={childName}>
                                                    <div className="ellipsisPro">{childName}</div>
                                                    <div>
                                                        <div className="rank">
                                                            <div
                                                                className="textBorder"
                                                                style={{
                                                                    background: childBg,
                                                                    borderColor: childBd,
                                                                    width: `${Math.abs(childScore)}%`,
                                                                    ...styleBlack,
                                                                }}
                                                            >
                                                                <div className="textRank">{childScore}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div
                                                            className={c('rank', { negative: recordOneChildDiff < 0 })}
                                                        >
                                                            <div
                                                                className="textBorder"
                                                                style={{
                                                                    background: recordOneChildBg,
                                                                    width: `${2 * Math.abs(recordOneChildDiff)}%`,
                                                                }}
                                                            >
                                                                <div className="textRank">{recordOneChildDiff}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div
                                                            className={c('rank', { negative: recordTwoChildDiff < 0 })}
                                                        >
                                                            <div
                                                                className="textBorder"
                                                                style={{
                                                                    background: recordTwoChildBg,
                                                                    width: `${2 * Math.abs(recordTwoChildDiff)}%`,
                                                                }}
                                                            >
                                                                <div className="textRank">{recordTwoChildDiff}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            );
                                        })}
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                        {isIngClient ? (
                            <div>
                                <div>{localPractice}</div>
                            </div>
                        ) : null}
                        {/* {appliedFiltersInfo()} */}
                        <div className="note">
                            <b>{NOTE}:</b>
                            <br />
                            1. {BENCH_NOTE}
                            <br />
                            2. {PERCENT_FAVORABLE}
                        </div>
                    </Fragment>
                ) : null}
            </div>
            <ul className="fixedScoreCardSurvey">
                <ul className="bottomList clearfix">
                    <li>{DIFF_HEAD} : </li>
                    {sigValToUse.map(({ title, bg: background }) => (
                        <li key={title}>
                            <span style={{ background }} />
                            {title}
                        </li>
                    ))}
                </ul>
                <ul className="benchmark clearfix">
                    <li>{BENCHMARK_HEAD} : </li>
                    {quartileColors
                        .slice()
                        .reverse()
                        .map(({ title, background, border: borderColor }) => {
                            const bottomDecileVar = title === BOTTOM_DECILE;
                            const BenchmarkTitle = title === NO_BENCHMARK;
                            return (
                                <li
                                    key={title}
                                    style={{ color: background }}
                                    className={c({
                                        hide: bottomDecileVar || BenchmarkTitle,
                                    })}
                                >
                                    <span
                                        className="benchmarkTriangle"
                                        style={{
                                            background,
                                            borderColor,
                                            borderStyle: 'solid',
                                        }}
                                    />
                                    <div style={{ color: 'black' }}>{title}</div>
                                </li>
                            );
                        })}
                    <li>
                        <span style={{ background: colors.$white, border: '1.5px solid #e6e6e6' }} />
                        {BENCHMARK_NA}
                    </li>
                </ul>
            </ul>
        </>
    );
}

BenchmarkBoard.propTypes = {
    errorInfo: PropTypes.string.isRequired,
    apiParams: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    scoreData: PropTypes.object.isRequired,
    scoreDataFetched: PropTypes.bool.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    leftBenchmarks: PropTypes.array.isRequired,
    rightBenchmarks: PropTypes.array.isRequired,
    filters: PropTypes.array.isRequired,
    getScoreData: PropTypes.func.isRequired,
    updateApiParams: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    updateLeftBenchmarks: PropTypes.func.isRequired,
    updateRightBenchmarks: PropTypes.func.isRequired,
    getPptData: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    reportError: PropTypes.string.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    singlePastReport: PropTypes.object.isRequired,
    localPractice: PropTypes.string.isRequired,
};

export default BenchmarkBoard;
