import React, { Fragment, useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';

const SuccessDialog = ({ feedback, setView, toggleConfirmModal, resetFeedback, setTicketId }) => {
    const { id, jira_number } = feedback || {};
    useEffect(() => {
        if (id) {
            setTicketId(id);
        }
        // eslint-disable-next-line
    }, [feedback]);

    return (
        <Dialog
            open
            disableEscapeKeyDown
            disableBackdropClick
            maxWidth="md"
            classes={{
                root: 'feedbackSuccessDialogRoot',
            }}
        >
            <section className="dataContent dataHead">
                <h2>Feedback sent successfully</h2>
                <div className="nRes">
                    {'Jira ticket ID : '} <b>{jira_number}</b>
                </div>
                <div className="para">
                    Thank you for registering the issue in OHI-Analytics Portal. You will be notified via email through
                    out the resolution process.
                </div>
                <div className="para">
                    Reach out to OHI-Helpdesk team at <b>ohi-helpdesk@mckinsey.com</b> any point if you have any
                    questions.
                </div>
            </section>

            <section className="buttonContent clearfix activeButtons">
                <Fragment>
                    <div
                        data-testid="detailButton"
                        className="detailedBtn aciveDetailBtn"
                        onClick={() => {
                            toggleConfirmModal();
                            resetFeedback();
                        }}
                    >
                        Done
                    </div>
                    <div
                        data-testid="compareButton"
                        className="compareBtn activeCompareBtn"
                        onClick={() => {
                            setView(2);
                            resetFeedback();
                        }}
                    >
                        View Details
                    </div>
                </Fragment>
            </section>
        </Dialog>
    );
};

SuccessDialog.propTypes = {
    toggleConfirmModal: PropTypes.func.isRequired,
    setView: PropTypes.func.isRequired,
    feedback: PropTypes.object.isRequired,
    resetFeedback: PropTypes.func.isRequired,
    setTicketId: PropTypes.func.isRequired,
};

export default SuccessDialog;
