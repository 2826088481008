import { call, put, takeLatest } from 'redux-saga/effects';

import {
    getActionOptionsSuccess,
    getActionOptionsFailed,
    getCompValuesSuccess,
    getCompValuesFailed,
    toggleActionUsabilitySuccess,
    toggleActionUsabilityFailed,
    toggleActionVoteSuccess,
    toggleActionVoteFailed,
    addCompActionSuccess,
    addCompActionFailed,
} from './actions';
import { GET_ACTION_LIST, GET_COMP_VALUES, ACTION_USABILITY, ACTION_VOTE, ADD_NEW_ACTION } from './constants';
import { fetchActionFilterUrl, getCompValueUrl, actionUsabilityUrl, actionVoteUrl, addCompendiumUrl } from './apis';

export function* getActionListSaga() {
    try {
        const { data = {} } = yield call(fetchActionFilterUrl);
        const { data: optionData = {} } = data;
        const optionList = {};
        const { outcome, ...keysToUse } = optionData;
        Object.keys(keysToUse).forEach(innerKey => {
            optionList[innerKey] = [];
        });
        yield put(getActionOptionsSuccess({ optionList, optionData }));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getActionOptionsFailed({ message, error, status }));
    }
}

export function* getCompValuesSaga({ payload }) {
    try {
        const { data = {} } = yield call(getCompValueUrl, payload);
        const { data: compData = {} } = data;
        yield put(getCompValuesSuccess(compData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getCompValuesFailed({ message, error, status }));
    }
}

export function* toggleActionUsabilitySaga({ payload }) {
    try {
        const { data } = yield call(actionUsabilityUrl, payload);
        yield put(toggleActionUsabilitySuccess(data));
    } catch ({ response }) {
        yield put(toggleActionUsabilityFailed(response));
    }
}

export function* toggleActionVoteSaga({ payload }) {
    try {
        const { data } = yield call(actionVoteUrl, payload);
        yield put(toggleActionVoteSuccess(data));
    } catch ({ response }) {
        yield put(toggleActionVoteFailed(response));
    }
}

export function* addCompendiumsSaga({ payload }) {
    try {
        yield call(addCompendiumUrl, payload);
        yield put(addCompActionSuccess());
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(addCompActionFailed({ message, error, status }));
    }
}

const settingsSagas = [
    takeLatest(`${GET_ACTION_LIST}_PENDING`, getActionListSaga),
    takeLatest(`${GET_COMP_VALUES}_PENDING`, getCompValuesSaga),
    takeLatest(`${ADD_NEW_ACTION}_PENDING`, addCompendiumsSaga),
    takeLatest(ACTION_USABILITY, toggleActionUsabilitySaga),
    takeLatest(ACTION_VOTE, toggleActionVoteSaga),
];

export default settingsSagas;
