import React, { useEffect, useState, useMemo, Fragment } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Accordion, AccordionDetails, AccordionSummary, Button, TextField, Tooltip } from '@material-ui/core';
import {
    Info,
    ThumbUp,
    ThumbUpTwoTone,
    ExpandMore,
    AddCircleOutlineOutlined,
    CheckCircleOutlineOutlined,
} from '@material-ui/icons';
import c from 'classnames';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Helmet } from 'react-helmet';

import AddActionModal from './AddActionModal';
import { COMP_KEYS } from './constants';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import { getStaticText } from '../../utils/constants';
import { removeUnderscore } from '../../utils/functions';
import './index.scss';
import ErrorToast from '../../components/InformationToast/ErrorToast';

function ActionCompendium({
    apiCount,
    optionList,
    optionData,
    optionDataFetched,
    getActionOptions,
    updateActionList,
    compendiumData,
    getCompValues,
    updateCompData,
    compDataFetched,
    errorInfo,
    toggleActionUsability,
    toggleActionVote,
}) {
    const staticText = getStaticText();
    const { SITE_TEXT } = staticText;
    const {
        ACTION_COMP_HEADER,
        ACTION_COMP_TEXT,
        GEN_LIST,
        YES,
        ADD_ACTION,
        COMPENDIUM_OPTION_KEYS,
        ACTION_COMPENDIUM,
    } = SITE_TEXT;
    const OPTION_KEYS = {
        recipe: COMPENDIUM_OPTION_KEYS.recipe,
        practice: COMPENDIUM_OPTION_KEYS.practice,
        level: COMPENDIUM_OPTION_KEYS.level,
        influence_model: (
            <ul>
                <li>{COMPENDIUM_OPTION_KEYS.influence_model[0]}</li>
                <li>{COMPENDIUM_OPTION_KEYS.influence_model[1]}</li>
                <li>{COMPENDIUM_OPTION_KEYS.influence_model[2]}</li>
                <li>{COMPENDIUM_OPTION_KEYS.influence_model[3]}</li>
            </ul>
        ),
    };
    const [expandedList, updateExpandedList] = useState([0]);
    const [showAddModal, updateModal] = useState(false);

    useEffect(() => {
        if (!optionDataFetched && !apiCount) {
            getActionOptions();
        }
        // eslint-disable-next-line
    }, [optionDataFetched]);

    const active = useMemo(() => Object.keys(optionList).filter(x => optionList[x].length).length, [optionList]);

    const generateList = () => {
        const selectedObj = {};
        Object.keys(optionList).forEach(innerSel => {
            selectedObj[innerSel] = optionList[innerSel].map(({ value }) => value).join(',');
        });
        getCompValues(selectedObj);
    };

    const updateExpanded = index => {
        let newList = [...expandedList];
        const currentIndex = expandedList.indexOf(index);
        if (currentIndex !== -1) {
            newList = [...expandedList.slice(0, currentIndex), ...expandedList.slice(currentIndex + 1)];
        } else {
            newList.push(index);
        }
        updateExpandedList(newList);
    };

    const updateCompendium = (pKey, cIndex, key, val, compendium_id, toggleUsablitiy = false, count = 0) => {
        const dataToSend = {
            compendium_id,
            flag: val,
        };
        if (toggleUsablitiy) {
            toggleActionUsability(dataToSend);
        } else {
            const countToUse = isNaN(count) ? 0 : count;
            const voteCount = val ? countToUse + 1 : countToUse - 1;
            updateCompData({ pKey, cIndex, key: 'vote_count', val: voteCount });
            toggleActionVote(dataToSend);
        }
        updateCompData({ pKey, cIndex, key, val });
    };

    return (
        <Layout showHeader showTabAction={false}>
            <div className="actionCompendium clearfix">
                <Helmet>
                    <title>{ACTION_COMPENDIUM}</title>
                </Helmet>
                {apiCount ? <Loader position="absolute" showBackground background="transparent" /> : null}
                {!isEmpty(optionList) && (
                    <section className="topSection clearfix">
                        <h2>
                            {ACTION_COMP_HEADER}
                            <span data-testid="openModalBtn" onClick={() => updateModal(true)}>
                                <AddCircleOutlineOutlined /> {ADD_ACTION}
                            </span>
                        </h2>
                        <p className="meta">{ACTION_COMP_TEXT}</p>
                        <div className="clearfix">
                            {Object.keys(OPTION_KEYS).map(innerKey => (
                                <div key={innerKey} className="optionContent">
                                    <Autocomplete
                                        data-testid={innerKey}
                                        multiple
                                        limitTags={1}
                                        options={optionData[innerKey]}
                                        getOptionLabel={({ value }) => value}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label={removeUnderscore(innerKey)}
                                            />
                                        )}
                                        value={optionList[innerKey]}
                                        onChange={(e, innerValue) => updateActionList({ innerKey, innerValue })}
                                        getOptionSelected={({ value }, { value: selectedVal }) => value === selectedVal}
                                    />
                                    <Tooltip
                                        classes={{
                                            tooltip: 'actionOptionTip',
                                        }}
                                        arrow
                                        title={OPTION_KEYS[innerKey]}
                                    >
                                        <Info />
                                    </Tooltip>
                                </div>
                            ))}
                        </div>
                        <div className="buttonContent clearfix">
                            <Button
                                data-testid="generateListBtn"
                                onClick={generateList}
                                classes={{ root: c({ active }) }}
                            >
                                {GEN_LIST}
                            </Button>
                        </div>
                    </section>
                )}
                {errorInfo && <ErrorToast message={errorInfo} />}
                {compDataFetched && !isEmpty(compendiumData) ? (
                    <section className="contentSection clearfix">
                        {Object.keys(compendiumData).map((dataSet, index) => {
                            const { practice_name = '', compendiums = [], ...paras } = compendiumData[dataSet];
                            return (
                                <Accordion
                                    classes={{ root: 'accordionRoot' }}
                                    key={index}
                                    expanded={expandedList.includes(index)}
                                >
                                    <AccordionSummary
                                        data-testid={dataSet}
                                        expandIcon={<ExpandMore />}
                                        onClick={() => updateExpanded(index)}
                                        classes={{ content: 'accordionHead' }}
                                    >
                                        <section className="outcomeContent clearfix">
                                            <div className="ellipsisPro">
                                                {practice_name} ({compendiums.length})
                                            </div>
                                        </section>
                                    </AccordionSummary>
                                    <AccordionDetails classes={{ root: 'clearfix' }}>
                                        <section className="paraContent">
                                            {Object.keys(paras).map((pContent, pIndex) => (
                                                <div key={pIndex}>
                                                    <h3>{SITE_TEXT[pContent]}</h3>
                                                    <p>{paras[pContent]}</p>
                                                </div>
                                            ))}
                                        </section>
                                        {compendiums.length ? (
                                            <div className="stripContent">
                                                {compendiums.map((childSet, childSetIndex) => {
                                                    const valueArray = COMP_KEYS;
                                                    const valueLength = valueArray.length;

                                                    const actionKey1 = valueArray[valueLength - 2];
                                                    const actionValue1 = childSet[valueArray[valueLength - 2]];
                                                    const actionKey2 = valueArray[valueLength - 1];
                                                    const actionValue2 = childSet[valueArray[valueLength - 1]];

                                                    return (
                                                        <Fragment key={childSetIndex}>
                                                            {!childSetIndex ? (
                                                                <section className="detailContent">
                                                                    {valueArray.map((compKey, cKIndex) => (
                                                                        <span key={cKIndex}>
                                                                            {removeUnderscore(compKey)}
                                                                        </span>
                                                                    ))}
                                                                </section>
                                                            ) : null}
                                                            <section className="detailContent">
                                                                {valueArray
                                                                    .slice(0, valueLength - 2)
                                                                    .map((compVal, cIndex) => (
                                                                        <span key={cIndex}>{childSet[compVal]}</span>
                                                                    ))}
                                                                <span
                                                                    data-testid={`Agree${childSetIndex}`}
                                                                    onClick={() =>
                                                                        updateCompendium(
                                                                            dataSet,
                                                                            childSetIndex,
                                                                            actionKey1,
                                                                            !actionValue1,
                                                                            childSet.id,
                                                                            true
                                                                        )
                                                                    }
                                                                >
                                                                    <span
                                                                        className={c('borderText', {
                                                                            active: actionValue1,
                                                                        })}
                                                                    >
                                                                        {actionValue1 && <CheckCircleOutlineOutlined />}
                                                                        {YES}
                                                                    </span>
                                                                </span>
                                                                <span
                                                                    data-testid={`Vote${childSetIndex}`}
                                                                    onClick={() =>
                                                                        updateCompendium(
                                                                            dataSet,
                                                                            childSetIndex,
                                                                            actionKey2,
                                                                            !actionValue2,
                                                                            childSet.id,
                                                                            false,
                                                                            childSet.vote_count
                                                                        )
                                                                    }
                                                                >
                                                                    {childSet.vote_count ? childSet.vote_count : null}
                                                                    {actionValue2 ? <ThumbUp /> : <ThumbUpTwoTone />}
                                                                </span>
                                                            </section>
                                                        </Fragment>
                                                    );
                                                })}
                                            </div>
                                        ) : null}
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                    </section>
                ) : null}
                {showAddModal && <AddActionModal closeModal={updateModal} optionListInfo={OPTION_KEYS} />}
            </div>
        </Layout>
    );
}

ActionCompendium.propTypes = {
    apiCount: PropTypes.number.isRequired,
    optionList: PropTypes.object.isRequired,
    optionData: PropTypes.object.isRequired,
    compendiumData: PropTypes.object.isRequired,
    optionDataFetched: PropTypes.bool.isRequired,
    compDataFetched: PropTypes.bool.isRequired,
    errorInfo: PropTypes.string.isRequired,
    getActionOptions: PropTypes.func.isRequired,
    updateActionList: PropTypes.func.isRequired,
    getCompValues: PropTypes.func.isRequired,
    updateCompData: PropTypes.func.isRequired,
    toggleActionUsability: PropTypes.func.isRequired,
    toggleActionVote: PropTypes.func.isRequired,
};

export default ActionCompendium;
