import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, IconButton, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { getStaticText } from '../../../utils/constants';
import getStaticTextDiagnose from '../constants';
import './index.scss';

const BarChartModal = ({
    closeModal,
    recipe,
    highScoreKey,
    highScoreIndex,
    projects,
    selectedIds,
    changeCurrentPage,
    setPracticeDisplay,
    rName,
    pulseSurvey,
    defaultSettings,
}) => {
    const { lang } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { BAR_CHART_SCORE, recipeImages } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { options = {}, values = [[]] } = recipe;
    const recipeToUse = highScoreIndex !== null ? options : recipe;

    const { title: mainTitle = '', score: mainScore = 0 } = recipeToUse[highScoreKey];
    const scoreToShow = highScoreIndex !== null ? values[0][highScoreIndex] : mainScore;
    const { COMPANY_RESULTS, COMPANY_RESULTS_TEXT, VIEW_PRACTICE, BAR_CHART_NOTE, NOTE, BAR_CHART_LEGENDS } =
        SITE_TEXT || {};

    const goToPractice = () => {
        setPracticeDisplay(mainTitle);
        changeCurrentPage(5);
    };

    return (
        <Dialog
            open
            onClose={() => closeModal(false)}
            maxWidth="sm"
            classes={{
                root: 'dialogRoot',
            }}
        >
            <section className="headContent">
                <IconButton aria-label="close" className="closeButton" onClick={() => closeModal(false)}>
                    <CloseIcon />
                </IconButton>
                <span>{scoreToShow}</span>
                <h2>{mainTitle}</h2>
            </section>
            <section className="dataContent clearfix">
                <div className="chartContentOuter">
                    <ul className="chartLegends">
                        {BAR_CHART_LEGENDS.map(legend => (
                            <li key={legend}>{legend}</li>
                        ))}
                    </ul>
                    <div className="chartContent">
                        <div className="midLine" />
                        <div className="rightLine" />
                    </div>
                    <ul className="chartScore">
                        {BAR_CHART_SCORE.map(scorePoint => (
                            <li key={scorePoint}>{scorePoint}</li>
                        ))}
                    </ul>
                </div>
                {Object.keys(recipeToUse).map((recipeSet, recipeIndex) => {
                    const { title = '', description = '', score = 0 } = recipeToUse[recipeSet];
                    return (
                        <div key={recipeSet} className="tableData">
                            <div className="textContent">
                                <h3 className="ellipsisPro">
                                    <img src={recipeImages[recipeIndex]} alt="" />
                                    {title}
                                </h3>
                                <p>{description}</p>
                            </div>
                            {highScoreIndex === null && (
                                <div className="barContent">
                                    <div style={{ width: `${score * 10}%` }}>{score}</div>
                                </div>
                            )}
                            {highScoreIndex !== null && (
                                <div className={`barContent barPoints${values.length}`}>
                                    {values.map((valuePoint, valueIndex) => (
                                        <div key={valueIndex} style={{ width: `${valuePoint[recipeIndex] * 10}%` }}>
                                            {valuePoint[recipeIndex]}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    );
                })}
            </section>
            {!pulseSurvey && (
                <section className="buttonContent clearfix">
                    <Button data-testid="setPracticeBtn" onClick={goToPractice}>
                        {VIEW_PRACTICE}
                    </Button>
                </section>
            )}
            {highScoreIndex !== null ? (
                <ul className="legends clearfix">
                    <li>
                        <span /> {COMPANY_RESULTS} {rName} {COMPANY_RESULTS_TEXT}
                    </li>
                    {selectedIds.map(idIndex => {
                        const { ohid, year } = projects[idIndex];
                        return (
                            <li key={idIndex}>
                                <span />
                                {ohid}-{year}
                            </li>
                        );
                    })}
                </ul>
            ) : null}
            <div className="note">
                <b>{NOTE}:</b>
                {BAR_CHART_NOTE}
            </div>
        </Dialog>
    );
};

BarChartModal.defaultProps = {
    highScoreIndex: null,
};

BarChartModal.propTypes = {
    pulseSurvey: PropTypes.bool.isRequired,
    projects: PropTypes.array.isRequired,
    selectedIds: PropTypes.array.isRequired,
    highScoreIndex: PropTypes.number,
    highScoreKey: PropTypes.string.isRequired,
    rName: PropTypes.string.isRequired,
    recipe: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    setPracticeDisplay: PropTypes.func.isRequired,
    changeCurrentPage: PropTypes.func.isRequired,
};

export default BarChartModal;
