import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import UserReports from './UserReports';
import userReports from './reducer';
import userReportsSagas from './sagas';

import { getReports } from './actions';
import { updateApiParams } from '../Diagnose/actions';
import { fetchDefaultSettings } from '../Login/actions';
import { selectLoginLoading, getDefaultSettings, selectUserData } from '../Login/selector';
import { selectApiCount, selectReportsData, selectReportsFetched, getErrorInfo } from './selector';

const stateToProps = createStructuredSelector({
    apiLoadingCount: selectApiCount(),
    loginLoadingCount: selectLoginLoading(),
    reportsData: selectReportsData(),
    reportsDataFetched: selectReportsFetched(),
    errorInfo: getErrorInfo(),
    defaultSettings: getDefaultSettings(),
    // new
    userData: selectUserData(),
});

const dispatchToProps = { getReports, updateApiParams, fetchDefaultSettings };

export { userReportsSagas, userReports };

export default connect(stateToProps, dispatchToProps)(UserReports);
