import { createSelector } from 'reselect';

const settings = state => state.get('settings');

const selectApiCount = () => createSelector(settings, state => state.toJS().count);
const selectSettingsData = () => createSelector(settings, state => state.toJS().settings);
const selectSettingsFetched = () => createSelector(settings, state => state.toJS().settingsFetched);
const getErrorInfo = () => createSelector(settings, state => state.toJS().error);

export { selectApiCount, selectSettingsData, selectSettingsFetched, getErrorInfo };
