import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    IconButton,
    DialogActions,
    DialogContent,
    MenuItem,
    Select,
    TextField,
    FormControl,
    InputLabel,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import Loader from '../Loader';
import { getStaticText } from '../../utils/constants';
import CloseIcon from '../../assets/Close.svg';
import SearchIcon from '../../assets/Magnifying.svg';
import { getResRateConfig, postResRateConfig } from '../../containers/ResponseRate/apis';
import './index.scss';

const AddInput = ({ setAddCustomInput, demographics, apiParams, defaultSettings }) => {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SUBMIT, CLOSE, UPDATE_TARGET, OVERALL, DEMOGRAPHICS, SELECT_ONE } = staticText.SITE_TEXT || {};
    const [dropDownValue, setDropDownValue] = useState(demographics[0].code);
    const [demoChildren, setDemoChildren] = useState([]);
    const [countObj, setCountObj] = useState({});
    const [reloadSiteData, setReloadParam] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const { ohid } = apiParams;
    const [pageData, setPageData] = useState([]);
    const { overall: overallArr = [], data: dataX = [] } = pageData;
    const cloneObj = cloneDeep(countObj);

    const setCount = dataArr => {
        const obj = {};
        for (let i = 0; i < dataArr.length; i += 1) {
            const { id, resp_count } = dataArr[i];
            obj[id] = resp_count;
        }
        setCountObj(obj);
    };

    const setDemographicArr = () => {
        const arr = dataX;
        const keys = Object.keys(countObj);
        for (let i = 0; i < keys.length; i += 1) {
            const count = Number(countObj[keys[i]]);
            if (count && count >= 0) {
                arr[i].resp_count = count;
            } else {
                arr[i].resp_count = 0;
            }
        }
        return arr;
    };

    async function getDataForDemo(apiValues) {
        setIsLoading(true);
        try {
            const { data: valueData = {} } = await getResRateConfig({
                ohid,
                lang: parseInt(lang, 10),
                demographics: apiValues,
            });
            const { data = {} } = valueData;
            setPageData(data);
            setCount(data.data);
            setDemoChildren(data.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(!error);
        }
    }
    async function postDataForDemo(payload) {
        setIsLoading(true);
        try {
            await postResRateConfig(payload);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(!error);
        }
    }

    const renderSection = (itemId, label) => {
        const id = String(itemId);
        return (
            <Box className="addCountBox" key={itemId}>
                <span className="ellipsisPro">{label}</span>
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="inputBox"
                    value={countObj[id]}
                    onChange={e => {
                        cloneObj[id] = e.target.value;
                        setCountObj(cloneObj);
                    }}
                />
            </Box>
        );
    };

    useEffect(() => {
        const demoParent = dropDownValue || demographics[0].code;
        getDataForDemo(demoParent);
        // eslint-disable-next-line
    }, [dropDownValue]);

    const selectOptions = ({ value }) => {
        if (value.trim()) {
            setDemoChildren(
                dataX.filter(({ child_display_name }) => child_display_name.toLowerCase().includes(value.toLowerCase()))
            );
        } else {
            setDemoChildren(dataX);
        }
        setSearch(value);
    };

    const resetSearch = () => {
        setSearch('');
        setDemoChildren(dataX);
    };

    return (
        <Dialog
            open
            disableEscapeKeyDown
            disableBackdropClick
            fullWidth
            className="addCountModal"
            onClose={() => setAddCustomInput(false, reloadSiteData)}
        >
            <section className="headContent">
                <IconButton
                    aria-label="close"
                    className="closeButton"
                    onClick={() => setAddCustomInput(false, reloadSiteData)}
                >
                    <Close />
                </IconButton>
            </section>
            <DialogContent>
                <h4>{UPDATE_TARGET}</h4>
                {overallArr[0] && (
                    <Box className="addCountBox">
                        <span>{OVERALL}</span>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            className="inputBox"
                            key={overallArr[0].id}
                            value={overallArr[0].resp_count}
                            onChange={e => {
                                const obj1 = {
                                    ...overallArr[0],
                                    resp_count: Number(e.target.value),
                                };
                                setPageData({ ohid, lang: parseInt(lang, 10), data: dataX, overall: [obj1] });
                            }}
                        />
                    </Box>
                )}
                <FormControl classes={{ root: 'mainFormInput' }}>
                    <InputLabel>{DEMOGRAPHICS}</InputLabel>
                    <Select
                        value={dropDownValue}
                        MenuProps={{
                            classes: { paper: 'addCountDrop' },
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                        onChange={({ target: { value: code } }) => {
                            if (code) {
                                setDropDownValue(code);
                                setIsLoading(true);
                            }
                        }}
                    >
                        <MenuItem classes={{ root: 'demographicOptions' }} value="">
                            <em>{SELECT_ONE}</em>
                        </MenuItem>
                        {demographics.map(({ code = '', label = '' }) => {
                            return (
                                <MenuItem key={code} classes={{ root: 'demographicOptions' }} value={code}>
                                    {label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                <div className="searchHead">
                    <img src={SearchIcon} alt="" className="searchIcon" />
                    <TextField
                        fullWidth
                        value={search}
                        placeholder="Search"
                        onChange={({ target }) => selectOptions(target)}
                    />
                    <img src={CloseIcon} alt="" className="closeIcon" onClick={resetSearch} />
                </div>
                {isLoading && <Loader position="absolute" showBackground background="transparent" />}
                <div className="childWrapper">
                    {demoChildren &&
                        demoChildren.map(({ id, child_display_name }) => renderSection(id, child_display_name))}
                </div>
            </DialogContent>
            <DialogActions className="dialogActions">
                <Button
                    onClick={() => {
                        const { id = '', resp_count = '' } = overallArr[0] || {};
                        setIsLoading(true);
                        const obj1 = {
                            lang: parseInt(lang, 10),
                            ohid,
                            overall: {
                                id,
                                resp_count,
                            },
                            demographics: setDemographicArr(),
                        };
                        postDataForDemo(obj1);
                        setReloadParam(true);
                        getDataForDemo(dropDownValue);
                    }}
                >
                    {SUBMIT}
                </Button>
                <Button onClick={() => setAddCustomInput(false, reloadSiteData)}>{CLOSE}</Button>
            </DialogActions>
        </Dialog>
    );
};

AddInput.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    setAddCustomInput: PropTypes.func.isRequired,
    demographics: PropTypes.array.isRequired,
    apiParams: PropTypes.object.isRequired,
};
export default AddInput;
