import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Profile from './Profile';

import { getScoreData, getPptData, getSinglePastReport } from '../actions';

import {
    selectScoreData,
    selectScoreDataFetched,
    selectApiParams,
    selectApiCount,
    selectFiltersApplied,
    getErrorInfo,
    selectCurrentTheme,
    selectFilters,
    selectPastReport,
} from '../selector';

import { getDefaultSettings } from '../../Login/selector';
import { selectReportError } from '../../Reports/selector';
import { getReport, getRawPptData } from '../../Reports/actions';

const stateToProps = createStructuredSelector({
    scoreData: selectScoreData(),
    scoreDataFetched: selectScoreDataFetched(),
    apiParams: selectApiParams(),
    apiLoadingCount: selectApiCount(),
    activeFilter: selectFiltersApplied(),
    errorInfo: getErrorInfo(),
    currentTheme: selectCurrentTheme(),
    filters: selectFilters(),
    defaultSettings: getDefaultSettings(),
    reportError: selectReportError(),
    singlePastReport: selectPastReport(),
});

const dispatchToProps = { getScoreData, getPptData, getReport, getRawPptData, getSinglePastReport };

export default connect(stateToProps, dispatchToProps)(Profile);
