import { call, put, takeLatest } from 'redux-saga/effects';
import {
    getDailyResponsesSuccess,
    getDailyResponsesFailed,
    getDemoResponsesSuccess,
    getDemoResponsesFailed,
    getCrossTabSuccess,
    getCrossTabFailed,
} from './actions';

import { GET_DAILY_RES, GET_DEMO_RES, GET_CROSS_TAB } from './constants';

import { getDailyRespUrl, getDemoResUrl, crossTapPathsUrl } from './apis';

export function* getDailyResSaga({ payload }) {
    try {
        const { data = {} } = yield call(getDailyRespUrl, payload);
        const { data: dailyResData } = data;
        yield put(getDailyResponsesSuccess(dailyResData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getDailyResponsesFailed({ message, error, status }));
    }
}

export function* getDemoResSaga({ payload }) {
    try {
        const { data = {} } = yield call(getDemoResUrl, payload);
        const { data: demographicData } = data;
        const { demographic } = payload;
        yield put(getDemoResponsesSuccess({ demographic, demographicData }));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getDemoResponsesFailed({ message, error, status }));
    }
}

export function* getCrossTabSaga({ payload }) {
    try {
        const { data = {} } = yield call(crossTapPathsUrl, payload);
        const { data: crossTabData } = data;
        yield put(getCrossTabSuccess(crossTabData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getCrossTabFailed({ message, error, status }));
    }
}

const settingsSagas = [
    takeLatest(`${GET_DAILY_RES}_PENDING`, getDailyResSaga),
    takeLatest(`${GET_DEMO_RES}_PENDING`, getDemoResSaga),
    takeLatest(`${GET_CROSS_TAB}_PENDING`, getCrossTabSaga),
];

export default settingsSagas;
