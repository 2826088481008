import React, { useEffect, useState } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Tooltip } from '@material-ui/core';
import { Helmet } from 'react-helmet';

import AuthStore from '../../../common/AuthStore';
import { getStaticText } from '../../../utils/constants';
import {
    getGapColor,
    sortChildren,
    getInclusionStyle,
    quartileTitle,
    getColor,
    excludeFromGapText,
    getBenchmarkColor,
    getPptParamsForLegend,
    getExcludeGapString,
} from '../../../utils/functions';
import { PPT_PARAMS } from '../constants';
import FilterSvg from '../../Diagnose/assets/Filter.svg';
import ActiveFilter from '../../Diagnose/assets/ActiveFilter.svg';
import Peer from '../assets/PeersBlack.svg';
import Leader from '../assets/LeadersBlack.svg';
import Systems from '../assets/SystemsBlack.svg';
import DownloadIcon from '../../Diagnose/assets/Download.svg';
import './index.scss';
import ErrorToast from '../../../components/InformationToast/ErrorToast';

function PracticeRanking({
    activeFilter,
    toggleFilterView,
    inclusionqbyq,
    allGapData,
    defaultSettings,
    getPptData,
    filters,
    demographics,
    scoreColor,
    iaApiParams,
    reportApiParams,
}) {
    const staticText = getStaticText();
    const { SITE_TEXT } = staticText;
    const {
        FILTERS,
        PRACTICE_RANKING,
        PRACTICE_CLASSIFICATION_TEXT,
        SCORE_CLASSIFICATION_TEXT,
        GAP_CLASSIFICATION_TEXT,
        PRACTICE_CLASSIFICATION,
        INCL_HEAD,
        INCL_HEAD_META,
        NOTES,
        INCL_PRAC_RANK_NOTES,
        DISCLAIMER,
        DISCLAIMER_PRACTICE_RANK_TEXT,
        DOWNLOAD,
        NUMBER_TEXT,
        FILTER_HOVER_TXT,
        UNDER_DEVELOPMENT_1,
        UNDER_DEVELOPMENT_2,
        INCLUSION_PRACTICE_RANKING,
        TOTAL_RES_TEXT,
    } = SITE_TEXT;
    const assetObj = {
        Peer,
        Leader,
        Systems,
    };
    const { report_name_or_client_name: rName = '', exclude_inclusion_gap: defaultExGap = '' } = defaultSettings;
    const { exclude_inclusion_gap: exGap = '' } = reportApiParams;
    const { with_benchmark: withBenchmark = false, benchmarks = '' } = iaApiParams;
    const { pptAccessToken = '' } = AuthStore;
    const [practices, updatePractices] = useState([]);
    const [activeIndex, updateIndex] = useState(0);
    const { message = '', qbyq: mainQbyq = {} } = inclusionqbyq;
    const { size = 0 } = mainQbyq;
    const [selectPractice, setSelectPractice] = useState('all');
    const {
        score: scoreStyles = [],
        gap: gapStyles = [],
        styles = [],
        benchmarkStyles = [],
        pptBenchmarkStyles = [],
    } = scoreColor;
    const styleToUse = !withBenchmark ? scoreStyles : benchmarkStyles;

    useEffect(() => {
        const { qbyq = {} } = inclusionqbyq || {};
        const { records = {} } = qbyq || {};
        const { gap = {} } = allGapData;
        let mainObj = {};

        if (!isEmpty(records)) {
            Object.keys(records).forEach(val => {
                const { children = {} } = records[val];
                const { children: gapChildren = {} } = gap[val] || {};
                Object.keys(children).forEach(childKey => {
                    const { gap: gapV = '' } = gapChildren[childKey] || {};
                    mainObj = {
                        ...mainObj,
                        [childKey]: {
                            ...children[childKey],
                            gapV,
                        },
                    };
                });
            });
        }
        const sortChildrenObj = withBenchmark
            ? sortChildren(mainObj, 'z_score', -1)
            : sortChildren(mainObj, 'score', -1);
        updatePractices(sortChildrenObj);
    }, [inclusionqbyq, allGapData, withBenchmark]);

    const { meta_scores = [], display_name = '' } = practices[activeIndex] || {};

    const getFiltersText = (appliedFilters, demographicListData) => {
        let filter_applied = [];
        let filterText = '';
        if (appliedFilters.length > 0) {
            for (let i = 0; i < appliedFilters.length; i += 1) {
                filter_applied = appliedFilters[i].split(':');
                const parentKey = filter_applied[0];
                const childKeyArr = filter_applied[1].split(',');
                const parentObj = demographicListData.filter(({ code }, index) => {
                    if (code === parentKey) return demographicListData[index];
                    return null;
                });
                const parent = parentObj[0].label;
                const childArr = parentObj[0].options;
                let children = '';
                for (let j = 0; j < childKeyArr.length; j += 1) {
                    const child = childArr.filter(({ code }, index) => {
                        if (code === childKeyArr[j]) return childArr[index].label;
                        return null;
                    });
                    if (j !== childKeyArr.length - 1) {
                        children += child[0].label + ', ';
                    } else {
                        children += child[0].label;
                    }
                }
                filterText += parent + ' : ' + children + '\n';
            }
        }
        return filterText;
    };

    const getFilterSlideObj = (filterText, reportName, NSize) => {
        return {
            identify_shape_name: 'checkme',
            identify_shape_value: 'filters_applied',
            is_copy: true,
            is_delete: true,
            data: [
                {
                    name: 'Source',
                    value: `${reportName} (${NUMBER_TEXT} = ${NSize})`,
                },
                {
                    name: 'checkme',
                    is_delete: true,
                },
                {
                    name: 'filtertext',
                    value: filterText,
                },
                {
                    name: 'exclude_inclusion_gap_text',
                    value: `${excludeFromGapText(getExcludeGapString(defaultExGap, exGap), demographics)}`,
                },
            ],
        };
    };

    const getDataForPpt = () => {
        const paramForLegend = getPptParamsForLegend(benchmarks);
        const data = [
            {
                name: 'Source',
                value: `${rName} (${NUMBER_TEXT} = ${size})`,
            },
            {
                name: 'checkme',
                is_delete: true,
            },
            ...paramForLegend,
        ];

        practices.forEach(({ score = '', display_name: value, gapV, quartile = -1 }, prcIndex) => {
            const indexInUse = prcIndex + 1;
            data.push(
                {
                    name: `Practice${indexInUse}_score`,
                    value: score,
                    style: withBenchmark ? getBenchmarkColor(quartile) : getColor(score, scoreStyles, false, true),
                },
                {
                    name: `Practice${indexInUse}_name`,
                    value,
                }
            );

            data.push({
                name: `Practice${indexInUse}_gap`,
                value: gapV,
                style: getGapColor(gapV, gapStyles, true) + 12,
            });
        });

        const filterText = getFiltersText(filters, demographics);
        const filterObj = getFilterSlideObj(filterText, rName, size);

        const output = {
            ...PPT_PARAMS,
            slides: [
                {
                    identify_shape_name: 'checkme',
                    identify_shape_value: 'Practice_ranking_gaps',
                    styles: withBenchmark ? pptBenchmarkStyles : styles,
                    data,
                },
                filterObj,
            ],
        };

        getPptData(output, pptAccessToken, 'Inclusion_Practice_Ranking');
    };

    const setViewOption = level => {
        const index = practices.findIndex(elem => elem.level === level);
        if (level === selectPractice) {
            setSelectPractice('all');
            updateIndex(0);
        } else {
            setSelectPractice(level);
            updateIndex(index);
        }
    };

    return (
        <div className={c('practiceRankingInclusion clearfix')}>
            <Helmet>
                <title>{INCLUSION_PRACTICE_RANKING}</title>
            </Helmet>
            <div className="underline">
                <div className="optionWrapper">
                    <div>
                        <h2>{PRACTICE_RANKING}</h2>
                    </div>
                    <div>
                        <ul className="rightNav">
                            {practices.length ? (
                                <li className="respondents">
                                    {TOTAL_RES_TEXT} = {size}
                                </li>
                            ) : null}
                            {!message && (
                                <li
                                    data-testid="getPptBtn"
                                    className={c({ disabled: withBenchmark }, 'separator')}
                                    onClick={getDataForPpt}
                                >
                                    <img src={DownloadIcon} alt="" /> <span>{DOWNLOAD}</span>
                                </li>
                            )}
                            <Tooltip arrow title={FILTER_HOVER_TXT}>
                                <li
                                    className="separator noBorder"
                                    data-testid="filterButton"
                                    onClick={() => toggleFilterView(true)}
                                >
                                    <img src={activeFilter ? ActiveFilter : FilterSvg} alt="" /> <span>{FILTERS}</span>
                                </li>
                            </Tooltip>
                        </ul>
                    </div>
                </div>
            </div>
            {message ? <ErrorToast message={message} /> : null}
            {practices.length ? (
                <section className="mainContent">
                    {withBenchmark ? (
                        <div className="underDevelopment">
                            <div className="developmentDialog">
                                <div>
                                    <h2>{UNDER_DEVELOPMENT_1}</h2>
                                    <h4>{UNDER_DEVELOPMENT_2}</h4>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <div className="legendsContent clearfix tile">
                        <div>
                            <ul className="classification clearfix">
                                <li className="classificationHeading">{SCORE_CLASSIFICATION_TEXT} </li>
                                {styleToUse
                                    .filter(
                                        ({ quartile, style_for }) =>
                                            quartile !== '0' && quartile !== '999' && style_for !== 'blank_data'
                                    )
                                    .map(({ title, bgcolor: background }) => (
                                        <li key={title}>
                                            <div className="circle" style={{ background }} />
                                            <div>{quartileTitle(withBenchmark, title)}</div>
                                        </li>
                                    ))}
                            </ul>
                            <ul className="classification clearfix">
                                <li className="classificationHeading">{GAP_CLASSIFICATION_TEXT} </li>
                                {gapStyles.map(({ title, bgcolor: background }) => (
                                    <li key={title}>
                                        <div style={{ background }} />
                                        <div>{title}</div>
                                    </li>
                                ))}
                            </ul>
                            <ul className="classification clearfix practice">
                                <li className="classificationHeading">{PRACTICE_CLASSIFICATION_TEXT} </li>
                                {PRACTICE_CLASSIFICATION.map(({ title, level }) => {
                                    const listBg = level === selectPractice ? { background: 'rgb(234,235,234)' } : {};
                                    return (
                                        <li
                                            data-testid={level}
                                            key={title}
                                            style={listBg}
                                            onClick={() => {
                                                setViewOption(level);
                                            }}
                                        >
                                            <img src={assetObj[level]} alt="" />
                                            <div>{title}</div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="content clearfix">
                        <div className="leftContent">
                            {!withBenchmark ? (
                                <div className="leftGradient" />
                            ) : (
                                <div className="leftGradientBenchmark" />
                            )}
                            <ul>
                                {practices.map(
                                    (
                                        {
                                            display_name: cName = '',
                                            score = 0,
                                            level = '',
                                            gapV = '',
                                            inclusion_quartile = -1,
                                            quartile = -1,
                                        },
                                        index
                                    ) => {
                                        const {
                                            bgcolor: background = '',
                                            text_color: color = '',
                                            border_color: borderColor = '',
                                        } = getInclusionStyle(inclusion_quartile, quartile, benchmarkStyles, styles);
                                        const {
                                            bgcolor: gapBgColor = '',
                                            text_color: textBgColor = '',
                                            border_color: gapBorderColor = '',
                                        } = getGapColor(gapV, gapStyles);
                                        const practiceStyle =
                                            level === selectPractice || selectPractice === 'all' ? {} : { opacity: 0 };
                                        return (
                                            <li key={index} style={practiceStyle}>
                                                <img src={assetObj[level]} alt="" />
                                                <span className="indexContent">
                                                    {!gapV && !score ? '-' : index + 1}
                                                </span>
                                                <div
                                                    data-testid={`Practice${index}Btn`}
                                                    className={c({ selected: activeIndex === index })}
                                                    onClick={() => updateIndex(index)}
                                                >
                                                    {cName}
                                                    <span
                                                        style={{
                                                            color: textBgColor,
                                                            background: gapBgColor,
                                                            border: `0.5px solid ${gapBorderColor}`,
                                                        }}
                                                        className="gapSpan"
                                                    >
                                                        <div>{!gapV && !score ? '-' : gapV}</div>
                                                    </span>
                                                    <span
                                                        className="scoreSpan"
                                                        style={{
                                                            background,
                                                            color,
                                                            border: `0.5px solid ${borderColor}`,
                                                        }}
                                                    >
                                                        <div>{score === 0 ? '-' : score}</div>
                                                    </span>
                                                </div>
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                        </div>
                        <div className="rightContent">
                            <section className="questionsContent">
                                <p>{display_name}</p>
                                <ul>
                                    <li className="clearfix">
                                        <span>{INCL_HEAD}</span>
                                        <span>
                                            {INCL_HEAD_META}
                                            <sup>1,2</sup>
                                        </span>
                                    </li>
                                    {meta_scores.map(({ qtext = '', score = 0 }, indexMeta) => (
                                        <li key={indexMeta} className="clearfix">
                                            <span>{qtext}</span>
                                            <span>{score}</span>
                                        </li>
                                    ))}
                                </ul>

                                <div className="notesContent">
                                    <p>{NOTES}</p>
                                    <ol>
                                        {INCL_PRAC_RANK_NOTES.map((note, noteIndex) => (
                                            <li key={noteIndex} className="clearfix">
                                                {note}
                                            </li>
                                        ))}
                                    </ol>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div className="disclaimer">
                        <div>{DISCLAIMER}</div>
                        <ul className="clearfix">
                            <li>{DISCLAIMER_PRACTICE_RANK_TEXT}</li>
                        </ul>
                    </div>
                </section>
            ) : null}
        </div>
    );
}

PracticeRanking.propTypes = {
    activeFilter: PropTypes.bool.isRequired,
    inclusionqbyq: PropTypes.object.isRequired,
    allGapData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    getPptData: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    demographics: PropTypes.array.isRequired,
    filters: PropTypes.array.isRequired,
    scoreColor: PropTypes.object.isRequired,
    iaApiParams: PropTypes.object.isRequired,
    reportApiParams: PropTypes.object.isRequired,
};

export default PracticeRanking;
