import React, { useEffect, Fragment } from 'react';
import XLSX from 'xlsx-color';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getStaticText } from '../../../utils/constants';
import { getBenchmarksLabel, getDemographicsLabel } from '../../../utils/functions';
import colors from '../../../sass/colors';

function Excel({ header, items, selectedDemographic, defaultSettings, filters, demographicsInUse, years, isOutcome }) {
    const { quartileColors = [], lang, survey_version = '' } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { SIGNIFICANCE, SIGNIFICANCES } = SITE_TEXT || {};
    const { $white, $black, $darkBlue100 } = colors;
    const signMainKey = 'significance';
    const isOhi4 = survey_version === '4' || survey_version === '3_2';
    const sigToUse = isOhi4 ? SIGNIFICANCES : SIGNIFICANCE;
    const getExport = () => {
        const table = document.getElementById('mytable');
        if (table) {
            const wb = XLSX.utils.table_to_book(table, { sheet: 'Report' });
            Object.keys(wb.Sheets.Report).forEach(val => {
                if (typeof wb.Sheets.Report[val] === 'object') {
                    const valueToSplit = wb.Sheets.Report[val].v || '';
                    const split = valueToSplit.split('#') || [];
                    if (split.length > 0) {
                        const splitFirst = split[0];
                        const isSignificance = splitFirst === signMainKey;
                        let { background = $white, color = $darkBlue100, border = $black } =
                            quartileColors[splitFirst] || [];
                        if (isSignificance) {
                            const { bg = $white, text_color = $darkBlue100 } = sigToUse[split[1]] || {};
                            background = bg;
                            color = text_color;
                            border = $black;
                        }
                        const rgb = splitFirst === '0' && !isOhi4 ? border.replace('#', '') : 'FFFFFF';
                        const bgColor =
                            splitFirst === '0' && isOhi4 ? border.replace('#', '') : background.replace('#', '');
                        // eslint-disable-next-line prefer-destructuring
                        wb.Sheets.Report[val].v = isSignificance ? split[2] : split[1];
                        let align = 'left';
                        if (split.length === 3) {
                            align = 'center';
                        } else {
                            align = splitFirst === 'bold' ? 'left' : 'center';
                        }
                        wb.Sheets.Report[val].s = {
                            font: {
                                color: { rgb: color.replace('#', '') },
                                bold: splitFirst === 'bold',
                                sz: splitFirst === 'bold' ? 14 : 12,
                            },
                            alignment: {
                                vertical: align,
                                horizontal: align,
                            },
                            fill: {
                                patternType: 'solid',
                                fgColor: { rgb: bgColor },
                            },
                            border: {
                                bottom: {
                                    style: 'thin',
                                    color: {
                                        rgb,
                                    },
                                },
                                top: {
                                    style: 'thin',
                                    color: {
                                        rgb,
                                    },
                                },
                                left: {
                                    style: 'thin',
                                    color: {
                                        rgb,
                                    },
                                },
                                right: {
                                    style: 'thin',
                                    color: {
                                        rgb,
                                    },
                                },
                            },
                        };
                    }
                }
            });
            return XLSX.writeFile(wb, `Resurvey_Heatmap_${moment().format('MM_DD_YYYY')}.xlsx`);
        }
        return null;
    };

    useEffect(() => {
        getExport();
        // eslint-disable-next-line
    }, []);

    const renderLabel = () => {
        const index = demographicsInUse.indexOf(selectedDemographic);
        const { label = '' } = demographicsInUse[index] || {};
        return <th>#{label}</th>;
    };

    const getHeapMapValues = () => {
        return items.map(({ items: subItem = [], title }, index) => {
            const nSize = !index && !isOutcome;
            return (
                <tr key={index}>
                    <td key={title}>bold#{title}</td>
                    {subItem.map(({ score = [], quartile = [], difference = '', significance }, subItemKey) => {
                        const quartile1 = quartile[0];
                        const quartile2 = quartile[1];
                        const score1 = score[0];
                        const score2 = score[1];
                        const isFirstOutcome = isOutcome && !subItemKey;
                        const significanceToUse = nSize || isFirstOutcome ? 'nSize' : significance;
                        return (
                            <Fragment key={subItemKey}>
                                <td>{quartile1 + '#' + score1}</td>
                                <td>{quartile2 + '#' + score2}</td>
                                <td>{`${signMainKey}#${significanceToUse}#${difference}`}</td>
                            </Fragment>
                        );
                    })}
                </tr>
            );
        });
    };

    const renderBenchMarkFilters = ({ benchmarks = [] }) => {
        const value = getBenchmarksLabel(benchmarks);
        if (!value) return null;
        return <td>#{value}</td>;
    };

    const renderDemographicFilter = ({ demographics = [] }) => {
        const labelArr = getDemographicsLabel(demographics);
        return labelArr.map(label => <td key={label}>#{label}</td>);
    };

    return (
        <table id="mytable" className="hideTable">
            <tbody>
                <tr>
                    <th>bold#Demographic Selected Option</th>
                    {renderLabel()}
                </tr>
            </tbody>
            <tbody>
                <tr>
                    <th>bold#Filters</th>
                </tr>
                <tr>
                    <td>bold#Demographic-filters({years[0]})</td>
                    {renderDemographicFilter(filters[0])}
                </tr>
                <tr>
                    <td>bold#Demographic-filters({years[1]})</td>
                    {renderDemographicFilter(filters[1])}
                </tr>
                <tr>
                    <td>bold#Benchmark-filters({years[0]})</td>
                    {renderBenchMarkFilters(filters[0])}
                </tr>
                <tr>
                    <td>bold#Benchmark-filters({years[1]})</td>
                    {renderBenchMarkFilters(filters[1])}
                </tr>
            </tbody>
            <tbody>
                <tr>
                    <th />
                    {header.map((val = '', i) => (
                        <th colSpan="3" key={`${val}${i}`} />
                    ))}
                </tr>
                <tr>
                    <th />
                    {header.map((val = '', i) => {
                        const headText = val.replace(/<sup>[\d]?<\/sup>/g, '');
                        return (
                            <th colSpan="3" key={`${val}${i}`}>
                                bold#{headText}#center
                            </th>
                        );
                    })}
                </tr>
                <tr>
                    <th />
                    {header.map((val, i) => {
                        return (
                            <Fragment key={`${val}${i}`}>
                                <th>bold#{years[0]}#center</th>
                                <th>bold#{years[1]}#center</th>
                                <th>bold#Difference#center</th>
                            </Fragment>
                        );
                    })}
                </tr>
                {getHeapMapValues()}
            </tbody>
        </table>
    );
}

Excel.propTypes = {
    header: PropTypes.array.isRequired,
    filters: PropTypes.array.isRequired,
    demographicsInUse: PropTypes.array.isRequired,
    years: PropTypes.array.isRequired,
    items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    selectedDemographic: PropTypes.string.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    isOutcome: PropTypes.bool.isRequired,
};

export default Excel;
