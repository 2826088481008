import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { Select, MenuItem } from '@material-ui/core';
import { ArrowUpward, ExpandMore, Add } from '@material-ui/icons';
import { Helmet } from 'react-helmet';

import AuthStore from '../../../common/AuthStore';
// import ActiveFilter from '../assets/ActiveFilter.svg';
import ActiveFilters1 from '../assets/ActiveFilters1.svg';
import FilterSvg from '../assets/Filter.svg';
import CompareView from '../assets/CompareView.svg';
import DownloadIcon from '../assets/Download.svg';
import { getStaticText } from '../../../utils/constants';
import { getPptValues } from '../../../utils/functions';
import getStaticTextDiagnose from '../constants';
import colors from '../../../sass/colors';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import DwnldModal from '../../../components/InformationToast/DwnldModal';

function ReSurveyDetailedView({
    setReport,
    setChartData,
    scoreData,
    projects,
    selectedIds,
    callReSurveyApi,
    errorInfo,
    updateFilterView,
    activeResetFilter,
    defaultSettings,
    getPptData,
    pulseSurvey,
    isPercentile,
    appliedFiltersInfo,
    apiParams,
    getReport,
    getRawPptData,
    reportError,
    getResurveyParams,
    filters,
    getSinglePastReport,
    singlePastReport,
    years,
    localPractice,
}) {
    const {
        quartileColors,
        report_name = '',
        report_name_or_client_name: rName = '',
        template_name: template_file_name,
        lang,
        survey_version,
        report_id,
        checkme_mapping = {},
        resurvey_settings = '',
        demographic = '',
        report_type = '',
        resurvey_custom_delta = [],
    } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { QUARTILE_RESURVEY, recipeImages, CUSTOM_RESURVEY } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { RESURVEY_EXPORT_PARAMS, SITE_TEXT, ING_REPORT_ID } = staticText;
    const isOhi4 = survey_version === '4';
    const isQuartMovement = resurvey_settings === QUARTILE_RESURVEY;
    const { $white, $black } = colors;
    const {
        SCORECARD,
        FILTERS,
        BENCHMARK_HEAD,
        RESURVEY_TEXT,
        STRONG_TEXT,
        VERY_STRONG_TEXT,
        NO_ALIGNMENT,
        SELECT_DEFAULT,
        COMPANY_RESULTS,
        COMPANY_RESULTS_TEXT,
        COMPANY_RESULTS_DIFF,
        COMPANY_RESULTS_DIFF_TEXT,
        ADD_YEAR,
        RECIPE_NOTE,
        HEALTH_SCORE,
        // DIFF_HEAD,
        DOWNLOAD,
        PPT_RES_TEXT,
        PPT_RES_TEXT3,
        SOURCE_TEXT,
        NUMBER_TEXT,
        NUMBER_SURVEY_TEXT,
        ALL_PPT_TEXT,
        SIGNIFICANCE,
        PPT_VALUES,
        SIGNIFICANCES,
        NOTE,
        OHI4_RESURVEY_NOTE,
        QUARTILE_MOVE_LEGEND_TXT,
        HEALTH_RESURVEY_DETAIL_BOARD,
        PERCENT_FAVORABLE,
        PERCENTILES,
        STATISTICAL_DIFFERENCE,
        QUARTILE_DIFFERENCE,
        CUSTOM_DIFFERENCE,
        CUSTOM_DELTA_LEGEND_TXT,
        COMPARISON_BUBBLES,
    } = SITE_TEXT || {};
    const { records = {}, recipe = {}, comparison = {}, sizes = {}, survey_names = [] } = scoreData;
    const [dwnld, setdwnld] = useState(false);
    const { ohi_score = {}, ...dataToIterate } = records;
    const { options = {}, values = [] } = recipe;
    const highScoreIndex = Object.keys(values).length
        ? values[0].reduce((iMax, x, i, arr) => (x > arr[iMax] ? i : iMax), 0)
        : null;
    const highScoreKey = highScoreIndex !== null ? Object.keys(options)[highScoreIndex] : '';
    const recipeData = highScoreKey ? { ...options[highScoreKey], score: [values[0][highScoreIndex]] } : {};
    const { score: ohiScore = [], quartile: ohiQtl = [], display_name: ohiDis } = ohi_score;
    const { significance: ohiSign = [], difference: ohiDiff = [] } = comparison.ohi_score || {};
    const ohiScoreUpdated = { ...ohi_score, significance: ohiSign };
    const ohiRecipe = ohiScore.length > 2 ? [ohiScoreUpdated, ohiScoreUpdated] : [ohiScoreUpdated];
    const headerContent = [...ohiRecipe, recipeData];
    const { pptAccessToken = '', resurveyName1, resurveyName2 } = AuthStore;
    const isIngClient = report_id === parseInt(ING_REPORT_ID, 10);
    const isCustomMovement = resurvey_settings === CUSTOM_RESURVEY;

    const headerList = [...ohiRecipe];
    const filterCheck = apiParams.filters || [];

    const headerContents = survey_version === '4' ? headerList : headerContent;

    const updateSurveyData = (value, index) => {
        if (value === '') return;
        const newList = [...selectedIds.slice(0, index), value, ...selectedIds.slice(index + 1)];
        callReSurveyApi(newList, index);
    };

    const surveyV = isOhi4 || survey_version === '3_2';

    const sigVInUse = survey_version === '4' || survey_version === '3_2' ? SIGNIFICANCES : SIGNIFICANCE;

    const [selectVisible, showDropDown] = useState(false);
    const [resurveyCustom, setResurveyCustom] = useState([]);

    // useEffect(() => {
    //     if (resurvey_custom_delta) {
    //         setResurveyCustom([resurvey_custom_delta]);
    //     }
    // }, [resurvey_custom_delta]);

    useEffect(() => {
        if (resurvey_custom_delta) {
            setResurveyCustom([resurvey_custom_delta]);
        }
        // eslint-disable-next-line
    }, []);

    const range = ['less than -n', 'more than n', 'between -n to n'];
    const generateRanges = n => {
        const ranges = [
            { key: `less than -${n}`, values: [] },
            { key: `more than ${n}`, values: [] },
            { key: `between -${n} to ${n}`, values: [] },
        ];
        return ranges;
    };
    const rangesHere = resurveyCustom[0] !== '' ? generateRanges(resurveyCustom) : [];

    const getStylesArr = () => {
        const styleArr = [];
        quartileColors.forEach(({ background = '', border = '', color = '' }, index) => {
            if (index === 0) {
                styleArr.push({
                    bgcolor: background,
                    text_color: color,
                    border_color: 'FFFFFF',
                    gradient_stoplist: [
                        {
                            position: 0,
                            bgcolor: border,
                        },
                        {
                            position: 100000,
                            bgcolor: background,
                        },
                        {
                            position: 10000,
                            bgcolor: border,
                        },
                        {
                            position: 10000,
                            bgcolor: background,
                        },
                    ],
                });
            } else {
                styleArr.push({
                    border_color: 'FFFFFF',
                    text_color: color,
                    bgcolor: background,
                });
            }
        });
        styleArr.push({
            border_color: 'FFFFFF',
            text_color: '000000',
            bgcolor: 'FFFFFF',
        });
        return styleArr;
    };

    const getPpt = (outcomeArray = [], prtArray = []) => {
        const output = {
            output_file_name: 'data.pptx',
            template_file_name,
            slides: [
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'resurvey_Ohandoutcome_checkme',
                    data: outcomeArray,
                    styles: getStylesArr(),
                },
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'resurvey_practice_checkme',
                    data: prtArray,
                    styles: getStylesArr(),
                },
            ],
        };
        getPptData(output, pptAccessToken, `${report_name} ReSurvey Detailed`);
    };

    const getIngPpt = () => {
        const { nSize, nSurvey, nRespondents, bmNames } = sizes;
        const { GLOBAL_VALS } = PPT_VALUES({ rName });
        const footNote = isIngClient ? [{ name: 'footnote', value: localPractice }] : [];
        const pptArray = [
            ...getPptValues(GLOBAL_VALS),
            {
                name: 'company2',
                value: `${COMPANY_RESULTS} ${rName} ${COMPANY_RESULTS_TEXT}`,
            },
            {
                name: 'company1',
                value: survey_names[1],
            },
            {
                name: 'comparison1',
                value: `${COMPANY_RESULTS_DIFF} ${rName} ${COMPANY_RESULTS_DIFF_TEXT} ${projects[selectedIds[0]].ohid}-
                ${projects[selectedIds[0]].year}`,
            },
            ...footNote,
        ];
        const prtArray = [
            ...pptArray,
            {
                name: '3. Subtitle',
                value: '',
                // value: isPercentile ? PPT_RES_TEXT3 : PPT_RES_TEXT2,
            },
            {
                name: 'Title 3',
                value: ALL_PPT_TEXT[25],
            },
            {
                name: 'source',
                value: `${SOURCE_TEXT}: ${rName} ${years[0]} (${NUMBER_TEXT}=${nSize[0]}); ${BENCHMARK_HEAD}: ${bmNames[0]} (${NUMBER_TEXT}=${nSurvey[0]}, ${NUMBER_SURVEY_TEXT}=${nRespondents[0]}), ${resurveyName1} (${NUMBER_TEXT}=${nSize[1]}); ${BENCHMARK_HEAD}: ${bmNames[1]} (${NUMBER_TEXT}=${nSurvey[1]}, ${NUMBER_SURVEY_TEXT}=${nRespondents[1]})`,
            },
            {
                name: 'Checkme',
                is_delete: 'true',
            },
            {
                name: 'title',
                value: `Practices: ${report_name} over time`,
            },
        ];

        const table_data = [];
        const childChart = [];
        const childChart1 = [];
        const diffChildChart2 = [];
        let indexInArr = 0;
        Object.keys(dataToIterate).forEach((innerContent, innerContentIndex) => {
            const { children = {}, display_name = '' } = records[innerContent];
            table_data.push({
                column_index: 0,
                row_index: innerContentIndex,
                value: display_name,
            });
            Object.keys(children).forEach(childVal => {
                const {
                    score: childScore,
                    quartile: childQT,
                    display_name: cDisplayName,
                    significance: [recordOneChildSign],
                    difference: [recordOneChildDiff],
                } = children[childVal];
                const { background = $white, border = $white } = quartileColors[childQT[0]] || {};
                const { background: bg1 = $white, border: border1 = $white } = quartileColors[childQT[1]] || {};
                const { bg: recordOneChildBg } = sigVInUse[recordOneChildSign] || {};
                childChart.push(
                    {
                        row_index: indexInArr + 1,
                        column_index: 1,
                        index: indexInArr,
                        value: childScore[0],
                        bgcolor: background,
                        border_color: border,
                    },
                    {
                        row_index: indexInArr + 1,
                        column_index: 0,
                        index: indexInArr,
                        value: cDisplayName,
                        cat: cDisplayName,
                    }
                );
                childChart1.push(
                    {
                        row_index: indexInArr + 1,
                        column_index: 1,
                        index: indexInArr,
                        value: childScore[1],
                        bgcolor: bg1,
                        border_color: border1,
                    },
                    {
                        row_index: indexInArr + 1,
                        column_index: 0,
                        index: indexInArr,
                        value: cDisplayName,
                        cat: cDisplayName,
                    }
                );
                diffChildChart2.push({
                    row_index: indexInArr + 1,
                    column_index: 0,
                    index: indexInArr,
                    value: recordOneChildDiff,
                    bgcolor: recordOneChildBg,
                    border_color: recordOneChildBg,
                });
                indexInArr += 1;
            });
        });
        prtArray.push(
            { name: 'Chart2', chart_data: childChart },
            { name: 'Chart1', chart_data: childChart1 },
            { name: 'comparison_chart1', chart_data: diffChildChart2 },
            { name: 'TableHeader', is_delete: false, table_data }
        );
        const output = {
            output_file_name: 'data.pptx',
            template_file_name,
            slides: [
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'Cpeerbenchmarkpractice_BUSwa',
                    data: prtArray,
                    styles: getStylesArr(),
                },
            ],
        };
        getPptData(output, pptAccessToken, `${report_name} ReSurvey Detailed`);
    };

    const getDataForPpt = () => {
        if (isIngClient) return getIngPpt();
        const { nSize = [], nSurvey, nRespondents, bmNames } = sizes;
        const valueDefault = `${SOURCE_TEXT}: ${rName} (${NUMBER_TEXT}=${nSize[0]}); ${BENCHMARK_HEAD}: ${bmNames[0]} (${NUMBER_TEXT}=${nSurvey[0]}, ${NUMBER_SURVEY_TEXT}=${nRespondents[0]}), ${resurveyName1} (${NUMBER_TEXT}=${nSize[1]}); ${BENCHMARK_HEAD}: ${bmNames[1]} (${NUMBER_TEXT}=${nSurvey[1]}, ${NUMBER_SURVEY_TEXT}=${nRespondents[1]})`;
        const valueAdded =
            nSize.length > 2
                ? `${resurveyName2} (${NUMBER_TEXT}=${nSize[2]}); ${BENCHMARK_HEAD}: ${bmNames[2]} (n=${nSurvey[2]}, ${NUMBER_SURVEY_TEXT}=${nRespondents[2]})`
                : '';
        const { GLOBAL_VALS } = PPT_VALUES();
        const pptArray = [
            {
                name: 'QuartileLegend',
                is_delete: true,
            },
            {
                name: 'source',
                value: `${valueDefault} ${valueAdded}`,
            },
            {
                name: 'Checkme',
                is_delete: 'true',
            },
            {
                name: '3. Subtitle',
                value: isPercentile ? PPT_RES_TEXT3 : PPT_RES_TEXT,
            },
            {
                name: '4. Footnote',
                value: ALL_PPT_TEXT[11],
            },
            ...getPptValues(GLOBAL_VALS),
        ];

        const outcomeArray = [...pptArray];
        const outcomeTableArray = [];
        const prtArray = [...pptArray];
        const prtTableArray = [];

        let ohiCol = 0;

        outcomeTableArray.push(
            {
                row_index: 1,
                column_index: ohiCol,
                is_bold: 'true',
                value: ohiDis,
            },
            {
                row_index: 0,
                column_index: ohiCol,
                is_bold: 'true',
                value: 'OUTCOMES',
            }
        );

        ohiCol = 4;
        ohiScore.forEach((value, indexScore) => {
            const headerVal = !indexScore ? rName : AuthStore[`resurveyName${indexScore}`];
            outcomeTableArray.push(
                {
                    row_index: 1,
                    column_index: ohiCol,
                    is_bold: 'false',
                    value,
                    style: ohiQtl[indexScore],
                },
                {
                    row_index: 0,
                    column_index: ohiCol,
                    is_bold: 'true',
                    value: headerVal,
                    text_color: '#000000',
                }
            );
            ohiCol -= 1;
        });

        outcomeTableArray.push(
            {
                row_index: 1,
                column_index: 5,
                is_bold: 'false',
                value: '',
            },
            {
                row_index: 0,
                column_index: 5,
                is_bold: 'false',
                value: '',
            }
        );

        ohiCol = 7;
        ohiDiff.forEach((diff, indexDiff) => {
            const { bg = $white, text_color = $black } = sigVInUse[ohiSign[indexDiff]] || {};
            outcomeTableArray.push(
                {
                    row_index: 1,
                    column_index: ohiCol,
                    is_bold: 'false',
                    value: diff,
                    bgcolor: bg,
                    border_color: '',
                    text_color,
                },
                {
                    row_index: 0,
                    column_index: ohiCol,
                    is_bold: 'true',
                    value: `${rName} - ${AuthStore[`resurveyName${indexDiff + 1}`]}`,
                    text_color: $black,
                }
            );
            ohiCol -= 1;
        });

        let rIndex = 0;
        let parent_name_index = 1;
        Object.keys(dataToIterate).forEach((dataSet, dataIndex) => {
            const { display_name = '', score = [], children = {}, quartile = [] } = dataToIterate[dataSet];
            const { difference = [], significance = [] } = comparison[dataSet];

            let column_index = 0;
            const row_index = dataIndex + 2;

            outcomeTableArray.push({
                row_index,
                column_index,
                is_bold: 'true',
                value: display_name,
            });
            prtTableArray.push({
                name: `${dataSet}_text`,
                value: display_name,
                column_index: 0,
                row_index: parent_name_index,
            });
            column_index = 4;
            score.forEach((value, indexScore) => {
                outcomeTableArray.push({
                    row_index,
                    column_index,
                    is_bold: 'false',
                    value,
                    style: quartile[indexScore] !== '' ? quartile[indexScore] : 5,
                });
                column_index -= 1;
            });
            column_index = 5;

            outcomeTableArray.push({
                row_index,
                column_index,
                is_bold: 'false',
                value: '',
            });
            column_index = 7;
            difference.forEach((diff, indexDiff) => {
                const { bg = $white, text_color = $black } = sigVInUse[significance[indexDiff]] || {};
                outcomeTableArray.push({
                    row_index,
                    column_index,
                    is_bold: 'false',
                    value: diff,
                    bgcolor: bg,
                    text_color,
                    border_color: '',
                });
                column_index -= 1;
            });

            Object.keys(children).forEach((childSet, childIndex) => {
                const { display_name: cName = '', score: cScore = [], quartile: cQuat = [] } = children[childSet];
                const { difference: cDiff = [], significance: cSign = [] } = comparison[dataSet].children[childSet];

                let cIndex = 1;
                rIndex += 1;
                parent_name_index += 1;

                prtTableArray.push({
                    row_index: rIndex,
                    column_index: cIndex,
                    is_bold: 'true',
                    value: cName,
                });
                if (!childIndex) {
                    prtTableArray.push(
                        {
                            row_index: 0,
                            column_index: 1,
                            is_bold: 'true',
                            value: 'Practices',
                        },
                        {
                            row_index: 6,
                            column_index: cIndex,
                            is_bold: 'false',
                            value: '',
                        }
                    );
                }
                cIndex = 5;
                cScore.forEach((value, indexScore) => {
                    const headerVal = !indexScore ? rName : AuthStore[`resurveyName${indexScore}`];
                    prtTableArray.push({
                        row_index: rIndex,
                        column_index: cIndex,
                        is_bold: 'false',
                        value,
                        style: cQuat[indexScore] !== '' ? cQuat[indexScore] : 5,
                    });
                    if (!childIndex) {
                        prtTableArray.push({
                            row_index: 0,
                            column_index: cIndex,
                            is_bold: 'true',
                            value: headerVal,
                            text_color: '#000000',
                        });
                    }
                    cIndex -= 1;
                });

                cIndex = 6;
                prtTableArray.push({
                    row_index: rIndex,
                    column_index: cIndex,
                    is_bold: 'false',
                    value: '',
                });

                cIndex = 8;
                cDiff.forEach((diff, indexDiff) => {
                    const { bg = $white, text_color = $black } = sigVInUse[cSign[indexDiff]] || {};
                    prtTableArray.push({
                        row_index: rIndex,
                        column_index: cIndex,
                        is_bold: 'false',
                        value: diff,
                        bgcolor: bg,
                        text_color,
                        border_color: '',
                    });
                    if (!childIndex) {
                        prtTableArray.push({
                            row_index: 0,
                            column_index: cIndex,
                            is_bold: 'true',
                            value: `${rName} - ${AuthStore[`resurveyName${indexDiff + 1}`]}`,
                            text_color: '#000000',
                        });
                    }
                    cIndex -= 1;
                });
            });
        });

        outcomeArray.push(
            {
                name: 'FinalOutputTable',
                is_delete: 'false',
                table_data: outcomeTableArray,
            },
            {
                name: 'Title_text',
                value: ALL_PPT_TEXT[12],
            }
        );

        prtArray.push(
            {
                name: 'FinalOutputTable',
                is_delete: 'false',
                table_data: prtTableArray,
            },
            {
                name: 'Title_text',
                value: ALL_PPT_TEXT[13],
            }
        );

        return getPpt(outcomeArray, prtArray);
    };

    const resurveyDemoName1 = AuthStore.resurveyFilter1 || 'Organization';
    const resurveyDemoName2 = AuthStore.resurveyFilter2 || 'Comparison survey';

    const v4PptDownload = () => {
        const { benchmark_str, ohid } = getResurveyParams(filters);
        const { bm_filters, threshold } = apiParams;
        const addOns = {
            fill_threshold_with: demographic === '1' ? '-' : '',
            others: demographic === '3' ? 1 : 0,
        };
        const { key: Resurvey_SigVsQuartile = '' } =
            COMPARISON_BUBBLES.find(({ list_key }) => list_key.toLowerCase() === resurvey_settings.toLowerCase()) || {};
        const resurveyParams = {
            ...RESURVEY_EXPORT_PARAMS,
            ...addOns,
            report_name: rName,
            options: {
                ...RESURVEY_EXPORT_PARAMS.options,
                // survey_names: `${ohid.split(',').join('$#$')}`,
                survey_names: `${ohid.split(',')[0]}${resurveyDemoName1.split(',')}$#$${
                    ohid.split(',')[1]
                }${resurveyDemoName2.split(',')}`,
                Resurvey_SigVsQuartile,
                resurvey_custom_delta,
            },
            bm_filters,
            benchmarks: benchmark_str,
            threshold,
            report_id,
        };
        const valueToSend = {
            ...resurveyParams,
            ...getResurveyParams(filters),
            single_page_meta: {
                checkme: checkme_mapping.resurvey_detailed,
                name: 'scorecard',
            },
        };
        setdwnld(true);
        getReport(valueToSend);
    };

    return (
        <div className="scoreBoard reSurveyReportBoard clearfix">
            <Helmet>
                <title>{HEALTH_RESURVEY_DETAIL_BOARD}</title>
            </Helmet>
            <h2>
                {rName} {SCORECARD}
                <ul className="rightSideNav">
                    <li data-testid="updateReportBtn" className="hovered" onClick={() => setReport(0)}>
                        <img src={CompareView} alt="" />
                        {RESURVEY_TEXT}
                    </li>
                    {!errorInfo && (
                        <li data-testid="getPptButton" onClick={!surveyV ? getDataForPpt : v4PptDownload}>
                            <img src={DownloadIcon} alt="" /> {DOWNLOAD}
                        </li>
                    )}
                    <li onClick={() => updateFilterView(true)} data-testid="filterButton">
                        <img src={activeResetFilter && filterCheck.length > 0 ? ActiveFilters1 : FilterSvg} alt="" />
                        <span className="filter">{FILTERS}</span>
                    </li>
                </ul>
            </h2>
            {appliedFiltersInfo({ years })}
            {Object.keys(scoreData).length ? (
                <ul className={c('headContent', 'headList', 'cardsList', { pulseSurvey })}>
                    {headerContents.map(({ score = [30], title = '', quartile = [], significance = [] }, index) => {
                        const strong = score[0] >= 7 ? VERY_STRONG_TEXT : STRONG_TEXT;
                        const subHeader = score[0] >= 5 ? strong : NO_ALIGNMENT;
                        const isLastIndex = index === headerContent.length - 1;
                        const titleInEnd = isLastIndex ? title : HEALTH_SCORE;
                        const { background = $white, color = $black, border = $white } =
                            quartileColors[quartile[0]] || {};
                        const { background: bg1 = $white, color: color1 = $black, border: border1 = $white } =
                            quartileColors[quartile[index + 1]] || {};
                        return (
                            <li
                                key={index}
                                data-testid={`headContent${index}`}
                                className={`headContent${index}, cardList`}
                                onClick={() => {
                                    if (isLastIndex) {
                                        setChartData(recipe, highScoreKey, highScoreIndex);
                                    }
                                }}
                            >
                                <h3
                                    style={{
                                        background:
                                            quartile[0] && quartileColors[quartile[0]] ? background : colors.$white,
                                        color: quartile[0] && quartileColors[quartile[0]] ? color : colors.$darkBlue100,
                                        borderColor: quartile[0] && quartileColors[quartile[0]] ? border : '',
                                    }}
                                >
                                    {score[0]}
                                </h3>
                                {!isNaN(score[index + 1]) && (
                                    <h3
                                        style={{
                                            background: quartile[index + 1] ? bg1 : colors.$white,
                                            color: quartile[index + 1] ? color1 : colors.$darkBlue100,
                                            borderColor: quartile[index + 1] ? border1 : colors.$darkBlue100,
                                        }}
                                    >
                                        {score[index + 1]}
                                    </h3>
                                )}
                                {!isLastIndex ? (
                                    <span
                                        style={{
                                            color: sigVInUse[significance[index]]
                                                ? sigVInUse[significance[index]].bg
                                                : $white,
                                        }}
                                        className={c('difference', { negative: ohiDiff[index] < 0 })}
                                    >
                                        {ohiDiff[index]}
                                        {ohiDiff[index] !== 0 && <ArrowUpward />}
                                    </span>
                                ) : null}
                                <div className="ellipsisPro">
                                    {isLastIndex && survey_version !== '4' && <span>{RECIPE_NOTE}</span>}
                                    {isLastIndex && survey_version !== '4' && <div>{subHeader}</div>}
                                    <span className="scoreContent">{titleInEnd}</span>
                                </div>
                                {isLastIndex && survey_version !== '4' && (
                                    <img src={recipeImages[highScoreIndex]} alt="" />
                                )}
                            </li>
                        );
                    })}
                </ul>
            ) : null}

            <div className="detailReportHeader clearfix">
                <div>
                    {COMPANY_RESULTS} {rName} {COMPANY_RESULTS_TEXT}
                </div>
                <div>
                    <Select
                        value={isNaN(selectedIds[0]) ? '' : selectedIds[0]}
                        onChange={({ target }) => updateSurveyData(target.value, 0)}
                        IconComponent={ExpandMore}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                    >
                        {projects.map(({ ohid, year }, menuIndex) => (
                            <MenuItem key={`${ohid}${year}`} value={menuIndex} classes={{ root: 'surveyOptions' }}>
                                {`${ohid} - ${year}`}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <div>
                    {COMPANY_RESULTS_DIFF} {rName} {COMPANY_RESULTS_DIFF_TEXT} {projects[selectedIds[0]].ohid}-
                    {projects[selectedIds[0]].year}
                </div>
                {selectVisible ? (
                    <Fragment>
                        <div>
                            <Select
                                displayEmpty
                                value={selectedIds.length > 1 ? selectedIds[1] : ''}
                                onChange={({ target }) => updateSurveyData(target.value, 1)}
                                IconComponent={ExpandMore}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                            >
                                <MenuItem classes={{ root: 'surveyOptions' }} value="">
                                    {SELECT_DEFAULT}
                                </MenuItem>
                                {projects.map(({ ohid, year }, menuIndex) => (
                                    <MenuItem
                                        key={`${ohid}${year}`}
                                        value={menuIndex}
                                        classes={{ root: 'surveyOptions' }}
                                    >
                                        {`${ohid} - ${year}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        {selectedIds.length > 1 && (
                            <div>
                                {COMPANY_RESULTS_DIFF} {rName} {COMPANY_RESULTS_DIFF_TEXT}{' '}
                                {projects[selectedIds[1]].ohid}-{projects[selectedIds[1]].year}
                            </div>
                        )}
                    </Fragment>
                ) : (
                    <div data-testid="addSelect" className="addYear" onClick={() => showDropDown(true)}>
                        <Add /> {ADD_YEAR}
                    </div>
                )}
            </div>
            {Object.keys(scoreData).length ? (
                <Fragment>
                    <div className="detailReport clearfix">
                        {Object.keys(dataToIterate).map(dataSet => {
                            const { display_name = '', score = [], children = {}, quartile = [] } = dataToIterate[
                                dataSet
                            ];

                            const { difference = [], significance = [] } = comparison[dataSet];
                            const { bg: bg0 = $white } = sigVInUse[significance[0]] || {};
                            const { bg: bg1 = $white } = sigVInUse[significance[1]] || {};
                            const { background: bg2 = $white, border = $white } = quartileColors[quartile[2]] || {};
                            return (
                                <section key={dataSet} className="reportTable clearfix">
                                    <div className="reportRow clearfix">
                                        <h4 className="ellipsisPro">{display_name}</h4>
                                        {score.slice(0, 2).map((scoreCont, indexScore) => {
                                            const { background = $white, border: borderColor = $white } =
                                                quartileColors[quartile[indexScore]] || {};
                                            return (
                                                <div className="scoreContent" key={indexScore}>
                                                    <div className="rank">
                                                        <div
                                                            className="textBorder"
                                                            style={{
                                                                background,
                                                                borderColor,
                                                                borderWidth:
                                                                    quartile[indexScore] !== '' &&
                                                                    quartile[indexScore] !== '999'
                                                                        ? 2
                                                                        : 0,
                                                                width: `${Math.abs(scoreCont)}%`,
                                                            }}
                                                        >
                                                            <div className="textRank">{scoreCont}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <div className="rankContent">
                                            <div className={c('rank', { negative: difference[0] < 0 })}>
                                                <div
                                                    className="textBorder"
                                                    style={{
                                                        background: bg0,
                                                        width: `${Math.abs(difference[0])}%`,
                                                    }}
                                                >
                                                    <div className="textRank">{difference[0]}</div>
                                                </div>
                                            </div>
                                        </div>
                                        {ohiScore.length > 2 && (
                                            <div className="scoreContent">
                                                <div className="rank">
                                                    <div
                                                        className="textBorder"
                                                        style={{
                                                            background: quartile[2] !== '' ? bg2 : $white,
                                                            borderColor: quartile[2] !== '' ? border : $white,
                                                            borderWidth: quartile[2] !== '' ? 2 : 0,
                                                            width: `${Math.abs(score[2])}%`,
                                                        }}
                                                    >
                                                        <div className="textRank">{score[2]}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {difference.length > 1 && (
                                            <div className="rankContent">
                                                <div className={c('rank', { negative: difference[1] < 0 })}>
                                                    <div
                                                        className="textBorder"
                                                        style={{
                                                            background: bg1,
                                                            width: `${Math.abs(difference[1])}%`,
                                                        }}
                                                    >
                                                        <div className="textRank">{difference[1]}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {Object.keys(children).map(childSet => {
                                        const {
                                            display_name: childName = '',
                                            score: childScore = [],
                                            quartile: childQuat = [],
                                        } = children[childSet];

                                        const { difference: childDiff = [], significance: childSign = [] } = comparison[
                                            dataSet
                                        ].children[childSet];
                                        const { bg: cbg0 = $white } = sigVInUse[childSign[0]] || {};
                                        const { bg: cbg1 = $white } = sigVInUse[childSign[1]] || {};
                                        const { background: background2 = $white, border: border2 = $white } =
                                            quartileColors[childQuat[2]] || {};
                                        const checkcbg = cbg0 === $white;
                                        const checkcbg1 = cbg1 === $white;
                                        return (
                                            <div key={childName} className="reportRow clearfix">
                                                <h5 className="ellipsisPro">{childName}</h5>
                                                {childScore.slice(0, 2).map((childScData, cSIn) => {
                                                    const { background = $white, border: borderColor = $white } =
                                                        quartileColors[childQuat[cSIn]] || {};
                                                    return (
                                                        <div className="scoreContent" key={cSIn}>
                                                            <div className="rank">
                                                                <div
                                                                    className="textBorder"
                                                                    style={{
                                                                        background,
                                                                        borderColor,
                                                                        borderWidth:
                                                                            childQuat[cSIn] !== '' &&
                                                                            childQuat[cSIn] !== '999'
                                                                                ? 2
                                                                                : 0,
                                                                        width: `${Math.abs(childScData)}%`,
                                                                    }}
                                                                >
                                                                    <div className="textRank">{childScData}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                <div className="rankContent">
                                                    <div className={c('rank', { negative: childDiff[0] < 0 })}>
                                                        <div
                                                            className="textBorder"
                                                            style={{
                                                                border: checkcbg && '1px solid #ddd',
                                                                background: cbg0,
                                                                width: `${Math.abs(childDiff[0])}%`,
                                                            }}
                                                        >
                                                            <div className="textRank">{childDiff[0]}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {ohiScore.length > 2 && (
                                                    <div className="scoreContent">
                                                        <div className="rank">
                                                            <div
                                                                className="textBorder"
                                                                style={{
                                                                    background:
                                                                        childQuat[2] !== '' ? background2 : $white,
                                                                    borderColor: childQuat[2] !== '' ? border2 : $white,
                                                                    borderWidth: childQuat[2] !== '' ? 2 : 0,
                                                                    width: `${Math.abs(childScore[2])}%`,
                                                                }}
                                                            >
                                                                <div className="textRank">{childScore[2]}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {childDiff.length > 1 && (
                                                    <div className="rankContent">
                                                        <div className={c('rank', { negative: childDiff[1] < 0 })}>
                                                            <div
                                                                className="textBorder"
                                                                style={{
                                                                    border: checkcbg1 && '1px solid #ddd',
                                                                    background: cbg1,
                                                                    width: `${Math.abs(childDiff[1])}%`,
                                                                }}
                                                            >
                                                                <div className="textRank">{childDiff[1]}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </section>
                            );
                        })}
                    </div>
                    {isIngClient ? (
                        <div>
                            <div>{localPractice}</div>
                        </div>
                    ) : null}
                    {/* <ul className="benchmark clearfix">
                        <li>{BENCHMARK_HEAD} : </li>
                        {quartileColors.map(({ title, background, border: borderColor }) => (
                            <li key={title}>
                                <span style={{ background, borderColor }} />
                                {title}
                            </li>
                        ))}
                    </ul> */}

                    {isOhi4 && (
                        <div className="note">
                            {!isQuartMovement ? (
                                <Fragment>
                                    <b>{NOTE}:</b>
                                    <br />
                                    {OHI4_RESURVEY_NOTE[0]}
                                    <br />
                                    {OHI4_RESURVEY_NOTE[1]}
                                    <br />
                                    {OHI4_RESURVEY_NOTE[2]}
                                    <br />
                                    {isOhi4 && report_type === 'percent favorable' && <div>4. {PERCENT_FAVORABLE}</div>}
                                    {isOhi4 && report_type === 'percentile' && <div>4. {PERCENTILES}</div>}
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <b>{NOTE}:</b>
                                    <br />
                                    1. {OHI4_RESURVEY_NOTE[3]}
                                    {isOhi4 && report_type === 'percent favorable' && <div>2. {PERCENT_FAVORABLE}</div>}
                                    {isOhi4 && report_type === 'percentile' && <div>2. {PERCENTILES}</div>}
                                </Fragment>
                            )}
                        </div>
                    )}

                    {surveyV ? (
                        <ul className="fixedScoreCardOhi">
                            <div className="benchmark clearfix">
                                <li>{BENCHMARK_HEAD} : </li>
                                {quartileColors
                                    .slice()
                                    .reverse()
                                    .map(({ title, background, border: borderColor }) => {
                                        const bottomDecileVar = title === 'Bottom decile';
                                        const BenchmarkTitle = title === 'No benchmarks';
                                        return (
                                            <li
                                                key={title}
                                                className={c({
                                                    hide: bottomDecileVar || BenchmarkTitle,
                                                })}
                                            >
                                                <span
                                                    className="benchmarkTriangle"
                                                    style={{
                                                        background,
                                                        borderWidth: '13px 13px 0 0',
                                                        borderColor: bottomDecileVar
                                                            ? `transparent ${borderColor} transparent transparent`
                                                            : `${borderColor} transparent transparent transparent`,
                                                        borderStyle: 'solid',
                                                    }}
                                                />
                                                <div style={{ color: 'black' }}>{title}</div>
                                            </li>
                                        );
                                    })}
                            </div>

                            <div
                                className={c('resurveyBottomList clearfix', {
                                    rangeList: isCustomMovement,
                                })}
                            >
                                {resurvey_settings === 'Statistical significance' && (
                                    <li>{STATISTICAL_DIFFERENCE} : </li>
                                )}
                                {resurvey_settings === 'Quartile movement' && <li>{QUARTILE_DIFFERENCE} : </li>}
                                {resurvey_settings === 'Custom delta' && <li>{CUSTOM_DIFFERENCE} : </li>}
                                {sigVInUse.map(({ title, bg: background, text_color, checkSig = false, sup }, i) => {
                                    if (checkSig && !isOhi4) return null;
                                    const titleToUse = isQuartMovement && surveyV ? QUARTILE_MOVE_LEGEND_TXT[i] : title;
                                    const supVal = sup && isOhi4 && !isQuartMovement ? sup : false;
                                    const newItem = CUSTOM_DELTA_LEGEND_TXT[i];
                                    return (
                                        <>
                                            {resurvey_settings !== 'Custom delta' && (
                                                <li key={title}>
                                                    <span
                                                        style={{
                                                            background,
                                                            border:
                                                                checkSig && isOhi4 && !isQuartMovement
                                                                    ? `1px solid ${text_color}`
                                                                    : 'none',
                                                        }}
                                                    />
                                                    {titleToUse} {supVal && <sup>{sup}</sup>}
                                                </li>
                                            )}
                                            {resurvey_settings === 'Custom delta' && (
                                                <li key={title}>
                                                    <span
                                                        style={{
                                                            background,
                                                            border:
                                                                checkSig && isOhi4 && !isQuartMovement
                                                                    ? `1px solid ${text_color}`
                                                                    : 'none',
                                                        }}
                                                    />
                                                    {newItem}
                                                    {isCustomMovement ? (
                                                        <>
                                                            {resurveyCustom[0] !== '' && i !== 3 ? (
                                                                <p style={{ marginLeft: '3px' }}>
                                                                    ({rangesHere[i].key})
                                                                </p>
                                                            ) : (
                                                                <p>{range[i]}</p>
                                                            )}
                                                        </>
                                                    ) : null}
                                                </li>
                                            )}
                                        </>
                                    );
                                })}
                            </div>
                        </ul>
                    ) : (
                        // {/* <div>Note: OHI scores reported in percent favorable</div> */}
                        <ul className="fixedScoreCardOhi">
                            <div className="benchmark clearfix">
                                <li>{BENCHMARK_HEAD} : </li>
                                {quartileColors
                                    .slice()
                                    .reverse()
                                    .map(({ title, background, border: borderColor }) => {
                                        const bottomDecileVar = title === 'Bottom decile';
                                        const BenchmarkTitle = title === 'No benchmarks';
                                        return (
                                            <li
                                                key={title}
                                                style={{ color: background }}
                                                className={c({
                                                    hide: bottomDecileVar || BenchmarkTitle,
                                                })}
                                            >
                                                <span
                                                    className="benchmarkTriangle"
                                                    style={{
                                                        background,
                                                        borderWidth: '13px 13px 0 0',
                                                        border: borderColor,
                                                        borderStyle: 'solid',
                                                    }}
                                                />
                                                <div style={{ color: 'black' }}>{title}</div>
                                            </li>
                                        );
                                    })}
                            </div>
                            <div
                                className={c('resurveyBottomList clearfix', {
                                    rangeList: isCustomMovement,
                                })}
                            >
                                {resurvey_settings === 'Statistical significance' && (
                                    <li>{STATISTICAL_DIFFERENCE} : </li>
                                )}
                                {resurvey_settings === 'Quartile movement' && <li>{QUARTILE_DIFFERENCE} : </li>}
                                {resurvey_settings === 'Custom delta' && <li>{CUSTOM_DIFFERENCE} : </li>}
                                {sigVInUse.map(({ title, bg: background, text_color, checkSig = false, sup }, i) => {
                                    if (checkSig && !isOhi4) return null;
                                    const titleToUse = isQuartMovement && surveyV ? QUARTILE_MOVE_LEGEND_TXT[i] : title;
                                    const supVal = sup && isOhi4 && !isQuartMovement ? sup : false;
                                    const newItem = CUSTOM_DELTA_LEGEND_TXT[i];
                                    return (
                                        <>
                                            {resurvey_settings !== 'Custom delta' && (
                                                <li key={title}>
                                                    <span
                                                        style={{
                                                            background,
                                                            border:
                                                                checkSig && isOhi4 && !isQuartMovement
                                                                    ? `1px solid ${text_color}`
                                                                    : 'none',
                                                        }}
                                                    />
                                                    {titleToUse} {supVal && <sup>{sup}</sup>}
                                                </li>
                                            )}
                                            {resurvey_settings === 'Custom delta' && (
                                                <li key={title}>
                                                    <span
                                                        style={{
                                                            background,
                                                            border:
                                                                checkSig && isOhi4 && !isQuartMovement
                                                                    ? `1px solid ${text_color}`
                                                                    : 'none',
                                                        }}
                                                    />
                                                    {newItem}
                                                    {isCustomMovement ? (
                                                        <>
                                                            {resurveyCustom[0] !== '' && i !== 3 ? (
                                                                <p style={{ marginLeft: '3px' }}>
                                                                    ({rangesHere[i].key})
                                                                </p>
                                                            ) : (
                                                                <p>{range[i]}</p>
                                                            )}
                                                        </>
                                                    ) : null}
                                                </li>
                                            )}
                                        </>
                                    );
                                })}
                            </div>
                        </ul>
                    )}

                    {/* new  */}
                    {/* {surveyV && (
                        <div>
                            <b>{NOTE} : </b>
                            OHI scores reported in percent favorable
                        </div>
                    )} */}
                    {/* {appliedFiltersInfo(nSizeArr)} */}
                </Fragment>
            ) : null}
            {errorInfo && <ErrorToast message={errorInfo} />}
            {dwnld && !reportError && (
                <DwnldModal
                    getRawPptData={getRawPptData}
                    reportId={report_id}
                    open={dwnld}
                    setOpen={setdwnld}
                    getSinglePastReport={getSinglePastReport}
                    singlePastReport={singlePastReport}
                    defaultSettings={defaultSettings}
                />
            )}
        </div>
    );
}

ReSurveyDetailedView.propTypes = {
    isPercentile: PropTypes.bool.isRequired,
    pulseSurvey: PropTypes.bool.isRequired,
    errorInfo: PropTypes.string.isRequired,
    scoreData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    projects: PropTypes.array.isRequired,
    selectedIds: PropTypes.array.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    setChartData: PropTypes.func.isRequired,
    setReport: PropTypes.func.isRequired,
    callReSurveyApi: PropTypes.func.isRequired,
    updateFilterView: PropTypes.func.isRequired,
    getPptData: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    reportError: PropTypes.string.isRequired,
    getResurveyParams: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    singlePastReport: PropTypes.object.isRequired,
    years: PropTypes.array.isRequired,
    localPractice: PropTypes.string.isRequired,
};

export default ReSurveyDetailedView;
