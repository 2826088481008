import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    getPptData,
    setfilterColumns,
    getCustomBuScoreData,
    getCustomBuLangData as setlangData,
    getTlnxEngagementData,
    setCustomBUFilters as setBUFilters,
} from '../../actions';
import { getDefaultSettings } from '../../../Login/selector';
import {
    selectApiCount,
    selectScoreData,
    selectFilters,
    selectFilterColumns,
    selectCustomBuLang,
    selectCustomBuLangFetched,
    selectScoreDataFetched,
    selectTlnxEngagement,
    selectCustomBuFilter,
    getErrorInfo,
} from '../../selector';
import CBUReports from './CBUReports';

const stateToProps = createStructuredSelector({
    defaultSettings: getDefaultSettings(),
    apiLoadingCount: selectApiCount(),
    scoreData: selectScoreData(),
    userfilters: selectFilters(),
    filterColumns: selectFilterColumns(),
    langData: selectCustomBuLang(),
    langDataFetched: selectCustomBuLangFetched(),
    scoreDataFetched: selectScoreDataFetched(),
    engagementData: selectTlnxEngagement(),
    buFilters: selectCustomBuFilter(),
    errorInfo: getErrorInfo(),
});

const dispatchToProps = {
    getPptData,
    setfilterColumns,
    getCustomBuScoreData,
    setlangData,
    getTlnxEngagementData,
    setBUFilters,
};

export default connect(stateToProps, dispatchToProps)(CBUReports);
