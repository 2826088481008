import {
    GET_ACCESS_DETAILS,
    GET_PPT_ACCESS_DETAILS,
    UPDATE_PASSWORD,
    GET_DEFAULT_SETTINGS,
    UPDATE_DEFAULT_SETTINGS,
    REMOVE_SAVED_TOKEN,
} from './constants';

export const getAccessDetails = payload => ({
    type: `${GET_ACCESS_DETAILS}_PENDING`,
    payload,
});

export const getAccessDetailsSuccess = payload => ({
    type: `${GET_ACCESS_DETAILS}_SUCCESS`,
    payload,
});

export const getAccessDetailsFailed = (payload = {}) => ({
    type: `${GET_ACCESS_DETAILS}_FAILED`,
    payload,
});
export const getPptAccessDetails = () => ({
    type: `${GET_PPT_ACCESS_DETAILS}_PENDING`,
});

export const getPptAccessDetailsSuccess = payload => ({
    type: `${GET_PPT_ACCESS_DETAILS}_SUCCESS`,
    payload,
});

export const getPptAccessDetailsFailed = (payload = {}) => ({
    type: `${GET_PPT_ACCESS_DETAILS}_FAILED`,
    payload,
});

export const updatePassword = (payload, token) => ({
    type: `${UPDATE_PASSWORD}_PENDING`,
    payload,
    token,
});

export const updatePasswordSuccess = payload => ({
    type: `${UPDATE_PASSWORD}_SUCCESS`,
    payload,
});

export const updatePasswordFailed = (payload = {}) => ({
    type: `${UPDATE_PASSWORD}_FAILED`,
    payload,
});

export const fetchDefaultSettings = payload => ({
    type: `${GET_DEFAULT_SETTINGS}_PENDING`,
    payload,
});

export const getDefaultSettingsSuccess = payload => ({
    type: `${GET_DEFAULT_SETTINGS}_SUCCESS`,
    payload,
});

export const getDefaultSettingsFailed = payload => ({
    type: `${GET_DEFAULT_SETTINGS}_FAILED`,
    payload,
});

export const updateDefaultSettings = payload => ({
    type: UPDATE_DEFAULT_SETTINGS,
    payload,
});

export const removeToken = (payload = true) => ({
    type: REMOVE_SAVED_TOKEN,
    payload,
});
