import { MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';

import React, { Fragment, useEffect, useState } from 'react';
import './index.scss';
import { cloneDeep } from 'lodash';

const SelectBenchmark = ({
    benchmarks,
    optionsToUse,
    setOptionsToUse,
    updateTargetSettingBm,
    targetBm,
    showLabel,
    updateOnSelect,
}) => {
    const [bm, setBm] = useState(targetBm);
    // const { selectedOption = '' } = benchmarks.filter(({ selectedOption: sel }) => !!sel)[0];

    const resetSelect = (targetBenchmarks, bmCode) => {
        const targetBmArr = bmCode.split(':');
        const defaultChildCode = targetBmArr[0];
        const targetBmDefaultCode = targetBmArr[1];
        const targetBenchIndexToUse = targetBenchmarks.findIndex(
            ({ code }) => code === parseInt(targetBmDefaultCode, 10)
        );
        const { options = [] } = targetBenchmarks[targetBenchIndexToUse];
        const childIndexToUse = options.findIndex(({ code }) => code === parseInt(defaultChildCode, 10));
        const selectedChildIndex = childIndexToUse !== -1 ? childIndexToUse : 0;
        targetBenchmarks[targetBenchIndexToUse].selectedOption = '';
        targetBenchmarks[targetBenchIndexToUse].options[selectedChildIndex].selectedOption = '';
    };

    const handleSelect = bmCode => {
        const targetBenchmarks = cloneDeep(benchmarks);
        resetSelect(targetBenchmarks, targetBm);
        const targetBmArr = bmCode.split(':');
        const defaultChildCode = targetBmArr[0];
        const targetBmDefaultCode = targetBmArr[1];
        const targetBenchIndexToUse = targetBenchmarks.findIndex(
            ({ code }) => code === parseInt(targetBmDefaultCode, 10)
        );
        const { options = [] } = targetBenchmarks[targetBenchIndexToUse];
        const childIndexToUse = options.findIndex(({ code }) => code === parseInt(defaultChildCode, 10));
        const selectedChildIndex = childIndexToUse !== -1 ? childIndexToUse : 0;
        targetBenchmarks[targetBenchIndexToUse].selectedOption =
            targetBenchmarks[targetBenchIndexToUse].options[selectedChildIndex].code;

        // updateTargetSettingBm(targetBenchmarks);
        setOptionsToUse(targetBenchmarks);
        setBm(bmCode);
        if (updateOnSelect) {
            updateTargetSettingBm(targetBenchmarks);
        }
    };

    useEffect(() => {
        setBm(targetBm);
    }, [targetBm]);

    // const optionsToUse = filteredOptions();
    return (
        <Fragment>
            {showLabel ? <div>Benchmarks</div> : null}
            <div className="rightOptions">
                <span>
                    <Select
                        IconComponent={KeyboardArrowDownSharpIcon}
                        value={bm}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                        onChange={({ target: { value: code } }) => {
                            handleSelect(code);
                            // const selectedIndex = benchmarks.findIndex({ options });
                        }}
                    >
                        {optionsToUse.slice(0, 2).map(({ options, code: codeP }) => {
                            return options.map(({ label, code }) => {
                                return (
                                    <MenuItem
                                        classes={{ root: 'demographicOptions' }}
                                        key={code}
                                        value={`${code}:${codeP}`}
                                    >
                                        {label}
                                    </MenuItem>
                                );
                            });
                        })}
                    </Select>
                </span>
            </div>
        </Fragment>
    );
};

SelectBenchmark.defaultProps = {
    updateTargetSettingBm: () => {},
    updateOnSelect: false,
};

SelectBenchmark.propTypes = {
    benchmarks: PropTypes.array.isRequired,
    targetBm: PropTypes.string.isRequired,
    optionsToUse: PropTypes.array.isRequired,
    setOptionsToUse: PropTypes.func.isRequired,
    showLabel: PropTypes.bool.isRequired,
    updateTargetSettingBm: PropTypes.func,
    updateOnSelect: PropTypes.bool,
};

export default SelectBenchmark;
