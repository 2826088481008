import { createSelector } from 'reselect';

const diagnose = state => state.get('diagnose');

const selectApiCount = () => createSelector(diagnose, state => state.toJS().count);
const selectScoreData = () => createSelector(diagnose, state => state.toJS().scoreData);
const selectScoreDataFetched = () => createSelector(diagnose, state => state.toJS().scoreDataFetched);
const selectValuesData = () => createSelector(diagnose, state => state.toJS().valuesData);
const selectValuesDataFetched = () => createSelector(diagnose, state => state.toJS().valuesDataFetched);
const selectCommentsData = () => createSelector(diagnose, state => state.toJS().commentsData);
const selectCommentsDataFetched = () => createSelector(diagnose, state => state.toJS().commentsDataFetched);
const selectPracticeData = () => createSelector(diagnose, state => state.toJS().practiceData);
const practiceDataFetched = () => createSelector(diagnose, state => state.toJS().practiceDataFetched);
const selectHeatMapDataFetched = () => createSelector(diagnose, state => state.toJS().heatMapDataFetched);
const selectFilters = () => createSelector(diagnose, state => state.toJS().filters);
const selectFiltersApplied = () => createSelector(diagnose, state => state.toJS().filtersApplied);
const selectLeftBenchmarks = () => createSelector(diagnose, state => state.toJS().leftBenchmarks);
const selectRightBenchmarks = () => createSelector(diagnose, state => state.toJS().rightBenchmarks);
const selectDefaultDemographics = () => createSelector(diagnose, state => state.toJS().defaultDemographics);
const selectHeatMapData = () => createSelector(diagnose, state => state.toJS().heatMap);
const selectApiParams = () => createSelector(diagnose, state => state.toJS().apiParams);
const selectSelectedDemographic = () => createSelector(diagnose, state => state.toJS().selectedDemographic);
const selectDemographicCurrentTab = () => createSelector(diagnose, state => state.toJS().demographicCurrentTab);
const selectHeatMapPractiseData = () => createSelector(diagnose, state => state.toJS().heatMapPractice);
const selectHeatMapPractiseDataFetched = () => createSelector(diagnose, state => state.toJS().heatMapPracticeFetched);
const selectProjectsFetched = () => createSelector(diagnose, state => state.toJS().projectsFetched);
const selectProjects = () => createSelector(diagnose, state => state.toJS().projects);
const selectReSurveyData = () => createSelector(diagnose, state => state.toJS().reSurveyData);
const selectReSurveyDataFetched = () => createSelector(diagnose, state => state.toJS().reSurveyDataFetched);
const getErrorInfo = () => createSelector(diagnose, state => state.toJS().error);
const selectBuData = () => createSelector(diagnose, state => state.toJS().buData);
const selectBuDataFetched = () => createSelector(diagnose, state => state.toJS().buDataFetched);
const selectPracticeBuData = () => createSelector(diagnose, state => state.toJS().practiceBuData);
const selectPracticeBuDataFetched = () => createSelector(diagnose, state => state.toJS().practiceBuDataFetched);
const selectToolData = () => createSelector(diagnose, state => state.toJS().toolBox);
const selectToolError = () => createSelector(diagnose, state => state.toJS().toolBoxError);
const selectToolDataFetched = () => createSelector(diagnose, state => state.toJS().toolBoxFetched);
const selectCohesionDataFetched = () => createSelector(diagnose, state => state.toJS().cohesionDataFetched);
const selectToolDataLoading = () => createSelector(diagnose, state => state.toJS().toolBoxLoading);
const selectEnableCreateTheme = () => createSelector(diagnose, state => state.toJS().enableCreateTheme);
const selectCurrentTheme = () => createSelector(diagnose, state => state.toJS().currentTheme);
const selectPracticeToDisplay = () => createSelector(diagnose, state => state.toJS().practiceToDisplay);
const selectIsCohesionData = () => createSelector(diagnose, state => state.toJS().cohesionData);
const selectCohesionError = () => createSelector(diagnose, state => state.toJS().cohesionError);
const selectCohesionScore = () => createSelector(diagnose, state => state.toJS().cohesionScore);
const selectCohesionDemographic = () => createSelector(diagnose, state => state.toJS().cohesionDemographic);
const selectIsOutcomeRetrieved = () => createSelector(diagnose, state => state.toJS().isOutcomeRetrieved);
const selectIsPractiseRetrived = () => createSelector(diagnose, state => state.toJS().isPracticeRetrived);
const selectProgressBar = () => createSelector(diagnose, state => state.toJS().showProgressBar);
const selectLoadHeatMapOutcomeData = () => createSelector(diagnose, state => state.toJS().loadHeatMapOutcome);
const selectLoadHeatMapPracticeData = () => createSelector(diagnose, state => state.toJS().loadHeatMapPractice);
const selectPracticePageNo = () => createSelector(diagnose, state => state.toJS().practicePageNo);
const selectOutcomePageNo = () => createSelector(diagnose, state => state.toJS().outcomePageNo);
const selectPptStatus = () => createSelector(diagnose, state => state.toJS().pptStatus);
const selectPptError = () => createSelector(diagnose, state => state.toJS().pptError);
const selectPptStatusFetching = () => createSelector(diagnose, state => state.toJS().pptStatusFetching);
const selectHeatMapInclusionError = () => createSelector(diagnose, state => state.toJS().heatMapInclusionError);
const selectHeatMapInclusion = () => createSelector(diagnose, state => state.toJS().heatMapInclusion);
const selectHeatMapInclusionFetched = () => createSelector(diagnose, state => state.toJS().heatMapInclusionFetched);
const selectHeatMapResurveyTab = () => createSelector(diagnose, state => state.toJS().heatMapResurveyTab);
const selectHeatMapResurveyInfo = () => createSelector(diagnose, state => state.toJS().heatMapResurveyInfo);
const selectResurveyOutcomeHeatMap = () => createSelector(diagnose, state => state.toJS().resurveyOutcomeHeatMap);
const selectResurveyPracticeHeatMap = () => createSelector(diagnose, state => state.toJS().resurveyPracticeHeatMap);
const selectResurveyPracticeHeatMapFetched = () =>
    createSelector(diagnose, state => state.toJS().resurveyPracticeHeatMapFetched);
const selectResurveyOutcomeHeatMapFetched = () =>
    createSelector(diagnose, state => state.toJS().resurveyOutcomeHeatMapFetched);
const selectNpsData = () => createSelector(diagnose, state => state.toJS().npsData);
const selectNpsDataFetched = () => createSelector(diagnose, state => state.toJS().npsDataFetched);
const selectNpsDemographicData = () => createSelector(diagnose, state => state.toJS().npsDemographicData);
const selectNpsDemographicDataFetched = () => createSelector(diagnose, state => state.toJS().npsDemographicDataFetched);
const selectNpsDropdown = () => createSelector(diagnose, state => state.toJS().npsDropdown);
const selectEngagementData = () => createSelector(diagnose, state => state.toJS().engagementData);
const selectEngagementDataFetched = () => createSelector(diagnose, state => state.toJS().engagementDataFetched);
const selectEngagementDemographicData = () => createSelector(diagnose, state => state.toJS().engagementDemographicData);
const selectEngagementDemographicDataFetched = () =>
    createSelector(diagnose, state => state.toJS().engagementDemographicDataFetched);
const selectEngagementDropdown = () => createSelector(diagnose, state => state.toJS().engagementDropdown);
const selectFilterColumns = () => createSelector(diagnose, state => state.toJS().filterColumns);
const selectCustomBuLang = () => createSelector(diagnose, state => state.toJS().customBuLang);
const selectTlnxEngagement = () => createSelector(diagnose, state => state.toJS().tlnxEngagement);
const selectCustomBuLangFetched = () => createSelector(diagnose, state => state.toJS().customBuLangFetched);
const selectCustomBuFilter = () => createSelector(diagnose, state => state.toJS().customBuFilters);
const selectClientEngagementData = () => createSelector(diagnose, state => state.toJS().clientEngagementData);
const selectClientEngagementFetched = () => createSelector(diagnose, state => state.toJS().clientEngagementFetched);
const selectSingleQuestion = () => createSelector(diagnose, state => state.toJS().singleQuestion);
const selectSingleQuestionFetched = () => createSelector(diagnose, state => state.toJS().singleQuestionFetched);
const selectIeHeatmap = () => createSelector(diagnose, state => state.toJS().ieHeatmap);
const selectIeHeatmapFetched = () => createSelector(diagnose, state => state.toJS().ieHeatmapFetched);
const selectEmpExpHeatmaps = () => createSelector(diagnose, state => state.toJS().empExpHeatmaps);
const selectEmpExpOhiHeatmaps = () => createSelector(diagnose, state => state.toJS().empExpOhiHeatmaps);
const selectPastReport = () => createSelector(diagnose, state => state.toJS().pastReport);
const selectOrgHealth = () => createSelector(diagnose, state => state.toJS().orgHealth);
const selectRankingHeatmapFetched = () => createSelector(diagnose, state => state.toJS().heatMapRankingFetched);
const selectHeatMapRanking = () => createSelector(diagnose, state => state.toJS().heatMapRanking);
const selectLoadHeatMapRankingData = () => createSelector(diagnose, state => state.toJS().loadHeatMapRanking);
const selectRankingPageNo = () => createSelector(diagnose, state => state.toJS().rankingPageNo);
const selectOrgHealthFetched = () => createSelector(diagnose, state => state.toJS().orgHealthFetched);
const selectPracticeDataCompYr = () => createSelector(diagnose, state => state.toJS().practiceDataCompYr);
const selectAddOnTabs = () => createSelector(diagnose, state => state.toJS().addOnTab);
const selectScoreDataHybrid = () => createSelector(diagnose, state => state.toJS().scoreDataHybrid);
const selectScoreDataHybridFetched = () => createSelector(diagnose, state => state.toJS().scoreDataHybridFetched);
const selectEnpsFetched = () => createSelector(diagnose, state => state.toJS().enpsFetched);
const selectLocalPractice = () => createSelector(diagnose, state => state.toJS().localPractice);
const selectLocalPracticeFetched = () => createSelector(diagnose, state => state.toJS().localPracticeFetched);
const selectFeedback = () => createSelector(diagnose, state => state.toJS().feedback);
const selectFeedbackSuccess = () => createSelector(diagnose, state => state.toJS().sendFeedbackSuccess);
const selectFeedbackList = () => createSelector(diagnose, state => state.toJS().feedbackList);
const selectFeedbackListFetched = () => createSelector(diagnose, state => state.toJS().feedbackListFetched);
const selectFeedbackDetails = () => createSelector(diagnose, state => state.toJS().feedbackDetails);
const selectFeedbackDetailsFetched = () => createSelector(diagnose, state => state.toJS().feedbackDetailsFetched);
const selectFeedbackOptions = () => createSelector(diagnose, state => state.toJS().feedbackOptions);
const selectTargetScoreData = () => createSelector(diagnose, state => state.toJS().targetScoreData);
const selectTargetScoreDataFetched = () => createSelector(diagnose, state => state.toJS().targetScoreDataFetched);
const selectTargetBenchmarks = () => createSelector(diagnose, state => state.toJS().targetBenchmarks);
const selectTargetBm = () => createSelector(diagnose, state => state.toJS().targetBm);

export {
    selectApiCount,
    selectScoreData,
    selectScoreDataFetched,
    selectPracticeData,
    practiceDataFetched,
    selectFilters,
    selectFiltersApplied,
    selectLeftBenchmarks,
    selectRightBenchmarks,
    selectHeatMapData,
    selectHeatMapDataFetched,
    selectApiParams,
    selectSelectedDemographic,
    selectHeatMapPractiseData,
    selectHeatMapPractiseDataFetched,
    selectDemographicCurrentTab,
    selectProjectsFetched,
    selectProjects,
    selectReSurveyData,
    selectReSurveyDataFetched,
    getErrorInfo,
    selectBuData,
    selectBuDataFetched,
    selectToolData,
    selectToolError,
    selectToolDataFetched,
    selectToolDataLoading,
    selectEnableCreateTheme,
    selectCurrentTheme,
    selectPracticeToDisplay,
    selectIsOutcomeRetrieved,
    selectIsPractiseRetrived,
    selectIsCohesionData,
    selectCohesionError,
    selectCohesionScore,
    selectCohesionDemographic,
    selectProgressBar,
    selectOutcomePageNo,
    selectPracticePageNo,
    selectLoadHeatMapPracticeData,
    selectLoadHeatMapOutcomeData,
    selectPptStatus,
    selectPptError,
    selectPptStatusFetching,
    selectCohesionDataFetched,
    selectValuesData,
    selectValuesDataFetched,
    selectPracticeBuData,
    selectPracticeBuDataFetched,
    selectHeatMapInclusion,
    selectHeatMapInclusionFetched,
    selectHeatMapInclusionError,
    selectCommentsData,
    selectCommentsDataFetched,
    selectHeatMapResurveyTab,
    selectHeatMapResurveyInfo,
    selectResurveyPracticeHeatMapFetched,
    selectResurveyOutcomeHeatMapFetched,
    selectResurveyOutcomeHeatMap,
    selectResurveyPracticeHeatMap,
    selectDefaultDemographics,
    selectNpsData,
    selectNpsDataFetched,
    selectNpsDemographicData,
    selectNpsDemographicDataFetched,
    selectNpsDropdown,
    selectEngagementData,
    selectEngagementDataFetched,
    selectEngagementDemographicData,
    selectEngagementDemographicDataFetched,
    selectEngagementDropdown,
    selectClientEngagementData,
    selectClientEngagementFetched,
    selectSingleQuestion,
    selectSingleQuestionFetched,
    selectIeHeatmap,
    selectIeHeatmapFetched,
    selectEmpExpHeatmaps,
    selectEmpExpOhiHeatmaps,
    selectFilterColumns,
    selectCustomBuLang,
    selectTlnxEngagement,
    selectCustomBuLangFetched,
    selectCustomBuFilter,
    selectPastReport,
    selectOrgHealth,
    selectHeatMapRanking,
    selectRankingHeatmapFetched,
    selectLoadHeatMapRankingData,
    selectRankingPageNo,
    selectOrgHealthFetched,
    selectPracticeDataCompYr,
    selectAddOnTabs,
    selectScoreDataHybrid,
    selectScoreDataHybridFetched,
    selectEnpsFetched,
    selectLocalPractice,
    selectLocalPracticeFetched,
    selectFeedback,
    selectFeedbackSuccess,
    selectFeedbackList,
    selectFeedbackListFetched,
    selectFeedbackDetails,
    selectFeedbackDetailsFetched,
    selectFeedbackOptions,
    selectTargetScoreData,
    selectTargetScoreDataFetched,
    selectTargetBenchmarks,
    selectTargetBm,
};
