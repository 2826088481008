import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import PracticeRanking from './PracticeRanking';
import { getPptData } from '../../Diagnose/actions';
import { selectFiltersApplied } from '../selector';
import { getDefaultSettings } from '../../Login/selector';

const stateToProps = createStructuredSelector({
    activeFilter: selectFiltersApplied(),
    defaultSettings: getDefaultSettings(),
});

const dispatchToProps = { getPptData };

export default connect(stateToProps, dispatchToProps)(PracticeRanking);
