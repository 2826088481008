import Scorecard from '../assets/Scorecard.svg';
import Scorecard_active from '../assets/Scorecard_active.svg';
import Profile from '../assets/Profile.svg';
import Profile_active from '../assets/profile_active.svg';
import Demographic from '../assets/Demographic.svg';
import Demographic_active from '../assets/demographic_active.svg';
import Benchmark from '../assets/Benchmark.svg';
import Benchmark_active from '../assets/benchmark_active.svg';
import Questions from '../assets/Questions.svg';
import Questions_active from '../assets/questions_active.svg';
import PracticeRanking from '../assets/PracticeRanking.svg';
import PracticeRanking_active from '../assets/practiceranking_active.svg';
import TextAnalysis from '../assets/TextAnalysis.svg';
import TextAnalysis_active from '../assets/textananalytics_active.svg';
import Receipe1 from '../assets/Receipe1.svg';
import Receipe2 from '../assets/Receipe2.svg';
import Receipe3 from '../assets/Receipe3.svg';
import Receipe4 from '../assets/Receipe4.svg';
import Alignment from '../assets/Alignment.svg';
import Execution from '../assets/Execution.svg';
import Renewal from '../assets/Renewal.svg';
import colors from '../../../sass/colors';
import AUTH0 from '../../../auth_config';
import Experience from '../assets/Experience.svg';
import Menu from '../assets/Menu.svg';
import Experience_active from '../assets/experience_active.svg';
import Briefcase from '../assets/briefcase.svg';
import Purpose from '../assets/purpose.svg';
import Talent from '../assets/talent.svg';
import Hands from '../assets/hands.svg';
import Recipe from '../assets/Recipe.svg';
import RecipeSelected from '../assets/Recipe_selected.svg';

export const NAV_LINKS = [
    {
        imgSrc: Scorecard,
        imgSrcActive: Scorecard_active,
        index: 0,
    },
    {
        imgSrc: Profile,
        imgSrcActive: Profile_active,
        index: 1,
        hideforProfile: true,
    },
    {
        imgSrc: Experience,
        imgSrcActive: Experience_active,
        index: 7,
        checkForOhi4: true,
    },
    {
        imgSrc: PracticeRanking,
        imgSrcActive: PracticeRanking_active,
        index: 5,
        checkToShow: true,
        checkTabNonProfit: true,
    },
    {
        imgSrc: Recipe,
        imgSrcActive: RecipeSelected,
        index: 9,
        checkForNonProfit: true,
        checkToShow: true,
        checkForCST: true,
    },
    {
        imgSrc: Demographic,
        imgSrcActive: Demographic_active,
        index: 2,
    },
    {
        imgSrc: Benchmark,
        imgSrcActive: Benchmark_active,
        checkForNonProfit: true,
        index: 3,
        checkForPercentile: true,
    },
    {
        imgSrc: TextAnalysis,
        imgSrcActive: TextAnalysis_active,
        index: 6,
        checkForCohesion: true,
        checkForNonProfit: true,
    },
    {
        imgSrc: Questions,
        imgSrcActive: Questions_active,
        index: 4,
    },
    {
        imgSrc: TextAnalysis,
        link: AUTH0.textAnalyticsUrl,
        checkForAnalytics: true,
        checkForIng: true,
    },
    {
        imgSrc: Menu,
        imgSrcActive: Menu,
        index: 8,
        checkForAddOns: true,
        checkForTalnx: true,
    },
];

export const PIE_CHART_CONFIG = {
    chart: {
        backgroundColor: '#f7f7f7',
        type: 'pie',
        height: 500,
        width: 500,
    },
    credits: {
        enabled: false,
    },
    tooltip: {
        enabled: false,
    },
    accessibility: {
        point: {
            valueSuffix: '%',
        },
    },
    legend: {
        symbolRadius: 0,
    },
    plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                format: '{point.name} {y}%',
            },
        },
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
            },
            showInLegend: true,
        },
    },
};

export const PROFILE_TILE_KEYS = [
    'accountability',
    'direction',
    'coordination_control',
    'external_orientation',
    'leadership',
    'innovation_learning',
    'capabilities',
    'work_environment',
    'motivation',
];

export const PROFILE_CATEGORIES = [
    {
        categories: ['direction', 'leadership', 'work_environment'],
        imgSrc: Alignment,
        labelName: 'Alignment',
        labelDesc: 'Are people aligned around the organization’s vision, strategy, culture and values?',
        descriptions: [
            '… communicate a clear and compelling vision of where the organization is headed, how to get there, and what it means for people',
            '…use effective leadership styles to shape the actions of people in the organization to drive high performance',
            '…cultivate a clear, consistent set of values and working norms that  foster effective workplace behavior',
        ],
    },
    {
        categories: ['accountability', 'coordination_control', 'capabilities', 'motivation'],
        imgSrc: Execution,
        labelName: 'Execution',
        labelDesc:
            'Can employees deliver on their roles with the current capabilities, processes and motivation level?',
        descriptions: [
            '…ensure individuals understand what is expected of them, have sufficient authority and feel accountable for delivering results',
            '…consistently measure and manage business and risk, and act to address problems when they arise',
            '…ensure the institutional skills and talent are in place to execute the strategy and create competitive advantage',
            '…develop employee loyalty and enthusiasm, and inspire people to exert extraordinary effort to perform at their very best',
        ],
    },
    {
        categories: ['innovation_learning', 'work_environment'],
        imgSrc: Renewal,
        labelName: 'Renewal',
        labelDesc:
            'How does the organization understand, interact, respond, and adapt to its situation and external environment?',
        descriptions: [
            '…encourage and harness new ideas, including everything from radical innovation to incremental improvement, so the organization can effectively evolve and grow over time',
            '…engage with important external stakeholders (customers, suppliers, partners, and others) to more effectively create and deliver value – both now and in the future',
        ],
    },
];

export const PROFILE_CATEGORIES_TXT = [
    ['direction', 'leadership', 'work_environment'],
    ['accountability', 'coordination_control', 'capabilities', 'motivation'],
    ['innovation_learning', 'work_environment'],
];

export const QUESTIONS_HEATMAP = [
    {
        title: 'Individual Purpose',
        imgSrc: Purpose,
        children: ['N', 'Connection to Meaning'],
    },
    {
        title: 'Career',
        imgSrc: Briefcase,
        children: ['Professional Growth', 'Equitable Opportunity', 'Career Customization'],
    },
    {
        title: 'Talent Attraction & Retention',
        imgSrc: Talent,
        children: ['Endorsing Organization to Others', 'Job Satisfaction', 'Intent to Leave'],
    },
    {
        title: 'Well-Being',
        imgSrc: Hands,
        children: ['Psychological Safety', 'Energy', 'Burnout'],
    },
];

export const QUESTIONS = [
    {
        title: 'Individual Purpose',
        imgSrc: Purpose,
        children: ['connection_to_meaning'],
    },
    {
        title: 'Career',
        imgSrc: Briefcase,
        children: ['professional_growth', 'equitable_opportunity', 'career_customization'],
    },
    {
        title: 'Talent Attraction & Retention',
        imgSrc: Talent,
        children: ['endorsing_organization_to_others', 'job_satisfaction', 'intent_to_leave'],
    },
    {
        title: 'Well-Being',
        imgSrc: Hands,
        children: ['psychological_safety', 'energy', 'burnout'],
    },
];

export const WORKPLACE_DEMO = 'demo_WorkplaceLocation';
export const DEFAULT_STATE = 'demo_OHIScoreCal';
export const ORGHEALTH_DEMO = 'demo_OVERALLHEALTH';
export const INT_TO_LEAVE_DEMO = 'demo_IEIntLea1A';
export const ENPS_DEMO = 'demo_IENPS1';
export const SLICE_IE_MAX_VALUE = 8;
export const SLICE_IE_MIN_VALUE = 0;

export const IE_CROSSTAB_OPTIONS = [
    {
        label: 'Employee Experience factor & OHI Score',
        code: 'demo_OHIScoreCal',
        name: '',
        // new
        nonProfitAccess: true,
    },
    {
        label: 'Employee Experience factor & OHI Score by eNPS',
        code: 'demo_IENPS1',
        name: 'Endorsing Organization to Others',
    },
    {
        label: 'Employee Experience factor & OHI Score by Intent to leave',
        code: 'demo_IEIntLea1A',
        name: 'Intent to Leave',
    },
    {
        label: 'Employee Experience factors by Work location',
        code: 'demo_WorkplaceLocation',
        name: 'Workplace Location',
        // new
        nonProfitAccess: true,
    },
    {
        label: 'Employee Experience factors & Organisational health analysis',
        code: 'demo_OVERALLHEALTH',
        pptName: 'Employee_Experience_with_OrgHealth',
        // new
        nonProfitAccess: true,
    },
];

export const THEME_PARAMS = {
    theme_name: '',
    description: '',
    notes: '',
    practices: '',
};

export const VALUES_PPT_COLOR = {
    enhancer: colors.$green300,
    detractor: colors.$red400,
};

export const DEMO_INLUSION_OHID = 'OHI09252';

export const ADD_QUES_SIZE = 8;

export const PRACTICE_META_CHECKS = {
    direction: 'DPD2',
    leadership: 'LPChal3',
    work_environment: 'EVPOpenNew1',
    coordination_control: 'CCPProf1',
    capabilities: 'CapPAcq4',
};

export const recipeImages = [Receipe1, Receipe2, Receipe3, Receipe4];
export const NPS_TABLE_HEADERS = ['N', 'Promoters', 'Passives', 'Detractors', 'Net Score'];
export const ENGAGEMENT_TABLE_HEADERS = [' ', 'Size', 'Disagree', 'Agree', 'Neutral'];

export const PULSE_SURVEY = 'Pulse';
export const QUARTILE_RESURVEY = 'Quartile movement';
export const CUSTOM_RESURVEY = 'Custom delta';

export const SLICE_VALUE = 4;
export const MAX_LENGTH_SUBSTRING = 36;
export const PPT_SLICE = 5;
export const PPT_SLICE_V2 = 2;
export const PPT_SLICE_V3 = 4;
export const RESURVEY_BREAKPOINT = 12;
export const RESURVEY_BREAKPOINT1 = 7;
export const OUTCOME_VAR = 'outcome';
export const PRACTICE_VAR = 'practice';
export const PR_VAR = 'Practice ranking';
export const PR = 'Practice ranking';
export const PRACTICES = 'Practices';
export const INCLUSION_VAR = 'Inclusion';
export const SLICE_MAX_VALUE = 8;
export const SLICE_MIN_VALUE = 1;
export const SORT_ASCENDING = 1;
export const SORT_DESCENDING = -1;
export const BAR_CHART_SCORE = [0, 5, 7, 10];
export const GET_SCORE_DATA = 'GET_SCORE_DATA';
export const GET_PRACTICE_DATA = 'GET_PRACTICE_DATA';
export const GET_PRACTICE_BU_DATA = 'GET_PRACTICE_BU_DATA';
export const GET_DEMOGRAPHICS = 'GET_DEMOGRAPHICS';
export const GET_BENCHMARKS = 'GET_BENCHMARKS';
export const UPDATE_LEFT_BENCHMARKS = 'UPDATE_LEFT_BENCHMARKS';
export const UPDATE_RIGHT_BENCHMARKS = 'UPDATE_RIGHT_BENCHMARKS';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const GET_HEATMAP_OUTCOME = 'GET_HEATMAP_OUTCOME';
export const UPDATE_API_PARAMS = 'UPDATE_API_PARAMS';
export const GET_HEATMAP_PRACTICE = 'GET_HEATMAP_PRACTICE';
export const GET_LOCAL_PRACTICE = 'GET_LOCAL_PRACTICE';
export const GET_HEATMAP_INCLUSION = 'GET_HEATMAP_INCLUSION';
export const SET_SELECTED_DEMOGRAPHIC = 'SET_SELECTED_DEMOGRAPHIC';
export const SET_DEMOGRAPHIC_CURRENT_TAB = 'SET_DEMOGRAPHIC_CURRENT_TAB';
export const UPDATE_HEAT_MAP_OUTCOME = 'UPDATE_HEAT_MAP_OUTCOME';
export const UPDATE_HEAT_MAP_PRACTICE = 'UPDATE_HEAT_MAP_PRACTICE';
export const SORT_HEAT_MAP_OUTCOME = 'SORT_HEAT_MAP_OUTCOME';
export const SORT_HEAT_MAP_RANKING = 'SORT_HEAT_MAP_RANKING';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_RESURVEY_DATA = 'GET_RESURVEY_DATA';
export const RESET_RESURVEY_FETCHED = 'RESET_RESURVEY_FETCHED';
export const RESET_BU_FETCHED = 'RESET_BU_FETCHED';
export const RESET_PRACTICE_BU_FETCHED = 'RESET_PRACTICE_BU_FETCHED';
export const GET_BU_DATA = 'GET_BU_DATA';
export const GET_PPT_DATA = 'GET_PPT_DATA';
export const RES_PPT_DATA = 'RES_PPT_DATA';
export const GET_PPT_STATUS = 'GET_PPT_STATUS';
export const RESET_FILTER_INDEX = 'RESET_FILTER_INDEX';
export const GET_TOOLBOX_THEME = 'GET_TOOLBOX_THEME';
export const CREATE_THEME = 'CREATE_THEME';
export const DELETE_THEME = 'DELETE_THEME';
export const SET_THEME = 'ADD_THEME';
export const SET_PRACTICE_DISPLAY = 'SET_PRACTICE_DISPLAY';
export const TOGGLE_CREATE_THEME = 'TOGGLE_CREATE_THEME';
export const GET_COHESION_DATA = 'GET_COHESION_DATA';
export const SET_COHESION_DEMOGRAPHIC = 'SET_COHESION_DEMOGRAPHIC';
export const SET_HEATMAP_PRACTICE_DATA = 'SET_HEATMAP_PRACTICE_DATA';
export const SET_HEATMAP_OUTCOME_DATA = 'SET_HEATMAP_OUTCOME_DATA';
export const SET_PRACTICE_PAGE_NO = 'SET_PRACTICE_PAGE_NO';
export const SET_OUTCOME_PAGE_NO = 'SET_OUTCOME_PAGE_NO';
export const SET_RANKING_PAGE_NO = 'SET_RANKING_PAGE_NO';
export const SET_OUTCOME_RETRIVED = 'SET_OUTCOME_RETRIVED';
export const SET_PRACTICE_RETRIEVED = 'SET_PRACTICE_RETRIEVED';
export const GET_VALUES_DATA = 'GET_VALUES_DATA';
export const GET_TEXT_COMMENTS = 'GET_TEXT_COMMENTS';
export const TOGGLE_PROGRESS_BAR = 'TOGGLE_PROGRESS_BAR';
export const SET_COHESION_SORT = 'SET_COHESION_SORT';
export const GET_IA_BENCHMARK = 'GET_IA_BENCHMARK';
export const GET_MAPPED_DEMOGRAPHICS = 'GET_MAPPED_DEMOGRAPHICS';
export const UPDATE_HEATMAP_RESURVEY = 'UPDATE_HEATMAP_RESURVEY';
export const SET_RESURVEY_HEATMAP_TAB = 'SET_RESURVEY_HEATMAP_TAB';
export const GET_RESURVEY_HEATMAP_OUTCOME = 'GET_RESURVEY_HEATMAP_OUTCOME';
export const GET_RESURVEY_HEATMAP_PRACTICE = 'GET_RESURVEY_HEATMAP_PRACTICE';
export const PERCENTILE = 'percentile';
export const GET_BENCHMARK_VERSION = 'GET_BENCHMARK_VERSION';
export const GET_NPS_DATA = 'GET_NPS_DATA';
export const GET_NPS_DEMOGRAPHIC_DATA = 'GET_NPS_DEMOGRAPHIC_DATA';
export const SET_NPS_DROPDOWN = 'SET_NPS_DROPDOWN';
export const GET_ENGAGEMENT_DATA = 'GET_ENGAGEMENT_DATA';
export const GET_ENGAGEMENT_DEMOGRAPHIC_DATA = 'GET_ENGAGEMENT_DEMOGRAPHIC_DATA';
export const SET_ENGAGEMENT_DROPDOWN = 'SET_ENGAGEMENT_DROPDOWN';
export const GET_CLIENT_ENGAGEMENT_DATA = 'GET_CLIENT_ENGAGEMENT_DATA';
export const GET_SINGLE_QUESTION = 'GET_SINGLE_QUESTION';
export const GET_IE_HEATMAP = 'GET_IE_HEATMAP';
export const GET_EMP_EXP_HEATMAP = 'GET_EMP_EXP_HEATMAP';
export const GET_OHI_EMP_EXP_HEATMAP = 'GET_OHI_EMP_EXP_HEATMAP';
export const SORT_IE_HEAT_MAP = 'SORT_IE_HEAT_MAP';
export const SET_FILTER_COLUMNS = 'SET_FILTER_COLUMNS';
export const GET_CUSTOM_BU_SCORE_DATA = 'GET_CUSTOM_BU_SCORE_DATA';
export const GET_CUSTOM_BU_LANG_DATA = 'GET_CUSTOM_BU_LANG_DATA';
export const GET_TLNX_ENGAGEMENT_DATA = 'GET_TLNX_ENGAGEMENT_DATA';
export const GET_SINGLE_PAST_REPORT = 'GET_SINGLE_PAST_REPORT';
export const GET_ORGHEALTH_DATA = 'GET_ORGHEALTH_DATA';
export const SET_CUSTOM_BU_FILTER = 'SET_CUSTOM_BU_FILTER';
export const GET_RANKING_HEATMAP = 'GET_RANKING_HEATMAP';
export const SET_RANKING_HEATMAP_RETRIEVED = 'SET_RANKING_HEATMAP_RETRIEVED';
export const SET_HEATMAP_RANKING_DATA = 'SET_HEATMAP_RANKING_DATA';
export const UPDATE_HEAT_MAP_RANKING = 'UPDATE_HEAT_MAP_RANKING';
export const GET_COMP_YR_PRACTICE_DATA = 'GET_COMP_YR_PRACTICE_DATA';
export const NUMBER_OF_RESPONDENTS_N = 'Number of respondents';
export const SET_ADD_ON_TAB = 'SET_ADD_ON_TAB';
export const CUSTOM_BU_QUARTILE_COLORS = [
    {
        title: 'Top Decile',
        border: 'rgb(255, 153, 0)',
        color_gr: '#006729',
        color_bl: '#0066B4',
        top_position: '19000',
    },
    {
        title: 'Top Quartile',
        border: '#002960',
        color_gr: '#006729',
        color_bl: '#0066B4',
        top_position: '23500',
    },
    {
        title: 'Second Quartile',
        border: '#128fa7',
        color_gr: '#79B530',
        color_bl: '#80CCEA',
        top_position: '31000',
    },
    {
        title: 'Third Quartile',
        border: '#9fb9bd',
        color_gr: '#C6C7C8',
        color_bl: '#D9D9D9',
        top_position: '41000',
    },
    {
        title: 'Bottom Quartile',
        border: '#d9d9d9',
        color_gr: '#6C6F70',
        color_bl: '#808080',
        top_position: '51000',
    },
];

export const CUSTOM_BU_DIFF_COLORS = [
    { title: '< -10', color: '#FF7575' },
    { title: '-10 - -1', color: '#FFD1D1' },
    { title: '0 - 10', color: '#CCF0BA' },
    { title: '> 10', color: '#60C93F' },
    { title: '', color: '#ffffff' },
];

export const CUSTOM_BU_COLUMNS = {
    CULTURE_N_CLIMATE: 'Culture & Climate',
    WORK_ENV: 'Work Environment',
    ALL_OF_TALANX_COLUMNS: 'All of Talanx',
};

export const FEEDBACK_PAYLOAD = {
    summary: '',
    description: '',
    type_of_bug: '',
    immediate_impact: false,
    is_workaround: true,
    environment: 'Test',
};

export const REPORT_FEEDBACK_SITE_TEXT = {
    CONFIRM_TXT:
        "Before submitting a new issue, please ensure that it's not already covered by an active ticket you have submitted. This helps us address your concerns more efficiently.",
};

export const FEEDBACK_ISSUES_OPTIONS = ['Data issue', 'Calculation error', 'PPT issue', 'Other'];
export const REPORT_FEEDBACK_LIST = ['Report feedback', 'My feedback list'];

export const GET_SCORE_DATA_HYBRID = 'GET_SCORE_DATA_HYBRID';
export const SORT_HEAT_MAP_PRACTICE = 'SORT_HEAT_MAP_PRACTICE';
export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const GET_FEEDBACK_LIST = 'GET_FEEDBACK_LIST';
export const GET_FEEDBACK_DETAILS = 'GET_FEEDBACK_DETAILS';
export const RESET_FEEDBACK = 'RESET_FEEDBACK';
export const REFRESH_FEEDBACK_LIST = 'REFRESH_FEEDBACK_LIST';
export const SET_FEEDBACK_OPTIONS = 'SET_FEEDBACK_OPTIONS';
export const GET_TARGET_SCORE_DATA = 'GET_TARGET_SCORE_DATA';
export const UPDATE_TARGET_SETTING_BM = 'UPDATE_TARGET_SETTING_BM';
