import { combineReducers } from 'redux-immutable';
import loader from './loader';
import { login } from '../containers/Login';
import { diagnose } from '../containers/Diagnose';
import { reports } from '../containers/Reports';
import { settings } from '../containers/Settings';
import { userReports } from '../containers/UserReports';
import { inclusion } from '../containers/Inclusion';
import { compendium } from '../containers/ActionCompendium';
import { responseRate } from '../containers/ResponseRate';
import analytics from '../containers/Analytics/reducer';

const rootReducer = combineReducers({
    loader,
    login,
    diagnose,
    reports,
    settings,
    userReports,
    inclusion,
    compendium,
    responseRate,
    analytics,
});

export default rootReducer;
