import { fromJS } from 'immutable';
import {
    GET_DAILY_RES,
    GET_DEMO_RES,
    SET_DEMO_VALUE,
    SET_CROSS_TABS_DEMO,
    GET_CROSS_TAB,
    RELOAD_DATA,
    RESET_TAB_DATA,
} from './constants';
import { REMOVE_SAVED_TOKEN, GET_DEFAULT_SETTINGS } from '../Login/constants';
import getStaticTextDiagnose from '../Diagnose/constants';

const staticTextDiagnose = getStaticTextDiagnose();

const { UPDATE_FILTERS, GET_DEMOGRAPHICS } = staticTextDiagnose;

export const INITIAL_STATE = fromJS({
    count: 0,
    error: fromJS([]),
    dailyResponses: fromJS({}),
    dailyResFetched: false,
    demoResponses: fromJS({}),
    demoResFetched: false,
    crossTabData: fromJS({}),
    crossTabFetched: false,
    dropDownValue: '',
    crossTabsDrop: fromJS([]),
});

const reducer = (state = INITIAL_STATE, { type = '', payload = {} }) => {
    switch (type) {
        case `${GET_DAILY_RES}_PENDING`: {
            return state.updateIn(['error', 0], () => '').update('count', count => count + 1);
        }
        case `${GET_DEMO_RES}_PENDING`: {
            return state.updateIn(['error', 1], () => '').update('count', count => count + 1);
        }
        case `${GET_CROSS_TAB}_PENDING`: {
            return state.updateIn(['error', 2], () => '').update('count', count => count + 1);
        }
        case `${GET_DAILY_RES}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('dailyResponses', fromJS(payload))
                .set('dailyResFetched', true);
        }
        case `${GET_DAILY_RES}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .updateIn(['error', 0], () => payload.error)
                .set('dailyResponses', fromJS({}))
                .set('dailyResFetched', true);
        }
        case `${GET_DEMO_RES}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('demoResFetched', true)
                .updateIn(['demoResponses', payload.demographic], () => payload.demographicData);
        }
        case `${GET_DEMO_RES}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .updateIn(['error', 1], () => payload.error)
                .set('demoResponses', fromJS({}))
                .set('demoResFetched', true);
        }
        case `${GET_CROSS_TAB}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('crossTabData', fromJS(payload))
                .set('crossTabFetched', true);
        }
        case `${GET_CROSS_TAB}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .updateIn(['error', 2], () => payload.error)
                .set('crossTabData', fromJS({}))
                .set('crossTabFetched', true);
        }
        case RELOAD_DATA:
        case UPDATE_FILTERS: {
            return state
                .set('dailyResFetched', false)
                .set('demoResFetched', false)
                .set('crossTabFetched', false)
                .set('demoResponses', fromJS({}));
        }
        case RESET_TAB_DATA: {
            if (payload === 'demoResFetched') {
                return state.set('demoResFetched', false).set('demoResponses', fromJS({}));
            }
            return state.set(payload, false);
        }
        case REMOVE_SAVED_TOKEN: {
            if (!payload) {
                return INITIAL_STATE;
            }
            return state;
        }
        case `${GET_DEMOGRAPHICS}_SUCCESS`: {
            if (!payload.indexToUpdate) {
                const { demographics = [] } = payload;
                const { code = '' } = demographics[0] || {};
                const { code: code2 = '' } = demographics[1] || {};
                return state.set('crossTabsDrop', fromJS([code, code2]));
            }
            return state;
        }
        case `${GET_DEFAULT_SETTINGS}_SUCCESS`: {
            return INITIAL_STATE;
        }
        case SET_DEMO_VALUE: {
            return state.set('dropDownValue', payload);
        }
        case SET_CROSS_TABS_DEMO: {
            return state.set('crossTabsDrop', fromJS(payload));
        }
        default:
            return state;
    }
};

export default reducer;
