import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Feedback from './Feedback';

import {
    getErrorInfo,
    selectFeedback,
    selectFeedbackDetails,
    selectFeedbackDetailsFetched,
    selectFeedbackList,
    selectFeedbackListFetched,
    selectFeedbackOptions,
    selectFeedbackSuccess,
} from '../selector';
import {
    getFeedbackDetails,
    getFeedbackList,
    refreshFeedbackList,
    resetFeedback,
    sendFeedback,
    setFeedbackOptions,
} from '../actions';

const stateToProps = createStructuredSelector({
    feedback: selectFeedback(),
    feedbackSuccess: selectFeedbackSuccess(),
    feedbackList: selectFeedbackList(),
    feedbackListFetched: selectFeedbackListFetched(),
    feedbackDetails: selectFeedbackDetails(),
    feedbackDetailsFetched: selectFeedbackDetailsFetched(),
    errorInfo: getErrorInfo(),
    feedbackOptions: selectFeedbackOptions(),
});

const dispatchToProps = {
    sendFeedback,
    getFeedbackList,
    getFeedbackDetails,
    resetFeedback,
    refreshFeedbackList,
    setFeedbackOptions,
};

export default connect(stateToProps, dispatchToProps)(Feedback);
