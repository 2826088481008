import { all } from 'redux-saga/effects';
import { loginSagas } from '../containers/Login';
import { diagnoseSagas } from '../containers/Diagnose';
import { reportsSagas } from '../containers/Reports';
import { settingsSagas } from '../containers/Settings';
import { userReportsSagas } from '../containers/UserReports';
import { compendiumSagas } from '../containers/ActionCompendium';
import { responseRateSagas } from '../containers/ResponseRate';
// import { analyticsSagas } from '../containers/Analytics';
import analyticsSagas from '../containers/Analytics/sagas';

export default function* rootSaga() {
    yield all([
        ...loginSagas,
        ...diagnoseSagas,
        ...reportsSagas,
        ...settingsSagas,
        ...userReportsSagas,
        ...compendiumSagas,
        ...responseRateSagas,
        ...analyticsSagas,
    ]);
}
