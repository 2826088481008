import { fetchWebApi, postWebApi } from '../../webapis/dashboard';
import endpoints from '../../Endpoints';

export const fetchActionFilterUrl = () => {
    const { apiBasePath, actionFiltersPath } = endpoints;
    const url = `${apiBasePath}${actionFiltersPath}`;
    return fetchWebApi(url).request;
};

export const getCompValueUrl = options => {
    const { apiBasePath, actionFiltersPath } = endpoints;
    const url = `${apiBasePath}${actionFiltersPath}`;
    return postWebApi(url, options).request;
};

export const actionUsabilityUrl = options => {
    const { apiBasePath, actionUsability } = endpoints;
    const url = `${apiBasePath}${actionUsability}`;
    return postWebApi(url, options).request;
};

export const actionVoteUrl = options => {
    const { apiBasePath, actionVote } = endpoints;
    const url = `${apiBasePath}${actionVote}`;
    return postWebApi(url, options).request;
};

export const addCompendiumUrl = options => {
    const { apiBasePath, addCompendium } = endpoints;
    const url = `${apiBasePath}${addCompendium}`;
    return postWebApi(url, options).request;
};
