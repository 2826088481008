import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ActionCompendium from './ActionCompendium';
import compendium from './reducer';
import compendiumSagas from './sagas';

import {
    getActionOptions,
    updateActionList,
    getCompValues,
    updateCompData,
    toggleActionUsability,
    toggleActionVote,
} from './actions';
import {
    selectApiCount,
    selectOptionList,
    selectOptionData,
    selectOptionDataFetched,
    getErrorInfo,
    getCompendiumData,
    getCompendiumDataFetched,
} from './selector';

const stateToProps = createStructuredSelector({
    apiCount: selectApiCount(),
    optionList: selectOptionList(),
    optionData: selectOptionData(),
    optionDataFetched: selectOptionDataFetched(),
    errorInfo: getErrorInfo(),
    compendiumData: getCompendiumData(),
    compDataFetched: getCompendiumDataFetched(),
});

const dispatchToProps = {
    getActionOptions,
    updateActionList,
    getCompValues,
    updateCompData,
    toggleActionUsability,
    toggleActionVote,
};

export { compendiumSagas, compendium };

export default connect(stateToProps, dispatchToProps)(ActionCompendium);
