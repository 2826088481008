import axios from 'axios';
import { postWebApi, fetchWebApi, putWebApi } from '../../webapis/dashboard';
import endpoints from '../../Endpoints';
import AuthStore from '../../common/AuthStore';

export const getReportUrl = options => {
    const { apiBasePath, reportsPath } = endpoints;
    const url = `${apiBasePath}${reportsPath}`;
    return postWebApi(url, options).request;
};

export const getDataCubeUrl = options => {
    const { apiBasePath, dataCubePath } = endpoints;
    const url = `${apiBasePath}${dataCubePath}`;
    return postWebApi(url, options).request;
};

export const exportPathUrl = report_id => {
    const { apiBasePath, exportPath } = endpoints;
    const url = `${apiBasePath}${exportPath}?status=all&page=1&per_page=10&report_number=${report_id}`;
    return fetchWebApi(url).request;
};

export const rawPptPathUrl = export_id => {
    const { apiBasePath, getRawPptPath } = endpoints;
    const url = `${apiBasePath}${getRawPptPath}?export_id=${export_id}`;
    return fetchWebApi(url).request;
};

export const getInclusionDataUrl = options => {
    const { apiBasePath, inclusionDataPath } = endpoints;
    const url = `${apiBasePath}${inclusionDataPath}`;
    return postWebApi(url, options).request;
};

export const getInclusionGapUrl = options => {
    const { apiBasePath, incluisionGapPath } = endpoints;
    const url = `${apiBasePath}${incluisionGapPath}`;
    return postWebApi(url, options).request;
};

export const getInclusionResGapUrl = options => {
    const { apiBasePath, inclusionResGapPath } = endpoints;
    const url = `${apiBasePath}${inclusionResGapPath}`;
    return postWebApi(url, options).request;
};

export const getAllGapUrl = options => {
    const { apiBasePath, allGapPath } = endpoints;
    const url = `${apiBasePath}${allGapPath}`;
    return postWebApi(url, options).request;
};

export const getPendingReportsUrl = options => {
    const { apiBasePath, getExportStatusPath } = endpoints;
    const url = `${apiBasePath}${getExportStatusPath}`;
    return postWebApi(url, options).request;
};

export const getRespondentsUrl = options => {
    const { apiBasePath, respondentsPath } = endpoints;
    const url = `${apiBasePath}${respondentsPath}`;
    return postWebApi(url, options).request;
};

export const getMappedProjectUrl = options => {
    const { apiBasePath, inclusionResurveyMapping } = endpoints;
    const url = `${apiBasePath}${inclusionResurveyMapping}`;
    return postWebApi(url, options).request;
};

export const getMappedDemo = options => {
    const { ohid = '', prevOhid = '' } = options;
    const { apiBasePath, mappedDemo } = endpoints;
    const url = `${apiBasePath}${mappedDemo}?ohid=${ohid}&previous_ohid=${prevOhid}`;
    return fetchWebApi(url).request;
};

export async function getMappingExcel(ohid, previous_ohid) {
    const { accessToken: Authorization = '' } = AuthStore;
    const { apiBasePath, getResurveyMappingDownload } = endpoints;

    axios({
        url: `${apiBasePath}${getResurveyMappingDownload}?ohid=${ohid}&previous_ohid=${previous_ohid}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization,
        },
        responseType: 'blob',
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx');
        document.body.appendChild(link);
        link.click();
    });
}

export const putReportPathUrl = options => {
    const { apiBasePath, reportsPath } = endpoints;
    const url = `${apiBasePath}${reportsPath}`;
    return putWebApi(url, options).request;
};
