import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import c from 'classnames';
import { TextField, Button } from '@material-ui/core';
import { ArrowBack, Close, Delete } from '@material-ui/icons';

import Loader from '../../../components/Loader';
import ConfirmationModal from '../../../components/ConfirmationModal';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import { getStaticText } from '../../../utils/constants';
import { getToolDraggedItems, removeUnderscore } from '../../../utils/functions';
import getStaticTextDiagnose from '../constants';
import DnDBg from '../assets/DnDBg.svg';
import './index.scss';

function Toolbox({
    scoreData,
    createTheme,
    draggedItems,
    toggleTheme,
    updateDragged,
    getToolData,
    toolData,
    toolDataFetched,
    toolDataLoading,
    createThemeData,
    deleteTheme,
    showCreateTheme,
    setTheme,
    error,
    defaultSettings,
}) {
    const { quartileColors = [], lang } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { THEME_PARAMS } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        TOOLBOX,
        CREATE_TEXT,
        NEW_THEME,
        BUILD_PAGE,
        THEME_NAME,
        ADD_TEXT,
        OUT_PRT,
        DESC,
        NOTES,
        THEME_PLACE,
        NOTES_PLACE,
        TEXT_PLACE,
        DRAG_DROP,
        OUTCOMES_TEXT,
        SAVED_THEMES,
        NO_PATTERN,
        CREATE_THEME_TEXT,
        DELETE_PATTERN,
        DELETE_MSG,
        ARE_YOU_SURE,
        DELETE,
        CANCEL,
    } = SITE_TEXT || {};
    const [newTheme, updateTheme] = useState(THEME_PARAMS);
    const [activeTheme, updateActiveTheme] = useState(null);
    const [openConfirm, toggleConfirmModal] = useState(false);
    const [patternToDelete, updateDelete] = useState({});
    const { theme_name, description, notes } = newTheme;

    useEffect(() => {
        if (!toolDataFetched && !toolData.length) {
            getToolData();
        }
        // eslint-disable-next-line
    }, []);

    const handleData = ({ name, value }) =>
        updateTheme({
            ...newTheme,
            [name]: value,
        });

    const setBackOldView = () => {
        updateTheme(THEME_PARAMS);
        toggleTheme(false);
        updateDragged([]);
    };

    const removePractice = key => {
        const index = draggedItems.findIndex(dragged => dragged === key);
        updateDragged([...draggedItems.slice(0, index), ...draggedItems.slice(index + 1)]);
    };

    const sendThemeData = () => {
        const practices = draggedItems.join(',');
        createThemeData({
            ...newTheme,
            practices,
        });
        setBackOldView();
    };

    const createNewTheme = () => {
        updateActiveTheme(null);
        toggleTheme(true);
        setTheme('');
    };

    const setCurrentTheme = (index, practiceToUse) => {
        if (activeTheme !== index) {
            updateActiveTheme(index);
            setTheme(practiceToUse);
        }
    };

    const removeTheme = (index, id, event) => {
        event.stopPropagation();
        updateDelete({ index, id });
        toggleConfirmModal(true);
    };

    const closeConfirmModal = () => {
        toggleConfirmModal(false);
        updateDelete({});
    };

    const makeApiCall = () => {
        const { index, id } = patternToDelete;
        if (activeTheme === index) {
            updateActiveTheme(null);
            setTheme('');
        }
        deleteTheme({ id }, index);
        toggleConfirmModal(false);
    };

    const { qbyq = {} } = scoreData;
    const { records = {} } = qbyq;
    const { ohi_score, ...dataToIterate } = records;
    const practicesToDisplay = getToolDraggedItems(dataToIterate, draggedItems);

    const activeCreate = theme_name.trim() && description.trim() && draggedItems.length > 1;

    return (
        <Fragment>
            {toolDataLoading ? <Loader position="absolute" showBackground background="transparent" /> : null}
            <h2>{TOOLBOX}</h2>
            {error && <ErrorToast message={error} />}
            {!createTheme ? (
                <div className="savedTheme">
                    <h3>{SAVED_THEMES}</h3>
                    {toolDataFetched && !toolData.length ? (
                        <div className="noTheme">
                            {NO_PATTERN}{' '}
                            {showCreateTheme && (
                                <span data-testid="createTheme" onClick={() => toggleTheme(true)}>
                                    {CREATE_THEME_TEXT}
                                </span>
                            )}
                        </div>
                    ) : null}

                    {toolData.map(({ theme_name: name, practices, id }, themeIndex) => (
                        <section
                            key={id}
                            className={c({ active: themeIndex === activeTheme })}
                            onClick={() => setCurrentTheme(themeIndex, practices)}
                            data-testid={`toolSection${themeIndex}`}
                        >
                            <h4 className="ellipsisPro">
                                {name}
                                <span
                                    data-testid={`deleteSection${themeIndex}`}
                                    onClick={e => removeTheme(themeIndex, id, e)}
                                >
                                    <Delete />
                                </span>
                            </h4>
                            <div className="prcList">
                                {practices.split(',').map(prc => (
                                    <span key={prc}>{removeUnderscore(prc)}</span>
                                ))}
                            </div>
                        </section>
                    ))}
                </div>
            ) : (
                <div className="newTheme">
                    <h3 onClick={setBackOldView}>
                        <ArrowBack />
                        {NEW_THEME}
                    </h3>

                    <div className="label">
                        {ADD_TEXT} {OUT_PRT}
                    </div>

                    <Droppable droppableId="toolBox">
                        {provided => (
                            <section ref={provided.innerRef} {...provided.droppableProps} className="toolDrop clearfix">
                                {practicesToDisplay.length ? (
                                    <ul>
                                        {practicesToDisplay.map(childSet => {
                                            const { display_name = '', score = '', quartile = '4', dataKey } = childSet;
                                            const { background, color, border: borderColor } =
                                                quartileColors[quartile] || {};
                                            return (
                                                <li
                                                    key={display_name}
                                                    style={{ background, color, borderColor }}
                                                    className="ellipsisPro"
                                                >
                                                    {display_name}
                                                    <span className="score">{score}</span>
                                                    <span className="delete" onClick={() => removePractice(dataKey)}>
                                                        <Close />
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                ) : (
                                    <div className="dropHere">
                                        <img src={DnDBg} alt="" />
                                        <div>
                                            {DRAG_DROP} <br />
                                            {OUTCOMES_TEXT}
                                        </div>
                                    </div>
                                )}
                            </section>
                        )}
                    </Droppable>

                    <div className="label">{THEME_NAME}</div>
                    <TextField
                        fullWidth
                        name="theme_name"
                        value={theme_name}
                        onChange={({ target }) => handleData(target)}
                        placeholder={THEME_PLACE}
                        inputProps={{
                            maxLength: 100,
                            'data-testid': 'theme_name',
                        }}
                    />

                    <div className="label">
                        {ADD_TEXT} {DESC}
                    </div>
                    <TextField
                        fullWidth
                        multiline
                        rows={3}
                        inputProps={{
                            maxLength: 120,
                            'data-testid': 'description',
                        }}
                        name="description"
                        value={description}
                        onChange={({ target }) => handleData(target)}
                        placeholder={TEXT_PLACE}
                    />

                    <div className="label">{NOTES}</div>
                    <TextField
                        fullWidth
                        multiline
                        rows={3}
                        inputProps={{
                            maxLength: 120,
                            'data-testid': 'notes',
                        }}
                        name="notes"
                        value={notes}
                        onChange={({ target }) => handleData(target)}
                        placeholder={NOTES_PLACE}
                    />
                </div>
            )}
            <div className="buttonContent clearfix">
                {createTheme ? (
                    <Button
                        data-testid="sendThemeData"
                        onClick={sendThemeData}
                        classes={{ root: c('createTheme', { activeCreate }) }}
                    >
                        {CREATE_TEXT}
                    </Button>
                ) : (
                    <Fragment>
                        {showCreateTheme && (
                            <Button data-testid="createNewTheme" onClick={createNewTheme}>
                                {CREATE_TEXT} {NEW_THEME}
                            </Button>
                        )}
                        <Button classes={{ root: 'buildTheme' }}>{BUILD_PAGE}</Button>
                    </Fragment>
                )}
            </div>
            {openConfirm && (
                <ConfirmationModal>
                    <h2>{DELETE_PATTERN}</h2>
                    <div className="content">{DELETE_MSG}</div>
                    <div className="content">{ARE_YOU_SURE}</div>
                    <div className="buttonContent">
                        <span data-testid="closeBtn" onClick={closeConfirmModal}>
                            {CANCEL}
                        </span>
                        <span data-testid="delBtn" onClick={makeApiCall}>
                            {DELETE}
                        </span>
                    </div>
                </ConfirmationModal>
            )}
        </Fragment>
    );
}

Toolbox.propTypes = {
    error: PropTypes.string.isRequired,
    scoreData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    createTheme: PropTypes.bool.isRequired,
    showCreateTheme: PropTypes.bool.isRequired,
    toolDataFetched: PropTypes.bool.isRequired,
    toolDataLoading: PropTypes.bool.isRequired,
    draggedItems: PropTypes.array.isRequired,
    toolData: PropTypes.array.isRequired,
    setTheme: PropTypes.func.isRequired,
    toggleTheme: PropTypes.func.isRequired,
    updateDragged: PropTypes.func.isRequired,
    getToolData: PropTypes.func.isRequired,
    deleteTheme: PropTypes.func.isRequired,
    createThemeData: PropTypes.func.isRequired,
};

export default Toolbox;
