import React, { Fragment, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { cloneDeep } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import AuthStore from '../../../common/AuthStore';
import Scoreboard from './Scoreboard';
import ReSurveyReport from './ReSurveyReport';
import ReSurveyDetailedView from './ReSurveyDetailedView';
import BUReport from './BUReport';
import BUDetailedReport from './BUDetailedReport';
import BarChartModal from '../BarChartModal';
import FilterBar from '../../../components/FilterBar';
import {
    updateFilterParams,
    getBenchmarkString,
    getFilterArray,
    getBenchmarkName,
    getFiltersAppliedText,
    getOutputFilter,
} from '../../../utils/functions';
import { getStaticText } from '../../../utils/constants';
import getStaticTextDiagnose from '../constants';
import './index.scss';
import FocusArea from '../assets/FocusArea.svg';
import viewIcon from '../../Reports/assets/viewIcon.svg';
import TargetSetting from './TargetSetting';

function MainBoard({
    scoreData,
    scoreDataFetched,
    getScoreData,
    toggleFilterView,
    apiParams,
    apiLoadingCount,
    getProjectsData,
    projects,
    projectsFetched,
    getReSurveyData,
    reSurveyData,
    errorInfo,
    getDemographics,
    getBenchmarks,
    filters,
    activeResetFilter,
    updateFilters,
    reSurveyDataFetched,
    resetReSurveyDataFetched,
    buData,
    buDataFetched,
    getBuData,
    resetBuDataFetched,
    updateBuFilters,
    buFilters,
    getPptData,
    isDragDisabled,
    toggleCreateTheme,
    currentTheme,
    changeCurrentPage,
    setPracticeDisplay,
    defaultSettings,
    cohesionScore,
    pulseSurvey,
    appliedFiltersInfo,
    getClientEngagementData,
    clientEngagement,
    clientEngagementFetched,
    getReport,
    getRawPptData,
    reportError,
    singlePastReport,
    getSinglePastReport,
    cohesionDemographic,
    localPractice,
    getTargetScoreData,
    targetScoreDataFetched,
    targetScoreData,
    targetBenchmarks,
    targetBm,
    updateTargetSettingBm,
}) {
    const [currentView, setReport] = useState(0);
    const [selectedIds, updateIds] = useState([]);
    const [showBarChart, updateModal] = useState(false);
    const [recipe, setRecipe] = useState({});
    const [highScoreKey, setHighScore] = useState('');
    const [highScoreIndex, setHighScoreIndex] = useState(null);
    const [reSurveyFilter, updateFilterView] = useState(false);
    const [sizeToUse, updateSize] = useState([0, 0]);
    const [surveyRespondents, updateSurveyRes] = useState([0, 0]);
    const [surveys, updateSurveys] = useState([0, 0]);
    const {
        benchmark = [],
        report_name_or_client_name: rName = '',
        resurvey_settings = '',
        lang,
        ohid: currentOhid,
        year: bmYear = '',
    } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { QUARTILE_RESURVEY, PERCENTILE } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { benchmarks = [] } = filters[0] || {};
    const { ohid: oldId, year: oldYear, threshold, report_type = '' } = apiParams;
    const projectInFilter = [`${oldId} - ${oldYear}`];
    const isPercentile = report_type === PERCENTILE;
    const { BU_PROJECTS, VIEW_LESS, VIEW_MORE, BENCHMARK, DEMOGRAPHIC, NUMBER_OF_SURVEYS, YEAR } = SITE_TEXT || {};
    const [showAll, setShowAll] = useState(false);
    const [showCompAll, setShowCompAll] = useState(false);
    const [buFiltersUpdate, setBuFiltersUpdate] = useState([]);

    const LightTooltip = withStyles(theme => ({
        arrow: {
            color: theme.palette.common.black,
        },
        tooltip: {
            backgroundColor: '#333333',
            fontSize: 14,
            fontStyle: 'italic',
            fontWeight: 400,
        },
    }))(Tooltip);

    const projectsInUse = useMemo(() => {
        return projects.filter(({ ohid }) => ohid !== currentOhid);
        // eslint-disable-next-line
    }, [currentOhid, projects]);

    selectedIds.forEach(addedId => {
        const { ohid, year } = projectsInUse[addedId];
        projectInFilter.push(`${ohid} - ${year}`);
    });

    const { year: prevYear } = projectsInUse[selectedIds[0]] || {};

    const getResurveyParams = newFilters => {
        const selectedOhiId = selectedIds.map(indexId => projectsInUse[indexId].ohid);
        const ohid = `${oldId},${selectedOhiId.join(',')}`;
        const compare_ids = selectedOhiId.map(selectId => `${oldId}-${selectId}`);

        const filtersArray = [];
        const benchmarksArray = [];
        newFilters.forEach(({ benchmarks: newB = [], demographics: newD = [] }, indexToUse) => {
            let benchMarkYear = oldYear;
            let preString = '';
            let endString = '';

            if (indexToUse) {
                const projectIndex = selectedIds[indexToUse - 1];
                if (isNaN(Number(projectIndex))) return;
                const { year } = projectsInUse[projectIndex];
                benchMarkYear = year;
                preString = indexToUse === 1 ? '||' : '||||';
                endString = indexToUse === 1 && selectedIds.length === 2 ? '||' : '';
            } else {
                endString = selectedIds.length === 2 ? '||||' : '||';
            }
            const filtersObtained = getFilterArray(newD, preString, endString);
            filtersObtained.forEach(result => {
                filtersArray.push(result);
            });
            if (newB.length) {
                benchmarksArray.push(`${getBenchmarkString(newB)}@${benchMarkYear}`);
            }
        });
        return {
            ohid,
            filters: filtersArray,
            benchmark_str: benchmarksArray.join(','),
            compare_ids,
            report_type,
            comparison_type: resurvey_settings === QUARTILE_RESURVEY ? 2 : 1,
            lang: parseInt(lang, 10),
        };
    };

    const getData = newFilters => {
        const RESURVEY_PARAMS = getResurveyParams(newFilters);
        getReSurveyData(RESURVEY_PARAMS);
    };

    const fetchBuData = newFilters => {
        const filtersArray = [];
        const { demographics = [], benchmarks: oldBench = [] } = newFilters[0];
        const { demographics: buDemo = [] } = newFilters[1] || {};
        demographics.forEach(({ options = [], code: parentCode = '' }, indexInDemo) => {
            const optionsCode = options
                .filter(({ isSelected }) => isSelected)
                .reduce((pre, { code }) => [...pre, code], []);
            const { options: buOptions = [] } = buDemo[indexInDemo] || {};
            const buOptionsCode = buOptions
                .filter(({ isSelected }) => isSelected)
                .reduce((pre, { code }) => [...pre, code], []);
            const preStr = optionsCode.length ? `${parentCode}:${optionsCode.join(',')}` : '';
            const endStr = buOptionsCode.length ? `${parentCode}:${buOptionsCode.join(',')}` : '';
            if (preStr || endStr) {
                filtersArray.push(`${preStr}||${endStr}`);
            }
        });
        getBuData({
            ohid: `${oldId},${oldId}`,
            lang: parseInt(lang, 10),
            year: oldYear,
            threshold,
            filters: filtersArray,
            benchmark_str: getBenchmarkString(oldBench),
            report_type,
        });
    };

    useEffect(() => {
        if (!projectsFetched) {
            const { report_id = '' } = AuthStore;
            getProjectsData({ report_id: parseInt(report_id, 10) });
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (currentView >= 3 && buDataFetched && Object.keys(buData).length) {
            const { sizes = {} } = buData;
            const { nSize = [], n_respondents = 0, n_survey = 0 } = sizes;
            updateSize(nSize);
            updateSurveyRes([n_respondents, 0]);
            updateSurveys([n_survey, 0]);
        }
        // eslint-disable-next-line
    }, [buDataFetched, buData]);

    useEffect(() => {
        if (currentView < 3 && reSurveyDataFetched && Object.keys(reSurveyData).length) {
            const { sizes = {} } = reSurveyData;
            const { nSize = [], nRespondents = [], nSurvey = [] } = sizes;
            updateSize(nSize);
            updateSurveyRes(nSurvey);
            updateSurveys(nRespondents);
        }
        // eslint-disable-next-line
    }, [reSurveyDataFetched, reSurveyData]);

    useEffect(() => {
        if (!reSurveyDataFetched && !apiLoadingCount) {
            const allDataReceived = filters.filter(({ benchmarks: cBen, demographics: cDen }) => cBen && cDen).length;
            if (allDataReceived === filters.length) {
                getData(filters);
            }
        }
        if (!buDataFetched && !apiLoadingCount) {
            fetchBuData([filters[0], { demographics: buFilters }]);
            setBuFiltersUpdate([filters[0], { demographics: buFilters }]);
        }
        // eslint-disable-next-line
    }, [reSurveyDataFetched, buDataFetched, buFilters, filters, apiLoadingCount]);

    const setFilters = (newFilters, filtersApplied) => {
        updateFilterView(false);
        const { demographics = [], benchmarks: oldBench = [] } = newFilters[0];
        const updatedParams = updateFilterParams(demographics, oldBench);
        const filtersToSend = currentView < 3 ? newFilters : [newFilters[0]];
        updateFilters({ filters: filtersToSend, filtersApplied, apiParams: { ...apiParams, ...updatedParams } });
        if (currentView < 3) {
            resetReSurveyDataFetched(false);
        }
        if (currentView >= 3) {
            updateIds([]);
            const { demographics: newBuFilters = [] } = newFilters[1];
            updateBuFilters(newBuFilters);
            resetBuDataFetched(false);
        }
    };

    // const setFiltersForTargetSetting = (newFilters, filtersApplied) => {
    //     updateFilterView(false);
    //     const { demographics = [], benchmarks: oldBench = [] } = newFilters[0];
    //     const updatedParams = updateFilterParams(demographics, oldBench);
    //     updateFilters({ filters: newFilters, filtersApplied, apiParams: { ...apiParams, ...updatedParams } });
    // };

    const callReSurveyApi = (currentIds, indexToUse = 0) => {
        if (JSON.stringify(selectedIds) !== JSON.stringify(currentIds)) {
            updateIds(currentIds);
            const { ohid: newId, year: newYear } = projectsInUse[currentIds[indexToUse]];
            getDemographics({ ohid: newId, lang: parseInt(lang, 10) }, indexToUse + 1);
            getBenchmarks({ ohid: newId, year: newYear, lang }, indexToUse + 1, benchmark);
            AuthStore[`resurveyName${indexToUse + 1}`] = `${newId} (${newYear})`;
            resetReSurveyDataFetched(false);
        }
    };

    const setChartData = (currentRecipe, currentScoreKey, currentScoreIndex = null) => {
        setRecipe(currentRecipe);
        setHighScore(currentScoreKey);
        setHighScoreIndex(currentScoreIndex);
        updateModal(true);
    };

    const maxItemsToShow = 6;

    const handleAllFilterData = (
        parKey,
        childKey,
        demographic,
        filtersHere,
        updateRedux = true,
        activeFilter = true
    ) => {
        const newDemographic = cloneDeep(demographic);
        const newParDemoIndex = newDemographic.findIndex(({ code }) => code === parKey);
        const selectedItemIndex = newDemographic[newParDemoIndex].options.findIndex(({ code }) => code === childKey);
        newDemographic[newParDemoIndex].options[selectedItemIndex].isSelected = false;
        const { benchmarks: filBenchmark = [] } = filtersHere[0];
        const allList = { benchmarks: filBenchmark, demographics: newDemographic };
        const newList = [...filters.slice(0, 0), allList, ...filters.slice(0 + 1)];
        if (updateRedux === true) {
            setFilters(newList, true, activeFilter, 0);
        }
    };

    const handleAllCompFilter = (
        parKey,
        childKey,
        demographic,
        filtersHere,
        updateRedux = true,
        activeFilter = true
    ) => {
        const newDemographic = cloneDeep(demographic);
        const newParDemoIndex = newDemographic.findIndex(({ code }) => code === parKey);
        const selectedItemIndex = newDemographic[newParDemoIndex].options.findIndex(({ code }) => code === childKey);
        newDemographic[newParDemoIndex].options[selectedItemIndex].isSelected = false;
        const { benchmarks: compBenchmark = [] } = filtersHere[0];
        const allList = { benchmarks: compBenchmark, demographics: newDemographic };
        const newList = [...filters.slice(0, 1), allList];
        if (updateRedux === true) {
            setFilters(newList, true, activeFilter, 1);
        }
    };

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const toggleCompShowAll = () => {
        setShowCompAll(!showCompAll);
    };
    const practiceResurveyName1 = AuthStore.resurveyFilter1 || 'Organization';
    const practiceResurveyName2 = AuthStore.resurveyFilter2 || 'Comparison survey';

    const appliedResurveyFiltersInfo = (years = [oldYear, prevYear]) => {
        const { benchmarks: bm = [], demographics = [] } = filters[0] || {};
        const { filters: filterApplied = [] } = apiParams;
        const label = getBenchmarkName(bm);
        const { benchmarks: compBenchmarks = [], demographics: compDemographic = [] } = filters[1] || {};
        const { filters: compFilter = [] } = updateFilterParams(compDemographic, compBenchmarks) || {};

        const arrItem = getFiltersAppliedText(filterApplied, demographics);
        const newItem = getOutputFilter(filterApplied, demographics);
        const arrItemComp = getFiltersAppliedText(compFilter, compDemographic);
        const newItemComp = getOutputFilter(compFilter, compDemographic);
        const sizeResurvey = sizeToUse || [];

        return (
            <div className="filterAppliedContent">
                {arrItem.length > 0 && newItem !== null && (
                    <div className="filterDemo">
                        {newItem !== null && (
                            <div className="heading">{practiceResurveyName1 + ` (${years[0]}) : `}</div>
                        )}
                        <div className="nsizeContent">
                            <div>n : </div>
                            <div>{sizeResurvey[0]}</div>
                        </div>
                        {newItem !== null &&
                            newItem
                                .slice(0, showAll ? newItem.length : maxItemsToShow)
                                .map(({ parentK, childKey, parentCode, childCode }) => {
                                    return (
                                        <div className="filterContent">
                                            <LightTooltip title={`${parentK} - ${childKey}`} arrow>
                                                <div
                                                    key={parentCode}
                                                    className="filterHighlight filterKey"
                                                >{`${childKey}`}</div>
                                            </LightTooltip>
                                            <img
                                                src={FocusArea}
                                                alt=""
                                                className="filterImg"
                                                onClick={() => {
                                                    handleAllFilterData(parentCode, childCode, demographics, filters);
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                        {(newItem.length >= maxItemsToShow || showAll) && (
                            <div onClick={toggleShowAll}>
                                {showAll ? (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_LESS}</div>
                                    </div>
                                ) : (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_MORE}</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}

                {arrItemComp.length > 0 && newItemComp !== null && (
                    <div className="filterDemo">
                        {newItemComp !== null && (
                            <div className="heading">{practiceResurveyName2 + ` (${years[1]}) : `}</div>
                        )}
                        <div className="nsizeContent">
                            <div>n : </div>
                            <div>{sizeResurvey[1]}</div>
                        </div>
                        {newItemComp !== null &&
                            newItemComp
                                .slice(0, showCompAll ? newItemComp.length : maxItemsToShow)
                                .map(({ parentK, childKey, parentCode, childCode }) => {
                                    return (
                                        <div className="filterContent">
                                            <LightTooltip title={`${parentK} - ${childKey}`} arrow>
                                                <div
                                                    key={parentCode}
                                                    className="filterHighlight filterKey"
                                                >{`${childKey}`}</div>
                                            </LightTooltip>
                                            <img
                                                src={FocusArea}
                                                alt=""
                                                className="filterImg"
                                                onClick={() => {
                                                    handleAllCompFilter(
                                                        parentCode,
                                                        childCode,
                                                        compDemographic,
                                                        filters
                                                    );
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                        {(newItemComp.length >= maxItemsToShow || showCompAll) && (
                            <div onClick={toggleCompShowAll}>
                                {showCompAll ? (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_LESS}</div>
                                    </div>
                                ) : (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_MORE}</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}

                <div className="filterDemo">
                    <div className="heading">{BENCHMARK + `:`}</div>
                    <div className="nsizeContent">
                        <div>{YEAR} : </div>
                        <div>{oldYear}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>n :</div>
                        <div>{surveyRespondents[0].toLocaleString('en-US')}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>{NUMBER_OF_SURVEYS} :</div>
                        <div>{surveys[0].toLocaleString('en-US')}</div>
                    </div>
                    <div className="filterContent">
                        <div className="filterHighlight filterKey filterLab">{label}</div>
                    </div>

                    <div className="heading">{BENCHMARK + `:`}</div>
                    <div className="nsizeContent">
                        <div>{YEAR} : </div>
                        <div>{prevYear}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>n :</div>
                        <div>{surveyRespondents[1].toLocaleString('en-US')}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>{NUMBER_OF_SURVEYS} :</div>
                        <div>{surveys[1].toLocaleString('en-US')}</div>
                    </div>
                    <div className="filterContent">
                        <div className="filterHighlight filterKey filterLab">{getBenchmarkName(compBenchmarks)}</div>
                    </div>
                </div>
            </div>
        );
    };

    const handleAllFilterBuData = (
        parKey,
        childKey,
        demographic,
        filtersHere,
        updateRedux = true,
        activeFilter = true
    ) => {
        const newDemographic = cloneDeep(demographic);
        const newParDemoIndex = newDemographic.findIndex(({ code }) => code === parKey);
        const selectedItemIndex = newDemographic[newParDemoIndex].options.findIndex(({ code }) => code === childKey);
        newDemographic[newParDemoIndex].options[selectedItemIndex].isSelected = false;
        const { benchmarks: buBenchmark = [] } = filtersHere[0];
        const allList = { demographics: newDemographic, benchmarks: buBenchmark };
        const newList = [...buFiltersUpdate.slice(0, 0), allList, ...buFiltersUpdate.slice(0 + 1)];
        if (updateRedux === true) {
            setFilters(newList, true, activeFilter, 0);
        }
    };

    const handleAllCompBuFilter = (
        parKey,
        childKey,
        compDemographic,
        buFilterHere,
        updateRedux = true,
        activeFilter = true
    ) => {
        const newDemographic = cloneDeep(compDemographic);
        const newParDemoIndex = newDemographic.findIndex(({ code }) => code === parKey);
        const selectedItemIndex = newDemographic[newParDemoIndex].options.findIndex(({ code }) => code === childKey);
        newDemographic[newParDemoIndex].options[selectedItemIndex].isSelected = false;
        const { benchmarks: compBenchmark = [] } = buFilterHere[1];
        const allList = { demographics: newDemographic, compBenchmark };
        const newList = [...buFiltersUpdate.slice(0, 1), allList];
        if (updateRedux === true) {
            setFilters(newList, true, activeFilter, 1);
        }
    };

    const appliedBuFiltersInfo = () => {
        const { demographics: budemographics = [], benchmarks: compBenchmarks = [] } = buFiltersUpdate[1] || {};
        const compDemographic = budemographics || [];
        // const { benchmarks: bm = [], demographics = [] } = buFiltersUpdate[0] || {};
        const { benchmarks: bm = [], demographics = [] } = filters[0] || {};

        const { filters: filterApplied = [] } = apiParams;
        const label = getBenchmarkName(bm);
        const { filters: compFilter = [] } = updateFilterParams(compDemographic, compBenchmarks) || {};

        const arrItem = getFiltersAppliedText(filterApplied, demographics);
        const newItem = getOutputFilter(filterApplied, demographics);
        const arrItemComp = getFiltersAppliedText(compFilter, compDemographic);
        const newItemComp = getOutputFilter(compFilter, compDemographic);

        const sizeBu = sizeToUse || [];

        const practiceBuName = AuthStore.practiceBU || 'Bu';
        const practiceReportName = AuthStore.practiceReportBU || 'Organization';

        return (
            <div className="filterAppliedContent" style={{ marginBottom: '0' }}>
                {arrItem.length > 0 && newItem !== null && (
                    <div className="filterDemo">
                        {newItem !== null && (
                            <div className="heading">
                                {practiceReportName} {DEMOGRAPHIC}:{' '}
                            </div>
                        )}
                        {newItem !== null && (
                            <div className="nsizeContent">
                                <div>n : </div>
                                <div>{sizeBu[0]}</div>
                            </div>
                        )}
                        {newItem !== null &&
                            newItem
                                .slice(0, showAll ? newItem.length : maxItemsToShow)
                                .map(({ parentK, childKey, parentCode, childCode }) => {
                                    return (
                                        <div className="filterContent">
                                            <LightTooltip title={`${parentK} - ${childKey}`} arrow>
                                                <div
                                                    key={parentCode}
                                                    className="filterHighlight filterKey"
                                                >{`${childKey}`}</div>
                                            </LightTooltip>
                                            <img
                                                src={FocusArea}
                                                alt=""
                                                className="filterImg"
                                                onClick={() => {
                                                    handleAllFilterBuData(
                                                        parentCode,
                                                        childCode,
                                                        demographics,
                                                        buFiltersUpdate
                                                    );
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                        {(newItem.length >= maxItemsToShow || showAll) && (
                            <div onClick={toggleShowAll}>
                                {showAll ? (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_LESS}</div>
                                    </div>
                                ) : (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_MORE}</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}

                {arrItemComp.length > 0 && newItemComp !== null && (
                    <div className="filterDemo">
                        {newItemComp !== null && (
                            <div className="heading">
                                {practiceBuName} {DEMOGRAPHIC}:{' '}
                            </div>
                        )}
                        {newItemComp !== null && (
                            <div className="nsizeContent">
                                <div>n : </div>
                                <div>{sizeBu[1]}</div>
                            </div>
                        )}
                        {newItemComp !== null &&
                            newItemComp
                                .slice(0, showCompAll ? newItemComp.length : maxItemsToShow)
                                .map(({ parentK, childKey, parentCode, childCode }) => {
                                    return (
                                        <div className="filterContent">
                                            <LightTooltip title={`${parentK} - ${childKey}`} arrow>
                                                <div
                                                    key={parentCode}
                                                    className="filterHighlight filterKey"
                                                >{`${childKey}`}</div>
                                            </LightTooltip>
                                            <img
                                                src={FocusArea}
                                                alt=""
                                                className="filterImg"
                                                onClick={() => {
                                                    handleAllCompBuFilter(
                                                        parentCode,
                                                        childCode,
                                                        compDemographic,
                                                        buFiltersUpdate
                                                    );
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                        {(newItemComp.length >= maxItemsToShow || showCompAll) && (
                            <div onClick={toggleCompShowAll}>
                                {showCompAll ? (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_LESS}</div>
                                    </div>
                                ) : (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_MORE}</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                <div className="filterDemo">
                    <div className="heading">{BENCHMARK}: </div>
                    <div className="nsizeContent">
                        <div>{YEAR} :</div>
                        <div>{bmYear}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>n :</div>
                        <div>{surveyRespondents[0].toLocaleString('en-US')}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>{NUMBER_OF_SURVEYS} :</div>
                        <div>{surveys[0].toLocaleString('en-US')}</div>
                    </div>
                    <div className="filterContent">
                        <div className="filterHighlight filterKey filterLab">{label}</div>
                    </div>
                </div>
            </div>
        );
    };

    const getPage = () => {
        let page;
        switch (currentView) {
            case 0:
            default:
                page = (
                    <Scoreboard
                        cohesionScore={cohesionScore}
                        cohesionDemographic={cohesionDemographic}
                        activeResetFilter={activeResetFilter}
                        projects={projectsInUse}
                        scoreData={scoreData}
                        scoreDataFetched={scoreDataFetched}
                        getScoreData={getScoreData}
                        toggleFilterView={toggleFilterView}
                        apiParams={apiParams}
                        apiLoadingCount={apiLoadingCount}
                        setReport={setReport}
                        resetBuDataFetched={resetBuDataFetched}
                        callReSurveyApi={callReSurveyApi}
                        setChartData={setChartData}
                        selectedIds={selectedIds}
                        projectsFetched={projectsFetched}
                        getPptData={getPptData}
                        errorInfo={errorInfo}
                        filters={filters}
                        isDragDisabled={isDragDisabled}
                        toggleCreateTheme={toggleCreateTheme}
                        currentTheme={currentTheme}
                        defaultSettings={defaultSettings}
                        pulseSurvey={pulseSurvey}
                        appliedFiltersInfo={appliedFiltersInfo}
                        getClientEngagementData={getClientEngagementData}
                        clientEngagement={clientEngagement}
                        clientEngagementFetched={clientEngagementFetched}
                        getReport={getReport}
                        getRawPptData={getRawPptData}
                        reportError={reportError}
                        singlePastReport={singlePastReport}
                        getSinglePastReport={getSinglePastReport}
                        localPractice={localPractice}
                        targetBenchmarks={targetBenchmarks}
                    />
                );
                break;
            case 1:
                page = (
                    <ReSurveyReport
                        cohesionScore={cohesionScore}
                        activeResetFilter={activeResetFilter}
                        scoreData={reSurveyData}
                        setReport={setReport}
                        setChartData={setChartData}
                        errorInfo={errorInfo}
                        updateFilterView={updateFilterView}
                        currentTheme={currentTheme}
                        defaultSettings={defaultSettings}
                        getPptData={getPptData}
                        years={[oldYear, prevYear]}
                        pulseSurvey={pulseSurvey}
                        isPercentile={isPercentile}
                        appliedFiltersInfo={appliedResurveyFiltersInfo}
                        apiParams={apiParams}
                        getReport={getReport}
                        getRawPptData={getRawPptData}
                        reportError={reportError}
                        getResurveyParams={getResurveyParams}
                        filters={filters}
                        getSinglePastReport={getSinglePastReport}
                        singlePastReport={singlePastReport}
                        localPractice={localPractice}
                    />
                );
                break;
            case 2:
                page = (
                    <ReSurveyDetailedView
                        activeResetFilter={activeResetFilter}
                        projects={projectsInUse}
                        callReSurveyApi={callReSurveyApi}
                        scoreData={reSurveyData}
                        setReport={setReport}
                        setChartData={setChartData}
                        selectedIds={selectedIds}
                        errorInfo={errorInfo}
                        updateFilterView={updateFilterView}
                        defaultSettings={defaultSettings}
                        getPptData={getPptData}
                        pulseSurvey={pulseSurvey}
                        isPercentile={isPercentile}
                        appliedFiltersInfo={appliedResurveyFiltersInfo}
                        apiParams={apiParams}
                        getReport={getReport}
                        getRawPptData={getRawPptData}
                        reportError={reportError}
                        getResurveyParams={getResurveyParams}
                        filters={filters}
                        getSinglePastReport={getSinglePastReport}
                        singlePastReport={singlePastReport}
                        years={[oldYear, prevYear]}
                        localPractice={localPractice}
                    />
                );
                break;
            case 3:
                page = (
                    <BUReport
                        activeResetFilter={activeResetFilter}
                        scoreData={buData}
                        setReport={setReport}
                        errorInfo={errorInfo}
                        updateFilterView={updateFilterView}
                        currentTheme={currentTheme}
                        benchmarks={benchmarks}
                        getPptData={getPptData}
                        defaultSettings={defaultSettings}
                        isPercentile={isPercentile}
                        appliedFiltersInfo={appliedBuFiltersInfo}
                        localPractice={localPractice}
                    />
                );
                break;
            case 4:
                page = (
                    <BUDetailedReport
                        activeResetFilter={activeResetFilter}
                        scoreData={buData}
                        setReport={setReport}
                        errorInfo={errorInfo}
                        updateFilterView={updateFilterView}
                        benchmarks={benchmarks}
                        getPptData={getPptData}
                        defaultSettings={defaultSettings}
                        isPercentile={isPercentile}
                        appliedFiltersInfo={appliedBuFiltersInfo}
                        localPractice={localPractice}
                    />
                );
                break;
            case 5:
                page = (
                    <TargetSetting
                        activeResetFilter={activeResetFilter}
                        setReport={setReport}
                        errorInfo={errorInfo}
                        toggleFilterView={updateFilterView}
                        currentTheme={currentTheme}
                        benchmarks={benchmarks}
                        getPptData={getPptData}
                        defaultSettings={defaultSettings}
                        isPercentile={isPercentile}
                        appliedFiltersInfo={appliedFiltersInfo}
                        localPractice={localPractice}
                        getTargetScoreData={getTargetScoreData}
                        scoreData={targetScoreData}
                        scoreDataFetched={targetScoreDataFetched}
                        apiLoadingCount={apiLoadingCount}
                        apiParams={apiParams}
                        targetBm={targetBm}
                        updateSize={updateSize}
                        updateSurveyRes={updateSurveyRes}
                        updateSurveys={updateSurveys}
                        getReport={getReport}
                        getRawPptData={getRawPptData}
                        reportError={reportError}
                        singlePastReport={singlePastReport}
                        getSinglePastReport={getSinglePastReport}
                        targetBenchmarks={targetBenchmarks}
                        updateTargetSettingBm={updateTargetSettingBm}
                    />
                );
        }
        return page;
    };

    return (
        <Fragment>
            {getPage()}
            {showBarChart && (
                <BarChartModal
                    defaultSettings={defaultSettings}
                    pulseSurvey={pulseSurvey}
                    highScoreIndex={highScoreIndex}
                    recipe={recipe}
                    highScoreKey={highScoreKey}
                    closeModal={updateModal}
                    projects={projectsInUse}
                    selectedIds={selectedIds}
                    rName={rName}
                    changeCurrentPage={changeCurrentPage}
                    setPracticeDisplay={setPracticeDisplay}
                />
            )}
            {reSurveyFilter && currentView !== 5 && (
                <FilterBar
                    activeResetFilter={activeResetFilter}
                    size={sizeToUse}
                    surveyRespondents={surveyRespondents}
                    surveys={surveys}
                    filters={currentView < 3 ? filters : [filters[0], { demographics: buFilters }]}
                    projectInFilter={currentView < 3 ? projectInFilter : BU_PROJECTS}
                    currentView={currentView}
                    showBenchmarks={currentView < 3}
                    closeFilters={updateFilterView}
                    setFilters={setFilters}
                    defaultBenchmark={benchmark}
                    defaultSettings={defaultSettings}
                    years={[oldYear, prevYear]}
                />
            )}
            {reSurveyFilter && currentView === 5 && (
                <FilterBar
                    activeResetFilter={activeResetFilter}
                    size={sizeToUse}
                    surveyRespondents={surveyRespondents}
                    surveys={surveys}
                    filters={filters}
                    closeFilters={updateFilterView}
                    setFilters={setFilters}
                    defaultBenchmark={benchmark}
                    defaultSettings={defaultSettings}
                    showBenchmarks={false}
                />
            )}
        </Fragment>
    );
}

MainBoard.defaultProps = {
    cohesionScore: 0,
};

MainBoard.propTypes = {
    cohesionScore: PropTypes.number,
    apiParams: PropTypes.object.isRequired,
    reSurveyData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    buData: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    scoreData: PropTypes.object.isRequired,
    pulseSurvey: PropTypes.bool.isRequired,
    scoreDataFetched: PropTypes.bool.isRequired,
    reSurveyDataFetched: PropTypes.bool.isRequired,
    buDataFetched: PropTypes.bool.isRequired,
    isDragDisabled: PropTypes.bool.isRequired,
    projects: PropTypes.array.isRequired,
    buFilters: PropTypes.array.isRequired,
    projectsFetched: PropTypes.bool.isRequired,
    errorInfo: PropTypes.string.isRequired,
    currentTheme: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    getScoreData: PropTypes.func.isRequired,
    getBuData: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    getProjectsData: PropTypes.func.isRequired,
    getReSurveyData: PropTypes.func.isRequired,
    getDemographics: PropTypes.func.isRequired,
    getBenchmarks: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    resetReSurveyDataFetched: PropTypes.func.isRequired,
    resetBuDataFetched: PropTypes.func.isRequired,
    updateBuFilters: PropTypes.func.isRequired,
    getPptData: PropTypes.func.isRequired,
    toggleCreateTheme: PropTypes.func.isRequired,
    changeCurrentPage: PropTypes.func.isRequired,
    setPracticeDisplay: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    getClientEngagementData: PropTypes.func.isRequired,
    clientEngagement: PropTypes.object.isRequired,
    clientEngagementFetched: PropTypes.bool.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    reportError: PropTypes.string.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    singlePastReport: PropTypes.object.isRequired,
    cohesionDemographic: PropTypes.string.isRequired,
    localPractice: PropTypes.string.isRequired,
    getTargetScoreData: PropTypes.func.isRequired,
    targetScoreDataFetched: PropTypes.bool.isRequired,
    targetScoreData: PropTypes.object.isRequired,
    targetBenchmarks: PropTypes.array.isRequired,
    targetBm: PropTypes.string.isRequired,
    updateTargetSettingBm: PropTypes.func.isRequired,
};

export default MainBoard;
