import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import { Box } from '@material-ui/core';
import AuthStore from '../../../../common/AuthStore';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import FilterSvg from '../../assets/Filter.svg';
import ActiveFilters1 from '../../assets/ActiveFilters1.svg';
import Down from '../../assets/Down.svg';
import { getStaticText } from '../../../../utils/constants';
import ErrorToast from '../../../../components/InformationToast/ErrorToast';

const showProgress = value => {
    const progessStyle = {
        background: `
                    radial-gradient(closest-side, #ffffff 79%, transparent 80% 100%),
                    conic-gradient(#000000 ${value}%, rgb(239, 238, 239) 0)`,
    };
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <div className="progress-bar" style={progessStyle}>
                <progress value={value} min="0" max="100" style={{ height: 0, width: 0 }} />
                <div className="percentValue">{`${value}%`}</div>
            </div>
        </Box>
    );
};

function NPS({
    pageData,
    errorInfo,
    defaultSettings,
    npsDataFetched,
    callNps,
    toggleFilterView,
    activeFilter,
    addOnTab,
    setAddOnTabs,
    scoreData,
    getPptData,
}) {
    const { lang, report_name, template_name: template_file_name } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { promoters = '', passively_satisfied = '', detractors = '', enps = '' } = pageData || {};
    const { qbyq = {} } = scoreData;
    const { size = '' } = qbyq;
    const {
        NET_PROMOTER_SCORE,
        PROMOTERS,
        NET_PROMOTER_SCORE_DESC,
        PROMOTERS_DESC,
        PASSIVES,
        PASSIVES_DESC,
        DETRACTORS,
        DETRACTORS_DESC,
        NPS_SCORE,
        NPS_QUES,
        NPS_NAV_LINKS,
        FILTERS,
        TOTAL_RES_TEXT,
        DOWNLOAD,
    } = SITE_TEXT || {};
    const checkDataLength = pageData && !!Object.keys(pageData).length;
    useEffect(() => {
        if (!npsDataFetched) {
            callNps();
        }
        // eslint-disable-next-line
    }, [npsDataFetched]);

    const getPpt = (data = []) => {
        const { pptAccessToken = '' } = AuthStore;
        const output = {
            output_file_name: 'data.pptx',
            template_file_name,
            slides: [
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'EngageNPS',
                    data,
                    // styles: getStylesArr(),
                },
            ],
        };
        getPptData(output, pptAccessToken, `${report_name} Scorecard`);
    };

    const getDataForPpt = () => {
        const data = [
            {
                name: 'enps',
                value: enps,
            },
            {
                name: 'passively_satisfied',
                value: passively_satisfied,
            },
            {
                name: 'detractors',
                value: detractors,
            },
            {
                name: 'promoters',
                value: promoters,
            },
            {
                name: 'Checkme',
                is_delete: true,
            },
        ];
        const delete_shapes = [...data].map(({ name }) => ({ name: `${name}_delta`, is_delete: true }));
        data.push(...delete_shapes);
        getPpt(data);
    };

    return (
        <Fragment>
            <div className="optionWrapper">
                <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <ul className="leftNav">
                            {NPS_NAV_LINKS.map((tabName, index) => {
                                return (
                                    <li
                                        data-testid={tabName}
                                        key={tabName}
                                        className={c({ activeLink: addOnTab === index })}
                                        onClick={() => setAddOnTabs(index)}
                                    >
                                        {tabName}
                                    </li>
                                );
                            })}
                        </ul>
                        <div className="nRes">
                            {TOTAL_RES_TEXT}
                            <span>{size.toLocaleString('en-US')}</span>
                        </div>
                    </div>
                    <ul className="navScoreList">
                        {!errorInfo && (
                            <li data-testid="getPptBtn" onClick={getDataForPpt} className="resurveyImg">
                                <img src={Down} alt="" />
                                <span className="lineHead upperHead">{DOWNLOAD}</span>
                            </li>
                        )}
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                            <img src={activeFilter ? ActiveFilters1 : FilterSvg} alt="" />
                            <span className="filter">{FILTERS}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <section className="sectionWrapper">
                {checkDataLength ? (
                    <div className="npsWrapper">
                        <div className="npsInfo">
                            <div className="heading">{NPS_SCORE}</div>
                            <div className="description">{NPS_QUES}</div>
                        </div>
                        <div className="cardWrapper">
                            <div className="cardHeader">
                                <div className="heading">
                                    {NET_PROMOTER_SCORE}
                                    <span className="scoreBox">{enps}</span>
                                </div>
                                <div className="infoText">{NET_PROMOTER_SCORE_DESC}</div>
                            </div>
                            <div className="cardBody">
                                <div className="cardData">
                                    <div className="iconContainer">
                                        <ThumbUpIcon />
                                    </div>
                                    <div className="tagName">
                                        {PROMOTERS} <br />
                                        <span className="infoText">{PROMOTERS_DESC}</span>
                                    </div>
                                    <div className="percentProgress">{showProgress(promoters)}</div>
                                </div>
                                <div className="cardData">
                                    <div className="iconContainer rotateThumb">
                                        <ThumbUpIcon />
                                    </div>
                                    <div className="tagName">
                                        {PASSIVES}
                                        <br />
                                        <span className="infoText">{PASSIVES_DESC}</span>
                                    </div>
                                    <div className="percentProgress">{showProgress(passively_satisfied)}</div>
                                </div>
                                <div className="cardData">
                                    <div className="iconContainer">
                                        <ThumbDownIcon />
                                    </div>
                                    <div className="tagName">
                                        {DETRACTORS} <br />
                                        <span className="infoText">{DETRACTORS_DESC}</span>
                                    </div>
                                    <div className="percentProgress">{showProgress(detractors)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {!checkDataLength && errorInfo && <ErrorToast message={errorInfo} />}
            </section>
        </Fragment>
    );
}

NPS.propTypes = {
    pageData: PropTypes.object.isRequired,
    errorInfo: PropTypes.string.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    npsDataFetched: PropTypes.bool.isRequired,
    callNps: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    scoreData: PropTypes.object.isRequired,
};

export default NPS;
