import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Flagpole from './Flagpole';

import {
    updateLeftBenchmarks,
    updateRightBenchmarks,
    getScoreData,
    updateApiParams,
    getPptData,
} from '../../Diagnose/actions';
import { getFlagpole } from '../actions';
import {
    selectLeftBenchmarks,
    selectRightBenchmarks,
    selectApiParams,
    selectApiCount,
    selectFiltersApplied,
    getErrorInfo,
} from '../../Diagnose/selector';
import {
    selectDataAtGlance,
    selectFilterParam,
    selectFlagpoleData,
    selectFlagpoleError,
    selectFlagpoleFetched,
} from '../selector';

import { getDefaultSettings } from '../../Login/selector';

const stateToProps = createStructuredSelector({
    flagpoleData: selectFlagpoleData(),
    flagpoleFetched: selectFlagpoleFetched(),
    leftBenchmarks: selectLeftBenchmarks(),
    rightBenchmarks: selectRightBenchmarks(),
    apiParams: selectApiParams(),
    apiLoadingCount: selectApiCount(),
    activeFilter: selectFiltersApplied(),
    errorInfo: getErrorInfo(),
    defaultSettings: getDefaultSettings(),
    filterParams: selectFilterParam(),
    flagpoleError: selectFlagpoleError(),
    dataAtGlance: selectDataAtGlance(),
});

const dispatchToProps = {
    getScoreData,
    getFlagpole,
    updateLeftBenchmarks,
    updateRightBenchmarks,
    updateApiParams,
    getPptData,
};

export default connect(stateToProps, dispatchToProps)(Flagpole);
