import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ProgressBar from './ProgressBar';

import { getPptDataStatus, toggleProgressBar } from '../../containers/Diagnose/actions';

import { selectPptStatus, selectPptError, selectPptStatusFetching } from '../../containers/Diagnose/selector';

const stateToProps = createStructuredSelector({
    pptError: selectPptError(),
    pptStatus: selectPptStatus(),
    pptStatusFetching: selectPptStatusFetching(),
});

const dispatchToProps = { getPptDataStatus, toggleProgressBar };

export default connect(stateToProps, dispatchToProps)(ProgressBar);
