import { fromJS } from 'immutable';
import { GET_REPORTS, GET_REPORT_USERS } from './constants';
import { REMOVE_SAVED_TOKEN } from '../Login/constants';

export const INITIAL_STATE = fromJS({
    count: 0,
    error: '',
    userReports: fromJS({}),
    userReportsFetched: false,
    listError: '',
    usersList: fromJS([]),
    usersListFetched: false,
});

const reducer = (state = INITIAL_STATE, { type = '', payload = {} }) => {
    switch (type) {
        case `${GET_REPORTS}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_REPORTS}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('userReports', fromJS(payload))
                .set('userReportsFetched', true);
        }
        case `${GET_REPORTS}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('userReports', fromJS({}))
                .set('userReportsFetched', true);
        }
        case `${GET_REPORT_USERS}_PENDING`: {
            return state.set('listError', '').update('count', count => count + 1);
        }
        case `${GET_REPORT_USERS}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('usersList', fromJS(payload))
                .set('usersListFetched', true);
        }
        case `${GET_REPORT_USERS}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('usersList', fromJS([]))
                .set('usersListFetched', true);
        }
        case REMOVE_SAVED_TOKEN: {
            if (!payload) {
                return INITIAL_STATE;
            }
            return state;
        }
        default:
            return state;
    }
};

export default reducer;
