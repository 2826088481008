import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import Lottie from 'react-lottie';
import { getStaticText } from '../../../utils/constants';
import { getColor, getNegColor } from '../../../utils/functions';
import animationData from '../assets/lottie.json';

function Questions({ data, defaultSettings, apiLoadingCount, errorInfo }) {
    const { lang, report_type: surveyType = '' } = defaultSettings || {};
    const staticText = getStaticText(lang);
    const { IND_EXP_SCORE_CLASSIFICATION, QUES_LEGEND_TYPE, SITE_TEXT } = staticText;
    const {
        SINGLE_QUESTIONS_LEGEND,
        IND_QUESTIONS_QUES,
        NEGATIVE_PRACTICES,
        RESPONSE_PERCENT,
        EE_PERCENT_FAVORABLE,
        EE_PERCENTILE,
        NOTE,
        IND_EXP_QUESTION_NOTE,
    } = SITE_TEXT || {};
    // const [selectedTab, setSelectedTab] = useState(0);
    // const { SINGLE_QUESTIONS_LEGEND, IND_QUESTIONS_QUES, NEGATIVE_PRACTICES, RESPONSE_PERCENT } = SITE_TEXT[lang] || {};
    const [expandedList, updateExpandedList] = useState([0]);
    const [isExpanded, setIsExpanded] = useState(false);
    const { children: selectedChild = [] } = IND_QUESTIONS_QUES[0];

    const updateExpanded = index => {
        let newList = [...expandedList];
        const currentIndex = expandedList.indexOf(index);
        if (currentIndex !== -1) {
            newList = [...expandedList.slice(0, currentIndex), ...expandedList.slice(currentIndex + 1)];
        } else {
            newList.push(index);
        }
        setIsExpanded(true);
        updateExpandedList(newList);
    };

    const ExpandIcon = () => {
        const defaultOptions = {
            loop: false,
            autoplay: false,
            animationData,
        };

        return (
            <div className="expand-icon">
                <Lottie options={defaultOptions} />
            </div>
        );
    };

    const ExpandIconAnimated = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData,
        };

        const onClickOptions = {
            ...defaultOptions,
            loop: false,
            autoplay: false,
        };

        const handleClick = () => {
            setIsExpanded(true);
        };

        return (
            <div className="expand-icon" onClick={handleClick}>
                <Lottie options={isExpanded ? onClickOptions : defaultOptions} />
            </div>
        );
    };
    const renderCategoryData = child => {
        return child.map((dataSet, index) => {
            const { display_name: title = '', meta_scores = [], title: childTitle, score: prScore = '-' } =
                data[dataSet] || {};
            const metas = meta_scores.map(({ meta }) => meta);
            const sup = QUES_LEGEND_TYPE[dataSet] || '';
            const singleQues = sup === 2;
            const isNegativeTrait = NEGATIVE_PRACTICES.includes(title);
            const colorIndex = getColor(prScore, [], false, true);
            const colorIndexNeg = getNegColor(prScore, [], false, true);
            const { positiveBg = '#ffffff', negativeBg = '#ffffff', color = '#ffffff' } =
                IND_EXP_SCORE_CLASSIFICATION[isNegativeTrait ? colorIndexNeg : colorIndex] || {};
            const bg = isNegativeTrait ? negativeBg : positiveBg;
            const background = bg;
            const SINGLE_QUESTIONS_LEGEND_TITLE = metas.includes('IENPS1');
            return (
                <Accordion classes={{ root: 'accordionRoot' }} key={index}>
                    <AccordionSummary
                        expandIcon={index === 0 ? <ExpandIconAnimated /> : <ExpandIcon />}
                        onClick={() => updateExpanded(index)}
                        data-testid={title}
                        classes={{ content: 'accordionHead' }}
                    >
                        {!SINGLE_QUESTIONS_LEGEND_TITLE ? (
                            <div className="outcomeContent">
                                {prScore ? (
                                    <div className="metaTotalScore">
                                        <div
                                            className="circle"
                                            style={{
                                                background,
                                                color,
                                            }}
                                        >
                                            {prScore}
                                        </div>
                                    </div>
                                ) : null}
                                {title} <sup>{sup}</sup>
                            </div>
                        ) : (
                            <Fragment>
                                {!singleQues ? (
                                    <div className="outcomeContent">{title}</div>
                                ) : (
                                    <div className="outcomeContent">
                                        {prScore ? (
                                            <div className="metaTotalScore">
                                                <div
                                                    className="circle"
                                                    style={{
                                                        background,
                                                        color,
                                                    }}
                                                >
                                                    {prScore}
                                                </div>
                                            </div>
                                        ) : null}
                                        {title}
                                        <span className="scoreHeading" />
                                        <ul className="questionLegends singleQuestions">
                                            <li className="listTitle">{RESPONSE_PERCENT}</li>
                                            {SINGLE_QUESTIONS_LEGEND.map(legendText => (
                                                <li key={legendText}>
                                                    <span />
                                                    {legendText}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </Fragment>
                        )}
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: 'clearfix' }}>
                        <section className="leftSection">
                            {meta_scores.map(item => {
                                const {
                                    qtext: metaPracticeText = '',
                                    bottom2_score = 0,
                                    nuetral_score = 0,
                                    na_score = 0,
                                    score = '-',
                                } = item || {};
                                const topScore = 100 - bottom2_score - nuetral_score - na_score;
                                const totalScore = bottom2_score + nuetral_score + topScore;
                                return (
                                    <div key={childTitle} className="clearfix">
                                        <section key={metaPracticeText} className="metaSection clearfix">
                                            <div className="metaText">
                                                {metaPracticeText}
                                                <sup>{sup}</sup>
                                            </div>
                                            {totalScore ? (
                                                <Fragment>
                                                    <div className={c({ singleLegends: singleQues }, 'metaScore')}>
                                                        <div>
                                                            <span
                                                                style={{
                                                                    width: `${bottom2_score}%`,
                                                                }}
                                                            >
                                                                {bottom2_score}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    width: `${nuetral_score}%`,
                                                                }}
                                                            >
                                                                {nuetral_score}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    width: `${score}%`,
                                                                }}
                                                            >
                                                                {score}
                                                            </span>
                                                            <span
                                                                className={c({ singleQues }, 'naScore')}
                                                            >{`${na_score}%`}</span>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ) : null}
                                        </section>
                                    </div>
                                );
                            })}
                        </section>
                    </AccordionDetails>
                </Accordion>
            );
        });
    };

    return (
        <Fragment>
            {Object.keys(data).length ? (
                <div className="questionBoard clearfix">{renderCategoryData(selectedChild)}</div>
            ) : null}

            {!apiLoadingCount && !errorInfo && (
                <div className="note">
                    <b>{NOTE}:</b>
                    <br />
                    {IND_EXP_QUESTION_NOTE[0]}
                    <br />
                    {IND_EXP_QUESTION_NOTE[1]}
                    <br />
                    {surveyType === 'percent favorable' ? <div>3. {EE_PERCENT_FAVORABLE}</div> : ''}
                    {surveyType === 'percentile' ? <div>3. {EE_PERCENTILE}</div> : ''}
                </div>
            )}
        </Fragment>
    );
}

Questions.propTypes = {
    data: PropTypes.array.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    errorInfo: PropTypes.number.isRequired,
};

export default Questions;
