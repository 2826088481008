import { getStaticTextAnalytics } from './constants';

const staticTextAnalytics = getStaticTextAnalytics();
const {
    GET_DATA_AT_GLANCE,
    GET_FILTERS_DATA,
    GET_ANALYTICS_SCORECARD,
    GET_ANALYTICS_CATEGORY,
    GET_RANKING,
    GET_DIFFERENTIAL,
    UPDATE_ANALYTICS_FILTERS,
    UPDATE_SCORECARD_DROPDOWN,
    UPDATE_ANALYTICS_CATEGORY,
    GET_ANALYTICS_FLAGPOLE,
    UPDATE_DIFFERENTIAL_DATA,
    GET_FILTERS_MAPPING,
    GET_DEFAULT_YEAR,
    SET_ORG_SIZE_DATA,
    GET_MEDIAN,
    UPDATE_MEDIAN_DATA,
    GET_DIFFERENTIAL_QUARTILE,
    SET_QUARTILE_TOP_BTN,
    SET_MEDIAN_TOP_BTN,
    GET_DIFFERENTIAL_MEDIAN,
    GET_CUSTOM_BM_LIST,
    GET_CUSTOM_BM_SURVEY_LIST,
    SAVE_CUSTOM_BM,
    GET_CUSTOM_BM_DETAIL,
    SET_CREATE_CUSTOM_BM_PAYLOAD,
    SET_CUSTOM_BM_FILTER,
    DELETE_CUSTOM_BM,
    UPDATE_CUSTOM_BM,
    CHECK_NAME_EXIST,
    SET_CUSTOM_BM_MSG,
    SEND_CUSTOM_BM_REQUEST,
    SET_ACTION_CODE,
    GET_APPROVAL_REQUESTS,
    GET_REJECTED_REQUESTS,
    APPROVE_REQUEST,
    REJECT_REQUEST,
    GET_APPROVED_REQUESTS,
    SAVE_AND_PUBLISH,
    DUPLICATE_BM,
    SET_EDIT_CUSTOM_BM_PAYLOAD,
    RESET_FORM,
} = staticTextAnalytics;

export const getFilterData = payload => ({
    type: `${GET_FILTERS_DATA}_PENDING`,
    payload,
});

export const getFilterDataSuccess = payload => ({
    type: `${GET_FILTERS_DATA}_SUCCESS`,
    payload,
});

export const getFilterDataFailed = (payload = {}) => ({
    type: `${GET_FILTERS_DATA}_FAILED`,
    payload,
});

export const getFiltersMapping = payload => ({
    type: `${GET_FILTERS_MAPPING}_PENDING`,
    payload,
});

export const getFiltersMappingSuccess = payload => ({
    type: `${GET_FILTERS_MAPPING}_SUCCESS`,
    payload,
});

export const getFiltersMappingFailed = (payload = {}) => ({
    type: `${GET_FILTERS_MAPPING}_FAILED`,
    payload,
});

export const getDataAtGlanceSuccess = payload => ({
    type: `${GET_DATA_AT_GLANCE}_SUCCESS`,
    payload,
});

export const getDataAtGlanceFailed = (payload = {}) => ({
    type: `${GET_DATA_AT_GLANCE}_FAILED`,
    payload,
});

export const getDataAtGlance = payload => ({
    type: `${GET_DATA_AT_GLANCE}_PENDING`,
    payload,
});

export const getDifferentialSuccess = payload => ({
    type: `${GET_DIFFERENTIAL}_SUCCESS`,
    payload,
});

export const getDifferentialFailed = (payload = {}) => ({
    type: `${GET_DIFFERENTIAL}_FAILED`,
    payload,
});

export const getDifferential = payload => ({
    type: `${GET_DIFFERENTIAL}_PENDING`,
    payload,
});

export const getDifferentialQuartileSuccess = payload => ({
    type: `${GET_DIFFERENTIAL_QUARTILE}_SUCCESS`,
    payload,
});

export const getDifferentialQuartileFailed = (payload = {}) => ({
    type: `${GET_DIFFERENTIAL_QUARTILE}_FAILED`,
    payload,
});

export const getDifferentialQuartile = payload => ({
    type: `${GET_DIFFERENTIAL_QUARTILE}_PENDING`,
    payload,
});

export const getDifferentialMedianSuccess = payload => ({
    type: `${GET_DIFFERENTIAL_MEDIAN}_SUCCESS`,
    payload,
});

export const getDifferentialMedianFailed = (payload = {}) => ({
    type: `${GET_DIFFERENTIAL_MEDIAN}_FAILED`,
    payload,
});

export const getDifferentialMedian = payload => ({
    type: `${GET_DIFFERENTIAL_MEDIAN}_PENDING`,
    payload,
});

export const getMedianSuccess = payload => ({
    type: `${GET_MEDIAN}_SUCCESS`,
    payload,
});

export const getMedianFailed = (payload = {}) => ({
    type: `${GET_MEDIAN}_FAILED`,
    payload,
});

export const getMedian = payload => ({
    type: `${GET_MEDIAN}_PENDING`,
    payload,
});

export const getRankingSuccess = payload => ({
    type: `${GET_RANKING}_SUCCESS`,
    payload,
});

export const getRankingFailed = (payload = {}) => ({
    type: `${GET_RANKING}_FAILED`,
    payload,
});

export const getRanking = payload => ({
    type: `${GET_RANKING}_PENDING`,
    payload,
});

export const getAnalyticsPerCategorySuccess = payload => ({
    type: `${GET_ANALYTICS_CATEGORY}_SUCCESS`,
    payload,
});

export const getAnalyticsPerCategoryFailed = (payload = {}) => ({
    type: `${GET_ANALYTICS_CATEGORY}_FAILED`,
    payload,
});

export const getAnalyticsPerCategory = payload => ({
    type: `${GET_ANALYTICS_CATEGORY}_PENDING`,
    payload,
});

export const getFlagpoleSuccess = payload => ({
    type: `${GET_ANALYTICS_FLAGPOLE}_SUCCESS`,
    payload,
});

export const getFlagpoleFailed = (payload = {}) => ({
    type: `${GET_ANALYTICS_FLAGPOLE}_FAILED`,
    payload,
});

export const getFlagpole = payload => ({
    type: `${GET_ANALYTICS_FLAGPOLE}_PENDING`,
    payload,
});

export const getScorecardSuccess = payload => ({
    type: `${GET_ANALYTICS_SCORECARD}_SUCCESS`,
    payload,
});

export const getScorecardFailed = (payload = {}) => ({
    type: `${GET_ANALYTICS_SCORECARD}_FAILED`,
    payload,
});

export const getScorecard = payload => ({
    type: `${GET_ANALYTICS_SCORECARD}_PENDING`,
    payload,
});

export const updateFilters = payload => ({
    type: UPDATE_ANALYTICS_FILTERS,
    payload,
});

export const setDropdown = payload => ({
    type: UPDATE_SCORECARD_DROPDOWN,
    payload,
});

export const setAnalyticsPerCategory = payload => ({
    type: UPDATE_ANALYTICS_CATEGORY,
    payload,
});

export const setDifferentialData = payload => ({
    type: UPDATE_DIFFERENTIAL_DATA,
    payload,
});

export const setMedianData = payload => ({
    type: UPDATE_MEDIAN_DATA,
    payload,
});

export const getDefaultYear = payload => ({
    type: `${GET_DEFAULT_YEAR}_PENDING`,
    payload,
});

export const getDefaultYearSuccess = payload => ({
    type: `${GET_DEFAULT_YEAR}_SUCCESS`,
    payload,
});

export const getDefaultYearFailed = (payload = {}) => ({
    type: `${GET_DEFAULT_YEAR}_FAILED`,
    payload,
});

export const setOrgSizeData = payload => ({
    type: SET_ORG_SIZE_DATA,
    payload,
});

export const showQuatileTabBtn = payload => ({
    type: SET_QUARTILE_TOP_BTN,
    payload,
});

export const showMedianTabBtn = payload => ({
    type: SET_MEDIAN_TOP_BTN,
    payload,
});

export const getCustomBmList = payload => ({
    type: `${GET_CUSTOM_BM_LIST}_PENDING`,
    payload,
});

export const getCustomBmListSuccess = payload => ({
    type: `${GET_CUSTOM_BM_LIST}_SUCCESS`,
    payload,
});

export const getCustomBmListFailed = (payload = {}) => ({
    type: `${GET_CUSTOM_BM_LIST}_FAILED`,
    payload,
});

export const getCustomBmSurveyList = payload => ({
    type: `${GET_CUSTOM_BM_SURVEY_LIST}_PENDING`,
    payload,
});

export const getCustomBmSurveyListSuccess = payload => ({
    type: `${GET_CUSTOM_BM_SURVEY_LIST}_SUCCESS`,
    payload,
});

export const getCustomBmSurveyListFailed = (payload = {}) => ({
    type: `${GET_CUSTOM_BM_SURVEY_LIST}_FAILED`,
    payload,
});

export const saveCustomBmDraft = payload => ({
    type: `${SAVE_CUSTOM_BM}_PENDING`,
    payload,
});

export const saveCustomBmDraftSuccess = payload => ({
    type: `${SAVE_CUSTOM_BM}_SUCCESS`,
    payload,
});

export const saveCustomBmDraftFailed = (payload = {}) => ({
    type: `${SAVE_CUSTOM_BM}_FAILED`,
    payload,
});

export const getCustomBmDetail = payload => ({
    type: `${GET_CUSTOM_BM_DETAIL}_PENDING`,
    payload,
});

export const getCustomBmDetailSuccess = payload => ({
    type: `${GET_CUSTOM_BM_DETAIL}_SUCCESS`,
    payload,
});

export const getCustomBmDetailFailed = (payload = {}) => ({
    type: `${GET_CUSTOM_BM_DETAIL}_FAILED`,
    payload,
});

export const setCreateCustomBmPayload = payload => ({
    type: SET_CREATE_CUSTOM_BM_PAYLOAD,
    payload,
});

export const setCustomBmFilter = payload => ({
    type: SET_CUSTOM_BM_FILTER,
    payload,
});

export const deleteCustomBm = payload => ({
    type: `${DELETE_CUSTOM_BM}_PENDING`,
    payload,
});

export const deleteCustomBmSuccess = payload => ({
    type: `${DELETE_CUSTOM_BM}_SUCCESS`,
    payload,
});

export const deleteCustomBmFailed = (payload = {}) => ({
    type: `${DELETE_CUSTOM_BM}_FAILED`,
    payload,
});

export const updateCustomBm = payload => ({
    type: `${UPDATE_CUSTOM_BM}_PENDING`,
    payload,
});

export const updateCustomBmSuccess = payload => ({
    type: `${UPDATE_CUSTOM_BM}_SUCCESS`,
    payload,
});

export const updateCustomBmFailed = (payload = {}) => ({
    type: `${UPDATE_CUSTOM_BM}_FAILED`,
    payload,
});

export const checkNameExist = payload => ({
    type: `${CHECK_NAME_EXIST}_PENDING`,
    payload,
});

export const checkNameExistSuccess = payload => ({
    type: `${CHECK_NAME_EXIST}_SUCCESS`,
    payload,
});

export const checkNameExistFailed = (payload = {}) => ({
    type: `${CHECK_NAME_EXIST}_FAILED`,
    payload,
});

export const setCustomBmMsg = (payload = {}) => ({
    type: SET_CUSTOM_BM_MSG,
    payload,
});

export const sendCustomBmRequest = payload => ({
    type: `${SEND_CUSTOM_BM_REQUEST}_PENDING`,
    payload,
});

export const sendCustomBmRequestSuccess = payload => ({
    type: `${SEND_CUSTOM_BM_REQUEST}_SUCCESS`,
    payload,
});

export const sendCustomBmRequestFailed = payload => ({
    type: `${SEND_CUSTOM_BM_REQUEST}_FAILED`,
    payload,
});

export const setActionCode = payload => ({
    type: SET_ACTION_CODE,
    payload,
});

export const getApprovalRequests = payload => ({
    type: `${GET_APPROVAL_REQUESTS}_PENDING`,
    payload,
});

export const getApprovalRequestsSuccess = payload => ({
    type: `${GET_APPROVAL_REQUESTS}_SUCCESS`,
    payload,
});

export const getApprovalRequestsFailed = payload => ({
    type: `${GET_APPROVAL_REQUESTS}_FAILED`,
    payload,
});

export const getRejectedRequests = payload => ({
    type: `${GET_REJECTED_REQUESTS}_PENDING`,
    payload,
});

export const getRejectedRequestsSuccess = payload => ({
    type: `${GET_REJECTED_REQUESTS}_SUCCESS`,
    payload,
});

export const getRejectedRequestsFailed = payload => ({
    type: `${GET_REJECTED_REQUESTS}_FAILED`,
    payload,
});

export const getApprovedRequests = payload => ({
    type: `${GET_APPROVED_REQUESTS}_PENDING`,
    payload,
});

export const getApprovedRequestsSuccess = payload => ({
    type: `${GET_APPROVED_REQUESTS}_SUCCESS`,
    payload,
});

export const getApprovedRequestsFailed = payload => ({
    type: `${GET_APPROVED_REQUESTS}_FAILED`,
    payload,
});

export const approveRequest = payload => ({
    type: `${APPROVE_REQUEST}_PENDING`,
    payload,
});

export const approveRequestSuccess = payload => ({
    type: `${APPROVE_REQUEST}_SUCCESS`,
    payload,
});

export const approveRequestFailed = payload => ({
    type: `${APPROVE_REQUEST}_FAILED`,
    payload,
});

export const rejectRequest = payload => ({
    type: `${REJECT_REQUEST}_PENDING`,
    payload,
});

export const rejectRequestSuccess = payload => ({
    type: `${REJECT_REQUEST}_SUCCESS`,
    payload,
});

export const rejectRequestFailed = payload => ({
    type: `${REJECT_REQUEST}_FAILED`,
    payload,
});

export const saveAndPublish = payload => ({
    type: SAVE_AND_PUBLISH,
    payload,
});

export const duplicateCustomBmRequest = payload => ({
    type: `${DUPLICATE_BM}_PENDING`,
    payload,
});

export const duplicateCustomBmRequestSuccess = payload => ({
    type: `${DUPLICATE_BM}_SUCCESS`,
    payload,
});

export const duplicateCustomBmRequestFailed = payload => ({
    type: `${DUPLICATE_BM}_FAILED`,
    payload,
});

export const setEditCustomBmPayload = payload => ({
    type: SET_EDIT_CUSTOM_BM_PAYLOAD,
    payload,
});

export const resetForm = payload => ({
    type: RESET_FORM,
    payload,
});
