import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import Layout from './Layout';

import { removeToken } from '../../containers/Login/actions';

import { selectPendingReports } from '../../containers/Reports/selector';
import { getDefaultSettings } from '../../containers/Login/selector';
import { selectReportsData } from '../../containers/UserReports/selector';

const stateToProps = createStructuredSelector({
    pendingReports: selectPendingReports(),
    defaultSettings: getDefaultSettings(),
    reportsData: selectReportsData(),
});

const dispatchToProps = { removeToken };

export default withRouter(connect(stateToProps, dispatchToProps)(Layout));
