import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import moment from 'moment';

import {
    getReportSuccess,
    getReportFailed,
    getPastReportSuccess,
    getPastReportFailed,
    getRawPptDataSuccess,
    getRawPptDataFailed,
    getInclusionDataSuccess,
    getInclusionDataFailed,
    getInclusionGapSuccess,
    getInclusionGapFailed,
    getAllGapSuccess,
    getAllGapFailed,
    getPendingReportStatusSuccess,
    getPendingReportStatusFailed,
    getDataCubeSuccess,
    getDataCubeFailed,
    getRespondentsSuccess,
    getRespondentsFailed,
    getGraphGapData,
    getResurveyMappingSuccess,
    getResurveyMappingFailed,
    getResurveyExcelSuccess,
    getResurveyExcelFailed,
    getInclusionDataResurveySuccess,
    getInclusionDataResurveyFailed,
    getResurveyAllGapSuccess,
    getResurveyAllGapFailed,
    putPastReportSuccess,
    putPastReportFailed,
    getResurveyGapSuccess,
    getResurveyGapFailed,
    getMappingExcelSuccess,
    getmappingExcelFailed,
    getMappedDemographicSuccess,
    getMappedDemographicFailed,
    getQbyqNsizeSuccess,
    getQbyqNsizeFailed,
    getMultiPptDataSuccess,
    getMultiPptDataFailed,
    savePptData,
    savePptDataSuccess,
    savePptDataFailed,
    getBulkScoreDataSuccess,
    getBulkScoreDataFailed,
} from './actions';
import {
    getGapAllInclusionDataSuccess,
    getGapAllInclusionDataFailed,
    getProjectsSuccess,
    getProjectsFailed,
    getResAllGapInclusionDataSuccess,
    getResAllGapInclusionDataFailed,
} from '../Inclusion/actions';
import { getPptData, getSinglePastReport } from '../Diagnose/actions';
import {
    GET_PAST_REPORT,
    GET_REPORT,
    GET_RESURVEY_EXCEL,
    GET_DATA_CUBE,
    GET_PPT_RAW_DATA,
    GET_INCLUSION_DATA,
    GET_GAP_DATA,
    GET_GAP_DATA_ALL,
    PENDING_REPORTS_STATUS,
    GET_RESPONDENTS_DATA,
    GET_RESURVEY_MAPPING,
    GET_INCLUSION_DATA_RES,
    GET_RES_GAP_DATA_ALL,
    PUT_PAST_REPORT,
    GET_RES_GAP_DATA,
    GET_MAPPING_EXCEL,
    GET_IA_MAPPED_DEMOGRAPHICS,
    GET_QBYQ_NSIZE,
    GET_MULTI_PPT_DATA,
    SAVE_PPT_ZIP,
    GET_BULK_SCORE_DATA,
} from './constants';
import {
    getReportUrl,
    getRespondentsUrl,
    getDataCubeUrl,
    exportPathUrl,
    rawPptPathUrl,
    getInclusionDataUrl,
    getInclusionGapUrl,
    getAllGapUrl,
    getPendingReportsUrl,
    getMappedProjectUrl,
    putReportPathUrl,
    getMappedDemo,
    getInclusionResGapUrl,
} from './apis';
import {
    GET_ALL_GAP_INCLUSION_DATA,
    GET_MAPPED_PROJECTS,
    GET_RES_ALL_GAP_INCLUSION_DATA,
    IA_CUSTOM_ERROR,
} from '../Inclusion/constants';

import endpoints from '../../Endpoints';
import { getMappedDemographicsUrl, savePptUrl, getScoreDataUrl } from '../Diagnose/apis';
import AuthStore from '../../common/AuthStore';
import { base64ToArrayBuffer, parseJwt } from '../../utils/functions';
import { getPptAccessApiUrl } from '../Login/apis';

export function* getReportSaga({ payload }) {
    try {
        const { export_type = '', report_id = '' } = payload || {};
        const { data = {} } = yield call(getReportUrl, payload);
        yield put(getReportSuccess(data));
        if (export_type === 'single_page') {
            yield put(getSinglePastReport(report_id));
        }
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {} } = errorData;
        yield put(getReportFailed({ message, status }));
    }
}
export function* getResurveyExcelSaga({ payload }) {
    const { apiBasePath, excelReportPath } = endpoints;
    const resurveyExcelUrl = `${apiBasePath}${excelReportPath}`;
    const { accessToken = '' } = AuthStore;

    const apiCall = () => {
        return axios
            .post(resurveyExcelUrl, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: accessToken,
                },
                responseType: 'arraybuffer',
            })
            .then(response => response)
            .catch(err => {
                throw err;
            });
    };
    try {
        const { data } = yield call(apiCall);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Resurvey Excel ${moment().format('MM_DD_YYYY')}.xlsx`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        yield put(getResurveyExcelSuccess());
    } catch ({ response = {} }) {
        const { status = '' } = response;
        yield put(getResurveyExcelFailed({ message: 'An error occurred, please try again.', status }));
    }
}

export function* getMappingExcelSaga({ payload }) {
    const { ohid = '', previous_ohid = '' } = payload;
    const { accessToken = '' } = AuthStore;
    const { apiBasePath, getResurveyMappingDownload } = endpoints;
    const mappingExcelUrl = `${apiBasePath}${getResurveyMappingDownload}?ohid=${ohid}&previous_ohid=${previous_ohid}`;
    const apiCall = () => {
        axios
            .get(mappingExcelUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: accessToken,
                },
                responseType: 'blob',
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Resurvey Excel ${moment().format('MM_DD_YYYY')}.xlsx`);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(err => {
                throw err;
            });
    };
    try {
        yield call(apiCall);
        yield new Promise(resolve => setTimeout(resolve, 1000));
        yield put(getMappingExcelSuccess());
    } catch ({ response = {} }) {
        const { status = '' } = response;
        yield put(getmappingExcelFailed({ message: 'An error occurred, please try again.', status }));
    }
}

export function* getRespondentsSaga({ payload }) {
    try {
        const { data = {} } = yield call(getRespondentsUrl, payload);
        const { data: resData = {} } = data;
        yield put(getRespondentsSuccess(resData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {} } = errorData;
        yield put(getRespondentsFailed({ message, status }));
    }
}

export function* getDataCubeSaga({ payload }) {
    try {
        const { data = {} } = yield call(getDataCubeUrl, payload);
        yield put(getDataCubeSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {} } = errorData;
        yield put(getDataCubeFailed({ message, status }));
    }
}

export function* getExportSaga({ payload }) {
    try {
        const { data } = yield call(exportPathUrl, payload);
        const { data: pastData = {} } = data;
        const { records = [] } = pastData;
        pastData.records = [...records].reverse();
        yield put(getPastReportSuccess(pastData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {} } = errorData;
        yield put(getPastReportFailed({ message, status }));
    }
}

export function* getRawPptSaga({ payload }) {
    try {
        const { pptTokenExp = 0 } = AuthStore;
        const currentTime = Math.floor(Date.now() / 1000);
        let newToken = '';
        const { id, pptAccessToken, taskIdToUse, export_name, artifact, fileToDownload } = payload;
        if (artifact !== null && artifact !== undefined) {
            const link = document.createElement('a');
            link.href = artifact;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        if (currentTime > pptTokenExp && !fileToDownload) {
            const { data: pptAccessData = {} } = yield call(getPptAccessApiUrl);
            const { data: accessData = {} } = pptAccessData;
            const { id_token, token_type } = accessData;
            newToken = `${token_type} ${id_token}`;
            const { exp = 0 } = parseJwt(newToken);
            AuthStore.pptAccessToken = newToken;
            AuthStore.pptTokenExp = exp;
        }
        const tokenToUse = newToken || pptAccessToken;
        const { data: rawData = {} } = yield call(rawPptPathUrl, id);
        const { data = {} } = rawData;
        const { json_data = '' } = data;
        const parseJsonValues = JSON.parse(json_data);
        const { output_file_name = 'MainPpt.' } = parseJsonValues;
        if (!fileToDownload) {
            yield put(
                getPptData(
                    JSON.parse(json_data),
                    tokenToUse,
                    output_file_name.split('.')[0],
                    0,
                    taskIdToUse,
                    export_name
                )
            );
        } else {
            const { url } = parseJsonValues;
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        yield put(getRawPptDataSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {} } = errorData;
        yield put(getRawPptDataFailed({ message, status }));
    }
}

export function* savePptZipSaga({ payload, token, pptName, countToAdd, report_name }) {
    try {
        const { pptTokenExp = 0 } = AuthStore;
        const currentTime = Math.floor(Date.now() / 1000);
        let newToken = '';
        if (currentTime > pptTokenExp) {
            const { data: pptAccessData = {} } = yield call(getPptAccessApiUrl);
            const { data: accessData = {} } = pptAccessData;
            const { id_token, token_type } = accessData;
            newToken = `${token_type} ${id_token}`;
            const { exp = 0 } = parseJwt(newToken);
            AuthStore.pptAccessToken = newToken;
            AuthStore.pptTokenExp = exp;
        }
        const tokenToUse = newToken || token;
        const response = yield all(
            payload.map(item => {
                const taskId = Math.ceil(Math.random() * 1000000);
                return call(savePptUrl, item, tokenToUse, taskId);
            })
        );
        const zip = new JSZip();
        response.forEach((item, i) => {
            const { data = {} } = item || {};
            const { FileData = '' } = data;
            const sampleBytes = base64ToArrayBuffer(FileData);
            zip.file(`${pptName[i]}`, sampleBytes, { binary: true });
        });
        yield zip.generateAsync({ type: 'blob' }).then(blob => {
            FileSaver.saveAs(blob, `${report_name}_${moment().format('MM_DD_YYYY_hh_mm_ss')}.zip`);
        });
        yield put(savePptDataSuccess({ response, countToAdd }));
    } catch ({ response }) {
        yield put(savePptDataFailed(response));
    }
}

export function* getMultiPptDwnldSaga({ payload }) {
    try {
        const { pptTokenExp = 0 } = AuthStore;
        const currentTime = Math.floor(Date.now() / 1000);
        let newToken = '';
        if (currentTime > pptTokenExp) {
            const { data: pptAccessData = {} } = yield call(getPptAccessApiUrl);
            const { data: accessData = {} } = pptAccessData;
            const { id_token, token_type } = accessData;
            newToken = `${token_type} ${id_token}`;
            const { exp = 0 } = parseJwt(newToken);
            AuthStore.pptAccessToken = newToken;
            AuthStore.pptTokenExp = exp;
        }
        const { ids, pptAccessToken, report_name = 'files' } = payload;
        const tokenToUse = newToken || pptAccessToken;
        const results = yield all(ids.map(id => call(rawPptPathUrl, id)));
        const jsonArr = [];
        const pptName = [];
        results.forEach(result => {
            const { data: rawData = {} } = result;
            const { data = {} } = rawData;
            const { json_data = '' } = data;
            const { output_file_name = 'MainPpt' } = JSON.parse(json_data);
            jsonArr.push(json_data);
            pptName.push(output_file_name);
        });
        yield put(savePptData(jsonArr, tokenToUse, pptName, 0, report_name));
        yield put(getMultiPptDataSuccess(results));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {} } = errorData;
        yield put(getMultiPptDataFailed({ message, status }));
    }
}

export function* getInclusionDataSaga({ payload }) {
    try {
        const { data = {} } = yield call(getInclusionDataUrl, payload);
        const { data: scoreData = {} } = data;
        yield put(getInclusionDataSuccess(scoreData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getInclusionDataFailed({ message, error, status }));
    }
}

export function* getQbyqNsizeSaga({ payload }) {
    try {
        const { data = {} } = yield call(getInclusionDataUrl, payload);
        const { data: scoreData = {} } = data;
        yield put(getQbyqNsizeSuccess(scoreData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getQbyqNsizeFailed({ message, error, status }));
    }
}

export function* getInclusionDataResSaga({ payload }) {
    try {
        const { data = {} } = yield call(getInclusionDataUrl, payload);
        const { data: scoreData = {} } = data;
        yield put(getInclusionDataResurveySuccess(scoreData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getInclusionDataResurveyFailed({ message, error, status }));
    }
}

export function* getInclusionGapSaga({ payload }) {
    try {
        const { group_on = '' } = payload;
        const { data = {} } = yield call(getInclusionGapUrl, payload);
        const { data: scoreData = {} } = data;
        if (group_on) {
            yield put(getGraphGapData(scoreData));
        } else {
            yield put(getInclusionGapSuccess(scoreData));
        }
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getInclusionGapFailed({ message, error, status }));
    }
}

export function* getInclusionGapResurveySaga({ payload }) {
    try {
        const { group_on = '' } = payload;
        const { data = {} } = yield call(getInclusionGapUrl, payload);
        const { data: scoreData = {} } = data;
        if (group_on) {
            yield put(getGraphGapData(scoreData));
        } else {
            yield put(getResurveyAllGapSuccess(scoreData));
        }
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getResurveyAllGapFailed({ message, error, status }));
    }
}

export function* getAllGapSaga({ payload }) {
    try {
        const { data = {} } = yield call(getAllGapUrl, payload);
        const { data: gapData = {} } = data;
        yield put(getAllGapSuccess(gapData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        if (status >= 500) {
            yield put(getAllGapFailed({ message: IA_CUSTOM_ERROR, error, status }));
        } else {
            yield put(getAllGapFailed({ message, error, status }));
        }
    }
}

export function* getGapResSaga({ payload }) {
    try {
        const { data = {} } = yield call(getInclusionResGapUrl, payload);
        const { data: gapData = {} } = data;
        yield put(getResurveyGapSuccess(gapData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getResurveyGapFailed({ message, error, status }));
    }
}

export function* getAllGapResSaga({ payload }) {
    try {
        const { data = {} } = yield call(getAllGapUrl, payload);
        const { data: gapData = {} } = data;
        yield put(getResurveyAllGapSuccess(gapData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        if (status >= 500) {
            yield put(getResurveyAllGapFailed({ message: IA_CUSTOM_ERROR, error, status }));
        } else {
            yield put(getResurveyAllGapFailed({ message, error, status }));
        }
    }
}

export function* getReportStatusSaga({ payload }) {
    try {
        const { data = {} } = yield call(getPendingReportsUrl, payload);
        const { data: exportStatus = [] } = data;
        yield put(getPendingReportStatusSuccess(exportStatus));
    } catch ({ response = {} }) {
        const { data: errorData = {} } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getPendingReportStatusFailed({ message, error, status: 401 }));
    }
}

export function* getAllGapInclusionDataSaga({ payload }) {
    try {
        const demosData = payload.demographics;
        const { page } = payload;
        delete payload.demographics;
        const allGapRes = {};
        const l = demosData.length;
        const payloads = [];
        for (let i = 0; i < l; i += 1) {
            const obj = {
                ohid: payload.ohid,
                group_on: payload.group_on,
                filters: payload.filters,
                lang: payload.lang,
                inclusion_threshold: payload.inclusion_threshold,
                gap_on: demosData[i].code,
                page,
            };
            payloads.push(obj);
        }
        const results = yield all(payloads.map(pld => call(getInclusionGapUrl, pld)));
        for (let i = 0; i < results.length; i += 1) {
            const { gap: gapData = {} } = results[i].data.data;
            const { records = {} } = gapData;
            const demo = payloads[i].gap_on;
            if (Object.keys(records).length) {
                allGapRes[demo] = gapData;
            }
        }
        yield put(getGapAllInclusionDataSuccess({ allGapRes }));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getGapAllInclusionDataFailed({ message, error, status }));
    }
}

export function* getResAllGapInclusionDataSaga({ payload }) {
    try {
        const demosData = payload.demographics;
        const { page } = payload;
        delete payload.demographics;
        const allGapRes = {};
        const demos = Object.keys(demosData).map(item => demosData[item]);
        const l = Object.keys(demosData).length;
        const payloads = [];
        for (let i = 0; i < l; i += 1) {
            const obj = {
                ohid: payload.ohid,
                group_on: payload.group_on,
                filters: payload.filters,
                lang: payload.lang,
                inclusion_threshold: payload.inclusion_threshold,
                gap_on: demos[i],
                page,
            };
            payloads.push(obj);
        }
        const results = yield all(payloads.map(pld => call(getInclusionGapUrl, pld)));
        for (let i = 0; i < results.length; i += 1) {
            const { gap: gapData = {} } = results[i].data.data;
            const { records = {} } = gapData;
            const demo = Object.keys(demosData)[i];
            if (Object.keys(records).length) {
                allGapRes[demo] = gapData;
            }
        }
        yield put(getResAllGapInclusionDataSuccess({ allGapRes }));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getResAllGapInclusionDataFailed({ message, error, status }));
    }
}

export function* getResurveyMappingSaga({ payload }) {
    try {
        const { data = {} } = yield call(getMappedDemographicsUrl, payload);
        const { data: mappedDemos = {} } = data;
        yield put(getResurveyMappingSuccess(mappedDemos));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getResurveyMappingFailed({ message, error, status }));
    }
}

export function* getInclusionResurveyProjectSaga({ payload }) {
    try {
        const { data = {} } = yield call(getMappedProjectUrl, payload);
        const { data: mappingData = [] } = data;
        const { mapping = [] } = mappingData;
        yield put(getProjectsSuccess(mapping));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getProjectsFailed({ message, error, status }));
    }
}

export function* getMappedDemographicsSaga({ payload }) {
    try {
        const { data = {} } = yield call(getMappedDemo, payload);
        const { data: mappingData = [] } = data;
        yield put(getMappedDemographicSuccess(mappingData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getMappedDemographicFailed({ message, error, status }));
    }
}

export function* putPastReportSaga({ payload }) {
    try {
        const { data = {} } = yield call(putReportPathUrl, payload);
        const { data: putData = {} } = data;
        yield put(putPastReportSuccess(putData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(putPastReportFailed({ message, error, status }));
    }
}

export function* getBulkScoreData({ payload }) {
    try {
        const { data = {} } = yield call(getScoreDataUrl, payload);
        const {
            data: {
                qbyq: { size },
            },
        } = data;
        const successPayload = payload.filters[0];
        const newSuccessDemo = { successKey: successPayload, nsize: size };
        yield put(getBulkScoreDataSuccess(newSuccessDemo));
    } catch ({ response = {} }) {
        const bulkPayload = payload.filters[0];
        const newDemos = { key: bulkPayload };
        yield put(getBulkScoreDataFailed(newDemos));
    }
}

const reportsSagas = [
    takeEvery(`${GET_REPORT}_PENDING`, getReportSaga),
    takeEvery(`${GET_RESURVEY_EXCEL}_PENDING`, getResurveyExcelSaga),
    takeEvery(`${GET_MAPPING_EXCEL}_PENDING`, getMappingExcelSaga),
    takeEvery(`${GET_BULK_SCORE_DATA}_PENDING`, getBulkScoreData),
    takeLatest(`${GET_RESPONDENTS_DATA}_PENDING`, getRespondentsSaga),
    takeLatest(`${GET_DATA_CUBE}_PENDING`, getDataCubeSaga),
    takeLatest(`${GET_PAST_REPORT}_PENDING`, getExportSaga),
    takeLatest(`${GET_PPT_RAW_DATA}_PENDING`, getRawPptSaga),
    takeLatest(`${GET_INCLUSION_DATA}_PENDING`, getInclusionDataSaga),
    takeLatest(`${GET_INCLUSION_DATA_RES}_PENDING`, getInclusionDataResSaga),
    takeLatest(`${GET_GAP_DATA}_PENDING`, getInclusionGapSaga),
    takeLatest(`${GET_RES_GAP_DATA}_PENDING`, getGapResSaga),
    takeLatest(`${GET_GAP_DATA_ALL}_PENDING`, getAllGapSaga),
    takeLatest(`${GET_RES_GAP_DATA_ALL}_PENDING`, getAllGapResSaga),
    takeLatest(`${PENDING_REPORTS_STATUS}_PENDING`, getReportStatusSaga),
    takeLatest(`${GET_ALL_GAP_INCLUSION_DATA}_PENDING`, getAllGapInclusionDataSaga),
    takeLatest(`${GET_RES_ALL_GAP_INCLUSION_DATA}_PENDING`, getResAllGapInclusionDataSaga),
    takeLatest(`${GET_RESURVEY_MAPPING}_PENDING`, getResurveyMappingSaga),
    takeLatest(`${GET_IA_MAPPED_DEMOGRAPHICS}_PENDING`, getMappedDemographicsSaga),
    takeLatest(`${GET_MAPPED_PROJECTS}_PENDING`, getInclusionResurveyProjectSaga),
    takeLatest(`${PUT_PAST_REPORT}_PENDING`, putPastReportSaga),
    takeLatest(`${GET_QBYQ_NSIZE}_PENDING`, getQbyqNsizeSaga),
    takeLatest(`${GET_MULTI_PPT_DATA}_PENDING`, getMultiPptDwnldSaga),
    takeLatest(`${SAVE_PPT_ZIP}_PENDING`, savePptZipSaga),
];

export default reportsSagas;
