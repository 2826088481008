import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Scorecard from './Scorecard';

import {
    selectAnalyticsApiCount,
    selectDataAtGlance,
    selectFilterParam,
    selectFilters,
    selectOutcomeData,
    selectScorecardDataFetched,
    selectScorecardDropdown,
    selectScorecardError,
} from '../selector';
import { selectScoreData } from '../../Diagnose/selector';
import { getDefaultSettings } from '../../Login/selector';
import { getScorecard, setDropdown } from '../actions';

const stateToProps = createStructuredSelector({
    analyticsApiCount: selectAnalyticsApiCount(),
    defaultSettings: getDefaultSettings(),
    outcomeData: selectOutcomeData(),
    scorecardDataFetched: selectScorecardDataFetched(),
    filters: selectFilters(),
    filterParam: selectFilterParam(),
    dropdown: selectScorecardDropdown(),
    scoreData: selectScoreData(),
    scorecardError: selectScorecardError(),
    dataAtGlance: selectDataAtGlance(),
});

const dispatchToProps = {
    getScorecard,
    setDropdown,
};

export default connect(stateToProps, dispatchToProps)(Scorecard);
