import React, { Fragment } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';

import { getStaticText } from '../../../../utils/constants';
import getStaticTextDiagnose from '../../constants';
import EnpsDemo from './EnpsDemo';
import OrgHealth from './OrgHealth';
import DeepDiveBoard from './DeepDiveBoard';

function DeepDive({
    filters,
    selectedDemographic,
    ieHeatmap,
    ieHeatmapFetched,
    getEmpExpFactor,
    sortIeHeatmap,
    defaultSettings,
    apiParams,
    getHeatMapPractice,
    heatMapPracticeFetched,
    heatMapPractice,
    callHeatMap,
    empExpHeatmaps,
    empExpOhiHeatmaps,
    getOhiScoreForEmp,
    crossTabDemo,
    setCrossTabDemo,
    selectedTab,
    setSelectedTab,
    getOrgHealthData,
    orgHealth,
    singleQuestion,
    orgHealthFetched,
    enpsFetched,
    apiLoadingCount,
    errorInfo,
    addOnTab,
    newDeepDiv,
    newDeepDive,
    newDeepDiving,
}) {
    const {
        demographic = '',
        survey_version = '',
        lang: langTxt = '',
        engagement = '',
        report_type: surveyType = '',
    } = defaultSettings;
    const { filters: filterParams } = apiParams || {};
    const staticTextDiagnose = getStaticTextDiagnose(langTxt);
    const { IE_CROSSTAB_OPTIONS, DEFAULT_STATE, ORGHEALTH_DEMO } = staticTextDiagnose;
    const staticText = getStaticText(langTxt);
    const { SITE_TEXT, NON_PROFIT_SURVEY } = staticText;
    const {
        PRACTICE_CATEGORY_MAP,
        PRACTICE_CATEGORY_MAP_3_2,
        EE_PERCENT_FAVORABLE,
        EE_PERCENTILE,
        NOTE,
        IND_EXP_QUESTION_NOTE,
        EE_OHI_PERCENT_FAVORABLE,
        EE_OHI_PERCENTILE,
    } = SITE_TEXT;
    const is3_2survey = survey_version === '3_2';
    const mapToUse = is3_2survey ? PRACTICE_CATEGORY_MAP_3_2 : PRACTICE_CATEGORY_MAP;

    const addOns = {
        fill_threshold_with: demographic === '1' ? '-' : '',
        others: demographic === '3' ? 1 : 0,
        columns: 10000,
    };
    const isNonProfit = engagement === NON_PROFIT_SURVEY;

    const getPage = () => {
        const defaultProps = {
            filters,
            selectedDemographic,
            ieHeatmap,
            ieHeatmapFetched,
            sortIeHeatmap,
            defaultSettings,
            apiParams,
            getHeatMapPractice,
            heatMapPracticeFetched,
            heatMapPractice,
            callHeatMap,
            selectedTab,
        };
        switch (crossTabDemo) {
            case DEFAULT_STATE:
                return <DeepDiveBoard {...defaultProps} />;
            case ORGHEALTH_DEMO:
                return (
                    <OrgHealth
                        orgHealth={orgHealth}
                        defaultSettings={defaultSettings}
                        singleQuestion={singleQuestion}
                        getOrgHealthData={getOrgHealthData}
                        filterParams={filterParams}
                        orgHealthFetched={orgHealthFetched}
                    />
                );
            default:
                return (
                    <EnpsDemo
                        filters={filters}
                        getEmpExpFactor={getEmpExpFactor}
                        selectedDemographic={selectedDemographic}
                        apiParams={apiParams}
                        defaultSettings={defaultSettings}
                        crossTabDemo={crossTabDemo}
                        empExpHeatmaps={empExpHeatmaps}
                        empExpOhiHeatmaps={empExpOhiHeatmaps}
                        getOhiScoreForEmp={getOhiScoreForEmp}
                        addOns={addOns}
                        enpsFetched={enpsFetched}
                    />
                );
        }
    };

    const tabOptionsToUse = crossTabDemo === DEFAULT_STATE ? mapToUse.slice(0, 3) : [];
    return (
        <Fragment>
            <div className="deepDiveHeading">
                {IE_CROSSTAB_OPTIONS.filter(({ code }) => code === crossTabDemo)[0].label}
            </div>
            <div className="buttonWrapper">
                <div className="buttonContent">
                    {tabOptionsToUse.map(({ title: tabVal }, tabIndex) => {
                        return (
                            <button
                                key={tabVal}
                                type="button"
                                className={c('separateButton', { isActive: tabIndex === selectedTab })}
                                onClick={() => setSelectedTab(tabIndex)}
                            >
                                {tabVal}
                            </button>
                        );
                    })}
                </div>
                <div className="rightOptions">
                    <span>
                        <Select
                            IconComponent={KeyboardArrowDownSharpIcon}
                            value={crossTabDemo}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                            }}
                            onChange={({ target: { value: code } }) => setCrossTabDemo(code)}
                        >
                            {IE_CROSSTAB_OPTIONS.map(({ label, code, nonProfitAccess }) => {
                                if (nonProfitAccess && isNonProfit) {
                                    return null;
                                }

                                return (
                                    <MenuItem classes={{ root: 'demographicOptions' }} key={code} value={code}>
                                        {label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </span>
                </div>
            </div>
            <div
                className={c(
                    { orgHealthBoard: crossTabDemo === ORGHEALTH_DEMO },
                    'demographicBoard clearfix clearPadding'
                )}
            >
                {getPage()}

                {!apiLoadingCount && !errorInfo && (
                    <div className="note">
                        <b>{NOTE}:</b>
                        <br />
                        {IND_EXP_QUESTION_NOTE[0]}
                        <br />
                        {IND_EXP_QUESTION_NOTE[1]}
                        <br />
                        {addOnTab !== 3 && surveyType === 'percent favorable' ? (
                            <div>3. {EE_PERCENT_FAVORABLE}</div>
                        ) : (
                            ''
                        )}
                        {addOnTab !== 3 && surveyType === 'percentile' ? <div>3. {EE_PERCENTILE}</div> : ''}
                        {addOnTab === 3 && surveyType === 'percent favorable' && newDeepDive ? (
                            <div>3. {EE_OHI_PERCENT_FAVORABLE}</div>
                        ) : (
                            ''
                        )}
                        {addOnTab === 3 && surveyType === 'percentile' && newDeepDive ? (
                            <div>3. {EE_OHI_PERCENTILE}</div>
                        ) : (
                            ''
                        )}
                        {addOnTab === 3 && newDeepDiving && surveyType === 'percent favorable' ? (
                            <div>3. {EE_PERCENT_FAVORABLE}</div>
                        ) : (
                            ''
                        )}
                        {addOnTab === 3 && newDeepDiving && surveyType === 'percentile' ? (
                            <div>3. {EE_PERCENTILE}</div>
                        ) : (
                            ''
                        )}
                        {addOnTab === 3 && crossTabDemo === ORGHEALTH_DEMO ? IND_EXP_QUESTION_NOTE[2] : ''}
                        <br />
                        {addOnTab === 3 && newDeepDiv && surveyType === 'percent favorable' ? (
                            <div>4. {EE_PERCENT_FAVORABLE}</div>
                        ) : (
                            ''
                        )}
                        {addOnTab === 3 && newDeepDiv && surveyType === 'percentile' ? (
                            <div>4. {EE_PERCENTILE}</div>
                        ) : (
                            ''
                        )}
                    </div>
                )}
            </div>
        </Fragment>
    );
}

DeepDive.propTypes = {
    filters: PropTypes.array.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
    selectedDemographic: PropTypes.string.isRequired,
    getHeatMapPractice: PropTypes.func.isRequired,
    heatMapPracticeFetched: PropTypes.bool.isRequired,
    heatMapPractice: PropTypes.object.isRequired,
    callHeatMap: PropTypes.func.isRequired,
    empExpHeatmaps: PropTypes.object.isRequired,
    empExpOhiHeatmaps: PropTypes.object.isRequired,
    getOhiScoreForEmp: PropTypes.func.isRequired,
    crossTabDemo: PropTypes.string.isRequired,
    setCrossTabDemo: PropTypes.func.isRequired,
    ieHeatmap: PropTypes.object.isRequired,
    ieHeatmapFetched: PropTypes.bool.isRequired,
    getEmpExpFactor: PropTypes.func.isRequired,
    sortIeHeatmap: PropTypes.func.isRequired,
    selectedTab: PropTypes.number.isRequired,
    setSelectedTab: PropTypes.func.isRequired,
    getOrgHealthData: PropTypes.func.isRequired,
    orgHealth: PropTypes.object.isRequired,
    singleQuestion: PropTypes.object.isRequired,
    orgHealthFetched: PropTypes.bool.isRequired,
    enpsFetched: PropTypes.bool.isRequired,
    errorInfo: PropTypes.number.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    newDeepDiving: PropTypes.bool.isRequired,
    newDeepDiv: PropTypes.bool.isRequired,
    newDeepDive: PropTypes.bool.isRequired,
    addOnTab: PropTypes.number.isRequired,
};

export default DeepDive;
