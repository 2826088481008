import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import PastReports from './PastReports';
import {
    getPastReport,
    getRawPptData,
    addPendingReport,
    putPastReport,
    updateTaskId,
    getMultiPptData,
    updatedPastRpts,
    resetPendingReport,
} from '../actions';
import { getPptDataStatus } from '../../Diagnose/actions';
import {
    selectPastReports,
    selectPendingReports,
    selectReportApiCount,
    selectShowMultiProgress,
    selectTaskId,
} from '../selector';
import { getDefaultSettings } from '../../Login/selector';
import { selectPptError, selectPptStatus, selectPptStatusFetching, selectProgressBar } from '../../Diagnose/selector';

const stateToProps = createStructuredSelector({
    showProgressBar: selectProgressBar(),
    pastReport: selectPastReports(),
    pendingReports: selectPendingReports(),
    reportApiCount: selectReportApiCount(),
    defaultSettings: getDefaultSettings(),
    pptError: selectPptError(),
    pptStatus: selectPptStatus(),
    pptStatusFetching: selectPptStatusFetching(),
    taskId: selectTaskId(),
    showMultiProgress: selectShowMultiProgress(),
});

const dispatchToProps = {
    getPastReport,
    getRawPptData,
    addPendingReport,
    putPastReport,
    getPptDataStatus,
    updateTaskId,
    getMultiPptData,
    updatedPastRpts,
    resetPendingReport,
};

export default connect(stateToProps, dispatchToProps)(PastReports);
