import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Tooltip, withStyles } from '@material-ui/core';
import c from 'classnames';
import { Helmet } from 'react-helmet';
import { cloneDeep } from 'lodash';
import AuthStore from '../../../common/AuthStore';
import FilterSvg from '../../Diagnose/assets/Filter.svg';
import ActiveFilter from '../../Diagnose/assets/ActiveFilters1.svg';
import { getStaticText } from '../../../utils/constants';
import './index.scss';
import Down from '../../Diagnose/assets/Down.svg';
import { getFilterName } from '../assets/functions';
import DisclaimerWarn from '../assets/DisclaimerWarning1.svg';
import MedianSplit from './MedianSplit';
import QuartileSplit from './QuartileSplit';
import WarningModal from '../Intro/WarningModal';

function DifferentiatorBoard({
    toggleFilterView,
    defaultSettings,
    getDifferential,
    differentialDataFetched,
    differentialData,
    renderfilterApplied,
    filterParams,
    setDifferentialData,
    tab,
    setTab,
    differentialError,
    getPptData,
    filtersApplied,
    filters,
    defaultYear,
    getMedian,
    medianData,
    medianDataFetched,
    dataAtGlance,
    getDifferentialQuartile,
    showQuartileBtn,
    showQuatileTabBtn,
    showMedianTabBtn,
    showMedianBtn,
    getDifferentialMedian,
    setMedianData,
    medianError,
    warningModal,
    setWarningModal,
}) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        FILTERS,
        PRACTICE_RANK_PAGE_TEXT,
        NUMBER_CAP_SURVEY_TEXT,
        NUMBER_TEXT,
        DOWNLOAD,
        GSOD_SIG,
        SCORECARD_NOTE,
        DISCLAIMER,
        DIFFERENTIATOR_ANALYSIS_TABS,
        RESTRUCTION_TOOLTIP,
        MEDIAN_RESTRUCTION_TOOLTIP,
        QUARTILE_TAB,
    } = SITE_TEXT || {};
    // const { n_survey = 0 } = rankingData || {};
    const { n_survey: differentialDataSize = 0, n_respondents = '' } = differentialData || {};
    const [showNewData, setShowNewData] = useState(false);
    const [showThresholdBtn, setShowThresholdBtn] = useState(false);
    const { gsodRole = '' } = AuthStore;
    const gsodRoles = gsodRole === 'AccessLevels.asset_leader';
    const { total_survey = '' } = dataAtGlance;
    const is24 = defaultYear === 2024;
    // const keys = filterParams.match(/\w+(?=\s*:)/g);
    // const keyCount = keys ? keys.length : 0;

    const getPpt = (slideArr = [], pptName = 'Differentiator') => {
        const { pptAccessToken = '' } = AuthStore;
        const output = {
            output_file_name: 'data.pptx',
            template_file_name: 'Template_OHIBMX_4_0.pptx',
            slides: slideArr,
        };
        getPptData(output, pptAccessToken, pptName);
    };

    const handleWarningModal = () => {
        setWarningModal(true);
    };

    // const [quartileValue, setQuartileValue] = useState(null);

    // useEffect(() => {
    //     if (total_survey) {
    //         const halfValue = total_survey / 2;
    //         const finalValue = halfValue / 2;
    //         setQuartileValue(finalValue);
    //     }
    //     // eslint-disable-next-line
    // }, []);

    const extractNSize = str => {
        const match = str.match(/N size: {2}(\d+)/);
        return match ? match[1] : null;
    };

    const nSize = differentialError.length > 0 && extractNSize(differentialError[0]);
    const nSizeCheckQuartile = nSize === null ? null : nSize <= 10;
    const handleThreshold = ({ checked }) => {
        const checkedCondition = checked;
        if (!checkedCondition) {
            getMedian({ filters: filterParams, remove_threshold: showNewData });
            showMedianTabBtn(false);
        }
        setShowNewData(checked);
    };

    const handleThresholdBtn = ({ checked }) => {
        const checkedCondition = checked;
        if (!checkedCondition) {
            getDifferential({ filters: filterParams, remove_threshold: showThresholdBtn });
            showQuatileTabBtn(false);
        }
        setShowThresholdBtn(checked);
    };

    const LightTooltip = withStyles(theme => ({
        arrow: {
            color: theme.palette.common.black,
        },
        tooltip: {
            backgroundColor: '#333333',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
        },
    }))(Tooltip);

    const dataObject = (row_index, value, style = 0, is_bold = 'false', alignment = 'center', rowspan) => {
        const obj = {
            row_index,
            column_index: 1,
            value,
            style,
            alignment,
            is_bold,
        };
        if (rowspan) {
            obj.rowspan = rowspan;
        }
        return obj;
    };

    const getDataForPpt = () => {
        const { records: mainData = [], n_size_tq = '', n_size_bq = '' } = differentialData || {};
        const slidesArr = [];
        const selectedSample = getFilterName(filters);
        const sampleExclYear = getFilterName(
            filters.filter(({ filter_id: id = '' }) => !(id === 'year' || id === 'survey_year'))
        );
        const dataHealthy = [
            {
                name: 'n_size',
                value: `1. Healthy organizations reflect the top 25% of OHI scores in the selected sample (n=${n_size_tq})`,
            },
            {
                name: 'Checkme',
                is_delete: true,
            },
            {
                name: 'Source',
                value: `Source: Subset of organizations classified as healthiest organizations in selected sample. Selected sample=${selectedSample} (n=${n_respondents} no. of surveys=${differentialDataSize})`,
            },
        ];
        const dataUnhealthy = [
            {
                name: 'n_size',
                value: `1. Unhealthy organizations reflect the top 25% of OHI scores in the selected sample (n=${n_size_bq})`,
            },
            {
                name: 'Checkme',
                is_delete: true,
            },
            {
                name: 'Source',
                value: `Source: Subset of organizations classified as unhealthiest organizations in selected sample. Selected sample=${selectedSample} (n=${n_respondents} no. of surveys=${differentialDataSize})`,
            },
        ];
        const barchart = [
            {
                name: 'Checkme',
                is_delete: true,
            },
            {
                name: 'healthy_TextBox',
                value: `Healthy ${sampleExclYear} organizations`,
            },
            {
                name: 'unhealthy_TextBox',
                value: `Unhealthy ${sampleExclYear} organizations`,
            },
            {
                name: 'Footnote',
                value: `1. Healthy organizations (n=${n_size_tq}) and unhealthy organizations (n=${n_size_bq}) reflect the top and bottom 25% of OHI scores in the selected sample, respectively ${'\n'}2. Practice(s) healthy organizations uniquely emphasize, meaning the practice is in the top 12 of the practice rank for top quartile organizations and at least +12 rank difference from unhealthy bottom quartile organizations’ practice rank ${'\n'}3. Practice(s) unhealthy organizations uniquely emphasize, meaning the practice is in the top 12 of the practice rank for bottom quartile organizations and at least -12 rank difference from healthy top quartile organizations’ practice rank`,
            },
            {
                name: 'Source',
                value: `Source: McKinsey & Company | People and Organizational Performance Practice | OHI database ${defaultYear}, n=${differentialDataSize}, # respondents=${n_respondents} | Filters: ${selectedSample}`,
            },
        ];

        let rank_top = [];
        let rank_middle = [];
        let rank_bottom = [];
        let col = 0;

        mainData.forEach(({ display_name }, i) => {
            if (i === 12 || i === 31) {
                col = 0;
            }
            if (i < 12) {
                rank_top.push(dataObject(col, display_name, 0, 'true'));
            } else if (i >= 12 && i < 31) {
                rank_middle.push(dataObject(col, display_name, 0));
            } else if (i >= 31) {
                rank_bottom.push(dataObject(col, display_name, 0));
            }
            col += 1;
        });
        dataHealthy.push(
            {
                name: 'rank_top',
                table_data: rank_top,
            },
            {
                name: 'rank_middle',
                table_data: rank_middle,
            },
            {
                name: 'rank_bottom',
                table_data: rank_bottom,
            }
        );
        const dataCopy = cloneDeep(mainData);
        const newItem = dataCopy.sort((a, b) => a.rank_bq - b.rank_bq);
        col = 0;
        rank_top = [];
        rank_middle = [];
        rank_bottom = [];
        newItem.forEach(({ display_name }, i) => {
            if (i === 12 || i === 31) {
                col = 0;
            }
            if (i < 12) {
                rank_top.push(dataObject(col, display_name, 0, 'true'));
            } else if (i >= 12 && i < 31) {
                rank_middle.push(dataObject(col, display_name, 0));
            } else if (i >= 31) {
                rank_bottom.push(dataObject(col, display_name, 0));
            }
            col += 1;
        });
        dataUnhealthy.push(
            {
                name: 'rank_top',
                table_data: rank_top,
            },
            {
                name: 'rank_middle',
                table_data: rank_middle,
            },
            {
                name: 'rank_bottom',
                table_data: rank_bottom,
            }
        );

        col = 0;
        mainData.forEach(({ display_name, rank_bq, rank_tq, sig_diff_tq_bq }) => {
            const { text = '' } = GSOD_SIG[sig_diff_tq_bq];
            if (text === 'stronger') {
                col += 1;
                barchart.push(
                    {
                        name: `healthy_${col}`,
                        value: display_name,
                    },
                    {
                        name: `healthy_${col}_tq`,
                        value: rank_tq,
                    },
                    {
                        name: `healthy_${col}_bq`,
                        value: rank_bq,
                    }
                );
            }
        });

        col += 1;
        while (col <= 12) {
            barchart.push(
                {
                    name: `healthy_${col}`,
                    is_delete: true,
                },
                {
                    name: `healthy_${col}_tq`,
                    is_delete: true,
                },
                {
                    name: `healthy_${col}_bq`,
                    is_delete: true,
                }
            );
            col += 1;
        }

        col = 0;
        mainData.forEach(({ display_name, rank_tq, rank_bq, sig_diff_tq_bq }) => {
            const { text = '' } = GSOD_SIG[sig_diff_tq_bq];
            if (text === 'weaker') {
                col += 1;
                barchart.push(
                    {
                        name: `unhealthy_${col}`,
                        value: display_name,
                    },
                    {
                        name: `unhealthy_${col}_tq`,
                        value: rank_tq,
                    },
                    {
                        name: `unhealthy_${col}_bq`,
                        value: rank_bq,
                    }
                );
            }
        });

        col += 1;
        while (col <= 12) {
            barchart.push(
                {
                    name: `unhealthy_${col}`,
                    is_delete: true,
                },
                {
                    name: `unhealthy_${col}_tq`,
                    is_delete: true,
                },
                {
                    name: `unhealthy_${col}_bq`,
                    is_delete: true,
                }
            );
            col += 1;
        }

        slidesArr.push(
            {
                identify_shape_name: 'Checkme',
                identify_shape_value: 'Differentiator_healthy',
                data: dataHealthy,
                style: [
                    {
                        bgcolor: '#ffffff',
                        text_color: '#000000',
                    },
                ],
            },
            {
                identify_shape_name: 'Checkme',
                identify_shape_value: 'Differentiator_unhealthy',
                data: dataUnhealthy,
                style: [
                    {
                        bgcolor: '#ffffff',
                        text_color: '#16537e',
                    },
                ],
            },
            {
                identify_shape_name: 'Checkme',
                identify_shape_value: 'Differentiator_barchart',
                data: barchart,
                style: [
                    {
                        bgcolor: '#ffffff',
                        text_color: '#16537e',
                    },
                ],
            }
        );
        getPpt([...slidesArr], `differentiator_analysis`);
    };

    const getPage = () => {
        switch (tab) {
            case 0:
                return (
                    <QuartileSplit
                        getDifferential={getDifferential}
                        differentialData={differentialData}
                        differentialDataFetched={differentialDataFetched}
                        filterParams={filterParams}
                        setDifferentialData={setDifferentialData}
                        defaultSettings={defaultSettings}
                        error={differentialError}
                        pracRankingTab={tab}
                        showThresholdBtn={showThresholdBtn}
                        dataAtGlance={dataAtGlance}
                        getDifferentialQuartile={getDifferentialQuartile}
                        showQuartileBtn={showQuartileBtn}
                        warningModal={warningModal}
                        setWarningModal={setWarningModal}
                    />
                );
            case 1:
                return (
                    <MedianSplit
                        getMedian={getMedian}
                        medianData={medianData}
                        medianDataFetched={medianDataFetched}
                        filterParams={filterParams}
                        setMedianData={setMedianData}
                        defaultSettings={defaultSettings}
                        error={medianError}
                        pracRankingTab={tab}
                        showNewData={showNewData}
                        showMedianBtn={showMedianBtn}
                        showMedianTabBtn={showMedianTabBtn}
                        getDifferentialMedian={getDifferentialMedian}
                        warningModal={warningModal}
                        setWarningModal={setWarningModal}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div className="practiceBoards clearfix">
                <div className="practiceContentFix">
                    <Helmet>
                        <title>{PRACTICE_RANK_PAGE_TEXT['5']}</title>
                    </Helmet>
                    <div className="rightScorecardHeader">
                        <div className="scorecardHeading">
                            <div className="mainHeading">Differentiator Analysis</div>
                            <div className="nRes">{`${NUMBER_CAP_SURVEY_TEXT} (${NUMBER_TEXT}) : ${
                                // tab === 0 ? n_survey : differentialDataSize
                                total_survey
                            }`}</div>
                        </div>
                        <ul className="navScoreList">
                            {tab === 0 && is24 && (
                                <li data-testid="getPptBtn" onClick={getDataForPpt}>
                                    <img src={Down} alt="" />
                                    <span className="lineHead upperHead">{DOWNLOAD}</span>
                                </li>
                            )}
                            <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                                <img src={!filtersApplied ? FilterSvg : ActiveFilter} alt="" />{' '}
                                <span className="filter">{FILTERS}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="disclaimerContent">
                        <img src={DisclaimerWarn} alt="" />
                        <div className="note scoreCardNote">
                            <span>{DISCLAIMER} : </span>
                            <div>{SCORECARD_NOTE}</div>
                            <div className="warningContent" onClick={handleWarningModal}>
                                Know more
                            </div>
                        </div>
                    </div>
                    {renderfilterApplied}
                    <div className="optionWrapper">
                        <div className="differentiatorToggle">
                            {gsodRoles ? (
                                <ul className="leftNav">
                                    {DIFFERENTIATOR_ANALYSIS_TABS.map((tabName, index) => (
                                        <li className={c({ activeLink: tab === index })} onClick={() => setTab(index)}>
                                            {tabName}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <ul className="leftNav">
                                    {QUARTILE_TAB.map((tabName, index) => (
                                        <li className={c({ activeLink: tab === index })} onClick={() => setTab(index)}>
                                            {tabName}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {tab === 1 && gsodRoles ? (
                                <div className="showPracticeToradoChart checkboxTip">
                                    <LightTooltip title={MEDIAN_RESTRUCTION_TOOLTIP} arrow>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showMedianBtn}
                                                    onChange={({ target }) => handleThreshold(target)}
                                                    name="tresholdData"
                                                    type="checkbox"
                                                    disabled={total_survey >= 20}
                                                />
                                            }
                                            label="Remove threshold restriction"
                                        />
                                    </LightTooltip>
                                </div>
                            ) : null}

                            {tab === 0 && gsodRoles ? (
                                <div className="showPracticeToradoChart checkboxTip">
                                    <LightTooltip title={RESTRUCTION_TOOLTIP} arrow>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showQuartileBtn}
                                                    onChange={({ target }) => handleThresholdBtn(target)}
                                                    name="tresholdData"
                                                    type="checkbox"
                                                    disabled={nSizeCheckQuartile === null}
                                                />
                                            }
                                            label="Remove threshold restriction"
                                        />
                                    </LightTooltip>
                                </div>
                            ) : null}
                        </div>
                        <div className="rightOptions">
                            {/* <div>
                        <ul className="rightSideNav">
                            <li className="strong">{`Number of Surveys (n) = ${n_survey}`}</li>
                            <li
                                onClick={() => toggleFilterView(true)}
                                data-testid="filterButton"
                                className="filterButton"
                            >
                                <img src={filtersApplied ? ActiveFilter : FilterSvg} alt="" />
                                {FILTERS}
                            </li>
                        </ul>
                    </div> */}
                        </div>
                    </div>
                </div>
                {getPage()}
            </div>

            {warningModal ? <WarningModal setWarningModal={setWarningModal} /> : null}
        </>
    );
}

DifferentiatorBoard.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    differentialData: PropTypes.object.isRequired,
    differentialDataFetched: PropTypes.bool.isRequired,
    getDifferential: PropTypes.func.isRequired,
    renderfilterApplied: PropTypes.string.isRequired,
    filterParams: PropTypes.string.isRequired,
    setDifferentialData: PropTypes.func.isRequired,
    tab: PropTypes.number.isRequired,
    setTab: PropTypes.func.isRequired,
    differentialError: PropTypes.array.isRequired,
    dataAtGlance: PropTypes.object.isRequired,
    filtersApplied: PropTypes.bool.isRequired,
    defaultYear: PropTypes.number.isRequired,
    showQuartileBtn: PropTypes.bool.isRequired,
    setMedianData: PropTypes.func.isRequired,
    showQuatileTabBtn: PropTypes.func.isRequired,
    showMedianBtn: PropTypes.bool.isRequired,
    showMedianTabBtn: PropTypes.func.isRequired,
    getDifferentialMedian: PropTypes.func.isRequired,
    getMedian: PropTypes.func.isRequired,
    medianData: PropTypes.object.isRequired,
    medianDataFetched: PropTypes.bool.isRequired,
    getDifferentialQuartile: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    getPptData: PropTypes.func.isRequired,
    medianError: PropTypes.array.isRequired,
    warningModal: PropTypes.bool.isRequired,
    setWarningModal: PropTypes.func.isRequired,
};

export default DifferentiatorBoard;
