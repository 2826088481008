import { createSelector } from 'reselect';

const login = state => state.get('login');

const selectLoginLoading = () => createSelector(login, state => state.toJS().count);
const selectUserData = () => createSelector(login, state => state.toJS().userData);
const getExpiryTime = () => createSelector(login, state => state.toJS().expiryTime);
const getDefaultSettings = () => createSelector(login, state => state.toJS().defaultSettings);
const selectPasswordUpdated = () => createSelector(login, state => state.toJS().passwordUpdated);
const selectResetToken = () => createSelector(login, state => state.toJS().resetToken);
const selectError = () => createSelector(login, state => state.toJS().error);
const selectDefaultSettingsFetched = () => createSelector(login, state => state.toJS().defaultSettingsFetched);

export {
    selectLoginLoading,
    selectUserData,
    getExpiryTime,
    getDefaultSettings,
    selectError,
    selectPasswordUpdated,
    selectResetToken,
    selectDefaultSettingsFetched,
};
