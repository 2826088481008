import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Dialog, IconButton, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Toast } from '@mds/mds-reactjs-library';
import AuthStore from '../../common/AuthStore';
import { getStaticText } from '../../utils/constants';
import './index.scss';

let timeoutAutoComplete;

function ProgressBarLoader({
    pptStatus,
    getPptDataStatus,
    pptStatusFetching,
    pptError,
    taskId,
    toggleProgressBar,
    defaultSettings,
    openStatusDialog,
    reportName,
}) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { pptAccessToken = '' } = AuthStore;
    const { BIG_PPT_ERROR, CLOSE_WINDOW_MSG } = SITE_TEXT || {};
    const messageRef = useRef();
    useEffect(() => {
        if (!pptStatusFetching && !pptError) {
            const timeToUse = pptStatus.length ? 2 : 1;
            clearTimeout(timeoutAutoComplete);
            timeoutAutoComplete = setTimeout(() => {
                getPptDataStatus(pptAccessToken, taskId);
            }, timeToUse * 1000);
        }
        // eslint-disable-next-line
    }, [pptStatusFetching, pptStatus]);

    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest',
            });
        }
        // eslint-disable-next-line
    }, [messageRef.current]);
    return (
        <div>
            {pptError ? (
                <div className="infoToastContainer">
                    <Toast type="warning" title="File is too big" onCloseClick={() => toggleProgressBar(false)}>
                        {BIG_PPT_ERROR}
                    </Toast>
                </div>
            ) : (
                <Fragment>
                    <div className="showBulkSection">
                        <Dialog open className="showStatusDialog" onClose={() => openStatusDialog(false)}>
                            <div className="pastReportHeading">
                                <span>{`We are downloading report "${reportName}"`}</span>
                                <IconButton className="reportIcon" onClick={() => openStatusDialog(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <DialogContent className="dialogBulkContent">
                                {pptStatus.map(string => {
                                    return (
                                        <DialogContentText ref={messageRef} className="dialogBulkText" component="span">
                                            <div className="reportDownldSection">
                                                <ul clasName="reportList">
                                                    <li className="ellipsisList">{string}</li>
                                                </ul>
                                            </div>
                                        </DialogContentText>
                                    );
                                })}
                            </DialogContent>
                            <DialogActions className="bulkDialogActions">{CLOSE_WINDOW_MSG}</DialogActions>
                        </Dialog>
                    </div>
                </Fragment>
            )}
        </div>
    );
}

ProgressBarLoader.propTypes = {
    taskId: PropTypes.number.isRequired,
    pptStatus: PropTypes.array.isRequired,
    pptError: PropTypes.bool.isRequired,
    pptStatusFetching: PropTypes.bool.isRequired,
    getPptDataStatus: PropTypes.func.isRequired,
    toggleProgressBar: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    openStatusDialog: PropTypes.object.isRequired,
    reportName: PropTypes.string.isRequired,
};

export default ProgressBarLoader;
