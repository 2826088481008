import { fromJS } from 'immutable';
import {
    GET_ACTION_LIST,
    UPDATE_ACTION_LIST,
    UPDATE_VALUES,
    GET_COMP_VALUES,
    RESET_ACTION_REDUX,
    ADD_NEW_ACTION,
} from './constants';
import { REMOVE_SAVED_TOKEN } from '../Login/constants';

export const INITIAL_STATE = fromJS({
    count: 0,
    error: '',
    optionList: fromJS({}),
    optionData: fromJS({}),
    compendiumData: fromJS({}),
    optionListFetched: false,
    compendiumDataFetched: false,
    actionError: '',
    actionLoading: false,
    actionCreated: false,
});

const reducer = (state = INITIAL_STATE, { type = '', payload = {} }) => {
    switch (type) {
        case `${GET_ACTION_LIST}_PENDING`:
        case `${GET_COMP_VALUES}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_ACTION_LIST}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('optionList', fromJS(payload.optionList))
                .set('optionData', fromJS(payload.optionData))
                .set('optionListFetched', true);
        }
        case `${GET_ACTION_LIST}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('optionList', fromJS({}))
                .set('optionData', fromJS({}))
                .set('optionListFetched', true);
        }
        case `${GET_COMP_VALUES}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('compendiumData', fromJS(payload))
                .set('compendiumDataFetched', true);
        }
        case `${GET_COMP_VALUES}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('compendiumData', fromJS({}))
                .set('error', payload.error)
                .set('compendiumDataFetched', true);
        }
        case UPDATE_ACTION_LIST: {
            return state.updateIn(['optionList', payload.innerKey], () => payload.innerValue);
        }
        case UPDATE_VALUES: {
            return state.updateIn(
                ['compendiumData', payload.pKey, 'compendiums', payload.cIndex, payload.key],
                () => payload.val
            );
        }
        case `${ADD_NEW_ACTION}_PENDING`: {
            return state.set('actionError', '').set('actionLoading', true);
        }
        case `${ADD_NEW_ACTION}_SUCCESS`: {
            return state.set('actionLoading', false).set('actionCreated', true);
        }
        case `${ADD_NEW_ACTION}_FAILED`: {
            return state.set('actionLoading', false).set('actionError', payload.error);
        }
        case RESET_ACTION_REDUX: {
            return state.set('actionError', '').set('actionCreated', false);
        }
        case REMOVE_SAVED_TOKEN: {
            if (!payload) {
                return INITIAL_STATE;
            }
            return state;
        }
        default:
            return state;
    }
};

export default reducer;
