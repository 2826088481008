import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import { Box } from '@material-ui/core';
import { getStaticText } from '../../../../utils/constants';
import { getColor, getNegColor } from '../../../../utils/functions';

const showProgress = (value, isNegative, IND_EXP_SCORE_CLASSIFICATION) => {
    const index = getColor(value, [], false, true);
    const indexNeg = getNegColor(value, [], false, true);
    const { positiveBg = '#000000', negativeBg = '#ffffff' } =
        IND_EXP_SCORE_CLASSIFICATION[[isNegative ? indexNeg : index]] || {};
    const progessStyle = {
        background: `
                    radial-gradient(closest-side, #ffffff 65%, transparent 0% 100%),
                    conic-gradient(${isNegative ? negativeBg : positiveBg} ${value}%, rgb(239, 238, 239) 0%)`,
    };
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <div className="progress-bar" style={progessStyle}>
                <progress value={value} min="0" max="100" style={{ height: 0, width: 0 }} />
                <div className="percentValue">{`${value}%`}</div>
            </div>
        </Box>
    );
};

function OrgHealth({ defaultSettings, orgHealth, singleQuestion, getOrgHealthData, filterParams, orgHealthFetched }) {
    const { lang, ohid, threshold } = defaultSettings || {};
    const staticText = getStaticText(lang);
    const { IND_EXP_SCORE_CLASSIFICATION, SITE_TEXT } = staticText;
    const {
        QUESTION_HEAD,
        OVERALL_SCORED,
        RESPONDENTS_WHO_RESPONDED,
        PERCEIVE_THE_ORGANIZATION_AS,
        HEALTHY,
        UNHEALTHY,
        EXRTREMELY_LIKELY,
        AGREE_OR_HIGHER,
    } = SITE_TEXT || {};

    const { records = {} } = orgHealth || {};
    const { qbyq = {} } = singleQuestion || {};
    const { records: qbyqRecords = {} } = qbyq || {};

    useEffect(() => {
        if (!orgHealthFetched) {
            getOrgHealthData({ ohid, lang, threshold, filters: filterParams });
        }
        // eslint-disable-next-line
    }, [orgHealthFetched]);

    return (
        <div className="orgHealth">
            <div className="orgHealthHeader">
                <div className="col1">{QUESTION_HEAD}</div>
                <div className="col2">{OVERALL_SCORED}</div>
                <div className="col3">{RESPONDENTS_WHO_RESPONDED} …</div>
                <div className="col4" />
                <div className="col5">
                    …{PERCEIVE_THE_ORGANIZATION_AS}… <br />
                    <ul className="clearfix">
                        <li>
                            {' '}
                            <span className="color healthy" />
                            {HEALTHY}
                        </li>
                        <li>
                            <span className="color unhealthy" />
                            {UNHEALTHY}
                        </li>
                    </ul>
                </div>
            </div>
            <div className="orgHealthBody">
                {Object.keys(records).map((key, i) => {
                    const { corelation, healthy, unhealthy } = records[key] || {};
                    const objToUse = key === 'enps' ? qbyqRecords.endorsing_organization_to_others : qbyqRecords[key];
                    const { meta_scores, score = '' } = objToUse || {};
                    const { qtext: question = '' } = meta_scores[0] || {};
                    return (
                        <div className="body" key={key}>
                            <div className="col1">{question}</div>
                            <div className="col2 percentProgress">
                                {showProgress(score, key === 'intent_to_leave', IND_EXP_SCORE_CLASSIFICATION)}
                            </div>
                            <div className="col3">
                                {i === 1 ? `"9" or "10" (${EXRTREMELY_LIKELY})` : `"4" ${AGREE_OR_HIGHER}`}
                            </div>
                            <div className="col4">
                                r<sub>xy</sub>={corelation} <sup>3</sup>
                            </div>
                            <div className="col5">
                                <div className="metaScore">
                                    <div className="chart">
                                        <span
                                            className={c({ gray: key !== 'intent_to_leave', negative: true })}
                                            style={{
                                                width: `${unhealthy}%`,
                                            }}
                                        >
                                            {unhealthy}
                                        </span>
                                    </div>
                                    <div className="chart">
                                        <span
                                            className={c({ gray: key === 'intent_to_leave' })}
                                            style={{
                                                width: `${healthy}%`,
                                            }}
                                        >
                                            {healthy}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

OrgHealth.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    orgHealth: PropTypes.object.isRequired,
    singleQuestion: PropTypes.object.isRequired,
    getOrgHealthData: PropTypes.func.isRequired,
    filterParams: PropTypes.array.isRequired,
    orgHealthFetched: PropTypes.bool.isRequired,
};

export default OrgHealth;
