import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getIeHeatmap, sortIeHeatmap } from '../../actions';

import {
    selectApiCount,
    getErrorInfo,
    selectFilters,
    selectSelectedDemographic,
    selectIeHeatmap,
    selectIeHeatmapFetched,
} from '../../selector';

import HeatmapBoard from './Heatmap';
import { getDefaultSettings } from '../../../Login/selector';

const stateToProps = createStructuredSelector({
    apiLoadingCount: selectApiCount(),
    errorInfo: getErrorInfo(),
    filters: selectFilters(),
    selectedDemographic: selectSelectedDemographic(),
    ieHeatmap: selectIeHeatmap(),
    ieHeatmapFetched: selectIeHeatmapFetched(),
    defaultSettings: getDefaultSettings(),
});

const dispatchToProps = { getIeHeatmap, sortIeHeatmap };

export default connect(stateToProps, dispatchToProps)(HeatmapBoard);
