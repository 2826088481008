import React, { useState } from 'react';
import { Toast } from '@mds/mds-reactjs-library';
import PropTypes from 'prop-types';
import { IA_CUSTOM_ERROR } from '../../containers/Inclusion/constants';
import { getStaticText } from '../../utils/constants';

import './index.scss';

export default function ErrorToast({ message }) {
    const [open, setOpen] = useState(true);
    const staticText = getStaticText();
    const { SITE_TEXT } = staticText;
    const { ERROR } = SITE_TEXT;

    return open ? (
        <div className="infoToastContainer">
            <Toast type="danger" title="Request processing error" onCloseClick={() => setOpen(false)}>
                {IA_CUSTOM_ERROR} <br /> <br />
                <span className="error">
                    {ERROR}: {message}
                </span>
            </Toast>
        </div>
    ) : null;
}

ErrorToast.propTypes = {
    message: PropTypes.string.isRequired,
};
