import React from 'react';
import loader from '../Loader/loader.svg';
import './index.scss';

const LoaderToast = () => {
    return (
        <div className="toastRoot">
            <div className="toastContent">
                <div className="iconContainer">
                    <img src={loader} alt="loading" />
                </div>
                <div className="textContainer">
                    <div className="head">Please wait a moment</div>
                    <div className="desc">
                        Survey list according to the selected filter is getting ready for further selection.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoaderToast;
