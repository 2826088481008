import React, { Fragment } from 'react';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import Detail from '../assets/Duplicate-large.svg';
// import Close from '../../../assets/Close.svg';
import './index.scss';

const DuplicateModal = ({ setView, duplicateCustomBm, customBmName }) => {
    return (
        <Dialog
            open
            disableEscapeKeyDown
            disableBackdropClick
            maxWidth="md"
            classes={{
                root: 'customBmDialogRoot',
            }}
        >
            <section className="infoContent">
                {/* <div style={{ float: 'right', cursor: 'pointer' }} onClick={() => setView(false)}>
                    <img src={Close} alt="" />
                </div> */}
                <div className="infoLarge">
                    <img src={Detail} alt="" />
                </div>
                <h2>Duplicate Custom Benchmark</h2>
                <div className="para">
                    <div>Are you sure to create duplicate of “{customBmName}” ?</div>
                    <div>
                        <b>Please note :</b> The attached OHI IDs to this benchmark will not be copied with the
                        duplicate benchmark.
                    </div>
                </div>
            </section>
            <section className="btnContent clearfix activeButtons multiBtn">
                <Fragment>
                    <div
                        data-testid="detailButton"
                        className="detailedBtn aciveDetailBtn"
                        onClick={() => {
                            setView(false);
                        }}
                    >
                        No, Cancel
                    </div>
                    <div
                        data-testid="compareButton"
                        className="compareBtn activeCompareBtn"
                        onClick={duplicateCustomBm}
                    >
                        Yes, Create
                    </div>
                </Fragment>
            </section>
        </Dialog>
    );
};

DuplicateModal.propTypes = {
    setView: PropTypes.func.isRequired,
    duplicateCustomBm: PropTypes.func.isRequired,
    customBmName: PropTypes.string.isRequired,
};

export default DuplicateModal;
