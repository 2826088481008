import React from 'react';
import PropTypes from 'prop-types';
import { getStaticTextAnalytics } from '../constants';
import CloudImage from '../assets/InsufficientData1.svg';

import './index.scss';

function BlankPage({ error, count, differentiateText }) {
    const staticTextAnalytics = getStaticTextAnalytics();
    const { SITE_TEXT } = staticTextAnalytics;
    const {
        ERROR_DEFAULT_TXT,
        LOGIN_DEFAULT_TXT,
        INSUFFICIENT_DATA,
        DATA_NOT_AVAILABLE,
        DIFFERENTIATOR_TEXT,
    } = SITE_TEXT;
    const differentiateTxts = differentiateText === 'median' || differentiateText === 'quartile' || '';
    return (
        <div className="blankSection">
            <div className="blankPageWrapper">
                {count === 1 || count === 0 || count === 2 ? (
                    <div className="textInfoWrapper">
                        <h2>{DATA_NOT_AVAILABLE}</h2>
                        {!differentiateTxts && <div className="errorDescription">{LOGIN_DEFAULT_TXT}</div>}
                        {differentiateTxts && <div className="errorDescription">{DIFFERENTIATOR_TEXT}</div>}
                    </div>
                ) : (
                    <div className="textInfoWrapper">
                        <h2>{INSUFFICIENT_DATA}</h2>
                        <div className="errorDescription">{error ? error[0] : ERROR_DEFAULT_TXT}</div>
                    </div>
                )}
                <div className="imageWrapper">
                    <img src={CloudImage} alt="" />
                </div>
            </div>
        </div>
    );
}

BlankPage.propTypes = {
    error: PropTypes.array.isRequired,
    pracTab: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    differentiateText: PropTypes.string.isRequired,
};

export default BlankPage;
