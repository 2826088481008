import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import './index.scss';
import {
    Button,
    FormControlLabel,
    FormGroup,
    Input,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from '@material-ui/core';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import CloudUpload from '../../../assets/Cloud-upload.svg';
import RemoveImage from '../../../assets/C-remove.svg';
import Warning from '../assets/T-warning.svg';
import SuccessDialog from './SuccessDialog';
import getStaticTextDiagnose from '../constants';

function ReportFeedback({
    sendFeedback,
    defaultSettings,
    handleClose,
    setView,
    tab,
    feedback,
    feedbackSuccess,
    resetFeedback,
    setTicketId,
    setOptions,
    options,
    resetFeedbackOptions,
}) {
    const { lang } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { FEEDBACK_ISSUES_OPTIONS, REPORT_FEEDBACK_LIST, REPORT_FEEDBACK_SITE_TEXT } = staticTextDiagnose;
    const [multipleFile, setMultipleFile] = useState([]);
    const [isUnique, setIsUnique] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [openConfirm, toggleConfirmModal] = useState(false);
    const [error, setError] = useState({ imageErr: '' });

    const { CONFIRM_TXT } = REPORT_FEEDBACK_SITE_TEXT;

    const uploadFile = ({ target }) => {
        const { files } = target;
        if (multipleFile.length <= 4 && files.length <= 4) {
            setMultipleFile([...multipleFile, ...files]);
            setError({
                imageErr: '',
            });
        } else {
            setError({
                imageErr: 'Include relevant screenshots (jpeg, png), up to 4 images or 5 mb.',
            });
        }
    };

    const { summary = '', description = '', type_of_bug = '', is_workaround, immediate_impact } = options || {};

    const validateInputs = () => {
        if (!summary || !description || !isUnique || !type_of_bug) {
            return setIsActive(false);
        }
        setIsActive(true);
        return null;
    };

    const errorMessage = message => {
        return (
            <Fragment>
                <span style={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}>
                    <img src={Warning} alt="" />
                </span>
                <span style={{ color: 'red' }}>{message}</span>
            </Fragment>
        );
    };

    const { imageErr = '' } = error || {};

    const handleChange = ({ target }) => {
        const { value, name } = target || {};
        const valToUse = name === 'immediate_impact' || name === 'is_workaround' ? value === 'yes' : value;
        // debugger
        // const valToUse = value;
        setOptions({
            ...options,
            [name]: valToUse,
        });
    };

    const handleCheckbox = ({ target }) => {
        const { checked } = target;
        setIsUnique(checked);
    };

    const handleSubmit = () => {
        const os = window.navigator.userAgentData.platform;
        const formData = new FormData();
        Object.keys(options).forEach(key => {
            formData.append(key, options[key]);
        });
        formData.append('other_detail', os);
        multipleFile.forEach(file => {
            formData.append('files', file);
        });
        sendFeedback(formData);
    };

    useEffect(() => {
        validateInputs();
        // eslint-disable-next-line
    }, [isUnique, options]);

    useEffect(() => {
        if (feedbackSuccess) {
            toggleConfirmModal(true);
        }
        // eslint-disable-next-line
    }, [feedbackSuccess]);

    const IS_WORKAROUND_OPT = [
        {
            label: 'Yes',
            key: 'yes',
        },
        {
            label: 'No',
            key: 'no',
        },
    ];

    const IMMEDIATE_IMPACT = [
        {
            label: 'Yes, an immediate impact',
            key: 'yes',
        },
        {
            label: 'No',
            key: 'no',
        },
    ];

    const handleModalClose = () => {
        toggleConfirmModal(false);
        handleClose();
        resetFeedbackOptions();
    };

    return (
        <Fragment>
            <div className="optionWrapper">
                <div>
                    <ul className="leftNav clearfix">
                        {REPORT_FEEDBACK_LIST.map((tabName, tabIndex) => {
                            return (
                                <li
                                    key={tabName + tabIndex}
                                    className={c({ activeLink: tabIndex === tab })}
                                    onClick={() => setView(tabIndex)}
                                >
                                    {tabName}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            <div className="scrollSection">
                <div className="sectionHead">
                    <h3>Report an issue</h3>
                    <div>
                        A issue is when functionality does not operate as intended. For example, when sorting BUs from
                        high to low, the sizes associated with each BU fail to reorder accordingly. Entering all the key
                        details in this form helps ensure that issues are effectively managed, assigned and resolved in
                        a timely manner.
                    </div>
                </div>
                <form>
                    <div>
                        <div>
                            <div className="title">
                                <span>
                                    Title<sup>*</sup>
                                </span>
                            </div>
                            <Input
                                placeholder="Enter title for the issue"
                                type="text"
                                name="summary"
                                onChange={handleChange}
                                value={summary}
                                maxLength="50"
                            />
                            <div className="subtext">Write a clear and concise description of the issue.</div>
                        </div>
                        <div>
                            <div className="title">
                                <span>
                                    Type of bug<sup>*</sup>
                                </span>
                            </div>
                            {/* <select>
							<option>Data Issue</option>
						</select> */}
                            <Select
                                IconComponent={KeyboardArrowDownSharpIcon}
                                value={type_of_bug}
                                variant="outlined"
                                displayEmpty
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                name="type_of_bug"
                                onChange={handleChange}
                            >
                                <MenuItem classes={{ root: 'demographicOptions' }} value="" disabled>
                                    <em>Select bug type</em>
                                </MenuItem>
                                {FEEDBACK_ISSUES_OPTIONS.map((label, code) => {
                                    return (
                                        <MenuItem
                                            classes={{ root: 'demographicOptions' }}
                                            key={code + label}
                                            value={label}
                                        >
                                            {label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            <div className="subtext">
                                Categorizing the issue allows the Product team to identify critical areas of
                                improvement, draw insights and capacity planning. Data issue / Calculation error / PPT
                                issue / Any other (user input field)
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="title">
                                <span>
                                    Describe the Issue<sup>*</sup>
                                </span>
                            </div>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                inputProps={{
                                    'data-testid': 'description',
                                }}
                                name="description"
                                value={description}
                                onChange={handleChange}
                                placeholder="Enter your feedback"
                            />
                            <div className="subtext">
                                Describe the issue or problem that occurred when the bug was encountered. Provide
                                detailed steps to replicate the issue. This will help the development team understand
                                and reproduce the problem.
                            </div>
                        </div>
                        <div>
                            <div className="title">
                                Add Image
                                {multipleFile.length ? (
                                    <label htmlFor="file-upload" className="custom-file-upload">
                                        <input
                                            id="file-upload"
                                            type="file"
                                            className="imageIp"
                                            name="images"
                                            accept="image/*"
                                            multiple
                                            onChange={uploadFile}
                                        />
                                        <img src={CloudUpload} alt="" />
                                        UPLOAD IMAGE
                                    </label>
                                ) : null}
                            </div>
                            <div className="imagePreview">
                                {multipleFile.map((file, i) => {
                                    return (
                                        <span>
                                            {' '}
                                            <img src={URL.createObjectURL(file)} alt="" />
                                            <img
                                                className="closeImage"
                                                src={RemoveImage}
                                                alt=""
                                                onClick={() =>
                                                    setMultipleFile(multipleFile.filter((_, index) => index !== i))
                                                }
                                            />
                                        </span>
                                    );
                                })}
                                {!multipleFile.length ? (
                                    <label htmlFor="file-upload" className="custom-file-upload-btn">
                                        <input
                                            id="file-upload"
                                            type="file"
                                            className="imageIp"
                                            name="images"
                                            accept="image/*"
                                            multiple
                                            onChange={uploadFile}
                                        />
                                        <img src={CloudUpload} alt="" />
                                        Upload image
                                    </label>
                                ) : null}
                            </div>
                            <div className="subtext">
                                {!imageErr ? (
                                    <Fragment>
                                        {' '}
                                        Include relevant screenshots (jpeg, png), up to 4 images or 5 mb.
                                    </Fragment>
                                ) : (
                                    errorMessage(imageErr)
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="title">
                                <span>
                                    Will there be an immediate impact on the ability to serve client?<sup>*</sup>
                                </span>
                            </div>
                            <FormGroup classes={{ root: 'radioGroup' }}>
                                <RadioGroup
                                    aria-label="filterOption"
                                    name="immediate_impact"
                                    value={immediate_impact ? 'yes' : 'no'}
                                    onChange={handleChange}
                                >
                                    {IMMEDIATE_IMPACT.map(({ label, key }, i) => (
                                        <FormControlLabel
                                            key={`${label}${i}`}
                                            value={key}
                                            control={<Radio disableRipple />}
                                            label={label}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormGroup>
                            <div />
                        </div>
                        <div>
                            <div className="title">
                                <span>
                                    Is there an workaround that you can do for now?<sup>*</sup>
                                </span>
                            </div>
                            <FormGroup classes={{ root: 'radioGroup' }}>
                                <RadioGroup
                                    aria-label="filterOption"
                                    name="is_workaround"
                                    value={is_workaround ? 'yes' : 'no'}
                                    onChange={handleChange}
                                >
                                    {IS_WORKAROUND_OPT.map(({ label, key }, i) => (
                                        <FormControlLabel
                                            key={`${key}${i}`}
                                            value={key}
                                            control={<Radio disableRipple />}
                                            label={label}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormGroup>
                        </div>
                    </div>
                </form>
            </div>
            <div className="buttonContent clearfix">
                <span>
                    <input type="checkbox" value={isUnique} onChange={handleCheckbox} />
                    <span>{CONFIRM_TXT}</span>
                </span>
                <Button onClick={handleClose} className="active">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} className={c({ active: isActive })}>
                    Send Feedback
                </Button>
            </div>
            {openConfirm && (
                <SuccessDialog
                    setView={setView}
                    feedback={feedback}
                    toggleConfirmModal={handleModalClose}
                    resetFeedback={resetFeedback}
                    setTicketId={setTicketId}
                />
            )}
        </Fragment>
    );
}

ReportFeedback.propTypes = {
    handleClose: PropTypes.func.isRequired,
    sendFeedback: PropTypes.func.isRequired,
    defaultSettings: PropTypes.func.isRequired,
    setView: PropTypes.func.isRequired,
    tab: PropTypes.number.isRequired,
    feedback: PropTypes.number.isRequired,
    feedbackSuccess: PropTypes.bool.isRequired,
    resetFeedback: PropTypes.func.isRequired,
    setTicketId: PropTypes.func.isRequired,
    setOptions: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    resetFeedbackOptions: PropTypes.func.isRequired,
};

export default ReportFeedback;
