import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { Helmet } from 'react-helmet';

import AuthStore from '../../../common/AuthStore';
import FilterSvg from '../assets/Filter.svg';
// import Close from '../../../assets/Close.svg';
import { getStaticText } from '../../../utils/constants';
import { getPptValue, getBenchmarkName, getPptValues, replacePssKeys } from '../../../utils/functions';
import getStaticTextDiagnose from '../constants';
import colors from '../../../sass/colors';
import './index.scss';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import Down from '../assets/Down.svg';
import ActiveFilters1 from '../assets/ActiveFilters1.svg';
import DwnldModal from '../../../components/InformationToast/DwnldModal';

function Profile({
    scoreData,
    scoreDataFetched,
    getScoreData,
    toggleFilterView,
    apiParams,
    apiLoadingCount,
    activeFilter,
    errorInfo,
    currentTheme,
    filters,
    getPptData,
    defaultSettings,
    appliedFiltersInfo,
    getReport,
    getRawPptData,
    reportError,
    getSinglePastReport,
    singlePastReport,
}) {
    const {
        quartileColors = [],
        report_name = '',
        report_name_or_client_name: rName = '',
        template_name: template_file_name,
        lang,
        report_id = '',
        checkme_mapping = {},
        survey_version = '',
        demographic = '',
        is_ohi_pss_module,
    } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { PROFILE_TILE_KEYS, PERCENTILE, PROFILE_CATEGORIES } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { SINGLE_PAGE_EXPORT_PARAMS, SITE_TEXT, PROFILE_REPLACEMENTS } = staticText;

    const updatedCheckmeMapping = replacePssKeys(checkme_mapping, is_ohi_pss_module, PROFILE_REPLACEMENTS, 'profile');
    const {
        PROFILE_HEAD,
        FILTERS,
        BENCHMARK_HEAD,
        DOWNLOAD,
        PPT_RES_TEXT,
        PPT_RES_TEXT2,
        PPT_RES_TEXT3,
        SOURCE_TEXT,
        NUMBER_TEXT,
        NUMBER_SURVEY_TEXT,
        ALL_PPT_TEXT,
        PPT_VALUES,
        BENCHMARK_NA,
        OUT_PRT,
        HEALTH_PROFILE_PAGE,
        NUMBER_OF_RESPONDENTS_N,
    } = SITE_TEXT || {};
    const { qbyq = {} } = scoreData;
    const { records = {}, size, n_respondents, n_survey } = qbyq;
    const { ohi_score, ...dataToIterate } = records;
    const { $white, $black } = colors;
    const { report_type } = apiParams;
    const [dwnld, setdwnld] = useState(false);
    // const [selectedTab, setSelectedTab] = useState(4);
    const selectedTab = 4;
    const isPercentile = report_type === PERCENTILE;
    const { labelName = '', imgSrc = '', categories = [] } = PROFILE_CATEGORIES[selectedTab] || {};
    const isOhi4 = survey_version === '4' || survey_version === '3_2';
    const filterCheck = apiParams.filters || [];

    const getPpt = (outcomeArray = [], prtArray = []) => {
        const { pptAccessToken = '' } = AuthStore;
        const output = {
            output_file_name: 'data.pptx',
            template_file_name,
            slides: [
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'outcome_profile_checkme',
                    data: outcomeArray,
                },
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'practice_profile_checkme',
                    data: prtArray,
                },
            ],
        };
        getPptData(output, pptAccessToken, `${report_name} ${OUT_PRT} Profile`);
    };

    const getDataForPpt = () => {
        const { GLOBAL_VALS, profile } = PPT_VALUES({ rName });
        const { benchmarks = [] } = filters[0];
        const label = getBenchmarkName(benchmarks);
        const pptArray = [
            {
                name: 'source',
                value: `${SOURCE_TEXT}: ${rName} (${NUMBER_TEXT}=${size}); ${BENCHMARK_HEAD}: ${label} (${NUMBER_TEXT}=${n_respondents}, ${NUMBER_SURVEY_TEXT}=${n_survey})`,
            },
            {
                name: 'Checkme',
                is_delete: 'true',
            },
            ...getPptValues(GLOBAL_VALS),
        ];

        const outcomeArray = [
            ...pptArray,
            {
                name: 'Title_text',
                value: profile.outcomeTitle,
            },
            {
                name: '3. Subtitle',
                value: isPercentile ? PPT_RES_TEXT3 : PPT_RES_TEXT,
            },
            {
                name: 'Rectangle 3',
                value: ALL_PPT_TEXT[23],
            },
        ];
        const prtArray = [
            ...pptArray,
            {
                name: 'Title_text',
                value: profile.practiceTitle,
            },
            {
                name: '3. Subtitle',
                value: isPercentile ? PPT_RES_TEXT3 : PPT_RES_TEXT2,
            },
        ];

        Object.keys(dataToIterate).forEach(innerContent => {
            const valueInRecord = records[innerContent];
            const { children = {}, display_name = '' } = valueInRecord;
            const parentArray = getPptValue(quartileColors, valueInRecord, innerContent, true, false);
            outcomeArray.push(...parentArray);
            prtArray.push({
                name: `${innerContent}_text`,
                value: display_name,
            });
            Object.keys(children).forEach(childVal => {
                const valueInChild = children[childVal];
                const childArray = getPptValue(quartileColors, valueInChild, childVal, false, true);
                prtArray.push(...childArray);
            });
        });
        getPpt(outcomeArray, prtArray);
    };

    const v4PptDownload = () => {
        const addOns = {
            fill_threshold_with: demographic === '1' ? '-' : '',
            others: demographic === '3' ? 1 : 0,
        };
        const { benchmarks = [] } = filters[0];
        const label = getBenchmarkName(benchmarks);
        const valueToSend = {
            ...SINGLE_PAGE_EXPORT_PARAMS({ BENCHMARK_NAME: label }),
            ...addOns,
            report_name: rName,
            ...apiParams,
            report_id,
            single_page_meta: {
                checkme: updatedCheckmeMapping.profile,
                name: 'profile',
            },
        };
        setdwnld(true);
        getReport(valueToSend);
    };

    useEffect(() => {
        if (!scoreDataFetched && !apiLoadingCount) {
            getScoreData(apiParams);
        }
        // eslint-disable-next-line
    }, [scoreDataFetched, apiParams]);

    return (
        <div className="scoreBoard profileContent clearfix">
            <Helmet>
                <title>{HEALTH_PROFILE_PAGE}</title>
            </Helmet>

            <div className="rightScorecardHeader">
                <div className="scorecardHeading">
                    <div className="mainHeading">{PROFILE_HEAD}</div>
                    <div className="nRes">
                        {NUMBER_OF_RESPONDENTS_N} : <span>{size.toLocaleString('en-US')}</span>
                    </div>
                </div>
                <ul className="navScoreList">
                    {!errorInfo && (
                        <li
                            data-testid="getPptButton"
                            onClick={isOhi4 ? v4PptDownload : getDataForPpt}
                            className="resurveyImg"
                        >
                            <img src={Down} alt="" />
                            <span className="lineHead upperHead">{DOWNLOAD}</span>
                        </li>
                    )}
                    <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                        <img src={activeFilter && filterCheck.length > 0 ? ActiveFilters1 : FilterSvg} alt="" />
                        <span className="filter">{FILTERS}</span>
                    </li>
                </ul>
            </div>

            {/* <div className="buttonWrapper">
                <div className="buttonContent">
                    {PROFILE_CATEGORIES.map(({ labelName: tabVal }, tabIndex) => {
                        return (
                            <button
                                key={tabVal}
                                type="button"
                                className={c('separateButton', { isActive: tabIndex === selectedTab })}
                                onClick={() => setSelectedTab(tabIndex)}
                            >
                                {tabVal}
                                {selectedTab === tabIndex ? (
                                    <div
                                        onClick={e => {
                                            e.stopPropagation();
                                            setSelectedTab(4);
                                        }}
                                    >
                                        <img src={Close} alt="" />
                                    </div>
                                ) : null}
                            </button>
                        );
                    })}
                </div>
            </div> */}
            {errorInfo && <ErrorToast message={errorInfo} />}
            {dwnld && !reportError && (
                <DwnldModal
                    getRawPptData={getRawPptData}
                    reportId={report_id}
                    open={dwnld}
                    setOpen={setdwnld}
                    getSinglePastReport={getSinglePastReport}
                    singlePastReport={singlePastReport}
                    defaultSettings={defaultSettings}
                />
            )}
            {Object.keys(scoreData).length ? (
                <Fragment>
                    {appliedFiltersInfo()}
                    {selectedTab === 4 ? (
                        <section className="scoreData clearfix">
                            {PROFILE_TILE_KEYS.map(dataSet => {
                                const {
                                    display_name = '',
                                    score = '',
                                    score_percentile = '',
                                    children = {},
                                    quartile = '4',
                                } = dataToIterate[dataSet] || {};
                                const { background = $white, color, border: borderColor = colors.$grey200 } =
                                    quartileColors[quartile] || {};
                                return (
                                    <div key={dataSet} className="scoreTile clearfix">
                                        <h4 className={c('ellipsisPro', { active: currentTheme.includes(dataSet) })}>
                                            <div
                                                style={{
                                                    background: isOhi4
                                                        ? `linear-gradient(135deg, ${borderColor} 25%, ${background} 25%)`
                                                        : background,
                                                    color,
                                                    borderColor: isOhi4 ? 'transparent' : borderColor,
                                                    borderWidth: quartile === '' ? 1 : 2,
                                                    borderStyle: isOhi4 ? 'none' : 'solid',
                                                }}
                                                className="triangleOutcome"
                                            >
                                                <div>{score_percentile || score}</div>
                                            </div>

                                            {display_name}
                                        </h4>
                                        <ul>
                                            {Object.keys(children).map(childSet => {
                                                const {
                                                    display_name: childName = '',
                                                    score: childScore = '',
                                                    score_percentile: childScorePercentile = '',
                                                    quartile: childQuartile = '',
                                                } = children[childSet];
                                                const {
                                                    background: childBg = $white,
                                                    color: childColor = $black,
                                                    border: childBorder = colors.$grey200,
                                                } = quartileColors[childQuartile] || {};
                                                const ohi4Border = isOhi4 && childQuartile !== '999';
                                                return (
                                                    <li
                                                        style={{
                                                            background: ohi4Border
                                                                ? `linear-gradient(135deg, ${childBorder} 5%, ${childBg} 5%)`
                                                                : childBg,
                                                            color: childColor,
                                                            borderColor: ohi4Border ? 'transparent' : childBorder,
                                                            borderWidth: quartile === '' ? 1 : 2,
                                                            borderStyle: ohi4Border ? 'none' : 'solid',
                                                        }}
                                                        className={c('ellipsisPro', {
                                                            active: currentTheme.includes(childSet),
                                                        })}
                                                        key={childName}
                                                    >
                                                        <div>
                                                            {childName}
                                                            <span>{childScorePercentile || childScore}</span>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                );
                            })}
                        </section>
                    ) : null}
                    {selectedTab < 4 ? (
                        <section className="scoreData scoreDataCategory">
                            <div className="categoryImgContainer">
                                <img src={imgSrc} alt="" />
                                <div>
                                    <h2>{labelName}</h2>
                                </div>
                            </div>
                            <div>
                                <div className="categoryTiles">
                                    {categories.map(dataSet => {
                                        const {
                                            display_name = '',
                                            score = '',
                                            score_percentile = '',
                                            children = {},
                                            quartile = '4',
                                        } = dataToIterate[dataSet] || {};
                                        const { background = $white, color, border: borderColor = colors.$grey200 } =
                                            quartileColors[quartile] || {};
                                        return (
                                            <div key={dataSet} className="scoreTile clearfix">
                                                <h4
                                                    className={c('ellipsisPro', {
                                                        active: currentTheme.includes(dataSet),
                                                    })}
                                                >
                                                    <span style={{ background, color, borderColor }}>
                                                        {score_percentile || score}
                                                    </span>
                                                    {display_name}
                                                </h4>
                                                <ul>
                                                    {Object.keys(children).map(childSet => {
                                                        const {
                                                            display_name: childName = '',
                                                            score: childScore = '',
                                                            score_percentile: childScorePercentile = '',
                                                            quartile: childQuartile = '4',
                                                        } = children[childSet];
                                                        const {
                                                            background: childBg = $white,
                                                            color: childColor = $black,
                                                            border: childBorder = colors.$grey200,
                                                        } = quartileColors[childQuartile] || {};
                                                        return (
                                                            <li
                                                                style={{
                                                                    background: childBg,
                                                                    color: childColor,
                                                                    borderColor: childBorder,
                                                                    borderWidth: childQuartile === '' ? 1 : 2,
                                                                }}
                                                                className={c('ellipsisPro', {
                                                                    active: currentTheme.includes(childSet),
                                                                })}
                                                                key={childName}
                                                            >
                                                                {childName}
                                                                <span>{childScorePercentile || childScore}</span>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </section>
                    ) : null}
                    {isOhi4 ? (
                        <ul className="benchmark clearfix">
                            <li>{BENCHMARK_HEAD} : </li>
                            {quartileColors
                                .slice()
                                .reverse()
                                .map(({ title, background, border: borderColor }) => {
                                    const bottomDecileVar = title === 'Bottom decile';
                                    const BenchmarkTitle = title === 'No benchmarks';
                                    return (
                                        <li
                                            key={title}
                                            style={{ color: background }}
                                            className={c({
                                                hide: bottomDecileVar || BenchmarkTitle,
                                            })}
                                        >
                                            <span
                                                className="benchmarkTriangle"
                                                style={{
                                                    background,
                                                    borderWidth: '13px 13px 0 0',
                                                    borderColor: bottomDecileVar
                                                        ? `transparent ${borderColor} transparent transparent`
                                                        : `${borderColor} transparent transparent transparent`,
                                                    borderStyle: 'solid',
                                                }}
                                            />
                                            <div style={{ color: 'black' }}>{title}</div>
                                        </li>
                                    );
                                })}
                            <li>
                                <span style={{ background: colors.$white, border: '1.5px solid #e6e6e6' }} />
                                {BENCHMARK_NA}
                            </li>
                        </ul>
                    ) : (
                        <ul className="benchmark clearfix">
                            <li>{BENCHMARK_HEAD} : </li>
                            {quartileColors
                                .slice()
                                .reverse()
                                .map(({ title, background, border: borderColor }) => {
                                    const bottomDecileVar = title === 'Bottom decile';
                                    const BenchmarkTitle = title === 'No benchmarks';
                                    return (
                                        <li
                                            key={title}
                                            style={{ color: background }}
                                            className={c({
                                                hide: bottomDecileVar || BenchmarkTitle,
                                            })}
                                        >
                                            <span
                                                className="benchmarkTriangle"
                                                style={{
                                                    background,
                                                    borderWidth: '13px 13px 0 0',
                                                    border: borderColor,
                                                    borderStyle: 'solid',
                                                }}
                                            />
                                            <div style={{ color: 'black' }}>{title}</div>
                                        </li>
                                    );
                                })}
                            <li>
                                <span style={{ background: colors.$white, border: '1.5px solid #e6e6e6' }} />
                                {BENCHMARK_NA}
                            </li>
                        </ul>
                    )}
                </Fragment>
            ) : null}
        </div>
    );
}

Profile.propTypes = {
    errorInfo: PropTypes.string.isRequired,
    currentTheme: PropTypes.string.isRequired,
    apiParams: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    scoreData: PropTypes.object.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    scoreDataFetched: PropTypes.bool.isRequired,
    filters: PropTypes.array.isRequired,
    getScoreData: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    getPptData: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    reportError: PropTypes.string.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    singlePastReport: PropTypes.object.isRequired,
};

export default Profile;
