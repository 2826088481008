import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Select, MenuItem } from '@material-ui/core';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import c from 'classnames';
import { Helmet } from 'react-helmet';

import FilterSvg from '../assets/Filter.svg';
import { getStaticText } from '../../../utils/constants';
import { assignGraphColorPractice, assignGraphColorPracticeOhi4 } from '../../../utils/functions';
import { GraphLegend, UpperLegends } from './Legends';
import AcrossPractices from './AcrossPractices';
import AcrossBus from './AcrossBus';
import DragDrop from './DragDrop';
import Download from '../assets/Down.svg';
import Excel, { getExport } from './Excel';
import './index.scss';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import ActiveFilters1 from '../assets/ActiveFilters1.svg';

const Cohesion = ({
    cohesionScore,
    cohesionData,
    getCohesionData,
    apiParams,
    cohesionDemographic,
    setCohesionDemographic,
    filters,
    error,
    activeFilter,
    apiLoadingCount,
    toggleFilterView,
    setCohesionSort,
    cohesionDataFetched,
    defaultSettings,
    appliedFiltersInfo,
}) => {
    const [showDragDrop, updateModal] = useState(false);
    const [value, setValue] = useState(1);
    const { ohid, filters: appliedFilters } = apiParams;
    const { cors = {}, res_BU = {} } = cohesionData;
    const { demographics = [] } = filters[0] || {};
    const overallScore = cohesionScore;
    const { lang, survey_version } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const filterCheck = apiParams.filters || [];
    const isOhi4 = survey_version === '4' || survey_version === '3_2';
    const {
        COHESION_HEADING,
        COHESION_SUB_HEADING,
        FILTERS,
        CUSTOMIZE_COHESION,
        COHESION_TAB1,
        COHESION_TAB2,
        HEALTH_COHESION_BOARD,
        DOWNLOAD_TEXT,
    } = SITE_TEXT || {};

    const payloadData = {
        ohid,
        lang: parseInt(lang, 10),
        type: 'practice',
        demographic: cohesionDemographic,
        threshold: 50,
        columns: 500,
        page: 1,
        percentile: 0,
        filters: appliedFilters,
    };

    const handleDemoChange = ({ target: { value: code } }) => {
        getCohesionData({
            ...payloadData,
            demographic: code,
        });
        setCohesionDemographic(code);
    };

    const closeAndUpdate = (header_seq = [], rows_seq = []) => {
        const dataToSend = {
            ohid,
            pid: '',
            demographic: cohesionDemographic,
            filters: [],
            header_seq,
            rows_seq,
        };
        setCohesionSort(dataToSend);
        updateModal(false);
    };

    useEffect(() => {
        const { filters: newFilters } = apiParams;
        if (!cohesionDataFetched && !apiLoadingCount) {
            getCohesionData({
                ...payloadData,
                filters: newFilters,
            });
        }
        // eslint-disable-next-line
    }, [cohesionDataFetched, apiParams]);

    const { lineGraphColor, textColor } = isOhi4
        ? assignGraphColorPracticeOhi4(overallScore)
        : assignGraphColorPractice(overallScore);

    return (
        <Box className="scoreBoard cohesionContent clearfix">
            <Helmet>
                <title>{HEALTH_COHESION_BOARD}</title>
            </Helmet>
            <Box component="h2">
                {COHESION_HEADING}
                {/* {isOhi4 && (
                    <div className="nRes">
                        {NUMBER_OF_RESPONDENTS_N}: <span>{size.toLocaleString('en-US')}</span>
                    </div>
                )} */}
                <div className="rightOptions">
                    <div className="demoFilter">
                        <Select
                            IconComponent={KeyboardArrowDownSharpIcon}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={cohesionDemographic}
                            onChange={handleDemoChange}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            {demographics.map(({ label, code }) => {
                                return (
                                    <MenuItem classes={{ root: 'demographicOptions' }} key={code} value={code}>
                                        {label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </div>
                    <ul className="rightNavList">
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton" className="filterBtn">
                            <img
                                className="imgView"
                                src={activeFilter && filterCheck.length > 0 ? ActiveFilters1 : FilterSvg}
                                alt=""
                            />
                            <span className="filter">{FILTERS}</span>
                        </li>
                    </ul>
                </div>
            </Box>
            {appliedFiltersInfo()}
            {error && <ErrorToast message={error} />}
            <Box className="cohesion-box">
                <p className="page-sub-heading">{COHESION_SUB_HEADING}</p>
                <Box className="overallscore">
                    <UpperLegends defaultSettings={defaultSettings} />
                    <Box className="square" style={{ background: lineGraphColor, color: textColor }}>
                        <Box id="cohesionScore">{overallScore}</Box>
                    </Box>
                </Box>
                {!error && (
                    <div className="root">
                        <div className="optionWrapper">
                            <div>
                                <ul>
                                    {[COHESION_TAB1, COHESION_TAB2].map((tab, index) => {
                                        return (
                                            <li
                                                onClick={() => setValue(index)}
                                                className={c({ activeLink: value === index })}
                                                data-testid={tab}
                                            >
                                                {tab}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div className="rightOptions">
                                {value === 1 && !error ? (
                                    <ul className="navScoreList">
                                        <li className="download-exl" data-testid="downloadExl" onClick={getExport}>
                                            <img src={Download} alt="" />
                                            {DOWNLOAD_TEXT}
                                        </li>
                                        <li
                                            className={c('dragModalBtn', { activeFilter })}
                                            data-testid="dragModalBtn"
                                            onClick={() => updateModal(true)}
                                        >
                                            {CUSTOMIZE_COHESION}
                                        </li>
                                    </ul>
                                ) : null}
                            </div>
                        </div>
                        {value === 0 ? (
                            <AcrossPractices practices={res_BU} defaultSettings={defaultSettings} />
                        ) : (
                            <AcrossBus res_BU={res_BU} cors={cors} defaultSettings={defaultSettings} />
                        )}
                    </div>
                )}
            </Box>
            {!error && <GraphLegend defaultSettings={defaultSettings} />}
            <div className="table2export">
                <Excel res_BU={res_BU} cors={cors} defaultSettings={defaultSettings} />
            </div>
            {showDragDrop && (
                <DragDrop
                    defaultSettings={defaultSettings}
                    res_BU={res_BU}
                    cors={cors}
                    closeModal={updateModal}
                    closeAndUpdate={closeAndUpdate}
                />
            )}
        </Box>
    );
};

Cohesion.propTypes = {
    error: PropTypes.string.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    filters: PropTypes.array.isRequired,
    cohesionData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
    cohesionScore: PropTypes.number.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    cohesionDemographic: PropTypes.string.isRequired,
    cohesionDataFetched: PropTypes.bool.isRequired,
    getCohesionData: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    setCohesionSort: PropTypes.func.isRequired,
    setCohesionDemographic: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
};
export default Cohesion;
