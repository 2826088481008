import { fromJS } from 'immutable';

export const INITIAL_STATE = fromJS({
    count: 0,
    status: 0,
});

const loaderReducer = (state = INITIAL_STATE, { type = '', payload = {} }) => {
    const requestStatus = type.split('_').reverse()[0];
    switch (requestStatus) {
        case 'TOKEN':
            return state.set('count', 0).set('status', 0);
        case 'PENDING':
            return payload.callApi ? state.set('count', 0) : state.update('count', count => count + 1);
        case 'SUCCESS':
            return state.update('count', count => count - 1);
        case 'FAILED': {
            const { status = 0 } = payload;
            const statusToSet = [401, 403].includes(state.status) ? state.status : parseInt(status, 10);
            return state.update('count', count => count - 1).set('status', statusToSet);
        }
        default:
            return state;
    }
};

export default loaderReducer;
