import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { Dialog, IconButton, Select, MenuItem } from '@material-ui/core';
import { ExpandMore, Close as CloseIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import c from 'classnames';

import Loader from '../Loader';
import { getStaticText } from '../../utils/constants';
import './index.scss';

function InclusionSelectModal({
    projects,
    projectsFetched,
    closeModal,
    selectProject,
    oldSelectedId,
    oldSelectedDemo,
    defaultSettings,
    heatMapResurvey = false,
    demographics,
    current_ohid,
    getMappedDemographics,
    apiLoadingCount,
    modalType,
    demographicMappedData,
}) {
    const { lang, ohid: ohId = '' } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        SELECT_DEFAULT,
        SELECT_ONE,
        RESURVEY_TEXT,
        SELECT_YEAR_INCLUSION,
        NO_MAPPED_DEMO,
        DONE,
        CLOSE_RESURVEY_TEXT,
        CONFIRMATION_TEXT,
        CONFIRMATION_BTN,
    } = SITE_TEXT || {};
    const [currentSelect, updateSelect] = useState('');
    const [selectedDemographic, setSelectedDemographic] = useState('');
    const { mappedDemoFetched: isDataFetched = false } = projects[currentSelect] || {};

    const getReportDemographics = newIndex => {
        if (newIndex !== '') {
            updateSelect(newIndex);
            setSelectedDemographic('');
            if (heatMapResurvey) {
                const { ohid: prevOhid = '' } = projects[newIndex];
                getMappedDemographics({ ohid: ohId, prevOhid });
            }
        }
    };

    const demographicsInUse = useMemo(() => {
        if (!heatMapResurvey) return [];
        const mappedDemos = demographicMappedData.map(({ current_demographic_parent: demo = '' }) => demo);
        if (!mappedDemos.length) {
            return [];
        }
        return demographics.filter(({ code }) => mappedDemos.indexOf(code) !== -1);
        // eslint-disable-next-line
    }, [heatMapResurvey, projects, demographics, currentSelect]);

    useEffect(() => {
        updateSelect(oldSelectedId);
        setSelectedDemographic(oldSelectedDemo);
        // eslint-disable-next-line
    }, []);

    const isActive = heatMapResurvey ? currentSelect !== '' && selectedDemographic : currentSelect !== '';
    const activeButtons = modalType === 2 ? true : isActive;

    return (
        <Dialog
            open
            disableBackdropClick
            maxWidth="sm"
            classes={{
                root: 'inclSelectDialogRoot',
            }}
        >
            {!projectsFetched || apiLoadingCount ? (
                <Loader position="absolute" showBackground background="transparent" />
            ) : null}
            <section className="headContent">
                <IconButton
                    aria-label="close"
                    classes={{
                        root: 'closeButton',
                    }}
                    onClick={() => closeModal(false)}
                >
                    <CloseIcon />
                </IconButton>
                <h2>{modalType === 1 ? RESURVEY_TEXT : CLOSE_RESURVEY_TEXT}</h2>
            </section>
            {modalType === 1 && (
                <section className={c('dataContent', { heatMapResurvey })}>
                    <p>{SELECT_YEAR_INCLUSION}</p>
                    <Select
                        value={currentSelect}
                        onChange={({ target }) => getReportDemographics(target.value)}
                        IconComponent={ExpandMore}
                        data-testid="selectModalDropdown"
                        // displayEmpty
                    >
                        <MenuItem classes={{ root: 'selectOptions' }} value="">
                            {SELECT_DEFAULT}
                        </MenuItem>
                        {projects.map(({ ohid, year }, menuIndex) => {
                            if (heatMapResurvey && ohid === current_ohid) {
                                return null;
                            }
                            return (
                                <MenuItem
                                    key={`${ohid}${year}`}
                                    value={menuIndex}
                                    classes={{ root: 'selectOptions' }}
                                    data-testid={`project${menuIndex}`}
                                >
                                    {`${ohid} - (${year})`}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {heatMapResurvey && (
                        <Select
                            IconComponent={ExpandMore}
                            value={selectedDemographic}
                            displayEmpty
                            onChange={({ target: { value: code } }) => {
                                setSelectedDemographic(code);
                            }}
                        >
                            <MenuItem classes={{ root: 'demographicOptions' }} value="">
                                <em>{SELECT_ONE}</em>
                            </MenuItem>
                            {demographicsInUse.map(({ label, code }) => {
                                return (
                                    <MenuItem classes={{ root: 'demographicOptions' }} key={code} value={code}>
                                        {label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    )}
                    {heatMapResurvey && isDataFetched && !demographicsInUse.length && (
                        <div className="noMapDemo">{NO_MAPPED_DEMO}</div>
                    )}
                </section>
            )}
            {modalType === 2 && (
                <>
                    <section className={c('dataContent')}>
                        <p>{CONFIRMATION_TEXT}</p>
                    </section>
                    <div className="confirm" />
                </>
            )}
            <section className={c('buttonContent clearfix', { activeButtons })}>
                {heatMapResurvey ? (
                    <div onClick={() => selectProject(currentSelect, selectedDemographic)}>{DONE}</div>
                ) : (
                    <Fragment>
                        <div data-testid="doneButton" onClick={() => selectProject(currentSelect, 1)}>
                            {modalType === 1 ? DONE : CONFIRMATION_BTN}
                        </div>
                    </Fragment>
                )}
            </section>
        </Dialog>
    );
}

InclusionSelectModal.defaultProps = {
    current_ohid: '',
    oldSelectedDemo: '',
    heatMapResurvey: false,
    getMappedDemographics: () => {},
    apiLoadingCount: 0,
    demographics: [],
    modalType: -1,
    demographicMappedData: [],
};

InclusionSelectModal.propTypes = {
    oldSelectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    projects: PropTypes.array.isRequired,
    projectsFetched: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    selectProject: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    oldSelectedDemo: PropTypes.string,
    demographics: PropTypes.array,
    current_ohid: PropTypes.string,
    heatMapResurvey: PropTypes.bool,
    getMappedDemographics: PropTypes.func,
    apiLoadingCount: PropTypes.number,
    modalType: PropTypes.number,
    demographicMappedData: PropTypes.array,
};

export default InclusionSelectModal;
