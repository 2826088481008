import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { ArrowBack } from '@material-ui/icons';
import { Helmet } from 'react-helmet';

import { Tooltip } from '@material-ui/core';
// import AuthStore from '../../../common/AuthStore';
import ActiveFilters1 from '../assets/ActiveFilters1.svg';
import FilterSvg from '../assets/Filter.svg';
import Down from '../assets/Down.svg';
import Check from '../assets/C-check.svg';
import DisclaimerWarn from '../../Analytics/assets/DisclaimerWarning1.svg';
import { getStaticText } from '../../../utils/constants';
import { getBenchmarkName, getPptValue } from '../../../utils/functions';
import getStaticTextDiagnose from '../constants';
import colors from '../../../sass/colors';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import WarningDialog from '../RecipeBoard/WarningDialog';
import DwnldModal from '../../../components/InformationToast/DwnldModal';
import SelectBenchmark from '../../../components/SelectBenchmark/SelectBenchmark';

function TargetSetting({
    setReport,
    scoreData,
    errorInfo,
    toggleFilterView,
    activeResetFilter,
    scoreDataFetched,
    getTargetScoreData,
    // currentTheme,
    // getPptData,
    // benchmarks,
    defaultSettings,
    // isPercentile,
    appliedFiltersInfo,
    // localPractice,
    apiLoadingCount,
    apiParams,
    targetBm,
    updateSize,
    updateSurveyRes,
    updateSurveys,
    getReport,
    getRawPptData,
    reportError,
    singlePastReport,
    getSinglePastReport,
    targetBenchmarks,
    updateTargetSettingBm,
}) {
    const {
        quartileColors,
        // report_name = '',
        report_name_or_client_name: rName = '',
        // template_name: template_file_name,
        lang,
        survey_version,
        // report_type = '',
        report_id = '',
        ohi_text = '',
        ohid,
        year = '',
        demographic = '',
    } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { OUTCOME_VAR, PRACTICE_VAR } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, SINGLE_PAGE_EXPORT_PARAMS } = staticText;
    const isOhi4 = survey_version === '4' || survey_version === '3_2';
    const [dwnld, setdwnld] = useState(false);
    const {
        SCORECARD,
        FILTERS,
        // BENCHMARK_HEAD,
        DOWNLOAD,
        // DIFF_HEAD,
        // PPT_RES_TEXT,
        // PPT_RES_TEXT2,
        // PPT_RES_TEXT3,
        // BU_HEAD_TITLE,
        // SOURCE_TEXT,
        // NUMBER_TEXT,
        // NUMBER_SURVEY_TEXT,
        // COMPANY_HEAD,
        // ALL_PPT_TEXT,
        // SIGNIFICANCE,
        // PPT_VALUES,
        // SIGNIFICANCES,
        // HEALTH_BU_BOARD,
        // PERCENT_FAVORABLE,
        // PERCENTILES,
        OUTCOMES,
        DYNAMIC_TEXT_INFO,
        PRACTICES,
        SURVEY_NAME,
        TARGET_SETTING,
        TARGET_QUARTILE,
        TARGET_HEADER,
    } = SITE_TEXT || {};
    const { OHI_SCORE_TEXT } = DYNAMIC_TEXT_INFO({ ohi_text });
    const { qbyq = {} } = scoreData || {};
    const { records = {}, size, n_respondents_target_setting, n_survey_target_setting } = qbyq || {};
    const addOns = {
        fill_threshold_with: demographic === '1' ? '-' : '',
        others: demographic === '3' ? 1 : 0,
    };
    // const {
    //     score: ohiScore = [],
    //     difference: ohiDiff = [],
    //     quartile: ohiQuartile = [],
    //     significance: ohiSign = [],
    // } = ohi_score;
    // const headerContent = [...ohiScore, ohiDiff[1]];
    // const { pptAccessToken = '', buName = '' } = AuthStore;
    // const isIngClient = String(report_id) === ING_REPORT_ID;
    const [demographicCurrentTab, handleTabData] = useState(OUTCOME_VAR);
    const [warning, setWarning] = useState(true);
    const [optionsToUse, setOptionsToUse] = useState(targetBenchmarks);

    // const surveyV = survey_version === '4' || survey_version === '3_2';

    // const getPpt = (outcomeArray = [], prtArray = []) => {
    //     const { GLOBAL_VALS, practice_profile_bu_checkme } = PPT_VALUES({ rName, buName });
    //     const { Title_text } = practice_profile_bu_checkme;
    //     const label = getBenchmarkName(benchmarks);
    //     const footNote = isIngClient ? [{ name: 'footnote', value: localPractice }] : [];
    //     const pptArray = [
    //         {
    //             name: 'source',
    //             value: `${SOURCE_TEXT}: ${rName} (${NUMBER_TEXT}=${nSize[0]}); ${buName} (${NUMBER_TEXT}=${nSize[1]}); ${BENCHMARK_HEAD}: ${label} (${NUMBER_TEXT}=${n_respondents}, ${NUMBER_SURVEY_TEXT}=${n_survey})`,
    //         },
    //         {
    //             name: 'Checkme',
    //             is_delete: 'true',
    //         },
    //         ...footNote,
    //         ...getPptValues(GLOBAL_VALS),
    //     ];
    //     const data = [
    //         ...pptArray,
    //         {
    //             name: 'Title_text',
    //             value: `${rName} ${ALL_PPT_TEXT[7]} ${buName}`,
    //         },
    //         {
    //             name: 'report_title',
    //             value: `${rName} ${ALL_PPT_TEXT[8]}`,
    //         },
    //         {
    //             name: 'comparison_title',
    //             value: `${buName} ${ALL_PPT_TEXT[8]}`,
    //         },
    //     ];
    //     ohiScore.forEach((value, ohiIndex) => {
    //         const preName = !ohiIndex ? 'overall' : 'overallBU';
    //         const arrowName = !ohiIndex ? 'RightArrow' : 'RightArrowBU';
    //         const { background: bgcolor, border: border_color, top_position } =
    //             quartileColors[ohiQuartile[ohiIndex]] || {};
    //         data.push(
    //             {
    //                 name: `${preName}_bg`,
    //                 bgcolor,
    //                 border_color,
    //             },
    //             {
    //                 name: `${preName}_value`,
    //                 text_color: bgcolor,
    //                 value,
    //             },
    //             {
    //                 name: arrowName,
    //                 top_position,
    //                 bgcolor,
    //             }
    //         );
    //     });

    //     const output = {
    //         output_file_name: 'data.pptx',
    //         template_file_name,
    //         slides: [
    //             {
    //                 identify_shape_name: 'Checkme',
    //                 identify_shape_value: 'profile_bu_checkme',
    //                 data,
    //             },
    //             {
    //                 identify_shape_name: 'Checkme',
    //                 identify_shape_value: 'outcome_profile_bu_checkme',
    //                 data: [
    //                     ...outcomeArray,
    //                     ...pptArray,
    //                     {
    //                         name: 'Title_text',
    //                         value: `${rName} ${ALL_PPT_TEXT[9]} ${buName}`,
    //                     },
    //                     {
    //                         name: 'report_title',
    //                         value: `${rName} ${ALL_PPT_TEXT[10]}`,
    //                     },
    //                     {
    //                         name: 'comparison_title',
    //                         value: `${buName} ${ALL_PPT_TEXT[10]}`,
    //                     },
    //                     {
    //                         name: '3. Subtitle',
    //                         value: isPercentile ? PPT_RES_TEXT3 : PPT_RES_TEXT,
    //                     },
    //                 ],
    //             },
    //             {
    //                 identify_shape_name: 'Checkme',
    //                 identify_shape_value: 'practice_profile_bu_checkme',
    //                 data: [
    //                     ...prtArray,
    //                     ...pptArray,
    //                     {
    //                         name: 'Title_text',
    //                         value: Title_text,
    //                     },
    //                     {
    //                         name: 'report_title',
    //                         value: COMPANY_HEAD,
    //                     },
    //                     {
    //                         name: 'comparison_title',
    //                         value: buName,
    //                     },
    //                     {
    //                         name: 'report_size',
    //                         value: nSize[0],
    //                     },
    //                     {
    //                         name: 'comparison_size',
    //                         value: nSize[1],
    //                     },
    //                     {
    //                         name: '3. Subtitle',
    //                         value: isPercentile ? PPT_RES_TEXT3 : PPT_RES_TEXT2,
    //                     },
    //                 ],
    //             },
    //         ],
    //     };
    //     getPptData(output, pptAccessToken, `${report_name} BU Data`);
    // };

    const getDataForPpt = () => {
        const label = getBenchmarkName(targetBenchmarks);
        const valueToSend = {
            ...SINGLE_PAGE_EXPORT_PARAMS({ BENCHMARK_NAME: label }),
            ...addOns,
            report_name: rName,
            ...apiParams,
            benchmarks: targetBm,
            report_id,
            single_page_meta: {
                checkme: ['target_setting_outcome', 'Target_setting_practices'],
                name: 'scorecard',
            },
        };
        getReport(valueToSend);
        setdwnld(true);
    };

    // const headerToUse = ['Third Quartile', 'Second Quartile', 'Top Quartile', 'Top decile'];

    const renderElement = val => {
        return val === '0 - 0' ? (
            <Fragment>
                <span>
                    <img src={Check} alt="" className="checkIcon" />
                </span>
            </Fragment>
        ) : (
            <Fragment>{val}</Fragment>
        );
    };

    const getPractices = () => {
        let obj = {};
        const outcomeNames = [];
        Object.keys(records).forEach(elem => {
            const { children = {}, display_name } = records[elem] || {};
            obj = { ...obj, ...children };
            if (display_name !== OHI_SCORE_TEXT) {
                outcomeNames.push({ name: display_name, len: Object.keys(children).length });
            }
        });
        return { practices: obj, outcomeNames };
    };

    const { practices, outcomeNames } = getPractices();
    // const borderIndex = outcomeNames.map(({ len }, i, arr) => len);

    const getHeapMapValues = items => {
        const itemsToIterate = Object.keys(items);
        return (
            <Fragment>
                <div>
                    {itemsToIterate.map((item, itemIndex) => {
                        const { display_name: rowTitle = '', quartile_target_setting: quartile = '', score = '' } =
                            items[item] || {};
                        const { background: bcground = colors.$white, border: border0 = '' } =
                            quartileColors[quartile] || {};
                        const title = rowTitle;
                        // const { len } = outcomeNames[itemIndex];
                        return (
                            <Fragment>
                                {!itemIndex && (
                                    <div className="sectionDiv headerWrapper" key="header">
                                        <ul>
                                            <li className="sectionContent headerList" />
                                            <li className="contentList firstCol bold headerList">OHI Score</li>
                                        </ul>
                                    </div>
                                )}
                                <div
                                    className={c(
                                        { ohiScore: rowTitle === OHI_SCORE_TEXT, firstRow: !itemIndex },
                                        'sectionDiv'
                                    )}
                                    key={`${title}${itemIndex}`}
                                >
                                    <ul>
                                        <Tooltip placement="top" arrow title={title} aria-label={title}>
                                            <li className="sectionContent">
                                                {title}
                                                {/* {rowTitle === N_SIZE || rowTitle === OHI_SCORE_TEXT ? (
                                                    <img
                                                        className="arrowSort"
                                                        src={UpDownEnabled}
                                                        alt=""
                                                        onClick={() => {
                                                            sortData(rowTitle);
                                                        }}
                                                    />
                                                ) : null} */}
                                            </li>
                                        </Tooltip>
                                        <li
                                            className={c({ bold: !quartileColors[quartile] }, 'contentList firstCol')}
                                            key={score}
                                            style={{
                                                border:
                                                    quartile === '0' || quartile === 0
                                                        ? '1px solid rgb(255, 216, 0)'
                                                        : '1px solid #f2f2f2',
                                                background: bcground,
                                                color: quartileColors[quartile]
                                                    ? quartileColors[quartile].color
                                                    : colors.$black100,
                                            }}
                                        >
                                            {isOhi4 && (
                                                <div
                                                    className="triangle"
                                                    style={{
                                                        borderWidth: '3vh 3vh 0 0',
                                                        borderColor: `${border0} transparent transparent transparent`,
                                                        position: 'absolute',
                                                        top: '0',
                                                        left: '0',
                                                        borderStyle: 'solid',
                                                    }}
                                                />
                                            )}
                                            {!isNaN(parseInt(score, 10))
                                                ? parseInt(score, 10).toLocaleString('en-US')
                                                : '-'}
                                        </li>
                                    </ul>
                                </div>
                            </Fragment>
                        );
                    })}
                </div>
                <div className="scrollSection">
                    {itemsToIterate.map((elem, itemIndex) => {
                        const { display_name: title = '', additional_stats = {} } = items[elem] || {};
                        const subItems = Object.keys(additional_stats) || {};
                        return (
                            <Fragment>
                                {!itemIndex && (
                                    <div className="sectionDiv headerWrapper" key="header">
                                        <ul>
                                            {TARGET_HEADER.map((item, index) => {
                                                const demoColName =
                                                    TARGET_HEADER.length && TARGET_HEADER[index].length > 25
                                                        ? TARGET_HEADER[index].slice(0, 20) + '...'
                                                        : TARGET_HEADER[index];
                                                return (
                                                    <Tooltip title={TARGET_HEADER[index]}>
                                                        <li className="contentList headerList" key={`header_${index}`}>
                                                            {demoColName}
                                                        </li>
                                                    </Tooltip>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                )}
                                <div
                                    className={c(
                                        { ohiScore: title === OHI_SCORE_TEXT, firstRow: !itemIndex },
                                        'sectionDiv'
                                    )}
                                    key={`${title}${itemIndex}`}
                                >
                                    <ul>
                                        {subItems.map((innerItem, i) => {
                                            const { range_start, range_end } = additional_stats[3 - innerItem] || {};
                                            const innerScore = `${range_start} - ${range_end}`;
                                            const background = colors.$white;
                                            const color = colors.$black100;
                                            const borderColor = '2px solid #f2f2f2';
                                            return (
                                                <>
                                                    <li
                                                        className={c(
                                                            { bold: color === colors.$black100 },
                                                            'contentList'
                                                        )}
                                                        key={`${innerScore}${i}${Math.random()}`}
                                                        style={{
                                                            border: borderColor,
                                                            background,
                                                            color,
                                                        }}
                                                    >
                                                        {renderElement(innerScore)}
                                                    </li>
                                                </>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </Fragment>
                        );
                    })}
                </div>
            </Fragment>
        );
    };

    useEffect(() => {
        if (!scoreDataFetched) {
            getTargetScoreData({ ...apiParams, target_setting_required: 1, benchmarks: targetBm });
        } else {
            updateSize([size, 0]);
            updateSurveyRes([n_respondents_target_setting, 0]);
            updateSurveys([n_survey_target_setting, 0]);
        }
        // eslint-disable-next-line
    }, [scoreDataFetched]);

    return (
        <Fragment>
            <div className="scoreBoard reSurveyReportBoard clearfix" data-testid="buBoardContent">
                <Helmet>
                    <title>{TARGET_SETTING}</title>
                </Helmet>
                <div className="breadCrumb">
                    <span data-testid="setScoreFromBuBtn" onClick={() => setReport(0)}>
                        {SCORECARD}
                    </span>{' '}
                    {' > '} {TARGET_SETTING}
                </div>
                {/* <h2 className="clearfix">
                    <span data-testid="setBackFromBuBtn" onClick={() => setReport(0)}>
                        <ArrowBack />
                        Target Setting
                    </span>

                    <ul className="rightSideNav">
                        <li data-testid="getPptButton" onClick={getDataForPpt}>
                            <img src={DownloadIcon} alt="" /> {DOWNLOAD}
                        </li>
                        <li data-testid="buBoardFilterBtn" onClick={() => toggleFilterView(true)}>
                            <img src={activeResetFilter ? ActiveFilters1 : FilterSvg} alt="" />
                            {FILTERS}
                        </li>
                        <div className="nRes">
                            {SURVEY_NAME} :
                            <span>
                                {ohid} ({year})
                            </span>
                        </div>
                    </ul>
                </h2> */}
                <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <div className="mainHeading">
                            <span data-testid="setBackFromBuBtn" onClick={() => setReport(0)}>
                                <ArrowBack />
                                {TARGET_SETTING}
                            </span>
                        </div>
                        <span>
                            <img src={WarningDialog} alt="" />
                        </span>
                        <div className="nRes">
                            {SURVEY_NAME} :
                            <span>
                                {ohid} ({year})
                            </span>
                        </div>
                    </div>
                    <ul className="navScoreList">
                        <SelectBenchmark
                            benchmarks={targetBenchmarks}
                            updateTargetSettingBm={updateTargetSettingBm}
                            targetBm={targetBm}
                            optionsToUse={optionsToUse}
                            setOptionsToUse={setOptionsToUse}
                            updateOnSelect
                        />
                        <li data-testid="getPptBtn" onClick={getDataForPpt} className="resurveyImg">
                            <img src={Down} alt="" />
                            <span className="lineHead upperHead">{DOWNLOAD}</span>
                        </li>
                        <li data-testid="buBoardFilterBtn" onClick={() => toggleFilterView(true)}>
                            <img src={activeResetFilter ? ActiveFilters1 : FilterSvg} alt="" />
                            {FILTERS}
                        </li>
                    </ul>
                </div>
                <div className="benchmarkInfo">{TARGET_QUARTILE}</div>
                {appliedFiltersInfo('', [], true)}
                {errorInfo && <ErrorToast message={errorInfo} />}
            </div>
            {dwnld && !reportError && (
                <DwnldModal
                    getRawPptData={getRawPptData}
                    reportId={report_id}
                    open={dwnld}
                    setOpen={setdwnld}
                    getSinglePastReport={getSinglePastReport}
                    singlePastReport={singlePastReport}
                    defaultSettings={defaultSettings}
                />
            )}
            {Object.keys(records).length ? (
                <Fragment>
                    {/* {appliedFiltersInfo()} */}
                    <div
                        className={c(
                            {
                                outcomeBoard: demographicCurrentTab === OUTCOMES,
                                practiceBoard: demographicCurrentTab === PRACTICES,
                                // demographicBoard: !isHeatmapBoard,
                            },
                            'clearfix heatmapBoard targetSettingTable'
                        )}
                    >
                        {/* {appliedFiltersInfo()} */}
                        <div className="optionWrapper">
                            <div>
                                <ul className="leftNav">
                                    <li
                                        data-testid={OUTCOME_VAR}
                                        className={c({ activeLink: demographicCurrentTab === OUTCOME_VAR })}
                                        onClick={() => handleTabData(OUTCOME_VAR)}
                                    >
                                        {OUTCOMES}
                                    </li>
                                    <li
                                        data-testid={PRACTICE_VAR}
                                        className={c({ activeLink: demographicCurrentTab === PRACTICE_VAR })}
                                        onClick={() => handleTabData(PRACTICE_VAR)}
                                    >
                                        {PRACTICES}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <section className={c({ wrapperShadow: TARGET_HEADER.length <= 6 }, 'sectionWrapper')}>
                            {demographicCurrentTab === PRACTICE_VAR ? (
                                <div className="outcomeNames targetSetting">
                                    {outcomeNames.map(({ name, len }) => {
                                        return (
                                            <div style={{ height: `${len * 5}vh` }}>
                                                <span>{name}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : null}
                            {/* {status === 400 || (heatMapInclusionError && isInclusion) ? (
                    <ErrorToast message={message || heatMapInclusionError} />
                ) : null} */}
                            <div className="contentWrapper clearfix">
                                <div
                                    className={c({ noPadding: TARGET_HEADER.length <= 6 }, 'mainContent')}
                                    id="myHeader"
                                >
                                    {demographicCurrentTab === OUTCOME_VAR
                                        ? getHeapMapValues(records)
                                        : getHeapMapValues(practices)}
                                </div>
                            </div>
                        </section>
                    </div>
                </Fragment>
            ) : null}
            {warning ? (
                <WarningDialog
                    defaultSettings={defaultSettings}
                    setView={setWarning}
                    benchmarks={targetBenchmarks}
                    updateTargetSettingBm={updateTargetSettingBm}
                    targetBm={targetBm}
                    optionsToUse={optionsToUse}
                    setOptionsToUse={setOptionsToUse}
                />
            ) : null}
        </Fragment>
    );
}

TargetSetting.propTypes = {
    // isPercentile: PropTypes.bool.isRequired,
    // currentTheme: PropTypes.string.isRequired,
    errorInfo: PropTypes.string.isRequired,
    scoreData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    // benchmarks: PropTypes.array.isRequired,
    setReport: PropTypes.func.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    // getPptData: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    // localPractice: PropTypes.string.isRequired,
};

export default TargetSetting;
