import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import CustomBenchmarkList from './CustomBenchmarkList';
import CreateCustomBenchmark from './CreateCustomBenchmark';
import CustomBmDetails from './CustomBmDetails';
import CustomBmEdit from './CustomBmEdit';
import CustomBmRequest from './CustomBmRequest';
import ErrorToast from '../../../components/InformationToast/ErrorToast';

function CustomBenchmark({
    defaultSettings,
    filterParams,
    filters,
    // defaultYear,
    renderfilterApplied,
    customBmList,
    customBmListFetched,
    getCustomBmList,
    defaultFilters,
    filtersMapping,
    getCustomBmSurveyList,
    customBmSurveyList,
    tab,
    setTab,
    saveCustomBmDraft,
    customBmMsg,
    getCustomBmDetail,
    customBmDetail,
    setCustomBmFilter,
    setCreateCustomBmPayload,
    data,
    customBmFilter,
    deleteCustomBm,
    updateCustomBm,
    checkNameExist,
    checkNameMsg,
    isNameAvailable,
    surveyListLoading,
    defaultYear,
    modal,
    viewModal,
    setCustomBmMsg,
    sendCustomBmRequest,
    setActionCode,
    actionCode,
    approvalRequestList,
    approvalRequestFetched,
    approvalRejectList,
    approvalRejectFetched,
    getApprovalRequests,
    getRejectedRequests,
    approveRequest,
    rejectRequest,
    getApprovedRequests,
    approvedList,
    approvedListFetched,
    saveAndPublish,
    duplicateCustomBmRequest,
    errorInfo,
    setEditCustomBmPayload,
    editCustomBmForm,
    resetForm,
}) {
    // const { lang = '1033' } = defaultSettings || {};
    // const staticText = getStaticText(lang);
    // const { SITE_TEXT } = staticText;
    // const [filter, setFilters] = useState(filters);
    const [customBmId, setCustomBmId] = useState('');
    const getPage = () => {
        switch (tab) {
            case 0:
                return (
                    <CustomBenchmarkList
                        defaultSettings={defaultSettings}
                        customBmList={customBmList}
                        customBmListFetched={customBmListFetched}
                        getCustomBmList={getCustomBmList}
                        filterParams={filterParams}
                        renderfilterApplied={renderfilterApplied}
                        tab={tab}
                        setTab={setTab}
                        setCustomBmId={setCustomBmId}
                        getCustomBmDetail={getCustomBmDetail}
                        deleteCustomBm={deleteCustomBm}
                        updateCustomBm={updateCustomBm}
                        customBmMsg={customBmMsg}
                        setSuccessToast={setCustomBmMsg}
                        sendCustomBmRequest={sendCustomBmRequest}
                        setActionCode={setActionCode}
                        actionCode={actionCode}
                        duplicateCustomBmRequest={duplicateCustomBmRequest}
                    />
                );
            case 1:
                return (
                    <CustomBmRequest
                        defaultSettings={defaultSettings}
                        approvalRequestList={approvalRequestList}
                        approvalRequestFetched={approvalRequestFetched}
                        getApprovalRequests={getApprovalRequests}
                        filterParams={filterParams}
                        renderfilterApplied={renderfilterApplied}
                        tab={tab}
                        setTab={setTab}
                        setCustomBmId={setCustomBmId}
                        getCustomBmDetail={getCustomBmDetail}
                        deleteCustomBm={deleteCustomBm}
                        updateCustomBm={updateCustomBm}
                        customBmMsg={customBmMsg}
                        setSuccessToast={setCustomBmMsg}
                        sendCustomBmRequest={sendCustomBmRequest}
                        setActionCode={setActionCode}
                        actionCode={actionCode}
                    />
                );
            case 2:
                return (
                    <CustomBmRequest
                        defaultSettings={defaultSettings}
                        approvalRequestList={approvalRejectList}
                        approvalRequestFetched={approvalRejectFetched}
                        getApprovalRequests={getRejectedRequests}
                        filterParams={filterParams}
                        renderfilterApplied={renderfilterApplied}
                        tab={tab}
                        setTab={setTab}
                        setCustomBmId={setCustomBmId}
                        getCustomBmDetail={getCustomBmDetail}
                        deleteCustomBm={deleteCustomBm}
                        updateCustomBm={updateCustomBm}
                        customBmMsg={customBmMsg}
                        setSuccessToast={setCustomBmMsg}
                        sendCustomBmRequest={sendCustomBmRequest}
                        setActionCode={setActionCode}
                        actionCode={actionCode}
                    />
                );
            case 3:
                return (
                    <CreateCustomBenchmark
                        setTab={setTab}
                        defaultSettings={defaultSettings}
                        filters={filters}
                        defaultFilters={defaultFilters}
                        filtersMapping={filtersMapping}
                        getCustomBmSurveyList={getCustomBmSurveyList}
                        customBmSurveyList={customBmSurveyList}
                        filter={customBmFilter}
                        setFilters={setCustomBmFilter}
                        saveCustomBmDraft={saveCustomBmDraft}
                        customBmMsg={customBmMsg}
                        data={data}
                        setData={setCreateCustomBmPayload}
                        checkNameExist={checkNameExist}
                        checkNameMsg={checkNameMsg}
                        isNameAvailable={isNameAvailable}
                        surveyListLoading={surveyListLoading}
                        defaultYear={defaultYear}
                        saveAndPublish={saveAndPublish}
                        modal={modal}
                        viewModal={viewModal}
                        setActionCode={setActionCode}
                        resetForm={resetForm}
                    />
                );
            case 4:
                return (
                    <CustomBmDetails
                        defaultSettings={defaultSettings}
                        setTab={setTab}
                        customBmDetail={customBmDetail}
                        customBmFilter={customBmFilter}
                        customBmId={customBmId}
                        defaultFilters={defaultFilters}
                        sendCustomBmRequest={sendCustomBmRequest}
                        approveRequest={approveRequest}
                        rejectRequest={rejectRequest}
                        setActionCode={setActionCode}
                        actionCode={actionCode}
                        duplicateCustomBmRequest={duplicateCustomBmRequest}
                    />
                );
            case 5:
                return (
                    <CustomBmEdit
                        setTab={setTab}
                        defaultSettings={defaultSettings}
                        filters={filters}
                        defaultFilters={defaultFilters}
                        filtersMapping={filtersMapping}
                        getCustomBmSurveyList={getCustomBmSurveyList}
                        customBmSurveyList={customBmSurveyList}
                        filter={customBmFilter}
                        setFilters={setCustomBmFilter}
                        saveCustomBmDraft={saveCustomBmDraft}
                        customBmMsg={customBmMsg}
                        data={editCustomBmForm}
                        setData={setEditCustomBmPayload}
                        updateCustomBm={updateCustomBm}
                        customBmDetail={customBmDetail}
                        checkNameExist={checkNameExist}
                        checkNameMsg={checkNameMsg}
                        isNameAvailable={isNameAvailable}
                        surveyListLoading={surveyListLoading}
                        defaultYear={defaultYear}
                        saveAndPublish={saveAndPublish}
                        setActionCode={setActionCode}
                        resetForm={resetForm}
                    />
                );
            case 6:
                return (
                    <CustomBmRequest
                        defaultSettings={defaultSettings}
                        approvalRequestList={approvedList}
                        approvalRequestFetched={approvedListFetched}
                        getApprovalRequests={getApprovedRequests}
                        filterParams={filterParams}
                        renderfilterApplied={renderfilterApplied}
                        tab={tab}
                        setTab={setTab}
                        setCustomBmId={setCustomBmId}
                        getCustomBmDetail={getCustomBmDetail}
                        deleteCustomBm={deleteCustomBm}
                        updateCustomBm={updateCustomBm}
                        customBmMsg={customBmMsg}
                        setSuccessToast={setCustomBmMsg}
                        sendCustomBmRequest={sendCustomBmRequest}
                        setActionCode={setActionCode}
                        actionCode={actionCode}
                        duplicateCustomBmRequest={duplicateCustomBmRequest}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Fragment>
            <div className="customBenchmark clearfix">{getPage()}</div>
            {errorInfo && <ErrorToast message={errorInfo} />}
        </Fragment>
    );
}

CustomBenchmark.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    filterParams: PropTypes.string.isRequired,
    renderfilterApplied: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    customBmList: PropTypes.array.isRequired,
    customBmListFetched: PropTypes.bool.isRequired,
    getCustomBmList: PropTypes.func.isRequired,
    defaultFilters: PropTypes.array.isRequired,
    filtersMapping: PropTypes.array.isRequired,
    getCustomBmSurveyList: PropTypes.func.isRequired,
    customBmSurveyList: PropTypes.array.isRequired,
    tab: PropTypes.number.isRequired,
    setTab: PropTypes.func.isRequired,
    saveCustomBmDraft: PropTypes.func.isRequired,
    customBmMsg: PropTypes.string.isRequired,
    getCustomBmDetail: PropTypes.func.isRequired,
    customBmDetail: PropTypes.object.isRequired,
    setCustomBmFilter: PropTypes.func.isRequired,
    setCreateCustomBmPayload: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    customBmFilter: PropTypes.array.isRequired,
    deleteCustomBm: PropTypes.func.isRequired,
    updateCustomBm: PropTypes.func.isRequired,
    // defaultYear: PropTypes.number.isRequired,
    checkNameExist: PropTypes.func.isRequired,
    checkNameMsg: PropTypes.string.isRequired,
    isNameAvailable: PropTypes.bool.isRequired,
    surveyListLoading: PropTypes.bool.isRequired,
    defaultYear: PropTypes.number.isRequired,
    modal: PropTypes.bool.isRequired,
    viewModal: PropTypes.func.isRequired,
    setCustomBmMsg: PropTypes.func.isRequired,
    sendCustomBmRequest: PropTypes.func.isRequired,
    setActionCode: PropTypes.func.isRequired,
    actionCode: PropTypes.number.isRequired,
    approvalRequestList: PropTypes.array.isRequired,
    approvalRequestFetched: PropTypes.bool.isRequired,
    approvalRejectList: PropTypes.array.isRequired,
    approvalRejectFetched: PropTypes.bool.isRequired,
    getApprovalRequests: PropTypes.func.isRequired,
    getRejectedRequests: PropTypes.func.isRequired,
    approveRequest: PropTypes.func.isRequired,
    rejectRequest: PropTypes.func.isRequired,
    getApprovedRequests: PropTypes.func.isRequired,
    approvedList: PropTypes.array.isRequired,
    approvedListFetched: PropTypes.bool.isRequired,
    saveAndPublish: PropTypes.func.isRequired,
    duplicateCustomBmRequest: PropTypes.func.isRequired,
    errorInfo: PropTypes.string.isRequired,
    setEditCustomBmPayload: PropTypes.func.isRequired,
    editCustomBmForm: PropTypes.object.isRequired,
    resetForm: PropTypes.func.isRequired,
};

export default CustomBenchmark;
