import { createSelector } from 'reselect';

const inclusion = state => state.get('inclusion');

const selectInclusionApiCount = () => createSelector(inclusion, state => state.toJS().count);
const selectInclusionQbyq = () => createSelector(inclusion, state => state.toJS().inclusionqbyq);
const selectGapDataFetched = () => createSelector(inclusion, state => state.toJS().gapDataFetched);
const selectGapData = () => createSelector(inclusion, state => state.toJS().gapArr);
const selectAllGapData = () => createSelector(inclusion, state => state.toJS().allGapData);
const selectDemographicList = () => createSelector(inclusion, state => state.toJS().demographicList);
const selectDemograhicDataFetched = () => createSelector(inclusion, state => state.toJS().demograhicDataFetched);
const selectCurrentOption = () => createSelector(inclusion, state => state.toJS().currentOption);
const selectInclusionDataFetched = () => createSelector(inclusion, state => state.toJS().inclusionDataFetched);
const selectDropDownValue = () => createSelector(inclusion, state => state.toJS().dropDownValue);
const selectError = () => createSelector(inclusion, state => state.toJS().error);
const selectRespondentsData = () => createSelector(inclusion, state => state.toJS().respondentsData);
const selectRespondentsDataFetched = () => createSelector(inclusion, state => state.toJS().resDataFetched);
const selectScoreColor = () => createSelector(inclusion, state => state.toJS().scoreColors);
const selectInclusionBenchmark = () => createSelector(inclusion, state => state.toJS().inclusionBenchmark);
const selectFilters = () => createSelector(inclusion, state => state.toJS().filters);
const selectFiltersApplied = () => createSelector(inclusion, state => state.toJS().filtersApplied);
const selectIaApiParams = () => createSelector(inclusion, state => state.toJS().apiParams);
const selectAllGapInclusionDataFetched = () =>
    createSelector(inclusion, state => state.toJS().allGapInclusionDataFetched);
const selectAllGapInclusionData = () => createSelector(inclusion, state => state.toJS().allGapInclusionData);
const selectAllGapInclusionDataLoading = () =>
    createSelector(inclusion, state => state.toJS().allGapInclusionDataLoading);
const selectPagesLoaded = () => createSelector(inclusion, state => state.toJS().pagesLoaded);
const selectResurveyData = () => createSelector(inclusion, state => state.toJS().resurveyData);
const selectResurveyDataFetched = () => createSelector(inclusion, state => state.toJS().resurveyDataFetched);
const selectResurveyGapData = () => createSelector(inclusion, state => state.toJS().resurveyGapData);
const selectResurveyGapDataFetched = () => createSelector(inclusion, state => state.toJS().resurveyGapDataFetched);
const selectResurveyAllGapData = () => createSelector(inclusion, state => state.toJS().resurveyAllGapData);
const selectResurveyAllGapDataFetched = () =>
    createSelector(inclusion, state => state.toJS().resurveyAllGapDataFetched);
const selectResurveyProjectsFetched = () => createSelector(inclusion, state => state.toJS().projects);
const selectDemographicMappedData = () => createSelector(inclusion, state => state.toJS().demographicMappedData);
const selectAllGapDataFetched = () => createSelector(inclusion, state => state.toJS().allGapDataFetched);
const selectBenchmarkVersions = () => createSelector(inclusion, state => state.toJS().benchmarkVersions);
const selectBmVersionInUse = () => createSelector(inclusion, state => state.toJS().benchmarkVersionInUse);
const selectPrevSurvey = () => createSelector(inclusion, state => state.toJS().previousSurvey);
const selectPrevYear = () => createSelector(inclusion, state => state.toJS().prevSurveyYear);
const selectDemoComparisonSurvey = () => createSelector(inclusion, state => state.toJS().demoComparisonSurvey);
const selectDemoComparisonYear = () => createSelector(inclusion, state => state.toJS().demoComparisonYear);
const selectResurveyDemographicfetched = () =>
    createSelector(inclusion, state => state.toJS().resurveyDemographicFetched);
const selectDemoNSize = () => createSelector(inclusion, state => state.toJS().demoNSize);
const selectResurveyDropdown = () => createSelector(inclusion, state => state.toJS().resurveyDropdown);
const selectResAllGapInclusionDataFetched = () =>
    createSelector(inclusion, state => state.toJS().resAllGapInclusionDataFetched);
const selectResAllGapInclusionData = () => createSelector(inclusion, state => state.toJS().resAllGapInclusionData);
const selectResAllGapInclusionDataLoading = () =>
    createSelector(inclusion, state => state.toJS().resAllGapInclusionDataLoading);

export {
    selectInclusionApiCount,
    selectInclusionQbyq,
    selectGapDataFetched,
    selectGapData,
    selectDemographicList,
    selectDemograhicDataFetched,
    selectCurrentOption,
    selectInclusionDataFetched,
    selectDropDownValue,
    selectAllGapData,
    selectError,
    selectRespondentsData,
    selectRespondentsDataFetched,
    selectScoreColor,
    selectInclusionBenchmark,
    selectFilters,
    selectFiltersApplied,
    selectIaApiParams,
    selectAllGapInclusionDataFetched,
    selectAllGapInclusionData,
    selectPagesLoaded,
    selectResurveyData,
    selectResurveyDataFetched,
    selectResurveyGapData,
    selectResurveyGapDataFetched,
    selectResurveyAllGapData,
    selectResurveyAllGapDataFetched,
    selectResurveyProjectsFetched,
    selectDemographicMappedData,
    selectAllGapDataFetched,
    selectBenchmarkVersions,
    selectBmVersionInUse,
    selectPrevSurvey,
    selectPrevYear,
    selectAllGapInclusionDataLoading,
    selectDemoComparisonSurvey,
    selectDemoComparisonYear,
    selectResurveyDemographicfetched,
    selectDemoNSize,
    selectResurveyDropdown,
    selectResAllGapInclusionDataFetched,
    selectResAllGapInclusionData,
    selectResAllGapInclusionDataLoading,
};
