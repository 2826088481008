import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';

import Login from './containers/Login';
import './sass/base.scss';

const App = ({ mid }) => (
    <Login mid={mid}>
        <Router basename="">
            <Routes mid={mid} />
        </Router>
    </Login>
);

App.propTypes = {
    mid: PropTypes.object.isRequired,
};

export default App;
