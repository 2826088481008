import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Loader from '../Loader';

const LogoutComponent = ({ history }) => {
    useEffect(() => {
        history.push('/');
        // eslint-disable-next-line
    }, []);

    return <Loader position="absolute" showBackground />;
};

LogoutComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default withRouter(LogoutComponent);
