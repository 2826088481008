import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ActionModal from './ActionModal';

import { addCompAction, resetActionRedux } from '../actions';
import { selectOptionData, getActionError, getActionCreated, getActionLoading } from '../selector';

const stateToProps = createStructuredSelector({
    optionData: selectOptionData(),
    actionError: getActionError(),
    actionCreated: getActionCreated(),
    actionLoading: getActionLoading(),
});

const dispatchToProps = { addCompAction, resetActionRedux };

export default connect(stateToProps, dispatchToProps)(ActionModal);
