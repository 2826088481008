import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import ValuesBoard from './ValuesBoard';
import TextComments from './TextComments';
import { getStaticText } from '../../../utils/constants';
import './index.scss';
import NPSBoard from './NPSBoard';
import EngagementBoard from './EngagementBoard';
import CustomBU from './CustomBU';

function AddOns({ toggleFilterView, defaultSettings, view }) {
    const { lang, comment_access, engagement } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, NON_PROFIT_SURVEY } = staticText;
    const isNonProfit = engagement === NON_PROFIT_SURVEY;
    const [showComments, toggleComments] = useState(isNonProfit);
    const { VALUES_TEXT, TEXT_COMMENTS_TXT, ADDONS_PAGE_TEXT } = SITE_TEXT || {};

    const valueBoardView = () => {
        return (
            <Fragment>
                <Helmet>
                    <title>
                        {ADDONS_PAGE_TEXT['1']} {showComments ? ADDONS_PAGE_TEXT['2'] : ADDONS_PAGE_TEXT['3']}{' '}
                        {ADDONS_PAGE_TEXT['4']}
                    </title>
                </Helmet>
                {comment_access && !isNonProfit && (
                    <div className="tabsContent">
                        <span
                            data-testid="valuesBtn"
                            onClick={() => toggleComments(false)}
                            className={!showComments ? 'active' : ''}
                        >
                            {VALUES_TEXT}
                        </span>
                        <span
                            data-testid="commentsBtn"
                            onClick={() => toggleComments(true)}
                            className={showComments ? 'active' : ''}
                        >
                            {TEXT_COMMENTS_TXT}
                        </span>
                    </div>
                )}
                {showComments ? <TextComments /> : <ValuesBoard toggleFilterView={toggleFilterView} />}
            </Fragment>
        );
    };

    const getPage = () => {
        switch (view) {
            case 0:
                return valueBoardView();
            case 1:
                return <NPSBoard toggleFilterView={toggleFilterView} />;
            case 2:
                return <EngagementBoard toggleFilterView={toggleFilterView} />;
            case 3:
                return <CustomBU toggleFilterView={toggleFilterView} />;
            default:
                return <Fragment />;
        }
    };

    return (
        <div className="scoreBoard addOns clearfix">
            <Helmet>
                <title>
                    {' '}
                    {ADDONS_PAGE_TEXT['1']} {showComments ? ADDONS_PAGE_TEXT['2'] : ADDONS_PAGE_TEXT['3']}{' '}
                    {ADDONS_PAGE_TEXT['4']}
                </title>
            </Helmet>
            {getPage()}
        </div>
    );
}

AddOns.propTypes = {
    toggleFilterView: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    view: PropTypes.number.isRequired,
};

export default AddOns;
