import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import CloseIcon from '../../../assets/Close.svg';
import PenBlue from '../../../assets/Pen-Blue.svg';
import './index.scss';
import PenWhite from '../../../assets/Pen-White.svg';
import SmallDown from '../../../assets/smallDown.svg';
import ReportFeedback from './ReportFeedback';
import FeedbackList from './FeedbackList';
import FeedbackDetail from './FeedbackDetail';
import Loader from '../../../components/Loader';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import { getStaticText } from '../../../utils/constants';
import getStaticTextDiagnose from '../constants';

function Feedback({
    closeFeedback,
    sendFeedback,
    feedback,
    feedbackSuccess,
    defaultSettings,
    getFeedbackList,
    feedbackList,
    feedbackListFetched,
    getFeedbackDetails,
    feedbackDetails,
    feedbackDetailsFetched,
    apiLoadingCount,
    resetFeedback,
    errorInfo,
    refreshFeedbackList,
    feedbackOptions,
    setFeedbackOptions,
}) {
    const { lang, ohid, user_id, report_id } = defaultSettings || {};
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { FEEDBACK } = SITE_TEXT;
    const { FEEDBACK_PAYLOAD } = getStaticTextDiagnose;
    const [isOpen, setIsOpen] = useState(true);
    const [tab, setView] = useState(0);
    const [ticketId, setTicketId] = useState(null);
    const [options, setOptions] = useState(feedbackOptions);
    const handleClose = (updateRedux = false) => {
        setTimeout(() => {
            closeFeedback(false);
        }, 200);
        setIsOpen(false);
        if (updateRedux) {
            setFeedbackOptions(options);
        }
    };

    const resetFeedbackOptions = () => {
        setFeedbackOptions({
            ...FEEDBACK_PAYLOAD,
            report_id,
            ohid,
            created_by: user_id,
        });
    };

    const closeFeedbackModal = () => {
        if (tab === 0) {
            handleClose(true);
        }
        handleClose();
    };

    const getPage = () => {
        switch (tab) {
            case 0:
                return (
                    <ReportFeedback
                        handleClose={handleClose}
                        sendFeedback={sendFeedback}
                        feedbackSuccess={feedbackSuccess}
                        defaultSettings={defaultSettings}
                        setView={setView}
                        tab={tab}
                        resetFeedback={resetFeedback}
                        feedback={feedback}
                        setTicketId={setTicketId}
                        feedbackOptions={feedbackOptions}
                        setFeedbackOptions={setFeedbackOptions}
                        options={options}
                        setOptions={setOptions}
                        resetFeedbackOptions={resetFeedbackOptions}
                    />
                );
            case 1:
                return (
                    <FeedbackList
                        getFeedbackList={getFeedbackList}
                        feedbackList={feedbackList}
                        feedbackListFetched={feedbackListFetched}
                        defaultSettings={defaultSettings}
                        setView={setView}
                        setTicketId={setTicketId}
                        ticketId={ticketId}
                        tab={tab}
                        refreshFeedbackList={refreshFeedbackList}
                    />
                );
            case 2:
                return (
                    <FeedbackDetail
                        feedbackList={feedbackList}
                        defaultSettings={defaultSettings}
                        setView={setView}
                        ticketId={ticketId}
                        getFeedbackDetails={getFeedbackDetails}
                        feedbackDetails={feedbackDetails}
                        feedbackDetailsFetched={feedbackDetailsFetched}
                        refreshFeedbackList={refreshFeedbackList}
                    />
                );
            default:
                return null;
        }
    };
    return (
        <div className="feedbackDialog" data-testid="filterDialog">
            <div className="backdropRoot" onClick={closeFeedbackModal} />
            <div className={`rightSideFilters ${isOpen ? 'slideIn' : 'slideOut'}`}>
                <div className="topContent clearfix">
                    <div className="closeContent">
                        <img data-testid="closeFilters" src={CloseIcon} alt="" onClick={closeFeedbackModal} />
                    </div>
                    <h2>
                        <span>
                            <img src={PenBlue} alt="" />
                        </span>
                        {FEEDBACK}
                    </h2>
                </div>
                {apiLoadingCount ? <Loader position="absolute" showBackground background="transparent" /> : null}
                {errorInfo && <ErrorToast message={errorInfo} />}
                {getPage()}
                <Button className="feedbackBtn" onClick={closeFeedbackModal}>
                    <span>
                        <img src={PenWhite} alt="" />
                    </span>
                    {FEEDBACK}
                    <span>
                        <img src={SmallDown} alt="" />
                    </span>
                </Button>
            </div>
        </div>
    );
}

Feedback.propTypes = {
    closeFeedback: PropTypes.func.isRequired,
    sendFeedback: PropTypes.func.isRequired,
    defaultSettings: PropTypes.func.isRequired,
    feedback: PropTypes.number.isRequired,
    feedbackSuccess: PropTypes.bool.isRequired,
    resetFeedback: PropTypes.func.isRequired,
    getFeedbackList: PropTypes.func.isRequired,
    feedbackList: PropTypes.array.isRequired,
    feedbackListFetched: PropTypes.bool.isRequired,
    getFeedbackDetails: PropTypes.func.isRequired,
    feedbackDetails: PropTypes.object.isRequired,
    feedbackDetailsFetched: PropTypes.bool.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    errorInfo: PropTypes.string.isRequired,
    refreshFeedbackList: PropTypes.func.isRequired,
    feedbackOptions: PropTypes.object.isRequired,
    setFeedbackOptions: PropTypes.func.isRequired,
};

export default Feedback;
