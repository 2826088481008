import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { decode } from 'html-entities';
import Loader from '../../../../components/Loader';
import getStaticTextDiagnose from '../../constants';
import { getScoreColor } from './utils';

import './tableview.scss';

function Filtertable({
    iscellBlue,
    ohid,
    currFilterName,
    dataSet,
    benchmark,
    precode,
    lang,
    year,
    filters,
    level = 0,
    isLoading,
    setColumns,
    columns,
    filterColumns,
    comparison_id,
    currpreCodePrev,
    getCustomBuScoreData,
    error,
    defaultCall,
}) {
    const staticTextDiagnose = getStaticTextDiagnose();
    const { CUSTOM_BU_COLUMNS, CUSTOM_BU_QUARTILE_COLORS } = staticTextDiagnose;
    useEffect(() => {
        if (dataSet?.length) {
            setColumns([...dataSet]);
        }
        // eslint-disable-next-line
    }, [dataSet]);

    const handleCustomBuScoreData = () => {
        const filtersPrecode = [];
        precode.map(item => filtersPrecode.push(`demo_${item.split('_')[0]}:${item}`));
        const prevFilters = [];
        currpreCodePrev.map(item => prevFilters.push(`demo_${item.split('_')[0]}:${item}`));
        const payloads = [];
        [0, 1].forEach((_, index) => {
            const payload = {
                ohid: !index ? ohid : comparison_id.ohi,
                filters: !index ? filtersPrecode : prevFilters,
                lang: Number(lang),
                year: !index ? Number(year) : comparison_id.year,
                threshold: 10,
                bm_filters: [benchmark[0]],
                benchmarks: benchmark[0],
            };
            payloads.push(payload);
        });
        getCustomBuScoreData({ payloads, level });
    };

    useEffect(() => {
        if (currFilterName && !defaultCall) {
            handleCustomBuScoreData();
        }
        // eslint-disable-next-line
    }, [currFilterName, filters]);

    return (
        <table className="custom-bu-table-wrapper">
            {isLoading ? <Loader position="absolute" showBackground background="transparent" /> : null}
            <thead>
                {!error && !isLoading ? (
                    <tr className="sectionListWrapper scoreSize">
                        <th className="colmn-header" />
                        <th className="colmn-header">
                            {CUSTOM_BU_COLUMNS.ALL_OF_TALANX_COLUMNS}
                            <span>(n={columns[0]?.qbyq?.size})</span>
                        </th>
                        {Object.keys(filterColumns).map((item, key) => {
                            return (
                                <th className="colmn-header" key={`clmNSize_${key}`}>
                                    {decode(filters[key])}
                                    <span> (n={filterColumns[item][0]?.size}) </span>
                                </th>
                            );
                        })}
                    </tr>
                ) : (
                    <p>{error}</p>
                )}
            </thead>
            {!error && !isLoading ? (
                <tbody className="">
                    {Object.keys(columns[0]?.qbyq?.records || {}).map((item, key) => {
                        return (
                            <tr>
                                <th className="sectionListWrapper recordName" key={`recordes_${key}`}>
                                    {columns[0]?.qbyq?.records[item]?.display_name === CUSTOM_BU_COLUMNS.WORK_ENV
                                        ? CUSTOM_BU_COLUMNS.CULTURE_N_CLIMATE
                                        : columns[0]?.qbyq?.records[item]?.display_name}
                                </th>

                                <td
                                    className={`sectionListWrapper scroreData ${columns[0]?.qbyq?.records[item]
                                        ?.quartile[0] === '0' && 'top-decile'}`}
                                    style={{
                                        background:
                                            CUSTOM_BU_QUARTILE_COLORS[columns[0]?.qbyq?.records[item]?.quartile][
                                                iscellBlue === 'true' ? 'color_bl' : 'color_gr'
                                            ],
                                    }}
                                >
                                    <div className="sectionList" key={`recordes_${key}`}>
                                        {columns[0]?.qbyq?.records[item]?.score}
                                        {Object.keys(columns[1]).length > 0 ? (
                                            <span
                                                className="score-diff"
                                                style={{
                                                    background: getScoreColor(
                                                        columns[0]?.qbyq?.records[item]?.score -
                                                            columns[1]?.qbyq?.records[item]?.score
                                                    ).colorCode,
                                                }}
                                            >
                                                {columns[0]?.qbyq?.records[item]?.score -
                                                    columns[1]?.qbyq?.records[item]?.score}
                                            </span>
                                        ) : (
                                            <span
                                                className="score-diff"
                                                style={{
                                                    background: '#efefef',
                                                }}
                                            >
                                                -
                                            </span>
                                        )}
                                    </div>
                                </td>
                                {Object.keys(filterColumns).map((bu_filter, key) => {
                                    return (
                                        <td
                                            className={`sectionListWrapper scroreData ${filterColumns[bu_filter][0]
                                                ?.records[item]?.quartile[0] === '0' && 'top-decile'}`}
                                            style={{
                                                background:
                                                    CUSTOM_BU_QUARTILE_COLORS[
                                                        filterColumns[bu_filter][0]?.records[item]?.quartile
                                                    ][iscellBlue === 'true' ? 'color_bl' : 'color_gr'],
                                            }}
                                        >
                                            <div className="sectionList" key={`recordes_${key}`}>
                                                {filterColumns[bu_filter][0]?.records[item]?.score}
                                                {Object.keys(filterColumns[bu_filter][1]).length > 0 ? (
                                                    <span
                                                        className="score-diff"
                                                        style={{
                                                            background: getScoreColor(
                                                                filterColumns[bu_filter][0]?.records[item]?.score -
                                                                    filterColumns[bu_filter][1]?.records[item]?.score
                                                            ).colorCode,
                                                        }}
                                                    >
                                                        {isNaN(filterColumns[bu_filter][0]?.records[item]?.score) ||
                                                        isNaN(filterColumns[bu_filter][1]?.records[item]?.score)
                                                            ? '-'
                                                            : filterColumns[bu_filter][0]?.records[item]?.score -
                                                              filterColumns[bu_filter][1]?.records[item]?.score}
                                                    </span>
                                                ) : (
                                                    <span className="score-diff" style={{ background: '#efefef' }}>
                                                        -
                                                    </span>
                                                )}
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            ) : null}
        </table>
    );
}

Filtertable.propTypes = {
    iscellBlue: PropTypes.object.isRequired,
    currpreCodePrev: PropTypes.array.isRequired,
    ohid: PropTypes.string.isRequired,
    currFilterName: PropTypes.string.isRequired,
    dataSet: PropTypes.array.isRequired,
    benchmark: PropTypes.string.isRequired,
    precode: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
    filters: PropTypes.array.isRequired,
    level: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setColumns: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    filterColumns: PropTypes.object.isRequired,
    comparison_id: PropTypes.string.isRequired,
    getCustomBuScoreData: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
    defaultCall: PropTypes.bool.isRequired,
};
export default Filtertable;
