import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import AuthStore from '../../../../common/AuthStore';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import FilterSvg from '../../assets/Filter.svg';
import ActiveFilters1 from '../../assets/ActiveFilters1.svg';
import Down from '../../assets/Down.svg';
import HandshakeIcon from '../../assets/handshake.png';
import ManIcon from '../../assets/businessman.png';
import { getStaticText } from '../../../../utils/constants';
import ErrorToast from '../../../../components/InformationToast/ErrorToast';

function Engagement({
    pageData,
    errorInfo,
    defaultSettings,
    engagementDataFetched,
    callEngagement,
    addOnTab,
    setAddOnTabs,
    toggleFilterView,
    activeFilter,
    scoreData,
    getPptData,
}) {
    const { lang, year, report_name, template_name: template_file_name } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        QUESTIONS_LEGEND,
        JOB_ENGAGEMENT,
        ORG_ENGAGEMENT,
        ENGAGEMENT_NAV_LINKS,
        FILTERS,
        TOTAL_RES_TEXT,
        DOWNLOAD,
    } = SITE_TEXT || {};
    const isCurrentYear = parseInt(year, 10) >= 2023;
    const { overall = {} } = pageData || {};
    const { question_scores = [], job_engagement_score: job = '', org_engagement_score: org = '' } = overall || {};
    const jobData = question_scores.filter(({ q_code: code = '' }) =>
        isCurrentYear ? code.includes('q2_1') : code.includes('additional_8')
    );
    const orgData = question_scores.filter(({ q_code: code = '' }) =>
        isCurrentYear ? code.includes('q2_2') : code.includes('additional_9')
    );
    const { qbyq = {} } = scoreData;
    const { size = '' } = qbyq || {};
    const checkLength = Object.keys(overall).length;

    useEffect(() => {
        if (!engagementDataFetched) {
            callEngagement();
        }
        // eslint-disable-next-line
    }, [engagementDataFetched]);

    const getPpt = (data = []) => {
        const { pptAccessToken = '' } = AuthStore;
        const output = {
            output_file_name: 'data.pptx',
            template_file_name,
            slides: [
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'orgengagement',
                    data,
                    // styles: getStylesArr(),
                },
            ],
        };
        getPptData(output, pptAccessToken, `${report_name} Scorecard`);
    };

    const getChartData = (dataObj, name) => {
        const { disagree, agree, neutral } = dataObj || {};
        const chart_data = [];
        const labelToUse = {
            '2': agree,
            '3': disagree,
            '1': neutral,
        };
        [2, 1, 3].forEach((val, i) => {
            const otherVal = val !== 2 ? { series: val, label: labelToUse[val] } : {};
            chart_data.push({
                index: 0,
                row_index: 1,
                column_index: i,
                value: `${val === 2 ? '' : '-'}${labelToUse[val]}`,
                ...otherVal,
            });
        });
        const obj = {
            name,
            chart_data,
            meta_data: {
                max_bound: agree,
                min_bound: -(disagree + neutral),
            },
        };
        return obj;
    };

    const getDataForPpt = () => {
        const data = [
            {
                name: 'job_engagement_score',
                value: job,
            },
            {
                name: 'org_engagement_score',
                value: org,
            },
            {
                name: 'Checkme',
                is_delete: true,
            },
        ];
        const pushSectionData = (indexArr, dataToUse) => {
            indexArr.forEach((val, i) => {
                const name = `qbyq${val}`;
                const chartName = `qbyqObj${val}`;
                if (dataToUse[i]) {
                    const { title = '' } = dataToUse[i] || {};
                    data.push({
                        name,
                        value: title,
                    });
                    const chart = getChartData(dataToUse[i], chartName);
                    data.push(chart);
                } else {
                    data.push({
                        name,
                        is_delete: true,
                    });
                    data.push({
                        name: chartName,
                        is_delete: true,
                    });
                }
            });
        };
        pushSectionData([1, 2, 3], jobData);
        pushSectionData([4, 5, 6], orgData);
        getPpt(data);
    };

    return (
        <Fragment>
            <div className="optionWrapper">
                <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <ul className="leftNav">
                            {ENGAGEMENT_NAV_LINKS.map((tabName, index) => {
                                return (
                                    <li
                                        data-testid={tabName}
                                        key={tabName}
                                        className={c({ activeLink: addOnTab === index })}
                                        onClick={() => setAddOnTabs(index)}
                                    >
                                        {tabName}
                                    </li>
                                );
                            })}
                        </ul>
                        <div className="nRes">
                            {TOTAL_RES_TEXT}
                            <span>{size.toLocaleString('en-US')}</span>
                        </div>
                    </div>
                    <ul className="navScoreList">
                        {!errorInfo && (
                            <li data-testid="getPptBtn" onClick={getDataForPpt} className="resurveyImg">
                                <img src={Down} alt="" />
                                <span className="lineHead upperHead">{DOWNLOAD}</span>
                            </li>
                        )}
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                            <img src={activeFilter ? ActiveFilters1 : FilterSvg} alt="" />
                            <span className="filter">{FILTERS}</span>
                        </li>
                    </ul>
                </div>
            </div>
            {checkLength ? (
                <div className="questionBoard clearfix">
                    {errorInfo && <ErrorToast message={errorInfo} />}
                    <div className="overallSection">
                        <div>
                            <h2>{JOB_ENGAGEMENT}</h2>
                            <div>
                                <h2>
                                    <span>
                                        <img src={ManIcon} alt="" />
                                    </span>
                                    {job}
                                </h2>
                            </div>
                        </div>
                        <div>
                            <h2>{ORG_ENGAGEMENT}</h2>
                            <div>
                                <h2>
                                    <span>
                                        <img src={HandshakeIcon} alt="" />
                                    </span>
                                    {org}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <Fragment>
                        <Accordion classes={{ root: 'accordionRoot' }} expanded>
                            <AccordionSummary expandIcon={null} classes={{ content: 'accordionHead' }}>
                                <div className="outcomeContent">
                                    {JOB_ENGAGEMENT}
                                    <ul className="questionLegends">
                                        <li></li>
                                        {QUESTIONS_LEGEND.map(legendText => (
                                            <li key={legendText}>
                                                <span />
                                                {legendText}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails classes={{ root: 'clearfix' }}>
                                <section className="leftSection">
                                    <div className="clearfix">
                                        {jobData.map(
                                            ({
                                                q_code: code = '',
                                                title: qtext = '',
                                                disagree = 0,
                                                neutral = 0,
                                                agree = 0,
                                            }) => {
                                                const totalScore = disagree + neutral + agree;
                                                return (
                                                    <section key={qtext} className="metaSection clearfix">
                                                        <div className="metaText">{qtext || code}</div>
                                                        {totalScore ? (
                                                            <div className="metaScore">
                                                                <div>
                                                                    <span style={{ width: `${disagree}%` }}>
                                                                        {disagree}
                                                                    </span>
                                                                    <span style={{ width: `${neutral}%` }}>
                                                                        {neutral}
                                                                    </span>
                                                                    <span style={{ width: `${agree}%` }}>{agree}</span>
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </section>
                                                );
                                            }
                                        )}
                                    </div>
                                </section>
                            </AccordionDetails>
                            <AccordionSummary expandIcon={null} classes={{ content: 'accordionHead' }}>
                                <div className="outcomeContent">
                                    {ORG_ENGAGEMENT}
                                    <ul className="questionLegends">
                                        <li></li>
                                        {QUESTIONS_LEGEND.map(legendText => (
                                            <li key={legendText}>
                                                <span />
                                                {legendText}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails classes={{ root: 'clearfix' }}>
                                <section className="leftSection">
                                    <div className="clearfix">
                                        {orgData.map(
                                            ({
                                                q_code: code = '',
                                                title: qtext = '',
                                                disagree = 0,
                                                neutral = 0,
                                                agree = 0,
                                            }) => {
                                                const totalScore = disagree + neutral + agree;
                                                return (
                                                    <section key={qtext} className="metaSection clearfix">
                                                        <div className="metaText">{qtext || code}</div>
                                                        {totalScore ? (
                                                            <div className="metaScore">
                                                                <div>
                                                                    <span style={{ width: `${disagree}%` }}>
                                                                        {disagree}
                                                                    </span>
                                                                    <span style={{ width: `${neutral}%` }}>
                                                                        {neutral}
                                                                    </span>
                                                                    <span style={{ width: `${agree}%` }}>{agree}</span>
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </section>
                                                );
                                            }
                                        )}
                                    </div>
                                </section>
                            </AccordionDetails>
                        </Accordion>
                    </Fragment>
                </div>
            ) : null}
        </Fragment>
    );
}

Engagement.propTypes = {
    pageData: PropTypes.object.isRequired,
    errorInfo: PropTypes.string.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    engagementDataFetched: PropTypes.bool.isRequired,
    callEngagement: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    scoreData: PropTypes.object.isRequired,
};

export default Engagement;
