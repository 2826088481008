import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import NPSBoard from './NPSBoard';

import { getNPSData, getNPSDemographicData, setNpsDropdown, getScoreData, getPptData } from '../../actions';

import {
    selectApiParams,
    selectApiCount,
    getErrorInfo,
    selectFilters,
    selectNpsData,
    selectNpsDataFetched,
    selectNpsDemographicData,
    selectNpsDemographicDataFetched,
    selectNpsDropdown,
    selectFiltersApplied,
    selectScoreData,
    selectScoreDataFetched,
} from '../../selector';

import { getDefaultSettings } from '../../../Login/selector';

const stateToProps = createStructuredSelector({
    npsData: selectNpsData(),
    npsDataFetched: selectNpsDataFetched(),
    npsDemographicData: selectNpsDemographicData(),
    npsDemographicDataFetched: selectNpsDemographicDataFetched(),
    npsDropdown: selectNpsDropdown(),
    apiParams: selectApiParams(),
    apiLoadingCount: selectApiCount(),
    errorInfo: getErrorInfo(),
    defaultSettings: getDefaultSettings(),
    filters: selectFilters(),
    activeFilter: selectFiltersApplied(),
    scoreData: selectScoreData(),
    scoreDataFetched: selectScoreDataFetched(),
});

const dispatchToProps = { getNPSData, getNPSDemographicData, setNpsDropdown, getScoreData, getPptData };

export default connect(stateToProps, dispatchToProps)(NPSBoard);
