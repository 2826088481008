import {
    GET_ACTION_LIST,
    UPDATE_ACTION_LIST,
    GET_COMP_VALUES,
    UPDATE_VALUES,
    ACTION_USABILITY,
    ACTION_VOTE,
    ADD_NEW_ACTION,
    RESET_ACTION_REDUX,
} from './constants';

export const getActionOptions = payload => ({
    type: `${GET_ACTION_LIST}_PENDING`,
    payload,
});

export const getActionOptionsSuccess = payload => ({
    type: `${GET_ACTION_LIST}_SUCCESS`,
    payload,
});

export const getActionOptionsFailed = (payload = {}) => ({
    type: `${GET_ACTION_LIST}_FAILED`,
    payload,
});

export const updateActionList = payload => ({
    type: UPDATE_ACTION_LIST,
    payload,
});

export const getCompValues = payload => ({
    type: `${GET_COMP_VALUES}_PENDING`,
    payload,
});

export const getCompValuesSuccess = payload => ({
    type: `${GET_COMP_VALUES}_SUCCESS`,
    payload,
});

export const getCompValuesFailed = (payload = {}) => ({
    type: `${GET_COMP_VALUES}_FAILED`,
    payload,
});

export const toggleActionUsability = payload => ({
    type: ACTION_USABILITY,
    payload,
});

export const toggleActionUsabilitySuccess = payload => ({
    type: `${ACTION_USABILITY}_DONE`,
    payload,
});

export const toggleActionUsabilityFailed = (payload = {}) => ({
    type: `${ACTION_USABILITY}_ERROR`,
    payload,
});

export const toggleActionVote = payload => ({
    type: ACTION_VOTE,
    payload,
});

export const toggleActionVoteSuccess = payload => ({
    type: `${ACTION_VOTE}_DONE`,
    payload,
});

export const toggleActionVoteFailed = (payload = {}) => ({
    type: `${ACTION_VOTE}_ERROR`,
    payload,
});

export const updateCompData = payload => ({
    type: UPDATE_VALUES,
    payload,
});

export const addCompAction = payload => ({
    type: `${ADD_NEW_ACTION}_PENDING`,
    payload,
});

export const addCompActionSuccess = payload => ({
    type: `${ADD_NEW_ACTION}_SUCCESS`,
    payload,
});

export const addCompActionFailed = (payload = {}) => ({
    type: `${ADD_NEW_ACTION}_FAILED`,
    payload,
});

export const resetActionRedux = () => ({ type: RESET_ACTION_REDUX });
