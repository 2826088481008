import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { Checkbox, Dialog, FormControlLabel, Menu, MenuItem, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import SearchIcon from '../../../assets/Magnifying.svg';
import Close from '../../../assets/Close.svg';
import './index.scss';
import { getSurveyList } from '../apis';
import { debounce } from '../assets/functions';

const PublishModal = ({
    setView,
    sendPublishRequest,
    customBmId,
    customBmName,
    savePayload,
    setTab,
    setActionCode,
    isSaveAndPublish,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [search, setSearch] = useState('');
    const [surveyList, setSurveyList] = useState([]);
    const inputRef = useRef(null);
    const [surveys, selectSurveys] = useState([]);
    const searchSurvey = useCallback(async val => {
        try {
            setAnchorEl(inputRef.current);
            const { data } = await getSurveyList({ q: val, year: 2024 });
            const { data: list = [] } = data || {};
            setSurveyList(list);
        } catch (err) {
            setAnchorEl(null);
        }
    });

    const debouncedSearch = debounce(val => searchSurvey(val), 500);

    const handleSearch = e => {
        const { value } = e.target;
        setSearch(value);
        if (value) {
            debouncedSearch(value);
        }
    };

    const removeSurvey = (arr, value) => {
        const i = arr.findIndex(id => id === value);
        selectSurveys([...arr.slice(0, i), ...arr.slice(i + 1)]);
    };

    const handleChange = ({ checked }, value) => {
        const arr = cloneDeep(surveys);
        if (checked) {
            arr.push(value);
            selectSurveys(arr);
        } else {
            removeSurvey(arr, value);
        }
    };
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);

    const publishRequest = () => {
        if (!isSaveAndPublish) {
            sendPublishRequest({
                id: customBmId,
                payload: {
                    is_public: false,
                    attached_ohi_ids: surveys,
                },
            });
        } else {
            sendPublishRequest({
                savePayload,
                payload: {
                    is_public: false,
                    attached_ohi_ids: surveys,
                },
                id: customBmId || '',
            });
        }
        setTab(0);
        setActionCode(6);
    };

    const memoizedSelectedSurveys = useMemo(
        () =>
            surveys.map(ohids => (
                <span key={ohids}>
                    {ohids}{' '}
                    <span onClick={() => removeSurvey(surveys, ohids)}>
                        <img src={Close} alt="" />
                    </span>
                </span>
            )),
        [surveys, removeSurvey]
    );

    return (
        <Dialog
            open
            disableEscapeKeyDown
            disableBackdropClick
            maxWidth="md"
            classes={{
                root: 'publishDialogRoot',
            }}
        >
            <section className="infoContent">
                {/* <div className="infoLarge">
                    <img src={InfoLarge} alt="" />
                </div> */}
                <h2>Send publish request</h2>
                <div className="para">
                    <div>
                        Would you like to send request to OHI Helpdesk to <b>{customBmName}</b>. You will receive update
                        on your email once it’s approved.
                    </div>
                    <div>
                        <b>Please note :</b> You can add OHI ID’s and make the created custom benchmark added to those
                        ID’s. Please ignore if it is a generic one.
                    </div>
                </div>
                <div className="para">
                    Attach this <b>{customBmName}</b> to below mentioned surveys.
                </div>
                <div className="searchHead">
                    <img src={SearchIcon} alt="" className="searchIcon" />
                    <TextField
                        fullWidth
                        value={search}
                        placeholder="Search"
                        inputRef={inputRef}
                        onChange={e => handleSearch(e)}
                        // id="input"
                    />
                    {surveyList.length > 0 && (
                        <Menu
                            id="search"
                            open={open}
                            onClose={handleClose}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            getContentAnchorEl={null}
                            classes={{ paper: 'popoverRoot' }}
                        >
                            {surveyList.map(({ survey_id }) => (
                                <MenuItem key={survey_id}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={surveys.includes(survey_id)}
                                                onChange={({ target }) => handleChange(target, survey_id)}
                                                name={survey_id}
                                            />
                                        }
                                        label={survey_id}
                                    />
                                </MenuItem>
                            ))}
                        </Menu>
                    )}
                </div>
                <div className="selectedSurveys">{memoizedSelectedSurveys}</div>
            </section>
            <section className="btnContent clearfix activeButtons">
                <Fragment>
                    <div
                        data-testid="detailButton"
                        className="detailedBtn aciveDetailBtn"
                        onClick={() => {
                            setView(false);
                        }}
                    >
                        Cancel
                    </div>
                    <div data-testid="compareButton" className="compareBtn activeCompareBtn" onClick={publishRequest}>
                        Send request
                    </div>
                </Fragment>
            </section>
        </Dialog>
    );
};

PublishModal.defaultProps = {
    savePayload: {},
    isSaveAndPublish: false,
};

PublishModal.propTypes = {
    setView: PropTypes.func.isRequired,
    sendPublishRequest: PropTypes.func.isRequired,
    customBmId: PropTypes.number.isRequired,
    customBmName: PropTypes.string.isRequired,
    savePayload: PropTypes.object,
    setActionCode: PropTypes.func.isRequired,
    setTab: PropTypes.func.isRequired,
    isSaveAndPublish: PropTypes.bool,
};

export default PublishModal;
