import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import './index.scss';
import { Accordion, AccordionDetails, MenuItem, Select, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { KeyboardArrowDownSharp, ArrowForward } from '@material-ui/icons';
import { Helmet } from 'react-helmet';
import InfoIcon from '../../Diagnose/assets/info-feedback.svg';
import ArrowBackSm from '../../Diagnose/assets/Small-left.svg';
import ArrowForwardIcon from '../../Diagnose/assets/Small-right.svg';
import ArrowDoubleBack from '../../Diagnose/assets/Double-arrow-left.svg';
import ArrowDoubleForward from '../../Diagnose/assets/Double-arrow-right.svg';
import CloudImage from '../assets/InsufficientData1.svg';
import Paper from '../assets/paper.svg';
import Detail from '../assets/detail.svg';
import searchBar from '../../UserReports/assets/zoom.svg';
// import Down from '../../Diagnose/assets/Down.svg';
import Edit from '../assets/Edit.svg';
import Publish from '../assets/upload.svg';
// import ActiveFilter from '../../Diagnose/assets/ActiveFilters1.svg';
// import FilterSvg from '../../Diagnose/assets/Filter.svg';
import { getStaticText } from '../../../utils/constants';
import { STATUS_ICONS } from '../constants';
// import { getStaticTextAnalytics } from '../constants';
// import colors from '../../../sass/colors';
import { getFilterArrFromFilterStr } from '../assets/functions';
import PublishModal from './PublishModal';
import PublishReqModal from './PublishReqModal';
import { USNumFormat } from '../../../utils/functions';
import DuplicateModal from './DuplicateModal';

function CustomBmDetails({
    defaultSettings,
    duplicateCustomBmRequest,
    setTab,
    customBmDetail,
    defaultFilters,
    sendCustomBmRequest,
    approveRequest,
    rejectRequest,
    setActionCode,
    actionCode,
}) {
    const { lang = '1033' } = defaultSettings || {};
    // const staticTextAnalytics = getStaticTextAnalytics(lang);
    // const { ANALYTICS_QUARTILE_COLORS, FLAGPOLE_LINK } = staticTextAnalytics;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText || {};
    const { ANALYTIC_CUSTOM_BM_TEXT, FILTERS_APPLIED } = SITE_TEXT || {};
    const [page, setPage] = useState(1);
    const [min, setMinSlice] = useState(0);
    const [max, setMaxSlice] = useState(5);
    const [entryCount, setEntryCount] = useState(10);
    const [publishModal, setPublishModal] = useState(false);
    const [publishReqModal, setPublishReqModal] = useState(false);
    const [duplicateModal, setDuplicateModal] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const {
        survey_details = [],
        created_on = '',
        approved_on = '',
        submitted_on = '',
        created_by = '',
        status = '',
        type = '',
        no_of_surveys = '',
        no_of_responses = '',
        name = '',
        filter_str = '',
        id = '',
    } = customBmDetail || {};
    const is_approver = true;
    const [customBmList, setFilteredData] = useState(survey_details);

    const CREATE_CUSTOM_BM = [
        {
            key: 'uniquesurveyreferencecode',
            title: 'OHI ID',
            width: '8vw',
        },
        {
            key: 'confirmitsurveyid',
            title: 'PID',
            width: '15vw',
        },
        {
            key: 'surveyclientname',
            title: 'Organisation Name',
            width: '20vw',
        },
        {
            key: 'totalcomplete',
            title: 'Complete Responses',
            width: '6vw',
            align: 'right',
        },
        {
            key: 'bm_year',
            title: 'Benchmarking Year',
            width: '8vw',
            align: 'center',
        },
        {
            key: 'quartile',
            title: 'Global Quartile',
            width: '10vw',
        },
        {
            key: 'mckinseysectorlevel1',
            title: 'Sector Level 1',
            width: '20vw',
        },
        {
            key: 'mckinseysectorlevel2',
            title: 'Sector Level 2',
            width: '20vw',
        },
        {
            key: 'region',
            title: 'Region',
            width: '10vw',
        },
        {
            key: 'subregion1',
            title: 'Subregion (high level)',
            width: '15vw',
        },
        {
            key: 'subregion2',
            title: 'Subregion (detailed)',
            width: '15vw',
        },
        {
            key: 'country',
            title: 'Country',
            width: '15vw',
        },
        {
            key: 'close_date',
            title: 'Closed Date',
            width: '10vw',
        },
        // {
        //     key: 'confirmitsurveyid',
        // },
        // {
        //     key: 'uniquesurveyreferencecode',
        // },
    ];

    const formattedDate = val => {
        return val ? moment(val).format('DD-MMM-YYYY') : '';
    };

    const renderStatusIcon = status_type => {
        const { imgSrc, alternateImgSrc } = STATUS_ICONS.find(({ statusType }) => statusType === status_type) || {};
        return (
            <span>
                <img src={status_type === 'Draft' ? alternateImgSrc : imgSrc} alt="" />
            </span>
        );
    };

    const BREADCRUMB = {
        0: 'Your Benchmark',
        1: 'Requested Benchmarks',
        2: 'Rejected Benchmarks',
        4: 'Your Benchmark',
        6: 'Benchmark library',
    };

    const getFilterPreview = filtersArr => {
        const arr = [];
        filtersArr.forEach(({ filter_name, filter_items = [] }) => {
            filter_items
                .filter(({ isSelected }) => isSelected)
                .forEach(({ filter_item_name }) => {
                    arr.push(`${filter_name} - ${filter_item_name}`);
                });
        });
        return arr;
    };

    const renderfilterApplied = () => {
        const filterStr = getFilterArrFromFilterStr(defaultFilters, filter_str);
        const appliedFilters = getFilterPreview(filterStr);
        return (
            <div className="filterAppliedContent">
                <div className="filterDemo">
                    <div className="heading">{FILTERS_APPLIED}:</div>
                    {appliedFilters.map(elem => (
                        <div className="filterContent">
                            <div className="filterHighlight filterKey filterLab"> {elem} </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const lastPage = Math.ceil(customBmList.length / entryCount);

    const duplicateRequest = () => {
        duplicateCustomBmRequest(id);
        setTab(0);
    };

    const normalizeString = str => {
        if (!str) return '';
        return str.toLowerCase().replace(/\s+/g, '');
    };

    const handleSearchChange = e => {
        const { value } = e.target;
        setSearchInput(value);
        const normalizedInput = normalizeString(value);

        const filtered = survey_details.filter(
            ({ surveyclientname = '', uniquesurveyreferencecode = '' }) =>
                normalizeString(surveyclientname).includes(normalizedInput) ||
                normalizeString(uniquesurveyreferencecode).includes(normalizedInput)
        );
        setFilteredData(filtered);
    };

    return (
        <div className="customBmList customBmDetails">
            <Helmet>
                <title>{ANALYTIC_CUSTOM_BM_TEXT['1']} </title>
            </Helmet>
            <div className="bmDetailsHeader">
                <div className="breadCrumb">
                    <span data-testid="setScoreFromBuBtn" onClick={() => setTab(actionCode)} className="greytext">
                        {BREADCRUMB[actionCode]}
                    </span>{' '}
                    {' > '} <span>{name}</span>
                </div>
            </div>
            <div className="rightScorecardHeader">
                <div className="scorecardHeading">
                    <div className="sectionHeader">
                        <span>
                            <img src={Paper} alt="" />
                        </span>
                        Custom Benchmark Name: <b>{name}</b>
                    </div>
                    {/* <div className="nRes">{`${NUMBER_CAP_SURVEY_TEXT} (${NUMBER_TEXT}) : ${n_survey}`}</div> */}
                </div>
                <ul className="navScoreList">
                    {status === 'Draft' ? (
                        <Fragment>
                            <li onClick={() => setTab(5)}>
                                <img src={Edit} alt="" />
                                <span className="upperHead">EDIT</span>
                            </li>
                            <li onClick={() => setPublishModal(true)}>
                                <img src={Publish} alt="" />
                                <span className="upperHead">PUBLISH</span>
                            </li>
                        </Fragment>
                    ) : null}
                    {/* <li data-testid="getPptBtn">
                        <img src={Down} alt="" />
                        <span className="upperHead">DOWNLOAD</span>
                    </li> */}
                    <li onClick={() => setDuplicateModal(true)}>
                        <img src={Detail} alt="" />
                        <span className="upperHead">DUPLICATE</span>
                    </li>
                </ul>
            </div>
            {is_approver && actionCode === 1 ? (
                <div className="bottomCard">
                    <span>
                        You have a publish request for this benchmark
                        <span className="arrowIcon" onClick={() => setPublishReqModal(true)}>
                            VIEW REQUEST
                            <ArrowForward />
                        </span>
                    </span>
                </div>
            ) : null}
            <div className="bmDetailsInfoCard">
                <div className="bmDetailsInfo">
                    <div className="title">No. of Surveys</div>
                    <div className="bold">{USNumFormat(no_of_surveys)}</div>
                </div>
                <div className="bmDetailsInfo">
                    <div className="title">No. of Responses</div>
                    <div className="bold">{USNumFormat(no_of_responses)}</div>
                </div>
                <div className="bmDetailsInfo">
                    <div className="title">Created On</div>
                    <div className="bold">{formattedDate(created_on)}</div>
                </div>
                <div className="bmDetailsInfo">
                    <div className="title">
                        Type
                        <Tooltip
                            classes={{ popper: 'cbmTooltip' }}
                            arrow
                            title={
                                <span>
                                    Types of Benchmark <br /> Flagpole (to create a flagpole number of survey needs to
                                    be 10 or more) <br /> <br /> Benchmark (to create a benchmark number of survey needs
                                    to be 25 or more)
                                </span>
                            }
                        >
                            <span className="infoIcon">
                                <img src={InfoIcon} alt="" />
                            </span>
                        </Tooltip>
                    </div>
                    <div className="bold">{type}</div>
                </div>
                <div className="bmDetailsInfo status">
                    <div className="title">
                        Status
                        <Tooltip
                            classes={{ popper: 'cbmTooltip' }}
                            arrow
                            title={
                                <span>
                                    Status Journey: <br /> 1. Draft <br /> 2. Awaiting approval <br /> 3. Published
                                </span>
                            }
                        >
                            <span className="infoIcon">
                                <img src={InfoIcon} alt="" />
                            </span>
                        </Tooltip>
                    </div>
                    <div className="status">
                        <div
                            className={c({
                                draft: status === 'Draft',
                                awaiting: status === 'Awaiting Approval',
                                rejected: status === 'Rejected',
                                approved: status === 'Approved',
                            })}
                        >
                            {renderStatusIcon(status)}
                            {status}
                        </div>
                    </div>
                </div>
                {actionCode === 4 ? (
                    <div className="bmDetailsInfo">
                        <div className="title">Attached to</div>
                        <div className="bold ellipsisPro">{created_by}</div>
                    </div>
                ) : null}
                {actionCode === 6 ? (
                    <div className="bmDetailsInfo">
                        <div className="title">Created by</div>
                        <div className="bold">{created_by}</div>
                    </div>
                ) : null}
                {submitted_on ? (
                    <div className="bmDetailsInfo">
                        <div className="title">Submitted On</div>
                        <div className="bold">{formattedDate(submitted_on)}</div>
                    </div>
                ) : null}
                {approved_on ? (
                    <div className="bmDetailsInfo">
                        <div className="title">{actionCode === 2 ? 'Rejected On' : 'Approved On'}</div>
                        <div className="bold">{formattedDate(approved_on)}</div>
                    </div>
                ) : null}
            </div>
            {renderfilterApplied()}
            <div className="optionWrapper">
                <div className="differentiatorToggle">
                    <ul className="leftNav detailListHeader">
                        <li>Survey List</li>
                    </ul>
                    <div className="searchBar">
                        <input
                            type="text"
                            placeholder="Search"
                            value={searchInput}
                            onChange={handleSearchChange}
                            className="search"
                        />
                        <div className="searchIcon">
                            <img src={searchBar} alt="" />
                        </div>
                    </div>
                </div>
                <div className="rightOptions">
                    {/* <div>
                        <ul className="rightSideNav">
                            <li className="strong">{`Number of Surveys (n) = ${n_survey}`}</li>
                            <li
                                onClick={() => toggleFilterView(true)}
                                data-testid="filterButton"
                                className="filterButton"
                            >
                                <img src={filtersApplied ? ActiveFilter : FilterSvg} alt="" />
                                {FILTERS}
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>
            <div className="userReportsContent xScroll">
                <Accordion classes={{ root: 'accordionRoot' }} key="table" expanded>
                    <AccordionDetails classes={{ root: 'clearfix' }}>
                        <table className="userSection">
                            <tbody className="userContent">
                                <thead className="headerSection">
                                    {CREATE_CUSTOM_BM.map(({ key, title, width }) => (
                                        <th className="headerHeading" key={key} style={{ width }}>
                                            {title}
                                            {key === 'status' ? (
                                                <span className="infoIcon">
                                                    <Tooltip
                                                        // title="Ticket Journey: <br/> 1. To Do"
                                                        classes={{ popper: 'cbmTooltip' }}
                                                        arrow
                                                        title={
                                                            <span>
                                                                Ticket Journey: <br /> 1. To Do <br /> 2. In Progress{' '}
                                                                <br /> 3. QA Ready <br /> 4. Code Review <br /> 5. Done{' '}
                                                            </span>
                                                        }
                                                    >
                                                        <span />
                                                        {/* <img src={InfoIcon} alt="" /> */}
                                                    </Tooltip>
                                                </span>
                                            ) : null}
                                        </th>
                                    ))}
                                </thead>

                                {!!customBmList.length &&
                                    customBmList.slice((page - 1) * entryCount, page * entryCount).map(obj => {
                                        const { uniquesurveyreferencecode } = obj || {};
                                        return (
                                            <tr key={uniquesurveyreferencecode} className="userDataSection">
                                                {CREATE_CUSTOM_BM.map(({ key, width, align = '' }) => {
                                                    let dataValue =
                                                        key === 'close_date'
                                                            ? moment(obj[key]).format('MMM-YYYY')
                                                            : USNumFormat(obj[key]);
                                                    if (key === 'bm_year') {
                                                        dataValue = obj[key];
                                                    }
                                                    return (
                                                        <Tooltip
                                                            classes={{ popper: 'cbmTooltip' }}
                                                            title={obj[key]}
                                                            arrow
                                                        >
                                                            <td
                                                                className="userTableContent ellipsisPro"
                                                                key={key}
                                                                style={{ width, textAlign: align }}
                                                            >
                                                                {dataValue}
                                                            </td>
                                                        </Tooltip>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                {!customBmList.length ? (
                                    <div className="emptyList">
                                        <div>
                                            <h3>No survey list available</h3>
                                            <img src={CloudImage} alt="" />
                                        </div>
                                    </div>
                                ) : null}
                            </tbody>
                        </table>
                    </AccordionDetails>
                </Accordion>
                {customBmList.length > entryCount ? (
                    <div className="pagination">
                        <span
                            onClick={() => {
                                if (min - 4 <= 0) return null;
                                setPage(min - 4);
                                setMaxSlice(max - 5);
                                setMinSlice(min - 5);
                                return null;
                            }}
                            className={c({ inActive: page === 1 }, 'arrowBtn')}
                        >
                            <img src={ArrowDoubleBack} alt="" />
                        </span>
                        <span
                            onClick={() => {
                                setPage(page - 1);
                                if (page - 1 <= min) {
                                    setMaxSlice(max - 5);
                                    setMinSlice(min - 5);
                                }
                            }}
                            className={c({ inActive: page === 1 }, 'arrowBtn')}
                        >
                            <img src={ArrowBackSm} alt="" />
                        </span>
                        {Array(lastPage)
                            .fill(1)
                            .map((_, i) => i + 1)
                            .slice(min, max)
                            .map(index => {
                                return (
                                    <span
                                        key={index + 1}
                                        className={c({ activePage: page === index })}
                                        onClick={() => {
                                            setPage(index);
                                        }}
                                    >
                                        {index}
                                    </span>
                                );
                            })}
                        <span
                            onClick={() => {
                                setPage(page + 1);
                                if (page >= max) {
                                    setMaxSlice(max + 5);
                                    setMinSlice(min + 5);
                                }
                            }}
                            className={c({ inActive: page === lastPage }, 'arrowBtn')}
                        >
                            <img src={ArrowForwardIcon} alt="" />
                        </span>
                        <span
                            onClick={() => {
                                if (min + 5 > lastPage) return null;
                                setPage(min + 6);
                                setMinSlice(min + 5);
                                setMaxSlice(max + 5);
                                return null;
                            }}
                            className={c({ inActive: page === lastPage }, 'arrowBtn')}
                        >
                            <img src={ArrowDoubleForward} alt="" />
                        </span>
                        <span className="resultsPerPage">
                            Results Per Page{' '}
                            <span>
                                <Select
                                    IconComponent={KeyboardArrowDownSharp}
                                    value={entryCount}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    onChange={({ target }) => {
                                        setEntryCount(target.value);
                                    }}
                                >
                                    {[10, 11, 12].map(pageItem => {
                                        return (
                                            <MenuItem
                                                classes={{ root: 'demographicOptions' }}
                                                key={pageItem}
                                                value={pageItem}
                                            >
                                                {pageItem}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </span>
                        </span>
                    </div>
                ) : null}
            </div>
            {publishModal ? (
                <PublishModal
                    setView={setPublishModal}
                    sendPublishRequest={sendCustomBmRequest}
                    customBmId={id}
                    customBmName={name}
                    setActionCode={setActionCode}
                    setTab={setTab}
                />
            ) : null}
            {publishReqModal ? (
                <PublishReqModal
                    setView={setPublishReqModal}
                    sendPublishRequest={sendCustomBmRequest}
                    customBmId={id}
                    creatorName={created_by}
                    approveRequest={approveRequest}
                    rejectRequest={rejectRequest}
                    setActionCode={setActionCode}
                    setTab={setTab}
                    customBmName={name}
                />
            ) : null}
            {duplicateModal ? (
                <DuplicateModal setView={setDuplicateModal} duplicateCustomBm={duplicateRequest} customBmName={name} />
            ) : null}
        </div>
    );
}

CustomBmDetails.propTypes = {
    defaultSettings: PropTypes.func.isRequired,
    setTab: PropTypes.func.isRequired,
    customBmDetail: PropTypes.object.isRequired,
    defaultFilters: PropTypes.array.isRequired,
    sendCustomBmRequest: PropTypes.func.isRequired,
    approveRequest: PropTypes.func.isRequired,
    rejectRequest: PropTypes.func.isRequired,
    setActionCode: PropTypes.func.isRequired,
    actionCode: PropTypes.number.isRequired,
    duplicateCustomBmRequest: PropTypes.func.isRequired,
};

export default CustomBmDetails;
