import React, { useEffect, useState, useMemo, Fragment } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import { cloneDeep } from 'lodash';
import { getStaticText } from '../../../../utils/constants';
import getStaticTextDiagnose from '../../constants';
import { getColor, getNegColor, manipulateHeatmapTitle } from '../../../../utils/functions';
import colors from '../../../../sass/colors';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import ArrowRight from '../../assets/ArrowRight.svg';
import ArrowUp from '../../assets/ArrowUp.svg';
import ArrowDown from '../../assets/ArrowDown.svg';

function DeepDive({
    filters,
    selectedDemographic,
    ieHeatmap,
    ieHeatmapFetched,
    sortIeHeatmap,
    defaultSettings,
    apiParams,
    getHeatMapPractice,
    heatMapPracticeFetched,
    heatMapPractice,
    callHeatMap,
    selectedTab,
}) {
    const SLICE_MAX_VALUE = 8;
    const SLICE_MIN_VALUE = 0;
    const { quartileColors = [], demographic = '', survey_version = '', lang: langTxt = '' } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(langTxt);
    const { MAX_LENGTH_SUBSTRING, PRACTICE_VAR, SORT_ASCENDING, SORT_DESCENDING } = staticTextDiagnose;
    const staticText = getStaticText(langTxt);
    const { SITE_TEXT, IND_EXP_SCORE_CLASSIFICATION } = staticText;
    const {
        N_SIZE,
        OHI_SCORE_TEXT,
        NEGATIVE_PRACTICES,
        PRACTICE_CATEGORY_MAP,
        PRACTICE_CATEGORY_MAP_3_2,
        OHI_PRACTICE,
    } = SITE_TEXT;
    const { demographics = [] } = filters[0] || {};
    const { header = [], items = [] } = ieHeatmap || {};
    const { items: ohiItems = [], header: ohiHeader = [] } = heatMapPractice || {};
    const [minSliceValue, setMinSliceValue] = useState(SLICE_MIN_VALUE);
    const [maxSliceValue, setMaxSliceValue] = useState(SLICE_MAX_VALUE);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const [sortOrder, setSortOrder] = useState({});
    const is3_2survey = survey_version === '3_2';
    const mapToUse = is3_2survey ? PRACTICE_CATEGORY_MAP_3_2 : PRACTICE_CATEGORY_MAP;

    const addOns = {
        fill_threshold_with: demographic === '1' ? '-' : '',
        others: demographic === '3' ? 1 : 0,
        columns: 10000,
    };

    const headerIE = useMemo(() => {
        if (!items.length) return [];
        return [...items.map(({ title = '' }) => title)];
        // eslint-disable-next-line
    }, [ieHeatmap]);

    const dataToUse = useMemo(() => {
        if (!items.length) return [];
        const newArray = [];
        const innerArray = [];
        items.forEach(({ items: subItem = [] }) => {
            innerArray.push(subItem);
        });
        // eslint-disable-next-line no-unused-vars
        header.forEach(_elem => {
            newArray.push([]);
        });
        for (let i = 0; i < innerArray.length; i += 1) {
            // const { items: subItems } = items
            for (let j = 0; j < header.length; j += 1) {
                newArray[j].push(innerArray[i][j]);
            }
        }

        return newArray;
        // eslint-disable-next-line
    }, [ieHeatmap, maxSliceValue, minSliceValue, headerIE]);

    // Condition to set table data when no of columns are different in ohi heatmap and ee heatmap due to different threshold
    const dataToUseOhi = useMemo(() => {
        if (!ohiItems.length) return [];
        const newItems = [];
        if (headerIE.length === ohiHeader.length) return ohiItems;
        if (ohiHeader.length > headerIE.length) {
            const headerDiff = [];
            ohiHeader.forEach((item, index) => {
                if (!headerIE.includes(item)) {
                    headerDiff.push(index);
                }
            });
            ohiItems.forEach(({ items: sub_items = [], ...rest }) => {
                const subItem = cloneDeep(sub_items);
                newItems.push({
                    ...rest,
                    items: subItem.filter((_, i) => !headerDiff.includes(i)),
                });
            });
        } else {
            ohiItems.forEach(({ items: sub_items = [], ...rest }) => {
                let subItem = cloneDeep(sub_items);
                const headerDiff = [];
                headerIE.forEach((item, index) => {
                    if (!ohiHeader.includes(item)) {
                        headerDiff.push(index);
                    }
                });
                headerDiff.forEach(index => {
                    subItem = [...subItem.slice(0, index), { score: '-', quartile: '999' }, ...subItem.slice(index)];
                });
                newItems.push({
                    ...rest,
                    items: subItem,
                });
            });
        }
        return newItems;
        // eslint-disable-next-line
    }, [heatMapPractice, headerIE, ieHeatmap]);

    const headerToUse = headerIE;

    useEffect(() => {
        if (!heatMapPracticeFetched) {
            getHeatMapPractice({
                ...apiParams,
                ...addOns,
                type: PRACTICE_VAR,
                demographic: selectedDemographic || demographics[0].code,
            });
        }
        // eslint-disable-next-line
    }, [heatMapPracticeFetched]);

    useEffect(() => {
        if (items.length - 1 >= maxSliceValue) {
            setShowRightArrow(true);
        } else {
            setShowRightArrow(false);
        }
        // eslint-disable-next-line
    }, [maxSliceValue, items]);

    const getHeapMapValues = itemsToIterate => {
        const { title: practiceCategory, children } = mapToUse[selectedTab];
        return itemsToIterate
            .filter((_elem, parentI) => children.includes(header[parentI]) || !header[parentI] === 'N')
            .map((subItems, parentI) => {
                const title = header.filter(headerN => children.includes(headerN) || !header[parentI] === 'N')[parentI];
                const isNegativeTrait = NEGATIVE_PRACTICES.includes(title);
                return (
                    <div className="sectionDiv" key={title}>
                        {!parentI ? (
                            <ul>
                                <li className="sectionContent headerContent">{practiceCategory}</li>
                            </ul>
                        ) : null}
                        <ul>
                            <Tooltip placement="top" arrow title={title} aria-label={title}>
                                <li className="sectionContent">{title}</li>
                            </Tooltip>
                            {subItems.slice(minSliceValue, maxSliceValue).map(({ score: innerScore }, index) => {
                                const i = getColor(innerScore, [], false, true);
                                const iNeg = getNegColor(innerScore, [], false, true);
                                const { positiveBg = '#ffffff', negativeBg = '#ffffff', color = '#ffffff' } =
                                    IND_EXP_SCORE_CLASSIFICATION[isNegativeTrait ? iNeg : i] || {};
                                const bg = isNegativeTrait ? negativeBg : positiveBg;
                                const background = bg;
                                return (
                                    <li
                                        className="contentList"
                                        key={`${innerScore}${index}${Math.random()}`}
                                        style={{
                                            border: `1px solid white`,
                                            background,
                                            color,
                                        }}
                                    >
                                        {!isNaN(parseInt(innerScore, 10)) ? innerScore : '-'}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            });
    };

    const getOhiHeapMapValues = arrItems => {
        if (!arrItems.length) return null;
        const { children_ohi_slug } = mapToUse[selectedTab];
        const itemsToIterate = [];
        children_ohi_slug.forEach(elem => {
            itemsToIterate.push(arrItems.find(({ slug = '' }) => slug === elem || elem === 'n'));
        });
        return itemsToIterate.map(({ items: subItems = [], title: rowTitle = '' }, parentI) => {
            const title = manipulateHeatmapTitle(rowTitle);
            return (
                <div className="sectionDiv" key={title}>
                    {parentI === 1 ? (
                        <ul>
                            <li className="sectionContent headerContent">{OHI_PRACTICE}</li>
                        </ul>
                    ) : null}
                    <ul>
                        <Tooltip placement="top" arrow title={title} aria-label={title}>
                            <li className="sectionContent">{title}</li>
                        </Tooltip>
                        {subItems.slice(minSliceValue, maxSliceValue).map(({ score: innerScore, quartile }, index) => {
                            const {
                                background = colors.$white,
                                color = colors.$darkBlue100,
                                border: borderColor = '',
                            } = quartileColors[quartile] || {};
                            return (
                                <li
                                    className={c({ nSize: !parentI }, 'contentList')}
                                    key={`${innerScore}${index}${Math.random()}`}
                                    style={{
                                        border: `1px solid white`,
                                        background: borderColor
                                            ? `linear-gradient(135deg, ${borderColor} 15%, ${background} 15%)`
                                            : background,
                                        color,
                                    }}
                                >
                                    {!isNaN(parseInt(innerScore, 10)) ? innerScore.toLocaleString('en-US') : '-'}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        });
    };

    const sortData = type => {
        if (type === N_SIZE || type === OHI_SCORE_TEXT) {
            setSortOrder({ [type]: sortOrder[type] === SORT_ASCENDING ? SORT_DESCENDING : SORT_ASCENDING });
            const arr = [];
            items.map(({ items: subItems = [] }, i) => {
                const { score = '' } = type === N_SIZE ? subItems[0] || {} : subItems[1] || {};
                arr.push(score + '#' + i);
                return items;
            });
            const sortArr =
                sortOrder[type] === 1
                    ? arr.slice(1, arr.length).sort((a, b) => a.split('#')[0] - b.split('#')[0])
                    : arr.slice(1, arr.length).sort((a, b) => b.split('#')[0] - a.split('#')[0]);
            sortArr.unshift(arr[0]);
            const indexArr = [];
            sortArr.map(val => {
                return indexArr.push(arr.indexOf(val));
            });
            const output = indexArr.map(i => items[i]);
            sortIeHeatmap(output);
        }
    };

    useEffect(() => {
        if (!ieHeatmapFetched) {
            callHeatMap(demographics[0].code);
        }
        // eslint-disable-next-line
    }, [ieHeatmapFetched, maxSliceValue]);

    return (
        <Fragment>
            <section className="sectionWrapper">
                <div id="myHeader" className={c('headerWrapper clearfix')}>
                    {showLeftArrow ? (
                        <div
                            data-testid="arrowLeft"
                            className="arrow"
                            onClick={() => {
                                setMaxSliceValue(minSliceValue);
                                setMinSliceValue(minSliceValue - (SLICE_MAX_VALUE - SLICE_MIN_VALUE));
                                setShowRightArrow(maxSliceValue >= SLICE_MAX_VALUE);
                                setShowLeftArrow(minSliceValue > SLICE_MAX_VALUE);
                            }}
                        >
                            <img className="imgClass" src={ArrowLeft} alt="" />
                        </div>
                    ) : null}

                    <div>
                        {headerToUse.length ? (
                            <ul className={c({ tabLeft: true })}>
                                {headerToUse &&
                                    headerToUse.slice(minSliceValue, maxSliceValue).map((val, i) => {
                                        const cellVal = manipulateHeatmapTitle(val);
                                        return (
                                            <Tooltip
                                                key={`${val}${i}`}
                                                placement="top"
                                                arrow
                                                title={val}
                                                aria-label={val}
                                            >
                                                <li className="headerList" onClick={() => sortData(val)}>
                                                    {cellVal.length > MAX_LENGTH_SUBSTRING
                                                        ? `${cellVal.substring(0, MAX_LENGTH_SUBSTRING) + '...'}`
                                                        : cellVal}
                                                    {cellVal === OHI_SCORE_TEXT && (
                                                        <img
                                                            className="arrowSort"
                                                            src={sortOrder[val] === 1 ? ArrowUp : ArrowDown}
                                                            alt=""
                                                        />
                                                    )}
                                                </li>
                                            </Tooltip>
                                        );
                                    })}
                            </ul>
                        ) : null}
                    </div>
                    {showRightArrow ? (
                        <div
                            data-testid="arrowRight"
                            className="arrow arrowRight"
                            onClick={() => {
                                setMinSliceValue(maxSliceValue);
                                setMaxSliceValue(maxSliceValue + SLICE_MAX_VALUE);
                                setShowRightArrow(maxSliceValue + SLICE_MAX_VALUE <= header.length);
                                setShowLeftArrow(maxSliceValue + SLICE_MAX_VALUE > SLICE_MAX_VALUE);
                            }}
                        >
                            <img className="imgClass" src={ArrowRight} alt="" />
                        </div>
                    ) : null}
                </div>
                <div className="contentWrapper clearfix">
                    <div className="mainContent">{getOhiHeapMapValues(dataToUseOhi)}</div>
                </div>
                <div className="contentWrapper clearfix">
                    <div className="mainContent">{getHeapMapValues(dataToUse)}</div>
                </div>
            </section>
        </Fragment>
    );
}

DeepDive.propTypes = {
    filters: PropTypes.array.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
    selectedDemographic: PropTypes.string.isRequired,
    getHeatMapPractice: PropTypes.func.isRequired,
    heatMapPracticeFetched: PropTypes.bool.isRequired,
    heatMapPractice: PropTypes.object.isRequired,
    callHeatMap: PropTypes.func.isRequired,
    ieHeatmap: PropTypes.object.isRequired,
    ieHeatmapFetched: PropTypes.bool.isRequired,
    sortIeHeatmap: PropTypes.func.isRequired,
    selectedTab: PropTypes.number.isRequired,
};

export default DeepDive;
