import { createSelector } from 'reselect';

const compendium = state => state.get('compendium');

const selectApiCount = () => createSelector(compendium, state => state.toJS().count);
const selectOptionList = () => createSelector(compendium, state => state.toJS().optionList);
const selectOptionData = () => createSelector(compendium, state => state.toJS().optionData);
const selectOptionDataFetched = () => createSelector(compendium, state => state.toJS().optionListFetched);
const getCompendiumData = () => createSelector(compendium, state => state.toJS().compendiumData);
const getCompendiumDataFetched = () => createSelector(compendium, state => state.toJS().compendiumDataFetched);
const getErrorInfo = () => createSelector(compendium, state => state.toJS().error);
const getActionError = () => createSelector(compendium, state => state.toJS().actionError);
const getActionCreated = () => createSelector(compendium, state => state.toJS().actionCreated);
const getActionLoading = () => createSelector(compendium, state => state.toJS().actionLoading);

export {
    selectApiCount,
    selectOptionList,
    selectOptionData,
    selectOptionDataFetched,
    getCompendiumData,
    getCompendiumDataFetched,
    getErrorInfo,
    getActionError,
    getActionCreated,
    getActionLoading,
};
