import colors from '../../sass/colors';
import CompanySurveyImg from '../../containers/Diagnose/assets/CompanyValuesIcon.svg';
import UpArrow from '../../containers/Diagnose/assets/arrow-up.svg';
import DownArrow from '../../containers/Diagnose/assets/arrow-down.svg';
import SquareMinus from '../../containers/Diagnose/assets/minus-square.svg';
import NoSig from '../../containers/Diagnose/assets/no_significance.svg';

export const NONE = 'Ninguno';
export const CUSTOM = 'Personalizado';
export const NONE_10 = 'Ninguno';

export const SITE_TEXT = {
    ERROR_400_MSG:
        'La página que está buscando no existe. Es posible que haya escrito mal la dirección o que la página haya cambiado de dirección.',
    LOADING: 'Loading',
    BUBBLE_CHART: 'Gráficos de burbujas',
    HEALTH_NAV_LINKS: [
        'Cuadro de mando',
        'Perfil',
        'Experiencia del empleado',
        'Clasificación de prácticas',
        'Cuadro de mando de receta',
        'Demográfico',
        'Criterio de referencia',
        'Cohesión',
        'Preguntas',
        'Analítica de texto',
        '',
    ],
    INCLUSION_NAV_LINKS: [
        'Descripción general',
        'Encuestados',
        'Cuadro de mando',
        'Demográfico',
        'Preguntas',
        'Clasificación de prácticas',
        'Nueva encuesta',
    ],
    SURVEY_NAV_LINKS: ['Respuestas día a día', 'Respuestas en términos de demografía', 'Entre pestañas'],
    SETTINGS_NAV_LINKS: ['Personalización', 'Personalizar colores de cuartil'],
    LONG_TEXT: 'Solo 18 carácteres ',
    REPORT_NAV_LINKS: [
        'Seleccionar informe',
        'Configuración general',
        'Comparar informes',
        'Opciones demográficas',
        'Práctica prioritaria y burbujas de comparación',
        'Gráfico de bandera y clasificación de práctica',
        'Opciones de resultados',
        'Opciones de práctica',
    ],
    REPORT_NAV_LINKS_V4: [
        'Seleccionar informe',
        'Configuración general',
        'Comparar informes',
        'Opciones demográficas',
        'Burbujas de comparación',
        'Gráfico de bandera y clasificación de práctica',
        'Opciones de resultados',
        'Opciones de práctica',
    ],
    NPS_NAV_LINKS: ['NPS', 'NPS por demografía'],
    ENGAGEMENT_NAV_LINKS: ['Compromiso', 'Compromiso por demografía'],
    IND_EXP_LINKS: ['Cuadro de mando', 'Preguntas', 'Mapa de calor', 'Análisis profundo'],
    ANALYTICS_NAV_LINKS: [
        'Introducción',
        'Descripción general',
        'Cuadro de mando',
        'Bandera',
        'Clasificación de prácticas',
        'Differentiator Analysis',
        'Custom Benchmark',
    ],
    ANALYTICS_RANKING_TABS: ['Clasificación de prácticas', 'Análisis diferenciador'],
    DIFFERENTIATOR_ANALYSIS_TABS: ['Cuartil dividido', 'Mediana dividida'],
    QUARTILE_TAB: ['Cuartil dividido'],
    CUSTOM_BM_ANALYSIS_TABS: [
        { tabName: 'Requested Benchmark', index: 1, restrictedAccess: true },
        { tabName: 'Rejected Benchmark', index: 2, restrictedAccess: true },
        { tabName: 'Your Benchmark', index: 0 },
        { tabName: 'Benchmark library', index: 6 },
    ],
    ENGAGEMENT_HEADING: 'Compromiso',
    INCLUSION_OVERVIEW: 'Descripción general de Inclusión',
    ADD_ON: [{ label: 'Valores de la empresa', img: CompanySurveyImg, codeN: 0 }],
    ADD_ON_NP: [{ label: 'Text Comments', img: CompanySurveyImg, codeN: 0 }],
    ADD_ON_VIEWS: ['NPS', 'Compromiso'],
    ADD_ON_VIEWS_ING: [
        { label: 'NPS', codeN: 1, img: CompanySurveyImg },
        { label: 'Compromiso', codeN: 2, img: CompanySurveyImg },
    ],
    ADD_ON_VIEWS_TALANX: [{ label: 'Informe de BU personalizado', codeN: 3, img: CompanySurveyImg }],
    NET_PROMOTER_SCORE: 'Puntuación neta del promotor',
    NPS_HEADING: 'Puntuación neta del promotor por empleado',
    CUSTOM_BU_HEADING: 'Informe de BU personalizado',
    NET_PROMOTER_SCORE_DESC: '(Promoters - Detractors)',
    PROMOTERS: 'Promotores',
    PROMOTERS_DESC: 'answer (9 - 10)',
    PASSIVES: 'Pasivos',
    PASSIVES_DESC: 'answer (7 - 8)',
    DETRACTORS: 'Detractores',
    DETRACTORS_DESC: 'answer (0 - 6)',
    REPORTS_USER_PAGE: 'Página de usuarios de informes',
    ANALYTICS_LANDING_PAGE: 'Página de inicio de Analítica de OHI',
    INCLUSION_QUESTIONS_BOARD: 'Tablero de preguntas de Inclusión',
    INCLUSION_RESPONDENTS: 'Encuestados sobre Inclusión',
    NPS_NOTE: [
        '1. Preguntas respondidas en una escala de 11 puntos que va desde 0 = extremadamente improbable hasta 10 = extremadamente probable.',
        '2. El puntaje neta de promotores de empleados (eNPS) se calcula restando el % de detractores del % de promotores y puede ir de -100 a 100.',
    ],
    ENGAGEMENT_NOTE: [
        '1. Compromiso laboral: Un estado mental positivo y gratificante relacionado con el trabajo que se caracteriza por sentimientos de dedicación, absorción y felicidad derivados del trabajo.',
        '2. Compromiso organizacional: Un apego emocional a ING que se caracteriza por sentirse partícipe y disfrutar trabajando para la organización.',
    ],
    IND_EXP_QUESTION_NOTE: [
        '1. Las puntuaciones altas no son deseables, ya que indican el porcentaje de empleados que respondieron “De acuerdo” o “Muy de acuerdo” a factores negativos de experiencia individual.',
        '2. Porcentaje de “Promotores” que respondieron “9” o “10” a la pregunta “¿Qué probabilidades hay de que recomiende trabajar en la organización a amigos y familiares?”, en una escala que va desde 0 - Extremadamente improbable a 10 - Extremadamente probable',
        '3. rxy es la correlación bivariada entre las respuestas individuales a los factores de Experiencia del empleado y las Puntuaciones de OHI a nivel de empleado',
    ],
    OHI4_RESURVEY_NOTE: [
        '1. Los resultados estadísticamente significativos tuvieron valores p de <0,05, lo que indica una alta probabilidad de que haya ocurrido un cambio real. La significación estadística se basa en el número de encuestados y la magnitud de la diferencia de puntos',
        '2. Las preguntas de práctica subyacentes tuvieron cambios de 2022 a 2023; es decir, se agregaron o eliminaron preguntas en función de las investigaciones y algunas prácticas también cambiaron de nombre; por ejemplo, Conducta de los empleados antes se llamaba Estándares profesionales. En adelante, la puntuación de 2023 debe considerarse el nuevo criterio de referencia.',
        '3. Las prácticas correspondientes a conceptos completamente nuevos introducidos en el marco de OHI 4.0 no tienen puntuación disponible para el año anterior.',
        'Las prácticas correspondientes a conceptos completamente nuevos introducidos en el marco de OHI 4.0 no tienen puntuación disponible para el año anterior.',
    ],
    IND_QUESTIONS: [
        {
            title: 'Propósito',
            children: ['connection_to_meaning'],
        },
        {
            title: 'Desarrollo profesional',
            children: ['professional_growth', 'equitable_opportunity', 'career_customization'],
        },
        {
            title: 'Bienestar',
            children: ['psychological_safety', 'energy', 'Agotamiento laboral'],
        },
        {
            title: 'Retención de talento',
            children: ['job_satisfaction', 'intent_to_leave', 'endorsing_organization_to_others'],
        },
    ],
    IND_QUESTIONS_QUES: [
        {
            title: 'Todos',
            children: [
                'connection_to_meaning',
                'professional_growth',
                'equitable_opportunity',
                'career_customization',
                'endorsing_organization_to_others',
                'job_satisfaction',
                'intent_to_leave',
                'psychological_safety',
                'energy',
                'Agotamiento laboral',
            ],
        },
        {
            title: 'Propósito',
            children: ['connection_to_meaning'],
        },
        {
            title: 'Desarrollo profesional',
            children: ['professional_growth', 'equitable_opportunity', 'career_customization'],
        },
        {
            title: 'Atracción y retención de talento',
            children: ['endorsing_organization_to_others', 'job_satisfaction', 'intent_to_leave'],
        },
        {
            title: 'Bienestar',
            children: ['psychological_safety', 'energy', 'Agotamiento laboral'],
        },
    ],
    TARGET_HEADER: ['Tercer cuartil', 'Segundo cuartil', 'Cuartil superior', 'decil superior'],
    IND_QUES_LEGEND: ['En desacuerdo', 'Neutral', 'De acuerdo', 'No lo sé'],
    NPS_SCORE: 'Puntuación de NPS',
    NPS_QUES: '¿Qué tan probable es que recomiende ser cliente de ING a sus familiares, amigos y colegas?',
    JOB_ENGAGEMENT: 'Compromiso laboral',
    ORG_ENGAGEMENT: 'Compromiso organizacional',
    PULSE: 'Percepciones',
    INCLUSION_SURVEY_RESPONDENTS: '78,351',
    INCLUSION_SURVEY_RESPONDENTS_COUNT: 45,
    DIFF_TEXT: 'Dif.',
    ORG_TITLE: 'Organización',
    INCLUSION_SURVEY_RESPONDENTS_V_TEXT: 'v1 March 2022',
    INCLUSION_BENCHMARK_TEXT: 'N= 78,351 | No. surveys: 45 | v1 March 2022',
    DEMO_INCLUSION_TABS: [
        'Mapa de calor de puntuación',
        'Mapa de calor de brechas',
        'Gráfico de pulso',
        'Mapa de calor de puntuación de nueva encuesta',
        'Mapa de calor de brecha de nueva encuesta',
    ],
    SCORECARD_TABS: ['Cuadro de mando', 'Cuadro de mando de nueva encuesta'],
    SCORECARD_GROUP_TEXTS: [
        '¿Están las personas alineadas con la visión, la estrategia, la cultura y los valores de la organización?',
        '¿Pueden los empleados cumplir sus funciones con las capacidades, procesos y nivel de motivación actuales?',
        '¿Cómo entiende, interactúa, responde y se adapta la organización a su situación y entorno externo?',
    ],
    REPORT_FILTER: 'Filtro de informe',
    COMPARISON_FILTER: 'Filtro de comparación',
    SCORECARD_GROUP_HEAD: ['Alineación', 'Ejecución', 'Renovación'],
    PRACTICE_CATEGORY_MAP: [
        {
            title: 'Individual Propósito',
            children: ['Conexión con el significado'],
            children_ohi: [
                'N',
                'Propósito común',
                'Orientación al cliente',
                'Responsabilidad social',
                'Líderes inspiradores',
                'Incentivos financieros',
            ],
            children_ohi_slug: [
                'n',
                'common_purpose',
                'customer_orientation',
                'social_responsibility',
                'inspirational_leaders',
                'financial_incentives',
            ],
        },
        {
            title: 'Desarrollo profesional',
            children: [
                'Crecimiento profesional',
                'Oportunidad equitativa',
                'Personalización del desarrollo profesional',
            ],
            children_ohi: [
                'N',
                'Desarrollo de talento',
                'Oportunidades profesionales',
                'Asignación de talento',
                'Evaluación del desempeño de las personas',
                'Recompensas y reconocimiento',
            ],
            children_ohi_slug: [
                'n',
                'talent_development',
                'career_opportunities',
                'talent_deployment',
                'people_performance_review',
                'rewards_recognition',
            ],
        },
        {
            title: 'Bienestar',
            children: ['Seguridad psicológica', 'Energía', 'Agotamiento laboral'],
            children_ohi: [
                'N',
                'Liderazgo de apoyo',
                'Inclusión y pertenencia',
                'Normas laborales saludables',
                'Apertura y confianza',
            ],
            children_ohi_slug: [
                'n',
                'supportive_leadership',
                'inclusion_belonging',
                'healthy_working_norms',
                'open_and_trusting',
            ],
        },
        {
            title: 'Atracción y retención de talento',
            children: ['Satisfacción laboral', 'Intención de irse', 'Respaldo a la organización ante otros'],
            children_ohi: ['N', 'Puntuación de OHI', 'Orientación externa', 'Dirección'],
        },
    ],
    PRACTICE_CATEGORY_MAP_3_2: [
        {
            title: 'Individual Propósito',
            children: ['Conexión con el significado'],
            children_ohi: [
                'N',
                'Participación de los empleados',
                'Enfoque en el cliente',
                'Responsabilidad social',
                'Líderes inspiradores',
                'Incentivos financieros',
            ],
            children_ohi_slug: [
                'n',
                'employee_involvement',
                'customer_focus',
                'inspirational_leaders',
                'financial_incentives',
            ],
        },
        {
            title: 'Desarrollo profesional',
            children: [
                'Crecimiento profesional',
                'Oportunidad equitativa',
                'Personalización del desarrollo profesional',
            ],
            children_ohi: [
                'N',
                'Desarrollo de talento',
                'Oportunidades profesionales',
                'Asignación de talento',
                'Evaluación del desempeño de las personas',
                'Recompensas y reconocimiento',
            ],
            children_ohi_slug: [
                'n',
                'talent_development',
                'career_opportunities',
                'people_performance_review',
                'rewards_recognition',
            ],
        },
        {
            title: 'Bienestar',
            children: ['Seguridad psicológica', 'Energía', 'Agotamiento laboral'],
            children_ohi: [
                'N',
                'Liderazgo de apoyo',
                'Inclusión y pertenencia',
                'Normas laborales saludables',
                'Apertura y confianza',
            ],
            children_ohi_slug: ['n', 'Liderazgo de apoyo', 'Apertura y confianza'],
        },
        {
            title: 'Atracción y retención de talento',
            children: ['Satisfacción laboral', 'Intención de irse', 'Respaldo a la organización ante otros'],
            children_ohi: ['N', 'Puntuación de OHI', 'Orientación externa', 'Dirección'],
        },
    ],
    PROFILE_VIEW: 'Vista de Perfil',
    COMPARISON: 'Comparación',
    MAPPED: 'Mapeado',
    SESSION_EXPIRED: 'Su sesión ha caducado.',
    LOGIN_BTN: 'Haga clic aquí para iniciar sesión',
    LOGIN_BTN_TEXT: 'Iniciar',
    LOGIN_TEXT: 'Welcome to',
    LOGIN_REFRESH_TEXT: 'OHI por McKinsey',
    LOGIN_DIFF_USER: 'Iniciar sesión como otro usuario',
    SCORECARD: 'Cuadro de mando',
    BU_HEAD: 'Informe de BU',
    DRAG_DROP: 'Arrastrar y soltar',
    OUTCOMES_TEXT: 'Resultados y prácticas',
    COMPANY_HEAD: 'Empresa',
    QUESTION_HEAD: 'Preguntas',
    PROFILE_HEAD: 'Perfil',
    PRACTICE_HEAD: 'Clasificación de prácticas',
    RECIPE_HEAD: 'Cuadro de mando de receta',
    OUTCOME_SUBHEAD: 'preguntas de resultado',
    PRACTICE_SUBHEAD: 'practice questions',
    PRC_QUESTIONS: 'Preguntas de práctica',
    QUESTIONS_LEGEND: ['En desacuerdo', 'Neutral', 'De acuerdo', 'No lo sé'],
    SINGLE_QUESTIONS_LEGEND_COLORS: ['#051c2c', '#00a9f4', '#1f40e6'],
    SINGLE_QUESTIONS_LEGEND: ['Detractors(0-6)', 'Pasivamente satisfecho (7-8)', 'Promoters(9-10)'],
    QUESTIONS_PRACTICE_LEGEND: ['Con poca frecuencia', 'A veces', 'Con frecuencia', 'No lo sé'],
    QUESTIONS_EX_LEGEND: ['Experiencia negativa', 'Neutral', 'Experiencia positiva', 'No lo sé'],
    SURVEY_LINKS: ['Respuestas día a día', 'Respuestas en términos de demografía', 'Entre pestañas'],
    FILTERS: 'Filtros',
    FILTERS_MAIN: 'Filtros',
    FILTERS_APPLIED: 'Filtros aplicados',
    BENCHMARKS_APPLIED: 'Criterios de referencia aplicados',
    FILTER_FOR: 'Filtrar por',
    APPLY_FILTERS: 'Aplicar filtros',
    RESET_FILTERS: 'Restablecer filtros',
    VIEW_ALL: 'Ver todo',
    ALL_SELECTED: 'Toda la selección',
    SELECT_ONE: 'Seleccione un artículo',
    SELECT_DEMOGRAPHIC: 'Seleccionar grupo',
    SURVEY_TYPE: 'Tipo de encuesta',
    SWITCH_VIEW: 'Cambiar vista',
    SURVEY_ID: 'Id. de encuesta',
    SURVEY_PID: 'PID',
    REPORT_ID: 'Identificación de reporte',
    SURVEY_NAME: 'Nombre de la encuesta',
    TARGET_HEADING:
        '¡Se utiliza un punto de referencia global o regional asociado para establecer objetivos asociados!',
    TARGET_PARAGRAPH:
        'Los objetivos de práctica y resultados se establecen en comparación con puntos de referencia globales o regionales. Anclaje en el Se recomienda un punto de referencia global como mejor práctica. Los puntos de referencia regionales son útiles sólo cuando las autoridades locales Los factores afectan las puntuaciones. Los subpuntos de referencia son más volátiles, lo que dificulta determinar con precisión Proyecte los aumentos de puntos necesarios para alcanzar los cuartiles posteriores año tras año.',
    TARGET_OUTCOME: 'La herramienta mostrará resultados y objetivos de práctica para:',
    TARGET_LIST_1:
        'Punto de referencia global si se seleccionan puntos de referencia a nivel de sector de McKinsey o puntos de referencia de especialidad/personalizados.',
    TARGET_SUB_LIST:
        'Punto de referencia de la región si se selecciona el punto de referencia del país o el punto de referencia subregional”',
    CONTINUE: 'Continuar',
    COACH_TEXT: 'Coach/Creador',
    YR_TEXT: 'Año',
    ACTION_TEXT: 'Librería de acción',
    EXPLORE_PARAGRAPH: 'Panel interactivo para explorar y visualizar el OHI GSBD como nunca antes.',
    ACTION_PARAGRAPH:
        '¡Colección de iniciativas que puedes llevar a cabo con los clientes para generar impacto desde el primer día!',
    EXPLORER_TEXT: 'Benchmark por Explorer (BMX)',
    EXPLORE: 'Explore',
    EXPLORE_DASHBOARD: 'Explore OHI Dashboards',
    ACTIONS: 'Actions',
    ANALYTICS_TEXT: 'Choose Analytics Report',
    DONE: 'Listo',
    NONE: NONE_10,
    NO_ACCESS:
        'No tiene acceso a este informe para la Id. de OHI seleccionada; póngase en contacto con el servicio de asistencia técnica de OHI',
    TOTAL_RESPONDENTS: 'Total de encuestados',
    TOTAL_RES_TEXT: 'Total de encuestados (n) = ',
    TOTAL_RESURVEY_TEXT: 'Total de encuestados (n)',
    FREQUENCY_SCALE: 'Escala de frecuencia',
    FILTERS_APPLIED_TEXT: 'Según los filtros aplicados',
    ACROSS_DEMO: 'En todas las demografías',
    ON_SURVEY: 'en la encuesta',
    YES: 'Sí',
    OPTION_ONE: 'Option 1',
    OPTION_TWO: 'Option 2',
    SAMPLE_SIZE: 'Tamaño de la muestra',
    N_SIZE: 'N',
    N_SIZE_TEXT: 'Tamaño N',
    N_SIZE_SMALL: 'n-size',
    N_SIZE_MAIN: 'Tamaño N',
    DIS_AGREE: 'En desacuerdo',
    NEUTRAL: 'Neutral',
    AGREE: 'De acuerdo',
    DEMOGRAPHIC: 'Demográfico',
    DEMOGRAPHICS: 'Demografía',
    HEALTH_DEMOGRAPHIC: 'Demografía de salud',
    DEMOGRAPHICS_SMALL: 'demographics',
    SELECT_MIN_2: 'Puede seleccionar un mínimo de 2 hasta',
    OUT_OF: ' out of ',
    BENCHMARK_HEAD: 'Criterio de referencia',
    NO_BENCHMARK: 'Sin criterios de referencia',
    BOTTOM_DECILE: 'Decil inferior',
    EXCLUDE_GAP_HEAD: 'Demografía del cálculo de puntuación de brecha',
    EXCLUDE_GAP_SUB_HEAD:
        'Abajo puede seleccionar demografías que desee excluir de los cálculos de brecha para todos los resultados y prácticas',
    FILTER_HOVER_TXT:
        'Ahora puede excluir grupos demográficos seleccionados de los cálculos de brechas más grandes Y aplicar los nuevos criterios de referencia de IA',
    UNDER_DEVELOPMENT_1: 'Esta página está en desarrollo.',
    UNDER_DEVELOPMENT_2: 'Agradecemos su paciencia. Volveremos pronto.',
    DEMOGRAPHIC_SUMMARY_SUBHEADING: [
        'Elija hasta 8 grupos demográficos para ver una página de resumen de anillos demográficos',
        'Elija hasta 5 opciones para cada grupo, las opciones restantes se agruparán en otras en el anillo.',
    ],
    DEMOGRAPHIC_SUMMARY: 'Resumen de grupos demográficos',
    DIFF_HEAD: 'Diferencia',
    STATISTICAL_DIFFERENCE: 'Diferencia de Puntuación (con lógica de Significancia Estadística)',
    QUARTILE_DIFFERENCE: 'Diferencia de Puntuación (con lógica de Movimiento de Cuartil)',
    CUSTOM_DIFFERENCE: 'Diferencia de Puntuación (con lógica de Delta Customizada)',
    VALUE_BASED_DIFFERENCE: 'Diferencia de Puntuación (Basada en valor)',
    GAP_BASED_DIFFERENCE: 'Diferencia de brecha (basada en valor)',
    BY_TEXT: 'por',
    BUSINESS_TEXT: 'Nombre del negocio',
    SEARCH: 'Buscar',
    TORNADO_LABEL: 'Convertir a gráfico de tornado',
    VS_LABEL: ' vs. ',
    LEGEND: 'Leyenda',
    HIGH_PRACTICES: 'Prácticas destacadas',
    RECIPE_TOP: '10 principales de la receta',
    UNDER_EMPHASIZED: 'Énfasis insuficiente (ranking< -10)',
    COMPARE_TO_RECIPE: 'Comparable con la receta/énfasis excesivo',
    STRONG_TEXT: 'Fuerte',
    VERY_STRONG_TEXT: 'Muy fuerte',
    NO_ALIGNMENT: 'Sin alineación',
    OUTCOMES: 'Resultados',
    PRACTICES: 'Prácticas',
    LIST_BY_OUTCOMES: 'Listar por resultado',
    LIST_BY_PRACTICES: 'Listar por práctica',
    PRACTICES_SELECT: 'Tipo de práctica',
    OUTCOME_SELECT: 'Dimensione',
    OUTCOME_TEXT: 'Dimensione',
    PRACTICE_TEXT: 'Práctica',
    PRACTICE_NAME_VIEW: 'Vista del nombre de la práctica',
    OVERVIEW_PRCT_TEXT: 'Descripción general de resultado y práctica',
    HEALTH_SCORE: 'Puntaje de salud total',
    TOP: 'Superior',
    BOTTOM: 'Inferior',
    THREE: 'Tres',
    BY_SCR: 'Por Puntaje',
    VIEW_RES: 'Ver encuestados',
    VIEW_SCORE: 'Ver tabla de resultados',
    VIEW_PRC_RANKING: 'Ver clasificación de prácticas',
    VIEW_PRC: 'Ver práctica',
    VIEW_OCT: 'Ver dimensión',
    VIEW_DEMOGRAPHICS: 'Ver demografía',
    VIEW_BY: 'Ver por',
    INVITED_TEXT: 'Invitado a encuesta',
    COMPLETE_RES_TEXT: 'Respuestas completas',
    INCOMPLETE_RES_TEXT: 'Respuestas incompletas',
    DEMO_SURVEY_TOOLTIP:
        'Las tasas de participación solo son visibles para los grupos demográficos con al menos 5 respuestas.',
    TOTAL_RES: 'Núm. de encuestados',
    NUMBER_OF_RESPONDENTS_N: 'Número de encuestados (n)',
    RES_HEAD: 'Respuestas',
    COMPLETE: 'Completo',
    INCOMPLETE: 'Incompleto',
    OVERALL: 'General',
    TOTAL: 'Total',
    DAILY_RES_TEXT: 'Respuestas diarias',
    DEMO_RES_TEXT: 'Responses based on ',
    PEOPLE_RES_TEXT: '% de personas que respondieron a la encuesta',
    PEOPLE_NUM_RES_TEXT: 'Response Rate (cumulative)',
    RES_TO_TEXT: 'Respondieron la encuesta',
    COMMENTS_TEXT: 'Número de comentarios',
    RESURVEY_TEXT: 'Nueva encuesta',
    HEALTH_BU_DETAILED_BOARD: 'Tablero detallado de Salud de BU',
    HEALTH_BU_BOARD: 'Tablero de Salud de BU',
    HEALTH_RESURVEY_DETAIL_BOARD: 'Tablero detallado de nueva encuesta de Salud',
    HEALTH_RESURVEY_BOARD: 'Tablero de nueva encuesta de Salud',
    HEALTH_SCORECARD: 'Cuadro de mando de Salud',
    RESURVEY_INACTIVE_TEXT:
        'No tiene acceso a datos de nueva encuesta. Póngase en contacto con su equipo de asistencia técnica para utilizar esta función.',
    COMPARE_BU: 'Comparación de BU',
    SELECT_DEFAULT: 'Seleccionar año',
    SELECT_YEAR: 'Seleccione el año comercial para comparar',
    BUSINESS_YEAR: 'Año comercial',
    DEMOGRAPHIC_GROUP: 'Grupo demográfico',
    SELECT_YEAR_INCLUSION:
        'Seleccione el año para elegir los datos de qué año desea comparar con los datos de esta encuesta. Los resultados estarán disponibles en las pestañas individuales. ',
    CLOSE_RESURVEY_TEXT: 'Cerrar pestañas de nueva encuesta',
    CONFIRMATION_TEXT:
        '¿Está seguro de que desea cerrar la vista comparativa? Esto cerrará las pestañas que están mostrando datos de la otra encuesta.',
    CONFIRMATION_BTN: 'Sí, cerrar',
    DETAILED_BUTTON: 'Ver informe detallado',
    COMPARE_SURVEY_BUTTON: 'Ver comparación en la página',
    VIEW_HEATMAP_BUTTON: 'Ver mapa de calor comparativo',
    NO_MAPPED_DEMO:
        'No existe mapeo para el año comercial seleccionado. Cargue mapas demográficos antes de generar mapas de calor comparativos',
    BULK_RESURVEY_ERROR: 'Para exportaciones masivas, seleccione solo dos encuestas.',
    BULK_RESURVEY_COMP_ERROR: 'Para mapas de calor comparativos, seleccione solo dos encuestas.',
    COMPANY_RESULTS: 'Este año',
    VIEW_PRACTICE: 'Ver perfil de práctica',
    VIEW_MORE: 'Ver más',
    VIEW_LESS: 'Ver menos',
    BENCHMARK: 'Criterio de referencia',
    ORG_DEMOGRAPHIC: 'Demografía de la organización',
    BU_DEMOGRAPHIC: 'Demográficos de los BU',
    COMPANY_RESULTS_TEXT: 'Resultados',
    BU_RESULTS: 'Resultados de la BU',
    COMPANY_RESULTS_DIFF: 'Difference b/w ',
    COMPANY_RESULTS_DIFF_TEXT: ' results and ',
    ADD_YEAR: 'Agregar otro año para comparar',
    ADD_ACTION: 'Agregar nueva acción',
    CREATE_ACTION: 'Crear nueva acción de OHI',
    ACTION_SUBMITTED: '¡Enviado correctamente!',
    ACTION_SUBMITTED_TEXT: 'Los nuevos detalles de acción de OHI se enviaron correctamente.',
    OK_TEXT: '¡Bien!',
    REPORT_TEXT: 'Informe',
    REPORT_VIEW: 'Ver informe',
    REPORTS: 'Informes',
    REPORTS_LIST: 'Lista de informes',
    HOME: 'Pantalla de inicio',
    LOG_OUT: 'Cerrar sesión',
    USER_MNGT: 'Gestión de usuarios',
    USER_ID: 'Id. de usuario',
    NAME: 'Nombre',
    EMAIL: 'Dirección de correo electrónico',
    ROLE: 'Función',
    SEARCH_EMAIL_REPORT: 'Buscar por nombre o correo electrónico',
    SELECT_DEMO_GROUP: 'Seleccionar grupo demográfico',
    N_SIZE_SORT: ['Tamaño N: de mayor a menor', 'Tamaño N: de menor a mayor'],
    OVERALL_SORT: ['General: de mayor a menor', 'General: de menor a mayor'],
    GENERATE_REPORT: 'Generar informe',
    DOWNLOAD_SAMPLE_REPORT: 'Descargar informe de muestra',
    PAST_REPORT: 'Informes anteriores',
    THESE_OPTIONS_ARE_ACCURATE_PROCED: 'Estas opciones son precisas. proceda.',
    REFRESH_TEXT: 'Actualizar',
    REFRESH_REPORTS: 'Actualizar informes',
    DEMOGRAPHIC_OPTIONS: 'Opciones demográficas',
    DEMOGRAPHIC_FILTERS: 'Filtros demográficos',
    DEMOGRAPHIC_THRESHOLD: 'Umbral demográfico',
    CHANGE_DEMOGRAPHIC_THRESHOLD: 'Change Demographic Threshold ?',
    NOT_ALLOWED: 'N/A',
    X_AXIS: 'Eje X',
    Y_AXIS: 'Eje Y',
    CHANGE: 'Cambiar',
    INSUFF_RES: 'Respuestas insuficientes',
    OUTCOME_OPTIONS: 'Opciones de resultados',
    PRACTICE_OPTIONS: 'Opciones de práctica',
    BUBBLE_CHART_SORTING: 'Orden de gráfico de burbujas',
    PRACTICE_SCORE_BY_DEMOGRAPHIC: 'Gráficos de pulso',
    DEMOGRAPHIC_INSIGHTS: 'Análisis demográfico profundo',
    BUBBLE_CHART_CUSTOMISATION: 'Gráficos de burbujas',
    VIEW_DEMOGRAPHIC_SCORES: 'Ver puntajes por demográficos',
    VIEW_SAMPLE_SLIDE: 'Ver diapositiva de muestra',
    SAMPLE_SLIDE: 'Diapositiva de muestra',
    DOWNLOAD_FORMAT: 'Descargar formato',
    VIEW_SAMPLE: 'Ver muestra',
    ALL_DEMOGRAPHICS: NONE_10,
    DEMOGRAPHIC_HEAT_MAP: 'Mapa de calor demográfico',
    SCORE_HEAT_MAP: 'Puntaje de mapas de calor',
    SCORE_MAPS: 'Mapa de calor de puntuación',
    SELECT_ALL: ' Seleccionar todo',
    ADDITIONAL_SETTINGS: 'Configuración adicional',
    CUSTOMISE_EXPORT_REPORT: 'Personalizar exportación de informe',
    CREATE_REPORT: 'Crear informe',
    CHOSE_REPORT: 'Seleccionar informe',
    SELECT_REPORT: 'Elija y selecciona el informe',
    PRACTICE_RANKING: 'Clasificación de prácticas',
    CHART_PRACTICE_RANKING: 'Gráfico comparativo con otros benchmarks de referencia y ranking de prácticas',
    CHART_PRACTICE_RANKING_BUBBLES: 'Práctica de bandera y burbujas de comparación',
    COMPARISON_BUBBLE: 'Burbujas de comparación',
    FLAGPOLE_CHART: 'Resultado de prácticas en un gráfico de asta de bandera',
    GENERAL_SETTINGS: 'Configuración general',
    BULK_REPORT: 'Informe Masivo',
    CHOOSE_BULK_UNITS: 'Seleccionar unidad de negocio',
    COMPARISON_HEATMAP: 'Mapa de calor comparativo',
    MAIN_GROUP_TEXT: 'Grupo principal',
    ENABLE_TEXT:
        'Habilita este interruptor para incluir páginas adicionales que comparen el “Grupo Principal” seleccionado con la “Organización” en general. Ten en cuenta que el tamaño n de la “Organización” es el tamaño total de la muestra y es independiente de cualquier filtro aplicado.',
    DEMO_BU_DESC_TEXT:
        'Seleccione el grupo del cual desea ver un análisis profundo; normalmente es una unidad de negocio o función.',
    COMPARISION_GROUP: 'Grupo de comparación',
    COMPARISION_GROUP_DESC:
        'Seleccione el grupo que será el grupo de referencia - generalmente es toda la organización',
    BULK_REPORT_NOTE:
        'Revise los filtros cuidadosamente en esta página. En el caso de exportaciones masivas, no aplique filtros para la categoría demográfica para la cual está creando descargas masivas.',
    PRACTICE_RANKING_NOTE:
        '​Las clasificaciones de práctica se calculan estandarizando las puntuaciones de práctica y ordenándolas de mayor a menor.',
    ENTER_COMPANY_NAME: 'Escriba el nombre de la organización',
    ENTER_ORG_NAME: 'Ingrese el nombre de la organización',
    LANGUAGE: 'Idioma',
    COMPARISION_BU_TEXT: 'Comparison BU',
    SELECT_BM_VERSION: 'Seleccione la versión del criterio de referencia',
    BENCHMARK_QUARTILE_COLOR: 'Color del cuartil del Benchmark',
    BENCHMARK_YEAR: 'Año del Benchmark',
    BENCHMARK_VERSION: 'Versión de benchmark',
    BENCHMARK_TARGET_SETTING: 'Benchmark aplicado a las páginas para puntos del cuartil superior/subsecuentes',
    APPLIED_BENCHMARK_TEXT: 'Comuníquese con OHI-HelpDesk si necesita cambiar el punto de referencia aplicado',
    BENCHMARK_TARGET_SETTING_SUB_INFO:
        'Las páginas que muestran puntajes y objetivos para alcanzar el cuartil superior o los cuartiles subsecuentes están comparadas con bechmarks globales/regionales, incluso si el resto del informe está comparado con un benchmark a más granular.',
    BENCHMARK_NA: 'Criterio de referencia no disponible',
    SCORECARD_NOTE:
        'Los puntajes medianos de salud mostrados son propiedad intelectual protegida de la firma y no deben publicarse en publicaciones internas o externas, ni compartirse con los clientes.',
    PERCENT_FAVORABLE: 'Puntajes de OHI reportados en porcentaje favorable',
    PERCENTILES: 'Puntajes de OHI reportados en percentiles',
    TARGET_SETTING: 'Establecimiento de objetivos',
    TARGET_QUARTILE:
        'El establecimiento de objetivos representa los puntos necesarios para alcanzar el siguiente cuartil.',
    ACCESS_EXPIRE: 'El acceso caduca',
    OHI_VALADITY: 'Comuníquese con el servicio de asistencia de OHI para extender su validez',
    EE_PERCENT_FAVORABLE: 'Factores EE reportados en porcentaje favorable',
    EE_PERCENTILE: 'Factores EE reportados en porcentaje favorable',
    EE_OHI_PERCENT_FAVORABLE: 'Puntajes OHI y factores EE reportados en porcentaje favorable',
    EE_OHI_PERCENTILE: 'Puntajes de OHI reportados en percentiles, Factores EE reportados en porcentaje favorable',
    ENGLISH: 'English(United States)',
    ENGLISH_CBR: 'Inglés',
    GERMAN_CBR: 'Alemán',
    SET_FLAGPOLE_CHART: 'Definir benchmark de referencia',
    NUMBER_OF_SURVEYS: 'Número de encuestas',
    DISPLAY_TORNADO_CHART: 'Mostrar gráficos de tornado',
    SURVEY_SENT: 'Encuestas enviadas',
    SURVEY_COMPLETED: 'Encuestas completadas',
    COMPLETED: 'Completadas',
    INCLUSION_COMPLETED: 'Tasa de respuesta',
    RESPONSE_RATE_TEXT: 'Tasa de respuesta',
    LAST_UPDATE: 'Última actualización',
    USER_SETTINGS: 'Configuración de usuario',
    OPTION: 'Opciones',
    ADDITIONAL_SURVEY: 'Encuesta adicional',
    PREVIOUS_SURVEY: 'Encuesta anterior',
    CURRENT_SURVEY: 'Encuesta actual',
    CURRENT_REPORT_NAME: 'Nombre del informe actual',
    CURRENT_SURVEY_INFO: 'Información de la encuesta actual',
    DATACUBE_TYPE: 'Tipo de Datacube',
    DATACUBE_LEVEL: 'Nivel demográfico',
    SCORE_DEPTH: 'Detalle de puntuación',
    DATACUBE_VERSION: 'Datacube Version',
    REPORT_PLACEHOLDER: 'Escriba el nombre que desea que se use en el informe',
    SUB_INFO_GENERAL:
        'Seleccione el criterio de referencia que desea que se use para el color de los cuartiles en el informe. Elija la opción Global para usar el criterio de referencia global o Seleccionar otro para ver otras opciones disponibles.',
    SUB_INFO_INCLUSION:
        'Seleccione el criterio de referencia que desea que se use para el color de los cuartiles en el informe.',
    SUB_INFO_PRACTICE: ' Select the check box below to show tornado charts for practice ranking pages.',
    SUB_INFO:
        'Seleccione los criterios de referencia que desea utilizar en las páginas de bandera de resultados y prácticas. Los resultados y prácticas del cliente se mostrarán indicando la diferencia con la puntuación media del criterio de referencia.',
    BACK: 'Atrás',
    NEXT: 'Siguiente',
    BUILD_REPORT: 'Construya el informe',
    BUILD_TEXT:
        'Puede personalizar la descarga de su informe eliminando cualquiera de las secciones de la lista. Todas las secciones se seleccionan de forma predeterminada, lo que producirá una salida estándar de plataforma completa.',
    INSUFFICIENT_HEADING: 'Advertencia de informe masivo',
    INSUFFICIENT_INFO: 'Algunas opciones tienen un tamaño n insuficiente',
    SUFFICIENT_INFO: 'Remove these options and proceed?',
    INSUFFICIENT_OK: 'Sí',
    REMOVE: 'Eliminar',
    DOWNLOAD_TEXT: 'Descargar',
    SELECT_DOWNLOAD: 'Seleccione Descargar',
    DOWNLOAD: 'Descargar informe',
    DOWNLOAD_ALL: 'Descargar todo',
    DOWNLOAD_PPT: 'Descargar PPT',
    DOWNLOAD_PDF: 'Descargar PDF',
    DOWNLOAD_PPT_V1: 'Descargar PPT versión 1',
    DOWNLOAD_PPT_V2: 'Descargar PPT versión 2',
    DOWNLOAD_PPT_V3: 'Descargar PPT versión 3',
    VERSION_TEXT: 'Versión',
    SELECT_OUT_VER: 'Seleccione la versión de PPT de resultado',
    INCLUDE_TEXT: 'Incluir mapas de calor delta para datos demográficos mapeados',
    SELECT_PRTC_VER: 'Seleccione la versión de PPT de práctica',
    DOWNLOAD_EXCEL: 'Descargar Excel',
    DOWNLOAD_POWERPOINT: 'Descargar PowerPoint',
    OUTCOME_PLACEMENT: 'Puede customizar la posición de las dimensiones en el mapa de calor',
    SELECT_OUTCOME: 'Seleccione la posición de las dimensiones',
    STATUS: 'Estado',
    ACTION: 'Acción',
    DATE: 'Fecha',
    DATE_TIME: 'Fecha y hora',
    RPT_TYPE: 'Tipo de informe',
    RPT_NAME: 'Nombre del informe',
    RETRY: 'Reintentar',
    RESURVEY: 'Nueva encuesta',
    COMPARE: 'Comparar',
    TO: 'para',
    NO_REPORTS: 'No se encontraron informes',
    HEAT_MAP_NONE: 'Si corresponde, seleccione la demografía para el módulo EE',
    HEAT_MAP_SELECT:
        'Seleccione las preguntas demográficas que desea utilizar en los mapas de calor de resultados y prácticas.',
    INCLUSION_HEATMAP: 'Elija los grupos demográficos para los que desea generar los mapas de calor de puntuación.',
    HEAT_MAP_INCLUSION:
        'Seleccione los grupos demográficos para los que deseas generar los mapas de calor de puntuación en "Personalizado".',
    SCORE_NEW_HEATMAP:
        'Seleccione los grupos demográficos para los que deseas generar los mapas de calor de puntuación en "Personalizado".',
    SCORE_NEW_HEATMAP_2:
        'El valor predeterminado es "Ninguno", lo que significa que no habrá mapas de calor de puntaje ni otras páginas enumeradas en su informe.',
    HEAT_MAP_INCLUSION_2:
        'El valor predeterminado es "Ninguno", lo que significa que no habrá mapas de calor de puntaje en su informe.',
    HEAT_MAP_INCLUSION_RES:
        'Choose the demographic groups that you want to generate Score Heat maps for by selecting "Personalizado".',
    HEAT_MAP_INCLUSION_RES_2:
        'Los mapas de calor de comparación año tras año están disponibles solo para datos demográficos mapeados. Póngase en contacto con el servicio de asistencia técnica para obtener un mapeo demográfico año tras año.',
    INFO_TEXT:
        'Los mapas de calor de comparación año tras año están disponibles solo para datos demográficos mapeados.',
    NAME_COMPARISON: 'Nombre de la comparación',
    GIVE_NAME_PLACEHOLDER: 'Asigne un nombre a la comparación',
    ENTER_REPORT_NAME: 'Escriba el nombre del informe',
    ENTER_NAME: 'Introduzca un nombre de informe',
    SELECT_SURVEY: 'Seleccionar encuesta',
    SELECT_PRIORITY_PRACTICE: 'Seleccione 15 prácticas prioritarias',
    SELECT_COMPARISON_BUBBLES: 'Seleccionar burbujas de comparación',
    OHI4_HEAD: 'Incluir preguntas beta de OHI 4.0',
    OHI4_META:
        'Incluya preguntas de la versión beta de OHI 4.0 en el informe. Podrás seleccionar la demografía para el mismo en el siguiente paso.',
    OHI4_OPTIONS_HEAD: 'Opciones demográficas de OHI 4.0',
    BU_GROUPS: 'Comparación BU por grupos ',
    BU_BUSINESS_TEXT:
        'Selecciona el grupo para el que deseas ver un análisis detallado (usualmente es una unidad de negocio o función).',
    BU_ORGANIZATION_TEXT: 'Seleccione el grupo que será el grupo de referencia - generalmente es toda la organización',
    OHI4_OPTIONS_META: 'Seleccione la demografía de eNPS que desea incluir en el informe.',
    SELECT_RESURVEY_DOWNLOAD_TYPE: 'Seleccionar formato de informe',
    ADD_ANOTHER_SURVEY: 'Agregar otra encuesta',
    REPORT_NAME_PLACEHOLDER: 'Escriba el nombre que desea que se use en el informe',
    SELECT_COMPARE_YEAR: 'Seleccione Comparar año',
    OVERVIEW_TEXT: 'Descripción general',
    RESPONDENTS_TEXT: 'Encuestados',
    TOOLBOX: 'Caja de herramientas',
    NEW_THEME: 'Nuevo tema',
    BUILD_PAGE: 'Crear página',
    BU_REPORT_TEXT: 'BU',
    CREATE_TEXT: 'Crear',
    OUT_PRT: 'Resultados y prácticas',
    ADD_TEXT: 'Agregar',
    THEME_NAME: 'Escriba el nombre del tema',
    DESC: 'Descripción',
    NOTES: 'Notas',
    FEEDBACK_TEXT: 'Comuníquese con el equipo de OHI-Helpdesk en cualquier momento si tiene alguna pregunta.',
    THEME_PLACE: 'Escriba un nombre de hasta 100 caracteres',
    NOTES_PLACE: 'Escriba sus notas aquí',
    TEXT_PLACE: 'Escriba texto aquí',
    SAVED_THEMES: 'Patrones guardados',
    NO_PATTERN: 'No se encontraron patrones.',
    CREATE_THEME_TEXT: 'Haga clic aquí para crear un nuevo tema.',
    CONFIRM: 'CONFIRMAR',
    DELETE: 'ELIMINAR',
    CANCEL: 'CANCELAR',
    CANCEL_TEXT: 'Cancelar',
    SAVE_GROUP: 'Guardar grupo',
    DRAG_DROP_HEAD: 'Arrastre para organizar columnas y filas',
    SHOW_SCORE: 'Mostrar puntuación',
    SHOW_SCORES: 'Mostrar puntuación',
    CUSTOMIZE_COHESION: 'Personalizar distribución',
    COHESION_TAB1: 'Similitudes culturales entre unidades y organizaciones',
    COHESION_TAB2: 'Matriz de similitud',
    CLOSE: 'Cerrar',
    DELETE_PATTERN: 'Delete Pattern ?',
    AVG_GAP: 'Brecha promedio',
    LARGEST_GAP: 'Brecha mayor',
    OVERALL_INCLUSION: 'Inclusión general',
    OVERALL_SCORE: 'Puntuación general',
    OVERALL_SCORED: 'Puntuación general',
    SORT: 'Ordenar',
    DELETE_MSG:
        'Está a punto de eliminar este patrón guardado. Si elige eliminar este patrón, perderá todos los datos.',
    ARE_YOU_SURE: '¿Está seguro de que desea continuar?',
    SETTINGS: 'Configuración',
    BG_COLOR: 'Color de fondo',
    BD_COLOR: 'Color de borde',
    FONT_COLOR: 'Color de fuente',
    RECIPE_NOTE: 'Alineación de recetas',
    FILTES_CHECK_NOTE: 'Revise los filtros aplicados',
    NOTE: 'Nota',
    QUESTION_NOTE: 'Todas las puntuaciones indican % de empleados',
    EXCEL_DWNLD_TEXT:
        'Su solicitud de exportación a Excel ha sido enviada. Pronto recibirá un correo electrónico con información para descargar el archivo.',
    APPLY_SETTINGS: 'Aplicar',
    BAR_CHART_NOTE:
        'La similitud de recetas es la correlación entre la receta del cliente y la receta ideal, multiplicada por 10.',
    BENCH_NOTE:
        'Los resultados estadísticamente significativos tuvieron valores p de < 0,05, lo que indica una alta probabilidad de que haya ocurrido un cambio/diferencia real. La significación estadística se basa en el número de encuestados y la magnitud de la diferencia de puntos.',
    FLAGPOLE_NOTE_1:
        'Los resultados estadísticamente significativos tienen valores p < 0.05, lo que indica una alta probabilidad de que las diferencias observadas sean reales y no debidas al azar. La significación estadística se basa en las puntuaciones observadas en la muestra seleccionada y el tamaño de dicha muestra. La misma diferencia absoluta en las puntuaciones es más probable que sea estadísticamente significativa en una muestra grande en comparación con una muestra pequeña.',
    FLAGPOLE_NOTE_2: 'Las clasificaciones de la práctica se basan en percentiles globales.',
    FLAGPOLE_LINK_TEXT: 'Metodología OHI curso e-learning',
    GSOD_OVERVIEW_NOTE:
        'Se requiere un mínimo de 10 encuestas para crear  indicadores de otros benchmarks de referencia y 25 encuestas para crear cuartiles de color para sub-benchmarks en los informes de clientes del índice de salud organizacional.',
    VIEW_ONLINE: 'VER CLASE EN LÍNEA',
    LEARN_MORE:
        'Para obtener más información sobre la metodología del índice de salud organizacional, por favor consulte el curso de e-Learning sobre la metodología del índice de salud organizacional',
    LEARN_MORE_LINK:
        'https://mckinseylearning.csod.com/samldefault.aspx?returnUrl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dlodetails%2526lo%253d684aef4c-e57c-4d83-bd2a-d2351584451d',
    OVERVIEW_NUMBER_NOTE: 'No hay análisis detallados disponibles (n<10)',
    GSOD_PRAC_NOTE: [
        'Las clasificaciones de la práctica se basan en percentiles globales',
        'Las clasificaciones de la práctica se basan en valores z',
    ],
    GSOD_PRAC_ANALYSIS: [
        'Las organizaciones del cuartil superior (CS) y del cuartil inferior (CI) reflejan el 25% superior e inferior de las puntuaciones del índice de salud organizacional en la muestra seleccionada',
    ],
    DEMO_NOTE: 'Para proteger la confidencialidad, no se muestran los resultados de grupos de <Umbral participantes.',
    DEMOGRAPHIC_OPTIONS_SUBINFO: 'Seleccione cómo desea que aparezcan los grupos de menos de ',
    DEMOGRAPHIC_OPTIONS_EX:
        'En el Informe de experiencia del empleado, no se mostrarán resultados para grupos con n<10',
    DEMOGRAPHIC_OPTIONS_SUBINFO_2: ' in outcome and practice heat maps',
    DATACUBE_OPTIONS_SUBINFO: 'No se mostrarán resultados para grupos con n<',
    DATACUBE_OPTIONS_SUBINFO_2: 'de forma predeterminada',
    DATACUBE_NAME_PLACEHOLDER: 'Escriba el valor n',
    DEMO_OPT_NOTE: thresVal =>
        `Los grupos de menos de ${thresVal} no deben compartirse con los clientes para proteger la confidencialidad. ¿Está seguro de que desea mostrar los grupos < ${thresVal} ? Si desea entregar estos resultados a su cliente, comuníquese con su ROM para obtener su aprobación.`,
    PREVIOUS: 'Anterior',
    COHESION_HEADING: 'Cohesión',
    TXT_ANA_HEADING: 'Analítica de texto',
    ADD_ONS: 'Complementos',
    COHESION_SCORE: 'Puntuación de cohesión',
    REPORTS_TIP_TEXT: 'Sus informes están listos para descargar.',
    COHESION_SUB_HEADING:
        'La cohesión nos ayuda a analizar más allá de los resultados promedio de una organización para determinar si todos están experimentando lo mismo.',
    COHESION_LEGENDS_TEXT: [
        {
            type: 'alta',
            title: '> 95 potencialmente muy alta',
            description: 'Potencial de rigidez cultural, falta de diversidad y variedad necesaria en las subculturas.',
        },
        {
            type: 'Moderada',
            title: '75 a 84 Moderada',
            description:
                'Cierta cohesión y coherencia cultural, pero no suficiente para fortalecer el desempeño y la salud de la organización debido a formas de trabajo divergentes y silos.',
        },
        {
            type: 'Optimo',
            title: '> 84 a 95 Óptima',
            description:
                'In the sweet spot of high cultural Cohesion without being too high; top quartile performance and health much more likely',
        },
        {
            type: 'Baja',
            title: '< 75 Baja',
            description:
                'La empresa es, en términos de comportamiento, una serie de culturas y normas laborales diferentes que hacen más difícil mantener la salud en el cuartil superior.',
        },
    ],
    BU_HEAD_TITLE: ['Puntuación de salud de la organización', 'Puntuación de salud de la BU', 'Diferencia'],
    BU_PROJECTS: ['Filtro de informe', 'Filtro de comparación'],
    BAR_CHART_LEGENDS: ['Sin alineación', 'Fuerte', 'Muy fuerte'],
    COHESION_GRAPH_LEGEND_TEXT: [
        'Criterios de referencia',
        'Scores >.90(very high)',
        'Scores between .70 and .90(high)',
        'Scores between .50 and .70(moderate)',
        'Scores between .30 and .50(low)',
        'Scores between -.30 and .30(negligible)',
        'Score <-.30(negative)',
    ],
    COHESION_GRAPH_LEGEND_TEXT_OHI4: ['Criterios de referencia', 'Alta', 'Moderada', 'Baja'],
    SCORECARD_DISCLAIMER: [
        'Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        'Las brechas destacadas aquí son las más altas de las brechas de inclusión a nivel demográfico más grandes para cada una de los puntajes de inclusión, dimensiones y prácticas; las brechas de Inclusión se representan en cuadrados y todos los demás puntajes se representan en círculos.',
        `Los puntos de corte para las brechas de inclusión se basan en la distribución de los puntajes promedio de brechas medianas a través de todos los resultados y prácticas en la base de datos de Evaluación de Inclusión global (por debajo de lo típico: <7, típico: 7-14, por encima de lo típico: >14).`,
    ],
    SCORECARD_RES_DISCLAIMER: [
        'Las preguntas subyacentes cambiaron desde la encuesta del año pasado (es decir, se agregaron y/o eliminaron preguntas). Por lo tanto, los puntajes del año pasado y del año actual no se pueden comparar directamente.',
        '** Nuevas prácticas añadidas desde la encuesta del año pasado. Por lo tanto, no hay comparaciones disponibles.',
    ],

    SCORECARD_DEMOGRAPHIC_DISCLAIMER: [
        ' 1. Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        ' 2. Las brechas destacadas aquí son las más altas de las brechas de inclusión a nivel demográfico más grandes para cada una de los puntajes de inclusión, dimensiones y prácticas; las brechas de Inclusión se representan en cuadrados y todos los demás puntajes se representan en círculos.',
        ` 3. Los puntos de corte para las brechas de inclusión se basan en la distribución de los puntajes promedio de brechas medianas a través de todos los resultados y prácticas en la base de datos de Evaluación de Inclusión global (por debajo de lo típico: <7, típico: 7-14, por encima de lo típico: >14).`,
        ` 4. Los resultados para los grupos demográficos con n < 10 no se informan`,
        ` 5. GEl cálculo de la brecha excluye a aquellos que respondieron 'Prefiero no responder`,
    ],

    SCORECARD_RESURVEY_VALUE_BASED: [
        'Las comparaciones de puntuación se basan únicamente en la magnitud del cambio (±1 o más), no en la significancia estadística',
        'Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        'Las brechas destacadas aquí son las más altas de las brechas de inclusión a nivel demográfico más grandes para cada una de los puntajes de inclusión, dimensiones y prácticas; las brechas de Inclusión se representan en cuadrados y todos los demás puntajes se representan en círculos.',
        'Los puntos de corte para las brechas de inclusión se basan en la distribución de los puntajes promedio de brechas medianas a través de todos los resultados y prácticas en la base de datos de Evaluación de Inclusión global (por debajo de lo típico: <7, típico: 7-14, por encima de lo típico: >14).',
        'Las preguntas subyacentes (para  Meaningful work, Allyship, Connection Opportunities, Resource Accessibility y Mentorship) han cambiado desde la encuesta de años anteriores (es decir, se han añadido y/o eliminado preguntas). Por lo tanto, las puntuaciones del año pasado y del año actual no se pueden comparar directamente.',
        'Se han añadido nuevas prácticas (Collective Identity y Sponsorship) desde la encuesta del año pasado. Por lo tanto, no hay comparaciones disponibles.',
    ],

    SCORECARD_RESURVEY_VALUE_BASED_V2: [
        'Las comparaciones de puntuación se basan únicamente en la magnitud del cambio (±1 o más), no en la significancia estadística',
        'Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Los puntos de corte para las brechas de inclusión se basan en la distribución de los puntajes promedio de brechas medianas a través de todos los resultados y prácticas en la base de datos de Evaluación de Inclusión global (por debajo de lo típico: <7, típico: 7-14, por encima de lo típico: >14).',
    ],

    SCORECARD_RESURVEY_QUARTILE_BASED_V2: [
        'Score comparisons based on positive/negative changes in quartiles, not statistical significance',
        'Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Los puntos de corte para las brechas de inclusión se basan en la distribución de los puntajes promedio de brechas medianas a través de todos los resultados y prácticas en la base de datos de Evaluación de Inclusión global (por debajo de lo típico: <7, típico: 7-14, por encima de lo típico: >14).',
    ],

    SCORECARD_RESURVEY_QUARTILE_BASED: [
        'Score comparisons based on positive/negative changes in quartiles, not statistical significance',
        'Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Los puntos de corte para las brechas de inclusión se basan en la distribución de los puntajes promedio de brechas medianas a través de todos los resultados y prácticas en la base de datos de Evaluación de Inclusión global (por debajo de lo típico: <7, típico: 7-14, por encima de lo típico: >14).',
        'Las preguntas subyacentes (para  Meaningful work, Allyship, Connection Opportunities, Resource Accessibility y Mentorship) han cambiado desde la encuesta de años anteriores (es decir, se han añadido y/o eliminado preguntas). Por lo tanto, las puntuaciones del año pasado y del año actual no se pueden comparar directamente.',
        'Se han añadido nuevas prácticas (Collective Identity y Sponsorship) desde la encuesta del año pasado. Por lo tanto, no hay comparaciones disponibles.',
    ],

    RESURVEY_DEMO_VALUE_BASED: [
        'Las comparaciones de puntuación se basan únicamente en la magnitud del cambio (±1 o más), no en la significancia estadística',
        'Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Los puntos de corte para las brechas de inclusión se basan en la distribución de los puntajes promedio de brechas medianas a través de todos los resultados y prácticas en la base de datos de Evaluación de Inclusión global (por debajo de lo típico: <7, típico: 7-14, por encima de lo típico: >14).',
        'Los resultados para los grupos demográficos con n < 10 no se informan',
        'El cálculo de la brecha excluye a quienes respondieron "Prefiero no responder".',
        'Las preguntas subyacentes (para  Meaningful work, Allyship, Connection Opportunities, Resource Accessibility y Mentorship) han cambiado desde la encuesta de años anteriores (es decir, se han añadido y/o eliminado preguntas). Por lo tanto, las puntuaciones del año pasado y del año actual no se pueden comparar directamente.',
        'Se han añadido nuevas prácticas (Collective Identity y Sponsorship) desde la encuesta del año pasado. Por lo tanto, no hay comparaciones disponibles.',
    ],

    RESURVEY_DEMO_QUARTILE_BASED: [
        'Score comparisons based on positive/negative changes in quartiles, not statistical significance',
        'Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Los puntos de corte para las brechas de inclusión se basan en la distribución de los puntajes promedio de brechas medianas a través de todos los resultados y prácticas en la base de datos de Evaluación de Inclusión global (por debajo de lo típico: <7, típico: 7-14, por encima de lo típico: >14).',
        'Los resultados para los grupos demográficos con n < 10 no se informan',
        'El cálculo de la brecha excluye a quienes respondieron "Prefiero no responder".',
        'Las preguntas subyacentes (para  Meaningful work, Allyship, Connection Opportunities, Resource Accessibility y Mentorship) han cambiado desde la encuesta de años anteriores (es decir, se han añadido y/o eliminado preguntas). Por lo tanto, las puntuaciones del año pasado y del año actual no se pueden comparar directamente.',
        'Se han añadido nuevas prácticas (Collective Identity y Sponsorship) desde la encuesta del año pasado. Por lo tanto, no hay comparaciones disponibles.',
    ],

    RESURVEY_DEMO_VALUE_BASED_V2: [
        'Las comparaciones de puntuación se basan únicamente en la magnitud del cambio (±1 o más), no en la significancia estadística',
        'Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Los puntos de corte para las brechas de inclusión se basan en la distribución de los puntajes promedio de brechas medianas a través de todos los resultados y prácticas en la base de datos de Evaluación de Inclusión global (por debajo de lo típico: <7, típico: 7-14, por encima de lo típico: >14).',
        'Los resultados para los grupos demográficos con n < 10 no se informan',
        'Gap calculation excludes those that answered ‘Prefer not to answer',
    ],

    RESURVEY_DEMO_QUARTILE_BASED_V2: [
        'Score comparisons based on positive/negative changes in quartiles, not statistical significance',
        'Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Los puntos de corte para las brechas de inclusión se basan en la distribución de los puntajes promedio de brechas medianas a través de todos los resultados y prácticas en la base de datos de Evaluación de Inclusión global (por debajo de lo típico: <7, típico: 7-14, por encima de lo típico: >14).',
        'Los resultados para los grupos demográficos con n < 10 no se informan',
        'Gap calculation excludes those that answered ‘Prefer not to answer',
    ],
    DISCLAIMER: 'Descargo de responsabilidad',
    RESTRUCTION_TOOLTIP:
        'Los criterios de umbral restringen el análisis a muestras seleccionadas con 10 organizaciones en el cuartil superior y 10 organizaciones en el cuartil inferior de la muestra seleccionada.',
    MEDIAN_RESTRUCTION_TOOLTIP:
        'Los criterios de umbral restringen el análisis a muestras seleccionadas con 10 organizaciones en la mitad superior y 10 organizaciones en la mitad inferior de la muestra seleccionada.',
    QUESTION_DISCLAIMER:
        'El porcentaje de encuestados que indicaron ”No sé o No corresponde” no se considera en los cálculos de las puntuaciones de Resultados/Práctica de Inclusión.',
    IA_2_QUESTIONS_DISCLAIMER:
        '4. Los textos de las preguntas pueden no coincidir exactamente con lo que se preguntó en la encuesta V2.0',
    LEADERS: 'Líderes',
    PEER_TEXT: 'Par',
    TEAMMATES_TEXT: 'Compañeros(as) de equipo',
    PEERS: 'Pares/compañeros(as) de equipo',
    INCLUSION: 'Inclusión',
    SHOW_OVERALL: 'Mostrar general',
    GAP_SCORE_ARE_LOADING_PLEASE_WAIT: 'Las puntuaciones de brecha se están cargando. Por favor, espere.',
    SYSTEMS: 'Sistemas',
    OUTCOME_SCORE_GAPS: 'Puntuaciones de resultados y brechas',
    PRACTICE_SCORE_GAPS: 'Puntuaciones de práctica y brechas',
    GAP_CLASSIFICATION_TEXT: 'Clasificación de brecha',
    SCORE_CLASSIFICATION_TEXT: 'Clasificación de puntuación',
    OHI_ANALYTICS_LOGIN: 'Inicio de sesión de analítica de OHI',
    CHANGE_CLASSIFICATION: [
        {
            label: 'Cambio positivo',
            type: 'up',
            color: colors.$green400,
            index: 1,
        },
        {
            label: 'Neutral',
            color: colors.$yellow,
            index: 2,
        },
        {
            label: 'Cambio negativo',
            color: colors.$pink,
            type: 'down',
            index: 0,
        },
    ],
    PRACTICE_CLASSIFICATION_TEXT: 'Tipo de práctica :',
    WORKPLACE_QUESTIONS: 'Preguntas de resultado en el lugar de trabajo',
    OUTCOME_QUESTIONS: 'Preguntas por dimensión',
    SURVEY_RES: 'Encuestados',
    PRCT_LARGE_GAP: 'Prácticas con las brechas más grandes',
    OUTCOME_LARGE_GAP: 'Resultados con las brechas más grandes',
    DEMOGRAPHICS_LARGE_GAP: 'Demografías con las brechas más grandes',
    PERSONAL_EXP: 'Experiencia personal',
    ENT_PERCEPTION: 'Percepción de la empresa',
    SIZE_MAIN_TEXT: 'Tamaño',
    WORKPLACE_OUT: 'Dimensiones en el lugar de trabajo',
    WORKPLACE_OUT_SCORE: 'Puntuaciones de resultado en el lugar de trabajo',
    AGGREMENT_LEVEL: 'Nivel de acuerdo',
    AGREEMENT_SCALE: 'Agreement scale',
    FREQUENCY_LEVEL_PERCENT: 'Nivel de frecuencia en %',
    AGGREMENT_LEVEL_PERCENT: 'Nivel de acuerdo en %',
    EXPERIENCE_LEVEL_PERCENT: 'Nivel de experiencia en %',
    RESPONSE_PERCENT: 'Response in %',
    SHOW_INCLUSION_GAPS: 'Mostrar brechas de inclusión',
    INCLUSION_SCORECARD: 'Cuadro de mando de inclusión',
    QUESTIONS: 'Preguntas',
    VALUES_TEXT: 'Valores de la empresa',
    TEXT_COMMENTS_TXT: 'Comentarios de texto',
    INC_SCORE_DEMO: 'Puntuación de inclusión para demografías',
    GAPS_TEXT: 'Brechas',
    BM_DISABLE_TXT:
        'Esta función está deshabilitada ya que el ajuste de nueva encuesta de inclusión está configurado en Cambio de cuartil o Significación estadística.',
    VALUES_MAIN_TEXT: {
        current: {
            heading: 'Valores actuales',
            para: 'Lo que somos hoy',
        },
        intersect: {
            heading: 'Valores actuales y deseados',
            para: 'Lo que nos gustaría continuar',
        },
        desired: {
            heading: 'Valores deseados',
            para: 'Lo que nos gustaría ser',
        },
    },
    VALUES_LEGEND_TEXT: [
        'Detractores de la salud organizacional: valor que tiene una correlación negativa con la salud general',
        'Potenciadores de la salud organizacional: valor que tiene la correlación positiva más sólida con la salud general',
    ],
    NEGATIVE_PRACTICES: ['Intención de irse', 'Agotamiento laboral'],
    DIFFERENCE: [
        { legendText: 'Score Down', color: '#D51F31' },
        { legendText: 'Score Up', color: '#007F26' },
        { legendText: 'Same score', color: '#FFA800' },
    ],
    SOURCE_TEXT: 'Fuente',
    YEAR: 'Año',
    NUMBER_TEXT: 'norte',
    PRACTICE_SCREEN: 'Todas las prácticas caben en una sola pantalla.',
    CATEGORY_BASED: 'Basado en categorías (alineación, renovación de ejecución).',
    NUMBER_CAP_TEXT: 'Norte',
    NUMBER_SURVEY_TEXT: 'No. encuestas',
    NUMBER_CAP_SURVEY_TEXT: 'Núm. de encuestas',
    RES_PPT_TEXT: 'resultados',
    ACTION_COMP_HEADER: 'Compendio de acciones de OHI',
    ACTION_COMPENDIUM: 'Compendio de acciones',
    ACTION_COMP_TEXT:
        '¡Bienvenido(a) a la herramienta de planificación de acciones de OHI! Hemos recopilado una serie de acciones que puede realizar con los clientes para generar impacto desde el primer día. Búsquelas, compílelas y agréguelas usando las siguientes opciones. Puede buscar por práctica de OHI independiente o puede organizar y explorar por receta, nivel y cuadrante de modelo de influencia.',
    GEN_LIST: 'Generar lista',
    DISCLAIMER_TEXT_PART1: 'Resultados para grupos demográficos con n',
    DISCLAIMER_TEXT_PART2: 'no se informan',
    DISCLAIMER_DEMO_PART1: 'No se muestran resultados para grupos de',
    DISCLAIMER_DEMO_PART2: 'para proteger la confidencialidad',
    DISCLAIMER_DEMO_PART3: '1 La puntuación general es el promedio de las 8 preguntas.',
    DISCLAIMER_GAP_TEXT: 'El cálculo de la brecha excluye a quienes respondieron "Prefiero no responder".',
    DISCLAIMER_PRACTICE_RANK_TEXT:
        'Las brechas destacadas aquí son las más altas de las brechas de inclusión a nivel demográfico más grandes para cada una de las puntuaciones de Práctica de Inclusión; las brechas de Inclusión se representan en cuadrados y todas las demás puntuaciones se representan en círculos.',
    INSIGHT_INFO:
        'Seleccione 2 opciones demográficas dentro de un grupo demográfico determinado: las puntuaciones de la opción demográfica 1 se muestran y se comparan con las puntuaciones respectivas de la opción demográfica 2 para el cálculo de la brecha.',
    UPDATE_VIEW: 'Cambiar vista de usuario',
    UPDATE_VIEW_BUTTON: 'Actualizar vista',
    EXCLUDE_DEMO_GROUP_HEADING: 'Exclude Demo group(s) from gap calculations',
    EXCLUDE_DEMO_GROUP_SUB_HEADING: [
        'Seleccione abajo las demografías que desee excluir de los cálculos de brecha para todos los resultados y prácticas.',
        'Nota: Los usuarios de línea recta/aceleradores, n<grupos de umbral y demostraciones preseleccionadas (si las hay) ya están excluidos. Póngase en contacto con el servicio de asistencia técnica para cambiar',
    ],
    INCLUSION_DEMOOPTION_SUB_HEADING:
        'En el Informe de evaluación de inclusión no se mostrarán resultados para grupos con n<',
    INCLUSION_DEMOOPTION_SUB_HEADING_RESURVEY:
        'En el Informe de nueva encuesta de evaluación de inclusión no se mostrarán resultados para grupos con n<',
    INCLUSION_DEMOFILTER_SUB_HEADING: [
        'Seleccione las opciones demográficas necesarias para su informe.',
        'Todas las opciones demográficas se seleccionan de forma predeterminada, lo que genera un informe para toda la organización.',
    ],
    INCLUSION_PRACTICEBY_DEMO_SUB_HEADING:
        'Seleccione los grupos demográficos para los que desea generar gráficos de pulso.',
    DATACUBE_DEMOOPTION_SUB_HEADING:
        'Seleccione un mínimo de 2 y hasta 10 demografías para generar Datacube L4 o L5. Si selecciona más de 10 demografías, se generará un Datacube L3.',
    OUTCOMES_OPTION_SUBHEADING:
        'Para profundizar en los resultados de Inclusión, seleccione 2 preguntas de cada resultado y dos de sus prácticas.',
    PRACTICE_OPTION_SUBHEADING:
        'Seleccione 3 prácticas de cada tipo de práctica para ver una comparación lado a lado de las prácticas con sus puntuaciones, brechas y porcentaje de respuestas favorables de todas sus preguntas.',
    DEMO_SCORE_SUBHEADING: 'Este mapa de calor muestra las puntuaciones de la organización en general.',
    BUBBLE_CHART_SUBHEADING: [
        'Elija los grupos demográficos y las opciones que necesita en su gráfico de burbujas.',
        'Tenga en cuenta que no es posible seleccionar “demostraciones excluidas del cálculo de brecha”',
    ],
    OHI_4_RPT_TEXT: [
        [
            'Elija los grupos demográficos para generar páginas de desglose de la tasa de respuesta',
            'El valor predeterminado es "Ninguno", lo que significa que el informe no incluirá estadísticas de tasa de respuesta para ningún grupo demográfico.',
        ],
        [
            'Elija el grupo demográfico (y hasta 10 opciones en cada grupo) para generar páginas detalladas de Cohesión organizacional.',
            '"Ninguno" está seleccionado de forma predeterminada, lo que significa que no habrá páginas de análisis profundo demográfico de Cohesion en su informe.',
        ],
        [
            'Elija los grupos demográficos para generar páginas de mapas de calor para el Módulo de experiencia del empleado (mapa de calor de la experiencia del empleado, mapa de calor eNPS, etc.)',
            'El valor predeterminado es "Ninguno", lo que significa que no habrá mapas de calor demográficos de Experiencia del empleado.',
        ],
    ],
    OHI_4_HEADER: [
        'Demografía de tasa de respuesta',
        'Demografía de cohesión',
        'Demografía de Experiencia del empleado',
    ],
    LOW_THRESHOLD:
        'No se puede crear el informe porque el número de encuestados es inferior al umbral mínimo. Revise los filtros.',
    why_important_text: 'Por qué es importante',
    what_it_entails: 'Qué es',
    ADD_QUES_TEXT: 'Preguntas adicionales',
    EX_JOURNEY_QUES: 'Preguntas Trayectoria del empleado',
    SCORE_TXT: 'Puntuación',
    GAP_TXT: 'Brecha',
    BUTTON_TAG: 'Mostrar puntuación más alta y más baja',
    GAP_BUTTON_TAG: 'Mostrar la brecha más alta',
    GAP_TYPE: 'Tipo de brecha',
    ON: 'ACTIVADO',
    OFF: 'DESACTIVADO',
    BIG_PPT_ERROR:
        'El archivo es demasiado grande, se enviará a su correo electrónico. Recibirá el archivo en los próximos 5 a 10 minutos.',
    CLOSE_WINDOW_MSG: 'Puede cerrar esta ventana, el proceso continuará en segundo plano.',
    ZIP_FILE_MSG: 'Sus informes se están descargando y se entregarán como un archivo zip.',
    INCL_HEAD: 'Preguntas de la encuesta',
    INCL_HEAD_META: '% de respuestas favorables',
    INCL_PRAC_RANK_NOTES: [
        'Percentage of respondents who responded with "De acuerdo"/"Strongly agree" for Outcome questions and "Often"/"Almost always" for Practice questions',
        'El porcentaje de encuestados que indicaron "No sé o No corresponde" no se considera en los cálculos de las puntuaciones de Resultados/Práctica de Inclusión.',
    ],
    THRESHOLD_TXT: 'Umbral para el informe',
    UPDATE_TARGET: 'Actualizar conteo objetivo',
    SUBMIT: 'Enviar',
    PPT_RES_TEXT: 'Porcentaje de encuestados que seleccionaron “de acuerdo” o “muy de acuerdo”.',
    PPT_RES_TEXT2: 'Porcentaje de encuestados que seleccionaron “a menudo” o “casi siempre”.',
    PPT_RES_TEXT3: 'Las puntuaciones representan percentiles.',
    COMPENDIUM_OPTION_KEYS: {
        recipe:
            'Cuando se combinan, las prácticas de OHI crean recetas que nos ayudan a determinar qué tipo de organización es.',
        practice:
            'Son 37 prácticas las que comprenden la salud organizacional, y cada una tiene resultados, artículos de encuesta y acciones diferentes.',
        level: 'La complejidad estimada de implementar la acción (p. ej., recursos, personas y tiempo)',
        influence_model: [
            'Modelo a seguir: Comportamiento de líderes y colegas',
            'Convicción: Entender qué y por qué',
            'Capacidades: Habilidades y capacidades',
            'Reforzamiento: Procesos que apoyan el cambio',
        ],
    },
    LEVEL_SCORE: 'Puntuaciones de nivel',
    OUT_SCORE: 'Puntuaciones de resultados',
    PRC_SCORE: 'Puntuaciones de práctica',
    DEMO_INCLUSION_LEGENDS: [
        {
            background: colors.$red500,
            title: 'Puntuación inferior a 50',
        },
        {
            background: colors.$orange400,
            title: 'Puntuación entre 50-75',
        },
        {
            background: colors.$green700,
            title: 'Puntuación superior a 75',
        },
    ],
    PR_HEATMAP_LEGENDS: [
        {
            background: colors.$blue500,
            title: 'Clasificación más alta',
        },
        {
            background: colors.$red500,
            title: 'Clasificación inferior',
        },
    ],
    DEMOGRAPHICS_GRAPH_CLASSIFICATION: [
        {
            title: 'Muy alto (80 y más))',
            background: colors.$green600,
        },
        {
            title: 'Alto (70-79)',
            background: colors.$green500,
        },
        {
            title: 'Moderado (60-69)',
            background: colors.$orange300,
        },
        {
            title: 'Bajo (50-59)',
            background: colors.$orange200,
        },
        {
            title: 'Muy bajo (menos de 50)',
            background: colors.$orange100,
        },
    ],
    PRACTICE_CLASSIFICATION: [
        {
            level: 'Par',
            title: 'Pares/compañeros(as) de equipo',
        },
        {
            level: 'Líder',
            title: 'Líderes',
        },
        {
            level: 'Sistemas',
            title: 'Sistemas',
        },
    ],
    HEADER_LEGENDS: [
        {
            title: 'En desacuerdo/Muy en desacuerdo',
            background: colors.$black,
        },
        {
            title: 'Ni de acuerdo ni en desacuerdo',
            background: colors.$darkBlue400,
        },
        {
            title: 'De acuerdo/Muy de acuerdo',
            background: colors.$blue700,
        },
        {
            title: 'No sé o N/C',
            background: colors.$white,
        },
    ],
    NA_BG: {
        bgcolor: colors.$white,
        text_color: 'black',
        border_color: 'black',
    },
    SUB_HEADER_LEGENDS: [
        {
            title: 'Rara vez/Casi nunca',
            background: colors.$black,
        },
        {
            title: 'Neutral',
            background: colors.$darkBlue400,
        },
        {
            title: '(A menudo/Casi siempre)',
            background: colors.$blue700,
        },
        {
            title: 'No sé o N/C',
            background: colors.$white,
        },
    ],
    practicesByOutcome: [
        'Alianza',
        'Integración de ideas',
        'Respeto mutuo',
        'Apoyo de pares',
        'Defensa',
        'Mentoría',
        'Patrocinio',
        'Toma de decisiones participativa',
        'Formación de equipos',
        'Trato imparcial',
        'Expresión personal',
        'Mecanismos de protección',
        'Oportunidades de conexión',
        'Identidad colectiva',
        'Meritocracia',
        'Accesibilidad de recursos',
        'Apoyo al equilibrio vida-trabajo',
    ],
    pcLegends: {
        Authenticity: 'Alianza, Idea integration',
        Belonging: 'Mutual respect, Defensa',
        'Trabajo significativo': 'Peer support, Mentorship, Patrocinio',
        Acceptance: 'Self-expression, Protective mechanisms, Participative decision-making',
        Camaraderie: 'Connection opportunities, Collective identity, Formación de equipos',
        Fairness: 'Meritocracy, Resource accessibility, Balance trabajo-vida, Impartial treatment',
    },
    outLegends: {
        'Pares/compañeros(as) de equipo': 'Alianza, Idea integration, Mutual respect, Peer support',
        Leader:
            'Defensa, Mentorship, Patrocinio, Participative decision-making, Formación de equipos, Impartial treatment',
        Systems:
            'Self-expression, Protective mechanisms, Connection opportunities, Collective identity, Meritocracy, Resource accessibility, Balance trabajo-vida',
    },
    CHART_TEXT: 'Busque caídas en la organización y la demografía',
    FEATURE_MODAL_HEADING: '¡Novedades en Inclusión!',
    FEATURE_MODAL_TEXT:
        'Estas son instantáneas de las nuevas características introducidas en Inclusión. Deslice las flechas Siguiente y Anterior para desplazarse por todas las imágenes. Presione el botón de abajo para cerrar esta sección.',
    FEATURE_MODAL_BTN: '¡Entendido!',
    RR_SURVEY_OPTIONS: [
        {
            code: 1,
            label: 'Individual',
        },
        {
            code: 2,
            label: 'Combinado',
        },
        {
            code: 99,
            label: 'Global',
        },
    ],
    HEADER_NAV: [
        {
            text: 'Pantalla de inicio',
            link: '/',
            checkAccess: true,
            checkTabAccess: true,
        },
        {
            text: 'Tasas de respuesta',
            link: '/response-rate',
            checkRR: true,
            checkTabAccess: true,
            checkNonProfit: true,
        },
        {
            text: 'Salud',
            link: '/diagnose',
            checkHealthAccess: true,
        },
        {
            text: 'Inclusión',
            link: '/inclusion',
            checkIncAccess: true,
        },
        // {
        //     text: 'Recomendar',
        //     link: '/recommend',
        //     checkAccess: true,
        //     checkTabAccess: true,
        // },
        // {
        //     text: 'Tomar medidas',
        //     link: '/action',
        //     activeLink: true,
        //     checkAction: true,
        // },
        // {
        //     text: 'Analítica',
        //     link: '/gsod',
        //     checkGsodAccess: true,
        //     checkTabAccess: true,
        // },
        {
            text: 'Usuarios del informe',
            link: '/usersList',
            activeLink: true,
            checkUserPermission: true,
        },
    ],

    HEADER_NAV_2: [
        {
            text: 'Pantalla de inicio',
            link: '/',
        },
        {
            text: 'Tomar medidas',
            link: '/action',
            activeLink: true,
            checkAction: true,
        },
    ],

    HEADER_NAV_3: [
        {
            text: 'Pantalla de inicio',
            link: '/',
            id: 'home',
            activeLink: true,
            showBench: true,
        },
        {
            text: 'Analítica Library',
            link: '/gsod',
            id: 'gsod',
            checkGsodAccess: true,
        },
    ],
    SIGNIFICANCE: [
        {
            title: 'Considerablemente más débil',
            bg: colors.$pink,
            text_color: colors.$white,
            borderWidth: 2,
        },
        {
            title: 'Considerablemente más fuerte',
            bg: colors.$green400,
            text_color: colors.$white,
            borderWidth: 2,
        },
        {
            title: 'Comparable',
            bg: colors.$yellow200,
            text_color: colors.$white,
            borderWidth: 2,
        },
    ],
    SETTINGS_SIGNIFICANCE: [
        {
            title: 'Considerablemente más débil',
            bg: colors.$pink,
            text_color: colors.$white,
            borderWidth: 2,
        },
        {
            title: 'Considerablemente más fuerte',
            bg: colors.$green400,
            text_color: colors.$white,
            borderWidth: 2,
        },
        {
            title: 'Comparable',
            bg: colors.$yellow200,
            text_color: colors.$white,
            borderWidth: 2,
        },
    ],
    SIGNIFICANCES: [
        {
            title: 'Considerablemente más débil',
            bg: colors.$significantlyPink,
            text_color: colors.$white,
            borderWidth: 2,
            sup: 1,
        },
        {
            title: 'Considerablemente más fuerte',
            bg: colors.$significantlyGreen,
            text_color: colors.$white,
            borderWidth: 2,
            sup: 1,
        },
        {
            title: 'Comparable',
            bg: colors.$comparableColor,
            text_color: colors.$white,
            borderWidth: 2,
        },
        {
            title: 'Sin significación estadística',
            bg: colors.$black,
            text_color: colors.$black,
            borderWidth: 2,
            checkSig: true,
            sup: 2,
        },
    ],
    SIGNIFICANCEv2: [
        {
            title: 'Considerablemente más débil',
            bg: colors.$practiceRed,
            text_color: colors.$white,
            practice_color: colors.$practiceRed,
            text: 'weaker',
            borderWidth: 2,
        },
        {
            title: 'Considerablemente más fuerte',
            bg: colors.$practiceGreen,
            text_color: colors.$white,
            practice_color: colors.$practiceGreen,
            text: 'stronger',
            borderWidth: 2,
        },
        {
            title: 'Comparable',
            bg: colors.$practiceYellow,
            text_color: colors.$white,
            practice_color: colors.$grey600,
            text: 'comparable',
            borderWidth: 2,
        },
    ],
    GSOD_SIG: [
        {
            title: 'Negative Differentiator',
            bg: colors.$practiceRed,
            text_color: colors.$white,
            practice_color: colors.$practiceRed,
            text: 'weaker',
            borderWidth: 2,
            toolTipText: 'Practice(s) that unhealthy organizations uniquely emphasize',
        },
        {
            title: 'Positive Differentiator',
            bg: colors.$practiceGreen,
            text_color: colors.$white,
            practice_color: colors.$practiceGreen,
            text: 'stronger',
            borderWidth: 2,
            toolTipText: 'Practice(s) that healthy organizations uniquely emphasize',
        },
        {
            title: 'Neutral',
            bg: '#b3b3b3',
            text_color: colors.$white,
            practice_color: colors.$grey600,
            text: 'comparable',
            borderWidth: 2,
            toolTipText: '',
        },
    ],
    QUESTION_SIGNIFICANCE: [
        {
            title: 'Considerablemente más débil',
            sup: 1,
            imgSrc: DownArrow,
        },
        {
            title: 'Considerablemente más fuerte',
            sup: 1,
            imgSrc: UpArrow,
        },
        {
            title: 'Comparable',
            imgSrc: SquareMinus,
        },
        {
            title: 'No statistical significance',
            checkSig: true,
            sup: 2,
            imgSrc: NoSig,
        },
    ],
    QUARTILE_MOVE_LEGEND_TXT: [
        'Shifted down to lower quartile',
        'Shifted up to higher quartile',
        'No quartile movement',
        'Comparison not available',
    ],
    QUARTILE_MOVE_LEGEND_TXT_IA: [
        'Shifted up to higher quartile',
        'Shifted down to lower quartile',
        'No quartile movement',
        'Comparison not available',
    ],
    CUSTOM_DELTA_LEGEND_TXT: ['más débil', 'mas fuerte', 'Comparable', 'Comparación no disponible'],
    VALUES_BASED_LEGEND_TEXT: ['Decrease in score value', 'Increase in score value', 'No Change'],
    GAP_LEGEND_TEXT: ['Increase in gap value', 'Decrease in gap value', 'No Change'],
    QUARTILE_MOVE: 'Cambio de cuartil',
    CROSS_TABS: [
        {
            background: colors.$grey800,
            text: '',
        },
        {
            background: colors.$green800,
            text: 'Very high ( > 90% )',
        },
        {
            background: colors.$green900,
            text: 'High ( 70% - 90% )',
        },
        {
            background: colors.$orange500,
            text: 'Moderate ( 40% - 70% )',
        },
        {
            background: colors.$orange600,
            text: 'Low ( < 40% )',
        },
    ],
    QUEUE: ['queue', 'raw_ppt_generating'],
    SORTARR: [
        {
            label: 'Predeterminado',
            key: NONE,
        },
        {
            label: 'Tamaño N ascendente',
            key: 'nsize:asc',
        },
        {
            label: 'Tamaño N descendente',
            key: 'nsize:desc',
        },
        {
            label: 'Puntuación de salud ascendente',
            key: 'score:asc',
        },
        {
            label: 'Puntuación de salud descendente',
            key: 'score:desc',
        },
    ],
    SORTARR_EE: [
        {
            label: 'Predeterminado',
            key: NONE,
        },
        {
            label: 'Tamaño N ascendente',
            key: 'nsize:asc',
        },
        {
            label: 'Tamaño N descendente',
            key: 'nsize:desc',
        },
    ],

    CFG_CHOOSE_REPORT_OPTIONS: [
        {
            label: 'Informe de BU',
            key: '1',
        },
        {
            label: 'Tendencias de comparaciones de encuestas a través del tiempo',
            key: '3',
        },
    ],
    INCLUSION_DEFAULT_BENCHMARK: '1001',
    INCLUSION_DEFAULT_BENCHMARK_STR: '1001:10',
    ADDITIONAL_SETTING_OPTIONS: [
        {
            label: 'Descripción general de la encuesta',
            key: 'Descripción general de la encuesta',
        },
        {
            label: 'Sección de puntuación de salud',
            key: 'Sección de puntuación de salud',
        },
        {
            label: 'Priorización de prácticas',
            key: 'Practice priortization',
        },
        {
            label: 'Módulos adicionales',
            key: 'Módulos adicionales',
        },
        {
            label: 'Resultados por demografía',
            key: 'Result by demographic',
        },
        {
            label: 'Resultados pregunta por pregunta',
            key: 'Question by question result',
        },
    ],
    COMPARISON_BUBBLES: [
        {
            label: 'Statistical Significance',
            key: '1',
            list_key: 'statistical significance',
        },
        {
            label: 'Quartile Movement',
            key: '2',
            list_key: 'Quartile movement',
        },
        {
            label: 'Custom Delta',
            key: '3',
            list_key: 'Custom delta',
        },
    ],
    RESURVEY_DOWNLOAD_TYPE: [
        {
            label: 'Crear informe en PPT',
            key: 'PPT',
        },
        {
            label: 'Descargar Excel',
            key: 'Excel',
        },
    ],
    demoOptionsValue: thresholdVal => {
        return {
            option: [
                {
                    label: `Hide where < ${thresholdVal}`,
                    key: `${thresholdVal}`,
                },
                {
                    label: `Club < ${thresholdVal} into "others" category`,
                    key: '1',
                },
            ],
            adminOption: [
                {
                    label: `Esconderse donde < ${thresholdVal}`,
                    key: `${thresholdVal}`,
                },
                {
                    label: `Mostrar dónde < ${thresholdVal} pero con -`,
                    key: '-',
                },
                {
                    label: 'Mostrar todos los puntajes',
                    key: '0',
                },
                {
                    label: `Club < ${thresholdVal} en la categoría "otras"`,
                    key: '1',
                },
            ],
            exOption: [
                {
                    label: `Esconderse donde < ${thresholdVal}`,
                    key: `${thresholdVal}`,
                },
            ],
            inclusionDemoOptions: [
                {
                    label: `Esconderse donde < ${thresholdVal}`,
                    key: `${thresholdVal}`,
                },
            ],
            dataCubeDemoOptions: [
                {
                    label: `Esconderse donde < ${thresholdVal}`,
                    key: `${thresholdVal}`,
                },
                {
                    label: CUSTOM,
                    key: 'custom',
                },
            ],
            filterArr: [
                {
                    label: 'Todos los encuestados',
                    key: 'All',
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            heatMap: [
                {
                    label: NONE_10,
                    key: NONE,
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            bubbleChartSort: [
                {
                    label: 'Ordenar por tamaño N descendente',
                    key: 'nsize',
                },
                {
                    label: 'Ordenar por puntuación descendente',
                    key: 'score',
                },
            ],
            practiceScoreDemographic: [
                {
                    label: 'All Demographics',
                    key: 'All',
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            bubbleChartOptions: [
                {
                    label: 'Establecer valor predeterminado',
                    key: 'All',
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            excludeOptions: [
                {
                    label: NONE_10,
                    key: NONE,
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            summaryOptions: [
                {
                    label: NONE_10,
                    key: NONE,
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            inclusionGapType: [
                {
                    label: 'Mayor brecha',
                    key: false,
                },
                {
                    label: 'Brecha promedio',
                    key: true,
                },
            ],
            rrDemographicOptions: [
                {
                    label: NONE_10,
                    key: NONE,
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            practiceRankingOptions: [
                {
                    label: 'Vista de lista: sin puntuaciones de práctica',
                    key: false,
                },
                { label: 'con puntuaciones de práctica', key: true },
            ],
            empExpDemoOptions: [
                {
                    label: NONE_10,
                    key: NONE,
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            cohesionDemoOptions: [
                {
                    label: NONE_10,
                    key: NONE,
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
        };
    },
    ALL_PPT_TEXT: [
        'Lista de posibles prácticas que se deben priorizar según la ciencia detrás de',
        'No faltan prácticas en las 10 principales de la receta',
        'Sin Prácticas poderosas fallidas',
        'No hay prácticas con una brecha >10 para esta receta',
        'salud general y resultados vs.',
        'prácticas vs.',
        'Diferencia con',
        'salud general vs.',
        'puntuación de salud general',
        'perfil de resultados vs.',
        'perfil de resultados',
        '1 - Los resultados estadísticamente significativos tuvieron valores p de < 0,05, lo que indica una alta probabilidad de que haya ocurrido un cambio/diferencia real. La significación estadística se basa en el número de encuestados y la magnitud de la diferencia de puntos',
        'Salud general y resultados a lo largo del tiempo',
        'Perfil de práctica a lo largo del tiempo',
        'Salud general a lo largo del tiempo',
        'Paso 2: Recipe alignment over time',
        'Perfil de resultados a lo largo del tiempo',
        'perfil de resultados de la encuesta1',
        'Cambio en el perfil de práctica desde la última encuesta con cambio de significación estadística',
        'Mapa de calor de las puntuaciones de resultados de Inclusión',
        'Mapa de calor de las puntuaciones de prácticas de Inclusión - Percepción de la empresa',
        'Mapa de calor de las puntuaciones de prácticas de Inclusión - Experiencia personal',
        'perfil de práctica',
        'TEXTO y explicación…',
        'Diferencia entre la organización y la mediana de referencia, pp',
        'Comparaciones clave entre pares',
        'Question',
        'Puntuación de la pregunta1',
        'Para proteger la confidencialidad, no se muestran los resultados de grupos de <10 participantes.',
        'Cambio año tras año',
        'Puntuaciones y brechas de resultado de Inclusión',
        'Puntuaciones y brechas de práctica de Inclusión',
    ],
    PPT_VALUES: ({ rName = '', buName = '', ques_Meta = '', threshold = 0 } = {}) => ({
        GLOBAL_VALS: {
            health: 'health',
            benchmark: 'Criterio de referencia',
            legendTitle: 'Criterio de referencia',
            txttopD: 'Decil superior',
            txttopQ: 'Cuartil superior',
            txt2nd: 'Segundo cuartil',
            txt3rd: 'Tercer cuartil',
            txtBottom: 'Cuartil inferior',
            source_txt: 'Fuente',
            no_of_survey_txt: 'no. surveys',
            legendTitlea: 'Diferencia',
            Strong: 'Considerablemente más fuerte',
            Comparable: 'Comparable',
            Weak: 'Considerablemente más débil',
            Strong_Q: 'Cambio positivo en el cuartil o decil superior',
            Comparable_Q: 'Sin cambio en el cuartil',
            Weak_Q: 'Cambio negativo en el cuartil',
            legendTitleDifference: 'Comparación',
            '4. Nota al pie': '1 Porcentaje de empleados',
            text_and_explanation: 'TEXTO y explicación…',
            strong_txt: 'Fuerte',
            percentle_specific_text:
                'Percentile scale indicates the % of scores in the benchmark below the score for a given Outcome or Practice. A higher % score indicates relative ranking compared to the benchmark.',
            favourable_specific_text:
                'La escala indica el porcentaje de encuestados de la organización que seleccionaron “de acuerdo” o “muy de acuerdo” para los resultados y “a menudo” o “casi siempre” para las prácticas de gestión.',
        },
        profile: {
            outcomeTitle: `${rName} outcome profile`,
            practiceTitle: `${rName} practice profile`,
        },
        scorecard: {
            title: `${rName} scorecard`,
        },
        practiceBoard: {
            RANK_LABEL: `${rName} rank`,
            RANK_VS_LABEL: `${rName} rank vs. `,
        },
        benchmark: {
            title: `${rName} results`,
        },
        thresholdTip: `Any group smaller than ${threshold} will not be reflected, in order to preserve the confidentiality of respondents.`,
        highlevel: {
            'Título 1': 'Objetivos generales para llevar cada práctica al siguiente cuartil',
            txttop: 'Aumento de puntos necesario para alcanzar el siguiente cuartil',
        },
        practice_profile_bu_checkme: {
            Title_text: `${rName}'s practice profile vs. ${buName}`,
        },
        questionsMeta: {
            outcome_title: `${ques_Meta} outcome questions1`,
            practice_title: `${ques_Meta} practice questions1`,
            qyq_out_legend: 'Outcomes:',
            qbyq_disagree: 'En desacuerdo',
            qbyq_neutral: 'Neutral',
            qbyq_agree: 'De acuerdo',
            qyq_prac_legend: 'Practices:',
            qbyq_inrequently: 'Con poca frecuencia',
            qbyq_sometimes: 'A veces',
            qbyq_frequently: 'Con frecuencia',
            '4. Nota al pie': '1 Porcentaje de empleados',
        },
        comparisonMeta: {
            legendTitleab: 'Comparison in time:',
            txttopb: 'Significantly Stronger<sup>1</sup>',
            txt3rdb: 'Significally weaker<sup>1</sup>',
        },
        Recipe_checkme_1: {
            companyshape: `Difference ${rName} rank vs. recipe rank`,
            '3. Subtítulo': 'Pasos 3 y 4',
            'Forma automática 250':
                'Diferencia en el orden de clasificación de las prácticas entre la organización y la receta.',
            'Rectángulo 2': 'Receta',
        },
        Recipe_checkme_second: {
            BPP_text: 'Prácticas poderosas en el cuartil inferior',
            G_text: 'Practices with a gap of >10 to this recipe',
            M_text: 'Prácticas que faltan en las 10 principales de esta receta',
            'Rectángulo 40': 'Las prácticas destacadas son aquellas que se repiten en los cuadros de prioridades.',
            '4. Nota al pie': 'La lista de prácticas no es exhaustiva.',
            'Rectángulo 28a': '1.ra',
            'Rectángulo 28b': '2.da',
            'Rectángulo 28c': '3.ra',
        },
        cdvalues_checkme: {
            Title_text: `${rName} valores`,
            AutoShape250b1: 'Valores actuales',
            AutoShape250c1: 'Lo que somos hoy',
            AutoShape250d1: 'Valores actuales y deseados',
            AutoShape250b2: 'Lo que nos gustaría continuar',
            AutoShape250c2: 'Valores deseados',
            AutoShape250d2: 'Lo que nos gustaría ser',
            txt3rd1: 'Detractores de la salud organizacional',
            txt3rd2: 'valores que tienen una correlación negativa con la salud general',
            txtBottom1: 'Potenciadores de la salud organizacional',
            txtBottom2: 'valores que tienen la correlación positiva más sólida con la salud general',
        },
        resurvey_recipealignment_checkme: {
            '3. Subtítulo': 'Paso 2',
            'Forma automática 250': 'Similitud de recetas según la clasificación de la práctica relativa actual1',
            rcpShp1a: 'Fábrica de líderes',
            rcpShp1b:
                'Impulsar el desempeño mediante el desarrollo y la asignación de líderes sólidos, que cuenten con apoyo de coaching, capacitación formal y oportunidades adecuadas de crecimiento.',
            rcpShp2a: 'Forjador de mercado',
            rcpShp2b:
                'Avanzar a través de la innovación en todos los niveles y de la rápida operacionalización basándose en un profundo conocimiento tanto de los clientes como de la competencia.',
            rcpShp3a: 'Ventaja de ejecución',
            rcpShp3b:
                'Obtener una ventaja competitiva mediante la participación de todos los empleados en la promoción del desempeño y la innovación, en la recopilación de ideas y en el intercambio de conocimientos.',
            rcpShp4a: 'Centro de talento y conocimiento',
            rcpShp4b: 'Crear valor atrayendo e inspirando a los mejores talentos.',
            '4. Nota al pie':
                '1 La similitud de recetas es la correlación entre la receta del cliente y la receta ideal, multiplicada por 10.',
            rcpScale1: 'No alignment',
            rcpScale2: 'Fuerte',
            rcpScale3: 'Muy fuerte',
            Title_text: 'Paso 2: Recipe alignment over time',
        },
    }),
    DYNAMIC_TEXT_INFO: ({ ohi_text = 'OHI' } = {}) => ({
        OHI_SCORE_TEXT: `${ohi_text} Score`,
        OHI_SCORE_TEXT_PPT: `${ohi_text} Score`,
        REPORT_TYPE: [
            `${ohi_text} Informe empresarial`,
            `${ohi_text} BU Informe`,
            'Informe solo de datos',
            `${ohi_text} Nueva encuesta`,
            'Informe empresarial de Inclusión',
            'cubo de datos',
            'Informe de nueva encuesta de Inclusión',
            'Informe empresarial de Experiencia del empleado',
            `${ohi_text} Influyente`,
            'Op Model module excel',
        ],
        CHOOSE_REPORT_OPTIONS: [
            { label: `${ohi_text} Informe empresarial`, key: '0' },
            { label: `${ohi_text} BU Informe`, key: '1' },
            { label: `${ohi_text} Nueva encuesta`, key: '3' },
            { label: `${ohi_text} Influyente`, key: '8', checkInfluencer: true },
            { label: 'Informe empresarial de Experiencia del empleado', key: '7', checkExAccess: true },
            { label: 'Informe empresarial de Inclusión', key: '4', checkIncAccess: true },
            {
                label: 'Informe de nueva encuesta de Inclusión',
                key: '6',
                checkIncAccess: true,
                checkIAResurveyAccess: true,
            },
            { label: 'cubo de datos', key: '5', checkCubeAccess: true },
            { label: `Módulo de modelo operativo excel`, key: '9', checkOpAccess: true },
        ],
        ING_REPORT_OPTIONS: [
            { label: `${ohi_text} BU Informe`, key: '1', checkYear: true },
            { label: `${ohi_text} Nueva encuesta`, key: '3' },
            { label: 'cubo de datos', key: '5', checkCubeAccess: true },
        ],
        AMMEGA_REPORT_OPTIONS: [{ label: `${ohi_text} Nueva encuesta`, key: '3' }],
    }),
    ROLES_META: {
        'Roles.user': 'Usuario',
        'Roles.cst': 'CST',
        'Roles.client': 'Cliente',
    },
    COLOR_TEXT: 'Color',
    GREEN_TEXT: 'Verde',
    BLUE_TEXT: 'Azul',
    PASTREPORT_TABLE_HEADING: [
        {
            id: 'export_name',
            numeric: false,
            disablePadding: false,
            label: 'Nombre del informe',
            width: '',
        },
        {
            id: 'export_type',
            numeric: false,
            disablePadding: false,
            label: 'Tipo de informe',
            width: '16%',
        },
        {
            id: 'created_on',
            numeric: false,
            disablePadding: false,
            label: 'Fecha',
            width: '16%',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Estado',
            width: '16%',
        },
        {
            id: 'action',
            numeric: false,
            disablePadding: false,
            label: 'Acción',
            width: '16%',
        },
    ],
    DEMOGRAPHIC_EE_THRESHOLD: 'Umbral de demografía de Experiencia del empleado',
    CHOOSE_DEMOGRAPHICS: 'Elija demografías',
    HEALTH_COHESION_BOARD: 'Tablero de cohesión de Salud',
    TOP_RANKING: 'Clasificación más alta',
    MIDDLE_RANKING: 'Clasificación media',
    BOTTOM_RANKING: 'Ranking inferior',
    RANK: 'Rank',
    RECIPE_TEXT: 'Receta',
    DOWNLOADING_TEXT: {
        '1': 'Descargado',
        '2': 'Descargando',
        '3': 'Su informe se está preparando para la descarga',
        '4':
            'También puede ver el informe listo en la sección de informes anteriores, debajo del llamado a la acción de los informes en la barra de navegación superior.',
        '5': 'Descargar',
    },
    ERROR: 'ERROR',
    INFO_MODAL_TEXT: {
        '1': 'How are Inclusion Gaps calculated ?',
        '2': 'Calcule las puntuaciones de inclusión para cada opción en cada grupo demográfico de la encuesta',
        '3':
            'Calcule la brecha “más grande” en las puntuaciones de Inclusión entre todas las opciones de un grupo demográfico',
        '4': 'Identifique las “más altas” entre todas las brechas “más grandes” entre los grupos demográficos',
        '5': 'Repita los pasos anteriores para cada resultado y práctica.',
        '6': 'Cómo reducir los cálculos de brecha',
        '7': 'Excluya algunas demografías del cálculo de brecha, o',
        '8': 'Aplique algún filtro demográfico',
    },
    CLEAR_ALL: 'Borrar todo',
    GLOBAL: 'Global',
    HEALTH_BENCHMARK_BOARD: 'Tablero de criterio de referencia de Salud',
    ANALYTIC_FLAGPOLE_TEXT: {
        '1': 'Bandera de analítica',
        '2': 'Bandera',
        '3': 'Los colores de los cuartiles se basan en el criterio de referencia global',
        '4': 'Median score',
        '5': 'Diferencia media de puntos',
        '6': 'con respecto al criterio de referencia global',
    },
    ANALYTIC_CUSTOM_BM_TEXT: {
        '1': 'Analytics Custom Benchmark',
        '2': 'Custom Benchmark',
        '3': 'Quartile coloring is based on comparison of median scores to global benchmark',
        '4': 'Median score',
        '5': 'Median Point Difference',
        '6': 'to Global benchmark',
    },
    OVERVIEW_PAGE_TEXT: {
        '1': 'Overview',
        '2': 'Vista rápida de los datos de Analítica de OHI',
        '3': 'Núm. de encuestas',
        '4': 'No. of Surveys',
        '5': 'Núm. de encuestados',
        '6': 'Número de encuestas',
    },
    GSOD_FLAGPOLE_NAME: 'Specified Sample’s Median Score',
    GSOD_FLAGPOLE_TEXT: 'Median Score',
    GSOD_FLAGPOLE_TEXT_1: 'Median Point Difference',
    GSOD_FLAGPOLE_TEXT_2: 'con respecto al Benchmark global',
    PRACTICE_RANK_PAGE_TEXT: {
        // '1': 'Clasificación de cuartil superior',
        // '2': 'Clasificación de cuartil inferior',
        '1': 'Healthy organizations rank',
        '2': 'Unhealthy organizations rank',
        '3': 'Diferencia entre clasificaciones',
        '4': 'Clasificación general de práctica',
        '5': 'Clasificación de prácticas de analítica',
        '6': 'Top quartile, n = ',
        '7': 'Bottom quartile, n = ',
        '8': 'Top and Second quartile, n = ',
        '9': 'Third and Bottom quartile, n = ',
    },
    TOOLTIP_PAGE_TEXT: {
        '1': 'Organizations falling in top quartile of selected sample',
        '2': 'Organizations falling in bottom quartile of selected sample',
        '3': 'Organizations falling in top and second quartile of selected sample',
        '4': 'Organizations falling in third and bottom quartile of selected sample',
    },
    SCORECARD_PAGE_TEXT: {
        '1': 'Tablero de cuadro de mando de GSOD',
        '2': 'Puntuaciones medias del criterio de referencia',
        '3': 'Colores de cuartiles basados en el criterio de referencia global',
    },
    ADDONS_PAGE_TEXT: {
        '1': 'Salud',
        '2': 'Comentarios',
        '3': 'Valores',
        '4': 'Tablero',
        '5': 'ING, Índice de diversidad, inclusión y pertenencia',
    },
    EXCEL_TEXT: {
        '1': 'ING, Índice de diversidad, inclusión y pertenencia',
        '2': 'Texto de la pregunta no disponible',
    },
    RESURVEY_BOARD_PAGE: {
        '1': 'Comparison HeatMap Version',
        '2': 'Demografía de nueva encuesta',
        '3': 'Tablero de mapa de calor',
    },
    EMPLYEE_EXPEIENCE: 'Experiencia del empleado',
    QUESTION_SCORE: 'Puntuación de la pregunta',
    IND_EXP_SCORE_PAGE_TEXT_NOTES: 'Desde 0=Extremadamente improbable hasta 10=Extremadamente probable',
    EMPLOYEE_NET_PROMOTER_SCORE: 'Puntuación neta del promotor del empleado',
    EMPLOYEE_FACTORS_POSITIVE: 'Factores de experiencia (Positiva)',
    EMPLOYEE_FACTORS_NEGATIVE: 'Factores de experiencia (Negativa)',
    RESPONDENTS_WHO_RESPONDED: 'Los encuestados que respondieron',
    PERCEIVE_THE_ORGANIZATION_AS: 'perciben la organización como',
    HEALTHY: 'Saludable',
    UNHEALTHY: 'No saludable',
    EXRTREMELY_LIKELY: 'Muy probable',
    AGREE_OR_HIGHER: '(De acuerdo) o superior',
    DONT_KNOW_NA: 'No lo sé/NA',
    OF_TOTAL_NUMBER_OF_RESPONDENTS: 'Del número total de encuestadas',
    AGREEMENT_LEVEL_IN_PERCENTAGE: 'Nivel de acuerdo en porcentaje',
    HEALTH_PRACTICE_RANKING: 'Clasificación de prácticas de salud',
    INCLUSION_PRACTICE_RANKING: 'Clasificación de prácticas de Inclusión',
    BU_COMPARISION: 'Comparación de BU',
    SHOW_TOP_12_BU_RANK: 'Mostrar las 12 clasificaciones principales de BU',
    ORGANISATION_RANK_VS: 'Organisation rank vs',
    RANKING: 'Categoría',
    GRAPH_DIFFERENCE: 'Graph Difference',
    GSOD_RANKING: 'Overall Practice Ranking',
    GSOD_DIFFERENCE: 'Rank Difference',
    HEALTH_PROFILE_PAGE: 'Página de perfil de salud',
    HEALTH_QUESTIONS_BOARD: 'Tablero de preguntas de Salud',
    EMPLOYEE_EXPERIENCE_QUESTIONS: 'Preguntas Experiencia del empleado',
    VIEW_EMPLOYEE_EXPERIENCE_QUESTIONS: 'VIEW EMPLOYEE EXPERIENCE QUESTIONS',
    POINT_INCREASE_RREQUIRED_TO_ACHIVE_TOP_QUARTILE: 'Aumento de puntos necesario para alcanzar el cuartil superior',
    FEEDBACK: 'Feedback',
};

export const FILTER_LIST_RANGE = 7;
export const INCLUSION_DEFAULT_BENCHMARK = '1001';
export const INCLUSION_DEFAULT_BENCHMARK_STR = '1001:10';

export const BPP_LIST = {
    strategic_clarity: 1,
    role_clarity: 2,
    competitive_insights: 3,
    personal_ownership: 4,
};

export const NON_PROFIT_SURVEY = 'non-profit';
export const OHI4_IND_EXP = 'individual_experience';
export const OHI4_RESIDUAL = ['new_ohi_practices', 'skip_adding_ohi_4_0_questions'];

export const USER_ROLES = {
    SUPER_ADMIN: 'Roles.sa',
    ADMIN: 'Roles.admin',
    COACH: 'Roles.coach',
    CLIENT: 'Roles.client',
    CST: 'Roles.cst',
    USER: 'Roles.user',
};

export const TEXT_COMMENTS_KEYS = ['open_ThreeWords', 'open_Strengths', 'open_Weaknesses', 'open_Thoughts'];

export const API_DUMMY_PARAMS = {
    ohid: 'OHI011119',
    lang: 1033,
    year: 2020,
    threshold: 10,
    bm_filters: ['1001:1'],
    filters: [],
    page: 1,
    columns: 50,
    type: '',
    demographic: '',
    benchmarks: '',
    report_type: '',
    is_top_quartile: 0,
};

export const INC_DUMMY_PARAMS = {
    ...API_DUMMY_PARAMS,
    with_benchmark: true,
    benchmarks: '1001:10',
};

export const ING_REPORT_ID = '102428';
export const AMMEGA_OHI_ID = 'OHI11817';
export const THOMSON_OHI_ID = 'OHI13191';
export const TALX_REPORT_ID = '100068';
export const HYBRID_QUES_KEY = 'q_hybrid';
export const EXCLUDE_QUES_KEY = 'qvitality';
export const ING_PREV_SURVEY_EXC_QUES = ['q_incl_fair', 'q_incl_bel', 'q_incl_div', 'q_incl_psi'];
export const ING_CURR_OHID = 'OHI14084';

export const INCLUSION_BENCHMARK = [
    {
        code: -1,
        label: 'Clasificación estandarizada (colores de semáforo)',
        options: [{ code: -1, label: 'Clasificación estandarizada (colores de semáforo)' }],
        selectedOption: '',
    },
];

export const CFG_REPORT_ID = '100054';
export const NPS_CODE = 'AddQ1_AddQ1_1';
export const CURRENT_YEAR = '2024';
export const SLICE_VALUE = 30;
export const BLANCO_DEFAULT_DEMO = 'demo_Engageclassify';
export const BANCO_REPORT_ID = 101194;
export const IND_EXP_SCORE_CLASSIFICATION = [
    {
        title: 'Muy Alto (+80)',
        positiveBg: colors.$indExpColor[1][0],
        negativeBg: colors.$indExpColor[1][1],
        color: '#ffffff',
        titleNeg: 'Muy Alto (+40)',
    },
    {
        title: 'Alto (70-79)',
        positiveBg: colors.$indExpColor[2][0],
        negativeBg: colors.$indExpColor[2][1],
        color: '#ffffff',
        titleNeg: 'Alto (30-39)',
    },
    {
        title: 'Moderado (60-69)',
        positiveBg: colors.$indExpColor[3][0],
        negativeBg: colors.$indExpColor[3][1],
        color: '#000000',
        titleNeg: 'Moderado (20-29)',
    },
    {
        title: 'Bajo (50-59)',
        positiveBg: colors.$indExpColor[4][0],
        negativeBg: colors.$indExpColor[4][1],
        color: '#000000',
        titleNeg: 'Bajo (10-19)',
    },
    {
        title: 'Muy Bajo (<50)',
        positiveBg: colors.$indExpColor[5][0],
        negativeBg: colors.$indExpColor[5][1],
        color: '#000000',
        titleNeg: 'Muy Bajo (<10)',
    },
];

export const QUARTILE_COLORS = [
    {
        background: '#061F79',
        border: '#FFA800',
        color: '#ffffff',
        title: 'Top decile',
        top_position: '19000',
    },
    {
        background: '#061F79',
        border: '#061F79',
        color: '#ffffff',
        title: 'Top quartile',
        top_position: '23500',
    },

    {
        background: '#2251FF',
        border: '#2251FF',
        color: '#ffffff',
        title: 'Second quartile',
        top_position: '31000',
    },

    {
        background: '#99C4FF',
        border: '#99C4FF',
        color: '#000000',
        title: 'Tercer cuartil',
        top_position: '41000',
    },

    {
        background: '#CCCCCC',
        border: '#CCCCCC',
        color: '#000000',
        title: 'Bottom quartile',
        top_position: '51000',
    },

    {
        background: '#CCCCCC',
        border: '#757575',
        color: '#ffffff',
        title: 'Bottom decile',
        top_position: '19000',
    },
];
export const NEG_IND_QUES = ['Agotamiento laboral', 'intent_to_leave'];
export const QUES_LEGEND_TYPE = {
    burnout: 1,
    intent_to_leave: 1,
    endorsing_organization_to_others: 2,
};

export const PRACTICE_RANKING_FILTER = {
    0: [
        'career_opportunities',
        'challenging_leadership',
        'financial_incentives',
        'financial_management',
        'open_and_trusting',
        'performance_goals',
        'personal_ownership',
        'risk_management',
        'shared_vision',
        'supportive_leadership',
    ],
    1: [
        'business_partnerships',
        'capturing_external_ideas',
        'competitive_insights',
        'customer_orientation',
        'employee_innovation',
        'government_industry_relations',
        'operational_management',
        'operationally_disciplined',
        'role_clarity',
        'top_down_innovation',
    ],
    2: [
        'capturing_external_ideas',
        'common_purpose',
        'consequence_management',
        'employee_innovation',
        'financial_incentives',
        'knowledge_sharing',
        'performance_transparency',
        'rewards_recognition',
        'role_clarity',
        'top_down_innovation',
    ],
    3: [
        'career_opportunities',
        'challenging_leadership',
        'inspirational_leaders',
        'knowledge_sharing',
        'meaningful_values',
        'people_performance_review',
        'personal_ownership',
        'rewards_recognition',
        'talent_acquisition',
        'talent_development',
    ],
};

export const SINGLE_PAGE_EXPORT_PARAMS = ({ BENCHMARK_NAME = 'Global' }) => ({
    others: 0,
    level: 1,
    export_type: 'single_page',
    report_type: 'favorable',
    need_tornado_chart: false,
    resource: 'ohi',
    resurveyDownloadType: 'ppt',
    n_size: 10,
    outcomePptVersion: '1',
    fill_threshold_with: '',
    report_name: 'Single_Page',
    comparison_heatmap: false,
    sort_by: '',
    practicePptVersion: '1',
    ingDemographicFilters: [],
    score_depth: 3,
    summaryOpt: NONE,
    summaryValue: NONE,
    bubbleChartValue: NONE,
    deepDiveValue: NONE,
    rrDemographics: NONE,
    empExpDemographics: NONE,
    cohesionDemographics: NONE,
    summary_demographic: {},
    includeDelta: false,
    unmapped_demos: {},
    bu_comparison_names: '',
    bu_reportname: '',
    options: {
        TopDecileBorder: '#FFD700',
        BU_FilterSummary: '',
        IsPercentile: false,
        TopDecileToggle: true,
        TopQuartileColor: '#002960',
        reportLanguage: '1033',
        SecondQuartileColor: '#128FA7',
        BENCHMARK_NAME,
        SURVEYSENT_RR: '3321||98%',
        FilterSummary: '',
        TopDecileColor: '#002960',
        ReportType: '0',
        BottomQuartileColor: '#D9D9D9',
        flagpole_footer: [
            { bmcode: '', bmtext: '' },
            { bmcode: '', bmtext: '' },
        ],
        ThirdQuartileColor: '#9FB9BD',
        leftBenchmarks: 'Global',
        rightBenchmarks: 'Global',
        pss_module: 0,
        engagement: null,
        non_profit_report_full: '1',
        Resurvey_SigVsQuartile: '1',
        survey_names: '',
        resurvey_custom_delta: '',
        org_level_filter: '0',
    },
    heatmap_demographic: {},
    response_rate_demographic: {},
    employee_experience_demographic: {},
    cohesion_demographic: [],
    ohi4_incModules: [],
    ohi4_incDemographics: {},
});

export const RESURVEY_EXPORT_PARAMS = {
    report_name: 'Resurvey_Scorecard',
    export_type: 'single_page',
    options: {
        Resurvey_footer_BM: '',
        Resurvey_years: '2023',
        Resurvey_SigVsQuartile: '1',
        Resurvey_IsResurvey: 'TRUE',
        reportLanguage: "'1033'",
        ReportType: '3',
        Resurvey_Stats: '2023$3321$3269',
        SURVEYSENT_RR: '3321||98%',
        BU_FilterSummary: '',
        IngBuFilterSummary: '',
        survey_names: 'Prev $#$ Current',
        pss_module: 0,
        engagement: null,
        FilterSummary: '',
        includeDelta: false,
        unmapped_demos: {},
        resurvey_custom_delta: '',
    },
    heatmap_demographic: {},
    comparison_names: 'm',
    PRIORITY_PRAC_ORDER: 'DELETE',
    BENCHMARK_NAME: 'Global',
    practicePptVersion: '1',
    outcomePptVersion: '1',
    comparison_heatmap: true,
    bu_comparison_names: '',
    bu_reportname: '',
    bu_Filters: [],
    sort_by: '',
    others: 0,
};

export const PRACTICE_RANKING_DESCRIPTION = {
    'Recipe A (Leadership Factory) alignment':
        'Impulsar el desempeño mediante el desarrollo y la asignación de líderes sólidos, apoyados por coaching, capacitación y oportunidades de crecimiento.',
    'Recipe B (Market Shaper) alignment':
        'Avanzar a través de la innovación en todos los niveles y lograr un entendimiento profundo de los clientes y los competidores.',
    'Recipe C (Execution Edge) alignment':
        'Desarrollar una ventaja competitiva mediante la participación de todos los empleados en la promoción del desempeño y la innovación, en la recopilación de ideas y en el intercambio de conocimientos.',
    'Recipe D (Núcleo de talento y conocimiento) alignment':
        'Crear valor atrayendo e inspirando a los mejores talentos.',
};

export const QUESTION_REPLACEMENTS = {
    QbyQ_coordination_control_4_0: ['pss_1_QbyQ_coordination_control_4_0', 'pss_2_QbyQ_coordination_control_4_0'],
    QbyQ_capabilities_4_0: ['pss_QbyQ_capabilities_4_0'],
    QbyQ_accountability_4_0: ['pss_QbyQ_accountability_4_0'],
    QbyQ_work_environment_4_0: ['pss_QbyQ_work_environment_4_0'],
};

export const PROFILE_REPLACEMENTS = {
    practice_profile_checkme_4_0: ['pss_practice_profile_checkme_4_0'],
    Practice_rank_alignment_4_0: ['pss_Practice_rank_alignment_4_0'],
    Practice_rank_execution_4_0: ['pss_Practice_rank_execution_4_0'],
    Practice_rank_renewal_4_0: ['pss_Practice_rank_renewal_4_0'],
};

export const SCORECARD_REPLACEMENTS = {
    Scorecard_4_0: ['pss_Scorecard_4_0'],
};

export const ING_OVERALL_HEALTH = [
    {
        name: `OVERALL_HEALTHGRP04`,
        is_delete: true,
    },
    {
        name: 'OVERALL_HEALTHOVAL03',
        is_delete: true,
    },
];
