import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Analytics from './Analytics';
import analytics from './reducer';
import analyticsSagas from './sagas';

import {
    selectAnalyticsApiCount,
    selectDataAtGlance,
    selectDataAtGlanceFetched,
    selectDefaultFilters,
    selectDefaultYear,
    selectFilterParam,
    selectFilters,
    selectFiltersApplied,
    selectFiltersFetched,
    selectFiltersMapping,
    selectFiltersMappingFetched,
    selectRange,
} from './selector';
import {
    getDataAtGlance,
    getFilterData,
    updateFilters,
    getFiltersMapping,
    getDefaultYear,
    setOrgSizeData,
} from './actions';
import { getDefaultSettings } from '../Login/selector';
import { selectApiCount } from '../Diagnose/selector';

const stateToProps = createStructuredSelector({
    analyticsApiCount: selectAnalyticsApiCount(),
    dataAtGlance: selectDataAtGlance(),
    filters: selectFilters(),
    filtersApplied: selectFiltersApplied(),
    filtersFetched: selectFiltersFetched(),
    defaultSettings: getDefaultSettings(),
    filterParams: selectFilterParam(),
    dataAtGlanceFetched: selectDataAtGlanceFetched(),
    filtersMapping: selectFiltersMapping(),
    filtersMappingFetched: selectFiltersMappingFetched(),
    defaultFilters: selectDefaultFilters(),
    defaultYear: selectDefaultYear(),
    diagnoseApiCount: selectApiCount(),
    range: selectRange(),
});

const dispatchToProps = {
    getDataAtGlance,
    getFilterData,
    updateFilters,
    getFiltersMapping,
    getDefaultYear,
    setOrgSizeData,
};

export { analyticsSagas, analytics };

export default connect(stateToProps, dispatchToProps)(Analytics);
