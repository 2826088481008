import React, { useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { ListItem, Checkbox } from '@material-ui/core';
import { cloneDeep } from 'lodash';

import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { toUpper, scoreToDisplay } from '../../utils/functions';
import { getStaticText } from '../../utils/constants';
import './index.scss';

function PracticeDemographics({ demographics, updateDemographics, limit, defaultSettings }) {
    const { lang, inclusion_survey_type = '' } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const isPulse = inclusion_survey_type === 'Pulse';
    const { SYSTEMS, PEER_TEXT, TEAMMATES_TEXT, OUT_OF } = SITE_TEXT || {};
    const selectParentCheckbox = ({ checked }, parentValue, index) => {
        const { level = '' } = parentValue;
        const newDemographics = cloneDeep(demographics);
        newDemographics[level][index].isSelected = checked;
        updateDemographics(newDemographics);
    };

    const [openPracticeOpt, setOpenPracticeOpt] = useState();
    const handlePracticeCollapse = indexParent => {
        if (openPracticeOpt === indexParent) {
            setOpenPracticeOpt('');
        } else {
            setOpenPracticeOpt(indexParent);
        }
    };

    return (
        <div className="filterContent clearfix">
            <div className="upperContent clearfix">
                {Object.keys(demographics).map((val, indexParent) => {
                    const checkLength = demographics[val].filter(({ isSelected }) => isSelected).length;
                    const { display_name: parName = '' } = val;
                    return (
                        <div className="mainListWrap" key={indexParent}>
                            <div className="questionHeading">
                                <div onClick={() => handlePracticeCollapse(indexParent)}>
                                    <Checkbox checked={checkLength} name={parName} />
                                    <span>
                                        {val}
                                        {val !== SYSTEMS && null}
                                        {val === { PEER_TEXT } ? `/ ${TEAMMATES_TEXT}` : null}
                                    </span>
                                </div>
                                <div className="practiceCollapse">
                                    <span>
                                        {checkLength}
                                        {OUT_OF}
                                        {limit}
                                    </span>
                                    <span
                                        onClick={() => handlePracticeCollapse(indexParent)}
                                        data-testid={`practiceCollapse${indexParent}`}
                                    >
                                        {openPracticeOpt === indexParent ? <ExpandLess /> : <ExpandMore />}
                                    </span>
                                </div>
                            </div>

                            {openPracticeOpt === indexParent ? (
                                <div>
                                    {demographics[val].map((parentValue, index) => {
                                        const {
                                            isSelected = false,
                                            display_name = '',
                                            score = 100,
                                            meta_scores = [],
                                        } = parentValue;
                                        const isNa = scoreToDisplay(score, meta_scores, isPulse) === '-';
                                        return (
                                            <ListItem
                                                classes={{
                                                    root: c('mainListContent', {
                                                        disableClass: (checkLength >= limit && !isSelected) || isNa,
                                                    }),
                                                }}
                                                key={index}
                                                data-testid={display_name}
                                                disabled={isNa}
                                                onClick={() =>
                                                    selectParentCheckbox({ checked: !isSelected }, parentValue, index)
                                                }
                                            >
                                                <Checkbox
                                                    checked={isSelected}
                                                    onChange={({ target }) =>
                                                        selectParentCheckbox(target, parentValue, index)
                                                    }
                                                    name={display_name}
                                                    inputProps={{
                                                        'data-testid': `ParentCheck${display_name}`,
                                                    }}
                                                />
                                                {toUpper(display_name)}
                                            </ListItem>
                                        );
                                    })}
                                </div>
                            ) : null}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

PracticeDemographics.defaultProps = {
    limit: 3,
};

PracticeDemographics.propTypes = {
    limit: PropTypes.number,
    demographics: PropTypes.object.isRequired,
    updateDemographics: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
};

export default PracticeDemographics;
